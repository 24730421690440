<template>
    <v-card>
        <v-toolbar flat dense>
            <v-toolbar-title class="text-subtitle-1">LLAMADAS</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn small color="primary" @click="open" v-if="false">
                Crear
            </v-btn>
        </v-toolbar>

        <v-divider></v-divider>

         <v-data-table
            :headers="columns"
            :items="llamadas_info"
            item-key="name"
            class="elevation-1"
            loading-text="Cargando..."
            dense
            :items-per-page="5"
        >
            <template v-slot:[`item.usuario`]="{ item }">
                {{ showAgenteName(item.usuario) }}
            </template>
        </v-data-table> 
    </v-card>
</template>

<script>
import { mainAxios } from "../../../mainAxios";

    export default {
        name: 'LlamadasLead',
        props: {
            llamadas_info: {
                type: Array,
                required: true
            }
        },
        data: function(){
            return{
                 columns: [
                     { text: "Disposición", align: "start", sortable: false, value: "disposicion"},
                     { text: "Fecha y hora", value: "fecha" },
                     { text: "Agente", value: "usuario" },
                 ],
                 agentes:[]
            }
        },
        methods: {
            getAgentes() {
                let config = {
                    headers: {
                    Authorization: "Bearer " + localStorage.agenteAccessToken,
                    },
                };
                mainAxios.get(`/v1/agente/list`, config)
                    .then((response) => {
                        this.agentes = response.data
                    });
            },
            showAgenteName(id){
                var agente = this.agentes.find(agente => agente.id == id);
                return agente.nombre + ' ' + agente.apellidoPaterno + ' ' + agente.apellidoMaterno
            }
        },
        mounted() {
            this.getAgentes()  
        }, 
    }
</script>