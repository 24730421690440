<template>
  <v-app>
    <Navigation></Navigation>
    <v-main class="mb-15">
      <v-container fluid>
        <v-row class="mb-15 fill-height">
          <v-col>
            <v-row>
              <v-col>
                <h1>Reporte Emisiones TuSeguro</h1>
              </v-col>
            </v-row>
            <v-row class="mb-15" style="padding: 30px">
              <v-col md="3">
                <h3>Selecciona el rango de fechas</h3>
                <v-date-picker
                  style="width: 100%"
                  v-model="dates"
                  range
                ></v-date-picker>
                <v-btn
                  rounded
                  color="primary"
                  style="width: 100%"
                  dark
                  @click="consultar"
                  >Consultar</v-btn
                >
                <v-btn
                  rounded
                  color="primary"
                  style="width:100%;margin-top:20px;"
                  dark
                  v-if="roles != 'AGENTCC'"
                  @click="newEmision"
                  >Crear nueva emision</v-btn
                >
                <v-btn
                  rounded
                  color="primary"
                  style="width:100%;margin-top:20px;"
                  :disabled="bloquear"
                  ><download-csv
                    :data="reportDownload"
                    name= "reporteEmisiones.csv"
                  >
                  Descargar Información
                  </download-csv></v-btn
                >
              </v-col>
              <v-col cols="9" md="9">
                <div>
                  <div v-if="loading" style="text-align: center">
                    <h2 clas="pb-4">Cargando Reporte....</h2>
                    <br /><br />
                    <md-progress-spinner
                      :md-diameter="100"
                      :md-stroke="10"
                      md-mode="indeterminate"
                    ></md-progress-spinner>
                  </div>
                </div>

                <v-data-table
                  :headers="columns"
                  :items="emisiones"
                  item-key="name"
                  class="elevation-1"
                  :search="search"
                  :custom-filter="filterOnlyCapsText"
                >
                  <template v-slot:top>
                    <v-text-field
                      v-model="search"
                      label="Buscar"
                      class="mx-4"
                    ></v-text-field>
                  </template>
                  <template v-slot:item.status="{ item }">
                    <td v-if="item.status == 1">Emitida</td>
                    <td v-if="item.status == 2">Pagada</td>
                  </template>

                  <template v-slot:item.aseguradoraId="{ item }">
                    <td v-if="item.aseguradoraId == 1">Afirme</td>
                    <td v-if="item.aseguradoraId == 2">ANA</td>
                    <td v-if="item.aseguradoraId == 3">GNP</td>
                    <td v-if="item.aseguradoraId == 4">Primero</td>
                    <td v-if="item.aseguradoraId == 5">Quálitas</td>
                    <td v-if="item.aseguradoraId == 6">Multiva</td>
                    <td v-if="item.aseguradoraId == 7">Sura</td>
                    <td v-if="item.aseguradoraId == 8">AIG</td>
                    <td v-if="item.aseguradoraId == 9">HDI</td>
                    <td v-if="item.aseguradoraId == 10">Zurich</td>
                    <td v-if="item.aseguradoraId == 11">AXXA</td>
                    <td v-if="item.aseguradoraId == 12">Banorte</td>
                  </template>
                  
                  <template v-slot:item.uuid="{ item }">
                    <v-btn @click="$router.push('/administrador/editar-emision/'+item.uuid)">
                      Ver
                    </v-btn>
                  </template>

                  <template v-slot:item.urlPoliza="{ item }">
                    <v-btn :href="'https://segurify-data.s3.amazonaws.com/'+item.urlPoliza">Descargar</v-btn>
                  </template>
                </v-data-table>

                
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <v-footer fixed style="text-align:center;">
      <p style="text-align:center;width:100%; margin: 10px 0px">Segurify 2021 <a target="_blank" href="/terminos-y-condiciones">Terminos y condiciones</a> y <a target="_blank" href="/aviso-de-privacidad">Aviso de privacidad</a>  </p>
    </v-footer>
  </v-app>
</template>

<script>
import Navigation from "../../../../components/Nav.vue";
import { mainAxios } from "../../../../mainAxios";

var moment = require("moment-timezone");

export default {
  components: {
    Navigation,
  },
  data() {
    return {
      dates: ["", ""],
      roles: JSON.parse(localStorage.getItem('roles')),
      loading: false,
      reportType: "lead",
      emisiones: [],
      search: "",
      reportDownload: [],
      bloquear: true,

      columns: [
        { text: "Codigo Emisión", value: "codigoConfirmacionSegurify" },
        { text: "Poliza", value: "poliza" },
        { text: "Fecha", sortable: false, value: "fecha",},
        { text: "Aseguradora", value: "aseguradoraId" },
        { text: "Vehiculo", value: "nombreVehiculo" },
        { text: "Correo Cliente", value: "correoContacto" },
        { text: "telefono cliente", value: "telefonoContacto" },
        { text: "No. Cotización / UUID", value: "origen" },
        { text: "status", value: "status" },
        { text: "Editar", value: "uuid" },
        { text: "PDF", value: "urlPoliza" },
      ],
    };
  },

  methods: {
    convertDate(date) {
      const dateRes = moment(date);
      dateRes.tz("UTC", true);
      return dateRes.tz("America/Mexico_City").format("DD-MM-YYYY HH:mm");
    },

    consultar() {
      var vm = this;
      let config = {
          headers: {
            Authorization: "Bearer " + localStorage.agenteAccessToken,
          },
        }
      let inicioUTC = null
      let finUTC = null

      if(this.dates.length > 0){
        if(this.dates[1]) {
            inicioUTC = moment.utc(this.dates[0]).format('YYYY-MM-DD HH:mm:ss')
            finUTC = moment.utc(this.dates[1]).add(24, 'hours').subtract(1, 'seconds').format('YYYY-MM-DD HH:mm:ss')
        } else {
            inicioUTC = moment.utc(this.dates[0]).format('YYYY-MM-DD HH:mm:ss')
            finUTC = moment.utc(this.dates[0]).add(24, 'hours').subtract(1, 'seconds').format('YYYY-MM-DD HH:mm:ss')
        }
      }
      let fechas = {
        fechaInicio: inicioUTC,
        fechaFin: finUTC,
      };
      mainAxios.post("/v1/reporte/emisiones/tuseguro", fechas,config).then((value) => {
        vm.reportDownload = []
        vm.emisiones = []
        vm.emisiones = value.data;
        console.log();
        vm.emisiones.map(element => {
          this.reportDownload.push({
            "Id": element.id,
            "UUID": element.uuid,
            "Fecha": element.fecha,
            "Inicio Póliza": element.inicio,
            "Fin Póliza": element.fin,
            "Nombre": element.titular,
            "Correo Electónico contacto": element.correoContacto,
            "Teléfono": element.telefonoContacto,
            //"ID Lead": element.id,
            "Campaña/Landing": element.campania,
            "Origen": element.origen,
            "Vehículo": element.nombreVehiculo,
            "Póliza": element.poliza,
            "Prima total": element.total,
            "Prima neta": element.primaNeta,
            "Primer pago": element.primerPago,
            "Pago Subsecuente": element.subsecuentePago,
            "periodicidad de Pago": element.pagoId == 1 ? 'Anual' 
              : element.pagoId == 2 ? 'Semestral' 
              : element.pagoId == 3 ? 'Trimestral'
              : 'Mensual', 
            "Forma de Pago": 
              element.formaPago == 1 ? 'Tarjeta de Crédito' : 
              element.formaPago == 2 ? 'Tarjeta de Débito' : 
              element.formaPago == 3 ? 'Depósito bancario' : 
              element.formaPago == 4 ? 'Transferencia' : 
              element.formaPago == 5 ? 'Cheque' : 
              element.formaPago == 6 ? 'Efectivo' : 
              element.formaPago == 7 ? 'Domiciliación' :
              'Cargo único',
            "Aseguradora": 
              element.aseguradoraId == 1 ? 'Afirme' 
              : element.aseguradoraId == 2 ? 'ANA' 
              : element.aseguradoraId == 3 ? 'GNP' 
              : element.aseguradoraId == 4 ? 'Primero' 
              : element.aseguradoraId == 5 ? 'Qualitas'
              : element.aseguradoraId == 6 ? 'Multiva'
              : element.aseguradoraId == 7 ? 'Sura'
              : element.aseguradoraId == 8 ? 'AIG'
              : element.aseguradoraId == 9 ? 'HDI'
              : element.aseguradoraId == 10 ? 'Zurich'
              : element.aseguradoraId == 11 ? 'AXA'
              : 'Banorte',
            "Plan": 
              element.planId == 1 ? 'Amplia'
              : element.planId == 14 ? 'Amplia Plus'
              : element.planId == 3 ? 'Limitada'
              : element.planId == 4 ? 'RC_reglamentaria' : '',
            "Estado de la República": 
              element.estadoId == 1 ? 'Aguascalientes'
              : element.estadoId == 2 ? 'Baja California'
              : element.estadoId == 3 ? 'Baja California Sur'
              : element.estadoId == 4 ? 'Campeche'
              : element.estadoId == 5 ? 'Coahuila de Zaragoza'
              : element.estadoId == 6 ? 'Colima'
              : element.estadoId == 7 ? 'Chiapas'
              : element.estadoId == 8 ? 'Chihuahua'
              : element.estadoId == 9 ? 'Ciudad de México'
              : element.estadoId == 10 ? 'Durango'
              : element.estadoId == 11 ? 'Guanajuato'
              : element.estadoId == 12 ? 'Guerrero'
              : element.estadoId == 13 ? 'Hidalgo'
              : element.estadoId == 14 ? 'Jalisco'
              : element.estadoId == 15 ? 'Estado de México'
              : element.estadoId == 16 ? 'Michoacán de Ocampo'
              : element.estadoId == 17 ? 'Morelos'
              : element.estadoId == 18 ? 'Nayarit'
              : element.estadoId == 19 ? 'Nuevo León'
              : element.estadoId == 20 ? 'Oaxaca'
              : element.estadoId == 21 ? 'Puebla'
              : element.estadoId == 22 ? 'Querétaro'
              : element.estadoId == 23 ? 'Quintana Roo'
              : element.estadoId == 24 ? 'San Luis Potosí'
              : element.estadoId == 25 ? 'Sinaloa'
              : element.estadoId == 26 ? 'Sonora'
              : element.estadoId == 27 ? 'Tabasco'
              : element.estadoId == 28 ? 'Tamaulipas'
              : element.estadoId == 29 ? 'Tlaxcala'
              : element.estadoId == 30 ? 'Veracruz'
              : element.estadoId == 31 ? 'Yucatán'
              : element.estadoId == 32 ? 'Zacatecas'
              : element.estadoId == 33 ? 'Desconocio' :'',
            "Estatus de a Póliza": element.status == 1 ? 'Emitida' : 'Pagada',
            "Estatus de Cobro": element.statusCobro == 1 ? 'Pagada' : 'No pagada',
            "Asesor": element.correoOperador ? element.correoOperador : "",
          })
        })
        this.bloquear = false
      });
    },
    newEmision() {
      this.$router.push('/administrador/carga-emisiones')
    },
    // Table
    filterOnlyCapsText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().indexOf(search) !== -1
      );
    },
  },
};
</script>
