<template lang="">
  <div>
    <v-row>
      <v-col md="10" class="p-0">
        <h1>{{vista ? 'Detalle Lead' : 'Crear Lead' }}</h1>
      </v-col>
      <v-col md="2" class="p-0" >
        <v-btn
          v-if="rol == 'ADMINCC' || rol == 'MANAGERCC' || rol == 'ADMIN'"
          rounded
          color="primary"
          style="width: 100%"
          @click="vista = !vista"
          class="my-3"
          >{{vista ? 'Crear Lead' : 'Detalle Lead'}}</v-btn
        >
      </v-col>
    </v-row>
    
    <v-row class="mt-3" v-if="vista">
      <v-col md="3">
        <h3>Selecciona el rango de fechas</h3>
        <v-date-picker
          style="width: 100%"
          v-model="dates"
          range
        ></v-date-picker>
        <v-btn
          rounded
          v-if="admincc == false || admin == false"
          color="primary"
          style="width: 100%"
          @click="searchlead()"
          :disabled="rangoInput"
          class="my-3"
          >Generar Lista de Lead</v-btn
        >
        <v-btn
          v-if="admincc || admin"
          rounded
          color="primary"
          style="width: 100%"
          @click="searchleadAll()"
          class="my-3"
          >Ver todos los Leads</v-btn
        >
        <v-btn
          rounded
          color="primary"
          style="width: 100%"
          @click="newSearch()"
          class="mb-3"
          >Nueva busqueda</v-btn
        >
        <v-btn
          rounded
          v-if="admincc || admin"
          color="primary"
          style="width: 100%"
          :disabled="items < 1"
        >
          <download-csv
            :data="items"
            name= "Leads.csv"
          >
            Descargar Información
          </download-csv>
        </v-btn
        >
      </v-col>

      <v-col md="9">
        <div>
          <div v-if="loading" style="text-align: center">
            <h2 clas="pb-4 pt-2">Cargando Reporte....</h2>
          </div>
        </div>

        <v-card>
          <v-card-title class="row">
            <v-col cols="3">
              Tabla de Leads
            </v-col> 
            <v-col cols="2" v-if="admincc == false || admin == true ">
              <v-radio-group
                v-model="selectSearch"
                column
                @change="search = null, rangoInput = true"
                :disabled="selector"
              >
                <v-radio
                  label="Correo"
                  value="mail"
                ></v-radio>
                <v-radio
                  label="Teléfono"
                  value="phone"
                ></v-radio>
              </v-radio-group>
            </v-col> 
            <v-col cols="7" v-if="admincc == false || admin == false">
              <v-text-field
                v-if="selectSearch == 'phone'"
                :disabled="selectSearch == null ? true : buscador"
                v-model="search"
                @keyup="bloqueadorRango()"
                append-icon="mdi-magnify"
                label="Bucador por Teléfono"
                placeholder="##########"
                hide-details
                v-mask="'##########'"
              ></v-text-field>
              <v-form
                ref="form"
                v-model="valid"
                lazy-validation
              >
                <v-text-field
                  v-if="selectSearch == 'mail'"
                  :disabled="selectSearch == null ? true : buscador"
                  v-model="search"
                  @keyup="bloqueadorRango()"
                  append-icon="mdi-magnify"
                  label="Buscador por Correo electronico"
                  placeholder="email"
                  hide-details
                  :rules="emailRules"
                ></v-text-field>
              </v-form>
            </v-col> 
            <v-col cols="7" v-if="admincc || admin">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Bucador"
                placeholder="buscador"
                hide-details
              ></v-text-field>
            </v-col> 
          </v-card-title>
          <v-data-table
            v-if="!loading && items.length > 0"
            :headers="headers"
            :items="items"
            :search="search"
            sort-by="creationDate"
            :loading="loading"
            loading-text="Cargando..."
          >
            <template v-slot:item.details="{ item }">
              <v-icon
                small
                class="mr-2"
                @click="buscarDatos(item.uuid, item.tabla, item.campania, item.cotizacion)"
              >
                mdi-pencil
              </v-icon>
            </template>
          </v-data-table>
          <h1 v-if="noData" class="text-center">
            No hay datos en las fechas seleccionadas
          </h1>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="mt-3" v-else>
    
      <v-form
        ref="form"
        class="row"
        v-model="valid"
        lazy-validation
      >
        <v-col md="4">
          <v-text-field
            v-model="request.nombre"
            :rules="nameRules"
            label="Nombre*"
            required
          ></v-text-field>
        </v-col>

        <v-col md="4">
          <v-text-field
            v-model="request.apellidoPaterno"
            :rules="nameRules"
            label="Apellido Paterno*"
            required
          ></v-text-field>
        </v-col>
        <v-col md="4">
          <v-text-field
            v-model="request.apellidoMaterno"
            label="Apellido Materno"
          ></v-text-field>
        </v-col>

        <v-col md="4">
          <v-text-field
            v-model="request.telefono"
            name="phone"
            label="Teléfono*"
            required
            placeholder="##########"
            v-mask="'##########'"
          ></v-text-field>
        </v-col>

        <v-col md="4">
          <v-text-field
            v-model="request.correo"
            :rules="emailRules"
            label="Correo Electronico*"
            required
          ></v-text-field>
        </v-col>
        <!-- <v-col md="4">
          <v-text-field
            v-model="request.cotizacion"
            label="Cotizacion"
          ></v-text-field>
        </v-col> -->

        <v-col md="4">
          <v-select
            v-model="request.campania"
            :items="items2"
            :rules="[v => !!v || 'Campaña es necesario']"
            label="Campaña"
            item-value="id"
            item-text="landing"
            required
          ></v-select>
        </v-col>

        <v-col md="12">
          <v-btn
            color="primary"
            @click="createLead()"
          >
            Crear
          </v-btn>
        </v-col>
      </v-form>
    </v-row>

    <v-dialog
      v-model="modalLeadCreado"
      width="500"
    >
      <v-card>
        <v-card-title class="headline grey lighten-2">
          Creacióno de Lead
        </v-card-title>

        <v-card-text>
          El Lead ha sido creado con exito. Seras Redireccionado a la información de Lead.
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="modalLeadCreado = false"
          >
            aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>
<script>
import { mainAxios } from "../../../mainAxios";
import moment from 'moment-timezone';
//import {json2excel, excel2json} from 'js2excel';


export default {
  name: 'SearchLead',
  props: 
    {
      telefono: String,
      editarLead: Function,
    },
  data() {
    return {
      rol: JSON.parse(localStorage.getItem('roles'))[0].rol,
      roles: JSON.parse(localStorage.getItem('roles')),
      telefonoLead: this.telefono,
      dates: [],
      loading: false,
      leadDetalleList: '',
      lead: null,
      search: '',
      vista: true,
      headers: [
        {
          text: 'Fecha de Cración',
          align: 'start',
          sortable: true,
          value: 'creationDate',
        },
        { text: 'Detalles', value: 'details' },
        { text: 'UUID', value: 'uuid' },
        { text: 'Usuario CallCenter', value: 'userCallCenter' },
        { text: 'Nombre', value: 'name' },
        { text: 'Email', value: 'mail' },
        { text: 'Télefono', value: 'phone' },
        { text: 'Tabla', value: 'tabla' },
        { text: 'Ultima Disposicion', value: 'ultimaDisposicion' },
        { text: 'Ultimo Cambio', value: 'ultimoCambio' },
        { text: 'Campaña', value: 'campania' },
      ],
      items: [],
      buscador: false,
      selector: false,
      rangoInput: true,
      selectSearch: null,
      event: null,
      valid: false,
      emailRules: [
        (v) => !!v || 'E-mail es necesario',
        (v) =>
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || 'E-mail debe ser valido',
      ],
      noData: false,


      name: '',
      nameRules: [
        v => !!v || 'Name es necesario',
      ],
      email: '',
      select: null,
      items2: [
        'AIG',
        'ANA',
        'AXA',
        'COMPARADOR',
        'COMPARADOR-ADT',
        'COMPARADOR-AUTOCOSMOS',
        'GNP',
        'HDI',
        'PROVEEDORES',
        'QUÁLITAS',
        'SGMM SEGURIFY',
        'SURA',
        'SURA MOVILIDAD',
        'SURA SGMM',
        'AUTOS RASTREATOR AIG',
        'TUSEGURO.COM',
        'CORU.COM',
      ],
      checkbox: false,

      request: {
        "tabla": "auto",
        "campania": null,
        "cotizacion": null,
        "nombre": null,
        "apellidoPaterno": null,
        "apellidoMaterno": null,
        "telefono": null,
        "correo": null,
        "userCallCenter": localStorage.getItem('agenteMail')
      },
      modalLeadCreado: false,
    }
  },

  mounted() {
    this.list();
  },

  methods: {
    list(){
      let config = {
        headers: {
          Authorization: 'Bearer 8b06348c-2830-486c-bb7c-e0599ae750f5',
        },
      }
      mainAxios.get(`/v1/agente/landing/list`,config).then(({data}) => {
        this.items2 = data
      });
    },
    searchlead() {
      this.buscador = true
      this.selector = true
      this.loading = true;
      let fechas = {
        fechaInicio: this.dates[0],
        fechaFin: this.dates[1],
      };
      let urlLeadDetails = `/v1/oportunidades/lead-detalles/leads/todos`
      mainAxios
      .post(urlLeadDetails, fechas,{
        headers: {
          Authorization: 'Bearer 8b06348c-2830-486c-bb7c-e0599ae750f5',
        },
      })
      .then((response) => {
          this.loading = false;
          if (response.data.length > 0) {
            this.noData = false
            response.data.map((element) => {
              this.items.push({
                uuid: element.uuid,
                name: `${element.nombre} ${element.apellidoPaterno} ${element.apellidoMaterno}`,
                userCallCenter: element.userCallCenter,
                mail: element.correo,
                phone: element.telefono,
                creationDate: moment(element.fechaCreacion).format('DD-MM-YYYY HH:mm'),
                tabla: element.tabla,
                ultimaDisposicion: element.ultimaDisposicion,
                ultimoCambio: moment(element.ultimoCambio).format('DD-MM-YYYY HH:mm'),
                campania: element.campania,
                cotizacion: element.cotizacion,
              });
            });
          } else {
            this.noData = true
          }
      })
    },
    searchleadAll() {
      this.buscador = true
      this.selector = true
      this.loading = true;
      let fechas = {
        fechaInicio: this.dates[0],
        fechaFin: this.dates[1],
      };
      let urlLeadDetails = `/v1/oportunidades/lead-detalles/leads/todos`
      mainAxios
      .post(urlLeadDetails, fechas,{
        headers: {
          Authorization: 'Bearer 8b06348c-2830-486c-bb7c-e0599ae750f5',
        },
      })
      .then((response) => {
          this.loading = false;
          if (response.data.length > 0) {
            this.noData = false
            response.data.map((element) => {
              this.items.push({
                uuid: element.uuid,
                name: `${element.nombre} ${element.apellidoPaterno} ${element.apellidoMaterno}`,
                userCallCenter: element.userCallCenter,
                mail: element.correo,
                phone: element.telefono,
                creationDate: moment(element.fechaCreacion).format('DD-MM-YYYY HH:mm'),
                tabla: element.tabla,
                ultimaDisposicion: element.ultimaDisposicion,
                ultimoCambio: moment(element.ultimoCambio).format('DD-MM-YYYY HH:mm'),
                campania: element.campania,
                cotizacion: element.cotizacion,
              });
            });
          } else {
            this.noData = true
          }
      })
    },
    downloadReport() {
      let vm = this;
      let config = {
        headers: {
          Authorization: 'Bearer ' + localStorage.agenteAccessToken,
        },
        responseType: 'blob',
      };
      vm.downloading = true;
      let fechas = {
        fechaInicio: this.dates[0],
        fechaFin: this.dates[1],
      };
      vm.downloadText = 'Generando reporte...';
      mainAxios.post('/v1/reporte/leads/', fechas, config).then((response) => {
        vm.downloading = false;
        vm.downloadText = 'Descargar';
        require('downloadjs')(
          response.data,
          'ReporteLeads.xls',
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        );
        //download(response.data, "ReporteLeads.xls", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
      });
    },
    buscarDatos(parametros, tabla, origen, cotizacion) {
        this.editarLead(parametros, tabla, origen, cotizacion);
    },
    details(uuid, campania){
      console.log(uuid)
      console.log(campania)
    },

    bloqueadorRango() {
      if (this.selectSearch == 'phone') {
        this.rangoInput = this.search.length == 10 ? false : true
      } else {
        this.rangoInput = this.$refs.form.validate() ? false : true
      }
    },

    newSearch() {
      this.buscador = false
      this.selector = false
      this.rangoInput = true
      this.selectSearch = null
      this.search = null
      this.items = []
      this.dates = []
    },

    createLead() {
      let urlLeadCreate = `/v1/oportunidades/lead-detalles/crear`
      mainAxios
      .post(urlLeadCreate, this.request,{
        headers: {
          Authorization: 'Bearer 8b06348c-2830-486c-bb7c-e0599ae750f5',
        },
      })
      .then((response) => {
        console.log(response);
        if (response.status == 200) {
          this.modalLeadCreado = true;
          setTimeout(() => {
            this.buscarDatos(response.data.uuid, response.data.tabla, response.data.campania)
          }, 2500);
        }
      });
    },
  },

  computed: {
    admin() {
      var isAdmin = false;
      this.roles.forEach((element) => {
        if (element.rol == 'ADMIN') {
          isAdmin = true;
        }
      });
      return isAdmin;
    },

    adminagent() {
      var isAdminAgent = false;

      this.roles.forEach((element) => {
        if (element.rol == 'ADMINAGENT') {
          isAdminAgent = true;
        }
      });
      return isAdminAgent;
    },

    admincc() {
      var isAdmincc = false;

      this.roles.forEach((element) => {
        if (element.rol == 'ADMINCC') {
          isAdmincc = true;
        }
      });
      return isAdmincc;
    },

    manager() {
      var isManager = false;
      this.roles.forEach((element) => {
        if (element.rol == 'MANAGER') {
          isManager = true;
        }
      });
      return isManager;
    },
    managercc() {
      var isManagercc = false;
      this.roles.forEach((element) => {
        if (element.rol == 'MANAGERCC') {
          isManagercc = true;
        }
      });
      return isManagercc;
    },

    operador() {
      var isOper = false;

      this.roles.forEach((element) => {
        if (element.rol == 'OPERADOR') {
          isOper = true;
        }
      });
      return isOper;
    },
    operaciones() {
      var isOperaciones = false;

      this.roles.forEach((element) => {
        if (element.rol == 'OPERACIONES') {
          isOperaciones = true;
        }
      });
      return isOperaciones;
    },

    agentcc() {
      var isAgentcc = false;

      this.roles.forEach((element) => {
        if (element.rol == 'AGENTCC') {
          isAgentcc = true;
        }
      });
      return isAgentcc;
    },
  },
  
}
</script>
<style lang="">
  
</style>