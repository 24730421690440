<template>
  <v-row style="padding: 0px 20px">
    <v-col cols="4">
      <v-select
        label="Adjuntar Contrato"
        v-model="contratoAdjunto"
        :items="contratoAdjuntoList"
        @change="avisarCambio"
      ></v-select>
    </v-col>
    
  </v-row>
</template>

<script>
export default {
  directives: {
    'number-only': {
      bind (el) {
        function checkValue (event) {
          event.target.value = event.target.value.replace(/[^0-9]/g, '')
          if (event.charCode >= 48 && event.charCode <= 57) {
            return true
          }
          event.preventDefault()
        }
        el.addEventListener('keypress', checkValue)
      }
    },
    'letter-only': {
      bind (el) {
        function checkValue (event) {
          if (event.charCode >= 48 && event.charCode <= 57) {
            event.preventDefault()
          }
          return true
        }
        el.addEventListener('keypress', checkValue)
      }
    }
  },
  data () {
    return {
      contratoAdjunto: null,

      //listas
      tipoPortafoliosList: [
        "Si",
        "No",
      ],
      
      //Validadores
      inputsRequeridos: [
        value => !!value || 'Campo Requerido',
      ],
    }
  },
  props: ['avisar', 'infoEmision'],
  methods: {
    avisarCambio(){
      let datos = {
        contratoAdjunto: this.contratoAdjunto,
      }
      this.avisar(datos)
    }
  },
  mounted() {
    this.contratoAdjunto = this.infoEmision.contratoAdjunto
  }
}
</script>