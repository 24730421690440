<template>
  <v-dialog v-model="dialog" persistent width="1600">
    <common-card>
      <v-card-text class="pa-4 black--text" style="text-align: center;">
        <v-row>
          <v-col cols="8">
            <div class="pt-5 pb-5 top-text" style="width: 100%; height: 100%;">
              Llamada Inbound
            </div>
          </v-col>
          <v-col cols="4" style="text-align: end; ">
            <v-btn icon dark @click="$emit('closeModal')">
              <v-icon color="black">mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <div>
          <v-row>
            <v-col cols="12">
              <ExpansionCallData
                :currentPanel="openFirstStep"
                @noNeedShowClienteData="noNeedShowClienteData = true"
                @needShowClienteData="noNeedShowClienteData = false"
                @nextStep="nextStep"
                @goToSaveLlamadaCortada="goToSaveLlamadaCortada"
              ></ExpansionCallData>
            </v-col>
            <v-col cols="12" v-if="!noNeedShowClienteData">
              <ExpansionClientData
                :currentPanel="openSecoundStep"
                :cliente="cliente"
                @goBack="goBack"
                @goToSave="saveClienteData"
              ></ExpansionClientData>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </common-card>
  </v-dialog>
</template>

<script>
import CommonCard from "../CommonCard.vue";
import ExpansionCallData from "./ExpansionCallData.vue";
import ExpansionClientData from "./ExpansionClientData.vue";
import { createLlamadaInbound } from "@/services/campañasInbound/CampañasInbound.js";
import { createClientePost } from "@/services/cliente/Clientes.js";
import { createSolicitud } from "@/services/solicitudes/Solicitudes.js";
import { getCampañasClientes } from "@/services/cliente/CampañasClientes.js";
import { getEmpresasInbound } from "@/services/empresasInbound/EmpresasInbound.js";
import moment from "moment";

export default {
  components: {
    CommonCard,
    ExpansionCallData,
    ExpansionClientData,
  },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    cliente: {
      type: Array,
      default: () => [],
    },
    createCliente: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      data: {},
      openFirstStep: 0,
      openSecoundStep: 1,
      callDataBody: {},
      clienteData: {},
      usuarioResponse: {},
      needSolicitud: false,
      campañaCliente: [],
      productosList: [],
      empresas: [],
      noNeedShowClienteData: false,
    };
  },
  methods: {
    nextStep(callData) {
      this.callDataBody = callData;
      this.needSolicitud = this.callDataBody.atendioLlamada;
      this.openFirstStep = 1;
      this.openSecoundStep = 0;
    },
    goBack() {
      this.openFirstStep = 0;
      this.openSecoundStep = 1;
    },
    saveClienteData(clienteData) {
      this.clienteData = clienteData;
      this.goToSave();
    },
    async goToSave() {
      if (this.clienteData.cliente_id) {
        if (this.needSolicitud == "true") {
          this.createLlamada();
        } else this.createsolicitud();
      } else this.createClienteMethod();
    },
    async createClienteMethod() {
      let request = {
        status: 0,
        regimen: this.clienteData.regimen,
        nombres: this.clienteData.nombre,
        apellidoPaterno: this.clienteData.apellidoPaterno,
        apellidoMaterno: this.clienteData.apellidoMaterno,
        genero: this.clienteData.genero,
        fechaNacimiento: this.clienteData.fechaNacimiento
          ? moment(this.clienteData.fechaNacimiento, "DD/MM/YYYY").format(
              "YYYY-MM-DD"
            )
          : null,
        estadoCivil: this.clienteData.estadoCivil,
        nombreEmpresa: null,
        razonSocial: null,
        fechaConstitucion: null,
        rfc: this.clienteData.rfc,
        correoElectronico: this.clienteData.correo,
        celular: this.clienteData.telefono,
        telefonoOficina: this.clienteData.telefono,
        agenteId: 700,
        direccionFacturacionId: "",
        fechaCreacion: null,
        creadoPor: localStorage.getItem("agenteUuid"),
        modificadoPor: localStorage.getItem("agenteUuid"),
      };
      this.usuarioResponse = await createClientePost(request);
      this.clienteData.usuario = localStorage.getItem("id") ?? "";
      this.clienteData.globalInteractionId =
        JSON.parse(localStorage.getItem("jsonLlamada")).global_id ?? "";
      this.clienteData.cliente_uuid = this.usuarioResponse.uuid ?? "";
      this.clienteData.cliente_id = this.usuarioResponse.id ?? "";
      this.goToSave();
    },
    async createLlamada() {
      const respo = await createLlamadaInbound({
        ...this.clienteData,
        ...this.callDataBody,
      });
      if (respo.id) {
        this.$toast.success("Se ha creado registro de llamada", {
          position: "top-right",
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
        if (this.clienteData.cliente_uuid != "") {
          this.$router.push(`/cliente-detalle/${this.usuarioResponse.uuid}`);
        } else {
          this.$emit("closeModal");
        }
        this.$emit("success");
      } else {
        this.$toast.error("Ups Ha ocurrido un error", {
          position: "top-right",
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }
    },
    async createsolicitud() {
      this.loading = true;
      const request = {
        //Datos generales de la solicitud
        agente: parseInt(this.getAgenteIdbyEmpresa()),
        agenteId: parseInt(this.getAgenteIdbyEmpresa()),
        idOrigenLead: 1,
        inicio: moment().format("YYYY-MM-DD"),
        fin: moment()
          .add(1, "year")
          .format("YYYY-MM-DD"),
        inicioVigencia: moment().format("YYYY-MM-DD"),
        finVigencia: moment()
          .add(1, "year")
          .format("YYYY-MM-DD"),
        anios: "1",
        fechaPrimerPago: moment().format("YYYY-MM-DD"),

        //Que tengo que tener en cuenta de la llamada para determinar estos campos
        campania: "Inboud" ?? "", //Sobre que campo de la llamada lo coloco
        campania_id: this.getCampaniaId(), //Sobre que campo de la llamada lo coloco
        categoriaSelected: this.getCampaniaRamo(), // ramo al que pertenece
        tipoSelected: "Otros", //Otros
        detalle: this.callDataBody.detalle ?? "", //¿Le pongo el mismo detalle de la llamada?
        //Fin datos generales

        //Datos del cliente
        nombres: this.clienteData.nombre ?? "",
        apellidoPaterno: this.clienteData.apellidoPaterno ?? "",
        apellidoMaterno: this.clienteData.apellidoMaterno ?? "",
        fechaNacimiento: this.clienteData.fechaNacimiento
          ? moment(this.clienteData.fechaNacimiento, "DD/MM/YYYY").format(
              "YYYY-MM-DD"
            )
          : null,
        edad: this.clienteData.edad ?? "",
        genero: this.clienteData.genero ?? "",
        correoElectronico: this.clienteData.correo ?? "",
        celular: this.clienteData.telefono ?? "",
        estadoCivilSelected: this.clienteData.estadoCivil ?? "",
        rfc: this.clienteData.rfc ?? "",
        codigoPostal: this.clienteData.codigoPostal ?? "",
        estadoRepublicaSelected: this.clienteData.estado ?? "",
        municipio: this.clienteData.ciudad ?? "",
        calle: this.clienteData.calle ?? "",
        numExterior: this.clienteData.num_exterior ?? "",
        numInterior: this.clienteData.num_interior ?? "",
        colonia: this.clienteData.colonia ?? "",
        nombre_cliente: "",
        regimen_persona: this.clienteData.regimen ?? "",
        //fin datos del cliente

        //Keys que manejan estatus o datos menores que no tenemos
        origenSolicitudSelected: "Llamada telefónica entrada",
        comercial_asignado: null,
        actividadSolicitud: "Otros",
        archivos: null,
        razon_social: null,
        numeroPoliza: this.callDataBody.poliza ?? "",
        solicitudCompletaSelected: false,
        checkPendienteInfo: false,
        checkCancelacion: false,
        causaPendienteInformacion: null,
        dateFormattedInicioVigencia: null,
        menufechasParaCotizar: false,
        polizaRenovar: null,
        fechaInicioVigenciaRenovar: null,
        fechaFinVigenciaRenovar: null,
        operadorUuid: null,
        //Fin Keys que manejan estatus o datos menores que no tenemos

        //No se llena se manda como null
        producto_id: "",
        aseguradora_id: this.callDataBody.aseguradora ?? "",
        productoId: "",
        esInbound: true,
        empresa_id: this.getEmpresaId(),
        cliente_id: this.clienteData.cliente_id ?? "",
        isTicketLlamada: true,
        ...this.callDataBody,
      };
      this.solicitudInfo = await createSolicitud(request);
      this.callDataBody.solicitud_id = this.solicitudInfo.id;
      this.needSolicitud = "true";
      this.goToSave();
    },
    getCampaniaId() {
      return this.campañaCliente.find((e) => e.medio == "Inboud").id;
    },
    getCampaniaRamo() {
      return this.campañaCliente.find((e) => e.medio == "Inboud").ramo;
    },
    getEmpresaId() {
      return this.callDataBody.empresa
        ? this.empresas.find((e) => e.empresa == this.callDataBody.empresa).id
        : 0;
    },
    getAgenteIdbyEmpresa() {
      if (this.callDataBody.empresa) {
        let empresa = this.empresas.find(
          (e) => e.empresa == this.callDataBody.empresa
        );
        return empresa.agente_id ? empresa.agente_id : 700;
      }
      return 700;
    },
    goToSaveLlamadaCortada(callData) {
      this.callDataBody = callData;
      if (this.cliente.length > 0) {
        let data = {
          nombre: "",
          apellidoMaterno: "",
          apellidoPaterno: "",
          regimen: "",
          rfc: "",
          telefono: "",
          fechaNacimiento: "",
          genero: "",
          correo: "",
          codigoPostal: "",
          calle: "",
          num_exterior: "",
          num_interior: "",
          colonia: "",
          ciudad: "",
          estado: "",
          usuario: "",
          globalInteractionId: "",
          cliente_uuid: "",
        };
        var keys = Object.keys(data);
        keys.map((e) => {
          if (typeof this.cliente[0][e] != "undefined") {
            this.clienteData[e] = this.cliente[0][e];
          }
        });
        this.clienteData.cliente_uuid = this.cliente[0].uuid ?? "";
        this.clienteData.cliente_id = this.cliente[0].id ?? "";
      }
      this.clienteData.telefono =
        JSON.parse(localStorage.getItem("jsonLlamada")).numero ?? "";
      this.clienteData.usuario = localStorage.getItem("id") ?? "";
      this.clienteData.globalInteractionId =
        JSON.parse(localStorage.getItem("jsonLlamada")).global_id ?? "";
      this.clienteData.cliente_uuid = "";
      this.createLlamada();
    },
  },
  async mounted() {
    this.campañaCliente = await getCampañasClientes();
    const campaña = await getEmpresasInbound();
    this.empresas = campaña.data.empresas;
  },
};
</script>

<style>
@import "../../../css/global-styles.css";

.v-toolbar--dense .v-toolbar__content,
.v-toolbar--dense .v-toolbar__extension {
  padding-top: 0;
  padding-bottom: 0;
  justify-content: center;
}

.top-text {
  width: 369px;
  height: 22px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 110%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #4d5358;
  flex: none;
  order: 0;
  flex-grow: 1;
}

.confirmation-text {
  /* Por favor, asegúrate de revisar cuidadosamente los cambios antes de continuar */

  width: 369px;
  height: 30px;

  /* Subtitle/S */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 110%;
  /* or 15px */
  text-align: center;

  color: #555555;

  /* Inside auto layout */
  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
}
.v-input--selection-controls {
  margin-top: 0px !important;
  padding-top: 0px !important;
}
</style>
