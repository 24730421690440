<template>
    <v-bottom-sheet id="embedded-botton" v-model="sheet" hide-overlay width="350px" persistent scrollable round>
        <template v-slot:activator="{ on, attrs }">
            <v-btn color="success" dark v-bind="attrs" v-on="on" class="embedded">
                Call center
            </v-btn>
        </template>

        <v-sheet height="420px" color="#133B7D" class="pl-2 pr-2" style="border-radius: 6px;" flat>
            <v-card height="410" class="" round flat>
                <v-toolbar flat color="#133B7D" dark>
                    <v-toolbar-title class="text-center">Call it Once</v-toolbar-title>
                    <v-spacer />
                    <v-btn icon @click="sheet = !sheet">
                        <v-icon>mdi-chevron-down</v-icon>
                    </v-btn>
                </v-toolbar>

                <v-card-text>
                    <v-row class="mt-1 mr-5" justify="space-around">
                        <v-col cols="1">
                            <v-btn id="botton-call" fab small color="success" @click="callNumber"
                                :disabled="llamar || this.selectPhone == null">
                                <v-icon dark>mdi-phone</v-icon>
                            </v-btn>
                        </v-col>

                        <v-col cols="1">
                            <v-btn fab small color="red" @click="stopCall" :disabled="!colgar">
                                <v-icon color="white">mdi-phone-hangup</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>

                    <v-form ref="formDisposicion">
                        <v-row>
                            <v-col cols="12">
                                <v-autocomplete label="Tipos de contacto" clearable :items="TiposContactos" hide-details
                                    dense v-model="tipo_contacto" :rules="[$rules.required]" :disabled="!llamar">
                                </v-autocomplete>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="12">
                                <v-autocomplete label="Disposición" clearable :items="disposicionesAux" hide-details
                                    dense v-model="disposicion" :disabled="tipo_contacto == null"
                                    item-text="disposicion" item-value="id" :rules="[$rules.required]">
                                </v-autocomplete>
                            </v-col>
                        </v-row>

                        <v-row class="mt-2 mr-4" justify="space-around" align="center">
                            <v-col cols="1">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn icon  v-bind="attrs" v-on="on">
                                            <v-icon dark x-large>mdi-calendar-month</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Agendar llamada</span>
                                </v-tooltip>
                            </v-col>
                        </v-row>
                        <v-row v-if="registrarAgenda">
                            <v-col cols="12">
                                <v-text-field v-model="dateAgenda" label="Fecha de agenda" type="datetime-local"
                                    hide-details :rules="[$rules.required]" :max="nextMonth()" :min="today()">
                                    <template #prepend>
                                        <v-btn icon @click="cancelAgenda">
                                            <v-icon>mdi-close-circle-outline</v-icon>
                                        </v-btn>
                                    </template>
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>

                <template v-if="isSolicitud">
                    <v-card-actions class="ml-2 dd-flex justify-center text-center">
                        <v-btn dark color="primary" @click="openModal" small :disabled="validarLead">
                            Crear solicitud
                        </v-btn>
                    </v-card-actions>
                </template>
                <template v-if="isVenta">
                    <v-card-actions class="ml-2 dd-flex justify-center text-center">
                        <v-btn  v-if="roles == 'AGENTCC' || 
                                      roles == 'AGENTCCSPONSORS' ||  
                                      roles == 'OPERADOR' || 
                                      roles == 'OPERADORCC' || 
                                      roles == 'ADMIN' || 
                                      roles == 'SOPORTETI' || 
                                      roles == 'RENOVACIONESCC'"
                                dark 
                                color="primary" 
                                @click="openPolizaModal" 
                                small 
                                :disabled="validarLead">
                            Crear Póliza
                        </v-btn>
                    </v-card-actions>
                </template>
            </v-card>
        </v-sheet>
    </v-bottom-sheet>
</template>

<script>
import { loadScript } from "vue-plugin-load-script";
import { mainAxios } from "../../../mainAxios";
var moment = require('moment-timezone');

//import Incoming from "~/assets/phone-incoming-outgoing.svg";
export default {
    name: "Embedded",
    components: {
    },
    props: {
        leadOrigen: {
            type: Object,
             require: true,
         },
         lead: {
            type: Object,
             require: true,
         },
    },
    data() {
        return {
            dialog: false,
            isSolicitud: false, // verifico si la disposicion es solicitud
            isVenta: false, // verifico si la disposicion es Venta
            registrarAgenda: false,  // registra la llamada para una agenda
            isConference: false,
            loading: false,
            loadingBotton: false,
            sendMessage: false, // active if send global_id from poliza
            // icon
           // icon: Incoming,
            // listas variable
            TiposContactos: [
                'Contacto Efectivo',
                'Contacto no efectivo'
            ],
            disposiciones: [],
            disposicionesAux: [],
            polizas: [], // lista de las polizas
            // config dialog embedded // pasar en otro archivo secundario
            sheet: false,
            llamar: false,
            colgar: false,
            mute: false,
            callInfo: null,

            // var user
            calendar: false,
            dateFormatted: null,
           
            // var to agend call
            dateAgenda: null,
            ListNumber: [],
            selectPhone: null,

            // disposicion
            tipo_contacto: null,
            disposicion: null,
            poliza: null,
            global_id: null,
            is_emitido: false,
            servicio_embebido: '',
            roles: JSON.parse(localStorage.getItem("roles"))[0].rol,
            fechaPrimerLlamada: '',
            timeoutId:{},
            leadOrigenData:{
                status:''
            }
        };
    },
    async mounted() {
        this.$emit("successEmbedded")
        this.setCallData()
        this.getDisposiciones()
        loadScript("https://aligev55.zw-callitonce.alestra.net.mx/agentdesktop/libs/servicepattern-sdk-v1.js").then(() => {
            window.bpspat.api.init("https://aligev55.zw-callitonce.alestra.net.mx");            
        }).catch(() => {
            console.error(
                "Fallo al carga el sdk, Refresque la pagina para realizar la llamada"
            );
        });
        setTimeout(() => (
            this.checkStatus())
        , 2000)
    },
    watch:{
        tipo_contacto(val) {
            if (val == null) {
                this.colgar = false;
                this.disposicion = null;
            }
            this.filteDisposicion();
        },
        disposicion(val){
            const disposicionFound = this.disposiciones.find(e => e.id == val);
            const esVenta = disposicionFound && disposicionFound.disposicion == "Venta";

            const agendarLlamada = disposicionFound && disposicionFound.disposicion.includes("Agendar Llamada")
            const esSolicitud = disposicionFound && disposicionFound.disposicion.includes("Solicitud")

            if(esVenta){
                this.isVenta = true
                this.isSolicitud = false
                this.checarLeadIncomplete()
            }
            if(esSolicitud){
                this.isSolicitud = true
                this.isVenta = false
                this.checarLeadIncomplete()
            }else{
                this.isSolicitud = false
            }
            if(agendarLlamada){
                this.registrarAgenda = true;
            }else{
                this.registrarAgenda = false;
                this.dateAgenda = null
            }
            if(val != null || val == ''){
                this.colgar = true 
            }else{
                this.colgar = false
            }
        },
        dateAgenda() {
            this.dateFormatted = this.formatDate(this.dateAgenda)
        },
        lead(){
            this.checarLeadIncomplete()
        },
        llamar(val){
            if(val)
                this.getOrigen()
            }
    },
    
    methods: {
        cancelAgenda() {
            this.tipo_contacto = null;
            this.disposicion = null;
            this.registrarAgenda = false;
        },
        setCallData(){
            this.selectPhone = this.leadOrigen.telefono
            this.servicio_embebido = this.leadOrigen.servicio_embebido
        },
        formatDate(date) {
            if (!date) {
                this.colgar = false;
                return null
            }
            const [year, month, day] = date.split('-')
            return `${day}/${month}/${year}`
        },
        getDisposiciones(){
            const config = {
                    headers: {
                    Authorization: "Bearer " + localStorage.agenteAccessToken,
                    }
                }
                mainAxios.get('/v1/disposiciones/lead-segurify/list/'+ this.leadOrigen.id, config).then(response => {
                    response.data.map(element => {
                        this.disposiciones.push({
                            id: element.id,
                            disposicion: element.disposicion,
                            tipo_contacto: element.tipoContacto
                    });
                }) 
            })    
        },
        saveDisposition(){
            const config = {
                    headers: {
                    Authorization: "Bearer " + localStorage.agenteAccessToken,
                    }
                }
            const disposicionName = this.disposicionesAux.filter(item => item.id == this.disposicion);
            const  request = {
                "leadUuid": this.leadOrigen.uuid_lead_segurify,
                "origen_uuid": this.leadOrigen.uuid,
                "disposicion": disposicionName[0].disposicion,
                "codigo_disposicion": String(this.disposicion),
                "fecha_agenda": this.dateAgenda ? moment.utc(this.dateAgenda).format('YYYY-MM-DD HH:mm:ss') : null,
                "tipo_llamada": 'Outbound',
                "globalInteractionId": this.globalId,
                "fechaPrimerLlamada": true,
                "callback": 0,
                "marcacion_automatica":this.leadOrigen.marcacion_automatica ?? false
            }
            if(this.leadOrigenData.status != 'Nuevo')
                delete request.fechaPrimerLlamada
            
            mainAxios.post('/v1/accion/lead-segurify/crear', request, config).then(() => {
                this.$emit('getLlamadas')
                this.tipo_contacto = null
                this.disposicion = null
                this.timerOut()
             })    
        },
        filteDisposicion() {
            this.disposicionesAux = this.disposiciones.filter(item => item.tipo_contacto == this.tipo_contacto);
        },
        callNumber() {
            this.llamar = true;
            window.bpspat.api.selectService(this.servicio_embebido);
            //this.selectPhone = '4492344656';
            window.bpspat.api.dialNumber(this.selectPhone);
            window.bpspat.api.addInteractionRenderedHandler(this.logs);
            window.bpspat.api.addInteractionCompletedHandler(this.logs);
        },
        stopCall() {
            window.bpspat.api.getState(({ data }) => {
                let ivr = data.interactions.find(item => item.phone_number == 4545);
                if (ivr) {
                    console.log('entre aqui')
                    window.bpspat.api.completeInteractionWithDisp(ivr.item_id, String('99999999'));
                }
                let interaccion = data.interactions.find(item => item.phone_number == this.selectPhone);
                console.log(interaccion.item_id, String('99999999'), "")
                window.bpspat.api.completeInteractionWithDisp(interaccion.item_id, String('99999999') , ""); // se termina la llamada con el envio de la disposicion
            });
            //Aqui guardamos todo para cuando se cuelga
            this.colgar = false;
            this.llamar = false;
            this.saveDisposition()
            this.sheet = !this.sheet
        },
        openModal(){
            this.$emit('openSolicitudModal')
        },
        openPolizaModal(){
            this.$emit('openPolizasModal')
        },
        logs(state){
            this.globalId = state.data.global_id;
        },
        statusChecked(state){
            this.$emit("successEmbedded")
            console.log(state);
            if(this.leadOrigen.marcacion_automatica){
                const btn_embedded = document.getElementsByClassName('embedded v-btn v-btn--is-elevated v-btn--has-bg theme--dark v-size--default success');
                btn_embedded[0].click()
                this.callNumber()
                this.postActualizacionMomentaneo()
            }
        },
        checkStatus(){
            window.bpspat.api.getState(this.statusChecked)
        },
        checarLeadIncomplete(){
            if(typeof this.lead.medio != 'undefined' &&
               typeof this.lead.producto != 'undefined' &&
               typeof this.lead.regimen != 'undefined' &&
               typeof this.lead.rfc != 'undefined' &&
               typeof this.lead.nombre != 'undefined' &&
               typeof this.lead.apellidoPaterno != 'undefined' &&
               typeof this.lead.apellidoMaterno != 'undefined' &&
               typeof this.lead.fechaNacimiento != 'undefined' &&
               typeof this.lead.sexo != 'undefined' &&
               typeof this.lead.estadoCivil != 'undefined' &&
               typeof this.lead.edad != 'undefined' &&
               typeof this.lead.telefono != 'undefined' &&
               typeof this.lead.correo != 'undefined' &&
               typeof this.lead.calle != 'undefined' &&
               typeof this.lead.num_exterior != 'undefined' &&
               typeof this.lead.num_interior != 'undefined' &&
               typeof this.lead.codigoPostal != 'undefined' &&
               typeof this.lead.colonia != 'undefined' &&
               typeof this.lead.ciudad != 'undefined' &&
               typeof this.lead.estado != 'undefined' &&
               typeof this.lead.pais != 'undefined')
            {
                this.validarLead = false
            }else{
                this.$emit('leadIncomplete')
                this.validarLead = true
            }
        },
        timerOut(){
            this.timeoutId = setTimeout(() => {
                // Redirigir a la vista después de 3 minutos
                //this.$router.push('/leads');
                console.log("desactivado por bug en prod")
            }, 180000); // 180,000 milisegundos = 3 minutos
        },
        nextMonth() {
            const now = new Date();
            const nextMonth = new Date(now.getFullYear(), now.getMonth() + 1, now.getDate());
            return nextMonth.toISOString().slice(0, 16); // Formato necesario para datetime-local
        },
        today() {
            const now = new Date();
            return now.toISOString().slice(0, 16); // Formato necesario para datetime-local
        },
        actualizarLead() {
            const config = {
                headers: {
                Authorization: "Bearer " + localStorage.agenteAccessToken,
                },
            };
            const enviar = {
                fechaPrimerLlamada: true,
            };
            mainAxios.post("/v1/origen/actualizar/" + this.leadOrigen.uuid, enviar, config).then((response) => {
                console.log("Se actualizo primera llamada ", response);
            });
        },
        getOrigen() {
            const config = {
                headers: {
                Authorization: "Bearer " + localStorage.agenteAccessToken,
                },
            };
            mainAxios
                .get(`/v1/origen/uuid/${this.leadOrigen.uuid}`, config)
                .then((response) => {
                    this.leadOrigenData = response.data
                    console.log({response})
                    if(this.leadOrigenData.status == 'Nuevo')
                    this.actualizarLead()
                })  
            .catch(() => {
                this.loading = false;
                this.error = "Error inesperado";
            });
        },
    },

    beforeDestroy() {
    // Cancelar el temporizador si existe antes de destruir el componente
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  },
};


</script>

<style scoped>
.embedded {
    position: fixed;
    bottom: 60px;
    right: 0;
}
.v-dialog__content {
    justify-content: end !important;
}
</style>
