<template>
  <v-app>
    <Navigation></Navigation>
    <v-main class="pa-5">
      <v-container >
        <v-row class="mb-10" dense align="center">
          <v-col cols="2">
            <v-img
              :max-height="width_log2"
              :max-width="height_log2"
              :src="logo_2"
            ></v-img>
          </v-col>
          <v-col cols="8" style="text-align: center;">
            <h2 class="ml-5">Bienvenidos a Inter.mx - Segurify</h2>
          </v-col>
          <v-col cols="2">
            <v-img
              :max-height="height_log1"
              :max-width="width_log1"
              :src="logo_1"
            ></v-img>
          </v-col>
          <v-spacer />
        </v-row>
        <v-row>
          <!-- <v-col cols="12" md="12" sm="12">
            <v-row >
              <v-col xl="12" md="12" sm="12">
                <v-carousel 
                  v-model="banner" 
                  class="mt-3"
                  height="190"
                  cycle
                  interval="4000"
                  show-arrows-on-hover
                  hide-delimiter-background
                  vertical-delimiters
                >
                  <v-carousel-item v-for="(banner) in banners" :key="banner.id">
                    <v-sheet height="100%" tile>
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center"
                      >
                        <img :src="'img/bannersHome/'+banner.url" class="banner-home" alt="Promociones Segurify">
                      </v-row>
                    </v-sheet>
                  </v-carousel-item>
                </v-carousel>
              </v-col>
            </v-row>
          </v-col> -->
          
          <v-col cols="12" v-if="rol != 'AGENTPARTNER'"  style="margin-top: 3%;">          
            <v-row >
              <!-- comparador -->
              <v-col xl="2" md="4" sm="6" class="d-none d-sm-block" v-if="rol!='AGENTCCSPONSORS'">
                <v-card
                  class="cardsHome d-flex flex-column justify-space-between"
                  height="100%"
                >
                  <v-card-title>
                    <v-icon
                      large
                      left
                    >
                      mdi-note-text-outline
                    </v-icon>
                    <span class="text-subtitle-2 font-weight-light">Hub Cotizaciones</span>
                  </v-card-title>

                  <v-card-text class="text-h5 font-weight-bold">
                    Cotizaciones de seguros para tus clientes
                  </v-card-text>

                  <v-card-actions class="cardAccionHome">
                    <v-list-item class="grow justify-end">
                      <v-btn
                        rounded
                        color="info"
                        @click="verCotizaciones()"
                      >
                        Cotizadores
                      </v-btn>
                    </v-list-item>
                  </v-card-actions>
                </v-card>
              </v-col>

              <!-- comparador -->
              <v-col cols="12" class="d-block d-sm-none">
                <v-card
                  @click="verCotizaciones()"
                  class="cardsHome cursor"
                  height="100%"
                >
                  <v-card-title class="d-flex justify-space-between">
                    <v-icon
                      large
                      left
                    >
                      mdi-car-multiple
                    </v-icon>
                    <span class="text-subtitle-2 font-weight-light">Hub Comparador</span>
                    <v-btn
                      class="mx-2"
                      fab
                      dark
                      small
                      color="indigo"
                    >
                      <v-icon dark>
                        mdi-eye
                      </v-icon>
                    </v-btn>
                  </v-card-title>
                </v-card>
              </v-col>
              
              <!-- solicitudes -->
              <v-col xl="2" md="4" sm="6" xs="12" v-if="rol != 'AGENTCC' && rol != 'ADMINCC' && rol != 'COBRANZACC' && rol != 'MANAGERCC' && rol != 'RENOVACIONESCC' && rol != 'AGENTCCSPONSORS'">
                <v-card
                  class="cardsHome d-flex flex-column justify-space-between"
                  height="100%"
                >
                  <v-card-title>
                    <v-icon
                      large
                      left
                    >
                      mdi-account-hard-hat
                    </v-icon>
                    <span class="text-subtitle-2 font-weight-light">Solicitudes</span>
                  </v-card-title>

                  <v-card-text class="text-h5 font-weight-bold">
                    Administracion de Solicitudes
                  </v-card-text>

                  <v-card-actions class="cardAccionHome">
                    <v-list-item class="grow justify-end">
                      <v-btn
                        rounded
                        color="info"
                        @click="verSolicitudes()"
                        class="text-button"
                      >
                        Mas Info
                      </v-btn>
                    </v-list-item>
                  </v-card-actions>
                </v-card>
              </v-col>

              <!-- polizas -->
              <v-col xl="2" md="4" sm="6" xs="12" v-if="rol != 'AGENT' || rol != 'AGENTCC'">
                <v-card
                  class="cardsHome d-flex flex-column justify-space-between"
                  height="100%"
                >
                  <v-card-title>
                    <v-icon
                      large
                      left
                    >
                      mdi-file-sign
                    </v-icon>
                    <span class="text-subtitle-2 font-weight-light">Pólizas</span>
                  </v-card-title>

                  <v-card-text class="text-h5 font-weight-bold">
                    Administracion de Pólizas
                  </v-card-text>

                  <v-card-actions class="cardAccionHome">
                    <v-list-item class="grow justify-end">
                      <v-btn
                        rounded
                        color="info"
                        @click="verPolizas()"
                        class="text-button"
                      >
                        Mas Info
                      </v-btn>
                    </v-list-item>
                  </v-card-actions>
                </v-card>
              </v-col>

              <!-- cuotas -->
              <v-col xl="2" md="4" sm="6" xs="12" v-if="rol == 'ADMIN' || rol == 'FINANZAS' ">
                <v-card
                  class="cardsHome d-flex flex-column justify-space-between"
                  height="100%"
                >
                  <v-card-title>
                    <v-icon
                      large
                      left
                    >
                      mdi-cash-multiple
                    </v-icon>
                    <span class="text-subtitle-2 font-weight-light">Cuotas</span>
                  </v-card-title>

                  <v-card-text class="text-h5 font-weight-bold">
                    Administracion de Cuotas
                  </v-card-text>

                  <v-card-actions class="cardAccionHome">
                    <v-list-item class="grow justify-end">
                      <v-btn
                        rounded
                        color="info"
                        @click="verCuotas()"
                        class="text-button"
                      >
                        Mas Info
                      </v-btn>
                    </v-list-item>
                  </v-card-actions>
                </v-card>
              </v-col>

              <!-- comisiones -->
              <v-col xl="2" md="4" sm="6" xs="12" v-if="mostrarCardComision">
                <v-card
                  class="cardsHome d-flex flex-column justify-space-between"
                  height="100%"
                >
                  <v-card-title>
                    <v-icon
                      large
                      left
                    >
                      mdi-account-cash
                    </v-icon>
                    <span class="text-subtitle-2 font-weight-light">Comisiones</span>
                  </v-card-title>

                  <v-card-text class="text-h5 font-weight-bold">
                    Administracion de Comisiones
                  </v-card-text>

                  <v-card-actions class="cardAccionHome">
                    <v-list-item class="grow justify-end">
                      <v-btn
                        rounded
                        color="info"
                        @click="verComisiones()"
                        class="text-button"
                      >
                        Mas Info
                      </v-btn>
                    </v-list-item>
                  </v-card-actions>
                </v-card>
              </v-col>

              <!-- clientes -->
              <v-col xl="2" md="4" sm="6" xs="12" v-if="(rol != 'AGENT' || rol != 'AGENTCC') &&  rol != 'AGENTCCSPONSORS'">
                <v-card
                  class="cardsHome d-flex flex-column justify-space-between"
                  height="100%"
                >
                  <v-card-title>
                    <v-icon
                      large
                      left
                    >
                      mdi-account-box-multiple
                    </v-icon>
                    <span class="text-subtitle-2 font-weight-light">Clientes</span>
                  </v-card-title>

                  <v-card-text class="text-h5 font-weight-bold">
                    Administracion de Clientes
                  </v-card-text>

                  <v-card-actions class="cardAccionHome">
                    <v-list-item class="grow justify-end">
                      <v-btn
                        rounded
                        color="info"
                        @click="verClientes()"
                        class="text-button"
                      >
                        Mas Info
                      </v-btn>
                    </v-list-item>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <v-footer style="text-align:center;">
      <p style="text-align:center;width:100%; margin: 10px 0px">Segurify {{ year }} <a target="_blank" href="/terminos-y-condiciones">Terminos y condiciones</a> y <a target="_blank" href="/aviso-de-privacidad">Aviso de privacidad</a>  </p>
    </v-footer>
  </v-app>
</template>

<script>
const moment = require('moment');
import Navigation from "../../components/Nav.vue";
import LogoSegurify from "@/assets/logo_segurify.png";
import LogoBradescard from "@/assets/logo-inter-horizontal.png";
export default {
  components: {
    Navigation,
  },
  data() {
    return {
      banner: 0,
      rol: JSON.parse(localStorage.getItem("roles"))[0].rol,
      banners: [
        //New banners
          { id: 1, url: 'segurify_banner_portalagentes_2450x400_ene2023_060123_AIG.jpg' },
          { id: 2, url: 'segurify_banner_portalagentes_2450x400_ene2023_060123_GNP.jpg' },
          { id: 3, url: 'segurify_banner_portalagentes_2450x400_ene2023_060123_HDI.jpg' },
          { id: 4, url: 'segurify_banner_portalagentes_2450x400_ene2023_060123_qualitas.jpg' },


        //{ id: 1, url: 'segurify_banner_portalagentes_2450x400_190522_gnp2.jpg' },
        //{ id: 2, url: 'segurify_banner_portalagentes_2450x400_190522_aig.jpg' },
        //{ id: 3, url: 'segurify_banner_portalagentes_2450x400_190522_qualitas.jpg' },
        // { id: 1, url: 'SEGURIFY_banner_2449x500_PortalAgentes_031221.jpeg' },
        // { id: 2, url: 'SEGURIFY_banner_2449x500_BuenFin_051121_Qualitas (2).jpeg' },
        //{id: 1, url: 'SEGURIFY_banner_2449x500_BlackFriday_171121_GNP.png'},
        // {id: 1, url: 'SEGURIFY_banner_2449x500_BuenFin_091121_GNP.jpg'},
        //{id: 4, url: 'SEGURIFY_PortalAgentes_001_bienvenida.jpg'},
        //{id: 5, url: 'SEGURIFY_PortalAgentes_002_proceso.jpg'},
        //{id: 6, url: 'SEGURIFY_PortalAgentes_003_control.jpg'},
        // {id: 7, url: 'SEGURIFY_promo_AIG_05:07:21.jpg' },
      ],
      icons: ["mdi-rewind", "mdi-play", "mdi-fast-forward"],
      items: [
        {
          title: "Cotiza",
          text: "Cotiza con nuestras aseguradoras",
          route:"cotiza-hub"
        },
        {
          title: "Mis Emisiones",
          text: "Aquí guardaremos el detalle de tus cotizaciones para que lleves un mejor control de tus ventas de auto.",
          route:"mis-emisiones"
        },
        {
          title: "Mis Cotizaciones",
          text: "¿Deseas retomar una cotización? Desde aquí puedes hacerlo.",
          route:"mis-cotizaciones"
        },
        // {
        //   title: "Solicitudes",
        //   text: "¿Tienes alguna solicitud para nuestro equipo de operaciones? Desde aquí puedes hacerlo.",
        //   route:"solicitudes"
        // },
      ],
      transparent: "rgba(255, 255, 255, 0)",
      value: [
        423,
        446,
        675,
        510,
        590,
        610,
        760,
      ],
      logo_1: LogoSegurify,
      width_log1: 80,
      height_log1: 80,

      logo_2: LogoBradescard,
      width_log2: 80,
      height_log2: 250,
      year:moment().year()

    };
  },
  methods: {
    verCotizaciones() {
      this.$router.push('/cotizacion-hub-seguros')
      // this.$router.push('/cotiza-hub')
    },
    verPolizas() {
      this.$router.push('/mis-emisiones')
    },
    verSolicitudes() {
      this.$router.push('/solicitudes')
    },
    verComisiones() {
      this.$router.push('/lista-comisiones')
    },
    verCuotas() {
      this.$router.push('/cuotas-hub')
    },
    verClientes() {
      this.$router.push('/lista-clientes')
    },
    verLandingCelular() {
      this.$router.push('/landing-celular')
    },
  },
  computed: {
    mostrarCardComision(){
      if (this.rol != 'AGENT' || this.rol != 'AGENTCC' || this.rol != 'MANAGERCC' || this.rol != 'ADMINCC') {
        return false
      } else {
        return true
      }
    }
  },
  mounted() {
    console.log(localStorage)
  }
};
</script>
<style scoped>
.banner-home {
  width: 100%;
}
.card {
  display: block;
  top: 0px;
  position: relative;
  max-width: 262px;
  background-color: white;
  border-radius: 15px;
  padding: 32px 24px;
  margin: 12px;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;
  border: 1px solid black;
  cursor: pointer;
}
.card:hover {
  transition: all 0.2s ease-out;
  box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
  top: -4px;
  border: 1px solid black;
  background-color: rgba(21, 101, 192, 1);
}
.card:hover:before {
  transform: scale(6.15);
}
.show-btns {
  color: rgba(255, 255, 255, 1) !important;
}

.cardsHome:hover{
  border: 1px solid #133B7D;
}
.cardsHome{
  border: 1px solid transparent;
}
.cardsHome:hover .cardAccionHome {
  background: #133B7D;
}

.cursor{
  cursor: pointer;
}
</style>
