<template>
  <v-app>
    <Navigation></Navigation>
    <v-main>
      <v-container>
        <h1>Mis cotizaciones autos</h1>
        <v-data-table
          :headers="headers"
          :items="cotizaciones"
          item-key="numeroCotizacion"
          class="elevation-1 mt-3"
          :search="search"
          :loading="cargando"
          loading-text="Cargando cotizaciones... Por favor espera"
        >  
          <template v-slot:item.id="{item}">
            <v-btn color="primary" v-on:click="$router.push('/cotizacion/'+item.numeroCotizacion)">Ver Cotización</v-btn>
          </template>  
        </v-data-table>
      </v-container>
    </v-main>
    <v-footer fixed style="text-align:center;">
      <p  style="text-align:center;width:100%;">Segurify 2022</p>
    </v-footer>
  </v-app>
</template>

<script>
import Navigation from "../../components/Nav.vue";
import {mainAxios} from "../../mainAxios";
export default {
  components: {
    Navigation,
  },
    data () {
      return {
        cotizaciones:[],
        search: '',
        calories: '',
        cargando:true,
      }
    },
    computed: {
      headers () {
        return [
          {
            text: 'Codigo',
            align: 'start',
            sortable: false,
            value: 'numeroCotizacion',
          },
          {
            text: 'Vehiculo',
            align: 'start',
            sortable: false,
            value: 'vehiculo',
          },  
          {
            text: 'Cliente',
            align: 'start',
            sortable: false,
            value: 'cliente',
          },                     
          {
            text: 'Aseguradora',
            align: 'start',
            sortable: false,
            value: 'aseguradora',
          },    
          {
            text: 'Acciones',
            align: 'start',
            sortable: false,
            value: 'id',
          },    
        ]
      },
    },
    mounted () {
      this.getCotizaciones();
    },
    methods: {
      getCotizaciones(){
        const config = {
          headers: {
            Authorization: "Bearer " + localStorage.agenteAccessToken,
          },
        };
        mainAxios
          .get("/v1/agente/cotizaciones/guardadas/"+localStorage.agenteUuid, config)
          .then((value) => {
            this.cotizaciones = value.data;
            this.cargando=false;
          });
      },
    },
};
</script>

