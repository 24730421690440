<template>
  <v-form>
    <v-row style="padding: 20px">
      <v-col cols="4">
        <v-select
          label="Tipo de Inmueble"
          placeholder="Requerido"
          :items="tipoInmuebleItems"
          v-model="tipoInmueble"
          outlined
        ></v-select>
      </v-col>
      <v-col cols="4" v-if="tipoInmueble == 'Otro'">
        <v-text-field
          label="Indicar que tipo"
          placeholder="Requerido"
          v-model="otroTipo"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
          label="Valor del inmueble"
          placeholder="Requerido"
          prefix="$"
          v-model="valorInmueble"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="4" v-if="tipoInmueble == 'Departamento'">
        <v-text-field
          label="Nivel del departamento"
          placeholder="Requerido"
          type="number"
          v-model="nivelDepartamento"
          outlined
        ></v-text-field>
      </v-col>

      <v-col cols="4" v-if="tipoInmueble == 'Departamento'">
        <v-text-field
          label="Pisos Totales"
          placeholder="Requerido"
          type="number"
          v-model="pisosTotales"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="4" v-if="tipoInmueble == 'Casa'">
        <v-text-field
          label="Número de pisos altos"
          placeholder="Requerido"
          type="number"
          v-model="pisosAltos"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="4" v-if="tipoInmueble == 'Casa'">
        <v-text-field
          label="Sotanos"
          placeholder="Requerido"
          type="number"
          v-model="sotanos"
          outlined
        ></v-text-field>
      </v-col>
      <!-- <v-col cols="4">
                <v-text-field
                    label="Valor del inmueble"
                    placeholder="Requerido"
                    prefix="$"
                    v-model="valorInmueble"
                ></v-text-field>
            </v-col> -->

      <v-col cols="4">
        <v-text-field
          label="Valor de los contenidos"
          placeholder="Requerido"
          prefix="$"
          v-model="valorContenidos"
          outlined
        ></v-text-field>
      </v-col>

      <v-col cols="4">
        <v-text-field
          label="Valor del equipo electrónico"
          placeholder="Requerido"
          prefix="$"
          v-model="valorEqupioElectronico"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
          label="Suma asegurada Responsabilidad Civil"
          placeholder="Requerido"
          prefix="$"
          v-model="sumaAseguradaRC"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
          label="Suma asegurada Cristales"
          placeholder="Requerido"
          prefix="$"
          v-model="sumaAseguradaCristales"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
          label="Suma asegurada robo con violencia"
          placeholder="Requerido"
          prefix="$"
          v-model="sumaAseguradaRoboConViolencia"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
          label="Suma asegurada dinero y valores"
          placeholder="Requerido"
          prefix="$"
          v-model="sumaAseguradaDineroValores"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
          label="Suma asegurada obras de arte o piezas de difícil reposición"
          placeholder="Requerido"
          prefix="$"
          v-model="sumaAseguradaPiezasDificilReposicion"
          outlined
        ></v-text-field>
      </v-col>

      <!-- ALERT MODAL -->
      <v-col cols="auto">
        <v-dialog
          transition="dialog-bottom-transition"
          max-width="400"
          v-model="dialog"
        >
          <template v-slot:default="dialog">
            <v-card>
              <v-toolbar color="success" dark>Confirmación de envío</v-toolbar>
              <v-card-text>
                <div class="text-h4" style="margin-top: 10px; ">
                  Envio de solicitud exitoso!
                </div>
              </v-card-text>
              <v-card-actions class="justify-end">
                <v-btn text @click="dialog.value = false">Cerrar</v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>
      </v-col>
      <!-- END OF ALERT MODAL -->
    </v-row>

    <v-row>
      <v-col  col="12" class="text-right">
        <v-btn
          color="primary"
          @click="sendSolicitud()"
          :disabled="!comportamientoBotonGuardar()"
        >
          Guardar
        </v-btn>
        <v-btn
          color="primary"
          style="margin-left: 10px"
          v-show="rolToCompare != 'AGENT'"
          @click="$emit('nextStep')"
        >
          Siguiente
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import moment from "moment";
import { mainAxios } from "../../../mainAxios";
export default {
  props: {
    origenSolicitud: String,
    statusSolicitud: String,
    agente: Number,
    tipo: String,
    ramo: String,
    producto: Number,
    detalleSolicitud: String,
    nombres: String,
    apellidoPaterno: String,
    apellidoMaterno: String,
    estadoCivil: String,
    estadoNacimiento: Number,
    genero: String,
    fechaNacimiento: String,
    rfc: String,
    tipoPersona: String,
    telefono: String,
    correo: String,
    codigoPostal: String,
    direccion: String,
    inicioVigencia: String,
    finVigencia: String,
    periodicidad: String,
    formaPago: String,
    operador: Number,
    solicitudCompleta: String,
    procesoCotizacion: String,
    comercialAsignado: Number,
    razonSocial: String,
    leadOrigen: Object,
    newFormProps: Object,
    esDesdeDialog: Boolean,
    numeroPoliza: String,
  },
  data() {
    return {
      rolToCompare: JSON.parse(localStorage.getItem("roles"))[0].rol,
      dialog: false,
      tipoInmueble: null,
      otroTipo: null,
      valorInmueble: null,
      nivelDepartamento: null,
      pisosTotales: null,
      pisosAltos: null,
      sotanos: null,
      valorContenidos: null,
      valorEqupioElectronico: null,
      sumaAseguradaRC: null,
      sumaAseguradaCristales: null,
      sumaAseguradaRoboConViolencia: null,
      sumaAseguradaDineroValores: null,
      sumaAseguradaPiezasDificilReposicion: null,
      tipoInmuebleItems: ["Casa", "Departamento", "Bodega", "Edificio", "Otro"],
    };
  },
  watch: {
    dialog(val) {
      if (!val) return;

      setTimeout(() => (this.dialog = false), 2500);
    },
  },
  methods: {
    sendSolicitud() {
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      let request = {
        /* CAMPOS DE COMPONENTE PADRE */
        origenSolicitudSelected: this.origenSolicitud,
        agenteId: this.agente,
        tipoSelected: this.tipo,
        categoriaSelected: this.ramo,
        productoId: this.producto,
        detalle: this.detalleSolicitud ? this.detalleSolicitud : "",
        nombres: this.nombres,
        apellidoPaterno: this.apellidoPaterno,
        apellidoMaterno: this.apellidoMaterno,
        estadoCivilSelected: this.estadoCivil,
        estadoRepublicaSelected: this.estadoNacimiento,
        genero: this.genero,
        fechaNacimiento: this.fechaNacimiento
          ? moment(this.fechaNacimiento, "DD/MM/YYYY").format("YYYY-MM-DD")
          : null,
        rfc: this.rfc,
        regimen_persona: this.tipoPersona,
        celular: this.telefono,
        correoElectronico: this.correo,
        codigoPostal: this.codigoPostal,
        direccion: this.direccion,
        inicioVigencia: moment(this.inicioVigencia, "DD/MM/YYYY").format(
          "YYYY-MM-DD"
        ),
        finVigencia: moment(this.finVigencia, "DD/MM/YYYY").format(
          "YYYY-MM-DD"
        ),
        periodicidadPago:
          this.periodicidad == "Anual"
            ? 1
            : this.periodicidad == "Semestral"
            ? 2
            : this.periodicidad == "Trimestral"
            ? 3
            : this.periodicidad == "Mensual"
            ? 4
            : "",
        formaPagoSelected: this.formaPago,
        operadorUuid: this.operador,
        solicitudCompletaSelected: this.solicitudCompleta
          ? this.solicitudCompleta
          : false,
        procesoCotizacionSelected: this.procesoCotizacion,
        checkPendienteInfo: this.checkPendienteInfo
          ? this.checkPendienteInfo
          : null,
        checkCancelacion: this.checkCancelacion ? this.checkCancelacion : null,
        causaPendienteInformacion: this.causaPendienteInformacion
          ? this.causaPendienteInformacion
          : null,
        comercial_asignado: this.comercialAsignado
          ? this.comercialAsignado
          : null,
        razon_social: this.razonSocial ? this.razonSocial : null,
        lead_id: this.leadOrigen ? this.leadOrigen.id : "",
        /* FIN DE CAMPOS DE COMPONENTE PADRE */

        /* CAMPOS DE COMPONENTE HIJO(RAMO) */
        tipoInmueble: this.tipoInmueble,
        otroTipo: this.otroTipo ? this.otroTipo : null,
        valorInmueble: this.valorInmueble,
        nivelDepartamento: this.nivelDepartamento,
        pisosTotales: this.pisosTotales,
        numeroPisosAltos: this.pisosAltos,
        sotanos: this.sotanos,
        valorContenidos: this.valorContenidos,
        valorEquipoElectronico: this.valorEqupioElectronico,
        sumaAseguradaRC: this.sumaAseguradaRC,
        sumaAseguradaCristales: this.sumaAseguradaCristales,
        sumaAseguradaRoboViolencia: this.sumaAseguradaRoboConViolencia,
        sumaAseguradaDineroValores: this.sumaAseguradaDineroValores,
        sumaAseguradaObrasDeArte: this.sumaAseguradaPiezasDificilReposicion,
        ...this.newFormProps,
        emit_direct: this.emitDirect,
        operadorId: JSON.parse(localStorage.getItem("roles"))[0].agente_id
          ? JSON.parse(localStorage.getItem("roles"))[0].agente_id
          : "777",
        checkTramite: this.emitDirect,
      };

      mainAxios
        .post("v1/solicitud/insert/", request, config)
        .then((response) => {
          if (response.status == 200) {
            this.$emit('showIDSolicitud', response)
            const ruta = window.location.pathname;
            if (ruta.includes("/segurify/leads-segurify/lead/")) {
              this.$emit("SolicitudSuccess");
            } else {
              this.dialog = true;
              !this.esDesdeDialog &&
                setTimeout(() => this.$router.push("/solicitudes"), 2500);
              this.esDesdeDialog &&
                setTimeout(() => (this.dialog.value = false), 2500);
              this.$emit("SolicitudSuccess");
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    comportamientoBotonGuardar() {
      if (this.tipoInmueble == "Otro") {
        return this.botonGuardarOtroTipo;
      }
      if (this.tipoInmueble == "Departamento") {
        return this.botonGuardarDepartamento;
      }
      if (this.tipoInmueble != "Otro" && this.tipoInmueble != "Departamento") {
        return this.botonGuardar;
      }
    },
    methods: {
      sendSolicitud() {
        let config = {
          headers: {
            Authorization: "Bearer " + localStorage.agenteAccessToken,
          },
        };
        let request = {
          /* CAMPOS DE COMPONENTE PADRE */
          origenSolicitudSelected: this.origenSolicitud,
          agenteId: this.agente,
          tipoSelected: this.tipo,
          categoriaSelected: this.ramo,
          productoId: this.producto,
          detalle: this.detalleSolicitud ? this.detalleSolicitud : "",
          nombres: this.nombres,
          apellidoPaterno: this.apellidoPaterno,
          apellidoMaterno: this.apellidoMaterno,
          estadoCivilSelected: this.estadoCivil,
          estadoRepublicaSelected: this.estadoNacimiento,
          genero: this.genero,
          fechaNacimiento: this.fechaNacimiento
            ? moment(this.fechaNacimiento, "DD/MM/YYYY").format("YYYY-MM-DD")
            : null,
          rfc: this.rfc,
          regimen_persona: this.tipoPersona,
          celular: this.telefono,
          correoElectronico: this.correo,
          codigoPostal: this.codigoPostal,
          direccion: this.direccion,
          inicioVigencia: moment(this.inicioVigencia, "DD/MM/YYYY").format(
            "YYYY-MM-DD"
          ),
          finVigencia: moment(this.finVigencia, "DD/MM/YYYY").format(
            "YYYY-MM-DD"
          ),
          periodicidadPago:
            this.periodicidad == "Anual"
              ? 1
              : this.periodicidad == "Semestral"
              ? 2
              : this.periodicidad == "Trimestral"
              ? 3
              : this.periodicidad == "Mensual"
              ? 4
              : "",
          formaPagoSelected: this.formaPago,
          operadorUuid: this.operador,
          solicitudCompletaSelected: this.solicitudCompleta
            ? this.solicitudCompleta
            : false,
          procesoCotizacionSelected: this.procesoCotizacion,
          checkPendienteInfo: this.checkPendienteInfo
            ? this.checkPendienteInfo
            : null,
          checkCancelacion: this.checkCancelacion
            ? this.checkCancelacion
            : null,
          causaPendienteInformacion: this.causaPendienteInformacion
            ? this.causaPendienteInformacion
            : null,
          comercial_asignado: this.comercialAsignado
            ? this.comercialAsignado
            : null,
          razon_social: this.razonSocial ? this.razonSocial : null,
          lead_id: this.leadOrigen ? this.leadOrigen.id : "",
          /* FIN DE CAMPOS DE COMPONENTE PADRE */

          /* CAMPOS DE COMPONENTE HIJO(RAMO) */
          tipoInmueble: this.tipoInmueble,
          otroTipo: this.otroTipo ? this.otroTipo : null,
          valorInmueble: this.valorInmueble,
          nivelDepartamento: this.nivelDepartamento,
          pisosTotales: this.pisosTotales,
          numeroPisosAltos: this.pisosAltos,
          sotanos: this.sotanos,
          valorContenidos: this.valorContenidos,
          valorEquipoElectronico: this.valorEqupioElectronico,
          sumaAseguradaRC: this.sumaAseguradaRC,
          sumaAseguradaCristales: this.sumaAseguradaCristales,
          sumaAseguradaRoboViolencia: this.sumaAseguradaRoboConViolencia,
          sumaAseguradaDineroValores: this.sumaAseguradaDineroValores,
          sumaAseguradaObrasDeArte: this.sumaAseguradaPiezasDificilReposicion,
          ...this.newFormProps,
          emit_direct: this.emitDirect,
          operadorId: JSON.parse(localStorage.getItem("roles"))[0].agente_id
            ? JSON.parse(localStorage.getItem("roles"))[0].agente_id
            : "777",
          checkTramite: this.emitDirect,
          numeroPoliza: this.numeroPoliza ? this.numeroPoliza : "",
        };

        mainAxios
          .post("v1/solicitud/insert/", request, config)
          .then((response) => {
            if (response.status == 200) {
              const ruta = window.location.pathname;
              if (ruta.includes("/segurify/leads-segurify/lead/")) {
                this.$emit("SolicitudSuccess");
              } else {
                this.dialog = true;
                setTimeout(() => this.$router.push("/solicitudes"), 2500);
              }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      },
      comportamientoBotonGuardar() {
        if (this.tipoInmueble == "Otro") {
          return this.botonGuardarOtroTipo;
        }
        if (this.tipoInmueble == "Departamento") {
          return this.botonGuardarDepartamento;
        }
        if (
          this.tipoInmueble != "Otro" &&
          this.tipoInmueble != "Departamento"
        ) {
          return this.botonGuardarOtroTipo;
        }
      },
    },
    //TIPO igual a "Otro"
    botonGuardarOtroTipo() {
      if (this.tipoPersona == "Moral") {
        return (
          this.origenSolicitud != null &&
          this.statusSolicitud != null &&
          this.agente != null &&
          this.tipo != null &&
          this.ramo != null &&
          this.detalleSolicitud != null &&
          this.tipoPersona != null &&
          this.razonSocial != null &&
          this.rfc != null &&
          this.telefono != null &&
          this.correo != null &&
          this.codigoPostal != null &&
          this.inicioVigencia != null &&
          this.finVigencia != null &&
          this.periodicidad != null &&
          this.formaPago != null &&
          /* CAMPOS DEL RAMO */
          this.tipoInmueble != null &&
          this.otroTipo != null &&
          this.valorInmueble != null
        );
      }
      return (
        this.origenSolicitud != null &&
        this.statusSolicitud != null &&
        this.agente != null &&
        this.tipo != null &&
        this.ramo != null &&
        this.detalleSolicitud != null &&
        this.nombres != null &&
        this.apellidoPaterno != null &&
        this.genero != null &&
        this.fechaNacimiento != null &&
        this.estadoNacimiento != null &&
        this.estadoCivil != null &&
        this.tipoPersona != null &&
        this.rfc != null &&
        this.telefono != null &&
        this.correo != null &&
        this.codigoPostal != null &&
        this.inicioVigencia != null &&
        this.finVigencia != null &&
        this.periodicidad != null &&
        this.formaPago != null &&
        /* CAMPOS DEL RAMO */
        this.tipoInmueble != null &&
        this.otroTipo != null &&
        this.valorInmueble != null
      );
    },
    //TIPO igual a "Departamento"
    botonGuardarDepartamento() {
      if (this.tipoPersona == "Moral") {
        return (
          this.origenSolicitud != null &&
          this.statusSolicitud != null &&
          this.agente != null &&
          this.tipo != null &&
          this.ramo != null &&
          this.detalleSolicitud != null &&
          this.tipoPersona != null &&
          this.razonSocial != null &&
          this.rfc != null &&
          this.telefono != null &&
          this.correo != null &&
          this.codigoPostal != null &&
          this.inicioVigencia != null &&
          this.finVigencia != null &&
          this.periodicidad != null &&
          this.formaPago != null &&
          /* CAMPOS DEL RAMO */
          this.tipoInmueble != null &&
          this.valorInmueble != null &&
          this.nivelDepartamento != null &&
          this.pisosTotales != null
        );
      }
      return (
        this.origenSolicitud != null &&
        this.statusSolicitud != null &&
        this.agente != null &&
        this.tipo != null &&
        this.ramo != null &&
        this.detalleSolicitud != null &&
        this.nombres != null &&
        this.apellidoPaterno != null &&
        this.genero != null &&
        this.fechaNacimiento != null &&
        this.estadoNacimiento != null &&
        this.estadoCivil != null &&
        this.tipoPersona != null &&
        this.rfc != null &&
        this.telefono != null &&
        this.correo != null &&
        this.codigoPostal != null &&
        this.inicioVigencia != null &&
        this.finVigencia != null &&
        this.periodicidad != null &&
        this.formaPago != null &&
        /* CAMPOS DEL RAMO */
        this.tipoInmueble != null &&
        this.valorInmueble != null &&
        this.nivelDepartamento != null &&
        this.pisosTotales != null
      );
    },
    emitDirect() {
      if (
        this.roles == "ADMINCC" ||
        this.roles == "MANAGERCC" ||
        this.roles == "OPERADORCC" ||
        this.roles == "AGENTCC" ||
        this.roles == "AGENTCCSPONSOR"
      ) {
        return true;
      } else return false;
    },
  },
};
</script>

<style></style>
