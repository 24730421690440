<template>
  <v-row style="padding: 0px 20px">
    <v-col cols="4">
      <v-select
        label="Tipo de Inmueble"
        v-model="tipoInmueble"
        :items="tipoInmuebleList"
        item-text="name"
        item-value="id"
        @change="avisarCambio"
      ></v-select>
    </v-col>
    <v-col cols="4" v-if="tipoInmueble == 'Otro'">
      <v-text-field
        label="Especificar Tipo de inmueble"
        v-model="otroTipoInmueble"
        @change="avisarCambio"
      ></v-text-field>
    </v-col>
    <v-col cols="4">
      <v-text-field
        label="Valor del inmueble"
        v-model="valorInmueble"
        @change="avisarCambio"
        prefix="$"
        v-number-only
      ></v-text-field>
    </v-col>
    <!-- Solo si es departamente -->
    <v-col cols="4" v-if="tipoInmueble == 'Departamento'">
      <v-text-field
        label="Nivel del departamento"
        v-model="nivelDepartamento"
        @change="avisarCambio"
      ></v-text-field>
    </v-col>
    <v-col cols="4" v-if="tipoInmueble == 'Departamento'">
      <v-text-field
        label="Pisos Totales"
        v-model="pisosTotales"
        @change="avisarCambio"
      ></v-text-field>
    </v-col>
    <!-- Solo si es Casa -->
    <v-col cols="4" v-if="tipoInmueble == 'Casa'">
      <v-text-field
        label="Número de pisos altos"
        v-model="numeroPisosAltos"
        @change="avisarCambio"
      ></v-text-field>
    </v-col>
    <v-col cols="4" v-if="tipoInmueble == 'Casa'">
      <v-text-field
        label="Sotanos"
        v-model="sotanos"
        @change="avisarCambio"
      ></v-text-field>
    </v-col>
    <v-col cols="4">
      <v-text-field
        label="Valor de los contenidos"
        v-model="valorContenidos"
        @change="avisarCambio"
        prefix="$"
        v-number-only
      ></v-text-field>
    </v-col>
    <v-col cols="4">
      <v-text-field
        label="Valor del equipo electrónico"
        v-model="valorEquipoElectronico"
        @change="avisarCambio"
        prefix="$"
        v-number-only
      ></v-text-field>
    </v-col>
    <v-col cols="4">
      <v-text-field
        label="Suma asegurada Responsabilidad Civil"
        v-model="sumaAseguradaRC"
        @change="avisarCambio"
        prefix="$"
        v-number-only
      ></v-text-field>
    </v-col>
    <v-col cols="4">
      <v-text-field
        label="Suma asegurada Cristales"
        v-model="sumaAseguradaCristales"
        @change="avisarCambio"
        prefix="$"
        v-number-only
      ></v-text-field>
    </v-col>
    <v-col cols="4">
      <v-text-field
        label="Suma asegurada robo con violencia "
        v-model="sumaAseguradaRoboViolencia"
        @change="avisarCambio"
        prefix="$"
        v-number-only
      ></v-text-field>
    </v-col>
    <v-col cols="4">
      <v-text-field
        label="Suma asegurada dinero y valores"
        v-model="sumaAseguradaDineroValores"
        @change="avisarCambio"
        prefix="$"
        v-number-only
      ></v-text-field>
    </v-col>
    <v-col cols="4">
      <v-text-field
        label="Suma asegurada obras de arte o piezas de difícil reposición"
        v-model="sumaAseguradaObrasDeArte"
        @change="avisarCambio"
        prefix="$"
        v-number-only
      ></v-text-field>
    </v-col>
    
  </v-row>
</template>

<script>
export default {
  directives: {
    'number-only': {
      bind (el) {
        function checkValue (event) {
          event.target.value = event.target.value.replace(/[^0-9]/g, '')
          if (event.charCode >= 48 && event.charCode <= 57) {
            return true
          }
          event.preventDefault()
        }
        el.addEventListener('keypress', checkValue)
      }
    },
    'letter-only': {
      bind (el) {
        function checkValue (event) {
          if (event.charCode >= 48 && event.charCode <= 57) {
            event.preventDefault()
          }
          return true
        }
        el.addEventListener('keypress', checkValue)
      }
    }
  },
  data () {
    return {
      tipoInmueble: null,
      otroTipoInmueble: null,
      valorInmueble: null,
      nivelDepartamento: null,
      pisosTotales: null,
      numeroPisosAltos: null,
      sotanos: null,
      valorContenidos: null,
      valorEquipoElectronico: null,
      sumaAseguradaRC: null,
      sumaAseguradaCristales: null,
      sumaAseguradaRoboViolencia: null,
      sumaAseguradaDineroValores: null,
      sumaAseguradaObrasDeArte: null,

      //listas
      tipoInmuebleList: [
       'Casa',
       'Departamento',
       'Bodega',
       'Edificio',
       'Otro',
      ],
    }
  },
  props: ['avisar'],
  methods: {
    avisarCambio(){
      let datos = {
        tipoInmueble: this.tipoInmueble,
        otroTipoInmueble: this.otroTipoInmueble,
        valorInmueble: this.valorInmueble,
        nivelDepartamento: this.nivelDepartamento,
        pisosTotales: this.pisosTotales,
        numeroPisosAltos: this.numeroPisosAltos,
        sotanos: this.sotanos,
        valorContenidos: this.valorContenidos,
        valorEquipoElectronico: this.valorEquipoElectronico,
        sumaAseguradaRC: this.sumaAseguradaRC,
        sumaAseguradaCristales: this.sumaAseguradaCristales,
        sumaAseguradaRoboViolencia: this.sumaAseguradaRoboViolencia,
        sumaAseguradaDineroValores: this.sumaAseguradaDineroValores,
        sumaAseguradaObrasDeArte: this.sumaAseguradaObrasDeArte,
      }
      this.avisar(datos)
    }
  }
}
</script>