<template>
  <v-card>
    <v-toolbar flat dense>
      <v-toolbar-title class="text-subtitle-1">Polizas</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        small
        color="primary"
        @click="openModal"
        v-if="
          validarLead &&
            (roles == 'OPERADORCC' || roles == 'ADMIN' || roles == 'OPERADOR' || roles == 'AGENTCC')
        "
      >
        Crear
      </v-btn>
    </v-toolbar>

    <v-divider></v-divider>

    <v-data-table
      :headers="columns"
      :items="emisiones"
      item-key="name"
      class="elevation-1"
      :items-per-page="5"
    >
      <template v-slot:[`item.accion`]="{ item }">
        <v-icon small class="mr-2" @click="VerEmision(item.id)">
          mdi-eye
        </v-icon>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
"moment";

export default {
  name: "leads.polizas",
  data: function() {
    return {
      columns: [
        { text: "Pólizas", align: "start", sortable: false, value: "id" },
        { text: "Estatus", value: "status" },
        { text: "Campaña", value: "campania" },
        { text: "Producto", value: "producto" },
        { text: "Ramo", value: "ramo" },
        { text: "Aseguradora", value: "aseguradora" },
        { text: "Fecha Creación", value: "fecha" },
        { text: "Ejecutivo", value: "operador" },
        { text: "Ver", value: "accion" },
      ],
      data_table: [],
      uuid: "",
      validarLead: false,
      roles: JSON.parse(localStorage.getItem("roles"))[0].rol,
    };
  },
  props: {
    emisiones: {
      type: Array,
      required: true,
    },
    lead: {
      type: Object,
      require: true,
    },
  },
  methods: {
    openModal() {
      this.$emit("openPolizasModal");
    },
    VerEmision(id) {
      window.open(`/emision/${id}`);
    },
    checarLeadIncomplete() {
      if (
        typeof this.lead.medio != "undefined" &&
        typeof this.lead.producto != "undefined" &&
        typeof this.lead.regimen != "undefined" &&
        typeof this.lead.rfc != "undefined" &&
        typeof this.lead.nombre != "undefined" &&
        typeof this.lead.apellidoPaterno != "undefined" &&
        typeof this.lead.apellidoMaterno != "undefined" &&
        typeof this.lead.fechaNacimiento != "undefined" &&
        typeof this.lead.sexo != "undefined" &&
        typeof this.lead.estadoCivil != "undefined" &&
        typeof this.lead.edad != "undefined" &&
        typeof this.lead.telefono != "undefined" &&
        typeof this.lead.correo != "undefined" &&
        typeof this.lead.calle != "undefined" &&
        typeof this.lead.num_exterior != "undefined" &&
        typeof this.lead.num_interior != "undefined" &&
        typeof this.lead.codigoPostal != "undefined" &&
        typeof this.lead.colonia != "undefined" &&
        typeof this.lead.ciudad != "undefined" &&
        typeof this.lead.estado != "undefined" &&
        typeof this.lead.pais != "undefined"
      ) {
        this.validarLead = true;
      } else {
        this.validarLead = false;
      }
    },
  },
  watch: {
    lead() {
      this.checarLeadIncomplete();
    },
  },
};
</script>
