<template>
     <v-dialog
      v-model="dialogDetalleDocumentos"
      max-width="750px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Detalle de Documento / Nota</span>
        </v-card-title>

        <template>
          <v-container style="padding: 30px">
              <v-row>
                  <v-col cols="6">
                      <v-text-field
                          label="Nombre"
                          v-model="detallesDocumento.nombre"
                          readonly
                      ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                      <v-checkbox
                          label="¿Enviar por correo electrónico?"
                          readonly
                      ></v-checkbox>
                  </v-col>
                  <v-col cols="6">
                      <v-select
                          label="Tipo"
                          :items="tipoItems"
                          v-model="detallesDocumento.tipo"
                          required
                          readonly
                      ></v-select>
                  </v-col>
                  <v-col cols="6" v-if="detallesDocumento.tipo == 'Documento'">
                      <v-select
                          label="Tipo de Doocumento*"
                          :items="tipoDocumentoItems"
                          v-model="detallesDocumento.tipoDocumento"
                          required
                          readonly
                      ></v-select>
                  </v-col>
                  <v-col cols="6">
                      <v-text-field
                          label="Descripción"
                          v-model="detallesDocumento.nota"
                          required
                          readonly
                      ></v-text-field>
                  </v-col>
                  <v-col cols="4" v-if="detallesDocumento.url">
                    <template>
                      <v-file-input
                        v-model="documentos"
                        color="primary"
                        label="Adjuntos*"
                      >
                        <template v-slot:selection="{ index }">
                          <v-chip
                            v-if="index <= 1"
                            color="primary"
                            dark
                            label
                            small
                            @click="detallesDocumento.url"
                          >
                            {{ detallesDocumento.nombre }}
                          </v-chip>

                          <span
                            v-else-if="index === 2"
                            class="text-overline grey--text text--darken-3 mx-2"
                          >
                            +{{ documentos.length - 2 }} Archivos(s)
                          </span>
                        </template>
                      </v-file-input>
                    </template>
                  </v-col>
                  <v-col cols="2" v-if="detallesDocumento.url">
                    <v-btn
                      text
                      :href="detallesDocumento.url"
                      small
                      color="primary"
                      class="mt-4"
                    >
                    <v-icon>mdi-download</v-icon>
                      Descargar
                    </v-btn>
                  </v-col>
                  <v-col cols="6">
                      <v-text-field
                          label="Creado por"
                          v-model="detallesDocumento.creadoPor"
                          readonly
                      ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                      <v-text-field
                          label="Fecha de Creación"
                          v-mask="'##/##/####'"
                          v-model="detallesDocumento.fecha"
                          readonly
                      ></v-text-field>
                  </v-col>
              </v-row>
          </v-container>
        </template>
        <v-card-actions class="justify-end">
            <v-btn text
                @click="closeDocumentosModal">
                Cerrar</v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>
</template>

<script>

export default {
    name: 'DocumentosModal',
    props: {
        dialogDetalleDocumentos: Boolean,
        detallesDocumento: Object,
    },
    data() {
        return {
            send: false,
            dialog: false,
            tipoItems: [
                "Nota",
                "Documento"
            ],
            tipoDocumentoItems: [
        "Acta constitutiva",
        "Aviso de privacidad firmado",
        "Caratula de afiliación",
        "Comprobante de Domicilio",
        "Comprobante Médico",
        "Constancia de situación fiscal",
        "Caratula de estado de cuenta",
        "Cotización",
        "Cédula del Agente",
        "Identificación oficial",
        "Documento de afiliación",
        "Poder Notarial",
        "Póliza",
        "RC de Agente",
        "Tarjeta de Circulación",
        "Otro",
      ],
            nombre: null,
            enviarPorCorreo: null,
            tipoSelected: null,
            tipoDocumentoSelected: null,
            descripcion: null,
            archivos: [],
            creadoPor: null,
            fechaCreacion: null,
            modificadoPor: null,
            fechaUltimaModificacion: null
        }
    },
    watch: {
        dialog(val) {
            this.$emit('update:active', val)
        },
    },
    mounted(){
        this.dialog = this.dialogDocumentos;
        if(this.form != null){
            this.nombre = this.form.nombre;
            this.tipoSelected = this.form.tipo;
            this.tipoDocumentoSelected = this.form.tipoDocumento;
            this.descripcion = this.form.descripcion;
            this.creadoPor = this.form.creadoPor;
            this.fechaCreacion = this.form.fecha;
        }
    },
    methods: {
        closeDocumentosModal(){
            this.$emit('closeDocumentosModal')
        },
        
    }
}
</script>