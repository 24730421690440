<template>
  <v-row style="padding: 0px 20px">
    <v-col cols="4">
      <v-text-field
        label="Modelo del Celular"
        v-model="modeloCelular"
        :rules="inputsRequeridos"
        @change="avisarCambio"
      ></v-text-field>
    </v-col>
    <v-col cols="4">
      <v-text-field
        label="Costo del Celular (Valor Factura)"
        v-model="costoCelular"
        :rules="inputsRequeridos"
        @change="avisarCambio"
      ></v-text-field>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data () {
    return {
      modeloCelular: null,
      costoCelular: null,

      //Validadores
      inputsRequeridos: [
        value => !!value || 'Campo Requerido',
      ],
    }
  },
  props: ['avisar'],
  methods: {
    avisarCambio(){
      let datos = {
        modeloCelular: this.modeloCelular,
        costoCelular: this.costoCelular,
      }
      this.avisar(datos)
    }
  }
}
</script>