<template>
  <v-app>
    <Navigation></Navigation>
    <v-main class="mb-15">
      <v-container fluid>
        <v-row class="mb-15 fill-height">
          <v-col>
            <v-row>
              <v-col cols="10">
                <h1>Mis Cuotas</h1>
              </v-col>
            </v-row>
            <v-row class="mt-4">
              <v-col cols="4" >
                <v-card outlined class="card" @click="getCuotas(1)">
                  <v-row>
                    <p>{{ cuotasPendientesPago }}</p>
                    <h3>Pendientes de Pago</h3>
                  </v-row>
                </v-card>
              </v-col>
              <v-col cols="4">
                <v-card outlined class="card" @click="getCuotas(2)">
                  <v-row>
                    <p>{{ cuotasPagadas }}</p>
                    <h3>Pagadas</h3>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
            <v-row >
              <v-col cols="4" >
                <v-card outlined class="card" @click="getCuotas(3)">
                  <v-row>
                    <p>{{ cuotasVencidas }}</p>
                    <h3>Vencidas</h3>
                  </v-row>
                </v-card>
              </v-col>
              <v-col cols="4">
                <v-card outlined class="card" @click="getCuotas(4)">
                  <v-row>
                    <p>{{ cuotasCanceladas }}</p>
                    <h3>Canceladas</h3>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <v-card style="padding: 30px">
              <v-card-title primary-title>
                <v-row>
                  <v-col cols="10">
                    <span class="text-h3">Cuotas {{ titulo }}</span>
                  </v-col>
                  <v-col cols="2">
                    <v-btn
                      color="primary"
                      style="height: 50px; weight: 50px"
                      class="ml-16"
                      dark
                      max-height="40px"
                      max-width="100px"
                      v-if="canCreate"
                      :disabled="descargarCuotas.length == 0"
                    >
                      <download-csv
                        :name="downloadText"
                        :data="descargarCuotas"
                      >
                        Descargar
                      </download-csv>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-title>
              
              <!-- TABLE -->
              <v-data-table
                  :headers="columnsCuotas"
                  :items="cuotas"
                  item-key="name"
                  class="elevation-1"
                  :search="search"
                  :loading='loading'
                  loading-text="Cargando..."
                >
                  <template v-slot:top>
                    <v-text-field
                      v-model="search"
                      label="Buscar"
                      class="mx-4"
                    ></v-text-field>
                  </template>
                  <template v-slot:item.uuid="{ item }">
                    <v-icon
                      small
                      class="mr-2"
                      @click="$router.push('/visualizacion-comisiones/' + item.uuid)"
                    >
                      mdi-eye
                    </v-icon>
                  </template>
                </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <v-footer fixed style="text-align:center;">
      <p style="text-align:center;width:100%; margin: 10px 0px">Segurify 2021 <a target="_blank" href="/terminos-y-condiciones">Terminos y condiciones</a> y <a target="_blank" href="/aviso-de-privacidad">Aviso de privacidad</a>  </p>
    </v-footer>
  </v-app>
</template>

<script>
import Navigation from "../../../components/Nav.vue";
import { mainAxios } from "../../../mainAxios";
var moment = require("moment-timezone");
export default {
  components: {
    Navigation,
  },
  computed:{
    canCreate(){
      var canView = false;
      var roles=[]
      this.roles.forEach((element) => {
        roles.push(element.rol)
      });
      const rolesToView = ["ADMIN", "MESADECONTROL", "OPERACIONES","OPERADOR"];
      roles.forEach((element)=>{
        if(rolesToView.includes(element)){
          canView=true;
        }
      })
      return canView;
    }
  },
  data() {
    return {
      dates: ["", ""],
      roles: JSON.parse(localStorage.getItem("roles")),
      agenteUuid: localStorage.getItem('agenteUuid'),
      loading: false,
      downloadText: '',
      titulo: '',
      search: '',
      cuotas: [],
      descargarCuotas: [],
      searchNuevas: "",
      searchPendientesPago: "",
      searchPagadasParcial: "",
      searchPagadasTotal: "",
      searchPorRenovar: "",
      searchRenovadas: "",
      searchFinalizadas: "",
      searchCanceladas: "",
      bloquear: true,
      productos: [],
      cuotasPendientesPago: 0,
      cuotasPagadas: 0,
      cuotasVencidas: 0,
      cuotasCanceladas: 0,
      columnsCuotas: [
        { text: "Cuota", value: "idCuota" },
        { text: "Póliza", value: "poliza" },
        { text: "Cliente", value: "cliente" },
        { text: "Orden", value: "orden" },
        { text: "Estado de la Cuota", value: "estadoCuota" },
        { text: "Valor a Pagar", value: "valorPagar" },
        { text: "Fecha Pago Acordada", value: "fechaPagoAcordada" },
        { text: "Fecha Pago Cuota", value: "fechaPagoCuota" },
        { text: "Ver Cuota", value: "uuid" },
      ],
    };
  },
  mounted(){
    this.obtenerProductos()
    this.getCuotas()
    this.numeroTotalCuotas()
  },
  methods: {
    convertDate(date) {
      const dateRes = moment(date);
      dateRes.tz("UTC", true);
      return dateRes.tz("America/Mexico_City").format("DD-MM-YYYY HH:mm");
    },
    getCuotas(status) {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      }
      let reporte = ''
      status == 1 ? reporte = 'cuotasByAgenteWithStatusPendienteDePago' :
      status == 2 ? reporte = 'cuotasByAgenteWithStatusPagadas' :
      status == 3 ? reporte = 'cuotasByAgenteWithStatusVencida' :
                    reporte = 'cuotasByAgenteWithStatusCanceladas'
      
      status == 1 ? this.downloadText = 'Cuotas_Pendientes_Pago.csv' :
      status == 2 ? this.downloadText = 'Cuotas_Pagadas.csv' :
      status == 3 ? this.downloadText = 'Cuotas_Vencidas.csv' :
                    this.downloadText = 'Cuotas_Canceladas.csv'
      
      status == 1 ? this.titulo = 'Pendientes Pago' :
      status == 2 ? this.titulo = 'Pagadas' :
      status == 3 ? this.titulo = 'Vencidas' :
                    this.titulo = 'Canceladas'

      this.cuotas = []
      this.descargarCuotas = []
      //Obtener Polizas
      mainAxios.get(`/v1/cuota/${reporte}/` + `${this.agenteUuid}`, config).then(resp => {
        console.log('Cuotas: ', resp)
        //Cargar datos en la tabla
        resp.data.map(element => {
          this.cuotas.push({
            idCuota: element.id,
            poliza: element.polizas ? element.polizas : 'No Disponible',
            cliente: element.nombreCliente ? element.nombreCliente : 'No Disponible',
            orden: element.orden ? element.orden : 'No Disponible',
            estadoCuota: element.status ? element.status : 'No Disponible',
            valorPagar: element.valorAPagar ? element.valorAPagar : 'No Disponible',
            fechaPagoAcordada: element.fechaPagoAcordada ? moment(element.fechaPagoAcordada, 'YYYY-MM-DD').format('DD/MM/YYYY') : 'No Disponible',
            fechaPagoCuota: element.fechaPago ? moment(element.fechaPago, 'YYYY-MM-DD').format('DD/MM/YYYY') : 'No Disponible',
            uuid: element.uuid
          })
        })
        //Datos para Descargar reporte
        resp.data.map(item => {
          this.descargarCuotas.push({
            "Cuota": item.id,
            "Póliza": item.polizas ? item.polizas : 'No Disponible',
            "Cliente": item.nombreCliente ? item.nombreCliente : 'No Disponible',
            "Ejecutivo": item.nombreEjecutivo ? item.nombreEjecutivo : 'No Disponible',
            "Orden": item.orden ? item.orden : 'No Disponible',
            "Estado de la Cuota": item.status ? item.status : 'No Disponible',
            "Valor a Pagar": item.valorAPagar ? '$' + item.valorAPagar : 'No Disponible',
            "Fecha de Pago Acordada": item.fechaPagoAcordada ? moment(item.fechaPagoAcordada, 'YYYY-MM-DD').format('DD/MM/YYYY') : 'No Disponible',
            "Fecha Pago de Cuota": item.fechaPago ? moment(item.fechaPago, 'YYYY-MM-DD').format('DD/MM/YYYY') : 'No Disponible'
          })
        })
      });
    },
    numeroTotalCuotas() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      }

      //Cuotas Pendientes de Pago
      mainAxios.get('/v1/cuota/cuotasByAgenteWithStatusPendienteDePago/' + `${this.agenteUuid}`, config).then(resp => {
        resp.data.map(element => {
          element
          this.cuotasPendientesPago ++;
        })
      });
      //Cuotas Pagadas
      mainAxios.get('/v1/cuota/cuotasByAgenteWithStatusPagadas/' + `${this.agenteUuid}`, config).then(resp => {
        resp.data.map(element => {
          element
          this.cuotasPagadas ++;
        })
      });
      //Cuotas Vencidas
      mainAxios.get('/v1/cuota/cuotasByAgenteWithStatusVencida/' + `${this.agenteUuid}`, config).then(resp => {
        resp.data.map( element => {
          element
          this.cuotasVencidas ++;
        })
      });
      //Cuotas Canceladas
      mainAxios.get('/v1/cuota/cuotasByAgenteWithStatusCanceladas/' + `${this.agenteUuid}`, config).then(resp => {
        resp.data.map(element => {
          element
          this.cuotasCanceladas ++;
        })
      });
    },
    
    // Table
    filterOnlyCapsText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLocaleUpperCase().indexOf(search) !== -1
      );
    },
    obtenerProductos() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios.get('/v1/producto/list', config).then(response => {
        // console.log('Productos: ', response.data);
        response.data.map(element => {
          this.productos.push({
            nombre: `${element.ramo}`+ ' / ' + `${element.nombre}` + ' / ' + `${element.nombreAseguradora}`,
            id: element.id,
            ramo: element.ramo,
            aseguradoraId: element.aseguradoraId
          })
        })
          //this.obtenerRamo(this.emision.productoId)
          //this.obtenerAseguradoraId(this.emision.productoId)
      });
    },
    obtenerRamo(id){
      let productoSelected = this.productos.find(element => element.id === id)
      this.ramo = productoSelected ? productoSelected.ramo : null
    },
  },
};
</script>

<style scoped>
.card {
  display: block;
  top: 0px;
  position: relative;
  background-color: white;
  border-radius: 15px;
  padding: 32px 24px;
  margin: 12px;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;
  border: 1px solid black;
  cursor: pointer;
}
.card:hover {
  transition: all 0.2s ease-out;
  box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
  top: -4px;
  border: 1px solid black;
  background-color: rgba(21, 101, 192, 1);
}
.card:hover:before {
  transform: scale(6.15);
}
</style>