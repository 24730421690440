<template>
  <v-app>
    <Navigation></Navigation>
    <v-main>
      <v-container fluid>
        <!-- HEAD TITLE -->
        <v-row style="margin-top: 10px">
          <v-col cols="6">
            <h2>Cuotas y Comisiones</h2>
          </v-col>

          <v-col cols="6">
            <v-btn
              @click="goBack()"
              elevation="2"
              style="margin-left: 527px"
              ><v-icon style="margin-right: 5px">mdi-arrow-left</v-icon>Regresar
            </v-btn>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="8">
            <v-form>
              <v-row>
                <v-col>
                  <v-stepper v-model="stepper" non-linear>
                    <v-stepper-header style="justify-content: left">
                      <v-stepper-step step="1" editable>
                        Cuota de la Póliza
                      </v-stepper-step>

                      <v-stepper-step step="2" editable>
                        Desglose de Comisiones
                      </v-stepper-step>
                    </v-stepper-header>

                    <v-stepper-items>
                      <!-- 1ST ITEM (FORM) -->
                      <v-stepper-content step="1">
                        <v-row style="padding: 20px">
                          <v-col cols="6">
                            <v-text-field
                              label="Pólizas"
                              v-model="poliza"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              label="Orden"
                              v-model="orden"
                            ></v-text-field>
                          </v-col>
                          <!-- End 1nd Col -->
                        </v-row>

                        <v-row style="padding: 20px; margin-top: -70px">
                          <v-col cols="6">
                            <v-select
                              label="Estado de la Cuota"
                              :items="estadoCuotaItems"
                              v-model="estadoCuotaSelected"
                            ></v-select>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              label="Valor a Pagar"
                              v-model="valorPagar"
                            ></v-text-field>
                          </v-col>
                          <!-- End 2nd Col -->
                        </v-row>

                        <v-row style="padding: 20px; margin-top: -70px">
                         <v-col cols="6">
                            <v-text-field
                              label="Fecha de pago acordada"
                              v-model="fechaPagoAcordada"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              label="Fecha límite de pago"
                              v-model="fechaLimitePago"
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row style="padding: 20px; margin-top: -70px">
                          <v-col cols="6">
                            <v-checkbox
                              label="Cuota Pagada"
                              v-model="checkCuotaPagada"
                            ></v-checkbox>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              label="Fecha pago cuota"
                              v-model="fechaPagoCuota"
                            ></v-text-field>
                          </v-col>
                          <!-- End 7th Col -->
                        </v-row>

                        <v-row style="padding: 20px; margin-top: -70px">
                          <v-col cols="6">
                            <v-text-field
                              label="Ejecutivo de Operaciones"
                              v-model="ejecutivo"
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row style="padding: 20px; margin-top: -70px">
                          <v-col cols="6">
                            <v-text-field
                              label="Fecha de Creación"
                              v-model="fechaCreacion"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              label="Última Modificación"
                              v-model="ultimaModificacion"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-stepper-content>

                      <!-- 2ND ITEM (2ND FORM) -->
                      <v-stepper-content step="2">
                        <v-row style="padding: 20px; margin-top: -35px">
                          <v-col cols="6">
                            <v-select
                              label="Estatus de Comisión"
                              :items="statusComisionItems"
                              v-model="statusComision"
                              readonly
                            ></v-select>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              label="Base Comisión"
                              v-model="baseComision"
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row style="padding: 20px; margin-top: -70px">
                          <v-col cols="6">
                            <v-checkbox
                              label="Comisión Recibida"
                              v-model="checkComisionRecibida"
                            ></v-checkbox>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              label="Fecha de Comisión Recibidas"
                              v-model="fechaComisionRecibida"
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row style="padding: 20px; margin-top: -70px">
                          <v-col cols="6">
                            <v-text-field
                              label="Folio de Movimiento Bancario Comisión"
                              v-model="folioMovimientoBancario"
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row style="padding: 20px; margin-top: -70px">
                          <v-col cols="6">
                            <v-text-field
                              label="% Comisión pagada por la aseguradora"
                              v-model="comisionPagadaAseguradora"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              label="Monto Comisión pagada por la aseguradora"
                              v-model="montoComisionPagadaAseguradora"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        
                        <v-row style="padding: 20px; margin-top: -70px">
                          <v-col cols="6">
                            <v-text-field
                              label="% Comisión Agente"
                              v-model="comisionAgente"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              label="Monto de Comisión Agente"
                              v-model="montoComisionAgente"
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row style="padding: 20px; margin-top: -70px; justify-content: right">
                          <v-col cols="6" style="aling: right">
                            <v-text-field
                              label="IVA"
                              v-model="iva"
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row style="padding: 20px; margin-top: -70px; justify-content: right">
                          <v-col cols="6">
                            <v-text-field
                              label="ISR"
                              v-model="isr"
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row style="padding: 20px; margin-top: -70px; justify-content: right">
                          <v-col cols="6">
                            <v-text-field
                              label="Retención IVA"
                              v-model="retencionIva"
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row style="padding: 20px; margin-top: -70px">
                          <v-col cols="6">
                            <v-text-field
                              label="Folio de la factura por pagar"
                              v-model="folioFacturaPagar"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              label="Importe a pagar al Agente"
                              v-model="importePagarAgente"
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row style="padding: 20px; margin-top: -70px">
                          <v-col cols="6">
                            <v-checkbox
                              label="Comisión Agente Pagada"
                              v-model="comisionAgentePagada"
                            ></v-checkbox>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              label="Fecha pagado de Comisión Agente"
                              v-model="fechaPagoComisionAgente"
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row style="padding: 20px; margin-top: -70px">
                          <v-col cols="6">
                            <v-text-field
                              label="% de comisión para Alige"
                              v-model="comisionAlige"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              label="Monto comisión para Alige"
                              v-model="montoComisionAlige"
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row style="padding: 20px; margin-top: -70px">
                          <v-col cols="6">
                            <v-text-field
                              label="% Bono"
                              v-model="bono"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              label="Monto Bono para Alige"
                              v-model="montoBonoAlige"
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row style="padding: 20px; margin-top: -70px">
                          <v-col cols="6">
                            <v-text-field
                              label="Folio de Movimiento Bancario Bono"
                              v-model="folioMovimientoBancarioBono"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              label="Fecha Pago de Bono"
                              v-model="fechaPagoBono"
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row style="padding: 20px; margin-top: -70px">
                          <v-col cols="6">
                            <v-text-field
                              label="% Comisión total"
                              v-model="comisionTotal"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              label="Monto Comisión Total Alige"
                              v-model="montoComisionTotalAlige"
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <v-btn color="primary" @click="insertarCuota()" v-if="canView">
                          Registrar Cuota
                        </v-btn>
                      </v-stepper-content>
                    </v-stepper-items>
                  </v-stepper>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>

        <!-- ALERT MODAL -->
        <v-col cols="auto">
          <v-dialog 
            transition="dialog-bottom-transition" 
            max-width="400" 
            v-model="dialog"
          >
            <template v-slot:default="dialog">
              <v-card>
                <v-toolbar color="success" dark
                  >Confirmación de envío</v-toolbar
                >
                <v-card-text>
                  <div class="text-h4" style="margin-top: 10px; ">
                    Registro de comisiones exitoso!
                  </div>
                </v-card-text>
                <v-card-actions class="justify-end">
                  <v-btn text @click="dialog.value = false, $router.push('/lista-comisiones')">Cerrar</v-btn>
                </v-card-actions>
              </v-card>
            </template>
          </v-dialog>
        </v-col>
        <!-- END OF ALERT MODAL -->
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import moment from 'moment';
import Navigation from "../../../components/Nav.vue";
import { mainAxios } from '../../../mainAxios';
export default {
  components: {
    Navigation,
  },
  data() {
    return {
      dialog: false,
      stepper: 1,
      poliza: null,
      orden: null,
      estadoCuotaItems: [
        'Pendiente de Pago',
        'Vencida',
        'Pagada',
        'Cancelada'
      ],
      estadoCuotaSelected: null,
      valorPagar: null,
      fechaPagoAcordada: null,
      fechaLimitePago: null,
      checkCuotaPagada: null,
      fechaPagoCuota: null,
      ejecutivo: null,
      fechaCreacion: moment().format('DD/MM/YYYY'),
      ultimaModificacion: null,
      statusComisionItems: [
        'Pendiente de Pago Aseguradora',
        'Pendiente de Pago al agente',
        'Pagada al Agente',
        'Cancelada'
      ],
      statusComision: null,
      baseComision: null,
      checkComisionRecibida: null,
      fechaComisionRecibida: null,
      folioMovimientoBancario: null,
      comisionPagadaAseguradora: null,
      montoComisionPagadaAseguradora: null,
      comisionAgente: null,
      montoComisionAgente: null,
      iva: null,
      isr: null,
      retencionIva: null,
      folioFacturaPagar: null,
      importePagarAgente: null,
      checkComisionAgentePagada: null,
      fechaPagoComisionAgente: null,
      comisionAlige: null,
      montoComisionAlige: null,
      bono: null,
      montoBonoAlige: null,
      folioMovimientoBancarioBono: null,
      fechaPagoBono: null,
      comisionTotal: null,
      montoComisionTotalAlige: null,
    }
  },
  computed:{
    canView(){
      let canView = false;
      let roles=[]
      this.roles.forEach((element) => {
        roles.push(element.rol)
      });
      const rolesToView = ["ADMIN", "ADMINCC", "AGENTCC"];
      roles.forEach((element)=>{
        if(rolesToView.includes(element)){
          canView=true;
        }
      })
      return canView;
    }
  },
  methods: {
    goBack() {
      return this.$router.go(-1);
    },
    insertarCuota() {
      let config = {
        headers: {
          Authorization: 'Bearer ' + localStorage.agenteAccessToken,
        },
      };
      let request = {
        poliza: this.poliza,
        orden: this.orden,
        estadoCuotaSelected: this.estadoCuotaSelected,
        valorPagar: this.valorPagar,
        fechaPagoAcordada: this.fechaPagoAcordada,
        fechaLimitePago: this.fechaLimitePago,
        checkCuotaPagada: this.checkCuotaPagada,
        fechaPagoCuota: this.fechaPagoCuota,
        ejecutivo: this.ejecutivo,
        fechaCreacion: this.fechaCreacion,
        ultimaModificacion: this.ultimaModificacion,
        statusComision: this.statusComision,
        baseComision: this.baseComision,
        checkComisionRecibida: this.checkComisionRecibida,
        fechaComisionRecibida: this.fechaComisionRecibida,
        folioMovimientoBancario: this.folioMovimientoBancario,
        comisionPagadaAseguradora: this.comisionPagadaAseguradora,
        montoComisionPagadaAseguradora: this.montoComisionPagadaAseguradora,
        comisionAgente: this.comisionAgente,
        montoComisionAgente: this.montoComisionAgente,
        iva: this.iva,
        isr: this.isr,
        retencionIva: this.retencionIva,
        folioFacturaPagar: this.folioFacturaPagar,
        importePagarAgente: this.importePagarAgente,
        checkComisionAgentePagada: this.checkComisionAgentePagada,
        fechaPagoComisionAgente: this.fechaPagoComisionAgente,
        comisionAlige: this.comisionAlige,
        montoComisionAlige: this.montoComisionAlige,
        bono: this.bono,
        montoBonoAlige: this.montoBonoAlige,
        folioMovimientoBancarioBono: this.folioMovimientoBancarioBono,
        fechaPagoBono: this.fechaPagoBono,
        comisionTotal: this.comisionTotal,
        montoComisionTotalAlige: this.montoComisionTotalAlige,
      }
      mainAxios
        .post('/v1/cuota/insert', request, config)
        .then(resp => {
          if (resp.status == 200) {
            this.dialog = true;
          }
          setTimeout(() => (this.$router.push(`/lista-comisiones`)), 2000);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
}
</script>

<style>
  .v-stepper__step__step {
    color: transparent !important;
    display: none !important;
  }
</style>