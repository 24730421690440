<template>
  <v-app>
    <NavCelular :color="color" :flat="flat" />
    <v-spacer style="background: #f6f9ff;"></v-spacer>
    <v-main style="background: #f6f9ff;">
      <v-container>
        <v-row>
          <h2 id="first-title">¿Qué modelo de celular quieres cotizar?</h2>
        </v-row>
        <template>

          <v-card color="grey lighten-5" class="mb-12" id="inside-card" dense>
            <v-form ref="cotizacionForm">
              <v-row class="form-container" dense>
                <v-col cols="4">
                  <v-autocomplete label="Marca y modelo del celular" placeholder="Introduce tu celular"
                    :items="catalogoCelulares" item-text="name" item-value="alias"
                    v-model="cotizacionCelular.marcaModelo" outlined :rules="rules.inputRequeridoRules">
                  </v-autocomplete>
                </v-col>
                <v-col cols="4">
                  <v-text-field label="Precio del celular" placeholder="$12000.00"
                    v-model="cotizacionCelular.precioFactura" outlined :rules="rules.precioRules" v-mask="'#####'">
                  </v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field label="Nombre" v-model="cotizacionCelular.nombre" outlined :rules="rules.nombreRules">
                  </v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field label="Apellido Paterno" v-model="cotizacionCelular.apellidoPaterno" outlined
                    :rules="rules.apellidosRules"></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field label="Apellido Materno" v-model="cotizacionCelular.apellidoMaterno" outlined
                    :rules="rules.inputRequeridoRules"></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field label="Celular" placeholder="+52.........." v-model="cotizacionCelular.celular"
                    v-mask="'##########'" outlined :rules="rules.celularRules"></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field label="Email" placeholder="email@mail.com" v-model="cotizacionCelular.email" outlined
                    :rules="rules.emailRules"></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field label="Código Postal" placeholder="#####" v-model="cotizacionCelular.codigoPostal"
                    v-mask="'#####'" outlined :rules="rules.inputRequeridoRules"></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-select label="Estado de la República" placeholder="Selecciona tu estado" :items="estados"
                    item-text="estadoRepublica" item-value="id" v-model="cotizacionCelular.estadoRepublica" outlined
                    :rules="rules.inputRequeridoRules"></v-select>
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <p>Opción de Pago</p>
                  <v-radio-group v-model="cotizacionCelular.opcionPago" mandatory>
                    <v-radio label="Un solo pago" value="tpv_12" color="brown"></v-radio>
                    <v-radio label="12 Meses sin intereses" value="tpv_12_finance" color="brown"></v-radio>
                  </v-radio-group>
                </v-col>

                <v-col cols="12">
                  <v-checkbox
                    label="Acepto los Términos y Condiciones de uso de medios electrónicos y el tratamiento de mis datos personales de acuerdo a lo establecido en el Aviso de Privacidad."
                    v-model="cotizacionCelular.termYCond" :rules="rules.inputRequeridoRules"></v-checkbox>
                </v-col>
              </v-row>
            </v-form>
          </v-card>

          <v-btn :loading="loadingButton" :disabled="!validarBotonGuardarCotizacion || loadingButton" color="primary"
            @click="sendCotizacion()">
            Cotizar
          </v-btn>
        </template>
      </v-container>

      <!-- Dialog with cotization details -->
      <div class="text-center">
        <v-dialog v-model="dialogCotizationDetail" width="500" persistent>
          <v-card>
            <v-card-title class="text-h5 orange lighten-2">
              Contrata tu Seguro Celular por: <br />
              ${{ cotizacionDetail.total }} / al año
            </v-card-title>

            <v-card-text class="mt-2">
              <ul>
                <li>Modelo: {{ cotizacionDetail.modelo }}</li>
                <li>Costo del celular: ${{ cotizacionDetail.precioFactura }}</li>
                <li>Contacto: {{ cotizacionDetail.nombre + " " + cotizacionDetail.apellidoPaterno + " " +
                    cotizacionDetail.apellidoMaterno
                }}</li>
                <li>Celular: {{ cotizacionDetail.telefono }}</li>
                <li>Correo: {{ cotizacionDetail.email }}</li>
                <li>Código Cotización: {{ cotizacionDetail.cotizacionGeneral }}</li>
              </ul>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="dialogCotizationDetail = false, guardarCotizacion()">
                Guardar Cotización
              </v-btn>
              <v-btn color="primary" text @click="dialogCotizationDetail = false, dialogEmisionForm = true">
                Emitir
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
      <!-- End Dialog with cotization details -->

      <!-- Dialog for Emite insurance -->
      <div class="text-center">
        <v-dialog v-model="dialogEmisionForm" persistent max-width="900px">
          <v-card>
            <v-card-title>
              <span class="text-h5" style="color: brown">Emitir Seguro Celular</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-form ref="emisionForm">
                  <v-row>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field label="Marca y Modelo del Celular" v-model="cotizacionDetail.modelo" readonly
                        :rules="rules.inputRequeridoRules"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field label="Precio del celular" v-model="cotizacionDetail.precioFactura" readonly
                        :rules="rules.inputRequeridoRules"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field label="Fecha de compra del equipo (dd/mm/aaaa)" v-model="emisionForm.fechaCompra"
                        :rules="rules.inputRequeridoRules" v-mask="'##/##/####'"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field label="Costo del Seguro Anual" v-model="cotizacionDetail.total" readonly
                        :rules="rules.inputRequeridoRules"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field label="Nombre(s)" v-model="cotizacionDetail.nombre" readonly
                        :rules="rules.inputRequeridoRules"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field label="Apellido Paterno" v-model="cotizacionDetail.apellidoPaterno" readonly
                        :rules="rules.inputRequeridoRules"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field label="Apellido Materno" v-model="cotizacionDetail.apellidoMaterno" readonly
                        :rules="rules.inputRequeridoRules"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field label="Fecha de Nacimiento (dd/MM/aaaa)" v-model="emisionForm.fechaNacimiento"
                        v-mask="'##/##/####'" :rules="rules.inputRequeridoRules"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <p>Género</p>
                      <v-radio-group v-model="emisionForm.genero" mandatory :rules="rules.inputRequeridoRules">
                        <v-radio label="Hombre" value="Masculino" color="brown"></v-radio>
                        <v-radio label="Mujer" value="Femenino" color="brown"></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field label="RFC" v-model="emisionForm.rfc" :rules="rulesRfc" v-mask="'XXXX######XXX'">
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field label="Código Postal" v-model="cotizacionDetail.codigoPostal" readonly
                        :rules="rules.inputRequeridoRules"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-select label="Estado de la República" v-model="cotizacionDetail.estadoId" :items="estados"
                        item-text="estadoRepublica" item-value="id" readonly :rules="rules.inputRequeridoRules">
                      </v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field label="Alcaldía o Municipio" v-model="emisionForm.alcaldiaMunicipio"
                        :rules="rules.inputRequeridoRules"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field label="Colonia o Fraccionamiento" v-model="emisionForm.coloniaFraccionamiento"
                        :rules="rules.inputRequeridoRules"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field label="Calle" v-model="emisionForm.calle" :rules="rules.inputRequeridoRules">
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field label="Número Exterior" v-model="emisionForm.numExterior" v-mask="'####'">
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field label="Número Interior" v-model="emisionForm.numInterior"></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="6" md="4">
                      <p>Opción de Pago</p>
                      <v-radio-group v-model="emisionForm.opcionPago" mandatory>
                        <v-radio label="Un solo pago" value="tpv_12" color="brown"></v-radio>
                        <v-radio label="12 Meses sin intereses" value="tpv_12_finance" color="brown"></v-radio>
                      </v-radio-group>
                    </v-col>

                    <v-col cols="12" sm="6" md="4">

                    </v-col>
                    <v-col cols="12">
                      <v-checkbox
                        label="Acepto los Términos y Condiciones de uso de medios electrónicos y el tratamiento de mis datos personales de acuerdo a lo establecido en el Aviso de Privacidad"
                        v-model="emisionForm.termYCond" :rules="rules.inputRequeridoRules" color="brown"></v-checkbox>
                    </v-col>
                    <v-col cols="12">
                      <v-checkbox
                        label="Acepto el cobro y/o domiciliación requerida para la contratación del seguro y envío del material contractual a mi correo electrónico registrado"
                        v-model="emisionForm.cobroDomici" :rules="rules.inputRequeridoRules" color="brown"></v-checkbox>
                    </v-col>
                  </v-row>
                </v-form>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="orange" :disabled="!validarBotonGuardarEmision" outlined @click="openWarningModal()">
                Emitir
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
      <!-- End Dialog for Emite insurance -->

      <!-- Dialog for warning the payment window -->
      <div class="text-center">
        <v-dialog v-model="warningModalPayWindow" persistent max-width="290">
          <v-card>
            <v-card-title class="text-h5">
              Redirección pasarela de pago
            </v-card-title>
            <v-card-text>A partir de este momento se ingresará a una página externa al Portal de Segurify, que es una
              pasarela de pago segura.</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="green darken-1" text :loading="loadingButtonEmitir" :disabled="loadingButtonEmitir"
                @click="loadingButtonEmitir = true, sendEmision()">
                De acuerdo
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
      <!-- End Dialog for warning the payment window -->

      <!-- Dialog for Error in cotization -->
      <div>
        <v-dialog v-model="dialogError" max-width="500px">
          <v-card>
            <v-card-title>
              <span>Error para generar la cotización</span>
              <v-spacer></v-spacer>
            </v-card-title>

            <v-card-text>
              <p class="error-text">Asegúrese de que los datos ingresados estén completos y correctos en todos los
                campos del formulario</p>
            </v-card-text>

            <v-card-actions>
              <v-btn color="primary" text @click="dialogError = false">
                Cerrar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
      <!-- End Dialog for Error in cotization -->
      <HelpCelular />
    </v-main>
  </v-app>
</template>

<script>
import NavCelular from "../../../components/NavLandingCelular.vue";
import { mainAxios } from '../../../mainAxios';
import { smartPhonesModels } from '../../../helpers/index'
import HelpCelular from "./HelpCelular.vue";
export default {
  components: {
    NavCelular,
    HelpCelular
  },
  data: () => ({
    color: "",
    flat: null,
    step: 1,
    tipoSeguro: true,
    loadingButton: false,
    loadingButtonEmitir: false,
    loading: false,
    dialogCotizationDetail: false,
    dialogError: false,
    dialogEmisionForm: false,
    warningModalPayWindow: false,
    flagPaymentWindow: false,
    cotizacionCelular: {
      marcaModelo: null,
      nombre: null,
      precioFactura: null,
      apellidoPaterno: null,
      apellidoMaterno: null,
      celular: null,
      email: null,
      codigoPostal: null,
      estadoRepublica: null,
      termYCond: false,
      opcionPago: null,
    },
    emisionForm: {
      fechaNacimiento: null,
      genero: null,
      rfc: '',
      alcaldiaMunicipio: null,
      coloniaFraccionamiento: null,
      calle: null,
      fechaCompra: null,
      numExterior: null,
      numInterior: null,
      opcionPago: null,
      termYCond: false,
      cobroDomici: false,
    },
    cotizacionDetail: {},
    rulesRfc: [ 
          v => !!v || "Este campo es requerido", 
          v => ( v && v.length >= 13 ) || "Este campo debe contener 13 caracteres, sino cuenta con homoclave colocar XXX",
          v => ( v && v.length <= 13 ) || "Este campo debe contener 13 caracteres, sino cuenta con homoclave colocar XXX",
    ],
    rules: {
      nombreRules: [
        v => !!v || 'Nombre es requerido',
        v => (v && v.length >= 3) || 'Nombre debe tener por lo menos 3 caracteres',
      ],
      precioRules: [
        v => !!v || 'Precio del celular es requerido',
        v => (v && v.length >= 2) || 'El precio debe tener por lo menos 2 caracteres',
        v => (!isNaN(parseFloat(v))) || 'El precio debe ser solo numérico',
      ],
      apellidosRules: [
        v => !!v || 'Apellidos es requerido',
        v => (v && v.length >= 3) || 'Los apellidos debe tener por lo menos 3 caracteres',
      ],
      celularRules: [
        v => !!v || 'Celular es requerido(10 Caracteres)',
      ],
      emailRules: [
        v => !!v || 'E-mail es requerido',
        v => /.+@.+\..+/.test(v) || 'Ingrese dirección de E-mail valida',
      ],
      inputRequeridoRules: [
        v => !!v || 'Campo requerido',
      ],
    },
    estados: [
      { id: 1, estadoRepublica: "Aguascalientes" },
      { id: 2, estadoRepublica: "Baja California" },
      { id: 3, estadoRepublica: "Baja California Sur" },
      { id: 4, estadoRepublica: "Campeche" },
      { id: 5, estadoRepublica: "Coahuila de Zaragoza" },
      { id: 6, estadoRepublica: "Colima" },
      { id: 7, estadoRepublica: "Chiapas" },
      { id: 8, estadoRepublica: "Chihuahua" },
      { id: 9, estadoRepublica: "Ciudad de México" },
      { id: 10, estadoRepublica: "Durango" },
      { id: 11, estadoRepublica: "Guanajuato" },
      { id: 12, estadoRepublica: "Guerrero" },
      { id: 13, estadoRepublica: "Hidalgo" },
      { id: 14, estadoRepublica: "Jalisco" },
      { id: 15, estadoRepublica: "Estado de México" },
      { id: 16, estadoRepublica: "Michoacán de Ocampo" },
      { id: 17, estadoRepublica: "Morelos" },
      { id: 18, estadoRepublica: "Nayarit" },
      { id: 19, estadoRepublica: "Nuevo León" },
      { id: 20, estadoRepublica: "Oaxaca" },
      { id: 21, estadoRepublica: "Puebla" },
      { id: 22, estadoRepublica: "Querétaro" },
      { id: 23, estadoRepublica: "Quintana Roo" },
      { id: 24, estadoRepublica: "San Luis Potosí" },
      { id: 25, estadoRepublica: "Sinaloa" },
      { id: 26, estadoRepublica: "Sonora" },
      { id: 27, estadoRepublica: "Tabasco" },
      { id: 28, estadoRepublica: "Tamaulipas" },
      { id: 29, estadoRepublica: "Tlaxcala" },
      { id: 30, estadoRepublica: "Veracruz de Ignacio de la Llave" },
      { id: 31, estadoRepublica: "Yucatán" },
      { id: 32, estadoRepublica: "Zacatecas" },
    ],
    catalogoCelulares: [],
  }),

  methods: {
    openWarningModal() {
      if (!this.$refs.emisionForm.validate()) {
        return;
      }
      this.warningModalPayWindow = true;
    },
    cargarCatalogoCelulares() {
      this.catalogoCelulares = smartPhonesModels
    },
    //Metodo para generar la cotizacion mediante el primer form
    sendCotizacion() {
      if (!this.$refs.cotizacionForm.validate()) {
        return;
      }
      this.loadingButton = true
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        }
      }

      let request = {
        modelo: this.cotizacionCelular.marcaModelo,
        precio_factura: this.cotizacionCelular.precioFactura,
        nombre: this.cotizacionCelular.nombre,
        apellido_paterno: this.cotizacionCelular.apellidoPaterno,
        apellido_materno: this.cotizacionCelular.apellidoMaterno,
        telefono: this.cotizacionCelular.celular,
        email: this.cotizacionCelular.email,
        codigo_postal: this.cotizacionCelular.codigoPostal,
        estado_id: this.cotizacionCelular.estadoRepublica,
        portal: "Agentes",
        partner: "Segurify",
        agente_uuid: localStorage.agenteUuid,
        producto: "236",
        periodicity: 12,
        pago_id: this.cotizacionCelular.opcionPago == 'tpv_12' ? 8 : 1,
      }

      mainAxios.post('/v1/seguro-celular/cotiza', request, config)
        .then(response => {
          if (response.status == 200) {
            this.cotizacionDetail = response.data
            this.loadingButton = false
            this.dialogCotizationDetail = true
          } else {
            this.loadingButton = false
          }
        })
        .catch(error => {
          if (error) {
            this.dialogError = true
            this.loadingButton = false
          }
        })
    },
    //Metodo para mandar la emision desde el form del modal dialogEmisionForm
    sendEmision() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        }
      }

      let request = {
        modelo: this.cotizacionDetail.modelo,
        marca: " ",
        precio_factura: this.cotizacionDetail.precioFactura,
        nombre: this.cotizacionDetail.nombre,
        apellido_paterno: this.cotizacionDetail.apellidoPaterno,
        apellido_materno: this.cotizacionDetail.apellidoMaterno,
        telefono: this.cotizacionDetail.telefono,
        email: this.cotizacionDetail.email,
        codigo_postal: this.cotizacionDetail.codigoPostal,
        ciudad: this.emisionForm.alcaldiaMunicipio,
        colonia: this.emisionForm.coloniaFraccionamiento,
        estado: this.cotizacionDetail.estadoId,
        calle: this.emisionForm.calle,
        numero_exterior: parseInt(this.emisionForm.numExterior),
        fecha_nacimiento: this.emisionForm.fechaNacimiento,
        fecha_compra: this.emisionForm.fechaCompra,
        rfc: this.emisionForm.rfc,
        estado_id: this.cotizacionDetail.estadoId,
        origen: "Portal",
        partner: "Segurify",
        agente_id: this.cotizacionDetail.agenteId,
        genero: this.emisionForm.genero,
        payment_method: this.emisionForm.opcionPago,
        periodicity: 12,
        pago_id: this.emisionForm.opcionPago == 'tpv_12' ? 8 : 1,
        cotizacion_uuid: this.cotizacionDetail.uuid
      }

      mainAxios.post("/v1/seguro-celular/emite", request, config)
        .then(response => {
          if (response.status == 200) {
            this.loadingButtonEmitir = false
            this.warningModalPayWindow = false
            window.open(response.data.card_payment_URL, "Pago de Seguro", "width=750, height=750")
            setTimeout(() => (this.$router.push(`/inicio-celular/${response.data.policy.identifier}/emision/${response.data.id_emision[0]}`)), 2000)
          }
          console.log("Emision: ", response)
        }).catch((exception) => {
          console.error(exception);
          this.warningModalPayWindow = false
          this.loadingButtonEmitir = false
        });
    },
    guardarCotizacion() {
      this.$router.push('/mis-cotizaciones-celular')
    },
  },

  computed: {
    validarBotonGuardarCotizacion() {
      return (
        this.cotizacionCelular.marcaModelo != null &&
        this.cotizacionCelular.nombre != null &&
        this.cotizacionCelular.precioFactura != null &&
        this.cotizacionCelular.apellidoPaterno != null &&
        this.cotizacionCelular.apellidoMaterno != null &&
        this.cotizacionCelular.celular != null &&
        this.cotizacionCelular.email != null &&
        this.cotizacionCelular.codigoPostal != null &&
        this.cotizacionCelular.estadoRepublica != null &&
        this.cotizacionCelular.termYCond != false
      )
    },
    validarBotonGuardarEmision() {
      return (
        this.emisionForm.fechaNacimiento != null &&
        this.emisionForm.genero != null &&
        this.emisionForm.cobroDomici != false &&
        this.emisionForm.rfc != '' &&
        this.emisionForm.rfc.length == 13 &&
        this.emisionForm.alcaldiaMunicipio != null &&
        this.emisionForm.coloniaFraccionamiento != null &&
        this.emisionForm.calle != null &&
        this.emisionForm.numExterior != null &&
        this.emisionForm.opcionPago != null &&
        this.emisionForm.termYCond != false
      )
    }
  },

  mounted() {
    this.cargarCatalogoCelulares()
  }
};
</script>

<style scoped>
.first-box {
  justify-content: center;
  align-items: center;
  border-radius: 15px;
}

.form-container {
  padding: 10px;
  margin: 10px;
}

#first-title {
  color: brown;
  text-align: center;
  margin-bottom: 30px;
}

#inside-card {
  border-radius: 15px;
}

h4 {
  margin: 20px;
}

.error-text {
  font-weight: bold;
  font-size: 26;
  margin: 0;
  padding: 0;
}
</style>
