<template>
  <v-app>
    <Navigation></Navigation>
    <template>
      <div>
        <v-row class="mb-14 fill-height">
          <v-col cols="12">
            <v-card>
              <v-card-title primary-title>
                Lead: {{ lead.id }}
                <v-spacer></v-spacer>
                <v-btn
                  @click="$router.go(-1)"
                  elevation="2"
                  style="background-color: #ff0042; color: white;"
                >
                  FINALIZAR
                </v-btn>
              </v-card-title>
            </v-card>
          </v-col>
          <v-col cols="12">
            <v-row>
              <v-col cols="12" sm="12" md="7" lg="7" xl="7">
                <v-card>
                  <v-tabs
                    v-model="tab"
                    centered
                    icons-and-text
                    style="background-color:#9dc6fe;"
                  >
                    <v-tabs-slider></v-tabs-slider>

                    <v-tab @click="changeTab(1)">
                      Datos lead
                    </v-tab>

                    <v-tab @click="changeTab(2)" v-if="endosoInfo.length > 0">
                      Historial de cambios
                    </v-tab>
                  </v-tabs>
                </v-card>
                <v-card v-if="currentTab == 1">
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" style="text-align: end;">
                        <v-btn class="mr-4" color="primary" dark @click="toBlacklistCheck" v-if="(rol == 'MANAGERCC' || rol == 'ADMINCC' || rol == 'ADMIN') && lead.telefono != null">
                          Enviar telefono a lista negra
                        </v-btn>
                        <v-btn color="primary" dark @click="abrirModal">
                          Editar
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="4">
                        <v-text-field
                          label="Estatus"
                          v-model="lead.status"
                          placeholder="Requerido"
                          readonly
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="4">
                        <v-text-field
                          v-if="rol != 'AGENTCC'"
                          label="Medio"
                          v-model="lead.medio"
                          placeholder="Requerido"
                          readonly
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="4">
                        <v-text-field
                          v-if="rol != 'AGENTCC'"
                          label="Producto"
                          v-model="lead.producto"
                          placeholder="Requerido"
                          readonly
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="4">
                        <v-text-field
                          label="Regimen"
                          v-model="lead.regimen"
                          placeholder="Requerido"
                          readonly
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="4">
                        <v-text-field
                          label="RFC"
                          v-model="lead.rfc"
                          readonly
                          placeholder="Requerido"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="4" v-if="lead.regimen == 'Moral'">
                        <v-text-field
                          label="Razon social"
                          v-model="lead.razon_social"
                          readonly
                          placeholder="Requerido"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="4">
                        <v-text-field
                          label="Nombre"
                          v-model="lead.nombre"
                          readonly
                          placeholder="Requerido"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="4">
                        <v-text-field
                          label="Apellido paterno"
                          v-model="lead.apellidoPaterno"
                          readonly
                          placeholder="Requerido"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="4">
                        <v-text-field
                          label="Apellido materno"
                          v-model="lead.apellidoMaterno"
                          readonly
                          placeholder="Requerido"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="3">
                        <v-text-field
                          label="Fecha Nacimiento"
                          v-model="lead.fechaNacimiento"
                          placeholder="Requerido"
                          readonly
                        ></v-text-field>
                      </v-col>
                      <v-col cols="3">
                        <v-text-field
                          label="Sexo"
                          v-model="lead.sexo"
                          readonly
                          placeholder="Requerido"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="3">
                        <v-text-field
                          label="Estado Civil"
                          v-model="lead.estadoCivil"
                          readonly
                          placeholder="Requerido"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="3">
                        <v-text-field
                          label="Edad"
                          v-model="lead.edad"
                          readonly
                          placeholder="Requerido"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="4">
                        <v-text-field
                          label="Telefono"
                          v-model="lead.telefono"
                          readonly
                          placeholder="Requerido"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="4">
                        <v-text-field
                          label="Correo"
                          v-model="lead.correo"
                          readonly
                          placeholder="Requerido"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="3">
                        <v-text-field
                          label="Calle"
                          v-model="lead.calle"
                          readonly
                          placeholder="Requerido"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="3">
                        <v-text-field
                          label="Numero exterior"
                          v-model="lead.num_exterior"
                          readonly
                          placeholder="Requerido"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="3">
                        <v-text-field
                          label="Numero interior"
                          v-model="lead.num_interior"
                          readonly
                          placeholder="Requerido"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="3">
                        <v-text-field
                          label="Código Postal"
                          v-model="lead.codigoPostal"
                          readonly
                          placeholder="Requerido"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="3">
                        <v-text-field
                          label="Colonia"
                          v-model="lead.colonia"
                          readonly
                          placeholder="Requerido"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="3">
                        <v-text-field
                          label="Ciudad"
                          v-model="lead.ciudad"
                          readonly
                          placeholder="Requerido"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="3">
                        <v-autocomplete
                          label="Estado"
                          placeholder="Requerido"
                          :items="estados"
                          item-text="name"
                          item-value="id"
                          readonly
                          v-model="lead.estado"
                        >
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="3">
                        <v-text-field
                          label="Pais"
                          v-model="lead.pais"
                          readonly
                          placeholder="Requerido"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <v-card v-if="currentTab == 2">
                  <v-simple-table fixed-header height="300px">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">
                            Usuario
                          </th>
                          <th class="text-left">
                            Detalles
                          </th>
                          <th class="text-left">
                            Fecha
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item in endosoInfo" :key="item.user">
                          <td>{{ item.user }}</td>
                          <td>{{ item.detalleCambios }}</td>
                          <td>{{ item.fechaCambio }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card>
              </v-col>

              <v-col cols="12" sm="12" md="5" lg="5" xl="5">
                <v-row>
                  <v-col cols="12">
                    <CampañasLead :item="item" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <Llamadas :llamadas_info="llamadas" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <SolicitudesLead
                      @openSolicitudModal="openSolicitudModal"
                      :solicitudes="solicitudes"
                      :lead="lead"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <PolizasLead
                      @openPolizasModal="openPolizasModal"
                      :emisiones="emisiones"
                      :lead="lead"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <NotasLead
                      @openDocumentosModal="openDocumentalModal"
                      :documentos="leadDocuments"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <EmailLead />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <div v-show="successlEmbeddedCheck">
          <Embedded
            :leadOrigen="item"
            :lead="lead"
            @getLlamadas="getLlamadas"
            @openSolicitudModal="openSolicitudModal"
            @successEmbedded="successEmbedded"
            @leadIncomplete="leadIncomplete"
            @openPolizasModal="openPolizasModal"
          />
        </div>
        <LeadDetallesmodal
          :dialog="dialog"
          :leadOrigen="item"
          :saveChanges="saveChanges"
          @close_dialog="closeModal"
          @lead_actualizado="leadActualizado"
        >
        </LeadDetallesmodal>
        <SolicitudModal
          :dialogSolicitud="dialogSolicitud"
          @closeSolicitudModal="closeSolicitudModal"
          @SolicitudSuccess="SolicitudSuccess"
          :leadOrigen="lead"
        >
        </SolicitudModal>
        <DocumentosModal
          :leadOrigen="item"
          :dialogDocumentos="dialogDocumentos"
          :lead="lead"
          @closeDocumentosModal="closeDocumentosModal"
          @documentSuccess="documentSuccess"
        >
        </DocumentosModal>
        <PolizaModal
          :dialogPolizas="dialogPolizas"
          @closePolizasModal="closePolizasModal"
          @polizaCreada="polizaCreada"
          :leadOrigen="lead"
          :item="item"
        >
        </PolizaModal>
        <DetallesLeadOrigenMarcacion
          v-if="showDetailsMarcacion"
          :show="showDetailsMarcacion"
          :origenUuid="item.uuid"
          @close="closeDetailsMarcacion()"
        />
      </div>
    </template>
    <v-footer fixed style="text-align:center; background: #f6f9ff;">
      <p style="text-align:center;width:100%;">Segurify {{ currentAño }}</p>
    </v-footer>
  </v-app>
</template>

<script>
import Navigation from "../../../components/Nav.vue";
import Llamadas from "./LlamadasLead.vue";
import CampañasLead from "./CampañasLead.vue";
import SolicitudesLead from "./SolicitudesLead.vue";
import PolizasLead from "./PolizasLead.vue";
import NotasLead from "./NotasLead.vue";
import EmailLead from "./EmailLead.vue";
import Embedded from "./Embedded.vue";
import DocumentosModal from "./DocumentosModal.vue";
import LeadDetallesmodal from "./LeadDetallesmodal.vue";
import SolicitudModal from "./SolicitudModal.vue";
import PolizaModal from "./PolizaModal.vue";
import DetallesLeadOrigenMarcacion from "./DetallesLeadOrigenMarcacion.vue";
import { mainAxios } from "../../../mainAxios";
var moment = require("moment-timezone");

export default {
  components: {
    Navigation,
    Llamadas,
    CampañasLead,
    SolicitudesLead,
    PolizasLead,
    NotasLead,
    EmailLead,
    Embedded,
    LeadDetallesmodal,
    SolicitudModal,
    DocumentosModal,
    PolizaModal,
    DetallesLeadOrigenMarcacion,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      lead: {
        nombre: "",
        apellidopaterno: "",
        apellidomaterno: "",
        telefono: "",
        correo: "",
        campania_id: "",
        regimen: "",
        rfc: "",
        razon_social: "",
        edad: "",
        sexo: "",
        estado_civil: "",
        fecha_nacimiento: "",
        calle: "",
        num_interior: "",
        num_exterior: "",
        codigo_postal: "",
        colonia: "",
        ciudad: "",
        estado: "",
        pais: "",
        reus: 0,
        status: "",
      },
      dialog: false,
      dialogSolicitud: false,
      dialogDocumentos: false,
      estados: [
        { id: 1, name: "Aguascalientes" },
        { id: 2, name: "Baja California" },
        { id: 3, name: "Baja California Sur" },
        { id: 4, name: "Campeche" },
        { id: 5, name: "Coahuila de Zaragoza" },
        { id: 6, name: "Colima" },
        { id: 7, name: "Chiapas" },
        { id: 8, name: "Chihuahua" },
        { id: 9, name: "Ciudad de México" },
        { id: 10, name: "Durango" },
        { id: 11, name: "Guanajuato" },
        { id: 12, name: "Guerrero" },
        { id: 13, name: "Hidalgo" },
        { id: 14, name: "Jalisco" },
        { id: 15, name: "Estado de México" },
        { id: 16, name: "Michoacán de Ocampo" },
        { id: 17, name: "Morelos" },
        { id: 18, name: "Nayarit" },
        { id: 19, name: "Nuevo León" },
        { id: 20, name: "Oaxaca" },
        { id: 21, name: "Puebla" },
        { id: 22, name: "Querétaro" },
        { id: 23, name: "Quintana Roo" },
        { id: 24, name: "San Luis Potosí" },
        { id: 25, name: "Sinaloa" },
        { id: 26, name: "Sonora" },
        { id: 27, name: "Tabasco" },
        { id: 28, name: "Tamaulipas" },
        { id: 29, name: "Tlaxcala" },
        { id: 30, name: "Veracruz de Ignacio de la Llave" },
        { id: 31, name: "Yucatán" },
        { id: 32, name: "Zacatecas" },
      ],
      llamadas: [],
      solicitudes: [],
      emisiones: [],
      leadDocuments: [],
      successlEmbeddedCheck: false,
      diccionarioSexo: {
        M: "Masculino",
        F: "Femenino",
      },
      dialogPolizas: false,
      showDetailsMarcacion: false,
      rol: JSON.parse(localStorage.getItem("roles"))[0].rol,
      saveChanges: false,
      currentTab: 1,
      currentAño: moment().format("YYYY"),
      endosoInfo: {},
    };
  },
  methods: {
    getLead() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      this.leadDocuments = [];
      mainAxios
        .get("/v1/lead-segurify/uuid/" + this.item.uuid_lead_segurify, config)
        .then((response) => {
          this.lead = response.data;
          this.lead.sexo = this.diccionarioSexo[this.lead.sexo];
          if (response.data.documentos) {
            response.data.documentos.map((element) => {
              this.leadDocuments.push({
                nombre: element.nombre
                      ? element.nombre : 'Sin nombre',
                    fecha: element.fechaCreacion
                      ? moment
                          .utc(element.fechaCreacion)
                          .subtract(6, "hours")
                          .format("DD/MM/YYYY HH:mm:ss")
                      : "No Disponible",
                    tipo: element.tipoRegistro ? element.tipoRegistro : "No Disponible",
                    tipoDocumento: element.tipoDocumento
                      ? element.tipoDocumento
                      : "No Disponible",
                    descripcion: element.nota ? element.nota : 'Sin descripcion',
                    creadoPor: element.creadoPor
                      ? element.creadoPor
                      : "No Disponible",
                    url: element.url_bucket,
                    uuid: element.uuid,
              });
            });
          }
          this.endosoInfo = response.data.jsonCambios
            ? JSON.parse(response.data.jsonCambios)
            : "";
          this.getSolicitudes();
          this.getEmisiones();
        });
    },

    getLlamadas() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      this.llamadas = [];
      mainAxios
        .get(
          "/v1/accion/lead-segurify/list/" + this.item.uuid_lead_segurify,
          config
        )
        .then((response) => {
          response.data.map((element) => {
            this.llamadas.push({
              usuario: element.usuario,
              disposicion: element.disposicion,
              fecha: element.fecha
                ? moment
                    .utc(element.fecha)
                    .subtract(6, "hours")
                    .format("DD-MM-YYYY HH:mm:ss")
                : "No Disponible",
              uuid: element.uuid,
            });
          });
        });
    },

    getSolicitudes() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      this.solicitudes = [];
      mainAxios
        .get("/v1/solicitud/list/leadSegurify/" + this.lead.id, config)
        .then((response) => {
          response.data.map((element) => {
            this.solicitudes.push({
              id: element.id ? element.id : "",
              status: element.status ? element.status : "",
              uuid: element.uuid ? element.uuid : "",
              fecha: element.fechaCreacion
                ? moment
                    .utc(element.fechaCreacion)
                    .subtract(6, "hours")
                    .format("DD-MM-YYYY HH:mm:ss")
                : "",
              tipo: element.tipo ? element.tipo : "",
            });
          });
        });
    },
    getEmisiones() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      this.emisiones = [];
      mainAxios
        .get("/v1/emision/list/lead-segurify/" + this.lead.id, config)
        .then((response) => {
          response.data.map((element) => {
            element.rJSON = JSON.parse(element.requestJson);
            this.emisiones.push({
              id: element.id ? element.id : "",
              status:
                element.status == 0
                  ? "Nueva"
                  : element.status == 1
                  ? "Emitida Pendente de Pago"
                  : element.status == 2
                  ? "Pagada Parcial"
                  : element.status == 3
                  ? "Pagada Total"
                  : element.status == 4
                  ? "Renovada"
                  : element.status == 5
                  ? "Finalizada"
                  : element.status == 6
                  ? "Cancelada"
                  : "",
              agente: element.agenteId ? element.agenteId : "",
              fecha: element.fecha
                ? moment
                    .utc(element.fecha)
                    .subtract(6, "hours")
                    .format("DD-MM-YYYY HH:mm:ss")
                : "",
              campania: element.rJSON.campania,
              producto: element.nombreProducto,
              ramo: element.nombreRamo,
              aseguradora: element.aseguradoraNombre,
              operador: element.agenteNombre,
            });
            if (
              element.status == 1 ||
              element.status == 2 ||
              element.status == 3 ||
              element.status == 4 ||
              element.status == 5
            ) {
              this.saveChanges = true;
            }
          });
        });
    },
    successEmbedded() {
      this.successlEmbeddedCheck = true;
      if (this.item.marcacion_automatica) {
        this.showDetailsMarcacion = true;
      }
    },
    getDocumentos() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      this.emisiones = [];
      mainAxios
        .get("/v1/emision/list/lead-segurify/" + this.lead.id, config)
        .then((response) => {
          response.data.map((element) => {
            this.emisiones.push({
              id: element.id ? element.id : "",
              status:
                element.status == 0
                  ? "Nueva"
                  : element.status == 1
                  ? "Emitida Pendente de Pago"
                  : element.status == 2
                  ? "Pagada Parcial"
                  : element.status == 3
                  ? "Pagada Total"
                  : element.status == 4
                  ? "Renovada"
                  : element.status == 5
                  ? "Finalizada"
                  : element.status == 6
                  ? "Cancelada"
                  : "",
              agente: element.agenteId ? element.agenteId : "",
              fecha: element.fecha
                ? moment
                    .utc(element.fecha)
                    .subtract(6, "hours")
                    .format("DD-MM-YYYY HH:mm:ss")
                : "",
            });
          });
        });
    },
    abrirModal() {
      this.dialog = !this.dialog;
    },
    closeModal() {
      this.dialog = false;
    },
    openSolicitudModal() {
      this.dialogSolicitud = true;
    },
    closeSolicitudModal() {
      this.dialogSolicitud = false;
    },
    openDocumentalModal() {
      this.dialogDocumentos = true;
    },
    closeDocumentosModal() {
      this.dialogDocumentos = false;
    },
    leadActualizado() {
      this.dialog = false;
      this.getLead();
    },
    SolicitudSuccess() {
      this.dialogSolicitud = false;
      this.getSolicitudes();
      this.succesToast("Solicitud creada con exito!");
    },
    documentSuccess() {
      this.dialogDocumentos = false;
      this.getLead();
    },
    leadIncomplete() {
      this.$toast.warning(
        "Se deben completar los datos del lead antes de crear una poliza/cotizacion",
        {
          position: "top-right",
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        }
      );
    },
    openPolizasModal() {
      this.dialogPolizas = true;
    },
    closePolizasModal() {
      this.dialogPolizas = false;
    },
    polizaCreada() {
      this.closePolizasModal();
      this.getEmisiones();
      this.succesToast("Poliza creada con exito!");
    },
    succesToast(type) {
      this.$toast.success(type, {
        position: "top-right",
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    closeDetailsMarcacion() {
      this.showDetailsMarcacion = false;
    },
    changeTab(tab) {
      this.currentTab = tab;
    },
    toBlacklistCheck(){
      if(this.lead.telefono != null) {
        this.$swal({
            title: 'Estas seguro que quieres mandar a lista negra el numero: ' + this.lead.telefono,
            type: 'success',
            showCancelButton: true,
            confirmButtonText: 'Si, aplicalo.',
            cancelButtonText: 'No.',
            showCloseButton: true,
            showLoaderOnConfirm: true,
          }).then((result) => {
            if(result.value) {
              this.toChecklistConfirm()
            } 
          })
      }
    },
    toChecklistConfirm(){
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      var body = {
        telefono: this.lead.telefono
      } 
      mainAxios.post("/v1/listaNegra/agregar",body,config)
        .then(() => {
          this.succesToast("Telefono " + this.lead.telefono +" enviado a lista negra con exito");
          this.getMainInfo()
        });
    },
    getMainInfo(){
      this.getLead();
      this.getLlamadas();
    }

  },
  mounted() {
    this.getLead();
    this.getLlamadas();
  },
};
</script>
