<template>
  <v-main class="mb-15">
    <v-container>
      <v-row>
        <v-col>
          <p
            class="MsoNormal"
            style="margin-right:-28.4pt;mso-margin-bottom-alt:auto;
margin-left:-1.0cm;mso-outline-level:1;background:#F8FAFC"
          >
            <b
              ><span
                lang="ES-MX"
                style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:gray;mso-font-kerning:18.0pt;mso-fareast-language:ES-TRAD'
                >Aviso de Privacidad<o:p></o:p></span
            ></b>
          </p>

          <div
            class="MsoNormal"
            align="center"
            style="margin-top:0cm;margin-right:-28.4pt;
margin-bottom:0cm;margin-left:-1.0cm;margin-bottom:.0001pt;text-align:center;
background:#F8FAFC"
          >
            <span
              lang="ES-MX"
              style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:gray;mso-fareast-language:ES-TRAD;
mso-no-proof:yes'
            >
              <hr size="0" width="100%" align="center" />
            </span>
          </div>

          <p
            class="MsoNormal"
            style="margin-top:0cm;margin-right:-28.4pt;margin-bottom:
0cm;margin-left:-1.0cm;margin-bottom:.0001pt;text-align:justify;background:
#F8FAFC"
          >
            <span
              lang="ES-MX"
              style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:gray;mso-themecolor:background1;
mso-themeshade:128;mso-fareast-language:ES-TRAD'
              ><o:p>&nbsp;</o:p></span
            >
          </p>

          <p
            class="MsoNormal"
            style="margin-top:0cm;margin-right:-28.4pt;margin-bottom:
0cm;margin-left:-1.0cm;margin-bottom:.0001pt;text-align:justify;background:
#F8FAFC"
          >
            <span
              lang="ES-MX"
              style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:gray;mso-themecolor:background1;
mso-themeshade:128;mso-fareast-language:ES-TRAD'
              >Segurify de México, Agente de Seguros y de Fianzas S.A de C.V.
              (en lo sucesivo “Segurify”), con domicilio en Av. Miguel Ángel de
              Quevedo 722 Colonia Barrio la Concepción C.P. 04020 Alcaldía
              Coyoacán, Ciudad de México es el responsable de recabar sus datos
              personales, del uso que se le dé a los mismos y de su protección,
              esto en cumplimiento a lo dispuesto en la Ley Federal de
              Protección de Datos Personales en Posesión de los Particulares,
              pone a su disposición el presente Aviso de Privacidad.<o:p></o:p
            ></span>
          </p>

          <p
            class="MsoNormal"
            style="margin-top:0cm;margin-right:-28.4pt;margin-bottom:
0cm;margin-left:-1.0cm;margin-bottom:.0001pt;text-align:justify;background:
#F8FAFC"
          >
            <span
              lang="ES-MX"
              style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:gray;mso-themecolor:background1;
mso-themeshade:128;mso-fareast-language:ES-TRAD'
              ><br />
              <b>Uso de los Datos Personales<o:p></o:p></b
            ></span>
          </p>

          <p
            class="MsoNormal"
            style="margin-top:0cm;margin-right:-28.4pt;margin-bottom:
0cm;margin-left:-1.0cm;margin-bottom:.0001pt;text-align:justify;background:
#F8FAFC"
          >
            <span
              lang="ES-MX"
              style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:gray;mso-themecolor:background1;
mso-themeshade:128;mso-fareast-language:ES-TRAD'
              ><br />
              Los Datos Personales que usted proporcione de manera voluntaria a
              Segurify podrán ser usados por nosotros: (i) para proporcionarle
              nuestros servicios financieros, (ii) para proporcionarle
              información relativa a nuestros servicios de seguros y Fianzas,
              (iii) para efectos de mercadotecnia internos, y (iv) para mantener
              vigente y existente nuestra relación con usted y/o dar
              cumplimiento a los compromisos adquiridos. Segurify. podrá además
              hacer uso de sus Datos Personales para fines históricos y
              estadísticos, en cuyos casos no se requerirá Aviso de Privacidad
              alguno.<o:p></o:p
            ></span>
          </p>

          <p
            class="MsoNormal"
            style="margin-top:0cm;margin-right:-28.4pt;margin-bottom:
0cm;margin-left:-1.0cm;margin-bottom:.0001pt;text-align:justify;background:
#F8FAFC"
          >
            <span
              lang="ES-MX"
              style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:gray;mso-themecolor:background1;
mso-themeshade:128;mso-fareast-language:ES-TRAD'
              ><o:p>&nbsp;</o:p></span
            >
          </p>

          <p
            class="MsoNormal"
            style="margin-top:0cm;margin-right:-28.4pt;margin-bottom:
0cm;margin-left:-1.0cm;margin-bottom:.0001pt;text-align:justify;background:
#F8FAFC"
          >
            <span
              lang="ES-MX"
              style='font-size:11.0pt;font-family:"Arial",sans-serif;
color:gray;mso-themecolor:background1;mso-themeshade:128'
              >Los datos personales deberán recabarse y tratarse de manera
              lícita conforme a las disposiciones establecidas en la </span
            ><span
              lang="ES-MX"
              style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:gray;mso-themecolor:background1;
mso-themeshade:128;mso-fareast-language:ES-TRAD'
              >Ley Federal de Protección de Datos Personales en Posesión de los
              Particulares</span
            ><span
              lang="ES-MX"
              style='font-size:11.0pt;font-family:"Arial",sans-serif;color:gray;mso-themecolor:
background1;mso-themeshade:128'
            >
              y demás normatividad aplicable. La obtención de datos personales
              no debe hacerse a través de medios engañosos o fraudulentos. En
              todo tratamiento de datos personales, se presume que existe la
              expectativa razonable de privacidad, entendida como la confianza
              que deposita cualquier persona en otra, respecto de que los datos
              personales proporcionados entre ellos serán tratados conforme a lo
              que acordaron las partes en los términos actualmente
              establecidos.</span
            ><span
              lang="ES-MX"
              style='font-size:11.0pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:gray;mso-themecolor:background1;mso-themeshade:128;mso-fareast-language:
ES-TRAD'
              ><o:p></o:p
            ></span>
          </p>

          <p
            class="MsoNormal"
            style="margin-top:0cm;margin-right:-28.4pt;margin-bottom:
0cm;margin-left:-1.0cm;margin-bottom:.0001pt;text-align:justify;background:
#F8FAFC"
          >
            <span
              lang="ES-MX"
              style='font-size:11.0pt;font-family:"Arial",sans-serif;
color:gray;mso-themecolor:background1;mso-themeshade:128;background:white'
              ><o:p>&nbsp;</o:p></span
            >
          </p>

          <p
            class="MsoNormal"
            style="margin-top:0cm;margin-right:-28.4pt;margin-bottom:
0cm;margin-left:-1.0cm;margin-bottom:.0001pt;text-align:justify;background:
#F8FAFC"
          >
            <b
              ><span
                lang="ES-MX"
                style='font-size:11.0pt;font-family:"Arial",sans-serif;
color:gray;mso-themecolor:background1;mso-themeshade:128;background:white'
                >¿Qué datos personales obtenemos?<o:p></o:p></span
            ></b>
          </p>

          <p
            class="MsoNormal"
            style="margin-top:0cm;margin-right:-28.4pt;margin-bottom:
0cm;margin-left:-1.0cm;margin-bottom:.0001pt;text-align:justify;background:
#F8FAFC"
          >
            <span
              lang="ES-MX"
              style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:gray;mso-themecolor:background1;
mso-themeshade:128;mso-fareast-language:ES-TRAD'
              ><o:p>&nbsp;</o:p></span
            >
          </p>

          <p
            class="MsoNormal"
            style="margin-top:0cm;margin-right:-28.4pt;margin-bottom:
0cm;margin-left:-1.0cm;margin-bottom:.0001pt;text-align:justify;background:
#F8FAFC"
          >
            <span
              lang="ES-MX"
              style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:gray;mso-themecolor:background1;
mso-themeshade:128;mso-fareast-language:ES-TRAD'
              >Datos personales que podrán ser recabados y tratados para llevar
              a cabo las finalidades descritas en el presente Aviso de
              Privacidad, sus datos personales que podrán ser recabados y
              sujetos a tratamiento son: 1) Nombre; 2) Domicilio; 3) Teléfono
              fijo y/o celular; 4) Correo electrónico; 5) Edad; 6) Género; 7)
              Copia de identificación oficial; 8) Registro Federal de
              Contribuyentes; y 9) CURP.<o:p></o:p
            ></span>
          </p>

          <p
            class="MsoNormal"
            style="margin-top:0cm;margin-right:-28.4pt;margin-bottom:
0cm;margin-left:-1.0cm;margin-bottom:.0001pt;text-align:justify;background:
#F8FAFC"
          >
            <span
              lang="ES-MX"
              style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:gray;mso-themecolor:background1;
mso-themeshade:128;mso-fareast-language:ES-TRAD'
              ><o:p>&nbsp;</o:p></span
            >
          </p>

          <p
            class="MsoNormal"
            style="margin-top:0cm;margin-right:-28.4pt;margin-bottom:
0cm;margin-left:-1.0cm;margin-bottom:.0001pt;text-align:justify;background:
#F8FAFC"
          >
            <b
              ><span
                lang="ES-MX"
                style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:gray;mso-themecolor:background1;
mso-themeshade:128;mso-fareast-language:ES-TRAD'
                >Limitación al Uso de Datos Personales:<o:p></o:p></span
            ></b>
          </p>

          <p
            class="MsoNormal"
            style="margin-top:0cm;margin-right:-28.4pt;margin-bottom:
0cm;margin-left:-1.0cm;margin-bottom:.0001pt;text-align:justify;background:
#F8FAFC"
          >
            <span
              lang="ES-MX"
              style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:gray;mso-themecolor:background1;
mso-themeshade:128;mso-fareast-language:ES-TRAD'
              ><br />
              Usted podrá limitar el uso y/o divulgación de sus Datos
              Personales, incluyendo sin limitar, su negativa a recibir
              comunicados y promociones por parte de Segurify mediante el
              procedimiento establecido más adelante bajo el rubro de “Acceso,
              Actualización, Rectificación, Cancelación de Datos Personales,
              Limitación de Uso y/o Revocación del Consentimiento”. En caso de
              otorgar su consentimiento, usted lo podrá revocar mediante el
              procedimiento establecido más adelante bajo el rubro “Acceso,
              Actualización, Rectificación y Cancelación de Datos Personales,
              Limitación de Uso y/o Revocación del Consentimiento”.<o:p></o:p
            ></span>
          </p>

          <p
            class="MsoNormal"
            style="margin-top:0cm;margin-right:-28.4pt;margin-bottom:
0cm;margin-left:-1.0cm;margin-bottom:.0001pt;text-align:justify;background:
#F8FAFC"
          >
            <span
              lang="ES-MX"
              style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:gray;mso-themecolor:background1;
mso-themeshade:128;mso-fareast-language:ES-TRAD'
              ><o:p>&nbsp;</o:p></span
            >
          </p>

          <p
            class="MsoNormal"
            style="margin-top:0cm;margin-right:-28.4pt;margin-bottom:
0cm;margin-left:-1.0cm;margin-bottom:.0001pt;text-align:justify;background:
#F8FAFC"
          >
            <b
              ><span
                lang="ES-MX"
                style='font-size:11.0pt;font-family:"Arial",sans-serif;
color:gray;mso-themecolor:background1;mso-themeshade:128'
                >Seguridad de los datos personales</span
              ></b
            ><span
              lang="ES-MX"
              style='font-size:11.0pt;font-family:
"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";color:gray;
mso-themecolor:background1;mso-themeshade:128;mso-fareast-language:ES-TRAD'
              ><o:p></o:p
            ></span>
          </p>

          <p
            style="margin-top:5.0pt;margin-right:-28.4pt;margin-bottom:5.0pt;margin-left:
-1.0cm;text-align:justify;background:white"
          >
            <span
              lang="ES-MX"
              style='font-size:
11.0pt;font-family:"Arial",sans-serif;color:gray;mso-themecolor:background1;
mso-themeshade:128'
              >“SEGURIFY” implementará las medidas de seguridad, técnicas,
              administrativas y físicas, necesarias para procurar la integridad
              de sus datos personales y evitar su daño, pérdida, alteración,
              destrucción o el uso, acceso o tratamiento no autorizado.<br />
              Las vulneraciones de seguridad que pudieran suceder en cualquier
              fase del tratamiento, que llegaran a afectar de forma
              significativa los derechos patrimoniales o morales de los
              titulares, serán informados de forma inmediata por “SEGURIFY” al
              titular, a fin de que este último pueda tomar las medidas
              correspondientes a la defensa de sus derechos tal y como lo señala
              el artículo 20 de la Ley.<o:p></o:p
            ></span>
          </p>

          <p
            style="margin-top:5.0pt;margin-right:-28.4pt;margin-bottom:5.0pt;margin-left:
-1.0cm;text-align:justify;background:white"
          >
            <span
              lang="ES-MX"
              style='font-size:
11.0pt;font-family:"Arial",sans-serif;color:gray;mso-themecolor:background1;
mso-themeshade:128'
              ><br />
              “SEGURIFY”, por conducto de sus accionistas, directivos,
              funcionarios, apoderados, empleados y prestadores de servicios,
              guardará confidencialidad respecto de los datos personales de los
              titulares, confidencialidad que subsistirá aún después de
              terminada la relación. “SEGURIFY”, por conducto de sus
              accionistas, directivos, funcionarios, apoderados, empleados y
              prestadores de servicios tiene prohibido permitir el acceso de
              personas no autorizadas y utilizar los datos personales de los
              titulares para fines distintos a los establecidos en el presente
              Aviso de Privacidad.<o:p></o:p
            ></span>
          </p>

          <p
            class="MsoNormal"
            style="margin-top:0cm;margin-right:-28.4pt;margin-bottom:
0cm;margin-left:-1.0cm;margin-bottom:.0001pt;text-align:justify;background:
#F8FAFC"
          >
            <b
              ><span
                lang="ES-MX"
                style='font-size:11.0pt;font-family:"Arial",sans-serif;
color:gray;mso-themecolor:background1;mso-themeshade:128'
                >Transmisión y Transferencia de datos personales</span
              ></b
            ><b
              ><span
                lang="ES-MX"
                style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:gray;mso-themecolor:background1;mso-themeshade:128;
mso-fareast-language:ES-TRAD'
                ><o:p></o:p></span
            ></b>
          </p>

          <p
            style="margin-top:5.0pt;margin-right:-28.4pt;margin-bottom:5.0pt;margin-left:
-1.0cm;text-align:justify;background:white"
          >
            <span
              lang="ES-MX"
              style='font-size:
11.0pt;font-family:"Arial",sans-serif;color:gray;mso-themecolor:background1;
mso-themeshade:128'
              >Como parte de las operaciones propias del negocio y a fin de
              cumplir con las finalidades descritas, “SEGURIFY” podrá compartir
              con terceros, nacionales o extranjeros, algunos o todos sus datos
              personales. Dichos terceros podrán ser compañías matrices,
              afiliadas o subsidiarias; Instituciones, organizaciones o
              entidades del sector financiero y de seguros mexicano y/o
              extranjero con la finalidad de dar cumplimiento a la relación
              jurídica que se iniciará o se tenga con el titular, y en general,
              cualquier tercero que actúe como encargado a nombre y por cuenta
              de la empresa, transferencias que de acuerdo con el artículo 37 de
              la Ley no requieren consentimiento expreso del titular.<o:p></o:p
            ></span>
          </p>

          <p
            style="margin-top:5.0pt;margin-right:-28.4pt;margin-bottom:5.0pt;margin-left:
-1.0cm;text-align:justify;background:white"
          >
            <span
              lang="ES-MX"
              style='font-size:
11.0pt;font-family:"Arial",sans-serif;color:gray;mso-themecolor:background1;
mso-themeshade:128'
              ><br />
              Asimismo, “SEGURIFY”, se asegurará, que dichos terceros mantengan
              medidas de seguridad, administrativas, técnicas y físicas
              adecuadas para resguardar sus datos personales, así como que
              dichos terceros únicamente utilicen sus datos personales para las
              finalidades para los cuales fueron recabados y de conformidad con
              el presente Aviso de Privacidad, por lo que se les notificará este
              Aviso de Privacidad y las finalidades a las que el titular sujetó
              su tratamiento y el tercero asumirá las mismas obligaciones que
              correspondan al responsable o transmisor de los datos, por lo que
              el tratamiento de los datos se hará conforme a lo convenido en
              este Aviso de Privacidad.<o:p></o:p
            ></span>
          </p>

          <p
            style="margin-top:5.0pt;margin-right:-28.4pt;margin-bottom:5.0pt;margin-left:
-1.0cm;text-align:justify;background:white"
          >
            <span
              lang="ES-MX"
              style='font-size:
11.0pt;font-family:"Arial",sans-serif;color:gray;mso-themecolor:background1;
mso-themeshade:128'
              >Asimismo, “SEGURIFY” podrá transferir sus datos personales a
              terceros, nacionales o extranjeros, sin requerir consentimiento
              del titular cuando dicha comunicación de datos esté prevista en
              una Ley o Tratado, o bien, cuando sea requerido por resolución de
              autoridad competente de acuerdo con el artículo 37 de la Ley. No
              obstante, lo anterior “SEGURIFY” no cederá, venderá o transferirá
              sus datos personales a terceros no relacionados con la empresa,
              salvo en los casos antes citados y los previstos en el artículo 10
              de la Ley.<o:p></o:p
            ></span>
          </p>

          <p
            class="MsoNormal"
            style="margin-top:0cm;margin-right:-28.4pt;margin-bottom:
0cm;margin-left:-1.0cm;margin-bottom:.0001pt;text-align:justify;background:
#F8FAFC"
          >
            <b
              ><span
                lang="ES-MX"
                style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:gray;mso-themecolor:background1;
mso-themeshade:128;mso-fareast-language:ES-TRAD'
                >Cambios a Nuestras Políticas de Privacidad:<o:p></o:p></span
            ></b>
          </p>

          <p
            class="MsoNormal"
            style="margin-top:0cm;margin-right:-28.4pt;margin-bottom:
0cm;margin-left:-1.0cm;margin-bottom:.0001pt;text-align:justify;background:
#F8FAFC"
          >
            <span
              lang="ES-MX"
              style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:gray;mso-themecolor:background1;
mso-themeshade:128;mso-fareast-language:ES-TRAD'
              ><br />
              El presente Aviso de Privacidad podrá ser modificado
              periódicamente y sin previo aviso para reflejar los cambios en las
              prácticas de uso y recopilación de información de Segurify, el
              cual se pondrá a su disposición en nuestras oficinas, o mediante
              medios electrónicos. En caso de que la modificación al presente
              Aviso de Privacidad implique: (i) un cambio de identidad de
              Segurify; (ii) requiramos recabar Datos Sensibles, patrimoniales o
              financieros adicionales; (iii) cambien las finalidades del uso de
              los Datos Personales; y/o (iv) se modifiquen las condiciones de
              pagos y/o transferencias, pondremos a su disposición un nuevo
              Aviso de Privacidad. Lo anterior, con la finalidad que usted
              siempre tenga conocimiento de nuestras políticas vigentes.<o:p
              ></o:p
            ></span>
          </p>

          <p
            class="MsoNormal"
            style="margin-top:0cm;margin-right:-28.4pt;margin-bottom:
0cm;margin-left:-1.0cm;margin-bottom:.0001pt;text-align:justify;background:
#F8FAFC"
          >
            <span
              lang="ES-MX"
              style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:gray;mso-themecolor:background1;
mso-themeshade:128;mso-fareast-language:ES-TRAD'
              ><br />
              Acceso, Actualización, Rectificación, Cancelación de Datos
              Personales, Limitación de Uso y/o Revocación del
              Consentimiento:<o:p></o:p
            ></span>
          </p>

          <p
            class="MsoNormal"
            style="margin-top:0cm;margin-right:-28.4pt;margin-bottom:
0cm;margin-left:-1.0cm;margin-bottom:.0001pt;text-align:justify;background:
#F8FAFC"
          >
            <span
              lang="ES-MX"
              style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:gray;mso-themecolor:background1;
mso-themeshade:128;mso-fareast-language:ES-TRAD'
              ><br />
              Usted podrá tener acceso, actualizar, rectificar y cancelar sus
              Datos Personales, limitar su uso y/o divulgación, y/o revocar su
              consentimiento, ya sea marcándonos al teléfono </span
            ><span
              lang="ES-MX"
              style='font-size:11.0pt;
font-family:"Arial",sans-serif;color:gray;mso-themecolor:background1;
mso-themeshade:128;background:#F8FAFC'
              ><span style="mso-spacerun:yes"> </span>(55) 88730915</span
            ><span
              lang="ES-MX"
              style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:gray;mso-themecolor:background1;mso-themeshade:128;
mso-fareast-language:ES-TRAD'
              >, enviando un correo electrónico a </span
            ><span
              lang="ES-MX"
              style='font-size:11.0pt;font-family:"Arial",sans-serif;color:gray;
mso-themecolor:background1;mso-themeshade:128;background:#F8FAFC'
              >atencionaclientes@segurify.com</span
            ><span
              lang="ES-MX"
              style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:gray;mso-themecolor:background1;mso-themeshade:128;
mso-fareast-language:ES-TRAD'
              >, o bien escribiéndonos a Av. Miguel Ángel de Quevedo 722 Colonia
              Barrio la Concepción C.P. 04020 Alcaldía Coyoacán, Ciudad de
              México, proporcionando la siguiente información: (i) nombre del
              titular; (ii) domicilio y/o correo electrónico del titular para
              comunicarle la respuesta, bajo el entendido que en caso de
              solicitar información de Datos Personales, la misma se le hará
              llegar al domicilio o al correo electrónico que nos haya usted
              indicado; (iii) los documentos que acrediten la identidad del
              titular y, en su caso, la representación legal del titular; (iv)
              la descripción de los Datos Personales de los que se busca tener
              acceso, actualizar, rectificar o cancelar, limitar su uso y/o
              divulgación y/o limitar su consentimiento; (v) cualquier otro
              elemento que facilite la localización de los Datos Personales.<o:p
              ></o:p
            ></span>
          </p>

          <p
            class="MsoNormal"
            style="margin-top:0cm;margin-right:-28.4pt;margin-bottom:
0cm;margin-left:-1.0cm;margin-bottom:.0001pt;text-align:justify;background:
#F8FAFC"
          >
            <span
              lang="ES-MX"
              style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:gray;mso-themecolor:background1;
mso-themeshade:128;mso-fareast-language:ES-TRAD'
              ><br />
              Adicionalmente, en caso de solicitudes de rectificación de Datos
              Personales, usted deberá indicar las modificaciones a realizarse y
              aportar la documentación que sustente su petición. Para la
              cancelación de sus Datos Personales, será necesaria la terminación
              de nuestra relación con usted, en virtud de que los mismos son
              necesarios para la prestación de nuestros servicios. Segurify le
              dará respuesta de la determinación adoptada en relación con su
              solicitud en el domicilio o correo electrónico que al efecto haya
              señalado, en un plazo máximo de 20 (veinte) días naturales
              contados a partir de la fecha en que se recibió la solicitud, a
              efecto de que, si resulta procedente, se haga efectiva la misma
              dentro de los 15 (quince) días naturales siguientes a la fecha en
              que se le comunique la respuesta. Segurify se reserva el derecho
              de ampliar los plazos antes mencionados, por una sola vez, por un
              periodo igual, siempre y cuando así lo justifiquen las
              circunstancias del caso. En caso de que ocurra una vulneración a
              la seguridad de sus Datos Personales, que afecte de forma
              significativa sus derechos patrimoniales o morales, Segurify lo
              hará de su conocimiento lo más pronto posible vía correo
              electrónico, a fin de que usted pueda tomar las medidas necesarias
              correspondientes para la defensa de sus derechos. Recuerde que en
              términos de la Ley Federal de Protección de Datos Personales en
              Posesión de los Particulares a usted le puede asistir el derecho
              para solicitar el inicio del procedimiento de protección de
              derechos de sus Datos Personales ante el Instituto Federal de
              Acceso a la Información y Protección de Datos.<o:p></o:p
            ></span>
          </p>

          <p
            class="MsoNormal"
            style="margin-top:0cm;margin-right:-28.4pt;margin-bottom:
0cm;margin-left:-1.0cm;margin-bottom:.0001pt;text-align:justify;background:
#F8FAFC"
          >
            <span
              lang="ES-MX"
              style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:gray;mso-themecolor:background1;
mso-themeshade:128;mso-fareast-language:ES-TRAD'
              ><o:p>&nbsp;</o:p></span
            >
          </p>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
export default {};
</script>

<style>
p {
  padding-left: 10px;
  padding-right: 10px;
}
</style>
