<template>
    <v-card>
        <v-toolbar flat dense>
            <v-toolbar-title class="text-subtitle-1">Notas y Documentos</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn small color="primary" @click="openModal" >
                Crear
            </v-btn>
        </v-toolbar>

        <v-divider></v-divider>

        <v-data-table
            :headers="columns"
            :items="documentos"
            item-key="name"
            class="elevation-1"
            loading-text="Cargando..."
            dense
            :items-per-page="5"
        >
            <template v-slot:[`item.accion`]="{ item }">
                <v-icon small class="mr-2" @click="openModalDetalles(item)">
                    mdi-eye
                </v-icon>
            </template>
        </v-data-table>
        <documentos-detalles-modal :dialogDetalleDocumentos="dialogDetalleDocumentos" :detallesDocumento="detallesDocumento" @closeDocumentosModal="closeDocumentosModal" ></documentos-detalles-modal>
    </v-card>
</template>

<script>
import DocumentosDetallesModal from "./DocumentosDetallesModal.vue"

    export default {
        name: 'leads.detalles.llamadas',
        props: {
            documentos: Array,
        },
        components: {
            DocumentosDetallesModal
        },
        data: function(){
            return{
                columns: [
                    { text: "Nombre", align: "start", sortable: false, value: "nombre"},
                    { text: "Tipo", value: "tipo" },
                    { text: "Descripcion", value: "nota" },
                    { text: "Creado Por", value: "creadoPor" },
                    { text: "Fecha y hora", value: "fecha" },
                    { text: "Ver", value: "accion" },
                ],
                data_table:[],
                uuid: '',
                dialogDetalleDocumentos: false,
                detallesDocumento: {}
            }
        },
         methods: {
            openModal(){
                this.$emit('openDocumentosModal')
            },
            openModalDetalles(item){
                this.detallesDocumento = item
                this.dialogDetalleDocumentos = true
            },
            closeDocumentosModal(){
                this.dialogDetalleDocumentos = false
            }
        }
    }
</script>