<template>
  <div>
    <v-row v-if="mostrarCotizacion">
      <v-col cols="12">
        <h3 v-if="formaPago == 'anual'" class="headline mb-0" style="font-size: 30px !important;">
          Total: {{ toCurrency(pagoTotal) }}
        </h3>
        <h3 v-if="formaPago != 'anual'" class="headline mb-0" style="font-size: 30px !important;">
          Total: {{ toCurrency(pagoTotal) }}
        </h3>
      </v-col>
      <v-col cols="6">
        <h3 v-if="formaPago != 'anual'" class="headline mb-0" style="font-size: 15px !important;">
          Primer Pago:<br>{{ toCurrency(pagoPrimero) }}
        </h3>
      </v-col>
      <v-col cols="6">
        <h3 v-if="formaPago != 'anual'" class="headline mb-0" style="font-size: 15px !important;">
          Subsecuentes:<br>{{ toCurrency(pagoSubsecuente) }}
        </h3>
      </v-col>
      <v-col cols="6">
        <p class="mb-0 infoCotizacion">Cobertura:<br>{{plan == 1 ? 'Amplia' : plan == 14  ? 'Amplia-Plus' : plan == 2 ? 'Limitada' : plan == 3 ? 'RC' : "" }}</p>
      </v-col>
      <v-col cols="6">
        <p class="mb-0 infoCotizacion">Periodicidad:<br>{{formaPago}}</p>
      </v-col>
    </v-row>

    <v-row v-else>
      <v-col cols="12">
        <v-btn
          style="width: 100%"
          @click="reecotizar()"
        >
          Recotizar
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <div style="word-break:break-word;" v-for="(cobertura,index) in coberturas" v-bind:key="index">
          <v-select
            :label="index == 0 ? 'DAÑOS MATERIALES' :
                    index == 1 ? 'ROBO TOTAL' :
                    index == 2 ? 'RESPONSABILIDAD CIVIL' : 'GASTOS MÉDICOS OCUPANTES'"
            :items="cobertura.valores"
            item-text="valor"
            item-value="valor"
            v-model="guardarCoberturas[index]"
            name="resposavilidad"
            @change="updateCobertura()"
            disabled
          ></v-select>
        </div>
      </v-col>
      <!-- <v-col cols="12" v-if="mostrarCotizacion == false">
        <v-btn
          style="width: 100%"
          @click="reecotizar()"
        >
          Recotizar
        </v-btn>
      </v-col> -->
    </v-row>
  </div>
</template>
<script>
export default {
  props: {
    aseguradora:String, 
    plan:Number,
    pagoTotal:Number,
    pagoPrimero:Number,
    pagoSubsecuente:Number,
    formaPago:String,
    datosParaMostrar: Array,
  },
  data() {
    return {
      coberturas:[],
      guardarCoberturas: [],
      mostrarCotizacion: true,
    };
  },
  mounted() {

    switch (this.plan) {
      case 1:
        //AMPLIA
        this.coberturas=[
          {
            titulo: 'DAÑOS MATERIALES',
            valores: [
              {id: 1, valor: '3%'},
              {id: 2, valor: '5%'},
              {id: 3, valor: '10%'},
            ]
          },
          {
            titulo: 'ROBO TOTAL',
            valores: [
              {id: 1, valor: '5%'},
              {id: 2, valor: '10%'},
              {id: 3, valor: '20%'},
            ]
          },
          {
            titulo: 'RESPONSABILIDAD CIVIL',
            valores: [
              {id: 1, valor: '3,000,000'},
              {id: 2, valor: '4,000,000'},
              {id: 3, valor: '5,000,000'},
            ]
          },
          {
            titulo: 'GASTOS MÉDICOS OCUPANTES',
            valores: [
              {id: 1, valor: '100,000'},
              {id: 2, valor: '200,000'},
              {id: 3, valor: '300,000'},
            ]
          },
        ]
        if(this.aseguradora == 'qualitas') {
          this.datosParaMostrar.length == 0 ? this.guardarCoberturas= ["5%","10%","3,000,000","300,000"] : this.guardarCoberturas = this.datosParaMostrar
        } else if (this.aseguradora == 'gnp') {
          this.datosParaMostrar.length == 0 ? this.guardarCoberturas=["5%","10%","3,000,000","200,000"] : this.guardarCoberturas = this.datosParaMostrar
        } else if (this.aseguradora == 'aig') {
          this.datosParaMostrar.length == 0 ? this.guardarCoberturas=["5%","10%","3,000,000","200,000"] : this.guardarCoberturas = this.datosParaMostrar
        }
        break;
      case 2:
        //LIMITADA
        this.coberturas=[
          {
            titulo: 'ROBO TOTAL',
            valores: [
              {id: 1, valor: '5%'},
              {id: 2, valor: '10%'},
              {id: 3, valor: '20%'},
            ]
          },
          {
            titulo: 'RESPONSABILIDAD CIVIL',
            valores: [
              {id: 1, valor: '3,000,000'},
              {id: 2, valor: '4,000,000'},
              {id: 3, valor: '5,000,000'},
            ]
          },
          {
            titulo: 'GASTOS MÉDICOS OCUPANTES',
            valores: [
              {id: 1, valor: '100,000'},
              {id: 2, valor: '200,000'},
              {id: 3, valor: '300,000'},
            ]
          },
        ]
        if(this.aseguradora == 'qualitas') {
          this.datosParaMostrar.length == 0 ? this.guardarCoberturas= ["N/A","10%","3,000,000","300,000"] : this.guardarCoberturas = this.datosParaMostrar
        } else if (this.aseguradora == 'gnp') {
          this.datosParaMostrar.length == 0 ? this.guardarCoberturas=["N/A","10%","3,000,000","200,000"] : this.guardarCoberturas = this.datosParaMostrar
        } else if (this.aseguradora == 'aig') {
          this.datosParaMostrar.length == 0 ? this.guardarCoberturas=["N/A","10%","3,000,000","200,000"] : this.guardarCoberturas = this.datosParaMostrar
        }
        break; 
      case 3:
        //RC
        this.coberturas=[
          {
            titulo: 'RESPONSABILIDAD CIVIL',
            valores: [
              {id: 1, valor: '3,000,000'},
              {id: 2, valor: '4,000,000'},
              {id: 3, valor: '5,000,000'},
            ]
          },
          {
            titulo: 'GASTOS MÉDICOS OCUPANTES',
            valores: [
              {id: 1, valor: '100,000'},
              {id: 2, valor: '200,000'},
              {id: 3, valor: '300,000'},
            ]
          },
        ]
        if(this.aseguradora == 'qualitas') {
          this.datosParaMostrar.length == 0 ? this.guardarCoberturas= ["N/A","N/A","3,000,000","300,000"] : this.guardarCoberturas = this.datosParaMostrar
        } else if (this.aseguradora == 'gnp') {
          this.datosParaMostrar.length == 0 ? this.guardarCoberturas=["N/A","N/A","3,000,000","200,000"] : this.guardarCoberturas = this.datosParaMostrar
        } else if (this.aseguradora == 'aig') {
          this.datosParaMostrar.length == 0 ? this.guardarCoberturas=["N/A","N/A","3,000,000","200,000"] : this.guardarCoberturas = this.datosParaMostrar
        }
        break;
      case 14 || 4:
        //AMPLIA PLUS
        this.coberturas=[
          {
            titulo: 'DAÑOS MATERIALES',
            valores: [
              {id: 1, valor: '3%'},
              {id: 2, valor: '5%'},
              {id: 3, valor: '10%'},
            ]
          },
          {
            titulo: 'ROBO TOTAL',
            valores: [
              {id: 1, valor: '5%'},
              {id: 2, valor: '10%'},
              {id: 3, valor: '20%'},
            ]
          },
          {
            titulo: 'RESPONSABILIDAD CIVIL',
            valores: [
              {id: 1, valor: '3,000,000'},
              {id: 2, valor: '4,000,000'},
              {id: 3, valor: '5,000,000'},
            ]
          },
          {
            titulo: 'GASTOS MÉDICOS OCUPANTES',
            valores: [
              {id: 1, valor: '100,000'},
              {id: 2, valor: '200,000'},
              {id: 3, valor: '300,000'},
            ]
          },
        ]
        if(this.aseguradora == 'qualitas') {
          this.datosParaMostrar.length == 0 ? this.guardarCoberturas= ["5%","10%","3,000,000","300,000"] : this.guardarCoberturas = this.datosParaMostrar
        } else if (this.aseguradora == 'gnp') {
          this.datosParaMostrar.length == 0 ? this.guardarCoberturas=["5%","10%","3,000,000","200,000"] : this.guardarCoberturas = this.datosParaMostrar
        } else if (this.aseguradora == 'aig') {
          this.datosParaMostrar.length == 0 ? this.guardarCoberturas=["5%","10%","4,000,000","300,000"] : this.guardarCoberturas = this.datosParaMostrar
        }
        break;   
      default:
        break;
    }

    // this.coberturas=[
    //   {titulo: 'DAÑOS MATERIALES',},
    //   {titulo: 'ROBO TOTAL',},
    //   {titulo: 'RESPONSABILIDAD CIVIL',},
    //   {titulo: 'GASTOS MÉDICOS OCUPANTES',},
    //   {titulo: 'POR ACCIDENTE AUTOMOVILISTICO',},
    // ]
    // {
    //   "plan" : "amplia",
    //   "coberturas" : {
    //       "sumaDanios" : "0",
    //       "deducibleDanios" : "0",
    //       "sumaRoboTotal" : "0",
    //       "deducibleRoboTotal" : "0",
    //       "sumaRC" : "0",
    //       "deducibleRC" : "0",
    //       "sumaGastosMedicos" : "0",
    //       "deducibleGastosMedicos" : "0",
    //       "sumaMuerte":"0",
    //       "deducibleMuerte":"0",
    //       "sumaGastosLegales":"0",
    //       "deducibleGastosLegales":"0",
    //       "sumaAsistencia":"0",
    //       "deducibleAsistencia":""
    //   }
    // },
  },


  methods: {
    toCurrency(number) {
      var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });
      return formatter.format(number); /* $2,500.00 */
    },
    updateCobertura() {
      this.mostrarCotizacion = false;
    },
  },

};
</script>

<style >
.v-label {
    font-size: 14px;
}

.infoCotizacion {
  font-size: 15px;
}
</style>
