<template>
  <v-dialog v-model="show" persistent width="800">
    <v-card :loading="loading">
      <v-card-title>
        <span> <b>Campaña lead </b></span>
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-alert
          text
          color="red"
        >
          <h3 class="text-h5">
            Estas marcando a un lead de la campaña: <b>{{ origen.medio }} </b>
          </h3>
          <div>

          </div>
        </v-alert>
      <v-card-text>
        <span v-if="loading">Cargando...</span>
        <v-row v-else dense>
          <v-col cols="12" v-if="showPriorityMedios.includes(origen.medio)">
            <v-alert
              v-if="originalRequestJson.scoreLead && originalRequestJson.scoreLead != '3'"
              dense
              text
              :type="originalRequestJson.scoreLead == '2' ? 'success' : 'info' "
            >Probabilidad de contratación: {{ getPrioridadText() }}</v-alert>
          </v-col>
          <v-col v-for="campo in campos" :key="campo.key" cols="12" md="4">
            <v-list-item>
              <v-list-item-content >
                <v-list-item-subtitle class="text-subtitle-2">{{
                  campo.texto
                }}</v-list-item-subtitle>
                <v-list-item-title class="text-subtitle-1">{{
                  origen[campo.key] ? origen[campo.key] : 'No disponible'
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-row>
          <v-col v-for="campo in leadData" :key="campo.key" cols="12" md="4">
            <v-list-item>
              <v-list-item-content v-if="campo.key != 'url'">
                <div v-if="campo.key != 'nombre' && campo.key != 'apellidoMaterno' 
                        && campo.key != 'apellidoPaterno' && campo.key != 'correo'
                        && campo.key != 'edad' && campo.key != 'utm' && campo.key != 'fechaNacimiento' && campo.key != 'fechaContratacion'">
                  <v-list-item-subtitle class="text-subtitle-2">{{ campo.key }}</v-list-item-subtitle>
                  <v-list-item-title class="text-subtitle-1">{{  campo.value }}</v-list-item-title>
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          </v-row>
          <v-row>
            <v-col v-for="campo in currentOrder" :key="campo.key" cols="12" md="4">
            <v-list-item>
              <v-list-item-content v-if="campo.key != 'url'">
                <v-list-item-subtitle class="text-subtitle-2">{{ campo.key }}</v-list-item-subtitle>
                <v-list-item-title class="text-subtitle-1">{{  campo.value }}</v-list-item-title>
              </v-list-item-content>
               <v-list-item-content v-else>
                <v-list-item-subtitle class="text-subtitle-2">URL</v-list-item-subtitle>
                <v-list-item-title class="text-subtitle-1"> 
                  <a :href="campo.value" target="_blank">Click para abrir el enlace</a>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          </v-row>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mainAxios } from "../../../mainAxios";
import moment from "moment";

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    origenUuid: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      origen: {},
      loading: false,
      error: null,
      campos: [
        {
          texto: "Estatus",
          key: "status",
          valor: null,
        },
        {
          texto: "Fecha Lead",
          key: "fecha_lead",
          valor: null,
          esFecha: true,
        },
        {
          texto: "Medio",
          key: "medio",
          valor: null,
        },
        {
          texto: "Ramo",
          key: "ramo",
          valor: null,
        },
        {
          texto: "Canal",
          key: "canal",
          valor: null,
        },
        {
          texto: "Producto",
          key: "producto",
          valor: null,
        },
        {
          texto: "Servicio Discador",
          key: "servicio_embebido",
          valor: null,
        },
        {
          texto: "Última Disposición",
          key: "ultima_disposicion",
          valor: null,
        },        
        {
          texto: "Fecha de Próxima Llamada",
          key: "proxima_llamada",
          valor: null,
          esFecha: true,
        },
        {
          texto: "Intentos",
          key: "intentos",
          valor: null,
        },
        {
          texto: "Teléfono",
          key: "telefono",
          valor: null,
        },
      ],
      camposJson:[],
      originalRequestJson:{},
      showPriorityMedios:[
        'coche seguro inter.mx',
        'moto segura inter.mx',
        'elinformador',
        'medico seguro Inter.mx',
        'mezubo',
        'cybolt'
      ],
    };
  },

  mounted() {
    this.getOrigen();
  },
  computed: {
    currentOrder() {
      var campos = [];
      var result = [];
      switch(this.origen.ramo){
        case "AUTOS":  
          if(this.origen.producto == 'Rastreator-Inter.mx'){
            campos = [
              'sexo',
              'fechaNacimiento',
              'cp',
              'cotizacionGeneral',
              'url',
              'modelo',
              'marca',
              'submarca',
              'version',
              'fechaContratacion',
              'aseguradora_seleccionada',
              'primaTotal',
              'posicion',
              'paquete',
              'cotizacion_general',
              'folioCotizacion'
            ]
            campos.forEach((element_key) => {
              this.camposJson.forEach((element) => {
                if(element.key == element_key){
                  result.push(element)
                }
              });
            });
          }
          else if(this.origen.producto != 'Rastreator-AIG'){
            campos = [
              "sexo",
              "fechaNacimiento",
              "cp",
              "cotizacionGeneral",
              "modelo",
              "marca",
              "submarca",
              "version",
              "fechaContratacion",
              "folio_cotizacion",
              "cotizacion_general",
              "folioCotizacion",
              "primaTotal",
              "posicion",
              "paquete",
            ]
            campos.forEach((element_key) => {
              this.camposJson.forEach((element) => {
                if(element.key == element_key){
                  result.push(element)
                }
              });
            });
          }else{
            campos = [
              "sexo",
              "fechaNacimiento",
              "cp",
              "modelo",
              "marca",
              "submarca",
              "version",
              "fechaContratacion",
              "sisa",
              "prima_total",
              "posicion",
              "paquete",
              "cotizacion_general",
              "cotizacion_general",
              "folioCotizacion",
              "folio_cotizacion",
              "tipoconductor"
            ];
            campos.forEach((element_key) => {
              this.camposJson.forEach((element) => {
                if(element.key == element_key){
                  result.push(element)
                }
              });
            });
          }
        break;
        case "AHORRO":
           campos = [
              'sexo',
              'fechaNacimiento',
              'ciudad',
              'objetivoAhorro',
              'rangoingreso',
              'pregunta1',
              'pregunta2',
              'pregunta3',
              'cotizacion_general',
              'folioCotizacion'
            ]
            campos.forEach((element_key) => {
              this.camposJson.forEach((element) => {
                if(element.key == element_key){
                  result.push(element)
                }
              });
            });
        break;
        default:
          result = this.camposJson
        break;
      }
      return result;
    },  
    leadData() {
      var campos = [];
      var result = [];
      campos = [
          'nombre',
          'apellidoPaterno',
          'apellidoMaterno',
          'correo',
          'edad',
        ]
        campos.forEach((element_key) => {
          this.camposJson.forEach((element) => {
            if(element.key == element_key){
              result.push(element)
            }
          });
        });
      return result;
    },   
  },

  methods: {
    getOrigen() {
      this.loading = true;
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };

      mainAxios
        .get(`/v1/origen/uuid/${this.origenUuid}`, config)
        .then((response) => {
          let dataJson= JSON.parse(response.data.request_json)  
          for (var key in dataJson) {
            if(key != 'campania_id'){
              this.camposJson.push({
                key: key,
                value: dataJson[key]
              })
            }
          }
          let hasUrl = false;
          this.camposJson.forEach((e) => {
            if(e.key == 'url'){
              hasUrl = true;
            }
          })
          if(!hasUrl){
            this.camposJson.push({
              key: 'url',
              value: 'https://segurify.com/'
            })
          }
          this.loading = false;
          this.origen = {};
          this.campos.forEach((campo) => {
            if (campo.esFecha)
              this.origen[campo.key] = response.data[campo.key]
                ? moment.utc(response.data[campo.key]).subtract(6, 'hours').format("DD/MM/YYYY HH:mm:ss")
                : "No disponible";
            else this.origen[campo.key] = response.data[campo.key];
          });
          this.originalRequestJson = dataJson;
        })
        .catch(() => {
          this.loading = false;
          this.error = "Error inesperado";
        });
    },
    getPrioridadText(){
      switch(this.originalRequestJson.scoreLead){
        case '1':
            return 'Dispositivo Mobile' 
        case '2': 
            return 'Dispositivo Desktop'
        default:
            return ''
      }
    }
  },
};
</script>
