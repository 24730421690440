<template>
  <v-container>
    <v-row>
      <v-col cols="6">
        <v-text-field label="Nombre" v-model="nombre"></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-checkbox
          label="¿Enviar por correo electrónico?"
          v-model="enviarPorCorreo"
        ></v-checkbox>
      </v-col>
      <v-col cols="6">
        <v-select
          label="Tipo"
          :items="tipoItems"
          v-model="tipoSelected"
          required
        ></v-select>
      </v-col>
      <v-col cols="6" v-if="tipoSelected == 'Documento'">
        <v-select
          label="Tipo de Doocumento*"
          :items="tipoDocumentoItems"
          v-model="tipoDocumentoSelected"
          required
        ></v-select>
      </v-col>
      <v-col cols="6">
        <v-text-field
          label="Descripción"
          v-model="descripcion"
          required
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-file-input
          v-model="archivos"
          color="primary"
          counter
          label="Adjuntos*"
          multiple
          prepend-icon="mdi-paperclip"
          :show-size="1000"
        >
          <template v-slot:selection="{ index, text }">
            <v-chip
              v-if="index < 2"
              color="deep-purple accent-4"
              dark
              label
              small
            >
              {{ text }}
            </v-chip>

            <span
              v-else-if="index === 2"
              class="text-overline grey--text text--darken-3 mx-2"
            >
              +{{ archivos.length - 2 }} File(s)
            </span>
          </template>
        </v-file-input>
      </v-col>
      <v-row>
        <v-col cols="6">
          <v-text-field label="Creado por" v-model="creadoPor"></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            label="Fecha de Creación"
            v-mask="'##/##/####'"
            v-model="fechaCreacion"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-row>
    <small>*campo requerido</small>

    <!-- ALERT MODAL -->
    <v-col cols="auto">
      <v-dialog
        transition="dialog-bottom-transition"
        max-width="400"
        v-model="dialog"
      >
        <template v-slot:default="dialog">
          <v-card>
            <v-toolbar color="success" dark>
              Confirmación de envío
            </v-toolbar>

            <v-card-text>
              <div class="text-h4" style="margin-top: 10px; ">
                Envio de documento exitoso!
              </div>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn
                text
                @click="
                  (dialog = false),
                    $router.push(`/seguimiento-solicitud/${solicitud}`)
                "
                >Cerrar</v-btn
              >
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
    </v-col>
    <!-- END ALERT MODAL -->
  </v-container>
</template>

<script>
import { mainAxios } from "../../mainAxios";
export default {
  props: {
    accionGuardar: Boolean,
    solicitud: Number,
    poliza: Number,
    cuota: Number,
    cliente: Number,
    usuario: Number,
    accion_id: Number
  },
  data() {
    return {
      dialog: false,
      tipoItems: ["Nota", "Documento"],
      tipoDocumentoItems: [
        "Acta constitutiva",
        "Aviso de privacidad firmado",
        "Caratula de afiliación",
        "Comprobante de Domicilio",
        "Comprobante Médico",
        "Constancia de situación fiscal",
        "Caratula de estado de cuenta",
        "Cotización",
        "Cédula del Agente",
        "Identificación oficial",
        "Documento de afiliación",
        "Poder Notarial",
        "Póliza",
        "RC de Agente",
        "Tarjeta de Circulación",
        "Otro",
      ],
      nombre: null,
      enviarPorCorreo: null,
      tipoSelected: null,
      tipoDocumentoSelected: null,
      descripcion: null,
      archivos: [],
      creadoPor: null,
      fechaCreacion: null,
      modificadoPor: null,
      fechaUltimaModificacion: null,
      config: {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
          "Content-Type": "multipart/form-data",
        },
      },
    };
  },
  watch: {
    accionGuardar(val) {
      if (!val) return;
      this.enviarDocumentos();
    },
    dialog(val) {
      if (!val) return;
      setTimeout(() => (this.dialog = false), 2500);
    },
  },
  computed: {
    routes() {
      if (this.solicitud) {
        return {
          post: `/v1/solicitud/documento/upload/${this.solicitud}`,
          view: `/seguimiento-solicitud/${this.solicitud}`,
        };
      } else if (this.poliza) {
        return {
          post: `/v1/emision/documento/upload/${this.poliza}`,
          view: `/emision/${this.poliza}`,
        };
      } else if (this.cuota) {
        return {
          post: `/v1/cuota/documento/upload/${this.cuota}`,
          view: `/visualizacion-comisiones/${this.cuota}`,
        };
      } else if (this.cliente) {
        return {
          post: `/v1/clientes/documento/upload/${this.cliente}`,
          view: `/cliente-detalle/${this.cliente}`,
        };
      } else if (this.usuario) {
        return {
          post: `/v1/agente/documento/upload/${this.usuario}`,
          view: `/inicio`,
        };
      } else if (this.accion_id) {
        return {
          post: `/v1/accionCliente/documento/upload/${this.accion_id}`,
          view: `/inicio`,
        };
      }
      return null;
    },
  },
  methods: {
    enviarDocumentos() {
      const formData = new FormData();
      for (var i = 0; i < this.archivos.length; i++) {
        let file = this.archivos[i];
        formData.append("file", file);
      }
      formData.append("nota", this.descripcion ? this.descripcion : null);
      formData.append("tipo", this.tipoSelected ? this.tipoSelected : null);
      formData.append(
        "tipo_documento",
        this.tipoDocumentoSelected ? this.tipoDocumentoSelected : null
      );
      formData.append("nombre", this.nombre ? this.nombre : null);
      mainAxios.post(this.routes.post, formData, this.config).then(
        (result) => {
          if (result.status == 200) {
            this.$toast.success("Nota creada con exito ! ", {
              position: "top-right",
              timeout: 5000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: "button",
              icon: true,
              rtl: false,
            });
            this.$emit("reload_info");
          }
        },
        function(error) {
          console.log(error);
        }
      );
    },
  },
};
</script>
