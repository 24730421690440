<template>
  <v-card>
    <v-toolbar flat dense>
      <v-toolbar-title class="text-subtitle-1"
        >Campañas del lead</v-toolbar-title
      >
      <v-spacer></v-spacer>
      <v-btn small color="primary" @click="open" v-if="false">
        Crear
      </v-btn>
    </v-toolbar>

    <v-divider></v-divider>

    <v-data-table
      :headers="columns"
      :items="data_table"
      item-key="name"
      class="elevation-1"
      loading-text="Cargando..."
      dense
      :items-per-page="5"
    >
      <template v-slot:[`item.campania`]="{ item }" v-if="rol == 'AGENTCC'">
      <b style="color: red;">{{ item.campania }} </b> 
      </template>
      <template v-slot:[`item.accion`]="{ item }">
        <v-icon small class="mr-2" @click="verLlamada(item)">
          mdi-eye
        </v-icon>
      </template>
    </v-data-table>

    <DetallesOrigen
      v-if="showDetails"
      :show="showDetails"
      :origenUuid="origenSeleccionado"
      @close="closeDetails()"
    />
  </v-card>
</template>

<script>
import { mainAxios } from "../../../mainAxios";
import moment from "moment";

export default {
  name: "CampañasLead",

  components: {
    DetallesOrigen: () => import("./DetallesOrigen.vue"),
  },

  data: function() {
    return {
      columns: [
        { text: "Campaña", align: "start", sortable: false, value: "campania" },
        { text: "Medio", value: "medio" },
        { text: "Estatus", value: "status" },
        { text: "Ramo", value: "ramo" },
        { text: "Fecha y hora", value: "fecha" },
        { text: "Ver", value: "accion" },
      ],
      data_table: [],
      uuid: "",
      campañas: [],
      showDetails: false,
      origenSeleccionado: null,
      rol: JSON.parse(localStorage.getItem('roles'))[0].rol
    };
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.getCampañas();
    }, methods: {
      getCampañasLead(){
          const config = {
              headers: {
              Authorization: "Bearer " + localStorage.agenteAccessToken,
              }
          }
          mainAxios.get('/v1/origen/list/lead-segurify/'+ this.item.uuid_lead_segurify, config).then(response => {
              response.data.map(element => {
              let jsonFormat = JSON.parse(element.request_json);
              var campania_name = jsonFormat.campania_id ? this.getCampaniaName(jsonFormat.campania_id):(jsonFormat.medio ? jsonFormat.medio : 'No disponible')
              this.data_table.push({
                  campania: campania_name,
                  medio: element.medio,
                  status: element.status ? element.status : 'No disponible',
                  ramo: element.ramo,
                  fecha: element.fecha_lead ? moment.utc(element.fecha_lead).subtract(6, 'hours').format('DD-MM-YYYY HH:mm:ss') : 'No disponible',
                  uuid: element.uuid,
                  request_json: element.request_json
              });
          });
          if(this.rol == 'AGENTCC'){
            var result = this.data_table.find((e) => e.uuid == this.item.uuid)
            this.data_table = []
            let jsonFormat = JSON.parse(result.request_json);
            var campania_name = jsonFormat.campania_id ? this.getCampaniaName(jsonFormat.campania_id):(jsonFormat.medio ? jsonFormat.medio : 'No disponible')
            this.data_table.push({
                  campania: campania_name,
                  medio: result.medio,
                  status: result.status ? result.status : 'No disponible',
                  ramo: result.ramo,
                  fecha: result.fecha,
                  uuid: result.uuid
              });
            } 
          })   
      },
      getCampañas(){
          const config = {
              headers: {
              Authorization: "Bearer " + localStorage.agenteAccessToken,
              }
          }
          mainAxios.get('/v1/campania/lead-segurify/list', config).then(response => {
              response.data.map(element => {
                  this.campañas.push({
                      id: element.id ? element.id : '',
                      producto: element.producto ? element.producto : ''
                  });
              });
              this.getCampañasLead();
          })    
      },
      getCampaniaName(id){
          let campania_name = this.campañas.find(element => element.id === id)
          console.log(campania_name)
          return campania_name.producto
      },
      verLlamada(item) {
        this.origenSeleccionado = item.uuid;
        this.showDetails = true;
      },
      closeDetails() {
        this.showDetails = false;
        this.origenSeleccionado = null;
      },
    },
};
</script>
