<template>
    <section class="section" id="features">
      <div class="container">
          <div class="row">
              <div class="col-lg-6 offset-lg-3">
                  <div class="section-heading">
                      <h2>Porque nuestros <em>Clientes</em> nos escogen</h2>
                      <img src="~@/assets/img/line-dec.png" alt="waves">
                      <p>En el siguiente listado podras apreciar las caracteristicas que nos representan</p>
                  </div>
              </div>
              <div class="col-lg-6">
                  <ul class="features-items">
                      <li class="feature-item">
                          <div class="left-icon">
                              <img src="~@/assets/img/reloj.png" alt="First One" style="width: 100px; height: 100px;">
                          </div>
                          <div class="right-content">
                              <h4>Sin límite de antigüedad</h4>
                              <p>Aseguramos tu celular sin importar su antigüedad.</p>
                          </div>
                      </li>
                      <li class="feature-item">
                          <div class="left-icon">
                              <img src="~@/assets/img/ver.png" alt="First One" style="width: 100px; height: 100px;">
                          </div>
                          <div class="right-content">
                              <h4>No importa de que compañía sea tu celular</h4>
                              <p>No nos importa si tu celular o tablet tienen contrato con compañías de teléfono ni cuanto te haya costado. Nosotros te lo aseguramos.</p>
                          </div>
                      </li>
                      <li class="feature-item">
                          <div class="left-icon">
                              <img src="~@/assets/img/vida.png" alt="First One" style="width: 100px; height: 100px;">
                          </div>
                          <div class="right-content">
                              <h4>Un seguro para toda la vida</h4>
                              <p>Si mañana decides cambiar de celular puedes modificarlo en tu póliza, sin necesidad de contratar un nuevo seguro.</p>
                          </div>
                      </li>
                  </ul>
              </div>
              <div class="col-lg-6">
                  <ul class="features-items">
                      <li class="feature-item">
                          <div class="left-icon">
                              <img src="~@/assets/img/regla.png" alt="First One" style="width: 100px; height: 100px;">
                          </div>
                          <div class="right-content">
                              <h4>A tu medida</h4>
                              <p>Personaliza tus coberturas y paga como tú quieras: mes a mes o anualmente.</p>
                          </div>
                      </li>
                      <li class="feature-item">
                          <div class="left-icon">
                              <img src="~@/assets/img/seguridad_bandita.png" alt="First One" style="width: 100px; height: 100px;">
                          </div>
                          <div class="right-content">
                              <h4>Protección total contra daños accidentales</h4>
                              <p>Reparamos tu celular o tablet y cubrimos todos todos los costos.</p>
                          </div>
                      </li>
                      <li class="feature-item">
                          <div class="left-icon">
                              <img src="~@/assets/img/gota.png" alt="First One" style="width: 100px; height: 100px;">
                          </div>
                          <div class="right-content">
                              <h4>Mojaduras</h4>
                              <p>¿Se ha mojado el celular y ya no funciona? Nosotros nos encargamos de repararlo sin ningún costo para ti.</p>
                          </div>
                      </li>
                  </ul>
              </div>
              <div class="col-lg-6">
                  <ul class="features-items">
                      <li class="feature-item">
                          <div class="left-icon">
                              <img src="~@/assets/img/candado.png" alt="First One" style="width: 100px; height: 100px;">
                          </div>
                          <div class="right-content">
                              <h4>Robo con violencia o sin violencia</h4>
                              <p>Si te roban tu celular nosotros te lo repondremos igual o de características similares.</p>
                          </div>
                      </li>
                  </ul>
              </div>
              <div class="col-lg-6">
                  <ul class="features-items">
                      <li class="feature-item">
                          <div class="left-icon">
                              <img src="~@/assets/img/reloj.png" alt="First One" style="width: 100px; height: 100px;">
                          </div>
                          <div class="right-content">
                              <h4>Sin periodo de espera</h4>
                              <p>Tendrás tu celular o tablet asegurado desde el primer momento que contratas el seguro.</p>
                          </div>
                      </li>
                  </ul>
              </div>
          </div>
      </div>
    </section>
</template>

<script>
export default {
  data: () => ({
    items: [
      {
        color: '#47C0EA',
        src: require('@/assets/img/icon_shield.png'),
        title: 'Sin límite de antigüedad',
        content: 'Aseguramos tu celular sin importar su antigüedad.',
      },
      {
        color: '#47C0EA',
        src: 'https://cdn.vuetifyjs.com/images/cards/halcyon.png',
        title: 'No importa de que compañía sea tu celular',
        content: 'No nos importa si tu celular o tablet tienen contrato con compañías de teléfono ni cuanto te haya costado. Nosotros te lo aseguramos.',
      },
      {
        color: '#47C0EA',
        src: 'https://cdn.vuetifyjs.com/images/cards/halcyon.png',
        title: 'Un seguro para toda la vida',
        content: 'Si mañana decides cambiar de celular puedes modificarlo en tu póliza, sin necesidad de contratar un nuevo seguro.',
      },
      {
        color: '#238ECF',
        src: 'https://cdn.vuetifyjs.com/images/cards/halcyon.png',
        title: 'A tu medida',
        content: 'Personaliza tus coberturas y paga como tú quieras: mes a mes o anualmente.',
      },
      {
        color: '#238ECF',
        src: 'https://cdn.vuetifyjs.com/images/cards/foster.jpg',
        title: 'Protección total contra daños accidentales',
        content: 'Reparamos tu celular o tablet y cubrimos todos todos los costos.',
      },
      {
        color: '#238ECF',
        src: 'https://cdn.vuetifyjs.com/images/cards/foster.jpg',
        title: 'Mojaduras',
        content: '¿Se ha mojado el celular y ya no funciona? Nosotros nos encargamos de repararlo sin ningún costo para ti.',
      },
      {
        color: '#3949AB',
        src: 'https://cdn.vuetifyjs.com/images/cards/foster.jpg',
        title: 'Robo con violencia o sin violencia',
        content: 'Si te roban tu celular nosotros te lo repondremos igual o de características similares.',
      },
      {
        color: '#3949AB',
        src: 'https://cdn.vuetifyjs.com/images/cards/foster.jpg',
        title: 'Sin periodo de espera',
        content: 'Tendrás tu celular o tablet asegurado desde el primer momento que contratas el seguro.',
      },
      {
        color: '#3949AB',
        src: 'https://cdn.vuetifyjs.com/images/cards/foster.jpg',
        title: 'Sin deducible',
        content: 'Nosotros asumimos el 100% del costo del siniestro, sin que tengas que pagar algún deducible.',
      },
    ],
  })
}
</script>

<style scoped>
#about {
  background-color: #f4f7f5;
}

#title {
  text-align: center;
}

#features {
  margin-bottom: 80px;
}

#features {
  margin-bottom: 80px;
}

.feature-item {
  display: inline-block;
  margin-bottom: 60px;
}

.feature-item .left-icon img {
  float: left;
  margin-right: 30px;
}

.feature-item .right-content {
  display: inline;
}

.feature-item .right-content h4 {
  margin-top: 0px;
  margin-bottom:  7px;
  letter-spacing: 0.25px;
  color: #232d39;
  font-size: 19px;
  font-weight: 600;
  text-transform: capitalize;
}

.feature-item .right-content a.text-button {
  margin-top: 7px;
  display: inline-block;
  font-size: 13px;
  text-transform: uppercase;
  color: #ed563b;
  font-weight: 500;
}

.card-items {
  transition: 0.5s ease-out;
}

.card-items:hover {
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.4);
  transform: translateY(-3%);
}

.section-heading {
  text-align: center;
  margin-top: 140px;
  margin-bottom: 80px;
}

.section-heading h2 {
  font-size: 28px;
  font-weight: 800;
  color: #232d39;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.section-heading h2 em {
  font-style: normal;
  color: #ed563b;
}

.feature-item .left-icon img {
    float: left;
    margin-right: 30px;
}

.section-heading img {
  margin: 20px auto;
}

.feature-item .left-icon img {
  float: left;
  margin-right: 30px;
}

@media (max-width: 992px) {
  #features {
    margin-bottom: 110px;
  }
  #features .feature-item {
    margin-bottom: 30px;
  }
}

@media (max-width: 450px) {
  .feature-item .right-content a.text-button {
    margin-left: 130px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1363px;
  }
}
 
</style>
