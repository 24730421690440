<template>
  <div id="login-background">
    <v-row style="height: 100%;">
      <v-col cols="12" md="4">
        <v-container>
          <v-img
            src="../../assets/inter-blue.f023d28c.svg"
            width="300"
            style="margin: 0 auto; filter: invert(0%) sepia(100%) saturate(140%) hue-rotate(213deg) brightness(800%) contrast(300%);"
          ></v-img>
          <v-img
            src="../../assets/nube_inter.png"
            width="300"
            style="margin: -50px auto;"
          ></v-img>
        </v-container>
      </v-col>
      <v-col cols="12" md="4" style="z-index: 99;">
        <div style="height: 100%" class="d-flex align-center justify-center">
          <v-card height="325px">
            <v-card-title>Bienvenido a tu Portal inter.mx</v-card-title>
            <v-card-subtitle
              >Para continuar ingresa tu correo electrónico y
              contraseña</v-card-subtitle
            >
            <v-card-text class="pt-5">
              <v-form ref="form" lazy-validation>
                <v-text-field
                  v-model="email"
                  :rules="emailRules"
                  label="Correo"
                  required
                  outlined
                ></v-text-field>
                <v-text-field
                  v-model="password"
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[rules.required]"
                  :type="show1 ? 'text' : 'password'"
                  name="input-10-1"
                  label="Contraseña"
                  counter
                  outlined
                  @click:append="show1 = !show1"
                ></v-text-field>
                <v-btn
                  color="#133b7d"
                  block
                  dark
                  class="mr-4"
                  @click="validate"
                  style="margin-top: 30px"
                >
                  Iniciar Sesión
                </v-btn>

                <v-alert v-if="error" class="mt-5" type="error">
                  La contraseña o el correo son incorrectos.
                </v-alert>
              </v-form>
            </v-card-text>
          </v-card>
        </div>
      </v-col>
      <v-col cols="12" md="4">
        <v-container id="logo-segurify">
          <v-img
            src="../../assets/SEGURIFY_logo_BCO.png"
            width="100"
            style="margin: 0 auto; filter: invert(0%) sepia(100%) saturate(140%) hue-rotate(213deg) brightness(104%) contrast(300%);"
          ></v-img>
          <div style="padding-top: 20px">
            <v-img
              src="../../assets/images/ramos.png"
              width="200"
              style="margin: 0 auto"
            ></v-img>
          </div>
        </v-container>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { axiosLogin } from "../../axiosLogin";
import { mainAxios } from "../../mainAxios";
export default {
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.login();
      }
    },

    login() {
      var qs = require("qs");
      var vm = this;
      vm.error = false;
      vm.cargando = true;
      const config = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        auth: {
          username: "admin",
          password: "g6PqgxyB0Z",
        },
      };
      var data = qs.stringify({
        grant_type: "password",
        username: this.email,
        password: this.password,
      });
      axiosLogin
        .post("/oauth/token", data, config)
        .then((value) => {
          localStorage.agenteAccessToken = value.data.access_token;
          const configA = {
            headers: {
              Authorization: "Bearer " + localStorage.agenteAccessToken,
            },
          };
          mainAxios.get("/v1/agente/roles", configA).then((value) => {
            const roles = value.data;
            localStorage.setItem("roles", JSON.stringify(roles));
            axiosLogin.get("/user", configA).then((value) => {
              console.log({value})
              localStorage.agenteName =
                value.data.principal.nombre +
                " " +
                value.data.principal.apellidoPaterno;
              localStorage.agenteUuid = value.data.principal.uuid;
              localStorage.agenteMail = this.email;
              this.loadUsuario()
              if (Object.keys(this.$route.query).length === 0) {
                if (
                  roles[0].rol == "AGENTCC" ||
                  roles[0].rol == "AGENTCCSPONSORS"
                ) {
                  vm.$router.push({ path: "/leads" });
                } else {
                  vm.$router.push({ path: "/inicio" });
                }
              } else {
                vm.$router.push({ path: `${this.$route.query.from}` });
              }
            });
          });
        })
        .catch((err) => {
          console.log(err);
          vm.error = true;
          vm.cargando = false;
          this.error = true;
        });
    },
    loadUsuario() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios.get(`/v1/agente/uuid/${localStorage.agenteUuid}`,config).then((response)=>{
        localStorage.id = response.data.id
      });
    },
  },
  mounted() {
    console.log(process.env.VUE_APP_API_URL);
  },
  data() {
    return {
      cargando: false,
      email: "",
      emailRules: [(v) => !!v || "El correo es requerido"],
      show1: false,
      rules: {
        required: (value) => !!value || "Campo requerido",
        emailMatch: () => `The email and password you entered don't match`,
      },
      password: "",
      passwordRules: [(v) => !!v || "El password es requerido"],
      error: null,
    };
  },
};
</script>

<style scoped>
#login-background {
  width: 100%;
  min-height: 100vh;
  background-image: radial-gradient(#34b1d7 2px, transparent 2px);
  background-size: 32px 32px;
  background-color: #039ecc;
  background-repeat: repeat;
  display: flex;
  align-items: center;
}

@media screen and (min-width: 768px) {
  #logo-segurify {
    position: fixed;
    bottom: 100px;
    right: -450px;
  }

  #nube-inter {
    position: fixed;
    bottom: 0;
    left: 0;
  }
}
</style>
