<template>
  <v-app>
    <Navigation></Navigation>
    <v-main class="mb-15">
      <v-container fluid>
        <v-row class="mb-15 fill-height">
          <v-col>
            <v-row>
              <v-col>
                <h1>
                  Reporte Leads-Oportunidades TuSeguro
                </h1>
              </v-col>
            </v-row>
            <v-row class="mb-15" style="padding: 30px">
              <v-col cols="3" md="3">
                <h3>Selecciona el rango de fechas</h3>
                <v-date-picker
                  style="width: 100%"
                  v-model="dates"
                  range
                ></v-date-picker>
                <v-btn
                  rounded
                  color="primary"
                  style="width: 100%"
                  dark
                  @click="generateReport"
                  >Generar Reporte</v-btn
                >
                <v-btn
                  rounded
                  color="primary"
                  style="width: 100%; margin-top: 20px"
                  :disabled="!report.length > 0"
                  >
                  <download-csv
                    :data="report"
                    name= "reportLeadsOportunidades.csv"
                  >
                    Descargar Información
                  </download-csv>
                </v-btn>

                
              </v-col>
              <v-col cols="9" md="9">
                <div>
                  <div v-if="loading" style="text-align: center">
                    <h2 clas="pb-4">Cargando Reporte....</h2>
                    <br /><br />
                    <md-progress-spinner
                      :md-diameter="100"
                      :md-stroke="10"
                      md-mode="indeterminate"
                    ></md-progress-spinner>
                  </div>
                </div>

                <v-data-table
                  v-if="!loading && reportType == 'lead'"
                  :headers="columns"
                  :items="report"
                  item-key="name"
                  class="elevation-1"
                  :search="search"
                  :custom-filter="filterOnlyCapsText"
                  :loading="loading"
                  loading-text="Cargando..."
                >
                  <template v-slot:top>
                    <v-text-field
                      v-model="search"
                      label="Buscar"
                      class="mx-4"
                    ></v-text-field>
                  </template>
                  <template v-slot:item.telefono="{ item }">
                    <td style="white-space: nowrap">{{ item.telefono }}</td>
                  </template>

                  <template v-slot:item.fecha="{ item }">
                    <td style="white-space: nowrap">
                      {{ convertDate(item.fecha) }}
                    </td>
                  </template>

                  <template v-slot:item.version="{ item }">
                    <td style="white-space: nowrap">{{ item.version }}</td>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <v-footer fixed style="text-align: center">
      <p style="text-align: center; width: 100%; margin: 10px 0px">
        Segurify 2021
        <a target="_blank" href="/terminos-y-condiciones"
          >Terminos y condiciones</a
        >
        y <a target="_blank" href="/aviso-de-privacidad">Aviso de privacidad</a>
      </p>
    </v-footer>
  </v-app>
</template>

<script>
import Navigation from "../../../components/Nav.vue";
import { mainAxios } from "../../../mainAxios";
var moment = require('moment-timezone');

export default {
  components: {
    Navigation,
  },
  data() {
    return {
      roles: JSON.parse(localStorage.getItem('roles')),
      uuid: localStorage.getItem('agenteUuid'),
      dates: [],
      downloadText: 'Descargar Reporte En Excel',
      downloading: false,
      loading: false,
      reportType: 'lead',
      report: [],

      search: '',
      columns: [
        { text: 'Poliza', 
          align: 'start',
          sortable: false,
          value: 'poliza' 
        },
        {text: 'Inicio Poliza',value: 'fechaInicioPoliza',},
        { text: 'Vigencia Poliza', value: 'fechaVigenciaPoliza' },
        { text: 'Nombre', value: 'nombre' },
        { text: 'Apellido Paterno', value: 'apellidoPaterno' },
        { text: 'Apellido Materno', value: 'apellidoMaterno' },
        { text: 'Teléfono', value: 'telefono' },
        { text: 'Correo Electronico', value: 'correo' },
        { text: 'Campaña', value: 'campania' },
        { text: 'Id Lead', value: 'idLead' },
        { text: 'Id Oppt', value: 'uuidOportunidad' },
        { text: 'Fecha creación', value: 'creatDate' },
        { text: 'Marca', value: 'marca' },
        { text: 'SubMarca', value: 'submarca' },
        { text: 'Modelo', value: 'modelo' },
        { text: 'Versión', value: 'version' },
        { text: 'Estado Lead', value: 'StageName' },
        { text: 'Uso', value: 'uso' },
        { text: 'Periodicidad de Pago', value: 'periodicidadPago' },
        { text: 'Prima Total', value: 'primaTotal' },
        { text: 'Prima Neta', value: 'primaNeta' },
        { text: 'Primer Pago', value: 'primerPago' },
        { text: 'Pago Subsecuente', value: 'pagoSubsecuente' },
        { text: 'Forma de Pago', value: 'formaPago' },
        { text: 'Aseguradora', value: 'aseguradora' },
        { text: 'Plan', value: 'plan' },
        { text: 'Estado de la Republica', value: 'estadoRepublica' },
        { text: 'Estatus poliza', value: 'estatusPoliza' },
        { text: 'Estatus Cobro', value: 'estatusCobro' },
        { text: 'Asesor', value: 'asesor' },
        { text: 'Medio', value: 'medio' },
        { text: 'Origen', value: 'origen' },
        { text: 'Landing', value: 'landing' },
      ],
    };
  },

  computed: {
    admin() {
      let isAdminCotiza = false;
      console.log(localStorage.roles);
      this.roles.forEach((element) => {
        if (element.rol == 'ADMIN') {
          isAdminCotiza = true;
        }
      });
      return isAdminCotiza;
    },
    operador() {
      let isOperCotiza = false;
      console.log(localStorage.roles);
      this.roles.forEach((element) => {
        if (element.rol == 'OPERADOR') {
          isOperCotiza = true;
        }
      });
      return isOperCotiza;
    },
    operaciones() {
      let isOperacionesCotiza = false;
      console.log(localStorage.roles);
      this.roles.forEach((element) => {
        if (element.rol == 'OPERACIONES') {
          isOperacionesCotiza = true;
        }
      });
      return isOperacionesCotiza;
    },
    adminagent() {
      let isAdminAgentCotiza = false;
      console.log(localStorage.roles);
      this.roles.forEach((element) => {
        if (element.rol == 'ADMINAGENT') {
          isAdminAgentCotiza = true;
        }
      });
      return isAdminAgentCotiza;
    },
    manager() {
      let isManagerCotiza = false;
      console.log(localStorage.roles);
      this.roles.forEach((element) => {
        if (element.rol == 'MANAGER') {
          isManagerCotiza = true;
        }
      });
      return isManagerCotiza;
    },
  },

  methods: {
    convertDate(date) {
      const dateRes = moment(date);
      dateRes.tz('UTC', true);
      return dateRes.tz('America/Mexico_City').format('DD-MM-YYYY HH:mm');
    },
    getColor(calories) {
      if (calories > 400) return 'red';
      else if (calories > 200) return 'orange';
      else return 'green';
    },
    generateReport() {
      let vm = this;
      vm.report = []
      vm.reportType = 'lead';
      let config = {
        headers: {
          Authorization: 'Bearer ' + localStorage.agenteAccessToken,
        },
      };
      let inicioUTC = null
      let finUTC = null

      if(this.dates.length > 0){
      if(this.dates[1]) {
          inicioUTC = moment.utc(this.dates[0]).format('YYYY-MM-DD HH:mm:ss')
          finUTC = moment.utc(this.dates[1]).add(24, 'hours').subtract(1, 'seconds').format('YYYY-MM-DD HH:mm:ss')
      } else {
          inicioUTC = moment.utc(this.dates[0]).format('YYYY-MM-DD HH:mm:ss')
          finUTC = moment.utc(this.dates[0]).add(24, 'hours').subtract(1, 'seconds').format('YYYY-MM-DD HH:mm:ss')
      }
      //restar 6 horas al reques
      //moment.utc(element.fecha).subtract(6, 'hours').format('DD/MM/YYYY HH:mm:ss'),
      }

      let fechas = {
        fechaInicio: inicioUTC,
        fechaFin: finUTC,
      };

      vm.loading = true;
      mainAxios
        .post('/v1/admin/oportunidades/reporte/leads/tuseguro', fechas, config)
        .then((response) => {
          response.data.map(element => {
            element.oportunidades.map(elem => {
              if (elem.CreatedById) {
                vm.report.push(
                  {
                    fechaInicioPoliza: elem.FechaInicioPoliza ? moment.utc(elem.FechaInicioPoliza).subtract(6, 'hours').format('DD/MM/YYYY') : '-',
                    fechaVigenciaPoliza: elem.FechaVigenciaPoliza ? moment.utc(elem.FechaVigenciaPoliza).subtract(6, 'hours').format('DD/MM/YYYY') : '-',
                    nombre: element.nombre ? element.nombre : '-',
                    apellidoPaterno: element.apellidoPaterno ? element.apellidoPaterno : '-',
                    apellidoMaterno: element.apellidoMaterno ? element.apellidoMaterno : '-',
                    correo: element.correo ? element.correo : '-',
                    telefono: element.telefono ? element.telefono : '-',
                    campania: element.campania ? element.campania : '-',
                    idLead: element.uuid ? element.uuid : '-',
                    uuidOportunidad: elem.uuid ? elem.uuid : '-',
                    creatDate: elem.BMX_Fecha_Creacion__c ? moment.utc(elem.BMX_Fecha_Creacion__c).subtract(6, 'hours').format('DD/MM/YYYY') : '-',
                    StageName: elem.StageName ? elem.StageName : '-',
                    medio: element.Medio ? element.Medio : '-',
                    origen: element.Origen ? element.Origen : '-',
                    marca: elem.Marca ? elem.Marca : '-',
                    submarca: elem.Submarca ? elem.Submarca : '-',
                    modelo: elem.Modelo ? elem.Modelo : '-',
                    version: elem.Version ? elem.Version : '-',
                    uso: elem.UsoCarro ? elem.UsoCarro : '-',
                    poliza: elem.NumeroPoliza ? elem.NumeroPoliza : '-',
                    primaTotal: elem.PrimaTotal ? elem.PrimaTotal : '-',
                    primaNeta: elem.PrimaNeta ? elem.PrimaNeta : '-',
                    primerPago: elem.PrimerPago ? elem.PrimerPago : '-',
                    pagoSubsecuente: elem.PagosSubsecuentes ? elem.PagosSubsecuentes : '-',
                    periodicidadPago: elem.Periodicidad ? elem.Periodicidad : '-',
                    formaPago: elem.FormaPago ? elem.FormaPago : '-',
                    aseguradora: elem.Insurance ? elem.Insurance : '-',
                    plan: elem.Plan ? elem.Plan : '-',
                    estadoRepublica: element.City ? element.City : '-',
                    estatusPoliza: elem.PolicyStatus ? elem.PolicyStatus : '-',
                    estatusCobro: elem.PaymentStatus ? elem.PaymentStatus : '-',
                    asesor: elem.CreatedById,
                    landing: elem.Landing ? elem.Landing : '-'
                  }
                )
              }
            }) 
          })
          vm.loading = false;
        });
      
    },
    downloadReport() {
      let vm = this;
      let config = {
        headers: {
          Authorization: 'Bearer ' + localStorage.agenteAccessToken,
        },
        responseType: 'blob',
      };
      vm.downloading = true;
      let fechas = {
        fechaInicio: this.dates[0],
        fechaFin: this.dates[1],
      };
      vm.downloadText = 'Generando reporte...';
      mainAxios.post('/v1/reporte/leads/', fechas, config).then((response) => {
        vm.downloading = false;
        vm.downloadText = 'Descargar';
        require('downloadjs')(
          response.data,
          'ReporteLeads.xls',
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        );
        //download(response.data, "ReporteLeads.xls", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
      });
    },
    downloadComparadorReport() {
      let vm = this;
      let config = {
        headers: {
          Authorization: 'Bearer ' + localStorage.agenteAccessToken,
        },
        responseType: 'blob',
      };
      vm.downloading = true;

      vm.downloadText = 'Generando reporte...';
      mainAxios
        .get('/v1/reporte/cotizaciones/comparador', config)
        .then((response) => {
          vm.downloading = false;
          vm.downloadText = 'Descargar';
          require('downloadjs')(
            response.data,
            'ReporteComprador.xls',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          );
          //download(response.data, "ReporteComprador.xls", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
        });
    },

    // Table
    filterOnlyCapsText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === 'string' &&
        value.toString().indexOf(search) !== -1
      );
    },
  },
};
</script>
