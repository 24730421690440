<template>
  <v-app>
    <Navigation></Navigation>
    <v-main class="mb-15">
      <v-container fluid>
        <v-card color="grey lighten-4" flat tile style="margin-top: 20px">
          <v-toolbar>
            <v-toolbar-title>
              <h2>Poliza #{{ this.uuid }}</h2>
            </v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn
              v-if="canEdit"
              icon
              @click="verEmisionEditable()"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>

            <v-btn @click="goBack()"
              ><v-icon style="margin-right: 5px">mdi-arrow-left</v-icon>Regresar
            </v-btn>
          </v-toolbar>
        </v-card>
        <v-divider></v-divider>

        <v-row>
          <v-col cols="7">
            <v-form>
              <v-row>
                <v-col>
                  <v-stepper non-linear>
                    <v-stepper-header class="d-flex justify-space-around">
                      <v-stepper-step step="1" editable>
                        Datos de la Póliza
                      </v-stepper-step>

                      <v-stepper-step step="2" editable>
                        Datos del Cliente y Generales
                      </v-stepper-step>

                    </v-stepper-header>

                    <v-stepper-items>
                      <v-stepper-content step="1">
                        <v-row style="padding: 20px; margin-top: -35px">
                          <v-col cols="4">
                            <v-text-field
                              label="No. Póliza"
                              v-model="poliza.poliza"
                              readonly
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-text-field
                              label="Poliza ID Interna"
                              v-model="poliza.id"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-text-field
                              label="Tipo de Póliza"
                              v-model="poliza.type"
                              readonly
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-text-field
                              label="Estatus de la Póliza"
                              v-model="poliza.status"
                              readonly
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-autocomplete
                              label="Campaña"
                              v-model="poliza.campania_id"
                              :items="campanias"
                              item-text="producto"
                              item-value="id"
                              readonly
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="4" v-if="poliza.status == 'Pagada Total' || poliza.status == 'Renovada'">
                            <v-text-field
                              label="Póliza renovada por"
                              v-model="poliza.anterior"
                              readonly
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-text-field
                              label="No. Cotización / Lead ID"
                              v-model="poliza.lead_id"
                              readonly
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6" v-if="poliza.status == 'Finalizada' || poliza.status == 'Cancelada'">
                            <v-select
                              label="Motivo de Cancelación / Finalización"
                              v-model="poliza.canceladaFinalizadaMotivo"
                              :items="cancelacionFinalizacionList"
                              readonly
                              item-text="name"
                              item-value="name"
                            ></v-select>
                          </v-col>
                          <v-col cols="5" v-if="poliza.status == 'Cancelada'">
                            <v-text-field
                              label="Fecha de Cancelación"
                              prepend-icon="mdi-calendar"
                              v-model="poliza.canceladaDate"
                              readonly
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4" v-if="poliza.status == 'Finalizada'">
                            <v-text-field
                              label="Fecha de Finalización"
                              v-model="poliza.finalizadaDate"
                              prepend-icon="mdi-calendar"
                              readonly
                            ></v-text-field>
                          </v-col>
                          <!-- <v-col cols="12" v-if="poliza.status == 'Renovada'">
                            <v-checkbox
                              label="Renovada"
                              readonly
                            ></v-checkbox>
                          </v-col> -->
                          <!-- INICIO DE CHECKS -->
                          <!-- <v-col cols="6" v-if="poliza.status == 'Nueva'">
                            <v-checkbox
                              label="Póliza Emitida"
                              v-model="poliza.emitidaCheck"
                              readonly
                            ></v-checkbox>
                          </v-col>
                          <v-col cols="6" v-if="poliza.emitidaCheck">
                            <v-text-field
                              label="Fecha de Emisión"
                              prepend-icon="mdi-calendar"
                              v-model="poliza.emitidDate"
                              readonly
                            ></v-text-field>
                          </v-col> -->
                          <!-- 2nd Col -->
                          <!-- <v-col cols="4" v-if="poliza.status == 'Nueva' || poliza.status == 'Emitida Pendiente de Pago' || poliza.status == 'Pagada Parcial' || poliza.status == 'Pagada Total'">
                            <v-checkbox
                              label="Póliza Cancelada"
                              v-model="poliza.canceladaCheck"
                              readonly
                            ></v-checkbox>
                          </v-col>
                          <v-col cols="4" v-if="poliza.canceladaCheck">
                            <v-text-field
                              label="Fecha de Cancelación"
                              prepend-icon="mdi-calendar"
                              v-model="poliza.canceladaDate"
                              readonly
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4" v-if="poliza.canceladaCheck">
                            <v-text-field
                              label="Motivo de Cancelación"
                              v-model="poliza.canceladaMotivo"
                              readonly
                            ></v-text-field>
                          </v-col> -->
                          <!-- 3rd Col -->
                          <!-- <v-col cols="4" v-if="poliza.status == 'Pagada Parcial' || poliza.status == 'Pagada Total'">
                            <v-checkbox
                              label="Póliza Finalizada"
                              v-model="poliza.finalizadaCheck"
                              readonly
                            ></v-checkbox>
                          </v-col>
                          
                          <v-col cols="4" v-if="poliza.finalizadaCheck">
                            <v-text-field
                              label="Motivo de Finalización"
                              v-model="poliza.finalizadaMotivo"
                              readonly
                            ></v-text-field>
                          </v-col> -->
                          <!-- FIN DE CHECKS -->
                          <v-col cols="4">
                            <v-text-field
                              label="Cliente"
                              v-model="poliza.newCliente"
                              readonly
                              class="enlacesForms"
                              @click="verCliente()"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-text-field
                              label="Agente"
                              v-model="poliza.newAgente"
                              readonly
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4">
                              <v-select
                                label="Ramo"
                                :items="categoria"
                                v-model="emision.categoriaSelected"
                                placeholder="Requerido"
                                readonly
                              ></v-select>
                          </v-col>
                          <v-col cols="4">
                            <v-select
                              label="Productos"
                              v-model="poliza.productos"
                              :items="productos"
                              readonly
                              item-text="nombre"
                              item-value="id"
                            ></v-select>
                          </v-col>
                          <v-col cols="4">
                            <v-text-field
                              label="Inicio de Vigencia"
                              v-model="poliza.inicioDate"
                              prepend-icon="mdi-calendar"
                              readonly
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-text-field
                              label="Fin de Vigencia"
                              v-model="poliza.finDate"
                              prepend-icon="mdi-calendar"
                              readonly
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-text-field
                              label="Periodicidad de pago"
                              v-model="poliza.periodicidad"
                              readonly
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-text-field
                              label="Años"
                              v-model="years"
                              readonly
                            ></v-text-field>
                          </v-col>
                          
                          <v-col cols="4">
                            <v-text-field
                              label="Número de cuotas"
                              v-model="poliza.numeroCuotas"
                              readonly
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-text-field
                              label="Prima neta"
                              v-model="poliza.primaNeta"
                              readonly
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-text-field
                              label="Prima total"
                              v-model="poliza.primaTotal"
                              readonly
                            ></v-text-field>
                          </v-col>

                          <v-col cols="4">
                            <v-text-field
                              label="Forma de Pago"
                              v-model="poliza.formaPago"
                              readonly
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-text-field
                              label="Monto de Primer Pago"
                              v-model="poliza.montoPrimerPago"
                              readonly
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4" v-if="poliza.periodicidadNumber != 1">
                            <v-text-field
                              label="Monto de Pago Subsecuente"
                              v-model="poliza.montoSubsecuente"
                              readonly
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4" v-if="poliza.solicitudRelacionada">
                            <v-text-field
                              label="No. Solicitud relacionada"
                              v-model="poliza.solicitudRelacionada"
                              readonly
                              class="enlacesForms"
                              @click="verSolicitud()"
                            ></v-text-field>
                          </v-col>
                          <!-- <v-col cols="4">
                            <v-text-field
                              label="Fecha de Primer Pago de la Póliza"
                              v-model="poliza.primerPagoDate"
                              prepend-icon="mdi-calendar"
                              readonly
                            ></v-text-field>
                          </v-col> -->
                          <!-- <v-col cols="6">
                            <v-checkbox
                              label="Primer Pago de la Póliza Realizado"
                              v-model="poliza.primerPagoCheck"
                              readonly
                            ></v-checkbox>
                          </v-col> -->
                          <!-- <v-col cols="6">
                            <v-text-field
                              label="Fecha de Primer Pago de la Póliza"
                              v-model="poliza.primerPagoPolizaDate"
                              prepend-icon="mdi-calendar"
                              readonly
                            ></v-text-field>
                          </v-col> -->
                          <v-col cols="12">
                            <v-textarea
                              label="Notas de la Póliza"
                              rows="1"
                              auto-grow
                              readonly
                              v-model="poliza.notas"
                            ></v-textarea>
                          </v-col>

                          <v-col cols="6">
                            <v-text-field
                              label="Fecha de Creación"
                              v-model="poliza.creadoPorDate"
                              prepend-icon="mdi-calendar"
                              readonly
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              label="Creado por"
                              v-model="poliza.creadoPor"
                              readonly
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              label="Última Modificación"
                              v-model="poliza.ultimaModoficacionDate"
                              prepend-icon="mdi-calendar"
                              readonly
                            ></v-text-field>
                          </v-col>

                          <v-col cols="6">
                            <v-text-field
                              label="Modificado por"
                              v-model="poliza.ultimaModoficacion"
                              readonly
                            ></v-text-field>
                          </v-col>

                          <!-- <v-col cols="6">
                            <v-btn color="primary" v-if="emision.urlPoliza !=null" :loading="downloading" @click="downloadUrlPoliza">Descargar</v-btn>
                          </v-col> -->

                          <!-- ENDS HERE -->
                        </v-row>

                      </v-stepper-content>

                      <v-stepper-content step="2">
                        <v-row style="padding: 20px; margin-top: -35px">
                          <v-col cols="4">
                            <v-text-field
                              label="Tipo de Persona (Régimen)"
                              v-model="poliza.regimen"
                              readonly
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row style="padding: 20px; margin-top: -35px">

                          <v-col cols="4" v-if="poliza.regimen == 'Moral'">
                            <v-text-field
                              label="Razón Social"
                              v-model="poliza.razonSocial"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4" v-if="poliza.regimen != 'Moral'">
                            <v-text-field
                              label="Nombre(s)"
                              v-model="poliza.nombres"
                              readonly
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4" v-if="poliza.regimen != 'Moral'">
                            <v-text-field
                              label="Apellido Paterno"
                              v-model="poliza.apellidoPaterno"
                              readonly
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4" v-if="poliza.regimen != 'Moral'">
                            <v-text-field
                              label="Apellido Materno"
                              v-model="poliza.apellidoMaterno"
                              readonly
                            ></v-text-field>
                          </v-col>

                          <v-col cols="6" v-if="poliza.regimen != 'Moral'">
                            <v-text-field
                              label="Fecha de Nacimiento"
                              v-model="poliza.fechaNacimiento"
                              readonly
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              label="RFC"
                              v-model="poliza.rfc"
                              readonly
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-text-field
                              label="Edad"
                              v-model="poliza.edad"
                              readonly
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-text-field
                              label="Género"
                              v-model="poliza.genero"
                              readonly
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4" v-if="poliza.regimen != 'Moral'">
                            <v-text-field
                              label="Estado civil"
                              v-model="poliza.estado_civil"
                              readonly
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-text-field
                              label="Teléfono"
                              v-model="poliza.celular"
                              readonly
                            ></v-text-field>
                          </v-col>
                          
                          <v-col cols="4">
                            <v-text-field
                              label="Correo Electrónico"
                              v-model="poliza.correoElectronico"
                              readonly
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4" v-if="poliza.regimen != 'Moral'">
                            <v-text-field
                              label="Codigo postal"
                              v-model="poliza.codigo_postal"
                              readonly
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4" >
                            <v-text-field
                              label="Calle"
                              v-model="poliza.calle"
                              readonly
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4" >
                            <v-text-field
                              label="Numero exterior"
                              v-model="poliza.num_exterior"
                              readonly
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4" >
                            <v-text-field
                              label="Numero interior"
                              v-model="poliza.num_interior"
                              readonly
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-text-field
                              label="Colonia"
                              v-model="poliza.colonia"
                              readonly
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-text-field
                              label="Ciudad"
                              v-model="poliza.ciudad"
                              readonly
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-text-field
                              label="Estado de la República"
                              v-model="poliza.estadoRepublicaSelected"
                              readonly
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-text-field
                              label="Código Postal"
                              v-model="poliza.codigoPostal"
                              readonly
                            ></v-text-field>
                          </v-col>
                          
                        </v-row>
                      </v-stepper-content>

                    </v-stepper-items>
                  </v-stepper>
                </v-col>
              </v-row>
            </v-form>
          </v-col>

          <v-col cols="5">
            <v-card style="margin-bottom: 40px">
              <v-subheader>CUOTAS</v-subheader>
              <v-divider></v-divider>
              <v-data-table
                :headers="columnsCuotas"
                :items="cuotas"
                :items-per-page="3"
                dense
                item-key="name"
                class="elevation-1"
              >
                <template v-slot:top>
                  <!-- <v-text-field
                                    v-model="search"
                                    label="Buscar"
                                    class="mx-4"
                                    ></v-text-field> -->
                </template>
                <template v-slot:item.uuid="{ item }">
                  <v-icon small class="mr-2" @click="verCuota(item.uuid)">
                    mdi-eye
                  </v-icon>
                </template>
              </v-data-table>
            </v-card>

             <v-card style="margin-bottom: 40px" class="pt-1">
              <v-row>
                <v-col cols="10">
                  <v-subheader>NOTAS Y DOCUMENTOS</v-subheader>
                </v-col>
                <v-col style="aling: right" class="mt-2" cols="2">
                  <v-btn small color="primary" @click="documentsDialog = true">
                    Crear
                  </v-btn>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-data-table
                :headers="columnsDocumentos"
                :items="documentos"
                item-key="name"
                class="elevation-1"
                dense
              >
                <template v-slot:top>
                  <!-- <v-text-field
                                    v-model="search"
                                    label="Buscar"
                                    class="mx-4"
                                    ></v-text-field> -->
                </template>
                <template v-slot:item.url="{ item }">
                  <v-icon
                    small
                    class="mr-2"
                    @click="verDocumento(item)"
                  >
                    mdi-eye
                  </v-icon>
                </template>
              </v-data-table>
            </v-card>

            <v-card style="margin-top: 15px">
              <v-row>
                <v-col cols="6">
                  <v-subheader>EMAILS</v-subheader>
                </v-col>
                <v-col cols="4">
                  <v-btn icon>
                    <v-icon>mdi-magnify</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="2">
                  <v-btn @click="newCorreoDialog=true" small color="primary">
                    Crear
                  </v-btn>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-data-table
                :headers="columnsEmails"
                :items="correos"
                item-key="name"
                class="elevation-1"
                dense
              >
                <!-- <template v-slot:top>
                  <v-text-field
                    v-model="search"
                    label="Buscar"
                    class="mx-4"
                  ></v-text-field>
                </template> -->
                <template v-slot:item="row">
                    <tr>
                      <td>{{row.item.asunto}}</td>
                      <td>{{row.item.fecha}}</td>
                      <td>{{row.item.to}}</td>
                      <td style="cursor:pointer;" @click="verDetalleCorreo(row.item)">
                        <v-icon light small class="mr-2">mdi-eye</v-icon>
                      </td>
                    </tr>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <!-- MODALES -->

    <!-- MODAL VER DOCUMENTOS -->
    <v-dialog
      v-model="dialogDetalleDocumentos"
      max-width="750px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Detalle de Documento / Nota</span>
        </v-card-title>

        <template>
          <v-container style="padding: 30px">
            <v-row>
              <v-col cols="6">
                <v-text-field
                  label="Nombre"
                  v-model="detallesDocumento.nombre"
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-checkbox
                  label="¿Enviar por correo electrónico?"
                  readonly
                ></v-checkbox>
              </v-col>
              <v-col cols="6">
                <v-select
                  label="Tipo"
                  :items="tipoItems"
                  v-model="detallesDocumento.tipo"
                  required
                  readonly
                ></v-select>
              </v-col>
              <v-col cols="6" v-if="detallesDocumento.tipo == 'Documento'">
                <v-select
                  label="Tipo de Doocumento*"
                  :items="tipoDocumentoItems"
                  v-model="detallesDocumento.tipoDocumento"
                  required
                  readonly
                ></v-select>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Descripción"
                  v-model="detallesDocumento.descripcion"
                  required
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="4" v-if="detallesDocumento.url">
                <template>
                  <v-file-input
                    v-model="documentos"
                    color="primary"
                    label="Adjuntos*"
                  >
                    <template v-slot:selection="{ index }">
                      <v-chip
                        v-if="index < 1"
                        color="primary"
                        dark
                        label
                        small
                        @click="detallesDocumento.url"
                      >
                        {{ detallesDocumento.nombre }}
                      </v-chip>

                      <span
                        v-else-if="index === 2"
                        class="text-overline grey--text text--darken-3 mx-2"
                      >
                        +{{ documentos.length - 2 }} Archivos(s)
                      </span>
                    </template>
                  </v-file-input>
                </template>
              </v-col>
              <v-col cols="2" v-if="detallesDocumento.url">
                <v-btn
                  text
                  :href="detallesDocumento.url"
                  small
                  color="primary"
                  class="mt-4"
                >
                <v-icon>mdi-download</v-icon>
                  Descargar
                </v-btn>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Creado por"
                  v-model="detallesDocumento.creadoPor"
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Fecha de Creación"
                  v-mask="'##/##/####'"
                  v-model="detallesDocumento.fecha"
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </template>
      </v-card>
    </v-dialog>

     <!-- MODAL DE CORREO -->
    <v-dialog
      v-model="dialogCorreo"
      width="600px"
    >

      <v-card style="padding: 30px">
        <v-row>
          <v-col cols="12">
            <v-text-field
            label="De"
            v-model="correoOpen.remitente"
            hide-details="auto"
            readonly
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-text-field
            label="Para"
            v-model="correoOpen.to"
            hide-details="auto"
            readonly
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-text-field
            label="CC"
            v-model="correoOpen.cc"
            hide-details="auto"
            readonly
            ></v-text-field>
          </v-col>
        </v-row>  
        <v-row>
          <v-col cols="12">
            <v-text-field
            label="BCC"
            v-model="correoOpen.bcc"
            hide-details="auto"
            readonly
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
            label="Asunto"
            v-model="correoOpen.asunto"
            hide-details="auto"
            readonly
            ></v-text-field>
          </v-col>
        </v-row> 
        <v-row>
          <v-col cols="12">
            <v-textarea
            v-model="correoOpen.cuerpo"
            label="Mensaje"
            readonly
            ></v-textarea>
          </v-col>
        </v-row> 
        
        <v-row v-if="correoOpen.documentos">
          <v-col>
            <p>Descargar Adjuntos</p>
          </v-col>
        </v-row>
        <v-row v-if="correoOpen.documentos">
          <v-col>
            <v-chip
              v-for="(documento, index) in correoOpen.documentos"
              v-bind:key="index"
              color="primary"
              outlined
              :href="documento.url"
              style="cursor: pointer"
            >
              <v-icon left>
                mdi-tray-arrow-down
              </v-icon>
              {{ documento.nombre }}
            </v-chip>
          </v-col>
        </v-row>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialogCorreo = false"
          >
           Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- SEND EMAIL -->
    <v-row justify="center">
      <EmailForm 
          :newCorreoDialog="newCorreoDialog"
          @closeSendEmail="closeSendEmail"
          :poliza="emision.id"
          :idAgente="emision.agenteId"
          :correoContacto="emision.correoContacto"
      />
    </v-row>

    <!-- ADD DOCUMENTS -->
    <v-row justify="center">
      <v-dialog
        v-model="documentsDialog"
        max-width="750px"
      >
        <v-card>
          <v-card-title>
            <span class="text-h5">Creación de Documento / Nota</span>
          </v-card-title>

          <div style="padding: 30px">
            <DocumentosNotasModal
              :accionGuardar="accionGuardar" 
              :poliza="poliza.id"
              @reload_info="cargarEmision"
            />
          </div>
          
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="documentsDialog = false"
            >
              Cerrar
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="accionGuardar = true, documentsDialog = false"
            >
              Guardar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

  </v-app>
</template>

<script>
import moment from 'moment';
import Navigation from "../../../components/Nav.vue";
import EmailForm from "../../components/EmailForm";
import DocumentosNotasModal from '../../components/DocumentosNotasModal.vue';
import { mainAxios } from "../../../mainAxios";
export default {
  components: {
    Navigation,
    EmailForm,
    DocumentosNotasModal
  },
  computed:{
    canEdit(){
      var canView = false;
      var roles=[]
      this.roles.forEach((element) => {
        roles.push(element.rol)
      });
      const rolesToView = [
        "ADMIN",
        "MESADECONTROL",
        "OPERACIONES",
        "OPERADOR",
        "ADMINCC",
        "MANAGERCC",
        "AGENTCC",
        "COBRANZACC",
        "RENOVACIONESCC",
        "FINANZAS",
        "AGENTCCSPONSORS",
        "OPERADORCC"
      ];
      roles.forEach((element)=>{
        if(rolesToView.includes(element)){
          canView=true;
        }
      })
      return canView;
    }
  },

  data() {
    return {
      downloading: false,
      roles: JSON.parse(localStorage.getItem("roles")),
      newCorreoDialog: false,
      dialogCorreo: false,
      correoOpen:{},
      documentsDialog: false,
      dialogDetalleDocumentos: false,
      detallesDocumento: {},
      accionGuardar: false,
      poliza: {
        poliza:null,
        id:null,
        status: null,
        type: null,
        productos: null,
        ejecutivoOperacion: null,
        cliente: null,
        newCliente: null,
        agente: null,
        newAgente: null,
        landing: null,
        leadId: null,
        anterior: null,
        emitidaCheck: null,
        emitidDate: null,
        primerPagoCheck: null,
        primerPagoDate: null,
        canceladaCheck: null,
        canceladaDate: null,
        canceladaMotivo: null,
        finalizadaCheck: null,
        finalizadaDate: null,
        finalizadaMotivo: null,
        inicioDate: null,
        finDate: null,
        periodicidad: null,
        periodicidadNumber: null,
        primaNeta: null,
        primaTotal: null,
        montoPrimerPago: null,
        montoSubsecuente: null,
        formaPago: null,
        numeroCuotas: null,
        primerPagoPolizaDate: null,
        noCuotasPagadas: null,
        creadoPorDate: null,
        creadoPor: null,
        ultimaModoficacionDate: null,
        ultimaModoficacion: null,
        observaciones: null,
        nombres: null,
        apellidoPaterno: null,
        apellidoMaterno: null,
        fechaNacimiento: null,
        selectedGenero: null,
        celular: null,
        correoElectronico: null,
        estadoRepublicaSelected: null,
        codigoPostal: null,
        modelo: null,
        marca: null,
        submarca: null,
        version: null,
        detalleAuto: null,
        plan: null,
        razonSocial: null,
        regimen: null,
        origenAuto: null,
        usoAuto: null,
        noMotor: null,
        noSerie: null,
        placas: null,
        notas: null,
        canceladaFinalizadaMotivo: null,
        rfc: null,
        solicitudRelacionada: null,
        categoriaSelected:null,
        edad: null,
        genero: null,
        estado_civil: null,
        colonia: null,
        ciudad: null,
        calle:null,
        num_interior: null,
        num_exterior: null,
        campania_id:null,
      },
      correoAgente: null,
      years: null,
      productos: [],
      cuotas: [],
      documentos: [],
      correos: [],
      clienteUuid: null,
      columnsEmails: [
        {
          text: "Asunto",
          align: "start",
          sortable: false,
          value: "asunto",
        },
        { text: "Fecha", value: "fecha" },
        { text: "Para", value: "remitente" },
      ],
      columnsDocumentos: [
        {
          text: "Nombre",
          align: "start",
          sortable: false,
          value: "nombre",
        },
        { text: "Tipo", value: "tipo" },
        { text: "Descripción", value: "descripcion" },
        { text: "Creado por", value: "creadoPor" },
        { text: "Fecha", value: "fecha" },
        { text: "Ver", value: "url" },
      ],
      columnsCuotas: [
        {
          text: 'Estado de Cuota',
          align: 'start',
          sortable: false,
          value: 'status',
        },
        { text: 'Valor a Pagar', value: 'valorPagar' },
        { text: 'Fecha de Pago Acordada', value: 'fechaPagoAcordada' },
        { text: 'Ver Cuota', value: 'uuid' },
      ],
      ejecutivos: [],
      clienteList: [
        {id: 1, name: 'Cliente 1'},
        {id: 2, name: 'Cliente 2'},
        {id: 3, name: 'Cliente 3'},
        {id: 4, name: 'Cliente 4'},
      ],
      emision: null,
      uuid: this.$route.params.uuid,
      estados: [
        {id: 1, estadoRepublica: 'Aguascalientes'},
        {id: 2, estadoRepublica: 'Baja California'},
        {id: 3, estadoRepublica: 'Baja California Sur'},
        {id: 4, estadoRepublica: 'Campeche'},
        {id: 5, estadoRepublica: 'Coahuila de Zaragoza'},
        {id: 6, estadoRepublica: 'Colima'},
        {id: 7, estadoRepublica: 'Chiapas'},
        {id: 8, estadoRepublica: 'Chihuahua'},
        {id: 9, estadoRepublica: 'Ciudad de México'},
        {id: 10, estadoRepublica: 'Durango'},
        {id: 11, estadoRepublica: 'Guanajuato'},
        {id: 12, estadoRepublica: 'Guerrero'},
        {id: 13, estadoRepublica: 'Hidalgo'},
        {id: 14, estadoRepublica: 'Jalisco'},
        {id: 15, estadoRepublica: 'Estado de México'},
        {id: 16, estadoRepublica: 'Michoacán de Ocampo'},
        {id: 17, estadoRepublica: 'Morelos'},
        {id: 18, estadoRepublica: 'Nayarit'},
        {id: 19, estadoRepublica: 'Nuevo León'},
        {id: 20, estadoRepublica: 'Oaxaca'},
        {id: 21, estadoRepublica: 'Puebla'},
        {id: 22, estadoRepublica: 'Querétaro'},
        {id: 23, estadoRepublica: 'Quintana Roo'},
        {id: 24, estadoRepublica: 'San Luis Potosí'},
        {id: 25, estadoRepublica: 'Sinaloa'},
        {id: 26, estadoRepublica: 'Sonora'},
        {id: 27, estadoRepublica: 'Tabasco'},
        {id: 28, estadoRepublica: 'Tamaulipas'},
        {id: 29, estadoRepublica: 'Tlaxcala'},
        {id: 30, estadoRepublica: 'Veracruz de Ignacio de la Llave'},
        {id: 31, estadoRepublica: 'Yucatán'},
        {id: 32, estadoRepublica: 'Zacatecas'},
      ],
      cancelacionFinalizacionList: [
        {id: 0, name: "Falta de pago"},
        {id: 1, name: "Incremento en prima / Renovación"},
        {id: 2, name: "Mal servicio agente"},
        {id: 3, name: "Mal servicion compañía"},
        {id: 4, name: "Económicas"},
        {id: 5, name: "Inconformidad con el producto"},
        {id: 6, name: "Mejora en competencia"},
        {id: 7, name: "Ya no tiene necesidad / Fallecimiento / Cambio de Agente"},
      ],
      tipoItems: [
        "Nota",
        "Documento"
      ],
      tipoDocumentoItems: [
        "Acta constitutiva",
        "Aviso de privacidad firmado",
        "Caratula de afiliación",
        "Comprobante de Domicilio",
        "Comprobante Médico",
        "Constancia de situación fiscal",
        "Caratula de estado de cuenta",
        "Cotización",
        "Cédula del Agente",
        "Identificación oficial",
        "Documento de afiliación",
        "Poder Notarial",
        "Póliza",
        "RC de Agente",
        "Tarjeta de Circulación",
        "Otro",
      ],
      categoria: [
        "AUTOS",
        "DAÑOS",
        "SALUD",
        "VIDA",
        "AHORRO",
        "FIANZA",
      ],
      campanias: []
    };
  },
  methods: {
    goBack() {
      return this.$router.go(-1);
    },
    verEmisionEditable() {
      this.$router.push(`/actualizar-emision/${this.uuid}`);
    },
    verSolicitud() {
      this.$router.push(`/seguimiento-solicitud/${this.poliza.solicitudRelacionada}`)
    },
    verDetalleCorreo(correo){
      console.log("Correo open:", correo);
      this.dialogCorreo=true;
      this.correoOpen=correo;
    },
    verDocumento(documento) {
      this.dialogDetalleDocumentos = true
      this.detallesDocumento = documento
    },
    obtenerProductos() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios.get('/v1/producto/list', config).then(response => {
        // console.log('Productos: ', response.data);
        response.data.map(element => {
          this.productos.push({
            nombre: `${element.ramo}`+ ' / ' + `${element.nombre}` + ' / ' + `${element.nombreAseguradora}`,
            id: element.id
          })
        })
      });
    },
    obtenerEjecutivos() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios.get('/v1/agentes/operador', config).then(response => {
        // console.log("Ejecutivos: ", response.data);
        response.data.map(element => {
          this.ejecutivos.push({
            nombre: `${element.nombre}` + " " + `${element.apellidoPaterno}` + " " + `${element.apellidoMaterno ? element.apellidoMaterno : ''}`,
            id: element.id,
            uuid: element.uuid
          })
        })
      });
    },
    cargarEmision() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios
        .get("/v1/emision/id/"+this.uuid, config)
        .then((value) => {
          console.log("Emision: ", value.data);
          this.documentos = []
          this.correos = []
          this.emision = value.data;
          this.emision.categoriaSelected = JSON.parse(value.data.requestJson).categoriaSelected ? JSON.parse(value.data.requestJson).categoriaSelected : null

          if (this.emision.aseguradoraId==3){
            if(this.emision.cotizacionId != undefined){ // en caso de que no exista el id de la cotizacion
              mainAxios.get('/v1/cotizacion/id/'+this.emision.cotizacionId,config).then((value2) => {
                  this.cotizacion=value2.data.cotizacion;
              });
            }
          }

          if(this.emision.urlPoliza != null && this.emision.urlPoliza != ""){
            this.documentos.push({
              nombre: this.emision.urlPoliza.split('/')[1],
              fecha: moment.utc(this.emision.fecha).subtract(6, 'hours').format('DD/MM/YYYY HH:mm'),
              tipo: 'Documento',
              tipoDocumento: 'Póliza',
              descripcion: 'No Disponible',
              creadoPor: this.emision.creadoPor,
              url: `https://segurify-data.s3.amazonaws.com/${this.emision.urlPoliza}`
            });
          }

          // Agrego el resto de documentos de la emision
          value.data.documentos ? value.data.documentos.map(element => {
            this.documentos.push({
              nombre: element.nombre ? element.nombre : 'No Disponible',
              fecha: element.fechaCreacion ? moment.utc(element.fechaCreacion).subtract(6, 'hours').format('DD/MM/YYYY HH:mm') : 'No Disponible',
              tipo: element.tipoRegistro ? element.tipoRegistro : 'No Disponible',
              tipoDocumento: element.tipoDocumento ? element.tipoDocumento : 'No Disponible',
              descripcion: element.nota ? element.nota : 'No Disponible',
              creadoPor: element.creadoPor ? element.creadoPor : 'No Disponible',
              url: element.url_bucket
            })
          }) : ''
          //Obtener Emails
          value.data.correos ? value.data.correos.map(element => {
            this.correos.push({
              asunto: element.subject,
              fecha: element.fecha ? moment.utc(element.fecha).subtract(6, 'hours').format('DD/MM/YYYY HH:mm') : null,
              cuerpo:element.cuerpo,
              remitente: element.remitente,
              to: element.to,
              cc: element.cc,
              bcc: element.bcc
            })
          }) : ''

          this.poliza.poliza = this.emision.poliza == 'null' ? this.emision.id : this.emision.poliza
          this.poliza.id = this.emision.id
          this.poliza.status = this.emision.status == 0 ? 'Nueva' 
                                : this.emision.status == 1 ? 'Emitida Pendente de Pago'
                                : this.emision.status == 2 ? 'Pagada Parcial'
                                : this.emision.status == 3 ? 'Pagada Total'
                                : this.emision.status == 4 ? 'Renovada'
                                : this.emision.status == 5 ? 'Finalizada'
                                : this.emision.status == 8 ? 'Duplicada'
                                : 'Cancelada'
          this.poliza.type = this.emision.tipoPoliza
          this.poliza.productos = this.emision.productoId
          this.poliza.ejecutivoOperacion = parseInt(this.emision.operadorId)
          this.poliza.agente = this.emision.agenteId
          this.poliza.solicitudRelacionada = this.emision.solicitudId
          this.correoAgente = this.emision.correoAgente ? this.emision.correoAgente : null
          this.poliza.landing = this.emision.campania
          this.poliza.leadId = null
          this.poliza.anterior = this.emision.polizaAnterior
          this.poliza.emitidaCheck = null
          this.poliza.emitidDate = null
          this.poliza.primerPagoCheck = null
          this.poliza.primerPagoDate = null
          this.poliza.canceladaCheck = null
          this.poliza.canceladaDate = this.emision.fechaCancelacion ? moment(this.emision.fechaCancelacion, 'YYYY-MM-DD').format('DD/MM/YYYY') : null
          this.poliza.canceladaMotivo = null
          this.poliza.finalizadaCheck = null
          this.poliza.finalizadaDate = this.emision.fechaFinalizacion ? moment(this.emision.fechaFinalizacion, 'YYYY-MM-DD').format('DD/MM/YYYY') : null
          this.poliza.finalizadaMotivo = null
          this.poliza.inicioDate = this.emision.inicio ? moment(this.emision.inicio, 'YYYY-MM-DD').format('DD/MM/YYYY') : null
          this.poliza.finDate = this.emision.fin ? moment(this.emision.fin, 'YYYY-MM-DD').format('DD/MM/YYYY') : null
          this.poliza.periodicidad = this.emision.pagoId == 1 ? 'Anual' 
                                    :  this.emision.pagoId == 2 ? 'Semestral' 
                                    :  this.emision.pagoId == 3 ? 'Trimestral'
                                    : 'Mensual'
          this.poliza.periodicidadNumber = this.emision.pagoId
          this.years = this.emision.anios ? this.emision.anios : 1
          this.poliza.primaNeta = this.emision.primaNeta
          this.poliza.primaTotal = this.emision.total
          this.poliza.montoPrimerPago = this.emision.primerPago
          this.poliza.montoSubsecuente = this.emision.subsecuentePago
          this.poliza.formaPago = this.emision.formaPago == 1 ? 'Tarjeta de Crédito' 
                                  : this.emision.formaPago == 2 ? 'Tarjeta de Débito' 
                                  : this.emision.formaPago == 3 ? 'Depósito en Banco' 
                                  : this.emision.formaPago == 4 ? 'Transferencia' 
                                  : this.emision.formaPago == 5 ? 'Cheque' 
                                  : this.emision.formaPago == 6 ? 'Efectivo' 
                                  : this.emision.formaPago == 7 ? 'Domiciliación' 
                                  : this.emision.formaPago == 8 ? 'Cargo Único' : ''
                                  
          // this.poliza.numeroCuotas = this.emision.pagoId == 1 ? 1
          //                           :  this.emision.pagoId == 2 ? 2 
          //                           :  this.emision.pagoId == 3 ? 4
          //                           : 6
          this.poliza.primerPagoPolizaDate = null
          this.poliza.noCuotasPagadas = null
          this.poliza.creadoPorDate = moment.utc(this.emision.fecha).subtract(6, 'hours').format('DD/MM/YYYY HH:mm')
          this.poliza.creadoPor = this.emision.creadoPor ? this.emision.creadoPor : ''
          this.poliza.cliente = this.emision.cliente
          this.poliza.ultimaModoficacionDate = moment.utc(this.emision.ultimaModificacion).subtract(6, 'hours').format('DD/MM/YYYY HH:mm')
          this.poliza.ultimaModoficacion = this.emision.modificadoPor ? this.emision.modificadoPor : 'No Disponible'
          this.poliza.observaciones = this.emision.observaciones
          this.poliza.nombres = this.emision.nombre
          this.poliza.apellidoPaterno = this.emision.apellidoPaterno
          this.poliza.apellidoMaterno = this.emision.apellidoMaterno
          this.poliza.fechaNacimiento = this.emision.fechaNacimiento ? moment(this.emision.fechaNacimiento, 'YYYY-MM-DD').format('DD/MM/YYYY') : null
          this.poliza.selectedGenero = this.emision.genero
          this.poliza.celular = this.emision.telefonoContacto
          this.poliza.correoElectronico = this.emision.correoContacto
          this.poliza.estadoRepublicaSelected = this.emision.estadoId == 1 ? 'Aguascalientes'
              : this.emision.estadoId == 2 ? 'Baja California'
              : this.emision.estadoId == 3 ? 'Baja California Sur'
              : this.emision.estadoId == 4 ? 'Campeche'
              : this.emision.estadoId == 5 ? 'Coahuila de Zaragoza'
              : this.emision.estadoId == 6 ? 'Colima'
              : this.emision.estadoId == 7 ? 'Chiapas'
              : this.emision.estadoId == 8 ? 'Chihuahua'
              : this.emision.estadoId == 9 ? 'Ciudad de México'
              : this.emision.estadoId == 10 ? 'Durango'
              : this.emision.estadoId == 11 ? 'Guanajuato'
              : this.emision.estadoId == 12 ? 'Guerrero'
              : this.emision.estadoId == 13 ? 'Hidalgo'
              : this.emision.estadoId == 14 ? 'Jalisco'
              : this.emision.estadoId == 15 ? 'Estado de México'
              : this.emision.estadoId == 16 ? 'Michoacán de Ocampo'
              : this.emision.estadoId == 17 ? 'Morelos'
              : this.emision.estadoId == 18 ? 'Nayarit'
              : this.emision.estadoId == 19 ? 'Nuevo León'
              : this.emision.estadoId == 20 ? 'Oaxaca'
              : this.emision.estadoId == 21 ? 'Puebla'
              : this.emision.estadoId == 22 ? 'Querétaro'
              : this.emision.estadoId == 23 ? 'Quintana Roo'
              : this.emision.estadoId == 24 ? 'San Luis Potosí'
              : this.emision.estadoId == 25 ? 'Sinaloa'
              : this.emision.estadoId == 26 ? 'Sonora'
              : this.emision.estadoId == 27 ? 'Tabasco'
              : this.emision.estadoId == 28 ? 'Tamaulipas'
              : this.emision.estadoId == 29 ? 'Tlaxcala'
              : this.emision.estadoId == 30 ? 'Veracruz'
              : this.emision.estadoId == 31 ? 'Yucatán'
              : this.emision.estadoId == 32 ? 'Zacatecas'
              : this.emision.estadoId == 33 ? 'Desconocio' :'',
          this.poliza.codigoPostal = this.emision.codigoPostal
          this.poliza.modelo = this.emision.modelo
          this.poliza.marca = this.emision.marca
          this.poliza.submarca = this.emision.submarca
          this.poliza.version = this.emision.nombreVehiculo
          this.poliza.detalleAuto = this.emision.detalleAuto
          this.poliza.plan = this.emision.planId == 1 ? 'Amplia'
                            : this.emision.planId == 14 ? 'Amplia Plus'
                            : this.emision.planId == 3 ? 'Limitada'
                            : 'RC_reglamentaria'
          this.poliza.regimen = this.emision.tipoPersona
          this.poliza.razonSocial = this.emision.razonSocial ? this.emision.razonSocial : null
          this.poliza.origenAuto = this.emision.origenAuto
          this.poliza.usoAuto = this.emision.usoAuto
          this.poliza.noMotor = this.emision.numMotor
          this.poliza.noSerie = this.emision.numSerie
          this.poliza.placas = this.emision.placas
          this.poliza.notas = this.emision.notas
          this.poliza.rfc = this.emision.rfc
          this.poliza.canceladaFinalizadaMotivo = this.emision.motivoCancelacion
          let jsonParse = this.emision.requestJson ? JSON.parse(this.emision.requestJson): {}
          this.poliza.edad = jsonParse.edad
          this.poliza.genero = jsonParse.genero
          this.poliza.estado_civil = jsonParse.estado_civil
          this.poliza.codigo_postal = jsonParse.codigo_postal
          this.poliza.colonia = jsonParse.colonia
          this.poliza.ciudad = jsonParse.ciudad
          this.poliza.calle = jsonParse.calle
          this.poliza.num_exterior = jsonParse.num_exterior
          this.poliza.num_interior = jsonParse.num_interior
          this.poliza.campania_id = jsonParse.campania_id ? parseInt(jsonParse.campania_id) : ''
          this.poliza.lead_id = this.emision.lead_id ? this.emision.lead_id : ''
          this.obtenerCliente(this.poliza.cliente)
          this.obtenerAgente(this.poliza.agente)
        });
    },
    cargarCuotasDePoliza() {
      const config = {
        headers: {
          
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios
        .get(`/v1/cuota/cuotaByEmisionId/${this.$route.params.uuid}`, config).then(resp => {
          // console.log("Cuotas: ", resp);
          resp.data.map(element => {
            this.cuotas.push({
              status: element.status,
              valorPagar: '$' + element.valorAPagar,
              fechaPagoAcordada: element.fechaPagoAcordada ? moment(element.fechaPagoAcordada, 'YYYY-MM-DD').format('DD/MM/YYYY') : 'No Disponible',
              uuid: element.uuid
            })
          })
        })
    },
    verCuota(uuid) {
      this.$router.push(`/visualizacion-comisiones/${uuid}`)
    },
    obtenerCliente(cliente) {
      if(cliente == undefined || cliente == null){
        return false;
      }
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios
        .get(`/v1/cliente/id/${cliente}`, config).then(resp => {
          this.poliza.newCliente = resp.data.nombreCliente ? resp.data.nombreCliente : resp.data.razonSocial
          this.clienteUuid = resp.data.uuid
        })
    },
    verCliente() {
      this.$router.push(`/cliente-detalle/${this.clienteUuid}`)
      // window.open(this.$router.push(`/cliente-detalle/${this.clienteUuid}`), '_blank')
    },
    obtenerAgente(agente) {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios.get(`/v1/agente/id/${agente}`, config).then(response => {
        console.log("Agente: ", response.data)
        this.poliza.newAgente = `${response.data.nombre}` + " " + `${response.data.apellidoPaterno}` + " " + `${response.data.apellidoMaterno}`
      })
    },
    downloadUrlPoliza() {
      this.downloading = true;
        const link = document.createElement('a')
        link.href= `https://segurify-data.s3.amazonaws.com/${this.emision.urlPoliza}`
        link.setAttribute('download', 'document.pdf')
        document.body.appendChild(link)
        link.click();
        this.downloading = false;
      // let config = {
      //   headers: {
      //      Authorization: 'Bearer ' + localStorage.agenteAccessToken,
      //   },
      //   responseType: 'blob',
      // };
      // corps
      // mainAxios.get(`https://segurify-data.s3.amazonaws.com/${this.emision.urlPoliza}`, config).then((response) => {
      //   this.downloading = false;
      //   require('downloadjs')(
      //     response.data,
      //     'documento.pdf',
      //     'application/pdf'
      //   );
      // }).catch((exception)=>{
      //   console.error(exception);
      //   this.downloading = false;
      // });
    },
    getCampanias() {
      this.loading = true;
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios
        .get("/v1/campania/lead-segurify/list", config)
        .then((response) => {
          this.campanias = response.data;
        });
    },
    closeSendEmail(){
      this.newCorreoDialog=false;
      this.cargarEmision()
    }
  },
  watch: {
    years(value) {
      if (value.typeof != 'number') {
        // console.log("No es number")
        this.years = parseInt(value)
        const meses = (this.years * 12);
        console.log("Calculando cuotas: ", meses);
        switch (this.poliza.periodicidadNumber) {
          case 1:
            this.poliza.numeroCuotas = (meses / 12)
            break;
          case 2:
            this.poliza.numeroCuotas = (meses / 6)
            break;
          case 3:
            this.poliza.numeroCuotas = (meses / 3)
            break;
          case 4:
            this.poliza.numeroCuotas = (meses / 1)
            break;
          default:
            break;
        }
      }
    }
  },
  mounted() {
    this.obtenerProductos(),
    this.obtenerEjecutivos(),
    this.cargarEmision(),
    this.cargarCuotasDePoliza(),
    this.getCampanias()
  },
};
</script>

<style scoped>
  .v-stepper__step__step {
    background-color: white;
  }
</style>

