<template>
    <v-card class="rounded-card " :elevation="elevation">
      <slot></slot>
    </v-card>
  </template>
  
  <script>
  export default {
    props: {
      elevation: {
        type: [Number, String],
        default: 3,
      },
    },
  };
  </script>
  
  <style scoped>
  .rounded-card {
    border-radius: 20px;
    max-width: "100%";
  }
  .mi-clase-con-sombra {
    box-shadow: 2px 4px 20px rgba(0, 0, 0, 0.1) !important;
  }
  </style>