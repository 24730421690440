<template>
 
  <v-app>
        <Navigation></Navigation>
        <v-main class="mb-15">
            <v-container fluid>
                <v-card
                    color="grey lighten-4"
                    flat
                    tile
                    style="margin-top: 20px"
                ><v-toolbar>
                    <v-toolbar-title>
                        Visualización de Producto 
                    </v-toolbar-title>

                    <v-spacer></v-spacer>

                    <v-btn icon @click="verProductoEditable($route.params.id)">
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>

                    <v-btn
                        @click="goBack()" 
                        ><v-icon style="margin-right: 5px">mdi-arrow-left</v-icon>Regresar
                    </v-btn>

                    </v-toolbar>
                    <p style="margin-top: 15px; padding-bottom: 10px">{{ titulo }}</p>
                </v-card>
                <v-divider></v-divider>

                <v-row>
                    <v-col cols="7">
                        <v-row style="padding: 20px">
                            <v-col cols="6">
                                <v-text-field
                                    label="Nombre del Producto"
                                    v-model="nombreProducto"
                                    :readonly="isEditing == false"
                                ></v-text-field>
                            </v-col>
                            <!-- End 2nd Col -->
                        </v-row>

                        <v-row style="padding: 20px; margin-top: -50px">
                            <v-col cols="6">
                                <v-text-field
                                    label="Compañia"
                                    v-model="compania"
                                    readonly
                                ></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field
                                    label="Ramo"
                                    v-model="ramo"
                                    readonly
                                ></v-text-field>
                            </v-col>
                            <!-- End 4th Col -->
                        </v-row>

                        <v-row style="padding: 20px; margin-top: -50px">
                            <v-col cols="6">
                                <v-text-field
                                    label="% Comisión Agente"
                                    v-model="comisionAgente"
                                    readonly
                                ></v-text-field>
                            </v-col>
                            <!-- End 7th Col -->
                        </v-row>

                        <v-row style="padding: 20px; margin-top: -50px">
                            <v-col cols="6">
                                <v-text-field
                                    label="% Bono"
                                    v-model="bono"
                                    readonly
                                ></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field
                                    label="% Comisión pagada por la aseguradora"
                                    v-model="comisionPayAseguradora"
                                    readonly
                                ></v-text-field>
                            </v-col>
                        </v-row>

                        <v-row style="padding: 20px; margin-top: -50px">
                            <v-col cols="6">
                                <v-text-field
                                    label="% Comisión Total"
                                    v-model="comisionTotal"
                                    readonly
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-container>
        </v-main>
    </v-app>
</template>

<script>
import Navigation from "../../../components/Nav.vue";
import { mainAxios } from '../../../mainAxios';
export default {
    components: {
        Navigation,
    },
    data() {
        return {
            nombreProducto: null,
            compania: null,
            ramo: null,
            comisionAgente: null,
            bono: null,
            comisionPayAseguradora: null,
            comisionTotal: null,
            titulo: null,
            isEditing: false,
        }
    },
    methods: {
        goBack() {
            return this.$router.go(-1);
        },
        obtenerDatosDeProducto() {
            const config = {
                headers: {
                    Authorization: "Bearer " + localStorage.agenteAccessToken,
                },
            };
            mainAxios.get(`/v1/producto/id/${this.$route.params.id}`, config).then((response) => {
                console.log(response.data);
                this.nombreProducto = response.data.nombre.toUpperCase(),
                this.compania = response.data.nombreAseguradora.toUpperCase(),
                this.ramo = response.data.ramo.toUpperCase(),
                this.comisionAgente = response.data.comisionAgente,
                this.bono = response.data.bono,
                this.comisionPayAseguradora = response.data.comisionAseguradora,
                this.comisionTotal = response.data.comisionTotal,
                this.titulo = `${response.data.ramo.toUpperCase()} / ${response.data.nombre.toUpperCase()} / ${response.data.nombreAseguradora.toUpperCase()}`
            })
        },
        verProductoEditable(id) {
            this.$router.push(`/edicion-productos/${id}`);
        },
    },
    mounted() {
        this.obtenerDatosDeProducto()
    }
}
</script>