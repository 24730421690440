<template>
  <v-row style="padding: 0px 20px">
    <v-col cols="4">
      <v-select
        label="Tipo de portafolios"
        v-model="tipoPortafolios"
        :items="tipoPortafoliosList"
        @change="avisarCambio"
      ></v-select>
    </v-col>
    <v-col cols="4">
      <v-select
        label="Fumador"
        v-model="fumador"
        :items="fumadorList"
        @change="avisarCambio"
      ></v-select>
    </v-col>
    <v-col cols="4">
      <v-text-field
        label="Plazo comprometido"
        v-model="plazoComprometido"
        @change="avisarCambio"
        v-number-only
      ></v-text-field>
    </v-col>
    <v-col cols="4">
      <v-select
        label="Deducibilidad"
        v-model="deducibilidad"
        :items="deducibilidadList"
        @change="avisarCambio"
      ></v-select>
    </v-col>
    <v-col cols="4">
      <v-select
        label="Revaluable con la inflación"
        v-model="revaluableInflacion"
        :items="revaluableInflacionList"
        @change="avisarCambio"
      ></v-select>
    </v-col>
    <v-col cols="4">
      <v-text-field
        label="Valor del Plan"
        v-model="valorPlan"
        @change="avisarCambio"
        prefix="$"
        v-number-only
      ></v-text-field>
    </v-col>
    <v-col cols="4">
      <v-text-field
        label="Monto de la inversión/Aportación"
        v-model="montoInversion"
        @change="avisarCambio"
        prefix="$"
        v-number-only
      ></v-text-field>
    </v-col>

  </v-row>
</template>

<script>
export default {
  directives: {
    'number-only': {
      bind (el) {
        function checkValue (event) {
          event.target.value = event.target.value.replace(/[^0-9]/g, '')
          if (event.charCode >= 48 && event.charCode <= 57) {
            return true
          }
          event.preventDefault()
        }
        el.addEventListener('keypress', checkValue)
      }
    },
    'letter-only': {
      bind (el) {
        function checkValue (event) {
          if (event.charCode >= 48 && event.charCode <= 57) {
            event.preventDefault()
          }
          return true
        }
        el.addEventListener('keypress', checkValue)
      }
    }
  },
  data () {
    return {
      tipoPortafolios: null,
      fumador: null,
      plazoComprometido: null,
      deducibilidad: null,
      revaluableInflacion: null,
      valorPlan: null,
      montoInversion: null,

      //listas
      tipoPortafoliosList: [
        'Moderado',
        'Medio',
        'Agresivo',
        'Elite Inversión',
        'Patrimonial Inversión',
        'Plus',
        'Educacional',
      ],
      fumadorList: [
        "Si",
        "No",
      ],
      deducibilidadList: [
        "Si",
        "No",
      ],
      revaluableInflacionList: [
        "Si",
        "No",
      ],
      
      //Validadores
      inputsRequeridos: [
        value => !!value || 'Campo Requerido',
      ],
    }
  },
  props: ['avisar'],
  methods: {
    avisarCambio(){
      let datos = {
        tipoPortafolios: this.tipoPortafolios,
        fumador: this.fumador,
        plazoComprometido: this.plazoComprometido,
        deducibilidad: this.deducibilidad,
        revaluableInflacion: this.revaluableInflacion,
        valorPlan: this.valorPlan,
        montoInversion: this.montoInversion,
      }
      this.avisar(datos)
    }
  }
}
</script>