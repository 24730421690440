<template>
  <div>
    <section class="section" id="call-to-action">
      <div class="container">
          <div class="row">
              <div class="col-lg-10 offset-lg-1">
                  <div class="cta-content">
                      <h2>Preguntas <em>frecuentes</em></h2>
                      <p>Si tienes alguna pregunta tal vez aqui puedes encontrar la respuesta a algunas de ellas.</p>
                  </div>
              </div>
          </div>
      </div>
    </section>
    <section class="pb-8 pt-6" id="faqs">
      <v-container fluid>
        <v-row align="center" justify="center">
          <v-col cols="10">
            <v-row justify="center">
              <v-expansion-panels inset class="mt-3">
                <v-expansion-panel v-for="(item, i) in faqs" :key="i">
                  <v-expansion-panel-header class="font-color">{{ item.header }}</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    {{ item.content }}
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
      <!-- <div class="svg-border-waves text-white">
        <v-img src="~@/assets/img/borderWavesBlue.svg"/>
      </div> -->
      <v-snackbar
        v-model="snackbar.enabled"
        timeout="3000"
        right
        top
        :color="snackbar.color"
      >
        {{ snackbar.text }}

        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar.enabled = false">
            Fechar
          </v-btn>
        </template>
      </v-snackbar>
    </section>
  </div>
</template>

<style scoped>
#contact {
  background-color: #f4f7f5;
}

#title {
  text-align: center;
}

.svg-border-waves .v-image {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3rem;
  width: 100%;
  overflow: hidden;
}

.font-color {
  color: #42A5F5;
}

.v-expansion-panel {
  background-color: violet;
}
</style>

<script>

export default {
  data: () => ({
    icons: ["fa-facebook", "fa-twitter", "fa-linkedin", "fa-instagram"],
    snackbar: {
      enabled: false,
      text: "",
      color: "",
    },
    faqs: [
      {
        header: "¿Qué cubre mi seguro y cómo funciona?",
        content:
          "Modalidad Plus: cubre cualquier daño accidental sufrido por el celular asegurado, daños causados por líquido al celular, robo con violencia y robo sin violencia. En el caso de daño accidental, nosotros nos encargamos de repararlo. En el caso de robo con violencia o sin violencia, te entregamos un celular igual o similar al que tenías.",
      },
      {
        header: "¿Qué no cubre el seguro?",
        content: "Cualquier siniestro por guerra, terrorismo, participación en conflictos armados o riesgos nucleares." +
                 "Daños causados intencionalmente o por una conducta negligente." +
                 "Si asumes riesgos con tu dispositivo conscientemente, es posible que rechacemos su siniestro. A continuación, te damos ejemplos de los casos en que consideramos que has podido asumir riesgos consiente con tu celular: En una cafetería, bar o restaurante, si dejas tu dispositivo sobre la mesa cuando vas al baño, en lugar de llevarlo contigo, en un gimnasio, si dejas tu celular en un banco de los vestuarios, en lugar de dejarlo en tu casillero o llevarte el celular contigo.",
      },
      {
        header: "¿Qué tipo de equipos puedo asegurar?",
        content: "Puedes asegurar cualquier celular, sin límite de antigüedad o importe de compra, siempre que tengas una factura de compra a tu nombre (o alguien de tu núcleo familiar: padre, madre, cónyuge, las personas de tu familia con las que compartes vivienda), un contrato de compraventa o cualquier documento que acredite cuando compraste el equipo, cuanto te costó y que el equipo es tuyo."
      },
      {
        header: "¿Puedo asegurar un celular que me ha dado gratis mi compañía de teléfono con cargo a algún programa de puntos?",
        content: "Sí, puedes asegurarlo. Debes tener la factura o recibo que te entregó tu compañía telefónica, y aseguraremos tu celular por el valor de mercado del terminal."
      },
      {
        header: "¿Puedo asegurar un celular libre?",
        content: "Sí, puedes asegurar tanto celulares libres como celulares subvencionados incluyendo un teléfono que te haya podido regalar la compañía, siempre que tengas la factura o recibo de entrega."
      },
      {
        header: "¿La factura debe estar a mi nombre? ¿Qué pasa si no tengo factura de compra?",
        content: "La factura o documento que acredite el propietario del seguro debe estar a nombre de la misma persona que contrate el seguro o bien a nombre de una persona dentro de su núcleo familiar (madre, padre, cónyuge, las personas de tu familia con las que compartes vivienda)." +
                 "Si no tienes factura de compra, podemos asegurar un terminal siempre que dispongas de un recibo de entrega, un contrato de compraventa o en general cualquier documento que acredite que tú eres el propietario del teléfono y la fecha en que lo adquiriste, identificándose en dicho documento la marca, modelo e IMEI del celular."
      },
      {
        header: "¿Qué información necesito para contratar?",
        content: "Solo tendrás que facilitarnos tus datos personales (RFC, Nombre, Apellidos y Fecha de Nacimiento) y datos del teléfono (marca, modelo, fecha compra, precio de compra e IMEI).Respecto al precio de compra, en caso de que no hayas tenido que pagar nada por tu teléfono, debes indicar el precio de mercado del teléfono.El número IMEI lo puedes encontrar en el propio móvil o smartphone: teclea *#06# y aparecerá de forma automática. Si tu dispositivo está en modo avión, asegúrate de desactivarlo o puede que no te muestre el número IMEI."
      },
      {
        header: "¿Cómo pago mi seguro?",
        content: "Hayas elegido la modalidad de pago anual o mensual (meses sin intereses), te solicitaremos una tarjeta de débito o crédito para realizar el pago de los recibos de tu seguro." +
                 "En la modalidad meses sin intereses aceptamos pagos con tarjetas de crédito Visa o Master card así como American Express."
      },
      {
        header: "¿El seguro tiene periodo de espera, desde cuándo tendría cobertura? Si al día siguiente de contratar el seguro tengo un siniestro, ¿Me lo cubren? ¿Tengo que pagar algo en caso de siniestro? ¿El seguro tiene deducible?",
        content: "Nuestro seguro no tiene ningún tipo de periodo de espera o periodo durante el cual tu celular no está asegurado. Si al día siguiente de contratar se te cae el celular y se rompe, tendría total cobertura y te lo repararíamos. Eso sí, siempre que verifiquemos el adecuado estado de tu dispositivo a través de la APP de Insurama que te podrás descargar al recibir nuestro email de confirmación de alta. En caso de siniestro, no tendrás que pagar ninguna cantidad en concepto de deducible." +
                 "Para caso de tu cobertura de robo, es muy importante que tengas activada la geolocalización dentro de la app Insurama instalada en el celular asegurado. Esto no solo para hacer valida tu póliza sino también para poder (de ser posible) ayudarte a recuperarlo."
      },
      {
        header: "¿Qué es la peritación?",
        content: "La peritación es un proceso de verificación que debemos realizar para comprobar el estado de tu equipo celular al momento de la contratación y cerciorarnos que no cuente con daño preexistente a la contratación del seguro."
      },
      {
        header: "¿Tengo que peritar mi terminal?",
        content: "Sí, debes descargarte nuestra APP tras contratar el seguro y seguir las instrucciones de peritación que te indica la APP. El proceso consiste en dos fotos, frontal y trasera del equipo sin ningún tipo de funda o protector de pantalla."
      },
      {
        header: "¿Cuánto dura mi contrato?",
        content: "El seguro tiene una duración de 12 meses, renovándose por periodos de 12 meses salvo que el cliente nos comunique que no desea renovar el seguro, previo a su periodo de renovación."
      },
      {
        header: "¿Qué tengo que hacer en caso de que tenga un siniestro de rotura de pantalla o robo?",
        content: "A través de nuestra APP o bien accediendo al espacio cliente de nuestra Web, utilizando el usuario y contraseña que te hemos remitido en el email de bienvenida, deberás completar el formulario para declarar el siniestro. En cualquier caso, deberás aportarnos la factura de compra del equipo." +
                 "Si se te rompe la pantalla, podrás elegir si quieres reparar tu teléfono en nuestra red de tiendas reparadoras, o bien prefieres que recojamos tu terminal en tu domicilio u oficina (en caso de contar cobertura al momento de tu siniestro y en la zona geográfica en la que te encuentres) para que procedamos a repararlo en nuestros centros de reparación autorizados." +
                 "Si te roban el celular lo primero que deberás hacer es levantar la denuncia por robo en el ministerio público que te corresponde. Con dicha denuncia, deberás solicitar a tu compañía telefónica que proceda al bloqueo del IMEI, para que nadie pueda usar tu teléfono. Una vez hayas realizado estos dos pasos, deberás, a través de nuestra APP o del Espacio Cliente de nuestra web, completar el formulario para declarar el siniestro, adjuntando copia de la factura de compra, copia de la denuncia y copia de la confirmación de bloqueo del IMEI. En un plazo no superior a 7 días te enviaremos un terminal igual al que tenías asegurado, salvo que tu modelo esté descatalogado, en cuyo caso te enviaremos un modelo de similares prestaciones y gama."
      },
      {
        header: "¿Hay algún límite de siniestros?",
        content: "Te aseguramos hasta un máximo de dos siniestros al año, uno de daños (rotura de pantalla, cualquier daño material o daños por agua) y otro por robo con violencia o robo sin violencia."
      },
      {
        header: "¿Cómo puedo tramitar cualquier cambio o solicitar un duplicado del mismo?",
        content: "Podrás realizar cualquier gestión vinculada a tu seguro de forma inmediata y automática desde nuestra APP o bien accediendo al Espacio cliente de nuestra Web, utilizando el usuario y contraseña que te hemos remitido en el email de bienvenida." +
                 "Recuerda que también puedes comunicarte con nosotros a través del número 5559854280o por medio del correo electrónico clientes@insurama.mx"
      },
      {
        header: "¿Cuáles son los plazos disponibles para la frecuencia de pago de Meses sin Intereses?",
        content: "Por defecto, 12 Meses sin intereses para contrataciones con primas anuales superiores a $2,500, y sólo para pagos con tarjeta de crédito."
      },
      {
        header: "¿Cuál es el monto mínimo para la oferta de Meses sin Intereses en nuestra web?",
        content: "La oferta de Meses sin Intereses aplica a los seguros contratados con una prima anual igual o mayor a $2,500."
      },
      {
        header: "¿Cuáles son los bancos que participan?",
        content: "Puedes usar cualquier tarjeta de crédito de tarjetas Visa, American Express y Master Card."
      },
      {
        header: "¿Qué puedo hacer si mi tarjeta de crédito pertenece a uno de los bancos participantes pero la opción de Meses sin intereses no está habilitada?",
        content: "Cada banco es quién determina cuáles de sus tarjetas participan, es por ello que te sugerimos que te comuniques con tu banco."
      },
      {
        header: "¿Puedo contratar con Meses sin intereses con una tarjeta de crédito emitida en el extranjero?",
        content: "No, únicamente aplica la promoción para contrataciones realizadas con tarjetas de crédito participantes expedidas en México."
      },
      {
        header: "¿Cómo se calculan los pagos a Meses sin intereses?",
        content: "Si escoges como frecuencia de pago “Mensual (12 meses sin intereses)” te indicaremos directamente el monto mensual."
      },
    ],
  }),
  methods: {
    submit() {
      /*db.collection("contactData").add({
        name: this.name,
        email: this.email,
        message: this.textArea
      }).then(() => {
        this.snackbar.text = "Mensagem enviada com sucesso"
        this.snackbar.color = "success"
        this.snackbar.enabled = true
      }).catch(() => {
        this.snackbar.text = "Erro ao enviar mensagem"
        this.snackbar.color = "danger"
        this.snackbar.enabled = true
      })*/
    },
  },
};
</script>

<style scoped>
  #call-to-action {
  padding: 11px 0px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  background: rgb(0,56,101);
  background: linear-gradient(90deg, rgba(0,56,101,1) 0%, rgba(0,111,200,1) 62%);
}

#our-classes {
  margin-bottom: 140px;
}

#our-classes .tabs-content {
  margin-left: 0px;
  margin-top: 30px;
}

.cta-content h2 {
  font-size: 36px;
  text-transform: uppercase;
  font-weight: 800;
  color: #fff;
  letter-spacing: 1px;
}

.cta-content h2 em {
  font-style: normal;
  color: #ed563b;
}

.cta-content p {
  font-size: 16px;
  color: #fff;
  margin: 15px 0px 25px 0px;
}

.main-button a {
  display: inline-block;
  font-size: 15px;
  padding: 12px 20px;
  background-color: #ed563b;
  color: #fff;
  text-align: center;
  font-weight: 400;
  text-transform: uppercase;
  transition: all .3s;
}

.main-button a:hover {
  background-color: #f9735b;
}

</style>
