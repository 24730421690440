import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';
import VueMoment from 'vue-moment'
import moment from 'moment-timezone'
import { VueMaskDirective } from 'v-mask'
import JsonCSV from 'vue-json-csv'
import VCreditCard from 'v-credit-card';
// import LoadScript from 'vue-plugin-load-script';
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

import * as timezoneParser from "./plugins/timezone";
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
const options = {
  confirmButtonColor: '#41b882',
  cancelButtonColor: '#ff7674',
};
import VueCarousel from 'vue-carousel';

moment.tz.guess()
Vue.component('downloadCsv', JsonCSV)
Vue.component('v-credit-card', VCreditCard);
Vue.use(Toast, {
  transition: "Vue-Toastification__bounce",
  maxToasts: 20,
  newestOnTop: true
});
Vue.use(VueMoment, { moment })
// Vue.use(LoadScript);
Vue.directive('mask', VueMaskDirective);
Vue.prototype.$rules = require("./plugins/rules").default;
Vue.prototype.$randomColor = require("./plugins/randomColor").default;
Vue.prototype.$timezone = timezoneParser;
Vue.config.productionTip = false
Vue.use(VueSweetalert2, options);
Vue.use(VueCarousel);

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')