<template>
    <v-card>
        <v-toolbar flat dense>
            <v-toolbar-title class="text-subtitle-1">Emails</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn small color="primary" @click="open">
                Crear
            </v-btn>
        </v-toolbar>

        <v-divider></v-divider>

        <v-data-table
            :headers="columns"
            :items="data_table"
            item-key="name"
            class="elevation-1"
            loading-text="Cargando..."
            dense
            :items-per-page="5"
        >
            <template v-slot:[`item.accion`]="{ item }">
                <v-icon small class="mr-2" @click="verLlamada(item)">
                    mdi-eye
                </v-icon>
            </template>
        </v-data-table>
    </v-card>
</template>

<script>
    // import { mainAxios } from "../../../mainAxios";
    // import moment from 'moment';

    export default {
        name: 'EmailLead',
        data: function(){
            return{
                columns: [
                    { text: "Campaña", align: "start", sortable: false, value: "campana"},
                    { text: "Medio", value: "medio" },
                    { text: "Ramo", value: "ramo" },
                    { text: "Fecha y hora", value: "fecha" },
                ],
                data_table:[],
                uuid: '',
            }
        }, mounted(){
            // this.uuid = this.$route.params.uuid;
            // this.getCampañas()
        }, computed: {

    }, methods: {
            //  async getCampañas(){
            //     const config = {
            //         headers: {
            //         Authorization: "Bearer " + localStorage.agenteAccessToken,
            //         }
            //     }

            //     mainAxios.get('/v1/origen/list/lead-segurify/'+ this.uuid, config).then(response => {
            //         response.data.map(element => {
            //         this.data_table.push({
            //             medio: element.medio,
            //             ramo: element.ramo,
            //             fecha: element.fecha_lead ? moment.utc(element.fecha_lead).format('DD/MM/YYYY') : 'No Disponible',

            //         });
            //     });
            //     })    
            // },
        }
    }
</script>