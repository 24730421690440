<template>
  <v-app>
    <NavLandingCelular :color="color" :flat="flat" />
    <v-main class="pt-0">
      <HomeSectionCelular />
      <AboutSectionCelular />
      <!-- <PricingSectionCelular /> -->
      <FAQsCelular />
      <!-- <download />
      <pricing /> -->
      <ContactSectionCelular />

      <!-- Alert modal for send cotization -->
      <div class="text-center">
        <v-dialog transition="dialog-bottom-transition" persistent max-width="500" v-model="dialogCotizacionEnviada">
          <v-card>
            <v-toolbar color="orange" dark>Alerta de emisión Seguro Celular</v-toolbar>
            <v-card-text>
              <div class="text-h4 pa-12">Confirmación de envío emisión Seguro Celular</div>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn outlined :loading="loading" :disabled="loading" color="orange"
                @click="loading = true, sendIdentifier()">Confirmar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
      <!-- End Alert modal for send cotization -->

    </v-main>
    <HelpCelular />
    <FooterCelular />
  </v-app>
</template>

<script>
import NavLandingCelular from '../../../components/NavLandingCelular.vue';
import HomeSectionCelular from '../../../components/HomeSectionCelular.vue';
import AboutSectionCelular from '../../../components/AboutSectionCelular.vue';
// import PricingSectionCelular from '../../../components/PricingSectionCelular.vue';
import FAQsCelular from '../../../components/FAQsCelular.vue';
import ContactSectionCelular from '../../../components/ContactSectionCelular.vue';
import FooterCelular from '../../../components/FooterCelular.vue';
import { mainAxios } from '../../../mainAxios';

import HelpCelular from "./HelpCelular.vue";
// import moment from 'moment';

export default {
  name: "LandingCelular",

  components: {
    NavLandingCelular,
    HomeSectionCelular,
    AboutSectionCelular,
    // PricingSectionCelular,
    FAQsCelular,
    ContactSectionCelular,
    FooterCelular,
    HelpCelular,
    
  },

  data: () => ({
    fab: null,
    color: "",
    flat: null,
    dialogCotizacionEnviada: false,
    loading: false,
  }),

  created() {
    const top = window.pageYOffset || 0;
    if (top <= 60) {
      // this.color = "transparent";
      this.color = "#000000";
      this.flat = true;
    }
    this.$route.params.identifier ? this.dialogCotizacionEnviada = true : this.dialogCotizacionEnviada = false
  },
  methods: {
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 60;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
    sendIdentifier() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        }
      }

      mainAxios
        .get(`/v1/seguro-celular/poliza/${this.$route.params.identifier}`, config)
        .then(response => {
          if (response.status == 200) {
            this.loading = false
            this.$router.push(`/emision/${this.$route.params.emision_id}`)
          }
        })
    },
  },

  //Todo:
  //genero -> "Femenino" o "Masculino"
  //Emision mandar -> id_cotizacion
  //Emision y Cotización mandar -> pago_id 1 o 12

}
</script>

<style scoped>
.v-main {
  background-image: url("~@/assets/img/bgMain.png");
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
}
</style>