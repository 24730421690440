<template>
    <v-app>
        <Navigation></Navigation>
        <v-main class="mb-15">
            <v-container fluid>
                <v-row class="mb-15 fill-height">
                    <v-col>
                        <v-row>
                            <v-col>
                                <h1>Reporte de Call Back Time</h1>
                            </v-col>
                        </v-row>

                        <v-row class="mb-15" style="padding: 30px">
                            <v-col cols="12" lg="3">
                                <v-select
                                    :items="campanias"
                                    label="Campaña"
                                    outlined
                                    item-text="campania"
                                    @input="searchCampania"
                                    
                                ></v-select>

                                <h3>Selecciona el rango de fechas</h3>
                                <v-date-picker style="width: 100%" v-model="dates" range></v-date-picker>

                                <v-btn
                                    rounded
                                    color="primary"
                                    style="width: 100%"
                                    
                                    @click="generarReporte"
                                    :disabled="campania == null || dates.length != 2"
                                >Generar Reporte</v-btn>

                                <v-btn
                                    rounded
                                    color="primary"
                                    style="width: 100%; margin-top: 20px"
                                    :disabled="!report.length > 0"
                                ><download-csv
                                    :data="reportDownload"
                                    name="reporteCallBackTime.csv"
                                    >Descargar Información</download-csv>
                                </v-btn>
                            </v-col>

                            <v-col cols="12" lg="9">
                                <div>
                                    <div v-if="loading" style="text-align: center">
                                        <h2 clas="pb-4">Cargando Reporte....</h2>
                                        <br /><br />
                                        <md-progress-spinner
                                            :md-diameter="100"
                                            :md-stroke="10"
                                            md-mode="indeterminate"
                                        ></md-progress-spinner>
                                    </div>
                                </div>

                                <v-data-table
                                    v-if="!loading && reportType == 'lead'"
                                    :headers="columns"
                                    :items="report"
                                    item-key="name"
                                    class="elevation-1"
                                    :search="search"
                                    :custom-filter="filterOnlyCapsText"
                                    :loading="loading"
                                    loading-text="Cargando..."
                                >
                                    <template v-slot:top>
                                        <v-text-field
                                            v-model="search"
                                            label="Buscar"
                                            class="mx-4"
                                        ></v-text-field>
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-container>
        </v-main>

        <v-footer fixed style="text-align: center">
            <p style="text-align: center; width: 100%; margin: 10px 0px">
                Segurify {{ new Date().getFullYear() }}
                <a target="_blank" href="/terminos-y-condiciones">Terminos y condiciones</a> y <a target="_blank" href="/aviso-de-privacidad">Aviso de privacidad</a>
            </p>
        </v-footer>
    </v-app>
</template>

<script>
    import Navigation from "../../../components/Nav.vue";
    import { mainAxios } from "../../../mainAxios";
    var moment = require('moment-timezone');

    export default {
        name:'CallbackTime',
        components: {
            Navigation,
        },
        data() {
            return {
                roles: JSON.parse(localStorage.getItem('roles')),
                uuid: localStorage.getItem('agenteUuid'),
                dates: [],
                campanias: [],
                campania: null,
                downloading: false,
                loading: false,
                reportType: 'lead',
                report: [],
                reportDownload: [],
                search: '',
                columns: [
                    {
                        text: 'Uuid/Cotizacion Origen',
                        align: 'start',
                        sortable: false,
                        value: 'uuid',
                    },
                    { text: 'Campaña', value: 'campania' },
                    { text: 'Nombre', value: 'nombre' },
                    { text: 'Apellido Paterno', value: 'apellidoPaterno' },
                    { text: 'Apellido Materno', value: 'apellidoMaterno' },
                    { text: 'Telefono', value: 'telefono' },
                    { text: 'Correo', value: 'correo' },
                    { text: 'Fecha Lead', value: 'fecha' },
                    { text: 'Hora Lead', value: 'hora' },
                    //TOQUE 1
                    { text: 'Disposición toque 1', value: 'disposicionToque' },
                    { text: 'Fecha Toque 1', value: 'fechaToque' },
                    { text: 'Hora Toque 1', value: 'horaToque' },
                    { text: 'CBT', value: 'cbt' },
                ],
            };
        }, async mounted(){
            await this.loadCampania();
        },
        methods: {
            async loadCampania(){
                try{
                    let config = {
                        headers: {
                            Authorization: 'Bearer ' + localStorage.agenteAccessToken,
                        },
                    };
                    let { data } = await mainAxios.get('/v1/campania/list', config);
                    this.campanias = data;
                }catch(exception){
                    console.error(exception);
                }
            },
            convertDate(date) {
                const dateRes = moment(date);
                dateRes.tz('UTC', true);
                return dateRes.tz('America/Mexico_City').format('DD-MM-YYYY HH:mm');
            },
            // Table
            filterOnlyCapsText(value, search) {
                return (
                    value != null &&
                    search != null &&
                    typeof value === 'string' &&
                    value.toString().indexOf(search) !== -1
                );
            },
            searchCampania(val){
                switch(val){
                    case 'LANDING ASEGURADORAS':
                        this.campania = 'landing';
                        break;
                    case 'AIG':
                        this.campania = 'landing';
                        break;
                    case 'ANA':
                        this.campania = 'landing';
                        break;
                    case 'AXA':
                        this.campania = 'landing';
                        break;
                    case 'COMPARADOR':
                        this.campania = 'comparador';
                        break;
                    case 'COMPARADOR-ADT':
                        this.campania = 'adt';
                        break;
                    case 'COMPARADOR-AUTOCOSMOS':
                        this.campania = 'autocosmos';
                        break;
                    case 'GNP':
                        this.campania = 'landing';
                        break;
                    case 'HDI':
                        this.campania = 'landing';
                        break;
                    case 'PROVEEDORES':
                        this.campania = 'auto';
                        break;
                    case 'QUÁLITAS':
                        this.campania = 'landing';
                        break;
                    case 'SGMM SEGURIFY':
                        this.campania = 'sgmm_segurify';
                        break;
                    case 'SURA':
                        this.campania = 'landing';
                        break;
                    case 'SURA MOVILIDAD':
                        this.campania = 'movilidad';
                        break;
                    case 'SURA SGMM':
                        this.campania = 'sura_salud';
                        break;
                    case 'SURA VIDA':
                        this.campania = 'sura_vida';
                        break;
                    case 'AUTOS RASTREATOR AIG':
                        this.campania = 'rastreator';
                        break;
                    case 'CORU.COM':
                        this.campania = 'coru';
                        break;
                    case 'TUSEGURO.COM':
                        this.campania = 'tuseguro';
                        break;
                    case 'SURA PLUS SGMM':
                        this.campania = 'sura_salud_plus';
                        break;
                    default:
                        this.campania = 'landing';
                        break
                }
            },
            async generarReporte() {
                try{
                    this.report = []
                    this.reportDownload = [];
                    this.reportType = 'lead';
                    let inicioUTC = null;
                    let finUTC = null;
                    let disposicionToque, fechaToque, horaToque;

                    let config = {
                        headers: {
                        Authorization: 'Bearer ' + localStorage.agenteAccessToken,
                        },
                    };

                    if(this.dates.length > 0){
                        if(this.dates[1]) {
                            inicioUTC = moment.utc(this.dates[0]).format('YYYY-MM-DD HH:mm:ss')
                            finUTC = moment.utc(this.dates[1]).add(24, 'hours').subtract(1, 'seconds').format('YYYY-MM-DD HH:mm:ss')
                        } else {
                            inicioUTC = moment.utc(this.dates[0]).format('YYYY-MM-DD HH:mm:ss')
                            finUTC = moment.utc(this.dates[0]).add(24, 'hours').subtract(1, 'seconds').format('YYYY-MM-DD HH:mm:ss')
                        }
                    }

                    let fechas = {
                        fechaInicio: inicioUTC,
                        fechaFin: finUTC,
                        campania : this.campania
                    };

                    this.loading = true;
                    let { data } = await mainAxios.post('/v1/reportes/callBack/online', fechas, config);
                    data.map((element)=>{
                        // primeros
                        disposicionToque = element.toques[0].disposicion;
                        fechaToque = moment.utc(element.toques[0].fecha).subtract(6, 'hours').format('DD/MM/YYYY'),
                        horaToque = moment.utc(element.toques[0].fecha).subtract(6, 'hours').format('HH:mm:ss'),

                        this.report.push({
                            uuid: element.uuid,
                            campania: element.campania,
                            nombre: element.nombre,
                            apellidoPaterno: element.apellidoPaterno,
                            apellidoMaterno: element.apellidoMaterno,
                            telefono: element.telefono,
                            correo: element.correo,
                            fecha: element.fecha,
                            hora: element.hora,
                            disposicionToque: disposicionToque ? disposicionToque : '-',
                            fechaToque: fechaToque ? fechaToque : '-',
                            horaToque: horaToque ? horaToque : '-',
                            cbt: element.cbt.split(" ")[1]
                        });
                        
                        this.ReporteExcel(element);
                    });

                    this.loading = false;
                    
                }catch(exception){
                    console.error(exception);
                    this.loading = false;
                }
            },
            ReporteExcel(element){
                let json = {
                    'Uuid/Cotizacion Origen': element.uuid,
                    'Campaña': element.campania,
                    'Nombre': element.nombre,
                    'Apellido Paterno': element.apellidoPaterno,
                    'Apellido Materno': element.apellidoMaterno,
                    'Telefono': element.telefono,
                    'Correo': element.correo,
                    'Fecha Lead': element.fecha,
                    'Hora Lead': element.hora,
                }
                for (let i = 0; i < 1; i++) {
                    json[`Disposicion Toque ${i+1}`] = element.toques[i] != undefined ? element.toques[i].disposicion : '-';
                    json[`Fecha Toque ${i+1}`] = element.toques[i] != undefined ? moment.utc(element.toques[i].fecha).subtract(6, 'hours').format('DD/MM/YYYY') : '-';
                    json[`Hora Toque ${i+1}`] = element.toques[i] != undefined ? moment.utc(element.toques[i].fecha).subtract(6, 'hours').format('HH:mm:ss') : '-';
                }

                json[`CBT`] = element.cbt.split(" ")[1];
                this.reportDownload.push(json);
            }
        },
    };
</script>
