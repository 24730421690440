<template>
  <v-row style="padding: 0px 20px">
    <v-col cols="4">
      <v-select
        label="Plan"
        v-model="plan"
        :items="planList"
        @change="avisarCambio"
      ></v-select>
    </v-col>
    <v-col cols="4">
      <v-select
        label="Fumador"
        v-model="fumador"
        :items="fumadorList"
        @change="avisarCambio"
      ></v-select>
    </v-col>
    <v-col cols="4">
      <v-text-field
        label="Suma Asegurada"
        v-model="sumaAsegurada"
        @change="avisarCambio"
        prefix="$"
        v-number-only
      ></v-text-field>
    </v-col>
    <v-col cols="4">
      <v-select
        label="Muerte Accidental"
        v-model="muerteAccidental"
        :items="muerteAccidentalList"
        @change="avisarCambio"
      ></v-select>
    </v-col>
    <v-col cols="4">
      <v-select
        label="Perdidas Orgánicas"
        v-model="perdidasOrganicas"
        :items="perdidasOrganicasList"
        @change="avisarCambio"
      ></v-select>
    </v-col>
    <v-col cols="4">
      <v-select
        label="Invalidez total y permanente"
        v-model="invalidezTotal"
        :items="invalidezTotalList"
        @change="avisarCambio"
      ></v-select>
    </v-col>
    <v-col cols="4">
      <v-select
        label="Gastos funerarios"
        v-model="gastosFunerarios"
        :items="gastosFunerariosList"
        @change="avisarCambio"
      ></v-select>
    </v-col>
  </v-row>
</template>

<script>
export default {
  directives: {
    'number-only': {
      bind (el) {
        function checkValue (event) {
          event.target.value = event.target.value.replace(/[^0-9]/g, '')
          if (event.charCode >= 48 && event.charCode <= 57) {
            return true
          }
          event.preventDefault()
        }
        el.addEventListener('keypress', checkValue)
      }
    },
    'letter-only': {
      bind (el) {
        function checkValue (event) {
          if (event.charCode >= 48 && event.charCode <= 57) {
            event.preventDefault()
          }
          return true
        }
        el.addEventListener('keypress', checkValue)
      }
    }
  },
  data () {
    return {
      plan: null,
      fumador: null,
      sumaAsegurada: null,
      muerteAccidental: null,
      perdidasOrganicas: null,
      invalidezTotal: null,
      gastosFunerarios: null,

      //listas
      planList: [
        "Temporal",
        "Ordinario",
        "Vida Pagos Limitados(VPL)",
      ],
      fumadorList: [
        "Si",
        "No",
      ],
      muerteAccidentalList: [
        'Si ampara',
        'No ampara',
      ],
      perdidasOrganicasList: [
        'Si ampara',
        'No ampara',
      ],
      invalidezTotalList: [
        'Si ampara',
        'No ampara',
      ],
      gastosFunerariosList: [
        'Si ampara',
        'No ampara',
      ],

      //Validadores
      inputsRequeridos: [
        value => !!value || 'Campo Requerido',
      ],
    }
  },
  props: ['avisar'],
  methods: {
    avisarCambio(){
      let datos = {
        plan: this.plan,
        fumador: this.fumador,
        sumaAsegurada: this.sumaAsegurada,
        muerteAccidental: this.muerteAccidental,
        perdidasOrganicas: this.perdidasOrganicas,
        invalidezTotal: this.invalidezTotal,
        gastosFunerarios: this.gastosFunerarios,
      }
      this.avisar(datos)
    }
  }
}
</script>