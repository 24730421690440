<template>
  <v-card>
    <v-app-bar color="#133B7D" dark elevate-on-scroll class="included">
      <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>
      <v-spacer></v-spacer>

      <!-- NOTIFICATIONS PANEL -->

      <v-menu bottom left>
        <!-- <template v-slot:activator="{ on, attrs }">
          <v-badge
            dot
            
            overlap
            class="mt-2"
          >
            <v-btn 
              icon
              v-bind="attrs"
              v-on="on"
              @click="models.overlay = !models.overlay"
            >
              <v-icon>mdi-bell</v-icon>
            </v-btn>

          </v-badge>
        </template> -->

        <v-list two-line subheader max-width="400" max-height="600">
          <h2 style="padding: 15px">Tus notificaciones</h2>
          <v-list-item-group active-class="blue--text" multiple>
            <h4
              v-if="notificaciones.length === 0"
              style="margin-left: 15px; margin-bottom: 15px"
            >
              Bandeja de notificaciones vacía
            </h4>
            <template v-else v-for="(item, index) in notificaciones">
              <v-list-item
                :key="item.id"
                v-click-outside="{
                  handler: onClickOutsideStandard,
                  include: include,
                }"
                @click="verNotificacion(item)"
              >
                <v-list-item-content>
                  <v-list-item-title
                    v-text="item.creadoPor"
                  ></v-list-item-title>

                  <v-list-item-subtitle
                    class="text--primary"
                    v-text="item.titulo"
                  ></v-list-item-subtitle>

                  <v-list-item-subtitle
                    v-text="item.subtitulo"
                  ></v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                  <v-list-item-action-text
                    v-text="item.action"
                  ></v-list-item-action-text>
                </v-list-item-action>
              </v-list-item>

              <v-divider
                v-if="index < notificaciones.length - 1"
                :key="index"
              ></v-divider>
            </template>
          </v-list-item-group>
          <a style="padding-left: 20px">Marcar todas como leídas</a>

          <a style="margin-left: 60px" @click="verNotificacionesCompletas()"
            >Ver todas</a
          >
          <v-icon color="primary">
            mdi-arrow-right
          </v-icon>
        </v-list>
      </v-menu>
      <!-- END NOTIFICATIONS PANEL -->

      <!-- DETAIL NOTIFICATION -->
      <v-dialog v-model="dialogNotificacion" max-width="500px">
        <v-card>
          <v-card-title v-text="detalleNotificacion.titulo"></v-card-title>
          <v-card-text>
            <p v-html="detalleNotificacion.contenido"></p>
          </v-card-text>
          <v-col style="padding-left: 25px">
            <h4>Creado por: {{ detalleNotificacion.creadoPor }}</h4>
          </v-col>
          <v-card-actions>
            <v-btn color="primary" text @click="dialogNotificacion = false">
              Cerrar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- END DETAIL NOTIFICATION -->

      <v-btn icon class="mt-2">
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      app
      temporary
      :color="'#133B7D'"
      :dark="true"
    >
      <v-list-item class="px-2">
        <v-list-item-title
          >{{ name }}
          <br />
          <span class="rolNota">{{
            rol == "ADMIN"
              ? "Admin"
              : rol == "ADMINAGENT"
              ? "Admin Agente"
              : rol == "MANAGER"
              ? "Manager Agente"
              : rol == "AGENT"
              ? "Agente"
              : rol == "ADMINCC"
              ? "Admin Call Center"
              : rol == "MANAGERCC"
              ? "Manager Call Center"
              : rol == "AGENTCC"
              ? "Agente Call Center"
              : rol == "DIGITAL"
              ? "Digital"
              : rol == "DIRECTOR"
              ? "Director"
              : rol == "DIRECTO"
              ? "Directo"
              : rol == "FINANZAS"
              ? "Finanzas"
              : rol == "MESADECONTROL"
              ? "Mesa de Control"
              : rol == "OPERACIONES"
              ? "Operaciones"
              : rol == "OPERADOR"
              ? "Operador"
              : rol == "AGENTPARTNER"
              ? "Agente Partner"
              : rol == "MESACONTROLINBOUND"
              ? "Mesa de control inbound"
              : rol == "OPERADORINBOUND"
              ? "Operador inbound"
              : rol == "OPERACIONESINBOUND"
              ? "Operaciones inbound"
              : rol == "RENOVACIONESCC"
              ? "Renovaciones Call Center"
              : ""
          }}</span></v-list-item-title
        >

        <v-btn icon @click="drawer = false">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </v-list-item>

      <div>
        <v-divider></v-divider>
        <v-list dense v-for="(list, idx) in listaDesplegable" :key="idx">
          <v-list-group
            v-for="item in list.items"
            :key="item.title"
            v-model="item.active"
            :prepend-icon="item.action"
            no-action
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title v-text="item.title"></v-list-item-title>
              </v-list-item-content>
            </template>

            <template v-for="child in item.items">
              <template v-if="emailCoru != 'leadscoruaig@coru.com'">
                <v-list-item
                  :key="child.title"
                  v-on:click="$router.push(child.route)"
                >
                  <template
                    v-if="child.route != '/administrador/reporte-coru-aig'"
                  >
                    <v-list-item-content
                      v-if="
                        emailCoru != 'leadscoruaig@coru.com' ||
                          child.route != '/administrador/reporte-coru-aig'
                      "
                    >
                      <v-list-item-title
                        v-text="child.title"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <template v-else-if="rol == 'ADMIN' || rol == 'ADMINCC'">
                    <v-list-item-content>
                      <v-list-item-title
                        v-text="child.title"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-list-item>
              </template>
              <template
                v-else-if="child.route == '/administrador/reporte-coru-aig'"
              >
                <v-list-item
                  :key="child.title"
                  v-on:click="$router.push(child.route)"
                >
                  <v-list-item-content>
                    <v-list-item-title v-text="child.title"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </template>
          </v-list-group>
        </v-list>
      </div>

      <v-divider></v-divider>
      <v-list dense style="cursor: pointer">
        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title v-on:click="logout">Salir</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-overlay :value="models.overlay"></v-overlay>
    <CommonCreateInboundCallsModal
      v-if="dialogInboundCalls"
      :dialog="dialogInboundCalls"
      @closeModal="dialogInboundCalls = false"
      :createCliente="true"
    />
    <CommonActionModal
      v-if="dialogEleccion"
      :dialog="dialogEleccion"
      :title="'La llamada paro repentinamente?'"
      :subtitle="'Algun motivo detuvo la llamada?'"
      :bottonText="'Si'"
      @userChoice="saveDispocision"
    />
  </v-card>
</template>

<script>
import { mainAxios } from '../mainAxios';
import { loadScript } from "vue-plugin-load-script";
import CommonCreateInboundCallsModal from "@/components/commonComponents/IndoundsCalls/CommonCreateInboundCallsModal.vue"
export default {
  data() {
    return {
      rol: JSON.parse(localStorage.getItem('roles'))[0].rol,
      emailCoru: localStorage.getItem('agenteMail'),
      name: localStorage.agenteName,
      drawer: false,
      listaDesplegable: null,
      models: {
        overlay: false,
      },
      selected: [0, 1, 2, 3, 4],
      notificaciones: [],
      dialogNotificacion: false,
      detalleNotificacion: {},
      intervalo: null,
      config: {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      },
      leadsLlamada:[],
      dialogInboundCalls:false,
      dialogEleccion:false

    };
  },
  components:{
    CommonCreateInboundCallsModal,
    CommonActionModal: () => import("@/components/commonComponents/CommonActionModal.vue")
  },
  mounted() {
    this.setListaDesplegable()
    //this.obtenerNotificaciones()
    if(this.rol == 'MESACONTROLINBOUND' || this.rol == 'OPERADORINBOUND' || this.rol == 'OPERACIONESINBOUND' || this.rol == 'ADMIN'){
      loadScript("https://aligev55.zw-callitonce.alestra.net.mx/agentdesktop/libs/servicepattern-sdk-v1.js").then(() => {
            window.bpspat.api.init("https://aligev55.zw-callitonce.alestra.net.mx");
        }).catch(() => {
            console.error(
                "Fallo al carga el sdk, Refresque la pagina para realizar la llamada"
            );
            location.reload();
        });
        setTimeout(() => {
          this.checkStatus();
          this.intervalo = window.setInterval(() => {
            this.checkStatus();
          }, 10000);
        }, 30000);
  }
},

  methods: {
    logout() {
      localStorage.clear();
      this.$router.push('/');
    },

    obtenerNotificaciones() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };

      mainAxios.get(`/v1/agente/notificaciones/${localStorage.agenteUuid}`, config).then((response) => {
        for (let index = 0; index < 5; index++) {
          this.notificaciones.push({
            titulo: response.data[index].titulo,
            creadoPor: response.data[index].creadoPor,
            subtitulo: response.data[index].subtitulo,
            fecha: response.data[index].fecha,
            id: response.data[index].id,
            contenido: response.data[index].contenido
          })
        }
      });
    },

    verNotificacion( notificacion ) {
      this.dialogNotificacion = true
      this.detalleNotificacion = notificacion
    },

    verNotificacionesCompletas() {
      this.$router.push(`/notificaciones/${localStorage.agenteUuid}`)
    },

    onClickOutsideStandard () {
      this.models.overlay = false
    },

    include () {
      return [document.querySelector('.included')]
    },

    setListaDesplegable() {
      this.rol == "ADMIN" ?
        this.listaDesplegable = [
          {
            title: 'Cuenta',
            items: [
              {
                action: 'mdi-account-circle',
                active: true,
                title: 'Cuenta',
                items: [
                  {
                    title: 'Inicio',
                    route: '/inicio' },
                  {
                    title: 'Mi Perfil',
                    route: '/usuario/ver-micuenta',
                  },
                  {
                    title: 'Mis Cotizaciones Autos',
                    route: '/mis-cotizaciones',
                    //route: '/proximamente',
                  },
                  {
                    title: 'Mis Cotizaciones Celular',
                    route: '/mis-cotizaciones-celular',
                  },
                  {
                    title: 'Leads Segurify',
                    route: '/leads',
                  },
                  {
                    title: 'Chats Leads',
                    route: '/chats-leads',
                  },
                  {
                    title: 'Chats Clientes',
                    route: '/chats-clientes',
                  },
                  {
                    title: 'Servicio Inbound',
                    route: '/inbound',
                  },
                  {
                    title: 'Home Servicios Inbound',
                    route: '/home/servicios/inbound',
                  },
                  {
                    title: 'Recicle Leads',
                    route: '/leads/recicle-lead',
                  },
                  {
                    title: 'Mis Solicitudes',
                    route: '/solicitudes',
                    //route: '/proximamente',
                  },
                  {
                    title: 'Mis Clientes',
                    route: '/lista-clientes',
                    //route: '/proximamente',
                  },
                  {
                    title: 'Mis Pólizas',
                    route: '/mis-emisiones',
                    //route: '/proximamente',
                  },
                  {
                    title: 'Cuotas de Pólizas',
                    route: '/cuotas-hub',
                    //route: '/proximamente',
                  },
                  {
                    title: 'Mis Comisiones',
                    route: '/lista-comisiones',
                    // route: '/proximamente',
                  },

                  {
                    title: 'Mis Productos',
                    route: '/lista-productos',
                    //route: '/proximamente',
                  },
                ],
              },
              {
                action: 'mdi-account-box-multiple-outline',
                title: 'Administración',
                items: [
                {
                    title: 'Administración de Agentes',
                    route: '/usuarios/agentes',
                    //route: '/proximamente',
                  },
                  {
                    title: 'Administración de Usuarios',
                    route: '/usuarios',
                    //route: '/proximamente',
                  },
                ],
              },
              {
                action: 'mdi-clipboard-text-multiple',
                title: 'Reportes',
                items: [
                  {
                    title: 'SLA de Solicitudes',
                    route: '/sla-solicitudes',
                  },
                  {
                    title: 'Solicitudes con Pólizas',
                    route: '/solicitudes-con-polizas',
                  },
                  {
                    title: 'Solicitudes Pendientes de Aprobación',
                    route: '/solicitudes-pendientes-aprobacion',
                  },
                  {
                    title: 'Cartera de Agentes',
                    route: '/cartera-agentes',
                  },
                  {
                    title: 'Cartera de Clientes',
                    route: '/proximamente',
                  },
                  // {
                  //   title: "Reporte de Pólizas",
                  //   route: "/reporte/emisiones"
                  // },
                  {
                    title: 'Reporte Pólizas Autos Masivos (Anterior)',
                    route: '/callcenter/reporte-emisiones-offline'
                  },
                  {
                    title: 'Reporte Polizas Emitidas (Anterior)',
                    route: '/polizas-emitidas',
                  },
                  {
                    title: 'Pólizas por renovar',
                    route: '/polizas-por-renovar',
                  },
                  {
                    title: 'Pólizas renovadas',
                    route: '/polizas-renovadas',
                  },
                  {
                    title: 'Cuotas de Pólizas Activas',
                    route: '/cobranza-polizas-activas',
                  },
                  // {
                  //   title: 'Polizas con cobranza Vencida',
                  //   route: '/proximamente',
                  // },
                  // {
                  //   title: 'Cuotas Pagadas',
                  //   route: '/cuotas-pagadas',
                  // },
                  {
                    title: 'Cuotas Canceladas',
                    route: '/cuotas-canceladas',
                  },
                  {
                    title: 'Cuotas Vencidas',
                    route: '/cuotas-vencidas',
                  },
                  {
                    title: 'Comisiones pendientes de Pago de la Aseguradora',
                    route: '/proximamente',
                  },
                  {
                    title: 'Comisiones Pendiente de Pago al agente',
                    route: '/proximamente',
                  },
                  {
                    title: 'Comisiones Pagadas al Agente',
                    route: '/proximamente',
                  },
                  // {
                  //   title: 'Leads CC',
                  //   route: '/callCenter/lead',
                  // },
                  // {
                  //   title: 'Reporte Toques Call Center',
                  //   route: '/callCenter/reporteToquesCC',
                  // },
                  // {
                  //   title: 'Reporte Leads-Oportunidades',
                  //   route: '/callCenter/reporteLeads',
                  // },
                  // {
                  //   title: 'AgenteCC',
                  //   route: '/proximamente',
                  // },
                  // {
                  //   title: 'Call Back Time',
                  //   route: '/reporte/call-back-time',
                  // },
                  {
                    title: 'Atracción de talento',
                    route: '/atraccion-talento',
                  },
                ],
              },
              {
                action: 'mdi-clipboard-text-search',
                title: 'Reportes ECI',
                items: [

                  // {
                  //   title: 'Reporte de Leads Autos',
                  //   route: '/administrador/reporte-actualSales',
                  //   //route: '/proximamente',
                  // },
                  // {
                  //   title: 'Reporte de Leads Movilidad',
                  //   route: '/administrador/reporte-sura-movilidad',
                  //   //route: '/proximamente',
                  // },
                  // {
                  //   title: 'Reporte de Leads Rastreator',
                  //   route: '/administrador/reporte-leads-rastreator',
                  //   //route: '/proximamente',
                  // },
                  // {
                  //   title: 'Reporte de Leads Ahorro',
                  //   route: '/administrador/reporte-ahorro',
                  //   //route: '/proximamente',
                  // },
                  // {
                  //   title: 'Reporte de Leads Tractos',
                  //   route: '/administrador/reporte-leads-tractos',
                  //   //route: '/proximamente',
                  // },
                  // {
                  //   title: 'Reporte de Leads SURA AlaSegura',
                  //   route: '/proximamente',
                  //   //route: '/proximamente',
                  // },
                  // {
                  //   title: 'Reporte de Leads SGMM Segurify',
                  //   route: '/administrador/reporte-sgmm',
                  //   //route: '/proximamente',
                  // },
                  // {
                  //   title: 'Reporte Call Back Time',
                  //   route: '/administrador/reporteCallBack',
                  // },
                  {
                    title: 'Reportes leads',
                    route: '/reporte/leads',
                  },
                  {
                    title: 'Reporte Call Back Time',
                    route: '/reporte/call-back-time',
                  },
                  {
                    title: 'Reporte de Marcación de Leads',
                    route: '/reporte/marcacion',
                  },
                  {
                    title: 'Reporte de Cotizaciones Landings',
                    route: '/administrador/reporte-clientes',
                  },
                  {
                    title: 'Reporte de Abandono ECI Landing',
                    route: '/landings/reporteAbandonoLeads',
                  },
                  {
                    title: 'Reporte de Tarificación Comparador',
                    route: '/reporte-tarificacion',
                  },
                  // {
                  //   title: 'Reporte de Marcación',
                  //   route: '/reporte/marcacion',
                  // },
                  {
                    title: 'Reporte de Marcación Simple',
                    route: '/reporte/marcacion-simple',
                  },
                ],
              },
              {
                action: 'mdi-clipboard-text-search-outline',
                title: 'Reporte de Partners',
                items: [
                  {
                    title: 'Reporte de Emisiones tuSeguro.com',
                    route: '/administrador/reporte-tuSeguro-emisiones',
                    //route: '/proximamente',
                  },
                  {
                    title: 'Reporte de Leads tuSeguro.com',
                    route: '/administrador/reporte-tuSeguro-leads',
                    //route: '/proximamente',
                  },
                  // {
                  //   title: 'Reporte Toques Call Center tuSeguro.com',
                  //   //route: '/callCenter/reporteLeadsTuseguro',
                  //   route: '/proximamente',
                  // },
                  {
                    title: 'Reporte Leads-Oportunidades tuSeguro.com',
                    route: '/callCenter/reporteLeadsTuseguro',
                    //route: '/proximamente',
                  },
                  {
                    title: 'Reporte Abandono Leads TuSeguro',
                    route: '/landings/reporteAbandonoLeads/tuSeguro',
                    //route: '/proximamente',
                  },
                  {
                    title: 'Reporte Leads Coru-AIG',
                    route: '/administrador/reporte-coru-aig',
                    //route: '/proximamente',
                  },
                  // {
                  //   title: 'Reporte de Leads Coru',
                  //   //route: '/lista-comisiones',
                  //   route: '/proximamente',
                  // },
                  // {
                  //   title: 'Reporte Toques Call Center Coru',
                  //   //route: '/lista-comisiones',
                  //   route: '/proximamente',
                  // },
                  // {
                  //   title: 'Reporte Leads-Oportunidades Coru',
                  //   //route: '/lista-comisiones',
                  //   route: '/proximamente',
                  // },
                ],
              },
            ],
          }
        ] :
      this.rol == "ADMINAGENT" ?
        this.listaDesplegable = [
          {
            title: 'Cuenta',
            items: [
              {
                action: 'mdi-account-circle',
                active: true,
                title: 'Cuenta',
                items: [
                  {
                    title: 'Inicio',
                    route: '/inicio' },
                  {
                    title: 'Mi Perfil',
                    route: '/usuario/ver-micuenta',
                  },
                  {
                    title: 'Mis Cotizaciones Autos',
                    route: '/mis-cotizaciones',
                  },
                  {
                    title: 'Mis Cotizaciones Celular',
                    route: '/mis-cotizaciones-celular',
                  },
                  {
                    title: 'Leads segurify',
                    route: '/leads',
                  },
                  {
                    title: 'Solicitudes',
                    route: '/solicitudes',
                    //route: '/proximamente',
                  },
                  {
                    title: 'Clientes',
                    route: '/lista-clientes',
                    //route: '/proximamente',
                  },
                  {
                    title: 'Pólizas',
                    route: '/mis-emisiones',
                    //route: '/proximamente',
                  },
                  // {
                  //   title: 'Cuotas de Pólizas',
                  //   route: '/lista-comisiones',
                  //   //route: '/proximamente',
                  // },
                ],
              },
              {
                action: 'mdi-account-box-multiple-outline',
                title: 'Administración',
                items: [
                {
                    title: 'Administración de Agentes',
                    route: '/usuarios/agentes',
                    //route: '/proximamente',
                  },
                ],
              },
              {
                action: 'mdi-clipboard-text-multiple',
                title: 'Reportes',
                items: [
                  {
                    title: 'SLA de Solicitudes',
                    route: '/sla-solicitudes',
                  },
                  {
                    title: 'Solicitudes con Pólizas',
                    route: '/solicitudes-con-polizas',
                  },
                  {
                    title: 'Solicitudes Pendientes de Aprobación',
                    route: '/solicitudes-pendientes-aprobacion',
                  },
                  {
                    title: 'Cartera de Agentes',
                    route: '/cartera-agentes',
                  },
                  {
                    title: 'Cartera de Clientes',
                    route: '/proximamente',
                  },
                  // {
                  //   title: "Reporte de Pólizas",
                  //   route: "/reporte/emisiones"
                  // },
                  {
                    title: 'Reporte Pólizas Autos Masivos (Anterior)',
                    route: '/callcenter/reporte-emisiones-offline'
                  },
                  {
                    title: 'Reporte Polizas Emitidas (Anterior)',
                    route: '/polizas-emitidas',
                  },
                  {
                    title: 'Pólizas por renovar',
                    route: '/polizas-por-renovar',
                  },
                  {
                    title: 'Pólizas renovadas',
                    route: '/polizas-renovadas',
                  },
                  {
                    title: 'Cuotas de Pólizas Activas',
                    route: '/cobranza-polizas-activas',
                  },
                  // {
                  //   title: 'Polizas con cobranza Vencida',
                  //   route: '/proximamente',
                  // },
                  // {
                  //   title: 'Cuotas Pagadas',
                  //   route: '/cuotas-pagadas',
                  // },
                  {
                    title: 'Cuotas Canceladas',
                    route: '/cuotas-canceladas',
                  },
                  {
                    title: 'Cuotas Vencidas',
                    route: '/cuotas-vencidas',
                  },
                  {
                    title: 'Comisiones pendientes de Pago de la Aseguradora',
                    route: '/proximamente',
                  },
                  {
                    title: 'Comisiones Pendiente de Pago al agente',
                    route: '/proximamente',
                  },
                  {
                    title: 'Comisiones Pagadas al Agente',
                    route: '/proximamente',
                  },
                  // {
                  //   title: 'Leads CC',
                  //   route: '/callCenter/lead',
                  // },
                  // {
                  //   title: 'Reporte Toques Call Center',
                  //   route: '/callCenter/reporteToquesCC',
                  // },
                  // {
                  //   title: 'Reporte Leads-Oportunidades',
                  //   route: '/callCenter/reporteLeads',
                  // },
                  // {
                  //   title: 'AgenteCC',
                  //   route: '/proximamente',
                  // },
                  // {
                  //   title: 'Call Back Time',
                  //   route: '/reporte/call-back-time',
                  // },
                  {
                    title: 'Atracción de talento',
                    route: '/atraccion-talento',
                  },
                ],
              },
            ],
          }
        ] :
      this.rol == "MANAGER" ?
        this.listaDesplegable = [
          {
            title: 'Cuenta',
            items: [
              {
                action: 'mdi-account-circle',
                active: true,
                title: 'Cuenta',
                items: [
                  {
                    title: 'Inicio',
                    route: '/inicio' },
                  {
                    title: 'Mi Perfil',
                    route: '/usuario/ver-micuenta',
                  },
                  {
                    title: 'Mis Cotizaciones Autos',
                    route: '/mis-cotizaciones',
                  },
                  {
                    title: 'Mis Cotizaciones Celular',
                    route: '/mis-cotizaciones-celular',
                  },
                  {
                    title: 'Leads segurify',
                    route: '/leads',
                  },
                  {
                    title: 'Solicitudes',
                    route: '/solicitudes',
                    //route: '/proximamente',
                  },
                  {
                    title: 'Clientes',
                    route: '/lista-clientes',
                    //route: '/proximamente',
                  },
                  {
                    title: 'Pólizas',
                    route: '/mis-emisiones',
                    //route: '/proximamente',
                  },
                  // {
                  //   title: 'Cuotas de Pólizas',
                  //   route: '/lista-comisiones',
                  //   //route: '/proximamente',
                  // },
                ],
              },
              {
                action: 'mdi-account-box-multiple-outline',
                title: 'Administración',
                items: [
                {
                    title: 'Administración de Agentes',
                    route: '/usuarios/agentes',
                    //route: '/proximamente',
                  },
                ],
              },
              {
                action: 'mdi-clipboard-text-multiple',
                title: 'Reportes',
                items: [
                  {
                    title: 'SLA de Solicitudes',
                    route: '/sla-solicitudes',
                  },
                  {
                    title: 'Solicitudes con Pólizas',
                    route: '/solicitudes-con-polizas',
                  },
                  {
                    title: 'Solicitudes Pendientes de Aprobación',
                    route: '/solicitudes-pendientes-aprobacion',
                  },
                  {
                    title: 'Cartera de Agentes',
                    route: '/cartera-agentes',
                  },
                  {
                    title: 'Cartera de Clientes',
                    route: '/proximamente',
                  },
                  // {
                  //   title: "Reporte de Pólizas",
                  //   route: "/reporte/emisiones"
                  // },
                  {
                    title: 'Reporte Pólizas Autos Masivos (Anterior)',
                    route: '/callcenter/reporte-emisiones-offline'
                  },
                  {
                    title: 'Reporte Polizas Emitidas (Anterior)',
                    route: '/polizas-emitidas',
                  },
                  {
                    title: 'Pólizas por renovar',
                    route: '/polizas-por-renovar',
                  },
                  {
                    title: 'Pólizas renovadas',
                    route: '/polizas-renovadas',
                  },
                  {
                    title: 'Cuotas de Pólizas Activas',
                    route: '/cobranza-polizas-activas',
                  },
                  // {
                  //   title: 'Polizas con cobranza Vencida',
                  //   route: '/proximamente',
                  // },
                  // {
                  //   title: 'Cuotas Pagadas',
                  //   route: '/cuotas-pagadas',
                  // },
                  {
                    title: 'Cuotas Canceladas',
                    route: '/cuotas-canceladas',
                  },
                  {
                    title: 'Cuotas Vencidas',
                    route: '/cuotas-vencidas',
                  },
                  {
                    title: 'Comisiones pendientes de Pago de la Aseguradora',
                    route: '/proximamente',
                  },
                  {
                    title: 'Comisiones Pendiente de Pago al agente',
                    route: '/proximamente',
                  },
                  {
                    title: 'Comisiones Pagadas al Agente',
                    route: '/proximamente',
                  },
                  // {
                  //   title: 'Leads CC',
                  //   route: '/callCenter/lead',
                  // },
                  // {
                  //   title: 'Reporte Toques Call Center',
                  //   route: '/callCenter/reporteToquesCC',
                  // },
                  // {
                  //   title: 'Reporte Leads-Oportunidades',
                  //   route: '/callCenter/reporteLeads',
                  // },
                  // {
                  //   title: 'AgenteCC',
                  //   route: '/proximamente',
                  // },
                  // {
                  //   title: 'Call Back Time',
                  //   route: '/reporte/call-back-time',
                  // },
                  {
                    title: 'Atracción de talento',
                    route: '/atraccion-talento',
                  },
                ],
              },
            ],
          }
        ] :
      this.rol == "AGENT" ?
        this.listaDesplegable = [
          {
            title: 'Cuenta',
            items: [
              {
                action: 'mdi-account-circle',
                active: true,
                title: 'Cuenta',
                items: [
                  {
                    title: 'Inicio',
                    route: '/inicio' },
                  {
                    title: 'Mi Perfil',
                    route: '/usuario/ver-micuenta',
                  },
                  {
                    title: 'Mis Cotizaciones Autos',
                    route: '/mis-cotizaciones',
                    //route: '/proximamente',
                  },
                  {
                    title: 'Mis Cotizaciones Celular',
                    route: '/mis-cotizaciones-celular',
                  },
                  // {
                  //   title: 'Leads segurify',
                  //   route: '/leads',
                  // },
                  {
                    title: 'Mis Solicitudes',
                    route: '/solicitudes',
                    //route: '/proximamente',
                  },
                  {
                    title: 'Mis Clientes',
                    route: '/lista-clientes',
                    //route: '/proximamente',
                  },
                  // {
                  //   title: 'Mis Productos',
                  //   route: '/lista-productos',
                  //   //route: '/proximamente',
                  // },
                  {
                    title: 'Mis Pólizas',
                    route: '/mis-emisiones',
                    //route: '/proximamente',
                  },
                  // {
                  //   title: 'Cuotas de Pólizas',
                  //   route: '/cuotas-hub',
                  //   //route: '/proximamente',
                  // },
                  // {
                  //   title: 'Mis Comisiones',
                  //   route: '/lista-comisiones',
                  //   // route: '/proximamente',
                  // },
                ],
              },
            ],
          }
        ] :
      this.rol == "ADMINCC" ?
        this.listaDesplegable = [
          {
            title: 'Cuenta',
            items: [
              {
                action: 'mdi-account-circle',
                active: true,
                items: [
                  {
                    title: 'Inicio',
                    route: '/inicio' },
                  {
                    title: 'Mi Perfil',
                    route: '/usuario/ver-micuenta',
                  },
                  {
                    title: 'Mis Cotizaciones Autos',
                    route: '/mis-cotizaciones',
                    //route: '/proximamente',
                  },
                  {
                    title: 'Mis Cotizaciones Celular',
                    route: '/mis-cotizaciones-celular',
                  },
                  {
                    title: 'Leads Segurify',
                    route: '/leads',
                  },
                  {
                    title: 'Servicio Inbound',
                    route: '/inbound',
                  },
                  {
                    title: 'Recicle Leads',
                    route: '/leads/recicle-lead',
                  },
                  // {
                  //   title: 'Mis Solicitudes',
                  //   route: '/solicitudes',
                  //   //route: '/proximamente',
                  // },
                  {
                    title: 'Mis Clientes',
                    route: '/lista-clientes',
                    //route: '/proximamente',
                  },
                  {
                    title: 'Mis Pólizas',
                    route: '/mis-emisiones',
                    //route: '/proximamente',
                  },
                  // {
                  //   title: 'Cuotas de Pólizas',
                  //   route: '/cuotas-hub',
                  //   //route: '/proximamente',
                  // },
                ],
                title: 'Cuenta',
              },
              {
                action: 'mdi-account-box-multiple-outline',
                title: 'Administración',
                items: [
                  {
                    title: 'Administración de Usuarios',
                    route: '/usuarios',
                    //route: '/proximamente',
                  },
                ],
              },
              {
                action: 'mdi-clipboard-text-multiple',
                title: 'Reportes',
                items: [
                  // {
                  //   title: "Reporte de Pólizas",
                  //   route: "/reporte/emisiones"
                  // },
                  // {
                  //   title: 'Leads CC',
                  //   route: '/callCenter/lead',
                  // },
                  // {
                  //   title: 'Reporte Toques Call Center',
                  //   route: '/callCenter/reporteToquesCC',
                  // },
                  // {
                  //   title: 'Reporte Leads-Oportunidades',
                  //   route: '/callCenter/reporteLeads',
                  // },
                  {
                    title: 'AgenteCC',
                    route: '/proximamente',
                  },
                  // {
                  //   title: 'Reporte Pólizas Autos Masivos',
                  //   route: '/callcenter/reporte-emisiones-offline'
                  // },
                  {
                    title: 'Pólizas por renovar',
                    route: '/polizas-por-renovar',
                  },
                  {
                    title: 'Pólizas renovadas',
                    route: '/polizas-renovadas',
                  },
                  {
                    title: 'Cuotas de Pólizas Activas',
                    route: '/cobranza-polizas-activas',
                  },
                  {
                    title: 'Reporte Pólizas Autos Masivos',
                    route: '/callcenter/reporte-emisiones-offline'
                  },
                  // {
                  //   title: 'Reporte Polizas Emitidas',
                  //   route: '/polizas-emitidas',
                  // },
                  // {
                  //   title: 'Polizas con cobranza Vencida',
                  //   route: '/proximamente',
                  // },
                  {
                    title: 'Cartera de Clientes',
                    route: '/proximamente',
                  },
                  {
                    title: 'Reportes leads',
                    route: '/reporte/leads',
                  },
                  {
                    title: 'Call Back Time',
                    route: '/reporte/call-back-time',
                  },
                ],
              },
              {
                action: 'mdi-clipboard-text-search',
                title: 'Reportes ECI',
                items: [

                  // {
                  //   title: 'Reporte de Leads Autos',
                  //   route: '/administrador/reporte-actualSales',
                  //   //route: '/proximamente',
                  // },
                  // {
                  //   title: 'Reporte de Leads Movilidad',
                  //   route: '/administrador/reporte-sura-movilidad',
                  //   //route: '/proximamente',
                  // },
                  // {
                  //   title: 'Reporte de Leads Rastreator',
                  //   route: '/administrador/reporte-leads-rastreator',
                  //   //route: '/proximamente',
                  // },
                  // {
                  //   title: 'Reporte de Leads Ahorro',
                  //   route: '/administrador/reporte-ahorro',
                  //   //route: '/proximamente',
                  // },
                  // {
                  //   title: 'Reporte de Leads Tractos',
                  //   route: '/administrador/reporte-leads-tractos',
                  //   //route: '/proximamente',
                  // },
                  // {
                  //   title: 'Reporte de Leads SURA AlaSegura',
                  //   route: '/proximamente',
                  //   //route: '/proximamente',
                  // },
                  // {
                  //   title: 'Reporte de Leads SGMM Segurify',
                  //   route: '/administrador/reporte-sgmm',
                  //   //route: '/proximamente',
                  // },
                  // {
                  //   title: 'Reporte Call Back Time',
                  //   route: '/administrador/reporteCallBack',
                  // },
                  {
                    title: 'Reportes leads',
                    route: '/reporte/leads',
                  },
                  {
                    title: 'Reporte Call Back Time',
                    route: '/reporte/call-back-time',
                  },
                  {
                    title: 'Reporte de Marcación de Leads',
                    route: '/reporte/marcacion',
                  },
                  {
                    title: 'Reporte de Cotizaciones Landings',
                    route: '/administrador/reporte-clientes',
                  },
                  {
                    title: 'Reporte de Abandono ECI Landing',
                    route: '/landings/reporteAbandonoLeads',
                  },
                  {
                    title: 'Reporte de Tarificación Comparador',
                    route: '/reporte-tarificacion',
                  },
                  // {
                  //   title: 'Reporte de Marcación',
                  //   route: '/reporte/marcacion',
                  // },
                  {
                    title: 'Reporte de Marcación Simple',
                    route: '/reporte/marcacion-simple',
                  },
                ],
              },
              {
                action: 'mdi-clipboard-text-search-outline',
                title: 'Reporte de Partners',
                items: [
                  {
                    title: 'Reporte de Emisiones tuSeguro.com',
                    route: '/administrador/reporte-tuSeguro-emisiones',
                    //route: '/proximamente',
                  },
                  {
                    title: 'Reporte de Leads tuSeguro.com',
                    route: '/administrador/reporte-tuSeguro-leads',
                    //route: '/proximamente',
                  },
                  // {
                  //   title: 'Reporte Toques Call Center tuSeguro.com',
                  //   //route: '/callCenter/reporteLeadsTuseguro',
                  //   route: '/proximamente',
                  // },
                  {
                    title: 'Reporte Leads-Oportunidades tuSeguro.com',
                    route: '/callCenter/reporteLeadsTuseguro',
                    //route: '/proximamente',
                  },
                  {
                    title: 'Reporte Abandono Leads TuSeguro',
                    route: '/landings/reporteAbandonoLeads/tuSeguro',
                    //route: '/proximamente',
                  },
                  {
                    title: 'Reporte Leads Coru-AIG',
                    route: '/administrador/reporte-coru-aig',
                    //route: '/proximamente',
                  },
                  // {
                  //   title: 'Reporte de Leads Coru',
                  //   //route: '/lista-comisiones',
                  //   route: '/proximamente',
                  // },
                  // {
                  //   title: 'Reporte Toques Call Center Coru',
                  //   //route: '/lista-comisiones',
                  //   route: '/proximamente',
                  // },
                  // {
                  //   title: 'Reporte Leads-Oportunidades Coru',
                  //   //route: '/lista-comisiones',
                  //   route: '/proximamente',
                  // },
                ],
              },
            ],
          }
        ] :
      this.rol == "MANAGERCC" ?
        this.listaDesplegable = [
          {
            title: 'Cuenta',
            items: [
              {
                action: 'mdi-account-circle',
                active: true,
                items: [
                  {
                    title: 'Inicio',
                    route: '/inicio' },
                  {
                    title: 'Mi Perfil',
                    route: '/usuario/ver-micuenta',
                  },
                  {
                    title: 'Mis Cotizaciones Autos',
                    route: '/mis-cotizaciones',
                    //route: '/proximamente',
                  },
                  {
                    title: 'Mis Cotizaciones Celular',
                    route: '/mis-cotizaciones-celular',
                  },
                  {
                    title: 'Leads Segurify',
                    route: '/leads',
                  },
                  {
                    title: 'Servicio Inbound',
                    route: '/inbound',
                  },
                  {
                    title: 'Recicle Leads',
                    route: '/leads/recicle-lead',
                  },
                  // {
                  //   title: 'Mis Solicitudes',
                  //   route: '/solicitudes',
                  //   //route: '/proximamente',
                  // },
                  {
                    title: 'Mis Clientes',
                    route: '/lista-clientes',
                    //route: '/proximamente',
                  },
                  {
                    title: 'Mis Pólizas',
                    route: '/mis-emisiones',
                    //route: '/proximamente',
                  },
                  // {
                  //   title: 'Cuotas de Pólizas',
                  //   route: '/cuotas-hub',
                  //   //route: '/proximamente',
                  // },
                ],
                title: 'Cuenta',
              },
              {
                action: 'mdi-account-box-multiple-outline',
                title: 'Administración',
                items: [
                  {
                    title: 'Administración de Usuarios',
                    route: '/usuarios',
                    //route: '/proximamente',
                  },
                ],
              },
              {
                action: 'mdi-clipboard-text-multiple',
                title: 'Reportes',
                items: [
                  //  {
                  //    title: "Reporte de Pólizas",
                  //    route: "/reporte/emisiones"
                  //  },
                  // {
                  //   title: 'Leads CC',
                  //   route: '/callCenter/lead',
                  // },
                  // {
                  //   title: 'Reporte Toques Call Center',
                  //   route: '/callCenter/reporteToquesCC',
                  // },
                  // {
                  //   title: 'Reporte Leads-Oportunidades',
                  //   route: '/callCenter/reporteLeads',
                  // },
                  {
                    title: 'AgenteCC',
                    route: '/proximamente',
                  },
                  // {
                  //   title: 'Reporte Pólizas Autos Masivos',
                  //   route: '/callcenter/reporte-emisiones-offline'
                  // },
                  // {
                  //    title: 'Reporte Polizas Emitidas',
                  //    route: '/polizas-emitidas',
                  // },
                  {
                    title: 'Reporte Pólizas Autos Masivos',
                    route: '/callcenter/reporte-emisiones-offline'
                  },
                  {
                    title: 'Pólizas por renovar',
                    route: '/polizas-por-renovar',
                  },
                  {
                    title: 'Pólizas renovadas',
                    route: '/polizas-renovadas',
                  },
                  {
                    title: 'Cuotas de Pólizas Activas',
                    route: '/cobranza-polizas-activas',
                  },
                  // {
                  //   title: 'Polizas con cobranza Vencida',
                  //   route: '/proximamente',
                  // },
                  {
                    title: 'Cartera de Clientes',
                    route: '/proximamente',
                  },
                  {
                    title: 'Leads',
                    route: '/reporte/leads',
                  },
                  {
                    title: 'Call Back Time',
                    route: '/reporte/call-back-time',
                  },

                ],
              },
              {
                action: 'mdi-clipboard-text-search',
                title: 'Reportes ECI',
                items: [

                  // {
                  //   title: 'Reporte de Leads Autos',
                  //   route: '/administrador/reporte-actualSales',
                  //   //route: '/proximamente',
                  // },
                  // {
                  //   title: 'Reporte de Leads Movilidad',
                  //   route: '/administrador/reporte-sura-movilidad',
                  //   //route: '/proximamente',
                  // },
                  // {
                  //   title: 'Reporte de Leads Rastreator',
                  //   route: '/administrador/reporte-leads-rastreator',
                  //   //route: '/proximamente',
                  // },
                  // {
                  //   title: 'Reporte de Leads Ahorro',
                  //   route: '/administrador/reporte-ahorro',
                  //   //route: '/proximamente',
                  // },
                  // {
                  //   title: 'Reporte de Leads Tractos',
                  //   route: '/administrador/reporte-leads-tractos',
                  //   //route: '/proximamente',
                  // },
                  // {
                  //   title: 'Reporte de Leads SURA AlaSegura',
                  //   route: '/proximamente',
                  //   //route: '/proximamente',
                  // },
                  // {
                  //   title: 'Reporte de Leads SGMM Segurify',
                  //   route: '/administrador/reporte-sgmm',
                  //   //route: '/proximamente',
                  // },
                  // {
                  //   title: 'Reporte Call Back Time',
                  //   route: '/administrador/reporteCallBack',
                  // },
                  {
                    title: 'Reportes leads',
                    route: '/reporte/leads',
                  },
                  {
                    title: 'Reporte Call Back Time',
                    route: '/reporte/call-back-time',
                  },
                  {
                    title: 'Reporte de Marcación de Leads',
                    route: '/reporte/marcacion',
                  },
                  {
                    title: 'Reporte de Cotizaciones Landings',
                    route: '/administrador/reporte-clientes',
                  },
                  {
                    title: 'Reporte de Abandono ECI Landing',
                    route: '/landings/reporteAbandonoLeads',
                  },
                  {
                    title: 'Reporte de Tarificación Comparador',
                    route: '/reporte-tarificacion',
                  },
                  // {
                  //   title: 'Reporte de Marcación',
                  //   route: '/reporte/marcacion',
                  // },
                  {
                    title: 'Reporte de Marcación Simple',
                    route: '/reporte/marcacion-simple',
                  },
                ],
              },
              {
                action: 'mdi-clipboard-text-search-outline',
                title: 'Reporte de Partners',
                items: [
                  {
                    title: 'Reporte de Emisiones tuSeguro.com',
                    route: '/administrador/reporte-tuSeguro-emisiones',
                    //route: '/proximamente',
                  },
                  {
                    title: 'Reporte de Leads tuSeguro.com',
                    route: '/administrador/reporte-tuSeguro-leads',
                    //route: '/proximamente',
                  },
                  {
                    title: 'Reporte Toques Call Center tuSeguro.com',
                    route: '/callCenter/reporteLeadsTuseguro',
                    // route: '/proximamente',
                  },
                  {
                    title: 'Reporte Leads-Oportunidades tuSeguro.com',
                    route: '/callCenter/reporteLeadsTuseguro',
                    //route: '/proximamente',
                  },
                  {
                    title: 'Reporte de Leads Coru',
                    route: '/lista-comisiones',
                    // route: '/proximamente',
                  },
                  {
                    title: 'Reporte Toques Call Center Coru',
                    route: '/lista-comisiones',
                    // route: '/proximamente',
                  },
                  {
                    title: 'Reporte Leads-Oportunidades Coru',
                    route: '/lista-comisiones',
                    // route: '/proximamente',
                  },
                ],
              },
            ],
          }
        ] :
      this.rol == "AGENTCC" ?
        this.listaDesplegable = [
          {
            title: 'Cuenta',
            items: [
              {
                action: 'mdi-account-circle',
                active: true,
                title: 'Cuenta',
                items: [
                  {
                    title: 'Inicio',
                    route: '/inicio' },
                  {
                    title: 'Mi Perfil',
                    route: '/usuario/ver-micuenta',
                  },
                  {
                    title: 'Mis Cotizaciones Autos',
                    route: '/mis-cotizaciones',
                    //route: '/proximamente',
                  },
                  {
                    title: 'Leads segurify',
                    route: '/leads',
                  },
                  {
                    title: 'Mis Cotizaciones Celular',
                    route: '/mis-cotizaciones-celular',
                  },
                  // {
                  //   title: 'Mis Solicitudes',
                  //   route: '/solicitudes',
                  //   //route: '/proximamente',
                  // },
                  {
                    title: 'Mis Clientes',
                    route: '/lista-clientes',
                    //route: '/proximamente',
                  },
                  {
                    title: 'Mis Pólizas',
                    route: '/mis-emisiones',
                    //route: '/proximamente',
                  },
                  // {
                  //   title: 'Cuotas de Pólizas',
                  //   route: '/cuotas-hub',
                  //   //route: '/proximamente',
                  // },
                ],
              },
              {
                action: 'mdi-clipboard-text-multiple',
                title: 'Call Center',
                items: [
                  // {
                  //   title: 'Leads CC',
                  //   route: '/callCenter/lead',
                  // },
                  // {
                  //   title: 'Reporte Polizas Emitidas',
                  //   route: '/polizas-emitidas',
                  // },
                  // {
                  //   title: 'Pólizas por renovar',
                  //   route: '/polizas-por-renovar',
                  // },
                  // {
                  //   title: 'Pólizas renovadas',
                  //   route: '/polizas-renovadas',
                  // },
                  // {
                  //   title: 'Cuotas de Pólizas Activas',
                  //   route: '/cobranza-polizas-activas',
                  // },
                  // {
                  //   title: 'Polizas con cobranza Vencida',
                  //   route: '/proximamente',
                  // },
                ],
              },
            ],
          }
        ] :
      this.rol == "DIGITAL" ?
        this.listaDesplegable = [
          {
            title: 'Cuenta',
            items: [
              {
                action: 'mdi-account-circle',
                active: true,
                title: 'Cuenta',
                items: [
                  {
                    title: 'Inicio',
                    route: '/inicio' },
                  {
                    title: 'Mi Perfil',
                    route: '/usuario/ver-micuenta',
                  },
                  {
                    title: 'Mis Cotizaciones Autos',
                    route: '/mis-cotizaciones',
                  },
                  {
                    title: 'Mis Cotizaciones Celular',
                    route: '/mis-cotizaciones-celular',
                  },
                  {
                    title: 'Leads segurify',
                    route: '/leads',
                  },
                ],
              },
              {
                action: 'mdi-clipboard-text-multiple',
                title: 'Reportes',
                items: [
                  {
                    title: 'SLA de Solicitudes',
                    route: '/sla-solicitudes',
                  },
                  {
                    title: 'Solicitudes con Pólizas',
                    route: '/solicitudes-con-polizas',
                  },
                  {
                    title: 'Solicitudes Pendientes de Aprobación',
                    route: '/solicitudes-pendientes-aprobacion',
                  },
                  {
                    title: 'Cartera de Agentes',
                    route: '/cartera-agentes',
                  },
                  {
                    title: 'Cartera de Clientes',
                    route: '/proximamente',
                  },
                  // {
                  //   title: "Reporte de Pólizas",
                  //   route: "/reporte/emisiones"
                  // },
                  {
                    title: 'Reporte Pólizas Autos Masivos (Anterior)',
                    route: '/callcenter/reporte-emisiones-offline'
                  },
                  {
                    title: 'Reporte Polizas Emitidas (Anterior)',
                    route: '/polizas-emitidas',
                  },
                  {
                    title: 'Pólizas por renovar',
                    route: '/polizas-por-renovar',
                  },
                  {
                    title: 'Pólizas renovadas',
                    route: '/polizas-renovadas',
                  },
                  {
                    title: 'Cuotas de Pólizas Activas',
                    route: '/cobranza-polizas-activas',
                  },
                  // {
                  //   title: 'Polizas con cobranza Vencida',
                  //   route: '/proximamente',
                  // },
                  // {
                  //   title: 'Cuotas Pagadas',
                  //   route: '/cuotas-pagadas',
                  // },
                  {
                    title: 'Cuotas Canceladas',
                    route: '/cuotas-canceladas',
                  },
                  {
                    title: 'Cuotas Vencidas',
                    route: '/cuotas-vencidas',
                  },
                  {
                    title: 'Comisiones pendientes de Pago de la Aseguradora',
                    route: '/proximamente',
                  },
                  {
                    title: 'Comisiones Pendiente de Pago al agente',
                    route: '/proximamente',
                  },
                  {
                    title: 'Comisiones Pagadas al Agente',
                    route: '/proximamente',
                  },
                  // {
                  //   title: 'Leads CC',
                  //   route: '/callCenter/lead',
                  // },
                  // {
                  //   title: 'Reporte Toques Call Center',
                  //   route: '/callCenter/reporteToquesCC',
                  // },
                  // {
                  //   title: 'Reporte Leads-Oportunidades',
                  //   route: '/callCenter/reporteLeads',
                  // },
                  // {
                  //   title: 'AgenteCC',
                  //   route: '/proximamente',
                  // },
                  // {
                  //   title: 'Call Back Time',
                  //   route: '/reporte/call-back-time',
                  // },
                  {
                    title: 'Atracción de talento',
                    route: '/atraccion-talento',
                  },
                ],
              },
              {
                action: 'mdi-clipboard-text-search',
                title: 'Reportes ECI',
                items: [

                  // {
                  //   title: 'Reporte de Leads Autos',
                  //   route: '/administrador/reporte-actualSales',
                  //   //route: '/proximamente',
                  // },
                  // {
                  //   title: 'Reporte de Leads Movilidad',
                  //   route: '/administrador/reporte-sura-movilidad',
                  //   //route: '/proximamente',
                  // },
                  // {
                  //   title: 'Reporte de Leads Rastreator',
                  //   route: '/administrador/reporte-leads-rastreator',
                  //   //route: '/proximamente',
                  // },
                  // {
                  //   title: 'Reporte de Leads Ahorro',
                  //   route: '/administrador/reporte-ahorro',
                  //   //route: '/proximamente',
                  // },
                  // {
                  //   title: 'Reporte de Leads Tractos',
                  //   route: '/administrador/reporte-leads-tractos',
                  //   //route: '/proximamente',
                  // },
                  // {
                  //   title: 'Reporte de Leads SURA AlaSegura',
                  //   route: '/proximamente',
                  //   //route: '/proximamente',
                  // },
                  // {
                  //   title: 'Reporte de Leads SGMM Segurify',
                  //   route: '/administrador/reporte-sgmm',
                  //   //route: '/proximamente',
                  // },
                  // {
                  //   title: 'Reporte Call Back Time',
                  //   route: '/administrador/reporteCallBack',
                  // },
                  {
                    title: 'Reportes leads',
                    route: '/reporte/leads',
                  },
                  {
                    title: 'Reporte Call Back Time',
                    route: '/reporte/call-back-time',
                  },
                  {
                    title: 'Reporte de Marcación de Leads',
                    route: '/reporte/marcacion',
                  },
                  {
                    title: 'Reporte de Cotizaciones Landings',
                    route: '/administrador/reporte-clientes',
                  },
                  {
                    title: 'Reporte de Abandono ECI Landing',
                    route: '/landings/reporteAbandonoLeads',
                  },
                  {
                    title: 'Reporte de Tarificación Comparador',
                    route: '/reporte-tarificacion',
                  },
                  {
                    title: 'Reporte de Marcación Simple',
                    route: '/reporte/marcacion-simple',
                  },
                ],
              },
              {
                action: 'mdi-clipboard-text-search-outline',
                title: 'Reporte de Partners',
                items: [
                  {
                    title: 'Reporte de Emisiones tuSeguro.com',
                    route: '/administrador/reporte-tuSeguro-emisiones',
                    //route: '/proximamente',
                  },
                  {
                    title: 'Reporte de Leads tuSeguro.com',
                    route: '/administrador/reporte-tuSeguro-leads',
                    //route: '/proximamente',
                  },
                  // {
                  //   title: 'Reporte Toques Call Center tuSeguro.com',
                  //   //route: '/callCenter/reporteLeadsTuseguro',
                  //   route: '/proximamente',
                  // },
                  {
                    title: 'Reporte Leads-Oportunidades tuSeguro.com',
                    route: '/callCenter/reporteLeadsTuseguro',
                    //route: '/proximamente',
                  },
                  {
                    title: 'Reporte Abandono Leads TuSeguro',
                    route: '/landings/reporteAbandonoLeads/tuSeguro',
                    //route: '/proximamente',
                  },
                  // {
                  //   title: 'Reporte de Leads Coru',
                  //   //route: '/lista-comisiones',
                  //   route: '/proximamente',
                  // },
                  // {
                  //   title: 'Reporte Toques Call Center Coru',
                  //   //route: '/lista-comisiones',
                  //   route: '/proximamente',
                  // },
                  // {
                  //   title: 'Reporte Leads-Oportunidades Coru',
                  //   //route: '/lista-comisiones',
                  //   route: '/proximamente',
                  // },
                ],
              },
            ],
          }
        ] :
      this.rol == "DIRECTOR" ?
        this.listaDesplegable = [
          {
            title: 'Cuenta',
            items: [
              {
                action: 'mdi-account-circle',
                active: true,
                title: 'Cuenta',
                items: [
                  {
                    title: 'Inicio',
                    route: '/inicio' },
                  {
                    title: 'Mi Perfil',
                    route: '/usuario/ver-micuenta',
                  },
                  {
                    title: 'Mis Cotizaciones Autos',
                    route: '/mis-cotizaciones',
                  },
                  {
                    title: 'Mis Cotizaciones Celular',
                    route: '/mis-cotizaciones-celular',
                  },
                  {
                    title: 'Leads segurify',
                    route: '/leads',
                  },
                  {
                    title: 'Solicitudes',
                    route: '/solicitudes',
                    //route: '/proximamente',
                  },
                  {
                    title: 'Clientes',
                    route: '/lista-clientes',
                    //route: '/proximamente',
                  },
                  {
                    title: 'Pólizas',
                    route: '/mis-emisiones',
                    //route: '/proximamente',
                  },
                  // {
                  //   title: 'Cuotas de Pólizas',
                  //   route: '/cuotas-hub',
                  //   //route: '/proximamente',
                  // },
                  {
                    title: 'Leads',
                    route: '/reporte/leads',
                  },
                ],
              },
              {
                action: 'mdi-clipboard-text-multiple',
                title: 'Reportes',
                items: [
                  {
                    title: 'SLA de Solicitudes',
                    route: '/sla-solicitudes',
                  },
                  {
                    title: 'Solicitudes con Pólizas',
                    route: '/solicitudes-con-polizas',
                  },
                  {
                    title: 'Solicitudes Pendientes de Aprobación',
                    route: '/solicitudes-pendientes-aprobacion',
                  },
                  {
                    title: 'Cartera de Agentes',
                    route: '/cartera-agentes',
                  },
                  {
                    title: 'Cartera de Clientes',
                    route: '/proximamente',
                  },
                  // {
                  //   title: "Reporte de Pólizas",
                  //   route: "/reporte/emisiones"
                  // },
                  {
                    title: 'Reporte Pólizas Autos Masivos (Anterior)',
                    route: '/callcenter/reporte-emisiones-offline'
                  },
                  {
                    title: 'Reporte Polizas Emitidas (Anterior)',
                    route: '/polizas-emitidas',
                  },
                  {
                    title: 'Pólizas por renovar',
                    route: '/polizas-por-renovar',
                  },
                  {
                    title: 'Pólizas renovadas',
                    route: '/polizas-renovadas',
                  },
                  {
                    title: 'Cuotas de Pólizas Activas',
                    route: '/cobranza-polizas-activas',
                  },
                  // {
                  //   title: 'Polizas con cobranza Vencida',
                  //   route: '/proximamente',
                  // },
                  // {
                  //   title: 'Cuotas Pagadas',
                  //   route: '/cuotas-pagadas',
                  // },
                  {
                    title: 'Cuotas Canceladas',
                    route: '/cuotas-canceladas',
                  },
                  {
                    title: 'Cuotas Vencidas',
                    route: '/cuotas-vencidas',
                  },
                  {
                    title: 'Comisiones pendientes de Pago de la Aseguradora',
                    route: '/proximamente',
                  },
                  {
                    title: 'Comisiones Pendiente de Pago al agente',
                    route: '/proximamente',
                  },
                  {
                    title: 'Comisiones Pagadas al Agente',
                    route: '/proximamente',
                  },
                  // {
                  //   title: 'Leads CC',
                  //   route: '/callCenter/lead',
                  // },
                  // {
                  //   title: 'Reporte Toques Call Center',
                  //   route: '/callCenter/reporteToquesCC',
                  // },
                  // {
                  //   title: 'Reporte Leads-Oportunidades',
                  //   route: '/callCenter/reporteLeads',
                  // },
                  // {
                  //   title: 'AgenteCC',
                  //   route: '/proximamente',
                  // },
                  // {
                  //   title: 'Call Back Time',
                  //   route: '/reporte/call-back-time',
                  // },
                  {
                    title: 'Atracción de talento',
                    route: '/atraccion-talento',
                  },
                ],
              },
              {
                action: 'mdi-clipboard-text-search',
                title: 'Reportes ECI',
                items: [

                  // {
                  //   title: 'Reporte de Leads Autos',
                  //   route: '/administrador/reporte-actualSales',
                  //   //route: '/proximamente',
                  // },
                  // {
                  //   title: 'Reporte de Leads Movilidad',
                  //   route: '/administrador/reporte-sura-movilidad',
                  //   //route: '/proximamente',
                  // },
                  // {
                  //   title: 'Reporte de Leads Rastreator',
                  //   route: '/administrador/reporte-leads-rastreator',
                  //   //route: '/proximamente',
                  // },
                  // {
                  //   title: 'Reporte de Leads Ahorro',
                  //   route: '/administrador/reporte-ahorro',
                  //   //route: '/proximamente',
                  // },
                  // {
                  //   title: 'Reporte de Leads Tractos',
                  //   route: '/administrador/reporte-leads-tractos',
                  //   //route: '/proximamente',
                  // },
                  // {
                  //   title: 'Reporte de Leads SURA AlaSegura',
                  //   route: '/proximamente',
                  //   //route: '/proximamente',
                  // },
                  // {
                  //   title: 'Reporte de Leads SGMM Segurify',
                  //   route: '/administrador/reporte-sgmm',
                  //   //route: '/proximamente',
                  // },
                  // {
                  //   title: 'Reporte Call Back Time',
                  //   route: '/administrador/reporteCallBack',
                  // },
                  {
                    title: 'Reportes leads',
                    route: '/reporte/leads',
                  },
                  {
                    title: 'Reporte Call Back Time',
                    route: '/reporte/call-back-time',
                  },
                  {
                    title: 'Reporte de Marcación de Leads',
                    route: '/reporte/marcacion',
                  },
                  {
                    title: 'Reporte de Cotizaciones Landings',
                    route: '/administrador/reporte-clientes',
                  },
                  {
                    title: 'Reporte de Abandono ECI Landing',
                    route: '/landings/reporteAbandonoLeads',
                  },
                  {
                    title: 'Reporte de Tarificación Comparador',
                    route: '/reporte-tarificacion',
                  },
                ],
              },
            ],
          }
        ] :
      this.rol == "DIRECTO" ?
        this.listaDesplegable = [
          {
            title: 'Cuenta',
            items: [
              {
                action: 'mdi-account-circle',
                active: true,
                title: 'Cuenta',
                items: [
                  {
                    title: 'Inicio',
                    route: '/inicio' },
                  {
                    title: 'Mi Perfil',
                    route: '/usuario/ver-micuenta',
                  },
                  {
                    title: 'Mis Cotizaciones Autos',
                    route: '/mis-cotizaciones',
                  },
                  {
                    title: 'Mis Cotizaciones Celular',
                    route: '/mis-cotizaciones-celular',
                  },
                  {
                    title: 'Leads segurify',
                    route: '/leads',
                  },
                ],
              },
              {
                action: 'mdi-clipboard-text-multiple',
                title: 'Reportes',
                items: [
                  {
                    title: 'SLA de Solicitudes',
                    route: '/sla-solicitudes',
                  },
                  {
                    title: 'Solicitudes con Pólizas',
                    route: '/solicitudes-con-polizas',
                  },
                  {
                    title: 'Solicitudes Pendientes de Aprobación',
                    route: '/solicitudes-pendientes-aprobacion',
                  },
                  {
                    title: 'Cartera de Agentes',
                    route: '/cartera-agentes',
                  },
                  {
                    title: 'Cartera de Clientes',
                    route: '/proximamente',
                  },
                  // {
                  //   title: "Reporte de Pólizas",
                  //   route: "/reporte/emisiones"
                  // },
                  {
                    title: 'Reporte Pólizas Autos Masivos (Anterior)',
                    route: '/callcenter/reporte-emisiones-offline'
                  },
                  {
                    title: 'Reporte Polizas Emitidas (Anterior)',
                    route: '/polizas-emitidas',
                  },
                  {
                    title: 'Pólizas por renovar',
                    route: '/polizas-por-renovar',
                  },
                  {
                    title: 'Pólizas renovadas',
                    route: '/polizas-renovadas',
                  },
                  {
                    title: 'Cuotas de Pólizas Activas',
                    route: '/cobranza-polizas-activas',
                  },
                  // {
                  //   title: 'Polizas con cobranza Vencida',
                  //   route: '/proximamente',
                  // },
                  // {
                  //   title: 'Cuotas Pagadas',
                  //   route: '/cuotas-pagadas',
                  // },
                  {
                    title: 'Cuotas Canceladas',
                    route: '/cuotas-canceladas',
                  },
                  {
                    title: 'Cuotas Vencidas',
                    route: '/cuotas-vencidas',
                  },
                  {
                    title: 'Comisiones pendientes de Pago de la Aseguradora',
                    route: '/proximamente',
                  },
                  {
                    title: 'Comisiones Pendiente de Pago al agente',
                    route: '/proximamente',
                  },
                  {
                    title: 'Comisiones Pagadas al Agente',
                    route: '/proximamente',
                  },
                  // {
                  //   title: 'Leads CC',
                  //   route: '/callCenter/lead',
                  // },
                  // {
                  //   title: 'Reporte Toques Call Center',
                  //   route: '/callCenter/reporteToquesCC',
                  // },
                  // {
                  //   title: 'Reporte Leads-Oportunidades',
                  //   route: '/callCenter/reporteLeads',
                  // },
                  // {
                  //   title: 'AgenteCC',
                  //   route: '/proximamente',
                  // },
                  // {
                  //   title: 'Call Back Time',
                  //   route: '/reporte/call-back-time',
                  // },
                  {
                    title: 'Atracción de talento',
                    route: '/atraccion-talento',
                  },
                ],
              },
            ],
          }
        ] :
      this.rol == "FINANZAS" ?
        this.listaDesplegable = [
          {
            title: 'Cuenta',
            items: [
              {
                action: 'mdi-account-circle',
                active: true,
                title: 'Cuenta',
                items: [
                  {
                    title: 'Inicio',
                    route: '/inicio' },
                  {
                    title: 'Mi Perfil',
                    route: '/usuario/ver-micuenta',
                  },
                  {
                    title: 'Mis Cotizaciones Autos',
                    route: '/mis-cotizaciones',
                  },
                  {
                    title: 'Mis Cotizaciones Celular',
                    route: '/mis-cotizaciones-celular',
                  },
                  {
                    title: 'Cuotas de Pólizas',
                    route: '/cuotas-hub',
                    //route: '/proximamente',
                  },
                  {
                    title: 'Leads segurify',
                    route: '/leads',
                  },
                ],
              },
              {
                action: 'mdi-clipboard-text-multiple',
                title: 'Reportes',
                items: [
                  {
                    title: 'SLA de Solicitudes',
                    route: '/sla-solicitudes',
                  },
                  {
                    title: 'Solicitudes con Pólizas',
                    route: '/solicitudes-con-polizas',
                  },
                  {
                    title: 'Solicitudes Pendientes de Aprobación',
                    route: '/solicitudes-pendientes-aprobacion',
                  },
                  {
                    title: 'Cartera de Agentes',
                    route: '/cartera-agentes',
                  },
                  {
                    title: 'Cartera de Clientes',
                    route: '/proximamente',
                  },
                  // {
                  //   title: "Reporte de Pólizas",
                  //   route: "/reporte/emisiones"
                  // },
                  {
                    title: 'Reporte Pólizas Autos Masivos (Anterior)',
                    route: '/callcenter/reporte-emisiones-offline'
                  },
                  {
                    title: 'Reporte Polizas Emitidas (Anterior)',
                    route: '/polizas-emitidas',
                  },
                  {
                    title: 'Pólizas por renovar',
                    route: '/polizas-por-renovar',
                  },
                  {
                    title: 'Pólizas renovadas',
                    route: '/polizas-renovadas',
                  },
                  {
                    title: 'Cuotas de Pólizas Activas',
                    route: '/cobranza-polizas-activas',
                  },
                  // {
                  //   title: 'Polizas con cobranza Vencida',
                  //   route: '/proximamente',
                  // },
                  // {
                  //   title: 'Cuotas Pagadas',
                  //   route: '/cuotas-pagadas',
                  // },
                  {
                    title: 'Cuotas Canceladas',
                    route: '/cuotas-canceladas',
                  },
                  {
                    title: 'Cuotas Vencidas',
                    route: '/cuotas-vencidas',
                  },
                  {
                    title: 'Comisiones pendientes de Pago de la Aseguradora',
                    route: '/proximamente',
                  },
                  {
                    title: 'Comisiones Pendiente de Pago al agente',
                    route: '/proximamente',
                  },
                  {
                    title: 'Comisiones Pagadas al Agente',
                    route: '/proximamente',
                  },
                  // {
                  //   title: 'Leads CC',
                  //   route: '/callCenter/lead',
                  // },
                  // {
                  //   title: 'Reporte Toques Call Center',
                  //   route: '/callCenter/reporteToquesCC',
                  // },
                  // {
                  //   title: 'Reporte Leads-Oportunidades',
                  //   route: '/callCenter/reporteLeads',
                  // },
                  // {
                  //   title: 'AgenteCC',
                  //   route: '/proximamente',
                  // },
                  // {
                  //   title: 'Call Back Time',
                  //   route: '/reporte/call-back-time',
                  // },
                  {
                    title: 'Atracción de talento',
                    route: '/atraccion-talento',
                  },
                ],
              },
              {
                action: 'mdi-clipboard-text-search',
                title: 'Reportes ECI',
                items: [

                  // {
                  //   title: 'Reporte de Leads Autos',
                  //   route: '/administrador/reporte-actualSales',
                  //   //route: '/proximamente',
                  // },
                  // {
                  //   title: 'Reporte de Leads Movilidad',
                  //   route: '/administrador/reporte-sura-movilidad',
                  //   //route: '/proximamente',
                  // },
                  // {
                  //   title: 'Reporte de Leads Rastreator',
                  //   route: '/administrador/reporte-leads-rastreator',
                  //   //route: '/proximamente',
                  // },
                  // {
                  //   title: 'Reporte de Leads Ahorro',
                  //   route: '/administrador/reporte-ahorro',
                  //   //route: '/proximamente',
                  // },
                  // {
                  //   title: 'Reporte de Leads Tractos',
                  //   route: '/administrador/reporte-leads-tractos',
                  //   //route: '/proximamente',
                  // },
                  // {
                  //   title: 'Reporte de Leads SURA AlaSegura',
                  //   route: '/proximamente',
                  //   //route: '/proximamente',
                  // },
                  // {
                  //   title: 'Reporte de Leads SGMM Segurify',
                  //   route: '/administrador/reporte-sgmm',
                  //   //route: '/proximamente',
                  // },
                  // {
                  //   title: 'Reporte Call Back Time',
                  //   route: '/administrador/reporteCallBack',
                  // },
                  {
                    title: 'Reportes leads',
                    route: '/reporte/leads',
                  },
                  {
                    title: 'Reporte Call Back Time',
                    route: '/reporte/call-back-time',
                  },
                  {
                    title: 'Reporte de Marcación de Leads',
                    route: '/reporte/marcacion',
                  },
                  {
                    title: 'Reporte de Cotizaciones Landings',
                    route: '/administrador/reporte-clientes',
                  },
                  {
                    title: 'Reporte de Abandono ECI Landing',
                    route: '/landings/reporteAbandonoLeads',
                  },
                  {
                    title: 'Reporte de Tarificación Comparador',
                    route: '/reporte-tarificacion',
                  },
                ],
              },
            ],
          }
        ] :
      this.rol == "OPERACIONES" || this.rol == "MESADECONTROL" || this.rol == "OPERACIONESINBOUND" ?
        this.listaDesplegable = [
          {
            title: 'Cuenta',
            items: [
              {
                action: 'mdi-account-circle',
                active: true,
                title: 'Cuenta',
                items: [
                  {
                    title: 'Inicio',
                    route: '/inicio' },
                  {
                    title: 'Mi Perfil',
                    route: '/usuario/ver-micuenta',
                  },
                  {
                    title: 'Mis Cotizaciones Autos',
                    route: '/mis-cotizaciones',
                  },
                  {
                    title: 'Chats Clientes',
                    route: '/chats-clientes',
                  },
                  {
                    title: 'Mis Cotizaciones Celular',
                    route: '/mis-cotizaciones-celular',
                  },
                  {
                    title: 'Leads segurify',
                    route: '/leads',
                  },
                  {
                    title: 'Home Servicios Inbound',
                    route: '/home/servicios/inbound',
                  },
                  {
                    title: 'Solicitudes',
                    route: '/solicitudes',
                    //route: '/proximamente',
                  },
                  {
                    title: 'Clientes',
                    route: '/lista-clientes',
                    //route: '/proximamente',
                  },
                  {
                    title: 'Pólizas',
                    route: '/mis-emisiones',
                    //route: '/proximamente',
                  },
                  // {
                  //   title: 'Cuotas de Pólizas',
                  //   route: '/cuotas-hub',
                  //   //route: '/proximamente',
                  // },
                ],
              },
              {
                action: 'mdi-account-box-multiple-outline',
                title: 'Administración',
                items: [
                  {
                    title: 'Administración de Usuarios',
                    route: '/usuarios',
                    //route: '/proximamente',
                  },
                ],
              },
              {
                action: 'mdi-clipboard-text-multiple',
                title: 'Reportes',
                items: [
                  {
                    title: 'SLA de Solicitudes',
                    route: '/sla-solicitudes',
                  },
                  {
                    title: 'Solicitudes con Pólizas',
                    route: '/solicitudes-con-polizas',
                  },
                  {
                    title: 'Solicitudes Pendientes de Aprobación',
                    route: '/solicitudes-pendientes-aprobacion',
                  },
                  {
                    title: 'Cartera de Agentes',
                    route: '/cartera-agentes',
                  },
                  {
                    title: 'Cartera de Clientes',
                    route: '/proximamente',
                  },
                  // {
                  //   title: "Reporte de Pólizas",
                  //   route: "/reporte/emisiones"
                  // },
                  {
                    title: 'Reporte Pólizas Autos Masivos (Anterior)',
                    route: '/callcenter/reporte-emisiones-offline'
                  },
                  {
                    title: 'Reporte Polizas Emitidas (Anterior)',
                    route: '/polizas-emitidas',
                  },
                  {
                    title: 'Pólizas por renovar',
                    route: '/polizas-por-renovar',
                  },
                  {
                    title: 'Pólizas renovadas',
                    route: '/polizas-renovadas',
                  },
                  {
                    title: 'Cuotas de Pólizas Activas',
                    route: '/cobranza-polizas-activas',
                  },
                  // {
                  //   title: 'Polizas con cobranza Vencida',
                  //   route: '/proximamente',
                  // },
                  // {
                  //   title: 'Cuotas Pagadas',
                  //   route: '/cuotas-pagadas',
                  // },
                  {
                    title: 'Cuotas Canceladas',
                    route: '/cuotas-canceladas',
                  },
                  {
                    title: 'Cuotas Vencidas',
                    route: '/cuotas-vencidas',
                  },
                  {
                    title: 'Comisiones pendientes de Pago de la Aseguradora',
                    route: '/proximamente',
                  },
                  {
                    title: 'Comisiones Pendiente de Pago al agente',
                    route: '/proximamente',
                  },
                  {
                    title: 'Comisiones Pagadas al Agente',
                    route: '/proximamente',
                  },
                  // {
                  //   title: 'Leads CC',
                  //   route: '/callCenter/lead',
                  // },
                  // {
                  //   title: 'Reporte Toques Call Center',
                  //   route: '/callCenter/reporteToquesCC',
                  // },
                  // {
                  //   title: 'Reporte Leads-Oportunidades',
                  //   route: '/callCenter/reporteLeads',
                  // },
                  // {
                  //   title: 'AgenteCC',
                  //   route: '/proximamente',
                  // },
                  // {
                  //   title: 'Call Back Time',
                  //   route: '/reporte/call-back-time',
                  // },
                  {
                    title: 'Atracción de talento',
                    route: '/atraccion-talento',
                  },
                ],
              },
            ],
          }
        ] :
      this.rol == "OPERADOR" ||  this.rol == "OPERADORINBOUND" ?
        this.listaDesplegable = [
          {
            title: 'Cuenta',
            items: [
              {
                action: 'mdi-account-circle',
                active: true,
                title: 'Cuenta',
                items: [
                  {
                    title: 'Inicio',
                    route: '/inicio' },
                  {
                    title: 'Mi Perfil',
                    route: '/usuario/ver-micuenta',
                  },
                  {
                    title: 'Mis Cotizaciones Autos',
                    route: '/mis-cotizaciones',
                  },
                  {
                    title: 'Chats Clientes',
                    route: '/chats-clientes',
                  },
                  {
                    title: 'Mis Cotizaciones Celular',
                    route: '/mis-cotizaciones-celular',
                  },
                  {
                    title: 'Home Servicios Inbound',
                    route: '/home/servicios/inbound',
                  },
                  {
                    title: 'Leads Segurify',
                    route: '/leads',
                  },
                  {
                    title: 'Servicio Inbound',
                    route: '/inbound',
                  },
                  {
                    title: 'Solicitudes',
                    route: '/solicitudes',
                    //route: '/proximamente',
                  },
                  {
                    title: 'Clientes',
                    route: '/lista-clientes',
                    //route: '/proximamente',
                  },
                  {
                    title: 'Pólizas',
                    route: '/mis-emisiones',
                    //route: '/proximamente',
                  },
                  // {
                  //   title: 'Cuotas de Pólizas',
                  //   route: '/cuotas-hub',
                  //   //route: '/proximamente',
                  // },
                ],
              },
              {
                action: 'mdi-clipboard-text-multiple',
                title: 'Reportes',
                items: [
                  {
                    title: 'SLA de Solicitudes',
                    route: '/sla-solicitudes',
                  },
                  {
                    title: 'Solicitudes con Pólizas',
                    route: '/solicitudes-con-polizas',
                  },
                  {
                    title: 'Solicitudes Pendientes de Aprobación',
                    route: '/solicitudes-pendientes-aprobacion',
                  },
                  {
                    title: 'Cartera de Agentes',
                    route: '/cartera-agentes',
                  },
                  {
                    title: 'Cartera de Clientes',
                    route: '/proximamente',
                  },
                  // {
                  //   title: "Reporte de Pólizas",
                  //   route: "/reporte/emisiones"
                  // },
                  {
                    title: 'Reporte Pólizas Autos Masivos (Anterior)',
                    route: '/callcenter/reporte-emisiones-offline'
                  },
                  {
                    title: 'Reporte Polizas Emitidas (Anterior)',
                    route: '/polizas-emitidas',
                  },
                  {
                    title: 'Pólizas por renovar',
                    route: '/polizas-por-renovar',
                  },
                  {
                    title: 'Pólizas renovadas',
                    route: '/polizas-renovadas',
                  },
                  {
                    title: 'Cuotas de Pólizas Activas',
                    route: '/cobranza-polizas-activas',
                  },
                  // {
                  //   title: 'Polizas con cobranza Vencida',
                  //   route: '/proximamente',
                  // },
                  // {
                  //   title: 'Cuotas Pagadas',
                  //   route: '/cuotas-pagadas',
                  // },
                  {
                    title: 'Cuotas Canceladas',
                    route: '/cuotas-canceladas',
                  },
                  {
                    title: 'Cuotas Vencidas',
                    route: '/cuotas-vencidas',
                  },
                  {
                    title: 'Comisiones pendientes de Pago de la Aseguradora',
                    route: '/proximamente',
                  },
                  {
                    title: 'Comisiones Pendiente de Pago al agente',
                    route: '/proximamente',
                  },
                  {
                    title: 'Comisiones Pagadas al Agente',
                    route: '/proximamente',
                  },
                  // {
                  //   title: 'Leads CC',
                  //   route: '/callCenter/lead',
                  // },
                  // {
                  //   title: 'Reporte Toques Call Center',
                  //   route: '/callCenter/reporteToquesCC',
                  // },
                  // {
                  //   title: 'Reporte Leads-Oportunidades',
                  //   route: '/callCenter/reporteLeads',
                  // },
                  // {
                  //   title: 'AgenteCC',
                  //   route: '/proximamente',
                  // },
                  // {
                  //   title: 'Call Back Time',
                  //   route: '/reporte/call-back-time',
                  // },
                  {
                    title: 'Atracción de talento',
                    route: '/atraccion-talento',
                  },
                ],
              },
            ],
          }
        ] :
      this.rol == "AGENTPARTNER" ?
        this.listaDesplegable = [
          {
            title: 'Cuenta',
            items: [
              {
                action: 'mdi-clipboard-text-search-outline',
                active: true,
                title: 'Reporte de Partners',
                items: [
                  {
                    title: 'Mis Cotizaciones Autos',
                    route: '/mis-cotizaciones',
                  },
                  {
                    title: 'Mis Cotizaciones Celular',
                    route: '/mis-cotizaciones-celular',
                  },
                  {
                    title: 'Leads segurify',
                    route: '/leads',
                  },
                  {
                    title: 'Reporte de Emisiones tuSeguro.com',
                    route: '/administrador/reporte-tuSeguro-emisiones',
                    //route: '/proximamente',
                  },
                  {
                    title: 'Reporte de Leads tuSeguro.com',
                    route: '/administrador/reporte-tuSeguro-leads',
                    //route: '/proximamente',
                  },
                  // {
                  //   title: 'Reporte Toques Call Center tuSeguro.com',
                  //   //route: '/callCenter/reporteLeadsTuseguro',
                  //   route: '/proximamente',
                  // },
                  {
                    title: 'Reporte Leads-Oportunidades tuSeguro.com',
                    route: '/callCenter/reporteLeadsTuseguro',
                    //route: '/proximamente',
                  },
                  {
                    title: 'Reporte Abandono Leads TuSeguro',
                    route: '/landings/reporteAbandonoLeads/tuSeguro',
                    //route: '/proximamente',
                  },
                  {
                    title: 'Reporte Leads Coru-AIG',
                    route: '/administrador/reporte-coru-aig',
                    //route: '/proximamente',
                  },
                ],
              },
            ],
          }
        ] :
      this.rol == "COBRANZACC" ?
        this.listaDesplegable = [
          {
            title: 'Cuenta',
            items: [
              {
                action: 'mdi-account-circle',
                active: true,
                title: 'Cuenta',
                items: [
                  {
                    title: 'Inicio',
                    route: '/inicio' },
                  {
                    title: 'Mi Perfil',
                    route: '/usuario/ver-micuenta',
                  },
                  {
                    title: 'Mis Cotizaciones Autos',
                    route: '/mis-cotizaciones',
                    //route: '/proximamente',
                  },
                  {
                    title: 'Mis Cotizaciones Celular',
                    route: '/mis-cotizaciones-celular',
                  },
                  {
                    title: 'Leads Segurify',
                    route: '/leads',
                  },
                  {
                    title: 'Servicio Inbound',
                    route: '/inbound',
                  },
                  // {
                  //   title: 'Mis Solicitudes',
                  //   route: '/solicitudes',
                  //   //route: '/proximamente',
                  // },
                  {
                    title: 'Mis Clientes',
                    route: '/lista-clientes',
                    //route: '/proximamente',
                  },
                  {
                    title: 'Mis Pólizas',
                    route: '/mis-emisiones',
                    //route: '/proximamente',
                  },
                  // {
                  //   title: 'Cuotas de Pólizas',
                  //   route: '/cuotas-hub',
                  //   //route: '/proximamente',
                  // },
                ],
              },
              {
                action: 'mdi-clipboard-text-search',
                title: 'Reportes ECI',
                items: [

                  // {
                  //   title: 'Reporte de Leads Autos',
                  //   route: '/administrador/reporte-actualSales',
                  //   //route: '/proximamente',
                  // },
                  // {
                  //   title: 'Reporte de Leads Movilidad',
                  //   route: '/administrador/reporte-sura-movilidad',
                  //   //route: '/proximamente',
                  // },
                  // {
                  //   title: 'Reporte de Leads Rastreator',
                  //   route: '/administrador/reporte-leads-rastreator',
                  //   //route: '/proximamente',
                  // },
                  // {
                  //   title: 'Reporte de Leads Ahorro',
                  //   route: '/administrador/reporte-ahorro',
                  //   //route: '/proximamente',
                  // },
                  // {
                  //   title: 'Reporte de Leads Tractos',
                  //   route: '/administrador/reporte-leads-tractos',
                  //   //route: '/proximamente',
                  // },
                  // {
                  //   title: 'Reporte de Leads SURA AlaSegura',
                  //   route: '/proximamente',
                  //   //route: '/proximamente',
                  // },
                  // {
                  //   title: 'Reporte de Leads SGMM Segurify',
                  //   route: '/administrador/reporte-sgmm',
                  //   //route: '/proximamente',
                  // },
                  // {
                  //   title: 'Reporte Call Back Time',
                  //   route: '/administrador/reporteCallBack',
                  // },
                  {
                    title: 'Reportes leads',
                    route: '/reporte/leads',
                  },
                  {
                    title: 'Reporte Call Back Time',
                    route: '/reporte/call-back-time',
                  },
                  {
                    title: 'Reporte de Marcación de Leads',
                    route: '/reporte/marcacion',
                  },
                  {
                    title: 'Reporte de Cotizaciones Landings',
                    route: '/administrador/reporte-clientes',
                  },
                  {
                    title: 'Reporte de Abandono ECI Landing',
                    route: '/landings/reporteAbandonoLeads',
                  },
                  {
                    title: 'Reporte de Tarificación Comparador',
                    route: '/reporte-tarificacion',
                  },
                ],
              },
              {
                action: 'mdi-clipboard-text-search-outline',
                active: true,
                title: 'Reporte de Partners',
                items: [
                  {
                    title: 'Cuotas de Pólizas Activas',
                    route: '/cobranza-polizas-activas',
                  },
                ],
              },
            ],
          }
        ] : this.rol == "BACKOFFICE" ? this.listaDesplegable = [
          {
            title: 'Cuenta',
            items: [
              {
                action: 'mdi-account-circle',
                active: true,
                title: 'Cuenta',
                items: [
                  {
                    title: 'Inicio',
                    route: '/inicio' },
                ],
              },
              {
                action: 'mdi-clipboard-text-multiple',
                title: 'Reportes',
                items: [
                  {
                    title: 'SLA de Solicitudes',
                    route: '/sla-solicitudes',
                  },
                  {
                    title: 'Solicitudes con Pólizas',
                    route: '/solicitudes-con-polizas',
                  },
                  {
                    title: 'Solicitudes Pendientes de Aprobación',
                    route: '/solicitudes-pendientes-aprobacion',
                  },
                  {
                    title: 'Cartera de Agentes',
                    route: '/cartera-agentes',
                  },
                  {
                    title: 'Cartera de Clientes',
                    route: '/proximamente',
                  },
                  // {
                  //   title: "Reporte de Pólizas",
                  //   route: "/reporte/emisiones"
                  // },
                  {
                    title: 'Reporte Pólizas Autos Masivos (Anterior)',
                    route: '/callcenter/reporte-emisiones-offline'
                  },
                  {
                    title: 'Reporte Polizas Emitidas (Anterior)',
                    route: '/polizas-emitidas',
                  },
                  {
                    title: 'Pólizas por renovar',
                    route: '/polizas-por-renovar',
                  },
                  {
                    title: 'Pólizas renovadas',
                    route: '/polizas-renovadas',
                  },
                  {
                    title: 'Cuotas de Pólizas Activas',
                    route: '/cobranza-polizas-activas',
                  },
                  // {
                  //   title: 'Polizas con cobranza Vencida',
                  //   route: '/proximamente',
                  // },
                  // {
                  //   title: 'Cuotas Pagadas',
                  //   route: '/cuotas-pagadas',
                  // },
                  {
                    title: 'Cuotas Canceladas',
                    route: '/cuotas-canceladas',
                  },
                  {
                    title: 'Cuotas Vencidas',
                    route: '/cuotas-vencidas',
                  },
                  {
                    title: 'Comisiones pendientes de Pago de la Aseguradora',
                    route: '/proximamente',
                  },
                  {
                    title: 'Comisiones Pendiente de Pago al agente',
                    route: '/proximamente',
                  },
                  {
                    title: 'Comisiones Pagadas al Agente',
                    route: '/proximamente',
                  },
                  // {
                  //   title: 'Leads CC',
                  //   route: '/callCenter/lead',
                  // },
                  // {
                  //   title: 'Reporte Toques Call Center',
                  //   route: '/callCenter/reporteToquesCC',
                  // },
                  // {
                  //   title: 'Reporte Leads-Oportunidades',
                  //   route: '/callCenter/reporteLeads',
                  // },
                  // {
                  //   title: 'AgenteCC',
                  //   route: '/proximamente',
                  // },
                  // {
                  //   title: 'Call Back Time',
                  //   route: '/reporte/call-back-time',
                  // },
                  {
                    title: 'Atracción de talento',
                    route: '/atraccion-talento',
                  },
                ],
              },
              {
                action: 'mdi-clipboard-text-search',
                title: 'Reportes ECI',
                items: [

                  // {
                  //   title: 'Reporte de Leads Autos',
                  //   route: '/administrador/reporte-actualSales',
                  //   //route: '/proximamente',
                  // },
                  // {
                  //   title: 'Reporte de Leads Movilidad',
                  //   route: '/administrador/reporte-sura-movilidad',
                  //   //route: '/proximamente',
                  // },
                  // {
                  //   title: 'Reporte de Leads Rastreator',
                  //   route: '/administrador/reporte-leads-rastreator',
                  //   //route: '/proximamente',
                  // },
                  // {
                  //   title: 'Reporte de Leads Ahorro',
                  //   route: '/administrador/reporte-ahorro',
                  //   //route: '/proximamente',
                  // },
                  // {
                  //   title: 'Reporte de Leads Tractos',
                  //   route: '/administrador/reporte-leads-tractos',
                  //   //route: '/proximamente',
                  // },
                  // {
                  //   title: 'Reporte de Leads SURA AlaSegura',
                  //   route: '/proximamente',
                  //   //route: '/proximamente',
                  // },
                  // {
                  //   title: 'Reporte de Leads SGMM Segurify',
                  //   route: '/administrador/reporte-sgmm',
                  //   //route: '/proximamente',
                  // },
                  // {
                  //   title: 'Reporte Call Back Time',
                  //   route: '/administrador/reporteCallBack',
                  // },
                  {
                    title: 'Reportes leads',
                    route: '/reporte/leads',
                  },
                  {
                    title: 'Reporte Call Back Time',
                    route: '/reporte/call-back-time',
                  },
                  {
                    title: 'Reporte de Marcación de Leads',
                    route: '/reporte/marcacion',
                  },
                  {
                    title: 'Reporte de Marcación Simple',
                    route: '/reporte/marcacion-simple',
                  },
                  {
                    title: 'Reporte de Cotizaciones Landings',
                    route: '/administrador/reporte-clientes',
                  },
                  {
                    title: 'Reporte de Abandono ECI Landing',
                    route: '/landings/reporteAbandonoLeads',
                  },
                  {
                    title: 'Reporte de Tarificación Comparador',
                    route: '/reporte-tarificacion',
                  },
                ],
              },
            ],
          }
        ] :
      this.rol == "RENOVACIONESCC" ?
        this.listaDesplegable = [
          {
            title: 'Cuenta',
            items: [
              {
                action: 'mdi-account-circle',
                active: true,
                title: 'Cuenta',
                items: [
                  {
                    title: 'Inicio',
                    route: '/inicio' },
                  {
                    title: 'Mi Perfil',
                    route: '/usuario/ver-micuenta',
                  },
                  {
                    title: 'Mis Cotizaciones Autos',
                    route: '/mis-cotizaciones',
                    //route: '/proximamente',
                  },
                  {
                    title: 'Mis Cotizaciones Celular',
                    route: '/mis-cotizaciones-celular',
                  },
                  {
                    title: 'Leads Segurify',
                    route: '/leads',
                  },
                  {
                    title: 'Servicio Inbound',
                    route: '/inbound',
                  },
                  // {
                  //   title: 'Mis Solicitudes',
                  //   route: '/solicitudes',
                  //   //route: '/proximamente',
                  // },
                  {
                    title: 'Mis Clientes',
                    route: '/lista-clientes',
                    //route: '/proximamente',
                  },
                  {
                    title: 'Mis Pólizas',
                    route: '/mis-emisiones',
                    //route: '/proximamente',
                  },
                  // {
                  //   title: 'Cuotas de Pólizas',
                  //   route: '/cuotas-hub',
                  //   //route: '/proximamente',
                  // },
                ],
              },
              {
                action: 'mdi-clipboard-text-search',
                title: 'Reportes ECI',
                items: [

                  // {
                  //   title: 'Reporte de Leads Autos',
                  //   route: '/administrador/reporte-actualSales',
                  //   //route: '/proximamente',
                  // },
                  // {
                  //   title: 'Reporte de Leads Movilidad',
                  //   route: '/administrador/reporte-sura-movilidad',
                  //   //route: '/proximamente',
                  // },
                  // {
                  //   title: 'Reporte de Leads Rastreator',
                  //   route: '/administrador/reporte-leads-rastreator',
                  //   //route: '/proximamente',
                  // },
                  // {
                  //   title: 'Reporte de Leads Ahorro',
                  //   route: '/administrador/reporte-ahorro',
                  //   //route: '/proximamente',
                  // },
                  // {
                  //   title: 'Reporte de Leads Tractos',
                  //   route: '/administrador/reporte-leads-tractos',
                  //   //route: '/proximamente',
                  // },
                  // {
                  //   title: 'Reporte de Leads SURA AlaSegura',
                  //   route: '/proximamente',
                  //   //route: '/proximamente',
                  // },
                  // {
                  //   title: 'Reporte de Leads SGMM Segurify',
                  //   route: '/administrador/reporte-sgmm',
                  //   //route: '/proximamente',
                  // },
                  // {
                  //   title: 'Reporte Call Back Time',
                  //   route: '/administrador/reporteCallBack',
                  // },
                  {
                    title: 'Reportes leads',
                    route: '/reporte/leads',
                  },
                  {
                    title: 'Reporte Call Back Time',
                    route: '/reporte/call-back-time',
                  },
                  {
                    title: 'Reporte de Marcación de Leads',
                    route: '/reporte/marcacion',
                  },
                  {
                    title: 'Reporte de Cotizaciones ECI',
                    route: '/administrador/reporte-clientes',
                  },
                  {
                    title: 'Reporte de Abandono ECI Landing',
                    route: '/landings/reporteAbandonoLeads',
                  },
                  {
                    title: 'Reporte de Tarificación Comparador',
                    route: '/reporte-tarificacion',
                  },
                ],
              },
              {
                action: 'mdi-clipboard-text-search-outline',
                active: true,
                title: 'Reporte de Partners',
                items: [
                  {
                    title: 'Pólizas por renovar',
                    route: '/polizas-por-renovar',
                  },
                  {
                    title: 'Pólizas renovadas',
                    route: '/polizas-renovadas',
                  },
                ],
              },
            ],
          }
        ] : this.role == "RH" ? this.listaDesplegable = [
          {
                  action: 'mdi-clipboard-text-multiple',
                  title: 'Reportes',
                  items: [
                    {
                      title: 'Atracción de talento',
                      route: '/atraccion-talento',
                    },]
          }
        ] :
        this.rol == "AGENTCCSPONSORS" ?
        this.listaDesplegable = [
          {
            title: 'Cuenta',
            items: [
              {
                action: 'mdi-account-circle',
                active: true,
                title: 'Cuenta',
                items: [
                  {
                    title: 'Inicio',
                    route: '/inicio'
                  },
                  {
                    title: 'Mis Pólizas',
                    route: '/mis-emisiones',
                    //route: '/proximamente',
                  },
                  {
                    title: 'Leads segurify',
                    route: '/leads',
                  },
                ],
              },
            ],
          }
        ] :
        this.rol == "MESACONTROLINBOUND" || this.rol == "BACKOFFICEINBOUND"  ?
        this.listaDesplegable = [
          {
            title: 'Cuenta',
            items: [
              {
                action: 'mdi-account-circle',
                active: true,
                title: 'Cuenta',
                items: [
                  {
                    title: 'Inicio',
                    route: '/inicio' },
                  {
                    title: 'Mi Perfil',
                    route: '/usuario/ver-micuenta',
                  },
                  {
                    title: 'Mis Cotizaciones Autos',
                    route: '/mis-cotizaciones',
                  },
                  {
                    title: 'Chats Clientes',
                    route: '/chats-clientes',
                  },
                  {
                    title: 'Mis Cotizaciones Celular',
                    route: '/mis-cotizaciones-celular',
                  },
                  {
                    title: 'Home Servicios Inbound',
                    route: '/home/servicios/inbound',
                  },
                  {
                    title: 'Solicitudes',
                    route: '/solicitudes',
                    //route: '/proximamente',
                  },
                  {
                    title: 'Clientes',
                    route: '/lista-clientes',
                    //route: '/proximamente',
                  },
                  {
                    title: 'Pólizas',
                    route: '/mis-emisiones',
                    //route: '/proximamente',
                  },
                  // {
                  //   title: 'Cuotas de Pólizas',
                  //   route: '/cuotas-hub',
                  //   //route: '/proximamente',
                  // },
                ],
              },
              {
                action: 'mdi-clipboard-text-multiple',
                title: 'Reportes',
                items: [
                  {
                    title: 'SLA de Solicitudes',
                    route: '/sla-solicitudes',
                  },
                  {
                    title: 'Solicitudes con Pólizas',
                    route: '/solicitudes-con-polizas',
                  },
                  {
                    title: 'Solicitudes Pendientes de Aprobación',
                    route: '/solicitudes-pendientes-aprobacion',
                  },
                  {
                    title: 'Cartera de Agentes',
                    route: '/cartera-agentes',
                  },
                  {
                    title: 'Cartera de Clientes',
                    route: '/proximamente',
                  },
                  // {
                  //   title: "Reporte de Pólizas",
                  //   route: "/reporte/emisiones"
                  // },
                  {
                    title: 'Reporte Pólizas Autos Masivos (Anterior)',
                    route: '/callcenter/reporte-emisiones-offline'
                  },
                  {
                    title: 'Reporte Polizas Emitidas (Anterior)',
                    route: '/polizas-emitidas',
                  },
                  {
                    title: 'Pólizas por renovar',
                    route: '/polizas-por-renovar',
                  },
                  {
                    title: 'Pólizas renovadas',
                    route: '/polizas-renovadas',
                  },
                  {
                    title: 'Cuotas de Pólizas Activas',
                    route: '/cobranza-polizas-activas',
                  },
                  // {
                  //   title: 'Polizas con cobranza Vencida',
                  //   route: '/proximamente',
                  // },
                  // {
                  //   title: 'Cuotas Pagadas',
                  //   route: '/cuotas-pagadas',
                  // },
                  {
                    title: 'Cuotas Canceladas',
                    route: '/cuotas-canceladas',
                  },
                  {
                    title: 'Cuotas Vencidas',
                    route: '/cuotas-vencidas',
                  },
                  {
                    title: 'Comisiones pendientes de Pago de la Aseguradora',
                    route: '/proximamente',
                  },
                  {
                    title: 'Comisiones Pendiente de Pago al agente',
                    route: '/proximamente',
                  },
                  {
                    title: 'Comisiones Pagadas al Agente',
                    route: '/proximamente',
                  },
                  // {
                  //   title: 'Leads CC',
                  //   route: '/callCenter/lead',
                  // },
                  // {
                  //   title: 'Reporte Toques Call Center',
                  //   route: '/callCenter/reporteToquesCC',
                  // },
                  // {
                  //   title: 'Reporte Leads-Oportunidades',
                  //   route: '/callCenter/reporteLeads',
                  // },
                  // {
                  //   title: 'AgenteCC',
                  //   route: '/proximamente',
                  // },
                  // {
                  //   title: 'Call Back Time',
                  //   route: '/reporte/call-back-time',
                  // },
                  {
                    title: 'Atracción de talento',
                    route: '/atraccion-talento',
                  },
                ],
              },
            ],
          }
        ] : 
        this.rol == "FORMACION" ?
        this.listaDesplegable = [
          {
            title: 'Cuenta',
            items: [
              {
                action: 'mdi-account-box-multiple-outline',
                title: 'Administración',
                items: [
                  {
                    title: 'Administración de Usuarios',
                    route: '/usuarios',
                    //route: '/proximamente',
                  },
                ],
              },
            ],
          }
        ] : ''
    },
    checkStatus(){
      window.bpspat.api.getState(this.statusChecked)
    },
    statusChecked(state){
      var jsonActividadActual = localStorage.getItem('jsonLlamada') !== null  ? JSON.parse(localStorage.getItem('jsonLlamada')) :  { numero: null, global_id: null, service: null, seColgo: false };
      switch(state.data.status){
        case 'busy':
              if(jsonActividadActual.global_id != state.data.interactions[0].global_id){
                let nuevaLlamada = {
                    numero: state.data.interactions[0].phone_number,
                    global_id: state.data.interactions[0].global_id,
                    service: state.data.interactions[0].service,
                    seColgo: false
                  };
                localStorage.removeItem('jsonLlamada')
                localStorage.setItem("jsonLlamada", JSON.stringify(nuevaLlamada));
                this.getCustomerView(nuevaLlamada.numero)
              }
        break;
        case 'after_call_work':
          if(jsonActividadActual.global_id == state.data.interactions[0].global_id && !jsonActividadActual.seColgo){
              let nuevaLlamada = {
                    numero: state.data.interactions[0].phone_number,
                    global_id: state.data.interactions[0].global_id,
                    service: state.data.interactions[0].service,
                    seColgo: true
                  };
              localStorage.removeItem('jsonLlamada')
              localStorage.setItem("jsonLlamada", JSON.stringify(nuevaLlamada));
              this.$toast.info('Se colgo la llamada')
          }
          break;   
      }
    },
    getCustomerView(phone) {
      this.data = [];
      mainAxios.get("/v1/cliente/telefono/"+phone, this.config).then((response) => {
        if(response.data.uuid != null){
          this.$router.push('/cliente-detalle/'+ response.data.uuid+'/true' );
        }
        else
         this.dialogInboundCalls = true
      });
    },
    checkLeadView(phone){
      const nuevosFiltros = {
        telefono:phone
      }
      mainAxios.post("/v1/lead-segurify/list/filtros",nuevosFiltros, this.config).then((response) => {
        this.leadsLlamada = []
          if (response.data[0].error){
            this.$router.push(`/home/servicios/inbound`)
          }else{
            response.data.forEach((lead) => {
              this.leadsLlamada.push({
                id: lead.origenId,
                nombre: `${lead.nombre} ${lead.apellidoPaterno} ${lead.apellidoMaterno}`,
                medio: lead.medio,
                ramo: lead.ramo,
                canal: lead.canal,
                producto: lead.producto,
                telefono: lead.telefono,
                correo: lead.correo,
                uuid: lead.origenUuid,
                uuid_lead_segurify: lead.uuid,
                foo: "bar",
                lead:
                  lead.nombre +
                  " " +
                  lead.apellidopaterno +
                  " " +
                  lead.apellidomaterno,
                servicio_embebido: lead.servicio_embebido,
                embedded: false,
              });
            });
            if(this.leadsLlamada.length  > 0)
              this.changeRoute(this.leadsLlamada[0])
          }
      });
    },
    changeRoute(item) {
      this.$router.push({
        name: "LeadsSegurify",
        params: { item },
        props: true,
      });
    },
    saveDispocision(e){
      console.log({e})
    }
  },
};
</script>
<style>
.v-carousel__controls {
  bottom: 12px;
}

.rolNota {
  font-size: 12px;
  color: rgb(192, 192, 192);
}

.v-list-item__title {
  white-space: normal !important;
}
</style>
