<template>
  <v-row style="padding: 0px 20px">
    <v-col cols="4">
      <v-select
        label="Nivel Hospitalario"
        v-model="nivelHospitalario"
        :items="nivelHospitalarioList"
        @change="avisarCambio"
      ></v-select>
    </v-col>
    <v-col cols="4">
      <v-text-field
        label="Suma asegurada"
        v-model="sumaAsegurada"
        :rules="inputsRequeridos"
        @change="avisarCambio"
        prefix="$"
        v-number-only
      ></v-text-field>
    </v-col>
    <v-col cols="4">
      <v-text-field
        label="Deducible"
        v-model="deducible"
        :rules="inputsRequeridos"
        @change="avisarCambio"
        prefix="$"
        v-number-only
      ></v-text-field>
    </v-col>
    <v-col cols="4">
      <v-text-field
        label="Coaseguro"
        v-model="coaseguro"
        :rules="inputsRequeridos"
        @change="avisarCambio"
      ></v-text-field>
    </v-col>
    <v-col cols="4">
      <v-select
        label="Cero deducible por accidente"
        v-model="ceroDeducible"
        :items="ceroDeducibleList"
        @change="avisarCambio"
      ></v-select>
    </v-col>
    <v-col cols="4">
      <v-select
        label="Medicamentos fuera de hospital"
        v-model="medicinaFueraHospital"
        :items="medicinaFueraHospitalList"
        @change="avisarCambio"
      ></v-select>
    </v-col>
    <v-col cols="4">
      <v-select
        label="Emergencia en el Extranjero"
        v-model="emergenciaExtranjero"
        :items="emergenciaExtranjeroList"
        @change="avisarCambio"
      ></v-select>
    </v-col>
    <v-col cols="4">
      <v-select
        label="Enfermedades Catastróficas"
        v-model="enfermedadesCatastroficas"
        :items="enfermedadesCatastroficasList"
        @change="avisarCambio"
      ></v-select>
    </v-col>
    <v-col cols="4">
      <v-select
        label="Cobertura en el Extranjero"
        v-model="coberturaExtranjero"
        :items="coberturaExtranjeroList"
        @change="avisarCambio"
      ></v-select>
    </v-col>
    <v-col cols="4">
      <v-select
        label="Preexistencias"
        v-model="preexistencias"
        :items="preexistenciasList"
        @change="avisarCambio"
      ></v-select>
    </v-col>
    <v-col cols="4">
      <v-select
        label="Protección Dental"
        v-model="proteccionDental"
        :items="proteccionDentalList"
        @change="avisarCambio"
      ></v-select>
    </v-col>
    <v-col cols="4">
      <v-select
        label="Maternidad Extendida"
        v-model="maternidadExtendida"
        :items="maternidadExtendidaList"
        @change="avisarCambio"
      ></v-select>
    </v-col>
    <v-col cols="4">
      <v-select
        label="Cobertura Nacional (Multirregión)"
        v-model="coberturaNacional"
        :items="coberturaNacionalList"
        @change="avisarCambio"
      ></v-select>
    </v-col>
    <v-col cols="4">
      <v-select
        label="Reconocimiento de Antigüedad"
        v-model="reconocimientoAntiguedad"
        :items="reconocimientoAntiguedadList"
        @change="avisarCambio"
      ></v-select>
    </v-col>
    <v-col cols="4">
      <v-text-field
        v-model="fechasParaCotizar"
        label="Indicar las fechas para cotizar"
        v-bind="attrs"
        @change="avisarCambio"
      ></v-text-field>
    </v-col>
    <v-col cols="12">
      <v-textarea
      v-model="dependientes"
      label="Dependientes: En su caso se debe indicar la edad, sexo y parentesco de cada uno."
      value=""
      rows="2"
      hint="Escribe aquí tus dependientes"
      @change="avisarCambio"
      ></v-textarea>
    </v-col>
    
  </v-row>
</template>

<script>
var moment = require('moment-timezone');

export default {
  directives: {
    'number-only': {
      bind (el) {
        function checkValue (event) {
          event.target.value = event.target.value.replace(/[^0-9]/g, '')
          if (event.charCode >= 48 && event.charCode <= 57) {
            return true
          }
          event.preventDefault()
        }
        el.addEventListener('keypress', checkValue)
      }
    },
    'letter-only': {
      bind (el) {
        function checkValue (event) {
          if (event.charCode >= 48 && event.charCode <= 57) {
            event.preventDefault()
          }
          return true
        }
        el.addEventListener('keypress', checkValue)
      }
    }
  },
  data () {
    return {
      nivelHospitalario: null,
      sumaAsegurada: null,
      deducible: null,
      coaseguro: null,
      ceroDeducible: null,
      medicinaFueraHospital: null,
      emergenciaExtranjero: null,
      enfermedadesCatastroficas: null,
      coberturaExtranjero: null,
      preexistencias: null,
      proteccionDental: null,
      maternidadExtendida: null,
      coberturaNacional: null,
      reconocimientoAntiguedad: null,
      dependientes:null,

      comboFechasParaCotizar: null,
      dateFormattedInicioVigencia: null,
      fechasParaCotizar: false,

      //listas
      nivelHospitalarioList: [
        'Alto',
        'Medio',
        'Bajo',
      ],
      ceroDeducibleList: [
        'Si ampara',
        'No ampara',
      ],
      medicinaFueraHospitalList: [
        'Si ampara',
        'No ampara',
      ],
      emergenciaExtranjeroList: [
        'Si ampara',
        'No ampara',
      ],
      enfermedadesCatastroficasList: [
        'Si ampara',
        'No ampara',
      ],
      coberturaExtranjeroList: [
        'Si ampara',
        'No ampara',
      ],
      preexistenciasList: [
        'Si ampara',
        'No ampara',
      ],
      proteccionDentalList: [
        'Si ampara',
        'No ampara',
      ],
      maternidadExtendidaList: [
        'Si ampara',
        'No ampara',
      ],
      coberturaNacionalList: [
        'Si ampara',
        'No ampara',
      ],
      reconocimientoAntiguedadList: [
        'Si ampara',
        'No ampara',
      ],
      

      //Validadores
      inputsRequeridos: [
        value => !!value || 'Campo Requerido',
      ],
    }
  },
  props: ['avisar', 'infoEmision'],
  methods: {
    avisarCambio(){
      let datos = {
        nivelHospitalario:this.nivelHospitalario,
        sumaAsegurada:this.sumaAsegurada,
        deducible:this.deducible,
        coaseguro:this.coaseguro,
        ceroDeducible:this.ceroDeducible,
        medicinaFueraHospital:this.medicinaFueraHospital,
        emergenciaExtranjero:this.emergenciaExtranjero,
        enfermedadesCatastroficas:this.enfermedadesCatastroficas,
        coberturaExtranjero:this.coberturaExtranjero,
        preexistencias:this.preexistencias,
        proteccionDental:this.proteccionDental,
        maternidadExtendida:this.maternidadExtendida,
        coberturaNacional:this.coberturaNacional,
        reconocimientoAntiguedad:this.reconocimientoAntiguedad,
        fechasParaCotizar:this.fechasParaCotizar,
        dependientes:this.dependientes,
      }
      this.avisar(datos)
    },
    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    parseDate (date) {
      if (!date) return null

      const [day, month, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
  },
  watch: {
    comboFechasParaCotizar () {
      this.dateFormattedInicioVigencia = this.formatDate(moment(this.comboFechasParaCotizar).format('YYYY-MM-DD'))
    },
  },
  mounted() {
    this.nivelHospitalario = this.infoEmision.nivelHospitalario
    this.sumaAsegurada = this.infoEmision.sumaAsegurada
    this.deducible = this.infoEmision.deducible
    this.coaseguro = this.infoEmision.coaseguro
    this.ceroDeducible = this.infoEmision.ceroDeducible
    this.medicinaFueraHospital = this.infoEmision.medicinaFueraHospital
    this.emergenciaExtranjero = this.infoEmision.emergenciaExtranjero
    this.enfermedadesCatastroficas = this.infoEmision.enfermedadesCatastroficas
    this.coberturaExtranjero = this.infoEmision.coberturaExtranjero
    this.preexistencias = this.infoEmision.preexistencias
    this.proteccionDental = this.infoEmision.proteccionDental
    this.maternidadExtendida = this.infoEmision.maternidadExtendida
    this.coberturaNacional = this.infoEmision.coberturaNacional
    this.reconocimientoAntiguedad = this.infoEmision.reconocimientoAntiguedad
    this.fechasParaCotizar = this.infoEmision.fechasParaCotizar
    this.dateFormattedInicioVigencia = this.infoEmision.fechasParaCotizar
    this.dependientes = this.infoEmision.dependientes
  }
}
</script>