import { mainAxios } from "../../mainAxios";
import { getDefaultHeaders, getErrorMessage } from "../mainService";

export const getEmpresasInbound = async () => {
  try {
    const response = await mainAxios.get(
      '/v1/empresasInbound/list',
      getDefaultHeaders()
    );

    return response.data;
  } catch (error) {
    throw new Error(getErrorMessage(error));
  }
};