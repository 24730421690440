<template>
  <v-app>
    <Navigation></Navigation>
    <v-main>
      <v-container fluid>
        <!-- HEAD TITLE -->
        <v-row style="margin-top: 10px">
          <v-col cols="6">
            <h2>Cuotas y Comisiones</h2>
          </v-col>

          <v-col cols="6" class="d-flex justify-end">
            <v-btn
              icon
              class="mx-5"
              @click="verEditar()"
              v-if="canEdit"
            >
              <v-icon >mdi-pencil</v-icon>
            </v-btn>

            <v-btn
              @click="goBack()"
              elevation="2"
              ><v-icon style="margin-right: 5px">mdi-arrow-left</v-icon>Regresar
            </v-btn>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="7">
            <v-form>
              <v-row>
                <v-col>
                  <v-stepper v-model="stepper" non-linear>
                    <v-stepper-header style="justify-content: left">
                      <v-stepper-step step="1" editable>
                        Cuota de la Póliza
                      </v-stepper-step>

                      <v-stepper-step step="2" editable v-if="canViewDesglose">
                        Desglose de Comisiones
                      </v-stepper-step>
                    </v-stepper-header>

                    <v-stepper-items>
                      <!-- 1ST ITEM (FORM) -->
                      <v-stepper-content step="1">
                        <v-row style="padding: 20px">
                          <v-col @click="$router.push(`/emision/${comision[0].emisionId}`)" cols="6">
                            <v-text-field
                              label="Pólizas"
                              v-model="comision[0].emisionId"
                              readonly
                              class="enlacesForms"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              label="Orden"
                              v-model="comision[0].orden"
                              readonly
                            ></v-text-field>
                          </v-col>
                          <!-- End 1nd Col -->
                        </v-row>

                        <v-row style="padding: 20px; margin-top: -70px">
                          <v-col cols="6">
                            <v-text-field
                              label="Estado de la Cuota"
                              v-model="comision[0].status"
                              readonly
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              label="Valor a Pagar"
                              v-model="comision[0].valorAPagar"
                              readonly
                              prefix="$"
                            ></v-text-field>
                          </v-col>
                          <!-- End 2nd Col -->
                        </v-row>

                        <v-row style="padding: 20px; margin-top: -70px">
                         <v-col cols="6">
                            <v-text-field
                              label="Fecha de pago acordada"
                              v-model="fechaPagoAcordada"
                              readonly
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              label="Fecha límite de pago"
                              v-model="fechaLimitePago"
                              readonly
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row style="padding: 20px; margin-top: -70px">
                          
                          <v-col cols="6">
                            <v-text-field
                              label="Fecha pago cuota"
                              v-model="fechaPagoCuota"
                              readonly
                            ></v-text-field>
                          </v-col>
                          <!-- End 7th Col -->
                        </v-row>

                        <v-row style="padding: 20px; margin-top: -70px">
                          <v-col cols="6">
                            <v-text-field
                              label="Creado por"
                              v-model="creadoPor"
                              readonly
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              label="Fecha de Creación"
                              v-model="fechaCreacion"
                              readonly
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row style="padding: 20px; margin-top: -70px">
                          <v-col>
                            <v-text-field
                              label="Modificado Por"
                              v-model="modificadoPor"
                              readonly
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              label="Última Modificación"
                              v-model="fechaModificacion"
                              readonly
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-stepper-content>

                      <!-- 2ND ITEM (2ND FORM) -->
                      <v-stepper-content step="2">
                        <v-row style="padding: 20px; margin-top: -35px">
                          <v-col cols="6">
                            <v-text-field
                              label="Estatus de Comisión"
                              v-model="comision[0].statusComision"
                              readonly
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              label="Base Comisión"
                              v-model="comision[0].baseComision"
                              readonly
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row style="padding: 20px; margin-top: -70px">
                          
                          <v-col cols="6">
                            <v-text-field
                              label="Fecha de Comisión Recibidas"
                              v-model="comision[0].fechaComisionRecibida"
                              readonly
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row style="padding: 20px; margin-top: -70px">
                          <v-col cols="6">
                            <v-text-field
                              label="Folio de Movimiento Bancario Comisión"
                              v-model="comision[0].folioMovimientoBancario"
                              readonly
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row style="padding: 20px; margin-top: -70px">
                          <v-col cols="6">
                            <v-text-field
                              label="% Comisión pagada por la aseguradora"
                              v-model="comision[0].porcentajeComisionAseguradora"
                              readonly
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              label="Monto Comisión pagada por la aseguradora"
                              v-model="comision[0].montoComisionPagadaAseguradora"
                              readonly
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        
                        <v-row style="padding: 20px; margin-top: -70px">
                          <v-col cols="6">
                            <v-text-field
                              label="% Comisión Agente"
                              v-model="comision[0].porcentajeComisionAgente"
                              readonly
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              label="Monto de Comisión Agente"
                              v-model="comision[0].montoComisionAgente"
                              readonly
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row style="padding: 20px; margin-top: -70px; justify-content: right">
                          <v-col cols="6" style="aling: right">
                            <v-text-field
                              label="IVA"
                              v-model="comision[0].iva"
                              readonly
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row style="padding: 20px; margin-top: -70px; justify-content: right">
                          <v-col cols="6">
                            <v-text-field
                              label="ISR"
                              v-model="comision[0].isr"
                              readonly
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row style="padding: 20px; margin-top: -70px; justify-content: right">
                          <v-col cols="6">
                            <v-text-field
                              label="Retención IVA"
                              v-model="comision[0].retencionIva"
                              readonly
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row style="padding: 20px; margin-top: -70px">
                          <v-col cols="6">
                            <v-text-field
                              label="Folio de la factura por pagar"
                              v-model="comision[0].folioFacturaAPagar"
                              readonly
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              label="Importe a pagar al Agente"
                              v-model="comision[0].importeAPagarAgente"
                              readonly
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row style="padding: 20px; margin-top: -70px">
                          
                          <v-col cols="6">
                            <v-text-field
                              label="Fecha pagado de Comisión Agente"
                              v-model="comision[0].fechaComisionRecibida"
                              readonly
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row style="padding: 20px; margin-top: -70px">
                          <v-col cols="6">
                            <v-text-field
                              label="% de comisión para Alige"
                              v-model="comision[0].porcentajeComisionSegurify"
                              readonly
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              label="Monto comisión para Alige"
                              v-model="comision[0].montoComisionSegurify"
                              readonly
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row style="padding: 20px; margin-top: -70px">
                          <v-col cols="6">
                            <v-text-field
                              label="% Bono"
                              v-model="comision[0].porcentajeBono"
                              readonly
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              label="Monto Bono para Alige"
                              v-model="comision[0].montoBonoSegurify"
                              readonly
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row style="padding: 20px; margin-top: -70px">
                          <v-col cols="6">
                            <v-text-field
                              label="Folio de Movimiento Bancario Bono"
                              v-model="comision[0].folioMovimientoBancarioBono"
                              readonly
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              label="Fecha Pago de Bono"
                              v-model="comision[0].fechaPagoBono"
                              readonly
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row style="padding: 20px; margin-top: -70px">
                          <v-col cols="6">
                            <v-text-field
                              label="% Comisión total"
                              v-model="comision[0].porcentajeComisionTotal"
                              readonly
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              label="Monto Comisión Total Alige"
                              v-model="comision[0].montoComisiontotalSegurify"
                              readonly
                            ></v-text-field>
                          </v-col>
                        </v-row>

                      </v-stepper-content>
                    </v-stepper-items>
                  </v-stepper>
                </v-col>
              </v-row>
            </v-form>
          </v-col>

          <v-col cols="5">
            <v-card style="margin-bottom: 40px" class="pt-1">
              <v-row>
                <v-col cols="10">
                  <v-subheader>NOTAS Y DOCUMENTOS</v-subheader>
                </v-col>
                <v-col style="aling: right" class="mt-2" cols="2">
                  <v-btn small color="primary" @click="documentsDialog = true">
                    Crear
                  </v-btn>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-data-table
                :headers="columnsDocumentos"
                :items="documentos"
                item-key="name"
                class="elevation-1"
                dense
              >
                <template v-slot:top>
                  <!-- <v-text-field
                                    v-model="search"
                                    label="Buscar"
                                    class="mx-4"
                                    ></v-text-field> -->
                </template>
                <template v-slot:item.url="{ item }">
                  <v-icon
                    small
                    class="mr-2"
                    @click="verDocumento(item)"
                  >
                    mdi-eye
                  </v-icon>
                </template>
              </v-data-table>
            </v-card>

            <v-card style="margin-top: 15px">
              <v-row>
                <v-col cols="6">
                  <v-subheader>EMAILS</v-subheader>
                </v-col>
                <v-col cols="4">
                  <v-btn icon>
                    <v-icon>mdi-magnify</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="2">
                  <v-btn @click="newCorreoDialog=true" small color="primary">
                    Crear
                  </v-btn>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-data-table
                :headers="columns"
                :items="correos"
                item-key="name"
                class="elevation-1"
                dense
              >
                <template v-slot:top>
                  <v-text-field
                    label="Buscar"
                    class="mx-4"
                  ></v-text-field>
                </template>
                <template v-slot:item="row">
                    <tr>
                      <td>{{row.item.asunto}}</td>
                      <td>{{row.item.fecha}}</td>
                      <td>{{row.item.to}}</td>
                      <td style="cursor:pointer;" @click="verDetalleCorreo(row.item)">
                        <v-icon light small class="mr-2">mdi-eye</v-icon>
                      </td>
                    </tr>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>

    <!-- MODAL VER DOCUMENTOS -->
    <v-dialog
      v-model="dialogDetalleDocumentos"
      max-width="750px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Detalle de Documento / Nota</span>
        </v-card-title>

        <template>
          <v-container style="padding: 30px">
              <v-row>
                  <v-col cols="6">
                      <v-text-field
                          label="Nombre"
                          v-model="detallesDocumento.nombre"
                          readonly
                      ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                      <v-checkbox
                          label="¿Enviar por correo electrónico?"
                          readonly
                      ></v-checkbox>
                  </v-col>
                  <v-col cols="6">
                      <v-select
                          label="Tipo"
                          :items="tipoItems"
                          v-model="detallesDocumento.tipo"
                          required
                          readonly
                      ></v-select>
                  </v-col>
                  <v-col cols="6" v-if="detallesDocumento.tipo == 'Documento'">
                      <v-select
                          label="Tipo de Doocumento*"
                          :items="tipoDocumentoItems"
                          v-model="detallesDocumento.tipoDocumento"
                          required
                          readonly
                      ></v-select>
                  </v-col>
                  <v-col cols="6">
                      <v-text-field
                          label="Descripción"
                          v-model="detallesDocumento.descripcion"
                          required
                          readonly
                      ></v-text-field>
                  </v-col>
                  <v-col cols="4" v-if="detallesDocumento.url">
                    <template>
                      <v-file-input
                        v-model="documentos"
                        color="primary"
                        label="Adjuntos*"
                      >
                        <template v-slot:selection="{ index }">
                          <v-chip
                            v-if="index < 1"
                            color="primary"
                            dark
                            label
                            small
                            @click="detallesDocumento.url"
                          >
                            {{ detallesDocumento.nombre }}
                          </v-chip>

                          <span
                            v-else-if="index === 2"
                            class="text-overline grey--text text--darken-3 mx-2"
                          >
                            +{{ documentos.length - 2 }} Archivos(s)
                          </span>
                        </template>
                      </v-file-input>
                    </template>
                  </v-col>
                  <v-col cols="2" v-if="detallesDocumento.url">
                    <v-btn
                      text
                      :href="detallesDocumento.url"
                      small
                      color="primary"
                      class="mt-4"
                    >
                    <v-icon>mdi-download</v-icon>
                      Descargar
                    </v-btn>
                  </v-col>
                  <v-col cols="6">
                      <v-text-field
                          label="Creado por"
                          v-model="detallesDocumento.creadoPor"
                          readonly
                      ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                      <v-text-field
                          label="Fecha de Creación"
                          v-mask="'##/##/####'"
                          v-model="detallesDocumento.fecha"
                          readonly
                      ></v-text-field>
                  </v-col>
              </v-row>
          </v-container>
        </template>
      </v-card>

    </v-dialog>

    <!-- SEND EMAIL -->
    <v-row justify="center">
      <v-dialog
        v-model="newCorreoDialog"
        max-width="600px"
      >
        <v-card>
          <v-card-title>
            <span class="text-h5">Crear Correo</span>
          </v-card-title>
          <div style="padding:40px;">
            <EmailForm
              :cuota="cuotaId"
              :correoAgente="correoAgente"
            />
          </div>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="newCorreoDialog = false"
            >
              Cerrar
            </v-btn>
          </v-card-actions>
        
        </v-card>
      </v-dialog>
    </v-row>

    <!-- MODAL DE CORREO -->
    <v-dialog
      v-model="dialogCorreo"
      width="600px"
    >

      <v-card style="padding: 30px">
        <v-row>
          <v-col cols="12">
            <v-text-field
            label="De"
            v-model="correoOpen.remitente"
            hide-details="auto"
            readonly
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-text-field
            label="Para"
            v-model="correoOpen.to"
            hide-details="auto"
            readonly
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-text-field
            label="CC"
            v-model="correoOpen.cc"
            hide-details="auto"
            readonly
            ></v-text-field>
          </v-col>
        </v-row>  
        <v-row>
          <v-col cols="12">
            <v-text-field
            label="BCC"
            v-model="correoOpen.bcc"
            hide-details="auto"
            readonly
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
            label="Asunto"
            v-model="correoOpen.asunto"
            hide-details="auto"
            readonly
            ></v-text-field>
          </v-col>
        </v-row> 
        <v-row>
          <v-col cols="12">
            <v-textarea
            v-model="correoOpen.cuerpo"
            label="Mensaje"
            readonly
            ></v-textarea>
          </v-col>
        </v-row> 
        
        <v-row v-if="correoOpen.documentos">
          <v-col>
            <p>Descargar Adjuntos</p>
          </v-col>
        </v-row>
        <v-row v-if="correoOpen.documentos">
          <v-col>
            <v-chip
              v-for="(documento, index) in correoOpen.documentos"
              v-bind:key="index"
              color="primary"
              outlined
              :href="documento.url"
              style="cursor: pointer"
            >
              <v-icon left>
                mdi-tray-arrow-down
              </v-icon>
              {{ documento.nombre }}
            </v-chip>
          </v-col>
        </v-row>
        
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialogCorreo = false"
          >
           Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- ADD DOCUMENTS -->
    <v-row justify="center">
      <v-dialog
        v-model="documentsDialog"
        max-width="750px"
      >
        <v-card>
          <v-card-title>
            <span class="text-h5">Creación de Documento / Nota</span>
          </v-card-title>

          <div style="padding: 30px">
            <DocumentosNotasModal 
              :accionGuardar="accionGuardar"
              :cuota="cuotaId"
            />
          </div>
          
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="documentsDialog = false"
            >
              Cerrar
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="accionGuardar = true, documentsDialog = false"
            >
              Guardar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-app>
</template>

<script>
import moment from 'moment';
import Navigation from "../../../components/Nav.vue";
import { mainAxios } from '../../../mainAxios';
import DocumentosNotasModal from '../../components/DocumentosNotasModal.vue';
import EmailForm from '../../components/EmailForm.vue';

export default {
  components: {
    Navigation,
    DocumentosNotasModal,
    EmailForm,
  },
  computed:{
    canViewDesglose(){
      var canView = false;
      var roles=[]
      this.roles.forEach((element) => {
        roles.push(element.rol)
      });
      const rolesToView = ["ADMIN", "DIRECTOR", "FINANZAS"];
      roles.forEach((element)=>{
        if(rolesToView.includes(element)){
          canView=true;
        }
        if(this.comision[0]){
          if(element == 'AGENTE' && this.comision[0].statusComision=="Pendiente de Pago al Agente"){
            canView=true;
          }
        }
      })
    
      
      return canView;
    },
    canEdit(){
      var canView = false;
      var roles=[]
      this.roles.forEach((element) => {
        roles.push(element.rol)
      });
      const rolesToView = [
        "ADMIN",
        "ADMINCC",
        "AGENTCC",
        "FINANZAS",
        "MESADECONTROL",
        "OPERACIONES",
        "OPERADOR",
        "MANAGERCC",
        "COBRANZACC"
      ];
      roles.forEach((element)=>{
        if(rolesToView.includes(element)){
          canView=true;
        }
        if(this.comision[0]){
          if(element == 'AGENTE' && this.comision[0].statusComision=="Pendiente de Pago al Agente"){
            canView=true;
          }
        }
      })
      return canView;
    }
  },
  data() {
    return {
      newCorreoDialog: false,
      dialogCorreo:false,
      documentsDialog: false,
      dialogDetalleDocumentos: false,
      detallesDocumento: {},
      correoOpen:{},
      accionGuardar: false,
      roles: JSON.parse(localStorage.getItem('roles')),
      stepper: 1,
      columns: [
        {
          text: "Asunto",
          align: "start",
          sortable: false,
          value: "asunto",
        },
        { text: "Fecha", value: "fecha" },
        { text: "Para", value: "remitente" },
      ],
      columnsDocumentos: [
        {
          text: "Nombre",
          align: "start",
          sortable: false,
          value: "nombre",
        },
        { text: "Tipo", value: "tipo" },
        { text: "Descripción", value: "descripcion" },
        { text: "Creado por", value: "creadoPor" },
        { text: "Fecha", value: "fecha" },
        { text: "Ver", value: "url" },
      ],
      comision: [],
      documentos: [],
      correos: [],
      correoAgente: null,
      creadoPor: null,
      fechaCreacion: null,
      fechaPagoCuota: null,
      modificadoPor: null,
      fechaModificacion: null,
      fechaPagoAcordada: null,
      fechaLimitePago: null,
      cuotaId: null,
      tipoItems: [
        "Nota",
        "Documento"
      ],
      tipoDocumentoItems: [
        "Acta constitutiva",
        "Aviso de privacidad firmado",
        "Caratula de afiliación",
        "Comprobante de Domicilio",
        "Comprobante Médico",
        "Constancia de situación fiscal",
        "Caratula de estado de cuenta",
        "Cotización",
        "Cédula del Agente",
        "Identificación oficial",
        "Documento de afiliación",
        "Poder Notarial",
        "Póliza",
        "RC de Agente",
        "Tarjeta de Circulación",
        "Otro",
      ],
    }
  },
  methods: {
    goBack() {
      return this.$router.go(-1);
    },
    verDetalleCorreo(correo){
      console.log("Correo open:", correo);
      this.dialogCorreo=true;
      this.correoOpen=correo;
    },
    verDocumento(documento) {
      this.dialogDetalleDocumentos = true
      this.detallesDocumento = documento
    },
    obtenerDatosComision() {
      let config = {
        headers: {
          Authorization: 'Bearer ' + localStorage.agenteAccessToken,
        },
      };
      mainAxios.get(`/v1/cuota/uuid/${this.$route.params.uuid}`, config).then(response => {
        this.comision.push(response.data);
        this.fechaCreacion = moment.utc(response.data.fechaCreacion).subtract(6, 'hours').format('DD/MM/YYYY HH:mm')
        this.modificadoPor = response.data.modificadoPor
        this.fechaModificacion = moment.utc(response.data.ultimaModificacion).subtract(6, 'hours').format('DD/MM/YYYY HH:mm')
        this.fechaPagoAcordada = this.comision[0].fechaPagoAcordada ? moment(this.comision[0].fechaPagoAcordada, 'YYYY-MM-DD').format('DD/MM/YYYY') : null
        this.fechaLimitePago = this.comision[0].fechaLimitePago ? moment(this.comision[0].fechaLimitePago, 'YYYY-MM-DD').format('DD/MM/YYYY') : null
        this.fechaPagoCuota = response.data.fechaPago ? moment(response.data.fechaPago, 'YYYY-MM-DD').format('DD/MM/YYYY') : null
        this.cuotaId = response.data.id
        this.creadoPor = response.data.creadoPor ? response.data.creadoPor : 'No Disponible'
        this.correoAgente = response.data.correoAgente ? response.data.correoAgente : null

        //Obtener documentos
        response.data.documentosCarga ? response.data.documentosCarga.map(element => {
          this.documentos.push({
            nombre: element.nombre ? element.nombre : 'No Disponible',
            fecha: element.fechaCreacion ? moment.utc(element.fechaCreacion).subtract(6, 'hours').format('DD/MM/YYYY HH:mm') : 'No Disponible',
            tipo: element.tipoRegistro ? element.tipoRegistro : 'No Disponible',
            tipoDocumento: element.tipoDocumento ? element.tipoDocumento : 'No Disponible',
            descripcion: element.nota ? element.nota : 'No Disponible',
            creadoPor: element.creadoPor ? element.creadoPor : 'No Disponible',
            url: element.url_bucket
          })
        }) : ''

        //Obtener Mails
        response.data.correos ? response.data.correos.map(element => {
          this.correos.push({
            asunto: element.subject,
            fecha: element.fecha ? moment.utc(element.fecha).subtract(6, 'hours').format('DD/MM/YYYY HH:mm') : null,
            cuerpo:element.cuerpo,
            remitente: element.remitente,
            documentos: element.documentos,
            to: element.to,
            cc: element.cc,
            bcc: element.bcc
          })
        }) : ''
      })
    },
    verEditar() {
      this.$router.push(`/editar-comisiones/${this.$route.params.uuid}`)
    }
  },
  mounted() {
    this.obtenerDatosComision()
  }
}
</script>

<style>
  .v-stepper__step__step {
    color: transparent !important;
    display: none !important;
  }

  .enlacesForms input {
    color: red !important;
    cursor: pointer;
  }
</style>
