<template>
  <v-app>
    <Navigation></Navigation>
    <v-card max-width>
      <v-list three-line style="padding-left: 20px; padding-right: 15px">
        <template v-for="(item, index) in notificaciones">
          <v-subheader
            v-if="item.header"
            :key="item.id"
            v-text="item.header"
          ></v-subheader>

          <v-divider
            v-else-if="item.divider"
            :key="index"
            :inset="item.inset"
          ></v-divider>

          <v-list-item v-else :key="item.id">
            <v-list-item-avatar>
              <v-icon large>mdi-email</v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title v-html="item.title"></v-list-item-title>
              <v-list-item-subtitle
                v-html="item.creadoPor"
                class="text--primary"
              ></v-list-item-subtitle>
              <v-list-item-subtitle
                v-html="item.subtitle"
              ></v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-card>
  </v-app>
</template>

<script>
import Navigation from "../../../components/Nav.vue";
import { mainAxios } from '../../../mainAxios';

export default {
  components: {
    Navigation,
  },

  data: () => ({
    items: [
      { header: "Today" },
      {
        avatar: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
        title: "Brunch this weekend?",
        subtitle: `<span class="text--primary">Ali Connors</span> &mdash; I'll be in your neighborhood doing errands this weekend. Do you want to hang out?`,
      },
      { divider: true, inset: true },
      {
        avatar: "https://cdn.vuetifyjs.com/images/lists/2.jpg",
        title: 'Summer BBQ <span class="grey--text text--lighten-1">4</span>',
        subtitle: `<span class="text--primary">to Alex, Scott, Jennifer</span> &mdash; Wish I could come, but I'm out of town this weekend.`,
      },
      { divider: true, inset: true },
      {
        avatar: "https://cdn.vuetifyjs.com/images/lists/3.jpg",
        title: "Oui oui",
        subtitle:
          '<span class="text--primary">Sandra Adams</span> &mdash; Do you have Paris recommendations? Have you ever been?',
      },
      { divider: true, inset: true },
      {
        avatar: "https://cdn.vuetifyjs.com/images/lists/4.jpg",
        title: "Birthday gift",
        subtitle:
          '<span class="text--primary">Trevor Hansen</span> &mdash; Have any ideas about what we should get Heidi for her birthday?',
      },
      { divider: true, inset: true },
      {
        avatar: "https://cdn.vuetifyjs.com/images/lists/5.jpg",
        title: "Recipe to try",
        subtitle:
          '<span class="text--primary">Britta Holt</span> &mdash; We should eat this: Grate, Squash, Corn, and tomatillo Tacos.',
      },
    ],
    notificaciones: [],
  }),

  methods: {
    obtenerNotificaciones() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };

      mainAxios.get(`/v1/agente/notificaciones/${localStorage.agenteUuid}`, config).then((response) => {
        console.log(response);
        this.notificaciones.push({ header: "Today" })
        response.data.map( element => {
          this.notificaciones.push({
            title: element.titulo,
            creadoPor: element.creadoPor,
            subtitle: element.subtitulo,
            fecha: element.fecha,
            id: element.id,
          },
          {
            divider: true,
            inset: true,
          })
        })
      });
    },
  },

  mounted() {
    this.obtenerNotificaciones()
  }
};
</script>
