<template>
  <v-app>
    <Navigation></Navigation>
    <v-main style="background: #f6f9ff;">
      <v-container>
        <div v-if="shadow" class="v-overlay v-overlay--active theme--dark" style="z-index: 201;"><div class="v-overlay__scrim" style="opacity: 0.46; background-color: rgb(33, 33, 33); border-color: rgb(33, 33, 33);"></div><div class="v-overlay__content"></div></div>
        <v-row class="mb-15 fill-height">
          <v-col>
            <v-row>
              <v-col>
                <h1>Cotizaciones Celular</h1>
              </v-col>
            </v-row>
            <v-row class="mb-15" style="padding-left: 30px">
              <v-col xl="3" md="12" xs="12"  >
                <v-card
                    elevation="10"
                    class="mx-auto"
                    width="full"
                  >
                  <v-card-text>
                    <h1 class="pb-5">Filtro </h1>  
                    <v-select
                      label="Canal"
                      outlined
                      style="max-width: 420px"
                      class="mt-5"
                      :items="canales"
                      item-text="name"
                      item-value="id"
                      v-model="canal"
                      v-show="canShow"
                    ></v-select>
                    <h3>Selecciona el rango de fechas</h3>
                    <v-date-picker
                      style="width: 100%"
                      v-model="dates"
                      range
                    ></v-date-picker>
                    <div class="mt-5">
                        <v-btn
                          rounded
                          color="primary"
                          style="width: 100%"
                          dark
                          @click="consultar"
                          :disabled="!blockBoton"
                          >Consultar</v-btn
                        >
                        <v-btn
                          rounded
                          color="primary"
                          style="width:100%;margin-top:20px;"
                          :disabled="bloquear"
                          ><download-csv
                            :data="reportDownload"
                            name= "Reporte_cotizaciones_celular.csv"
                          >
                          Descargar Información
                          </download-csv></v-btn>
                      </div>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col  xl="9" md="12" xs="12" >
                  <v-card
                    elevation="10"
                    class="mx-auto"
                    width="full"
                  >
                  <v-card-text>
                    <div v-if="cotizaciones.length > 0">
                      <h1 class="pb-5">Mis cotizaciones celular</h1>  
                      <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Buscar"
                        single-line
                        hide-details
                      ></v-text-field>
                      <v-data-table
                        :headers="headers"
                        :items="cotizaciones"
                        item-key="numeroCotizacion"
                        class="elevation-1 mt-3"
                        :search="search"
                        :loading="cargando"
                        loading-text="Cargando cotizaciones... Por favor espera"
                        >  
                        <template v-slot:item.uuid="{item}">
                          <v-btn color="primary" @click="abrirdetalles(item.uuid)">Ver Cotización</v-btn>
                        </template>  
                      </v-data-table>
                    </div>
                    <div v-else>
                      <div class="svg-border-waves text-white">
                        <center>
                          <img src="~@/assets/img/undraw_calculator_re_alsc.svg" style="width: 47%;"/>
                        </center>
                      </div>
                      <h3 class="pb-5 pt-5" style="text-align: center;">Sin informacion </h3>  
                      
                    </div>

                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <div justify="center" >
          <v-dialog
            v-model="dialog"
            fullscreen
            content-class="v-dialogCustom"
            transition="dialog-bottom-transition"
          >
            <v-card>
              <v-toolbar
                dark
                color="primary"
              >
                <v-btn
                  icon
                  dark
                  @click="dialog = false"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>Detalles de la solicitud</v-toolbar-title>
              </v-toolbar>
              <v-list
                three-line
                subheader
              >
                <v-list-item>
                  <v-list-item-content>
                    <center>
                   <b> <v-list-item-title style="font-size: 30px;">Contrata tu Seguro Celular por: <em style="color: #fe5000;"> {{ toCurrency(cotizacion.total) }} </em> /  al año</v-list-item-title></b>   
                    </center>
                    <v-divider></v-divider>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item class="pt-6">
                  <v-list-item-content>
                    <v-list-item-title>
                     <b> Modelo </b> 
                    </v-list-item-title>
                    <v-list-item-subtitle> 
                       <b> {{ cotizacion.modelo }} </b> 
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-content>
                    <v-list-item-title>
                     <b>Costo del celular: </b> 
                    </v-list-item-title>
                    <v-list-item-subtitle> 
                        <b style="color: #fe5000;" >{{cotizacion.precioFactura ? toCurrency(cotizacion.precioFactura) : toCurrency(0) }} </b> 
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-content>
                    <v-list-item-title>
                      <b>Contacto: </b> 
                    </v-list-item-title>
                    <v-list-item-subtitle> 
                     <b>   
                        {{
                            cotizacion.nombre +
                            " " +
                            cotizacion.apellidoPaterno +
                            " " +
                            cotizacion.apellidoMaterno
                          }}
                      </b>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item class="pt-6 pb-6">
                  <v-list-item-content>
                    <v-list-item-title>
                     <b>  Celular: </b>
                    </v-list-item-title>
                    <v-list-item-subtitle> 
                       <b> {{ cotizacion.telefono }} </b> 
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-content>
                    <v-list-item-title>
                      <b> Correo:</b> 
                    </v-list-item-title>
                    <v-list-item-subtitle> 
                       <b> {{ cotizacion.email }} </b> 
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-content>
                    <v-list-item-title>
                      <b>Código Cotización:</b> 
                    </v-list-item-title>
                    <v-list-item-subtitle> 
                        <b> {{ cotizacion.cotizacionGeneral }} </b> 
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-divider></v-divider>
              <v-card-actions style="justify-content: end; padding-right: 4%;">
              <v-btn
                color="primary"
                text
                @click="openEmitirDialog()"
              >
                Emitir
              </v-btn>
              <v-btn
                color="primary"
                text
                @click="dialog = false"
              >
                Cerrar
              </v-btn>
            </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </v-container>
    </v-main>
    <v-footer fixed style="text-align:center; background: #f6f9ff;">
      <p style="text-align:center;width:100%;">Segurify 2022</p>
    </v-footer>
    <emitir-modal @close_dialog="closeDialogEmitir" :open_dialog="open_dialog" :cotizacion_uuid="cotizacion_uuid"></emitir-modal>
  </v-app>
</template>

<script>
import EmitirModal from './EmitirModal.vue';
import Navigation from "../../../components/Nav.vue";
import {mainAxios} from "../../../mainAxios";
export default {
  components: {
    Navigation,
    EmitirModal
  },
    data () {
      return {
        roles: JSON.parse(localStorage.getItem('roles')),
        agenteUuid: localStorage.getItem('agenteUuid'),
        cotizaciones:[],
        reportDownload:[],
        dates: ["", ""],
        attachDialog: true,
        cotizacion_uuid: "",
        bloquear: true,
        dialog: false,
        open_dialog: false,
        canales: [
          {id: 1, name: 'AGENTES'},
          {id: 2, name: 'MASIVOS'},
          {id: 3, name: 'DIRECTOS'}
        ],
        canal: null,
        headers: [
          {
            text: 'Codigo',
            align: 'start',
            sortable: false,
            value: 'codigo',
          },
          {
            text: 'Fecha de cotización',
            value: 'fecha_cotizacion',
          },  
          {
            text: 'Modelo',
            value: 'modelo',
          },  
          {
            text: 'Precio factura',
            value: 'precio_factura',
          },                     
          {
            text: 'Cliente',
            value: 'cliente',
          },      
          {
            text: 'Agente',
            value: 'agente',
          },    
          {
            text: 'Estado',
            value: 'estado',
          }, 
          {
            text: 'Costo seguro',
            value: 'costoSeguro',
          }, 
          {
            text: 'Forma de pago',
            value: 'formaPago',
          }, 
          {
            text: 'Ver',
            value: 'uuid',
          },
        ],
        search: '',
        calories: '',
        cargando:true,
        cotizacion:[],
      }
    },
    
    mounted () {
      //this.getCotizaciones();
    },
    methods: {
    toCurrency(number) {
      var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });
      return formatter.format(number); /* $2,500.00 */
    },
    closeDialogEmitir() {
      this.open_dialog = false;
    },
    openEmitirDialog(){
      this.dialog = false;
      this.open_dialog = true;
    },
    async abrirdetalles(uuid){
      const config = {
          headers: {
            Authorization: "Bearer " + localStorage.agenteAccessToken,
          },
        };
        const resp = await mainAxios.get(`/v1/seguro-celular/uuid/${uuid}`,config);
        this.cotizacion_uuid = uuid;
        this.cotizacion = resp.data;
        this.dialog = true;
    },
    consultar(){
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios.post(`/v1/seguro-celular/${this.canal}/${localStorage.agenteUuid}`,{
            "rango_Fechas": this.dates,
          }, config)
          .then((response) => {
            this.cotizaciones = [];
            this.reportDownload = [];
            response.data.map(element => {
              this.cotizaciones.push({
                codigo: element.codigo,
                fecha_cotizacion: element.fecha_cotizacion,
                modelo: element.modelo,
                precio_factura: element.precio_factura,
                cliente: element.cliente,
                agente: element.agente,
                estado: element.estado,
                costoSeguro: element.costoSeguro,
                formaPago: element.formaPago,
                uuid: element.uuidCotizacion
              })
            })
            this.reportDownload = this.cotizaciones;
            this.cargando=false;
            if(this.reportDownload.length >0){
              this.bloquear=false;
            }
          });
        },
    },
    computed: {
      blockBoton() {
      return(
          this.canal != null &&
          this.dates != ["", ""]
      )   
      },
      canShow(){
        var canView = false;
        var roles=[]
        this.roles.forEach((element) => {
          roles.push(element.rol)
        });
        const rolesToView = [
          "ADMIN",
          "DIRECTOR",
          "FINANZAS",
        ];
        roles.forEach((element)=>{
          if(rolesToView.includes(element)){
            canView=true;
          }
        })

        roles.forEach((element)=>{
          if(element == 'AGENTE'){
            this.canal = 1;
          }
        })

        return canView;
      },
      shadow() {
        if(this.dialog){
          return true;
        }else
        {
          return false;
        }
      },
    }

};
</script>

<style>

.v-list-item__title {
    align-self: center;
    font-size: 1.5rem;
}
.v-list-item__subtitle {
    font-size: 1rem;
}
.v-dialogCustom {
    padding-left: 45% !important;
    border-radius: 4px !important;
    margin: 0px !important;
    overflow-y: auto !important;
    pointer-events: auto !important;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) !important;
    width: 100% !important;
    z-index: inherit !important;
    box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 20%), 0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 0px 0px 0px rgb(0 0 0 / 12%) !important;
}

@media (max-width: 992px) {
  .v-dialogCustom {
      padding-left: 0% !important;
      border-radius: 4px !important;
      margin: 0px !important;
      overflow-y: auto !important;
      pointer-events: auto !important;
      transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) !important;
      width: 100% !important;
      z-index: inherit !important;
      box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 20%), 0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 0px 0px 0px rgb(0 0 0 / 12%) !important;
  }
  .v-list-item__title{
      font-size: 18px !important;
  }
  .v-list-item__subtitle{
      font-size: 13px !important;
  }
}

</style>