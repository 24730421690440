import moment from "moment-timezone";

export const formatServerTimezone = (
  serverDateTime,
  format = "DD/MM/YYYY HH:mm"
) =>
  moment(serverDateTime)
    .subtract(6, "hours")
    .format(format);

export const formatToServerTimezone = (
  clientDateTime,
  format = "YYYY-MM-DD 00:00:00"
) =>
  moment(clientDateTime)
    .tz("America/Mexico_City", true)
    .tz("UTC")
    .format(format);
