<template>
  <v-app>
    <Navigation></Navigation>
    <v-main class="mb-15">
      <v-container fluid>
        <v-col cols="12">
          <h1 style="padding-left: 15px">Usuarios</h1>
        </v-col>

        <v-row style="padding: 30px">
          <v-col cols="8">
            <v-row>
              <v-col cols="4">
                <v-text-field
                  name="name"
                  label="Nombre(s)"
                  :rules="generalRules"
                  v-model="usuario.nombre"
                ></v-text-field>
              </v-col>

              <v-col cols="4">
                <v-text-field
                  name="apellidoPaterno"
                  label="Apellido Paterno"
                  :rules="generalRules"
                  v-model="usuario.apellidoPaterno"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  name="apellidoMaterno"
                  label="Apellido Materno"
                  v-model="usuario.apellidoMaterno"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="4">
                <v-select
                  v-model="usuario.sexo"
                  :items="sexoList"
                  item-text="sexo"
                  item-value="id"
                  label="Género"
                  required
                  :rules="generalRules"
                ></v-select>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="usuario.fechaNacimiento"
                  label="Fecha de Nacimiento"
                  placeholder="dd/mm/aaaa"
                  v-mask="'##/##/####'"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-select
                  item-text="estadoRepublica"
                  item-value="id"
                  v-model="usuario.nacimientoEstadoId"
                  :items="estados"
                  :rules="generalRules"
                  placeholder="Seleccionar Estado de nacimiento"
                ></v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="4">
                <v-text-field
                  name="telefono"
                  label="Teléfono"
                  placeholder="##########"
                  v-mask="'##########'"
                  :rules="generalRules"
                  v-model="usuario.telefono"
                ></v-text-field>
              </v-col>

              <v-col cols="4">
                <v-text-field
                  label="Celular"
                  placeholder="##########"
                  v-mask="'##########'"
                  v-model="usuario.celular"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="4">
                <v-text-field
                  id="rfc"
                  v-model="usuario.rfc"
                  label="RFC"
                  type="text"
                  required
                  placeholder="XAXX010101000"
                  :rules="rulesRfc"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  autocomplete="new-password"
                  label="Contraseña"
                  v-model="usuario.password"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPassword ? 'text' : 'password'"
                  @click:append="showPassword = !showPassword"
                ></v-text-field>
              </v-col>
              <v-col
                cols="4"
                v-if="
                  usuario.rol != 'AGENT' &&
                    usuario.rol != 'AGENTASEG' &&
                    usuario.rol != 'AGENTPARTNER'
                "
              >
                <v-text-field
                  v-model="usuario.fecha_ingreso"
                  label="Fecha de Ingreso"
                  placeholder="dd/mm/aaaa"
                  v-mask="'##/##/####'"
                ></v-text-field>
              </v-col>
            </v-row>

            <!-- <v-col cols="4">
                <v-text-field
                  label="Password"
                  v-model="usuario.confirmPassword"
                  :append-icon="showPassword2 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPassword2 ? 'text' : 'password'"
                  @click:append="showPassword2 = !showPassword2"
                ></v-text-field>
              </v-col> -->
            <!-- <v-col cols="4">
                <v-select
                  label="Status"
                  v-model="usuario.status"
                  :items="statusItems"
                ></v-select>
              </v-col> -->
            <!-- <v-col cols="4">
                <v-text-field
                  label="Motivo Baja"
                ></v-text-field>
              </v-col> -->

            <v-row>
              <v-col cols="4">
                <v-autocomplete
                  v-model="usuario.rol"
                  :items="currentRoles"
                  label="Rol"
                  item-text="nombre"
                  item-value="id"
                  :rules="generalRules"
                ></v-autocomplete>
              </v-col>
              <v-col cols="4">
                <v-select
                  v-model="usuario.status"
                  :items="status"
                  label="Estatus"
                  item-text="name"
                  item-value="id"
                  :rules="generalRules"
                ></v-select>
              </v-col>
              <v-col
                cols="4"
                v-if="
                  usuario.rol == 'AGENTCC' ||
                    usuario.rol == 'AGENTCCSPONSORS' ||
                    usuario.rol == 'MESADECONTROL' ||
                    usuario.rol == 'COBRANZACC' ||
                    usuario.rol == 'RENOVACIONESCC' ||
                    usuario.rol == 'MANAGERCC' ||
                    usuario.rol == 'OPERADOR'
                "
              >
                <v-select
                  label="Turno"
                  v-model="usuario.turno"
                  :items="['Matutino', 'Vespertino', 'Completo']"
                  :rules="generalRules"
                  placeholder="Seleccionar turno"
                ></v-select>
              </v-col>
              <v-col cols="4" v-if="usuario.status == 'Baja'">
                <v-select
                  v-model="usuario.motivoBaja"
                  :items="motivoBajaItems"
                  label="Motivo de Baja"
                  :rules="generalRules"
                ></v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="4">
                <v-select
                  v-model="usuario.ramo"
                  :items="ramoList"
                  item-text="ramo"
                  item-value="id"
                  label="Ramo"
                  multiple
                >
                  <template v-slot:selection="{ item, index }">
                    <v-chip color="primary" v-if="index === 0">
                      <span>{{ item.ramo }}</span>
                    </v-chip>
                    <span v-if="index === 1" class="grey--text text-caption">
                      (+{{ usuario.ramo.length - 1 }} más)
                    </span>
                  </template>
                </v-select>
              </v-col>
              <v-col cols="4">
                <v-select
                  v-model="usuario.canal"
                  :items="canalList"
                  label="Canal"
                  item-text="canal"
                  item-value="id"
                  multiple
                >
                  <template v-slot:selection="{ item, index }">
                    <v-chip color="primary" v-if="index === 0">
                      <span>{{ item.canal }}</span>
                    </v-chip>
                    <span v-if="index === 1" class="grey--text text-caption">
                      (+{{ usuario.canal.length - 1 }} más)
                    </span>
                  </template>
                </v-select>
              </v-col>
              <v-col
                cols="4"
                v-if="
                  usuario.rol == 'BACKOFFICEINBOUND'
                "
              >
                <v-autocomplete
                  v-model="usuario.empresas"
                  :items="empresas"
                  label="Empresas"
                  item-value="id"
                  item-text="empresa"
                  multiple
                >
                  <template v-slot:selection="{ item, index }">
                    <v-chip color="primary" v-if="index === 0">
                      <span>{{ item.empresa }}</span>
                    </v-chip>
                    <span v-if="index === 1" class="grey--text text-caption">
                      (+{{ usuario.empresas.length - 1 }} más)
                    </span>
                  </template>
                </v-autocomplete>
              </v-col>

              
              <v-col
                cols="4"
                v-if="
                  usuario.rol == 'AGENTCC' ||
                    usuario.rol == 'AGENTCCSPONSORS' ||
                    usuario.rol == 'MANAGERCC' ||
                    usuario.rol == 'ADMINCC' ||
                    usuario.rol == 'COBRANZACC' ||
                    usuario.rol == 'RENOVACIONESCC' ||
                    usuario.rol == 'OPERADORCC'
                "
              >
                <v-select
                  v-model="usuario.landing"
                  :items="landings"
                  label="Producto"
                  item-value="id"
                  item-text="landing"
                  multiple
                >
                  <template v-slot:selection="{ item, index }">
                    <v-chip color="primary" v-if="index === 0">
                      <span>{{ item.name }}</span>
                    </v-chip>
                    <span v-if="index === 1" class="grey--text text-caption">
                      (+{{ usuario.landing.length - 1 }} más)
                    </span>
                  </template>
                </v-select>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12">
                <ListadoCorreos
                  ref="listadoCorreos"
                  :idAgente="idAgente"
                  :canalesAgente="usuario.canal"
                  :rolAgente="usuario.rol"
                  @correoFavoritoModificado="correoFavoritoModificado"
                ></ListadoCorreos>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="4">
                <v-checkbox label="VIP" v-model="usuario.vip"></v-checkbox>
              </v-col>
            </v-row>

            <!-- ONLY IF ROL = AGENTE -->
            <v-row v-if="mostrarInputsAgente()">
              <v-col cols="4">
                <v-select
                  label="Fuente de Ingreso"
                  v-model="usuarioAgente.fuenteIngreso"
                  :items="fuenteIngresoItems"
                  :rules="generalRules"
                ></v-select>
              </v-col>
              <v-col cols="4">
                <v-select
                  label="Régimen"
                  v-model="usuarioAgente.regimen"
                  :items="regimenItems"
                  :rules="generalRules"
                ></v-select>
              </v-col>
              <v-col cols="4">
                <v-select
                  label="Agente asociado a una promotoria"
                  v-model="usuarioAgente.asociadoPromotoria"
                  :items="asociadoPromotoriaItems"
                  item-text="name"
                  item-value="id"
                  multiple
                >
                  <template v-slot:selection="{ item, index }">
                    <v-chip color="primary" v-if="index === 0">
                      <span>{{ item.name }}</span>
                    </v-chip>
                    <span v-if="index === 1" class="grey--text text-caption">
                      (+{{ usuarioAgente.asociadoPromotoria.length - 1 }} más)
                    </span>
                  </template>
                </v-select>
              </v-col>
            </v-row>

            <v-row v-if="mostrarInputsAgente()">
              <v-col cols="4">
                <v-select
                  label="Tipo de Agente"
                  v-model="usuarioAgente.tipoAgente"
                  :items="tipoAgenteItems"
                  :rules="generalRules"
                ></v-select>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  label="Razón Social"
                  v-model="usuarioAgente.razonSocial"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  label="Clabe Bancaria para transferencia"
                  v-model="usuarioAgente.clabeBancaria"
                  :rules="generalRules"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row v-if="mostrarInputsAgente()">
              <v-col cols="4" v-if="usuarioAgente.tipoAgente != 'Comisionista'">
                <v-text-field
                  label="Clave del Agente"
                  v-model="usuarioAgente.claveAgente"
                  :rules="generalRules"
                ></v-text-field>
              </v-col>
              <v-col cols="4" v-if="usuarioAgente.tipoAgente != 'Comisionista'">
                <v-select
                  label="Tipo de Cédula"
                  v-model="usuarioAgente.tipoCedula"
                  :items="tipoCedulaItems"
                  :rules="generalRules"
                ></v-select>
              </v-col>
              <v-col cols="4">
                <v-select
                  label="Nivel Agente"
                  v-model="usuarioAgente.nivelAgente"
                  :items="nivelAgenteItems"
                  :rules="generalRules"
                ></v-select>
              </v-col>
            </v-row>

            <v-row v-if="mostrarInputsAgente()">
              <v-col cols="4" v-if="usuarioAgente.tipoAgente != 'Comisionista'">
                <v-text-field
                  label="Número de Cartera"
                  v-model="usuarioAgente.numeroCartera"
                  :rules="generalRules"
                  type="number"
                ></v-text-field>
              </v-col>
              <v-col cols="4" v-if="usuarioAgente.tipoAgente != 'Comisionista'">
                <v-text-field
                  label="Cedula"
                  v-model="usuarioAgente.cedula"
                  :rules="generalRules"
                ></v-text-field>
              </v-col>
              <v-col cols="4" v-if="usuarioAgente.tipoAgente != 'Comisionista'">
                <v-text-field
                  label="RC"
                  v-model="usuarioAgente.rc"
                  :rules="generalRules"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row v-if="mostrarInputsAgente()">
              <v-col cols="4" v-if="usuarioAgente.tipoAgente != 'Comisionista'">
                <v-text-field
                  label="Vigencia de Cédula"
                  v-model="usuarioAgente.vigenciaCedula"
                  placeholder="Requerido(dd/mm/aaaa)"
                  v-mask="'##/##/####'"
                  :rules="generalRules"
                ></v-text-field>
              </v-col>
              <v-col cols="4" v-if="usuarioAgente.tipoAgente != 'Comisionista'">
                <v-text-field
                  label="Vigencia póliza RC"
                  v-model="usuarioAgente.vigenciaPolizaRC"
                  placeholder="Requerido(dd/mm/aaaa)"
                  v-mask="'##/##/####'"
                  :rules="generalRules"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row class="mt-2">
              <v-col cols="6">
                <v-text-field label="Creado por" readonly></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field label="Fecha de creación" readonly></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="6">
                <v-text-field label="Modificado por" readonly></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Fecha de modificacion"
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>

            <v-col cols="12" style="text-align: left;">
              <v-btn
                color="primary"
                @click="setNewUser()"
                class="mr-4"
                :disabled="validador"
              >
                Crear
              </v-btn>
            </v-col>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <v-footer fixed style="text-align:center;">
      <p style="text-align:center;width:100%; margin: 10px 0px">
        Segurify 2021
        <a target="_blank" href="/terminos-y-condiciones"
          >Terminos y condiciones</a
        >
        y <a target="_blank" href="/aviso-de-privacidad">Aviso de privacidad</a>
      </p>
    </v-footer>
  </v-app>
</template>
<script>
import { mainAxios } from "../../../../mainAxios";
import Navigation from "../../../../components/Nav.vue";
import moment from "moment";
import RfcFacil from "rfc-facil";

import ListadoCorreos from "../../../../components/listadoCorreos/ListadoCorreos.vue";
import { getEmpresasInbound } from "@/services/empresasInbound/EmpresasInbound.js";

export default {
  components: {
    Navigation,
    ListadoCorreos,
  },
  data() {
    return {
      rol: JSON.parse(localStorage.getItem("roles"))[0].rol,
      roles: [],
      showPassword: false,
      showPassword2: false,
      usuario: {
        nombre: null,
        apellidoPaterno: null,
        apellidoMaterno: null,
        rfc: null,
        curp: null,
        password: null,
        confirmPassword: null,
        sexo: null,
        fechaNacimiento: null,
        rol: null,
        nacimientoEstadoId: null,
        celular: null,
        telefono: null,
        correo: null,
        status: 0,
        motivoBaja: null,
        ramo: null,
        canal: null,
        landing: null,
        vip: false,
        productos: [],
        turno: null,
        fecha_ingreso: null,
        empresas:[]
      },
      usuarioAgente: {
        fuenteIngreso: null,
        regimen: null,
        asociadoPromotoria: null,
        tipoAgente: null,
        razonSocial: null,
        clabeBancaria: null,
        claveAgente: null,
        tipoCedula: null,
        nivelAgente: null,
        numeroCartera: null,
        cedula: null,
        rc: null,
        vigenciaCedula: null,
        vigenciaPolizaRC: null,
      },
      sexoList: [
        { id: 1, sexo: "Masculino" },
        { id: 2, sexo: "Femenino" },
      ],
      generalRules: [(v) => !!v || "Campo es necesario"],
      emailRules: [
        (v) => !!v || "E-mail es necesario",
        (v) =>
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || "E-mail no es valido",
      ],
      rulesRfc: [
        (v) => !!v || "Este campo es requerido",
        (v) => {
          if (v == null || v.trim() == "") {
            return true;
          }
          let value = v.toUpperCase();
          // hay un error en la validacion de a rfc
          const regex = /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?(([A-Z\d]{2})([A\d])|)$/;
          let correcto = value.match(regex); //SE CREA UN OBJETO CON LA EXPRESIÓN REGULAR

          return !!correcto || "La RFC no es valida";
        },
      ],
      curpRules: [
        (v) => !!v || "CURP es necesario",
        (v) =>
          /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/.test(
            v
          ) || "CURP no es valido recordar escribir en mayusculas",
      ],
      estados: [
        { id: 1, estadoRepublica: "Aguascalientes" },
        { id: 2, estadoRepublica: "Baja California" },
        { id: 3, estadoRepublica: "Baja California Sur" },
        { id: 4, estadoRepublica: "Campeche" },
        { id: 5, estadoRepublica: "Coahuila de Zaragoza" },
        { id: 6, estadoRepublica: "Colima" },
        { id: 7, estadoRepublica: "Chiapas" },
        { id: 8, estadoRepublica: "Chihuahua" },
        { id: 9, estadoRepublica: "Ciudad de México" },
        { id: 10, estadoRepublica: "Durango" },
        { id: 11, estadoRepublica: "Guanajuato" },
        { id: 12, estadoRepublica: "Guerrero" },
        { id: 13, estadoRepublica: "Hidalgo" },
        { id: 14, estadoRepublica: "Jalisco" },
        { id: 15, estadoRepublica: "Estado de México" },
        { id: 16, estadoRepublica: "Michoacán de Ocampo" },
        { id: 17, estadoRepublica: "Morelos" },
        { id: 18, estadoRepublica: "Nayarit" },
        { id: 19, estadoRepublica: "Nuevo León" },
        { id: 20, estadoRepublica: "Oaxaca" },
        { id: 21, estadoRepublica: "Puebla" },
        { id: 22, estadoRepublica: "Querétaro" },
        { id: 23, estadoRepublica: "Quintana Roo" },
        { id: 24, estadoRepublica: "San Luis Potosí" },
        { id: 25, estadoRepublica: "Sinaloa" },
        { id: 26, estadoRepublica: "Sonora" },
        { id: 27, estadoRepublica: "Tabasco" },
        { id: 28, estadoRepublica: "Tamaulipas" },
        { id: 29, estadoRepublica: "Tlaxcala" },
        { id: 30, estadoRepublica: "Veracruz" },
        { id: 31, estadoRepublica: "Yucatán" },
        { id: 32, estadoRepublica: "Zacatecas" },
        { id: 33, estadoRepublica: "Desconocio" },
      ],
      statusItems: ["Nuevo", "Activo"],
      canalList: [],
      ramoList: [],
      campaniaList: [],
      status: [
        { id: 0, name: "Nuevo" },
        { id: 1, name: "Activo" },
        { id: 2, name: "Inactivo" },
        { id: -1, name: "Baja" },
      ],
      motivoBajaItems: [
        "Encontró trabajo",
        "Falta de pago de comisiones",
        "Falta de producción",
        "Falta de seguimiento del área comercial",
        "Falta de seguimiento del área de operaciones",
        "Inconformidad con el proceso de capacitación",
        "Inconformidad con la oferta de valor",
        "Exceso de faltas",
        "Ingreso a escuela",
        "Temas de salud",
        "Otro",
      ],
      canalItems: ["Agentes", "Directo", "Masivos"],
      landings: [
        { id: 1, name: "AIG" },
        { id: 2, name: "ANA" },
        { id: 3, name: "AXA" },
        { id: 4, name: "COMPARADOR" },
        { id: 5, name: "COMPARADOR-ADT" },
        { id: 6, name: "COMPARADOR-AUTOCOSMOS" },
        { id: 7, name: "GNP" },
        { id: 8, name: "HDI" },
        { id: 9, name: "PROVEEDORES" },
        { id: 10, name: "QUÁLITAS" },
        { id: 11, name: "SGMM SEGURIFY" },
        { id: 12, name: "SURA" },
        { id: 13, name: "SURA MOVILIDAD" },
        { id: 14, name: "SURA SGMM" },
        { id: 15, name: "AUTOS RASTREATOR AIG" },
        { id: 16, name: "TUSEGURO.COM" },
        { id: 17, name: "CORU.COM" },
      ],
      fuenteIngresoItems: [
        "Referido",
        "LinkedIn",
        "Facebook",
        "RH",
        "IECA",
        "Base de Datos",
        "Simulador",
        "Eventos",
      ],
      regimenItems: ["Física", "Moral"],
      asociadoPromotoriaItems: [
        { id: 1, name: "Afirme" },
        { id: 2, name: "ANA Seguros" },
        { id: 3, name: "GNP" },
        { id: 4, name: "Primero Seguros" },
        { id: 5, name: "Quálitas" },
        { id: 6, name: "Multiva" },
        { id: 7, name: "RSA Seguros" },
        { id: 8, name: "AIG Seguros México" },
        { id: 9, name: "HDI Seguros" },
        { id: 10, name: "Zurich" },
        { id: 11, name: "AXA" },
        { id: 12, name: "BANORTE" },
        { id: 13, name: "ATLAS" },
        { id: 14, name: "CHUBB" },
        { id: 15, name: "GMX" },
        { id: 16, name: "ALLIANZ" },
        { id: 17, name: "ARGOS" },
        { id: 18, name: "ASERTA" },
        { id: 19, name: "BX+" },
        { id: 20, name: "MUTUUS" },
        { id: 21, name: "PLAN SEGURO" },
        { id: 22, name: "PREVEM" },
        { id: 23, name: "SISNOVA" },
        { id: 24, name: "BUPA" },
      ],
      tipoAgenteItems: [
        "Agente",
        "Comisionista",
        "Agente Apoderado",
        "Agente Promotoria",
      ],
      tipoCedulaItems: ["A", "A1", "B", "B1", "C", "F"],
      nivelAgenteItems: ["Básico", "Intermedio", "Avanzado"],
      productos: [],
      idAgente: null,
      empresas:[]
    };
  },

 async mounted() {
    this.listas();
    this.cargarRoles();
    this.getEmpresas();
  },

  watch: {
    "usuario.fechaNacimiento"() {
      this.actualizarRfc();
    },
    "usuario.nombre"() {
      this.actualizarRfc();
    },
    "usuario.apellidoPaterno"() {
      this.actualizarRfc();
    },
    "usuario.apellidoMaterno"() {
      this.actualizarRfc();
    },
  },

  computed: {
    validador() {
      let valdadorSum = 0;
      this.usuario.password
        ? this.usuario.password.length > 0
          ? (valdadorSum = valdadorSum + 1)
          : ""
        : "";
      this.usuario.nombre
        ? this.usuario.nombre.length > 0
          ? (valdadorSum = valdadorSum + 1)
          : ""
        : "";
      this.usuario.apellidoPaterno
        ? this.usuario.apellidoPaterno.length > 0
          ? (valdadorSum = valdadorSum + 1)
          : ""
        : "";
      this.usuario.telefono
        ? this.usuario.telefono.length > 9
          ? (valdadorSum = valdadorSum + 1)
          : ""
        : "";
      this.usuario.correo
        ? this.usuario.correo.length > 0
          ? (valdadorSum = valdadorSum + 1)
          : ""
        : "";
      this.usuario.nacimientoEstadoId
        ? this.usuario.nacimientoEstadoId.length > 0
          ? (valdadorSum = valdadorSum + 1)
          : ""
        : "";
      this.usuario.rfc
        ? this.usuario.rfc.length > 0
          ? (valdadorSum = valdadorSum + 1)
          : ""
        : "";
      if (valdadorSum == 6) {
        return false;
      } else {
        return true;
      }
    },
    currentRoles() {
      var currentRoles = [];
      var permitedRoles = [];
      switch (this.rol) {
        case "ADMIN":
          currentRoles = this.roles;
          break;
        case "ADMINAGENT":
          permitedRoles = ["MANAGER", "AGENT"];
          permitedRoles.forEach((rol) =>
            currentRoles.push(
              this.roles.filter((role) => role.id == rol)[0]
                ? this.roles.filter((role) => role.id == rol)[0]
                : []
            )
          );
          break;
        case "MANAGER":
          permitedRoles = ["AGENT"];
          permitedRoles.forEach((rol) =>
            currentRoles.push(
              this.roles.filter((role) => role.id == rol)[0]
                ? this.roles.filter((role) => role.id == rol)[0]
                : []
            )
          );
          break;
        case "ADMINCC":
          permitedRoles = [
            "MANAGERCC",
            "AGENTCC",
            "AGENTCCSPONSORS",
            "RENOVACIONESCC",
            "COBRANZACC",
          ];
          permitedRoles.forEach((rol) =>
            currentRoles.push(
              this.roles.filter((role) => role.id == rol)[0]
                ? this.roles.filter((role) => role.id == rol)[0]
                : []
            )
          );
          break;
        case "MANAGERCC":
          permitedRoles = ["AGENTCC", "AGENTCCSPONSORS"];
          permitedRoles.forEach((rol) =>
            currentRoles.push(
              this.roles.filter((role) => role.id == rol)[0]
                ? this.roles.filter((role) => role.id == rol)[0]
                : []
            )
          );
          break;
        case "OPERACIONESINBOUND":
          permitedRoles = ["MESACONTROLINBOUND", "OPERACIONESINBOUND", "OPERADORINBOUND", "BACKOFFICEINBOUND"];
          permitedRoles.forEach((rol) =>
            currentRoles.push(
              this.roles.filter((role) => role.id == rol)[0]
                ? this.roles.filter((role) => role.id == rol)[0]
                : []
            )
          );
          break;
        case "FORMACION":
          permitedRoles = ["FORMACION", "OPERADORCC", "COBRANZACC", "AGENTCC", 'ADMINCC', 'MESACONTROLINBOUND', 'OPERACIONESINBOUND', 'OPERADORINBOUND', 'RENOVACIONESCC', 'MANAGERCC'];
          permitedRoles.forEach((rol) =>
            currentRoles.push(
              this.roles.filter((role) => role.id == rol)[0]
                ? this.roles.filter((role) => role.id == rol)[0]
                : []
            )
          );
          break;
        default:
          window.alert("No tienes permiso de crear usuarios");
          currentRoles = [];
          break;
      }
      return currentRoles;
    },
  },

  methods: {
    correoFavoritoModificado(v) {
      this.usuario.correo = v;
    },

    mostrarInputsAgente() {
      if (this.usuario.rol != "AGENT") {
        return false;
      } else return true;
    },
    cargarRoles() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios.get(`/v1/agente/roles/list`, config).then((response) => {
        response.data
          ? response.data.map((element) => {
              this.roles.push({
                id: element.rol ? element.rol : "No tiene",
                nombre: element.nombre ? element.nombre : "No tiene",
              });
            })
          : "";
          this.roles.sort((a, b) => {
            const nombreA = a.nombre.toUpperCase();
            const nombreB = b.nombre.toUpperCase();

            if (nombreA < nombreB) {
              return -1;
            }
            if (nombreA > nombreB) {
              return 1;
            }
            return 0;
          });
      });
    },
    actualizarRfc() {
      if (
        !this.usuario.nombre ||
        !this.usuario.apellidoPaterno ||
        !this.usuario.apellidoMaterno ||
        !this.usuario.fechaNacimiento
      )
        return;
      const arregloNacimiento = this.usuario.fechaNacimiento
        .split("/")
        .map((e) => Number(e));
      if (arregloNacimiento.length < 3) return;
      const rfc = RfcFacil.forNaturalPerson({
        name: this.usuario.nombre,
        firstLastName: this.usuario.apellidoPaterno,
        secondLastName: this.usuario.apellidoMaterno,
        day: arregloNacimiento[0],
        month: arregloNacimiento[1],
        year: arregloNacimiento[2],
      });
      this.usuario.rfc = rfc.substring(0, rfc.length - 3);
      this.usuario.password = this.usuario.rfc;
    },
    setNewUser() {
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      let dataUser = {
        nombre: this.usuario.nombre,
        apellidoPaterno: this.usuario.apellidoPaterno,
        apellidoMaterno: this.usuario.apellidoMaterno,
        sexo: this.usuario.sexo,
        fechaNacimiento: moment(
          this.usuario.fechaNacimiento,
          "DD/MM/YYYY"
        ).format("YYYY-MM-DD"),
        nacimientoEstadoId: this.usuario.nacimientoEstadoId,
        telefono: this.usuario.telefono,
        celular: this.usuario.celular,
        correo: this.usuario.correo,
        rfc: this.usuario.rfc,
        curp: this.usuario.curp,
        password: this.usuario.password,
        rol: this.usuario.rol,
        status: 1,
        motivoBaja: this.usuario.motivoBaja ? this.usuario.motivoBaja : null,
        ramos: this.usuario.ramo,
        canales: this.usuario.canal,
        landings: this.usuario.landing ? this.usuario.landing : null,
        vip: this.usuario.vip,
        fuenteIngreso: this.usuarioAgente.fuenteIngreso
          ? this.usuarioAgente.fuenteIngreso
          : null,
        regimen: this.usuarioAgente.regimen ? this.usuarioAgente.regimen : null,
        agenteAsociadoPromotoria: this.usuarioAgente.asociadoPromotoria
          ? this.usuarioAgente.asociadoPromotoria
          : null,
        tipoAgente: this.usuarioAgente.tipoAgente
          ? this.usuarioAgente.tipoAgente
          : null,
        razonSocial: this.usuarioAgente.razonSocial
          ? this.usuarioAgente.razonSocial
          : null,
        clabeBancaria: this.usuarioAgente.clabeBancaria
          ? this.usuarioAgente.clabeBancaria
          : null,
        claveAgente: this.usuarioAgente.claveAgente
          ? this.usuarioAgente.claveAgente
          : null,
        tipoCedula: this.usuarioAgente.tipoCedula
          ? this.usuarioAgente.tipoCedula
          : null,
        nivelAgente: this.usuarioAgente.nivelAgente
          ? this.usuarioAgente.nivelAgente
          : null,
        numeroCartera: this.usuarioAgente.numeroCartera
          ? this.usuarioAgente.numeroCartera
          : null,
        cedula: this.usuarioAgente.cedula ? this.usuarioAgente.cedula : null,
        rc: this.usuarioAgente.rc ? this.usuarioAgente.rc : null,
        vigenciaCedula: this.usuarioAgente.vigenciaCedula
          ? moment(this.usuarioAgente.vigenciaCedula, "DD/MM/YYYY").format(
              "YYYY-MM-DD"
            )
          : null,
        vigenciaPolizaRc: this.usuarioAgente.vigenciaPolizaRC
          ? moment(this.usuarioAgente.vigenciaPolizaRC, "DD/MM/YYYY").format(
              "YYYY-MM-DD"
            )
          : null,
        fecha_ingreso: this.usuario.fecha_ingreso
          ? moment(this.usuario.fecha_ingreso, "DD/MM/YYYY").format(
              "YYYY-MM-DD HH:mm:ss"
            )
          : null,
        turno: this.usuario.turno ? this.usuario.turno : null,
        empresas: this.usuario.empresas ?? []
      };
      mainAxios
        .post(`/v1/agente/insert`, dataUser, config)
        .then(async (responseUsuario) => {
          this.idAgente = responseUsuario.data.id;
          console.log({ idAgente: this.idAgente });
          await this.$refs.listadoCorreos.actualizarCorreos();
          this.$toast.success("Usuario creado", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
          setTimeout(() => {
            this.$router.push("/usuarios");
          }, 2000);
        });

      // rol
      // mainAxios.post(`/v1/agente/rol`,this.usuario.rol,config).then((value) => {
      //   console.log(value)
      // })
    },
    listas() {
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };

      mainAxios.get(`/v1/canal/list`, config).then((response) => {
        this.canalList = response.data;
      });
      mainAxios.get(`/v1/ramo/list`, config).then((response) => {
        this.ramoList = response.data;
      });
      mainAxios.get(`/v1/campania/list`, config).then((response) => {
        this.campaniaList = response.data;
      });

      mainAxios.get(`/v1/agente/landing/list`, config).then(({ data }) => {
        this.landings = data;
      });
    },
    async getEmpresas(){
      const empresas =  await getEmpresasInbound()
      this.empresas = empresas.data.empresas
    }
  },
};
</script>

<style>
.mayusculas {
  text-transform: uppercase;
}
</style>
