<template>
  <v-app>
    <Navigation />
    <v-main class="mb-15">
      <v-container>
        <v-row class="mb-15 fill-height">
          <v-col>
            <v-row>
              <v-col>
                <h1>Atracción de Talento</h1>
              </v-col>
            </v-row>
            <v-row style="padding-left: 30px; margin-top: 25px"> </v-row>
            <v-row class="mb-15" style="padding-left: 30px">
              <v-col cols="3">
                <h3>Selecciona el rango de fechas</h3>
                <v-date-picker
                  style="width: 100%"
                  v-model="dates"
                  range
                  locale="es"
                ></v-date-picker>
                <v-btn
                  rounded
                  color="primary"
                  style="width: 100%"
                  dark
                  @click="getReporte()"
                  >Consultar</v-btn
                >
                <v-btn
                  rounded
                  color="primary"
                  style="width:100%;margin-top:20px;"
                  :disabled="bloquear"
                  ><download-csv
                    :data="reportDownload"
                    name="Reporte_Atraccion_Talento.csv"
                  >
                    Descargar Información
                  </download-csv></v-btn
                >
              </v-col>
              <v-col cols="9">
                <div>
                  <div v-if="loading" style="text-align: center">
                    <h2 clas="pb-4">Cargando Reporte....</h2>
                    <br /><br />
                    <md-progress-spinner
                      :md-diameter="100"
                      :md-stroke="10"
                      md-mode="indeterminate"
                    ></md-progress-spinner>
                  </div>
                </div>

                <v-data-table
                  :headers="columns"
                  :items="reporteData"
                  item-key="name"
                  class="elevation-1"
                  no-data-text="Sin datos para mostrar"
                ></v-data-table>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Navigation from "../../../../components/Nav.vue";
import { mainAxios } from "../../../../mainAxios";

import moment from "moment-timezone";
// ADMIN FINANZAS y DIRECTOR

export default {
  components: {
    Navigation,
  },

  data() {
    return {
      dates: ["", ""],
      roles: JSON.parse(localStorage.getItem("roles")),
      agenteUuid: localStorage.getItem("agenteUuid"),
      loading: false,
      bloquear: true,
      reporteData: [],
      reportDownload: [],

      columns: [
        { text: "ID", value: "id" },
        { text: "Fecha de creación", value: "fechaCreacion" },
        { text: "Origen", value: "origen" },
        { text: "Status", value: "status" },
        { text: "Perfil", value: "perfil" },
        { text: "Nombre", value: "nombre" },
        { text: "Apellido Paterno", value: "apellido_paterno" },
        { text: "Apellido Materno", value: "apellido_materno" },
        { text: "Teléfono", value: "telefono" },
        { text: "Correo", value: "correo" },
        { text: "Fecha de nacimiento", value: "fecha_nacimiento" },
        { text: "Género", value: "genero" },
        { text: "Estado civil", value: "estado_civil" },
        { text: "Nivel de estudios", value: "nivel_estudios" },
        { text: "Estado", value: "estado_id" },
      ],
    };
  },

  methods: {
    getReporte() {
      this.reportDownload = [];
      this.reporteData = [];
      this.loading = true;
      const reporteUrl = "/v1/atraccion-talento/reporte";
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };

      let inicioUTC = null;
      let finUTC = null;

      if (this.dates.length > 0) {
        if (this.dates[1]) {
          inicioUTC = moment.utc(this.dates[0]).format("YYYY-MM-DD");
          finUTC = moment
            .utc(this.dates[1])
            .add(24, "hours")
            .subtract(1, "seconds")
            .format("YYYY-MM-DD");
        } else {
          inicioUTC = moment.utc(this.dates[0]).format("YYYY-MM-DD");
          finUTC = moment
            .utc(this.dates[0])
            .add(24, "hours")
            .subtract(1, "seconds")
            .format("YYYY-MM-DD");
        }
      }
      const fechas = {
        fechaInicio: inicioUTC,
        fechaFin: finUTC,
      };

      mainAxios.post(reporteUrl, fechas, config).then(({ data }) => {
        data.forEach((element) => {
          let payloadDataTable = {};
          let payloadDownload = {};
          this.columns.forEach((column) => {
            payloadDataTable[column.value] =
              element[column.value] || "No disponible";
            payloadDownload[column.text] =
              element[column.value] || "No disponible";
          });
          this.reporteData.push(payloadDataTable);
          this.reportDownload.push(payloadDownload);
        });
      });

      this.bloquear = false;
      this.loading = false;
    },
  },
};
</script>
