function randomColor() {
  var hexadecimal = new Array(
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "A",
    "B",
    "C",
    "D",
    "E",
    "F"
  );
  var colorAleatorio = "#";
  for (var i = 0; i < 6; i++) {
    var posarray = aleatorio(0, hexadecimal.length);
    colorAleatorio += hexadecimal[posarray];
  }
  return colorAleatorio;
}

function aleatorio(inferior, superior) {
  var numPosibilidades = superior - inferior;
  var aleat = Math.random() * numPosibilidades;
  var otro = Math.floor(aleat);
  return parseInt(inferior) + otro;
}

export default randomColor;
