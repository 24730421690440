export const smartPhonesModels = [
  {
    id: 1456,
    name: "Amazon Fire Phone",
    brand: "Amazon",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Amazon Fire Phone",
    release_date: null,
  },
  {
    id: 1454,
    name: "iPad mini (6th generation)",
    brand: "Apple",
    so: "IOS",
    device_type: null,
    image: null,
    alias: "Apple iPad mini (6th generation)",
    release_date: null,
  },
  {
    id: 1453,
    name: "iPad mini (5th generation)",
    brand: "Apple",
    so: "IOS",
    device_type: null,
    image: null,
    alias: "Apple iPad mini (5th generation)",
    release_date: null,
  },
  {
    id: 1452,
    name: "iPad mini 4",
    brand: "Apple",
    so: "IOS",
    device_type: null,
    image: null,
    alias: "Apple iPad mini 4",
    release_date: null,
  },
  {
    id: 1451,
    name: "iPad mini 3",
    brand: "Apple",
    so: "IOS",
    device_type: null,
    image: null,
    alias: "Apple iPad mini 3",
    release_date: null,
  },
  {
    id: 1450,
    name: "iPad mini 2",
    brand: "Apple",
    so: "IOS",
    device_type: null,
    image: null,
    alias: "Apple iPad mini 2",
    release_date: null,
  },
  {
    id: 1449,
    name: "iPad mini",
    brand: "Apple",
    so: "IOS",
    device_type: null,
    image: null,
    alias: "Apple iPad mini",
    release_date: null,
  },
  {
    id: 1448,
    name: "iPad Pro (12.9-inch) (5th generation)",
    brand: "Apple",
    so: "IOS",
    device_type: null,
    image: null,
    alias: "Apple iPad Pro (12.9-inch) (5th generation)",
    release_date: null,
  },
  {
    id: 1447,
    name: "iPad Pro (11-inch) (3rd generation)",
    brand: "Apple",
    so: "IOS",
    device_type: null,
    image: null,
    alias: "Apple iPad Pro (11-inch) (3rd generation)",
    release_date: null,
  },
  {
    id: 1446,
    name: "iPad Pro (12.9-inch) (4th generation)",
    brand: "Apple",
    so: "IOS",
    device_type: null,
    image: null,
    alias: "Apple iPad Pro (12.9-inch) (4th generation)",
    release_date: null,
  },
  {
    id: 1445,
    name: "iPad Pro (11-inch) (2nd generation)",
    brand: "Apple",
    so: "IOS",
    device_type: null,
    image: null,
    alias: "Apple iPad Pro (11-inch) (2nd generation)",
    release_date: null,
  },
  {
    id: 1444,
    name: "iPad Pro (12.9-inch) (3rd generation)",
    brand: "Apple",
    so: "IOS",
    device_type: null,
    image: null,
    alias: "Apple iPad Pro (12.9-inch) (3rd generation)",
    release_date: null,
  },
  {
    id: 1443,
    name: "iPad Pro (11-inch)",
    brand: "Apple",
    so: "IOS",
    device_type: null,
    image: null,
    alias: "Apple iPad Pro (11-inch)",
    release_date: null,
  },
  {
    id: 1442,
    name: "iPad Pro (10.5-inch)",
    brand: "Apple",
    so: "IOS",
    device_type: null,
    image: null,
    alias: "Apple iPad Pro (10.5-inch)",
    release_date: null,
  },
  {
    id: 1441,
    name: "iPad Pro (12.9-inch) (2nd generation)",
    brand: "Apple",
    so: "IOS",
    device_type: null,
    image: null,
    alias: "Apple iPad Pro (12.9-inch) (2nd generation)",
    release_date: null,
  },
  {
    id: 1440,
    name: "iPad Pro (9.7-inch)",
    brand: "Apple",
    so: "IOS",
    device_type: null,
    image: null,
    alias: "Apple iPad Pro (9.7-inch)",
    release_date: null,
  },
  {
    id: 1439,
    name: "iPad Pro (12.9-inch)",
    brand: "Apple",
    so: "IOS",
    device_type: null,
    image: null,
    alias: "Apple iPad Pro (12.9-inch)",
    release_date: null,
  },
  {
    id: 1438,
    name: "iPad Air (5th generation)",
    brand: "Apple",
    so: "IOS",
    device_type: null,
    image: null,
    alias: "Apple iPad Air (5th generation)",
    release_date: null,
  },
  {
    id: 1437,
    name: "iPad Air (4th generation)",
    brand: "Apple",
    so: "IOS",
    device_type: null,
    image: null,
    alias: "Apple iPad Air (4th generation)",
    release_date: null,
  },
  {
    id: 1436,
    name: "iPad Air (3rd generation)",
    brand: "Apple",
    so: "IOS",
    device_type: null,
    image: null,
    alias: "Apple iPad Air (3rd generation)",
    release_date: null,
  },
  {
    id: 1435,
    name: "iPad Air 2",
    brand: "Apple",
    so: "IOS",
    device_type: null,
    image: null,
    alias: "Apple iPad Air 2",
    release_date: null,
  },
  {
    id: 1434,
    name: "iPad Air",
    brand: "Apple",
    so: "IOS",
    device_type: null,
    image: null,
    alias: "Apple iPad Air",
    release_date: null,
  },
  {
    id: 1433,
    name: "iPad (9th generation)",
    brand: "Apple",
    so: "IOS",
    device_type: null,
    image: null,
    alias: "Apple iPad (9th generation)",
    release_date: null,
  },
  {
    id: 1432,
    name: "iPad (8th generation)",
    brand: "Apple",
    so: "IOS",
    device_type: null,
    image: null,
    alias: "Apple iPad (8th generation)",
    release_date: null,
  },
  {
    id: 1431,
    name: "iPad (7th generation)",
    brand: "Apple",
    so: "IOS",
    device_type: null,
    image: null,
    alias: "Apple iPad (7th generation)",
    release_date: null,
  },
  {
    id: 1430,
    name: "iPad (6th generation)",
    brand: "Apple",
    so: "IOS",
    device_type: null,
    image: null,
    alias: "Apple iPad (6th generation)",
    release_date: null,
  },
  {
    id: 1429,
    name: "iPad (5th generation)",
    brand: "Apple",
    so: "IOS",
    device_type: null,
    image: null,
    alias: "Apple iPad (5th generation)",
    release_date: null,
  },
  {
    id: 1428,
    name: "iPad (4th generation)",
    brand: "Apple",
    so: "IOS",
    device_type: null,
    image: null,
    alias: "Apple iPad (4th generation)",
    release_date: null,
  },
  {
    id: 1427,
    name: "iPad (3rd generation)",
    brand: "Apple",
    so: "IOS",
    device_type: null,
    image: null,
    alias: "Apple iPad (3rd generation)",
    release_date: null,
  },
  {
    id: 1426,
    name: "iPad 2",
    brand: "Apple",
    so: "IOS",
    device_type: null,
    image: null,
    alias: "Apple iPad 2",
    release_date: null,
  },
  {
    id: 1425,
    name: "iPad",
    brand: "Apple",
    so: "IOS",
    device_type: null,
    image: null,
    alias: "Apple iPad",
    release_date: null,
  },
  {
    id: 1424,
    name: "iPhone SE (3rd generation)",
    brand: "Apple",
    so: "IOS",
    device_type: null,
    image: null,
    alias: "Apple iPhone SE (3rd generation)",
    release_date: null,
  },
  {
    id: 1423,
    name: "iPhone 13 Pro Max",
    brand: "Apple",
    so: "IOS",
    device_type: null,
    image: null,
    alias: "Apple iPhone 13 Pro Max",
    release_date: null,
  },
  {
    id: 1422,
    name: "iPhone 13 Pro",
    brand: "Apple",
    so: "IOS",
    device_type: null,
    image: null,
    alias: "Apple iPhone 13 Pro",
    release_date: null,
  },
  {
    id: 1421,
    name: "iPhone 13",
    brand: "Apple",
    so: "IOS",
    device_type: null,
    image: null,
    alias: "Apple iPhone 13",
    release_date: null,
  },
  {
    id: 1420,
    name: "iPhone 13 mini",
    brand: "Apple",
    so: "IOS",
    device_type: null,
    image: null,
    alias: "Apple iPhone 13 mini",
    release_date: null,
  },
  {
    id: 1419,
    name: "iPhone 12 Pro Max",
    brand: "Apple",
    so: "IOS",
    device_type: null,
    image: null,
    alias: "Apple iPhone 12 Pro Max",
    release_date: null,
  },
  {
    id: 1418,
    name: "iPhone 12 Pro",
    brand: "Apple",
    so: "IOS",
    device_type: null,
    image: null,
    alias: "Apple iPhone 12 Pro",
    release_date: null,
  },
  {
    id: 1417,
    name: "iPhone 12",
    brand: "Apple",
    so: "IOS",
    device_type: null,
    image: null,
    alias: "Apple iPhone 12",
    release_date: null,
  },
  {
    id: 1416,
    name: "iPhone 12 mini",
    brand: "Apple",
    so: "IOS",
    device_type: null,
    image: null,
    alias: "Apple iPhone 12 mini",
    release_date: null,
  },
  {
    id: 1415,
    name: "iPhone SE (2nd generation)",
    brand: "Apple",
    so: "IOS",
    device_type: null,
    image: null,
    alias: "Apple iPhone SE (2nd generation)",
    release_date: null,
  },
  {
    id: 1414,
    name: "iPhone 11 Pro Max",
    brand: "Apple",
    so: "IOS",
    device_type: null,
    image: null,
    alias: "Apple iPhone 11 Pro Max",
    release_date: null,
  },
  {
    id: 1413,
    name: "iPhone 11 Pro",
    brand: "Apple",
    so: "IOS",
    device_type: null,
    image: null,
    alias: "Apple iPhone 11 Pro",
    release_date: null,
  },
  {
    id: 1412,
    name: "iPhone 11",
    brand: "Apple",
    so: "IOS",
    device_type: null,
    image: null,
    alias: "Apple iPhone 11",
    release_date: null,
  },
  {
    id: 1411,
    name: "iPhone XS Max",
    brand: "Apple",
    so: "IOS",
    device_type: null,
    image: null,
    alias: "Apple iPhone XS Max",
    release_date: null,
  },
  {
    id: 1410,
    name: "iPhone XS",
    brand: "Apple",
    so: "IOS",
    device_type: null,
    image: null,
    alias: "Apple iPhone XS",
    release_date: null,
  },
  {
    id: 1409,
    name: "iPhone XR",
    brand: "Apple",
    so: "IOS",
    device_type: null,
    image: null,
    alias: "Apple iPhone XR",
    release_date: null,
  },
  {
    id: 1408,
    name: "iPhone X",
    brand: "Apple",
    so: "IOS",
    device_type: null,
    image: null,
    alias: "Apple iPhone X",
    release_date: null,
  },
  {
    id: 1407,
    name: "iPhone 8 Plus",
    brand: "Apple",
    so: "IOS",
    device_type: null,
    image: null,
    alias: "Apple iPhone 8 Plus",
    release_date: null,
  },
  {
    id: 1406,
    name: "iPhone 8",
    brand: "Apple",
    so: "IOS",
    device_type: null,
    image: null,
    alias: "Apple iPhone 8",
    release_date: null,
  },
  {
    id: 1405,
    name: "iPhone 7 Plus",
    brand: "Apple",
    so: "IOS",
    device_type: null,
    image: null,
    alias: "Apple iPhone 7 Plus",
    release_date: null,
  },
  {
    id: 1404,
    name: "iPhone 7",
    brand: "Apple",
    so: "IOS",
    device_type: null,
    image: null,
    alias: "Apple iPhone 7",
    release_date: null,
  },
  {
    id: 1403,
    name: "iPhone SE (1st generation)",
    brand: "Apple",
    so: "IOS",
    device_type: null,
    image: null,
    alias: "Apple iPhone SE (1st generation)",
    release_date: null,
  },
  {
    id: 1402,
    name: "iPhone 6s Plus",
    brand: "Apple",
    so: "IOS",
    device_type: null,
    image: null,
    alias: "Apple iPhone 6s Plus",
    release_date: null,
  },
  {
    id: 1401,
    name: "iPhone 6s",
    brand: "Apple",
    so: "IOS",
    device_type: null,
    image: null,
    alias: "Apple iPhone 6s",
    release_date: null,
  },
  {
    id: 1400,
    name: "iPhone 6 Plus",
    brand: "Apple",
    so: "IOS",
    device_type: null,
    image: null,
    alias: "Apple iPhone 6 Plus",
    release_date: null,
  },
  {
    id: 1399,
    name: "iPhone 6",
    brand: "Apple",
    so: "IOS",
    device_type: null,
    image: null,
    alias: "Apple iPhone 6",
    release_date: null,
  },
  {
    id: 1398,
    name: "iPhone 5s",
    brand: "Apple",
    so: "IOS",
    device_type: null,
    image: null,
    alias: "Apple iPhone 5s",
    release_date: null,
  },
  {
    id: 1397,
    name: "iPhone 5c",
    brand: "Apple",
    so: "IOS",
    device_type: null,
    image: null,
    alias: "Apple iPhone 5c",
    release_date: null,
  },
  {
    id: 1396,
    name: "iPhone 5",
    brand: "Apple",
    so: "IOS",
    device_type: null,
    image: null,
    alias: "Apple iPhone 5",
    release_date: null,
  },
  {
    id: 1395,
    name: "iPhone 4S",
    brand: "Apple",
    so: "IOS",
    device_type: null,
    image: null,
    alias: "Apple iPhone 4S",
    release_date: null,
  },
  {
    id: 1394,
    name: "iPhone 4",
    brand: "Apple",
    so: "IOS",
    device_type: null,
    image: null,
    alias: "Apple iPhone 4",
    release_date: null,
  },
  {
    id: 1393,
    name: "iPhone 3GS",
    brand: "Apple",
    so: "IOS",
    device_type: null,
    image: null,
    alias: "Apple iPhone 3GS",
    release_date: null,
  },
  {
    id: 1392,
    name: "iPhone 3G",
    brand: "Apple",
    so: "IOS",
    device_type: null,
    image: null,
    alias: "Apple iPhone 3G",
    release_date: null,
  },
  {
    id: 1391,
    name: "iPhone",
    brand: "Apple",
    so: "IOS",
    device_type: null,
    image: null,
    alias: "Apple iPhone",
    release_date: null,
  },
  {
    id: 1458,
    name: "Arirang 171",
    brand: "Arirang (smartphone)",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Arirang 171",
    release_date: null,
  },
  {
    id: 1457,
    name: "Arirang (original)",
    brand: "Arirang (smartphone)",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Arirang (original)",
    release_date: null,
  },
  {
    id: 1495,
    name: "Asus ZenFone 8",
    brand: "Asus",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Asus ZenFone 8",
    release_date: null,
  },
  {
    id: 1494,
    name: "Asus ROG Phone 5",
    brand: "Asus",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Asus ROG Phone 5",
    release_date: null,
  },
  {
    id: 1493,
    name: "Asus ZenFone 7",
    brand: "Asus",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Asus ZenFone 7",
    release_date: null,
  },
  {
    id: 1492,
    name: "Asus ROG Phone 3",
    brand: "Asus",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Asus ROG Phone 3",
    release_date: null,
  },
  {
    id: 1491,
    name: "Asus ROG Phone II",
    brand: "Asus",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Asus ROG Phone II",
    release_date: null,
  },
  {
    id: 1490,
    name: "Asus ZenFone 6",
    brand: "Asus",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Asus ZenFone 6",
    release_date: null,
  },
  {
    id: 1489,
    name: "Asus ZenFone Live L2",
    brand: "Asus",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Asus ZenFone Live L2",
    release_date: null,
  },
  {
    id: 1488,
    name: "Asus ZenFone Max Plus M2",
    brand: "Asus",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Asus ZenFone Max Plus M2",
    release_date: null,
  },
  {
    id: 1487,
    name: "Asus ZenFone Max Shot",
    brand: "Asus",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Asus ZenFone Max Shot",
    release_date: null,
  },
  {
    id: 1486,
    name: "Asus ZenFone Max Pro M2",
    brand: "Asus",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Asus ZenFone Max Pro M2",
    release_date: null,
  },
  {
    id: 1485,
    name: "Asus ZenFone Max M2",
    brand: "Asus",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Asus ZenFone Max M2",
    release_date: null,
  },
  {
    id: 1484,
    name: "Asus ZenFone Lite L1",
    brand: "Asus",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Asus ZenFone Lite L1",
    release_date: null,
  },
  {
    id: 1483,
    name: "Asus ROG Phone",
    brand: "Asus",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Asus ROG Phone",
    release_date: null,
  },
  {
    id: 1482,
    name: "Asus ZenFone Live L1",
    brand: "Asus",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Asus ZenFone Live L1",
    release_date: null,
  },
  {
    id: 1481,
    name: "Asus ZenFone 5",
    brand: "Asus",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Asus ZenFone 5",
    release_date: null,
  },
  {
    id: 1480,
    name: "Asus ZenFone Max Pro M1",
    brand: "Asus",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Asus ZenFone Max Pro M1",
    release_date: null,
  },
  {
    id: 1479,
    name: "Asus ZenFone Max M1",
    brand: "Asus",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Asus ZenFone Max M1",
    release_date: null,
  },
  {
    id: 1478,
    name: "Asus ZenFone Max Plus M1",
    brand: "Asus",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Asus ZenFone Max Plus M1",
    release_date: null,
  },
  {
    id: 1477,
    name: "Asus ZenFone 4",
    brand: "Asus",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Asus ZenFone 4",
    release_date: null,
  },
  {
    id: 1476,
    name: "Asus ZenFone 3",
    brand: "Asus",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Asus ZenFone 3",
    release_date: null,
  },
  {
    id: 1475,
    name: "Asus ZenFone 2",
    brand: "Asus",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Asus ZenFone 2",
    release_date: null,
  },
  {
    id: 1474,
    name: "Asus ZenFone 5 (2015)",
    brand: "Asus",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Asus ZenFone 5 (2015)",
    release_date: null,
  },
  {
    id: 1473,
    name: "Asus Pegasus (X002)",
    brand: "Asus",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Asus Pegasus (X002)",
    release_date: null,
  },
  {
    id: 1472,
    name: "Asus PadFone X mini",
    brand: "Asus",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Asus PadFone X mini",
    release_date: null,
  },
  {
    id: 1471,
    name: "Asus ZenFone 5 (2014)",
    brand: "Asus",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Asus ZenFone 5 (2014)",
    release_date: null,
  },
  {
    id: 1470,
    name: "Asus ZenFone 4.5 (2014)",
    brand: "Asus",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Asus ZenFone 4.5 (2014)",
    release_date: null,
  },
  {
    id: 1469,
    name: "Asus PadFone S/X",
    brand: "Asus",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Asus PadFone S/X",
    release_date: null,
  },
  {
    id: 1468,
    name: "Asus ZenFone 6 (2014)",
    brand: "Asus",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Asus ZenFone 6 (2014)",
    release_date: null,
  },
  {
    id: 1467,
    name: "Asus ZenFone 4 (2014)",
    brand: "Asus",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Asus ZenFone 4 (2014)",
    release_date: null,
  },
  {
    id: 1466,
    name: "Asus ZenFone 5 (2014)",
    brand: "Asus",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Asus ZenFone 5 (2014)",
    release_date: null,
  },
  {
    id: 1465,
    name: "Asus PadFone Infinity Lite",
    brand: "Asus",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Asus PadFone Infinity Lite",
    release_date: null,
  },
  {
    id: 1464,
    name: "Asus PadFone E",
    brand: "Asus",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Asus PadFone E",
    release_date: null,
  },
  {
    id: 1463,
    name: "Asus PadFone mini",
    brand: "Asus",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Asus PadFone mini",
    release_date: null,
  },
  {
    id: 1462,
    name: "Asus PadFone Infinity 2",
    brand: "Asus",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Asus PadFone Infinity 2",
    release_date: null,
  },
  {
    id: 1461,
    name: "Asus PadFone Infinity",
    brand: "Asus",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Asus PadFone Infinity",
    release_date: null,
  },
  {
    id: 1460,
    name: "Asus PadFone 2",
    brand: "Asus",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Asus PadFone 2",
    release_date: null,
  },
  {
    id: 1459,
    name: "Asus PadFone",
    brand: "Asus",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Asus PadFone",
    release_date: null,
  },
  {
    id: 1497,
    name: "Balmuda Phone",
    brand: "Balmuda",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Balmuda Phone",
    release_date: null,
  },
  {
    id: 1500,
    name: "BlackBerry DTEK60",
    brand: "BlackBerry Limited",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "BlackBerry DTEK60",
    release_date: null,
  },
  {
    id: 1499,
    name: "BlackBerry DTEK50",
    brand: "BlackBerry Limited",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "BlackBerry DTEK50",
    release_date: null,
  },
  {
    id: 1498,
    name: "BlackBerry Priv",
    brand: "BlackBerry Limited",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "BlackBerry Priv",
    release_date: null,
  },
  {
    id: 1506,
    name: "BlackBerry Key2 LE",
    brand: "BlackBerry Mobile",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "BlackBerry Key2 LE",
    release_date: null,
  },
  {
    id: 1505,
    name: "BlackBerry Evolve/X",
    brand: "BlackBerry Mobile",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "BlackBerry Evolve/X",
    release_date: null,
  },
  {
    id: 1504,
    name: "BlackBerry Key2",
    brand: "BlackBerry Mobile",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "BlackBerry Key2",
    release_date: null,
  },
  {
    id: 1503,
    name: "BlackBerry Motion",
    brand: "BlackBerry Mobile",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "BlackBerry Motion",
    release_date: null,
  },
  {
    id: 1502,
    name: "BlackBerry KeyOne",
    brand: "BlackBerry Mobile",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "BlackBerry KeyOne",
    release_date: null,
  },
  {
    id: 1501,
    name: "BlackBerry Aurora",
    brand: "BlackBerry Mobile",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "BlackBerry Aurora",
    release_date: null,
  },
  {
    id: 1507,
    name: "BoringPhone",
    brand: "BoringPhone",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "BoringPhone",
    release_date: null,
  },
  {
    id: 2697,
    name: "Ubuntu Edge",
    brand: "Canonical",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Ubuntu Edge",
    release_date: null,
  },
  {
    id: 1510,
    name: "CAT S62 Pro",
    brand: "CAT",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "CAT S62 Pro",
    release_date: null,
  },
  {
    id: 1509,
    name: "CAT S22 Flip",
    brand: "CAT",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "CAT S22 Flip",
    release_date: null,
  },
  {
    id: 1514,
    name: "Cherry Mobile Flare S8 Lite",
    brand: "Cherry Mobile",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Cherry Mobile Flare S8 Lite",
    release_date: null,
  },
  {
    id: 1513,
    name: "Cherry Mobile Flare S8 Plus",
    brand: "Cherry Mobile",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Cherry Mobile Flare S8 Plus",
    release_date: null,
  },
  {
    id: 1512,
    name: "Cherry Mobile Flare S8 Pro",
    brand: "Cherry Mobile",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Cherry Mobile Flare S8 Pro",
    release_date: null,
  },
  {
    id: 1511,
    name: "Cherry Mobile Flare S8 Deluxe",
    brand: "Cherry Mobile",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Cherry Mobile Flare S8 Deluxe",
    release_date: null,
  },
  {
    id: 2833,
    name: "Model",
    brand: "Developer",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Model",
    release_date: null,
  },
  {
    id: 2829,
    name: "Model",
    brand: "Developer",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Model",
    release_date: null,
  },
  {
    id: 2753,
    name: "Model",
    brand: "Developer",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Model",
    release_date: null,
  },
  {
    id: 2700,
    name: "Model",
    brand: "Developer",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Model",
    release_date: null,
  },
  {
    id: 2696,
    name: "Model",
    brand: "Developer",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Model",
    release_date: null,
  },
  {
    id: 2656,
    name: "Model",
    brand: "Developer",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Model",
    release_date: null,
  },
  {
    id: 2491,
    name: "Model",
    brand: "Developer",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Model",
    release_date: null,
  },
  {
    id: 2324,
    name: "Model",
    brand: "Developer",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Model",
    release_date: null,
  },
  {
    id: 2322,
    name: "Model",
    brand: "Developer",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Model",
    release_date: null,
  },
  {
    id: 2306,
    name: "Model",
    brand: "Developer",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Model",
    release_date: null,
  },
  {
    id: 2191,
    name: "Model",
    brand: "Developer",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Model",
    release_date: null,
  },
  {
    id: 2089,
    name: "Model",
    brand: "Developer",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Model",
    release_date: null,
  },
  {
    id: 1929,
    name: "Model",
    brand: "Developer",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Model",
    release_date: null,
  },
  {
    id: 1849,
    name: "Model",
    brand: "Developer",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Model",
    release_date: null,
  },
  {
    id: 1846,
    name: "Model",
    brand: "Developer",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Model",
    release_date: null,
  },
  {
    id: 1844,
    name: "Model",
    brand: "Developer",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Model",
    release_date: null,
  },
  {
    id: 1842,
    name: "Model",
    brand: "Developer",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Model",
    release_date: null,
  },
  {
    id: 1541,
    name: "Model",
    brand: "Developer",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Model",
    release_date: null,
  },
  {
    id: 1528,
    name: "Model",
    brand: "Developer",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Model",
    release_date: null,
  },
  {
    id: 1521,
    name: "Model",
    brand: "Developer",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Model",
    release_date: null,
  },
  {
    id: 1518,
    name: "Model",
    brand: "Developer",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Model",
    release_date: null,
  },
  {
    id: 1516,
    name: "Model",
    brand: "Developer",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Model",
    release_date: null,
  },
  {
    id: 1508,
    name: "Model",
    brand: "Developer",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Model",
    release_date: null,
  },
  {
    id: 1496,
    name: "Model",
    brand: "Developer",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Model",
    release_date: null,
  },
  {
    id: 1455,
    name: "Model",
    brand: "Developer",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Model",
    release_date: null,
  },
  {
    id: 1517,
    name: "Dior Phone Touch/Dior Revires",
    brand: "Dior",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Dior Phone Touch/Dior Revires",
    release_date: null,
  },
  {
    id: 1519,
    name: "Essential PH-1",
    brand: "Essential Products",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Essential PH-1",
    release_date: null,
  },
  {
    id: 1520,
    name: "Eye",
    brand: "ESTI",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Eye",
    release_date: null,
  },
  {
    id: 1526,
    name: "Fairphone 4",
    brand: "FairPhone",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Fairphone 4",
    release_date: null,
  },
  {
    id: 1525,
    name: "Fairphone 3+",
    brand: "FairPhone",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Fairphone 3+",
    release_date: null,
  },
  {
    id: 1524,
    name: "Fairphone 3",
    brand: "FairPhone",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Fairphone 3",
    release_date: null,
  },
  {
    id: 1523,
    name: "Fairphone 2",
    brand: "FairPhone",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Fairphone 2",
    release_date: null,
  },
  {
    id: 1522,
    name: "Fairphone 1",
    brand: "FairPhone",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Fairphone 1",
    release_date: null,
  },
  {
    id: 1527,
    name: "Freedom Phone",
    brand: "Freedom Phone",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Freedom Phone",
    release_date: null,
  },
  {
    id: 1533,
    name: "Garmin Nüvifone A50/Garminfone",
    brand: "Garmin",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Garmin Nüvifone A50/Garminfone",
    release_date: null,
  },
  {
    id: 1532,
    name: "Garmin Nüvifone A10",
    brand: "Garmin",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Garmin Nüvifone A10",
    release_date: null,
  },
  {
    id: 1534,
    name: "Geeksphone Revolution",
    brand: "Geeksphone",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Geeksphone Revolution",
    release_date: null,
  },
  {
    id: 1529,
    name: "Geeksphone One",
    brand: "Geeksphone",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Geeksphone One",
    release_date: null,
  },
  {
    id: 1530,
    name: "Blackphone",
    brand: "Geeksphone & Silent Circle",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Blackphone",
    release_date: null,
  },
  {
    id: 2321,
    name: "Pixel 6 Pro",
    brand: "Google",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Pixel 6 Pro",
    release_date: null,
  },
  {
    id: 2320,
    name: "Pixel 6",
    brand: "Google",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Pixel 6",
    release_date: null,
  },
  {
    id: 2319,
    name: "Pixel 5a",
    brand: "Google",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Pixel 5a",
    release_date: null,
  },
  {
    id: 2318,
    name: "Pixel 5",
    brand: "Google",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Pixel 5",
    release_date: null,
  },
  {
    id: 2317,
    name: "Pixel 4a (5G)",
    brand: "Google",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Pixel 4a (5G)",
    release_date: null,
  },
  {
    id: 2316,
    name: "Pixel 4a",
    brand: "Google",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Pixel 4a",
    release_date: null,
  },
  {
    id: 2315,
    name: "Pixel 4/XL",
    brand: "Google",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Pixel 4/XL",
    release_date: null,
  },
  {
    id: 2314,
    name: "Pixel 3a/XL",
    brand: "Google",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Pixel 3a/XL",
    release_date: null,
  },
  {
    id: 2313,
    name: "Pixel 3/XL",
    brand: "Google",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Pixel 3/XL",
    release_date: null,
  },
  {
    id: 2312,
    name: "Pixel 2/XL",
    brand: "Google",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Pixel 2/XL",
    release_date: null,
  },
  {
    id: 2311,
    name: "Pixel/XL",
    brand: "Google",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Pixel/XL",
    release_date: null,
  },
  {
    id: 1540,
    name: "Goophone 12 Pro",
    brand: "Goophone",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Goophone 12 Pro",
    release_date: null,
  },
  {
    id: 1539,
    name: "Goophone i5",
    brand: "Goophone",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Goophone i5",
    release_date: null,
  },
  {
    id: 1538,
    name: "Goophone i6",
    brand: "Goophone",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Goophone i6",
    release_date: null,
  },
  {
    id: 1537,
    name: "Goophone i5S",
    brand: "Goophone",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Goophone i5S",
    release_date: null,
  },
  {
    id: 1536,
    name: "Goophone i5C",
    brand: "Goophone",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Goophone i5C",
    release_date: null,
  },
  {
    id: 1535,
    name: "Goophone X",
    brand: "Goophone",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Goophone X",
    release_date: null,
  },
  {
    id: 1531,
    name: "Goophone X",
    brand: "Goophone",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Goophone X",
    release_date: null,
  },
  {
    id: 2150,
    name: "Nokia XR20",
    brand: "HMD Global",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Nokia XR20",
    release_date: null,
  },
  {
    id: 2149,
    name: "Nokia C30",
    brand: "HMD Global",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Nokia C30",
    release_date: null,
  },
  {
    id: 2148,
    name: "Nokia C20 Plus",
    brand: "HMD Global",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Nokia C20 Plus",
    release_date: null,
  },
  {
    id: 2147,
    name: "Nokia C01 Plus",
    brand: "HMD Global",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Nokia C01 Plus",
    release_date: null,
  },
  {
    id: 2146,
    name: "Nokia X10",
    brand: "HMD Global",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Nokia X10",
    release_date: null,
  },
  {
    id: 2145,
    name: "Nokia C10",
    brand: "HMD Global",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Nokia C10",
    release_date: null,
  },
  {
    id: 2144,
    name: "Nokia X20",
    brand: "HMD Global",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Nokia X20",
    release_date: null,
  },
  {
    id: 2143,
    name: "Nokia G20",
    brand: "HMD Global",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Nokia G20",
    release_date: null,
  },
  {
    id: 2142,
    name: "Nokia G10",
    brand: "HMD Global",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Nokia G10",
    release_date: null,
  },
  {
    id: 2141,
    name: "Nokia C20",
    brand: "HMD Global",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Nokia C20",
    release_date: null,
  },
  {
    id: 2140,
    name: "Nokia 1.4",
    brand: "HMD Global",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Nokia 1.4",
    release_date: null,
  },
  {
    id: 2139,
    name: "Nokia 5.4",
    brand: "HMD Global",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Nokia 5.4",
    release_date: null,
  },
  {
    id: 2138,
    name: "Nokia C1 Plus",
    brand: "HMD Global",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Nokia C1 Plus",
    release_date: null,
  },
  {
    id: 2137,
    name: "Nokia 3.4",
    brand: "HMD Global",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Nokia 3.4",
    release_date: null,
  },
  {
    id: 2136,
    name: "Nokia 2.4",
    brand: "HMD Global",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Nokia 2.4",
    release_date: null,
  },
  {
    id: 2135,
    name: "Nokia C3",
    brand: "HMD Global",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Nokia C3",
    release_date: null,
  },
  {
    id: 2134,
    name: "Nokia C2 Tennen",
    brand: "HMD Global",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Nokia C2 Tennen",
    release_date: null,
  },
  {
    id: 2133,
    name: "Nokia C5 Endi",
    brand: "HMD Global",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Nokia C5 Endi",
    release_date: null,
  },
  {
    id: 2132,
    name: "Nokia C2 Tava",
    brand: "HMD Global",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Nokia C2 Tava",
    release_date: null,
  },
  {
    id: 2131,
    name: "Nokia 5.3",
    brand: "HMD Global",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Nokia 5.3",
    release_date: null,
  },
  {
    id: 2130,
    name: "Nokia 1.3",
    brand: "HMD Global",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Nokia 1.3",
    release_date: null,
  },
  {
    id: 2129,
    name: "Nokia C2",
    brand: "HMD Global",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Nokia C2",
    release_date: null,
  },
  {
    id: 2128,
    name: "Nokia C1",
    brand: "HMD Global",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Nokia C1",
    release_date: null,
  },
  {
    id: 2127,
    name: "Nokia 2.3",
    brand: "HMD Global",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Nokia 2.3",
    release_date: null,
  },
  {
    id: 2126,
    name: "Nokia 7.2",
    brand: "HMD Global",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Nokia 7.2",
    release_date: null,
  },
  {
    id: 2125,
    name: "Nokia 6.2",
    brand: "HMD Global",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Nokia 6.2",
    release_date: null,
  },
  {
    id: 2124,
    name: "Nokia 2.2",
    brand: "HMD Global",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Nokia 2.2",
    release_date: null,
  },
  {
    id: 2123,
    name: "Nokia 3.2",
    brand: "HMD Global",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Nokia 3.2",
    release_date: null,
  },
  {
    id: 2122,
    name: "Nokia 4.2",
    brand: "HMD Global",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Nokia 4.2",
    release_date: null,
  },
  {
    id: 2121,
    name: "Nokia 1 Plus",
    brand: "HMD Global",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Nokia 1 Plus",
    release_date: null,
  },
  {
    id: 2120,
    name: "Nokia 9 PureView",
    brand: "HMD Global",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Nokia 9 PureView",
    release_date: null,
  },
  {
    id: 2119,
    name: "Nokia 8.1",
    brand: "HMD Global",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Nokia 8.1",
    release_date: null,
  },
  {
    id: 2118,
    name: "Nokia 7.1",
    brand: "HMD Global",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Nokia 7.1",
    release_date: null,
  },
  {
    id: 2117,
    name: "Nokia 3.1 Plus",
    brand: "HMD Global",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Nokia 3.1 Plus",
    release_date: null,
  },
  {
    id: 2116,
    name: "Nokia 6.1 Plus",
    brand: "HMD Global",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Nokia 6.1 Plus",
    release_date: null,
  },
  {
    id: 2115,
    name: "Nokia 5.1",
    brand: "HMD Global",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Nokia 5.1",
    release_date: null,
  },
  {
    id: 2114,
    name: "Nokia 2.1",
    brand: "HMD Global",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Nokia 2.1",
    release_date: null,
  },
  {
    id: 2113,
    name: "Nokia 5.1 Plus",
    brand: "HMD Global",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Nokia 5.1 Plus",
    release_date: null,
  },
  {
    id: 2112,
    name: "Nokia 3.1",
    brand: "HMD Global",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Nokia 3.1",
    release_date: null,
  },
  {
    id: 2111,
    name: "Nokia 8 Sirocco",
    brand: "HMD Global",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Nokia 8 Sirocco",
    release_date: null,
  },
  {
    id: 2110,
    name: "Nokia 6.1",
    brand: "HMD Global",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Nokia 6.1",
    release_date: null,
  },
  {
    id: 2109,
    name: "Nokia 1",
    brand: "HMD Global",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Nokia 1",
    release_date: null,
  },
  {
    id: 2108,
    name: "Nokia 7 Plus",
    brand: "HMD Global",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Nokia 7 Plus",
    release_date: null,
  },
  {
    id: 2107,
    name: "Nokia 2",
    brand: "HMD Global",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Nokia 2",
    release_date: null,
  },
  {
    id: 2106,
    name: "Nokia 8",
    brand: "HMD Global",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Nokia 8",
    release_date: null,
  },
  {
    id: 2105,
    name: "Nokia 7",
    brand: "HMD Global",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Nokia 7",
    release_date: null,
  },
  {
    id: 2104,
    name: "Nokia 5",
    brand: "HMD Global",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Nokia 5",
    release_date: null,
  },
  {
    id: 2103,
    name: "Nokia 3",
    brand: "HMD Global",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Nokia 3",
    release_date: null,
  },
  {
    id: 2102,
    name: "Nokia 6",
    brand: "HMD Global",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Nokia 6",
    release_date: null,
  },
  {
    id: 1786,
    name: "Honor Play 30 Plus",
    brand: "Honor",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Honor Play 30 Plus",
    release_date: null,
  },
  {
    id: 1785,
    name: "Honor X30",
    brand: "Honor",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Honor X30",
    release_date: null,
  },
  {
    id: 1784,
    name: "Honor 60/Pro",
    brand: "Honor",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Honor 60/Pro",
    release_date: null,
  },
  {
    id: 1783,
    name: "Honor X30 Max/X30i",
    brand: "Honor",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Honor X30 Max/X30i",
    release_date: null,
  },
  {
    id: 1782,
    name: "Honor 50 Lite",
    brand: "Honor",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Honor 50 Lite",
    release_date: null,
  },
  {
    id: 1781,
    name: "Honor Play 5 Youth",
    brand: "Honor",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Honor Play 5 Youth",
    release_date: null,
  },
  {
    id: 1780,
    name: "Honor X20",
    brand: "Honor",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Honor X20",
    release_date: null,
  },
  {
    id: 1779,
    name: "Honor Magic3/Pro/Pro+",
    brand: "Honor",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Honor Magic3/Pro/Pro+",
    release_date: null,
  },
  {
    id: 1778,
    name: "Honor Play 5T Pro",
    brand: "Honor",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Honor Play 5T Pro",
    release_date: null,
  },
  {
    id: 1777,
    name: "Honor X20 SE",
    brand: "Honor",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Honor X20 SE",
    release_date: null,
  },
  {
    id: 1776,
    name: "Honor 50 SE",
    brand: "Honor",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Honor 50 SE",
    release_date: null,
  },
  {
    id: 1775,
    name: "Honor 50/Pro",
    brand: "Honor",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Honor 50/Pro",
    release_date: null,
  },
  {
    id: 1774,
    name: "Honor Play 5",
    brand: "Honor",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Honor Play 5",
    release_date: null,
  },
  {
    id: 1773,
    name: "Honor Play 20",
    brand: "Honor",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Honor Play 20",
    release_date: null,
  },
  {
    id: 1772,
    name: "Honor Play 5T Youth",
    brand: "Honor",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Honor Play 5T Youth",
    release_date: null,
  },
  {
    id: 1771,
    name: "Honor V40 Lite",
    brand: "Honor",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Honor V40 Lite",
    release_date: null,
  },
  {
    id: 1770,
    name: "Honor V40",
    brand: "Honor",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Honor V40",
    release_date: null,
  },
  {
    id: 1693,
    name: "HTC Wildfire E3",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC Wildfire E3",
    release_date: null,
  },
  {
    id: 1692,
    name: "HTC Desire 21 Pro",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC Desire 21 Pro",
    release_date: null,
  },
  {
    id: 1691,
    name: "HTC Desire 20+",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC Desire 20+",
    release_date: null,
  },
  {
    id: 1690,
    name: "HTC U20 5G",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC U20 5G",
    release_date: null,
  },
  {
    id: 1689,
    name: "HTC Wildfire E2",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC Wildfire E2",
    release_date: null,
  },
  {
    id: 1688,
    name: "HTC Desire 20 Pro",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC Desire 20 Pro",
    release_date: null,
  },
  {
    id: 1687,
    name: "HTC Wildfire R70",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC Wildfire R70",
    release_date: null,
  },
  {
    id: 1686,
    name: "HTC Desire 19s",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC Desire 19s",
    release_date: null,
  },
  {
    id: 1685,
    name: "HTC Exodus 1s",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC Exodus 1s",
    release_date: null,
  },
  {
    id: 1684,
    name: "HTC Wildfire X",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC Wildfire X",
    release_date: null,
  },
  {
    id: 1683,
    name: "HTC U19e",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC U19e",
    release_date: null,
  },
  {
    id: 1682,
    name: "HTC Desire 19+",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC Desire 19+",
    release_date: null,
  },
  {
    id: 1681,
    name: "HTC Desire 12s",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC Desire 12s",
    release_date: null,
  },
  {
    id: 1680,
    name: "HTC Exodus 1",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC Exodus 1",
    release_date: null,
  },
  {
    id: 1679,
    name: "HTC Desire 12/12+",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC Desire 12/12+",
    release_date: null,
  },
  {
    id: 1678,
    name: "HTC U12 life",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC U12 life",
    release_date: null,
  },
  {
    id: 1677,
    name: "HTC U12+",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC U12+",
    release_date: null,
  },
  {
    id: 1676,
    name: "HTC U11 EYEs",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC U11 EYEs",
    release_date: null,
  },
  {
    id: 1675,
    name: "HTC U11+/Life",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC U11+/Life",
    release_date: null,
  },
  {
    id: 1674,
    name: "HTC One X10",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC One X10",
    release_date: null,
  },
  {
    id: 1673,
    name: "HTC U11",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC U11",
    release_date: null,
  },
  {
    id: 1672,
    name: "HTC U Ultra",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC U Ultra",
    release_date: null,
  },
  {
    id: 1671,
    name: "HTC U Play",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC U Play",
    release_date: null,
  },
  {
    id: 1670,
    name: "HTC Desire 650",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC Desire 650",
    release_date: null,
  },
  {
    id: 1669,
    name: "HTC Desire 10 Pro",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC Desire 10 Pro",
    release_date: null,
  },
  {
    id: 1668,
    name: "HTC 10 evo",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC 10 evo",
    release_date: null,
  },
  {
    id: 1667,
    name: "HTC One A9s",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC One A9s",
    release_date: null,
  },
  {
    id: 1666,
    name: "HTC Desire 728 Ultra",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC Desire 728 Ultra",
    release_date: null,
  },
  {
    id: 1665,
    name: "HTC Desire 10 Lifestyle",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC Desire 10 Lifestyle",
    release_date: null,
  },
  {
    id: 1664,
    name: "HTC Desire 625",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC Desire 625",
    release_date: null,
  },
  {
    id: 1663,
    name: "HTC Desire 628",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC Desire 628",
    release_date: null,
  },
  {
    id: 1662,
    name: "HTC One S9",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC One S9",
    release_date: null,
  },
  {
    id: 1661,
    name: "HTC 10 Lifestyle",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC 10 Lifestyle",
    release_date: null,
  },
  {
    id: 1660,
    name: "HTC Desire 630",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC Desire 630",
    release_date: null,
  },
  {
    id: 1659,
    name: "HTC One M9 Prime Camera",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC One M9 Prime Camera",
    release_date: null,
  },
  {
    id: 1658,
    name: "HTC Desire 830",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC Desire 830",
    release_date: null,
  },
  {
    id: 1657,
    name: "HTC 10",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC 10",
    release_date: null,
  },
  {
    id: 1656,
    name: "HTC Desire 825",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC Desire 825",
    release_date: null,
  },
  {
    id: 1655,
    name: "HTC Desire 530",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC Desire 530",
    release_date: null,
  },
  {
    id: 1654,
    name: "HTC One X9",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC One X9",
    release_date: null,
  },
  {
    id: 1653,
    name: "HTC One M9s",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC One M9s",
    release_date: null,
  },
  {
    id: 1652,
    name: "HTC Desire 828",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC Desire 828",
    release_date: null,
  },
  {
    id: 1651,
    name: "HTC Desire 728",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC Desire 728",
    release_date: null,
  },
  {
    id: 1650,
    name: "HTC Desire 520",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC Desire 520",
    release_date: null,
  },
  {
    id: 1649,
    name: "HTC One A9",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC One A9",
    release_date: null,
  },
  {
    id: 1648,
    name: "HTC One E9s",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC One E9s",
    release_date: null,
  },
  {
    id: 1647,
    name: "HTC Butterfly 3",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC Butterfly 3",
    release_date: null,
  },
  {
    id: 1646,
    name: "HTC One M9+ Supreme Camera Edition",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC One M9+ Supreme Camera Edition",
    release_date: null,
  },
  {
    id: 1645,
    name: "HTC Desire 626 (USA)",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC Desire 626 (USA)",
    release_date: null,
  },
  {
    id: 1644,
    name: "HTC Desire 526",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC Desire 526",
    release_date: null,
  },
  {
    id: 1643,
    name: "HTC Desire 626s",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC Desire 626s",
    release_date: null,
  },
  {
    id: 1642,
    name: "HTC One ME",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC One ME",
    release_date: null,
  },
  {
    id: 1641,
    name: "HTC Desire 820G+",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC Desire 820G+",
    release_date: null,
  },
  {
    id: 1640,
    name: "HTC Desire 326G",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC Desire 326G",
    release_date: null,
  },
  {
    id: 1639,
    name: "HTC One M9+",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC One M9+",
    release_date: null,
  },
  {
    id: 1638,
    name: "HTC One M8s",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC One M8s",
    release_date: null,
  },
  {
    id: 1637,
    name: "HTC One E9/+",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC One E9/+",
    release_date: null,
  },
  {
    id: 1636,
    name: "HTC One M9",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC One M9",
    release_date: null,
  },
  {
    id: 1635,
    name: "HTC Desire 626G+",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC Desire 626G+",
    release_date: null,
  },
  {
    id: 1634,
    name: "HTC Desire 820s",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC Desire 820s",
    release_date: null,
  },
  {
    id: 1633,
    name: "HTC Desire 626",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC Desire 626",
    release_date: null,
  },
  {
    id: 1632,
    name: "HTC Desire 826",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC Desire 826",
    release_date: null,
  },
  {
    id: 1631,
    name: "HTC Desire 526G+",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC Desire 526G+",
    release_date: null,
  },
  {
    id: 1630,
    name: "HTC Desire 320",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC Desire 320",
    release_date: null,
  },
  {
    id: 1629,
    name: "HTC Desire 620/G",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC Desire 620/G",
    release_date: null,
  },
  {
    id: 1628,
    name: "HTC Desire Eye",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC Desire Eye",
    release_date: null,
  },
  {
    id: 1627,
    name: "HTC Desire 820",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC Desire 820",
    release_date: null,
  },
  {
    id: 1626,
    name: "HTC Desire 816G",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC Desire 816G",
    release_date: null,
  },
  {
    id: 1625,
    name: "HTC One (M8 Eye)",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC One (M8 Eye)",
    release_date: null,
  },
  {
    id: 1624,
    name: "HTC Desire 612",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC Desire 612",
    release_date: null,
  },
  {
    id: 1623,
    name: "HTC Desire 820q",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC Desire 820q",
    release_date: null,
  },
  {
    id: 1622,
    name: "HTC Desire 510",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC Desire 510",
    release_date: null,
  },
  {
    id: 1621,
    name: "HTC Butterfly 2",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC Butterfly 2",
    release_date: null,
  },
  {
    id: 1620,
    name: "HTC One Remix",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC One Remix",
    release_date: null,
  },
  {
    id: 1619,
    name: "HTC Desire 516",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC Desire 516",
    release_date: null,
  },
  {
    id: 1618,
    name: "HTC One (E8)",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC One (E8)",
    release_date: null,
  },
  {
    id: 1617,
    name: "HTC Desire 616",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC Desire 616",
    release_date: null,
  },
  {
    id: 1616,
    name: "HTC Desire 210",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC Desire 210",
    release_date: null,
  },
  {
    id: 1615,
    name: "HTC One Mini 2",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC One Mini 2",
    release_date: null,
  },
  {
    id: 1614,
    name: "HTC Desire 816 dual sim",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC Desire 816 dual sim",
    release_date: null,
  },
  {
    id: 1613,
    name: "HTC Desire 610",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC Desire 610",
    release_date: null,
  },
  {
    id: 1612,
    name: "HTC Desire 310",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC Desire 310",
    release_date: null,
  },
  {
    id: 1611,
    name: "HTC One (M8)",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC One (M8)",
    release_date: null,
  },
  {
    id: 1610,
    name: "HTC Desire 816",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC Desire 816",
    release_date: null,
  },
  {
    id: 1609,
    name: "HTC Desire 501",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC Desire 501",
    release_date: null,
  },
  {
    id: 1608,
    name: "HTC Desire 700",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC Desire 700",
    release_date: null,
  },
  {
    id: 1607,
    name: "HTC Desire 601 dual sim",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC Desire 601 dual sim",
    release_date: null,
  },
  {
    id: 1606,
    name: "HTC Desire 300",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC Desire 300",
    release_date: null,
  },
  {
    id: 1605,
    name: "HTC Desire 601",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC Desire 601",
    release_date: null,
  },
  {
    id: 1604,
    name: "HTC One Max",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC One Max",
    release_date: null,
  },
  {
    id: 1603,
    name: "HTC Desire 500",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC Desire 500",
    release_date: null,
  },
  {
    id: 1602,
    name: "HTC One Mini",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC One Mini",
    release_date: null,
  },
  {
    id: 1601,
    name: "HTC Butterfly S",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC Butterfly S",
    release_date: null,
  },
  {
    id: 1600,
    name: "HTC Desire 200",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC Desire 200",
    release_date: null,
  },
  {
    id: 1599,
    name: "HTC Desire 600",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC Desire 600",
    release_date: null,
  },
  {
    id: 1598,
    name: "HTC Desire U",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC Desire U",
    release_date: null,
  },
  {
    id: 1597,
    name: "HTC Desire L/P/Q",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC Desire L/P/Q",
    release_date: null,
  },
  {
    id: 1596,
    name: "HTC First",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC First",
    release_date: null,
  },
  {
    id: 1595,
    name: "HTC One (M7)",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC One (M7)",
    release_date: null,
  },
  {
    id: 1594,
    name: "HTC Butterfly",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC Butterfly",
    release_date: null,
  },
  {
    id: 1593,
    name: "HTC One SV",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC One SV",
    release_date: null,
  },
  {
    id: 1592,
    name: "HTC Desire 400",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC Desire 400",
    release_date: null,
  },
  {
    id: 1591,
    name: "HTC Desire SV",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC Desire SV",
    release_date: null,
  },
  {
    id: 1590,
    name: "HTC One X+",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC One X+",
    release_date: null,
  },
  {
    id: 1589,
    name: "HTC One VX",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC One VX",
    release_date: null,
  },
  {
    id: 1588,
    name: "HTC Desire X",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC Desire X",
    release_date: null,
  },
  {
    id: 1587,
    name: "HTC Desire VT",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC Desire VT",
    release_date: null,
  },
  {
    id: 1586,
    name: "HTC J",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC J",
    release_date: null,
  },
  {
    id: 1585,
    name: "HTC One XC",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC One XC",
    release_date: null,
  },
  {
    id: 1584,
    name: "HTC Desire XC",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC Desire XC",
    release_date: null,
  },
  {
    id: 1583,
    name: "HTC Desire VC",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC Desire VC",
    release_date: null,
  },
  {
    id: 1582,
    name: "HTC Droid Incredible 4G LTE",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC Droid Incredible 4G LTE",
    release_date: null,
  },
  {
    id: 1581,
    name: "HTC Desire V",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC Desire V",
    release_date: null,
  },
  {
    id: 1580,
    name: "HTC Desire C",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC Desire C",
    release_date: null,
  },
  {
    id: 1579,
    name: "HTC One S C2",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC One S C2",
    release_date: null,
  },
  {
    id: 1578,
    name: "HTC Evo 4G LTE",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC Evo 4G LTE",
    release_date: null,
  },
  {
    id: 1577,
    name: "HTC One X/XL",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC One X/XL",
    release_date: null,
  },
  {
    id: 1576,
    name: "HTC One V",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC One V",
    release_date: null,
  },
  {
    id: 1575,
    name: "HTC One S",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC One S",
    release_date: null,
  },
  {
    id: 1574,
    name: "HTC Rezound",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC Rezound",
    release_date: null,
  },
  {
    id: 1573,
    name: "HTC Sensation XL",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC Sensation XL",
    release_date: null,
  },
  {
    id: 1572,
    name: "HTC Evo Design 4G",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC Evo Design 4G",
    release_date: null,
  },
  {
    id: 1571,
    name: "HTC Raider 4G",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC Raider 4G",
    release_date: null,
  },
  {
    id: 1570,
    name: "HTC Rhyme",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC Rhyme",
    release_date: null,
  },
  {
    id: 1569,
    name: "HTC Sensation XE",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC Sensation XE",
    release_date: null,
  },
  {
    id: 1568,
    name: "HTC Explorer",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC Explorer",
    release_date: null,
  },
  {
    id: 1567,
    name: "HTC Amaze 4G",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC Amaze 4G",
    release_date: null,
  },
  {
    id: 1566,
    name: "HTC Evo 3D",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC Evo 3D",
    release_date: null,
  },
  {
    id: 1565,
    name: "HTC Salsa",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC Salsa",
    release_date: null,
  },
  {
    id: 1564,
    name: "HTC ChaCha",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC ChaCha",
    release_date: null,
  },
  {
    id: 1563,
    name: "HTC Wildfire S",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC Wildfire S",
    release_date: null,
  },
  {
    id: 1562,
    name: "HTC Sensation",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC Sensation",
    release_date: null,
  },
  {
    id: 1561,
    name: "HTC Merge",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC Merge",
    release_date: null,
  },
  {
    id: 1560,
    name: "HTC Desire S",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC Desire S",
    release_date: null,
  },
  {
    id: 1559,
    name: "HTC ThunderBolt",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC ThunderBolt",
    release_date: null,
  },
  {
    id: 1558,
    name: "HTC Incredible S",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC Incredible S",
    release_date: null,
  },
  {
    id: 1557,
    name: "HTC Inspire 4G",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC Inspire 4G",
    release_date: null,
  },
  {
    id: 1556,
    name: "HTC Evo Shift 4G",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC Evo Shift 4G",
    release_date: null,
  },
  {
    id: 1555,
    name: "HTC Gratia",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC Gratia",
    release_date: null,
  },
  {
    id: 1554,
    name: "HTC Desire Z",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC Desire Z",
    release_date: null,
  },
  {
    id: 1553,
    name: "HTC Desire HD",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC Desire HD",
    release_date: null,
  },
  {
    id: 1552,
    name: "HTC Evo 4G+",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC Evo 4G+",
    release_date: null,
  },
  {
    id: 1551,
    name: "HTC Evo 4G",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC Evo 4G",
    release_date: null,
  },
  {
    id: 1550,
    name: "HTC Aria",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC Aria",
    release_date: null,
  },
  {
    id: 1549,
    name: "HTC Wildfire",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC Wildfire",
    release_date: null,
  },
  {
    id: 1548,
    name: "HTC Droid Incredible",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC Droid Incredible",
    release_date: null,
  },
  {
    id: 1547,
    name: "HTC Legend",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC Legend",
    release_date: null,
  },
  {
    id: 1546,
    name: "HTC Desire",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC Desire",
    release_date: null,
  },
  {
    id: 1545,
    name: "HTC Tattoo",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC Tattoo",
    release_date: null,
  },
  {
    id: 1544,
    name: "HTC Hero",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC Hero",
    release_date: null,
  },
  {
    id: 1543,
    name: "HTC Magic",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC Magic",
    release_date: null,
  },
  {
    id: 1542,
    name: "HTC Dream",
    brand: "HTC",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC Dream",
    release_date: null,
  },
  {
    id: 2091,
    name: "Nexus One",
    brand: "HTC/Google",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Nexus One",
    release_date: null,
  },
  {
    id: 1694,
    name: "HTC/Google Sooner",
    brand: "HTC/Google",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "HTC/Google Sooner",
    release_date: null,
  },
  {
    id: 1841,
    name: "Huawei Enjoy 20e",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Huawei Enjoy 20e",
    release_date: null,
  },
  {
    id: 1840,
    name: "Huawei Nova 9",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Huawei Nova 9",
    release_date: null,
  },
  {
    id: 1839,
    name: "Huawei Nova 8 SE Youth",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Huawei Nova 8 SE Youth",
    release_date: null,
  },
  {
    id: 1838,
    name: "Huawei Nova 8i",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Huawei Nova 8i",
    release_date: null,
  },
  {
    id: 1837,
    name: "Huawei Nova 8 Pro 4G",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Huawei Nova 8 Pro 4G",
    release_date: null,
  },
  {
    id: 1836,
    name: "Huawei Mate X2",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Huawei Mate X2",
    release_date: null,
  },
  {
    id: 1835,
    name: "Huawei Nova 8 5G/Pro",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Huawei Nova 8 5G/Pro",
    release_date: null,
  },
  {
    id: 1834,
    name: "Huawei Nova 8 SE",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Huawei Nova 8 SE",
    release_date: null,
  },
  {
    id: 1833,
    name: "Huawei Mate 40",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Huawei Mate 40",
    release_date: null,
  },
  {
    id: 1832,
    name: "Huawei Nova 7 SE 5G Youth",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Huawei Nova 7 SE 5G Youth",
    release_date: null,
  },
  {
    id: 1831,
    name: "Huawei P40 lite 5G",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Huawei P40 lite 5G",
    release_date: null,
  },
  {
    id: 1830,
    name: "Huawei Nova 7/Pro/SE",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Huawei Nova 7/Pro/SE",
    release_date: null,
  },
  {
    id: 1829,
    name: "Huawei P40",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Huawei P40",
    release_date: null,
  },
  {
    id: 1828,
    name: "Huawei P40 lite E",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Huawei P40 lite E",
    release_date: null,
  },
  {
    id: 1827,
    name: "Huawei P40 lite",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Huawei P40 lite",
    release_date: null,
  },
  {
    id: 1826,
    name: "Huawei Nova 7i",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Huawei Nova 7i",
    release_date: null,
  },
  {
    id: 1825,
    name: "Huawei Y7p",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Huawei Y7p",
    release_date: null,
  },
  {
    id: 1824,
    name: "Huawei Nova 6/5G/SE",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Huawei Nova 6/5G/SE",
    release_date: null,
  },
  {
    id: 1823,
    name: "Huawei Nova 5z",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Huawei Nova 5z",
    release_date: null,
  },
  {
    id: 1822,
    name: "Huawei Mate 30",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Huawei Mate 30",
    release_date: null,
  },
  {
    id: 1821,
    name: "Huawei Nova 5T",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Huawei Nova 5T",
    release_date: null,
  },
  {
    id: 1820,
    name: "Huawei Nova 5i Pro",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Huawei Nova 5i Pro",
    release_date: null,
  },
  {
    id: 1819,
    name: "Huawei Nova 5/5i/Pro",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Huawei Nova 5/5i/Pro",
    release_date: null,
  },
  {
    id: 1818,
    name: "Huawei P30 lite",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Huawei P30 lite",
    release_date: null,
  },
  {
    id: 1817,
    name: "Huawei P30",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Huawei P30",
    release_date: null,
  },
  {
    id: 1816,
    name: "Huawei Nova 4e",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Huawei Nova 4e",
    release_date: null,
  },
  {
    id: 1815,
    name: "Huawei Nova 4",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Huawei Nova 4",
    release_date: null,
  },
  {
    id: 1814,
    name: "Huawei Mate 20",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Huawei Mate 20",
    release_date: null,
  },
  {
    id: 1813,
    name: "Huawei Nova 3",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Huawei Nova 3",
    release_date: null,
  },
  {
    id: 1812,
    name: "Huawei Nova 3i/P Smart+",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Huawei Nova 3i/P Smart+",
    release_date: null,
  },
  {
    id: 1811,
    name: "Huawei P20",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Huawei P20",
    release_date: null,
  },
  {
    id: 1810,
    name: "Huawei Mate 10",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Huawei Mate 10",
    release_date: null,
  },
  {
    id: 1809,
    name: "Huawei Mate 10 Lite",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Huawei Mate 10 Lite",
    release_date: null,
  },
  {
    id: 1808,
    name: "Huawei Nova 2/Plus",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Huawei Nova 2/Plus",
    release_date: null,
  },
  {
    id: 1807,
    name: "Huawei P10",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Huawei P10",
    release_date: null,
  },
  {
    id: 1806,
    name: "Huawei Mate 9",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Huawei Mate 9",
    release_date: null,
  },
  {
    id: 1805,
    name: "Huawei Nova/Plus",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Huawei Nova/Plus",
    release_date: null,
  },
  {
    id: 1804,
    name: "Huawei P9",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Huawei P9",
    release_date: null,
  },
  {
    id: 1803,
    name: "Huawei Mate 8",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Huawei Mate 8",
    release_date: null,
  },
  {
    id: 1802,
    name: "Huawei Mate S",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Huawei Mate S",
    release_date: null,
  },
  {
    id: 1801,
    name: "Huawei P8",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Huawei P8",
    release_date: null,
  },
  {
    id: 1800,
    name: "Huawei Ascend Mate 7",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Huawei Ascend Mate 7",
    release_date: null,
  },
  {
    id: 1799,
    name: "Huawei Enjoy 7 Plus",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Huawei Enjoy 7 Plus",
    release_date: null,
  },
  {
    id: 1798,
    name: "Huawei Ascend P7",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Huawei Ascend P7",
    release_date: null,
  },
  {
    id: 1797,
    name: "Huawei Ascend Mate 2 4G",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Huawei Ascend Mate 2 4G",
    release_date: null,
  },
  {
    id: 1796,
    name: "Huawei Ascend P6",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Huawei Ascend P6",
    release_date: null,
  },
  {
    id: 1795,
    name: "Huawei Ascend P2",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Huawei Ascend P2",
    release_date: null,
  },
  {
    id: 1794,
    name: "Huawei Ascend Mate",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Huawei Ascend Mate",
    release_date: null,
  },
  {
    id: 1793,
    name: "Huawei STREAM X GL07S",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Huawei STREAM X GL07S",
    release_date: null,
  },
  {
    id: 1792,
    name: "Huawei Ascend P1",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Huawei Ascend P1",
    release_date: null,
  },
  {
    id: 1791,
    name: "Huawei Sonic",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Huawei Sonic",
    release_date: null,
  },
  {
    id: 1790,
    name: "Huawei IDEOS X5",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Huawei IDEOS X5",
    release_date: null,
  },
  {
    id: 1789,
    name: "Huawei IDEOS",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Huawei IDEOS",
    release_date: null,
  },
  {
    id: 1788,
    name: "Huawei U8100",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Huawei U8100",
    release_date: null,
  },
  {
    id: 1787,
    name: "Huawei U8230",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Huawei U8230",
    release_date: null,
  },
  {
    id: 1769,
    name: "Honor 10X Lite",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Honor 10X Lite",
    release_date: null,
  },
  {
    id: 1768,
    name: "Honor 30i",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Honor 30i",
    release_date: null,
  },
  {
    id: 1767,
    name: "Honor X10 Max",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Honor X10 Max",
    release_date: null,
  },
  {
    id: 1766,
    name: "Honor 30 Lite",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Honor 30 Lite",
    release_date: null,
  },
  {
    id: 1765,
    name: "Honor Play4/Pro",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Honor Play4/Pro",
    release_date: null,
  },
  {
    id: 1764,
    name: "Honor 8S 2020",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Honor 8S 2020",
    release_date: null,
  },
  {
    id: 1763,
    name: "Honor X10",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Honor X10",
    release_date: null,
  },
  {
    id: 1762,
    name: "Honor 9C/9S/9A",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Honor 9C/9S/9A",
    release_date: null,
  },
  {
    id: 1761,
    name: "Honor 9X Lite",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Honor 9X Lite",
    release_date: null,
  },
  {
    id: 1760,
    name: "Honor 30/Pro/Pro+",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Honor 30/Pro/Pro+",
    release_date: null,
  },
  {
    id: 1759,
    name: "Honor 20e",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Honor 20e",
    release_date: null,
  },
  {
    id: 1758,
    name: "Honor 8A 2020",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Honor 8A 2020",
    release_date: null,
  },
  {
    id: 1757,
    name: "Honor Play 4T/Pro",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Honor Play 4T/Pro",
    release_date: null,
  },
  {
    id: 1756,
    name: "Honor 30S",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Honor 30S",
    release_date: null,
  },
  {
    id: 1755,
    name: "Honor Play 9A",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Honor Play 9A",
    release_date: null,
  },
  {
    id: 1754,
    name: "Honor 8A Prime",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Honor 8A Prime",
    release_date: null,
  },
  {
    id: 1753,
    name: "Honor V30/30 Pro",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Honor V30/30 Pro",
    release_date: null,
  },
  {
    id: 1752,
    name: "Honor 9X",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Honor 9X",
    release_date: null,
  },
  {
    id: 1751,
    name: "Honor 20 lite (China)",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Honor 20 lite (China)",
    release_date: null,
  },
  {
    id: 1750,
    name: "Honor 20S",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Honor 20S",
    release_date: null,
  },
  {
    id: 1749,
    name: "Honor Play 3/3e",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Honor Play 3/3e",
    release_date: null,
  },
  {
    id: 1748,
    name: "Honor 9X Pro",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Honor 9X Pro",
    release_date: null,
  },
  {
    id: 1747,
    name: "Honor 9X (China)",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Honor 9X (China)",
    release_date: null,
  },
  {
    id: 1746,
    name: "Honor 20 Pro",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Honor 20 Pro",
    release_date: null,
  },
  {
    id: 1745,
    name: "Honor 20",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Honor 20",
    release_date: null,
  },
  {
    id: 1744,
    name: "Honor 20i",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Honor 20i",
    release_date: null,
  },
  {
    id: 1743,
    name: "Honor 20 lite",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Honor 20 lite",
    release_date: null,
  },
  {
    id: 1742,
    name: "Honor 8A Pro",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Honor 8A Pro",
    release_date: null,
  },
  {
    id: 1741,
    name: "Honor Play 8A",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Honor Play 8A",
    release_date: null,
  },
  {
    id: 1740,
    name: "Honor V20",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Honor V20",
    release_date: null,
  },
  {
    id: 1739,
    name: "Honor 10 Lite",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Honor 10 Lite",
    release_date: null,
  },
  {
    id: 1738,
    name: "Honor Magic 2/3D",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Honor Magic 2/3D",
    release_date: null,
  },
  {
    id: 1737,
    name: "Honor 8C",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Honor 8C",
    release_date: null,
  },
  {
    id: 1736,
    name: "Honor 8X/Max",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Honor 8X/Max",
    release_date: null,
  },
  {
    id: 1735,
    name: "Honor Note 10",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Honor Note 10",
    release_date: null,
  },
  {
    id: 1734,
    name: "Honor Play",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Honor Play",
    release_date: null,
  },
  {
    id: 1733,
    name: "Honor 9N",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Honor 9N",
    release_date: null,
  },
  {
    id: 1732,
    name: "Honor 7S",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Honor 7S",
    release_date: null,
  },
  {
    id: 1731,
    name: "Honor 10",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Honor 10",
    release_date: null,
  },
  {
    id: 1730,
    name: "Honor 7C",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Honor 7C",
    release_date: null,
  },
  {
    id: 1729,
    name: "Honor 7A",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Honor 7A",
    release_date: null,
  },
  {
    id: 1728,
    name: "Honor 9 Lite",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Honor 9 Lite",
    release_date: null,
  },
  {
    id: 1727,
    name: "Honor V10",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Honor V10",
    release_date: null,
  },
  {
    id: 1726,
    name: "Honor 7X",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Honor 7X",
    release_date: null,
  },
  {
    id: 1725,
    name: "Honor 6C Pro",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Honor 6C Pro",
    release_date: null,
  },
  {
    id: 1724,
    name: "Honor 9",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Honor 9",
    release_date: null,
  },
  {
    id: 1723,
    name: "Honor 6A",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Honor 6A",
    release_date: null,
  },
  {
    id: 1722,
    name: "Honor 8 Pro",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Honor 8 Pro",
    release_date: null,
  },
  {
    id: 1721,
    name: "Honor Magic",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Honor Magic",
    release_date: null,
  },
  {
    id: 1720,
    name: "Honor 6X",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Honor 6X",
    release_date: null,
  },
  {
    id: 1719,
    name: "Honor Holly 3",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Honor Holly 3",
    release_date: null,
  },
  {
    id: 1718,
    name: "Honor Note 8",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Honor Note 8",
    release_date: null,
  },
  {
    id: 1717,
    name: "Honor 8",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Honor 8",
    release_date: null,
  },
  {
    id: 1716,
    name: "Honor 5A",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Honor 5A",
    release_date: null,
  },
  {
    id: 1715,
    name: "Honor V8",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Honor V8",
    release_date: null,
  },
  {
    id: 1714,
    name: "Honor 5c",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Honor 5c",
    release_date: null,
  },
  {
    id: 1713,
    name: "Honor Holly 2 Plus",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Honor Holly 2 Plus",
    release_date: null,
  },
  {
    id: 1712,
    name: "Honor 5X",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Honor 5X",
    release_date: null,
  },
  {
    id: 1711,
    name: "Honor 7i",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Honor 7i",
    release_date: null,
  },
  {
    id: 1710,
    name: "Honor 7",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Honor 7",
    release_date: null,
  },
  {
    id: 1709,
    name: "Honor Bee",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Honor Bee",
    release_date: null,
  },
  {
    id: 1708,
    name: "Honor 4C",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Honor 4C",
    release_date: null,
  },
  {
    id: 1707,
    name: "Honor 6 Plus",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Honor 6 Plus",
    release_date: null,
  },
  {
    id: 1706,
    name: "Honor 4X",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Honor 4X",
    release_date: null,
  },
  {
    id: 1705,
    name: "Honor Holly",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Honor Holly",
    release_date: null,
  },
  {
    id: 1704,
    name: "Honor 4 Play",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Honor 4 Play",
    release_date: null,
  },
  {
    id: 1703,
    name: "Honor 3C Play",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Honor 3C Play",
    release_date: null,
  },
  {
    id: 1702,
    name: "Honor 6",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Honor 6",
    release_date: null,
  },
  {
    id: 1701,
    name: "Honor 3C 4G",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Honor 3C 4G",
    release_date: null,
  },
  {
    id: 1700,
    name: "Honor 3X Pro",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Honor 3X Pro",
    release_date: null,
  },
  {
    id: 1699,
    name: "Honor 3X",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Honor 3X",
    release_date: null,
  },
  {
    id: 1698,
    name: "Honor 3C",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Honor 3C",
    release_date: null,
  },
  {
    id: 1697,
    name: "Honor 3",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Honor 3",
    release_date: null,
  },
  {
    id: 1696,
    name: "Honor 2",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Honor 2",
    release_date: null,
  },
  {
    id: 1695,
    name: "Honor U8860",
    brand: "Huawei",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Honor U8860",
    release_date: null,
  },
  {
    id: 2098,
    name: "Nexus 6P",
    brand: "Huawei/Google",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Nexus 6P",
    release_date: null,
  },
  {
    id: 1843,
    name: "Intel AZ210",
    brand: "Intel",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Intel AZ210",
    release_date: null,
  },
  {
    id: 1848,
    name: "Karbonn a15",
    brand: "Karbonn",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Karbonn a15",
    release_date: null,
  },
  {
    id: 1847,
    name: "Kodak Ektra (phone)",
    brand: "Kodak",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Kodak Ektra (phone)",
    release_date: null,
  },
  {
    id: 1850,
    name: "Leitz Phone 1",
    brand: "Leica[disambiguation needed]",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Leitz Phone 1",
    release_date: null,
  },
  {
    id: 1870,
    name: "Lenovo Legion Duel 2",
    brand: "Lenovo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Lenovo Legion Duel 2",
    release_date: null,
  },
  {
    id: 1869,
    name: "Lenovo Legion 2 Pro",
    brand: "Lenovo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Lenovo Legion 2 Pro",
    release_date: null,
  },
  {
    id: 1868,
    name: "Lenovo Legion Duel",
    brand: "Lenovo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Lenovo Legion Duel",
    release_date: null,
  },
  {
    id: 1867,
    name: "Lenovo Legion Pro",
    brand: "Lenovo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Lenovo Legion Pro",
    release_date: null,
  },
  {
    id: 1866,
    name: "Lenovo Z2 Plus",
    brand: "Lenovo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Lenovo Z2 Plus",
    release_date: null,
  },
  {
    id: 1865,
    name: "Lenovo P2",
    brand: "Lenovo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Lenovo P2",
    release_date: null,
  },
  {
    id: 1864,
    name: "Lenovo K6/Power/Note",
    brand: "Lenovo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Lenovo K6/Power/Note",
    release_date: null,
  },
  {
    id: 1863,
    name: "Lenovo Phab 2/Plus/Pro",
    brand: "Lenovo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Lenovo Phab 2/Plus/Pro",
    release_date: null,
  },
  {
    id: 1862,
    name: "ZUK Z2",
    brand: "Lenovo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "ZUK Z2",
    release_date: null,
  },
  {
    id: 1861,
    name: "Lenovo Vibe K5/Plus",
    brand: "Lenovo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Lenovo Vibe K5/Plus",
    release_date: null,
  },
  {
    id: 1860,
    name: "Lenovo Vibe P1 Turbo",
    brand: "Lenovo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Lenovo Vibe P1 Turbo",
    release_date: null,
  },
  {
    id: 1859,
    name: "Lenovo K5 Note",
    brand: "Lenovo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Lenovo K5 Note",
    release_date: null,
  },
  {
    id: 1858,
    name: "Lenovo Vibe K4 Note",
    brand: "Lenovo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Lenovo Vibe K4 Note",
    release_date: null,
  },
  {
    id: 1857,
    name: "Lenovo Vibe P1",
    brand: "Lenovo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Lenovo Vibe P1",
    release_date: null,
  },
  {
    id: 1856,
    name: "ZUK Z1",
    brand: "Lenovo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "ZUK Z1",
    release_date: null,
  },
  {
    id: 1855,
    name: "Lenovo Vibe Z2",
    brand: "Lenovo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Lenovo Vibe Z2",
    release_date: null,
  },
  {
    id: 1854,
    name: "Lenovo Vibe Z2 Pro",
    brand: "Lenovo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Lenovo Vibe Z2 Pro",
    release_date: null,
  },
  {
    id: 1853,
    name: "Lenovo A526",
    brand: "Lenovo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Lenovo A526",
    release_date: null,
  },
  {
    id: 1852,
    name: "Lenovo Vibe Z",
    brand: "Lenovo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Lenovo Vibe Z",
    release_date: null,
  },
  {
    id: 1851,
    name: "Lenovo P780",
    brand: "Lenovo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Lenovo P780",
    release_date: null,
  },
  {
    id: 1928,
    name: "LG W41/+/Pro",
    brand: "LG Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "LG W41/+/Pro",
    release_date: null,
  },
  {
    id: 1927,
    name: "LG W31/+",
    brand: "LG Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "LG W31/+",
    release_date: null,
  },
  {
    id: 1926,
    name: "LG K92 5G",
    brand: "LG Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "LG K92 5G",
    release_date: null,
  },
  {
    id: 1925,
    name: "LG K52/K62",
    brand: "LG Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "LG K52/K62",
    release_date: null,
  },
  {
    id: 1924,
    name: "LG Q52",
    brand: "LG Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "LG Q52",
    release_date: null,
  },
  {
    id: 1923,
    name: "LG Wing",
    brand: "LG Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "LG Wing",
    release_date: null,
  },
  {
    id: 1922,
    name: "LG Q31",
    brand: "LG Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "LG Q31",
    release_date: null,
  },
  {
    id: 1921,
    name: "LG K42/K71",
    brand: "LG Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "LG K42/K71",
    release_date: null,
  },
  {
    id: 1920,
    name: "LG K22",
    brand: "LG Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "LG K22",
    release_date: null,
  },
  {
    id: 1919,
    name: "LG Q92 5G",
    brand: "LG Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "LG Q92 5G",
    release_date: null,
  },
  {
    id: 1918,
    name: "LG K31",
    brand: "LG Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "LG K31",
    release_date: null,
  },
  {
    id: 1917,
    name: "LG Q61",
    brand: "LG Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "LG Q61",
    release_date: null,
  },
  {
    id: 1916,
    name: "LG Stylo 6",
    brand: "LG Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "LG Stylo 6",
    release_date: null,
  },
  {
    id: 1915,
    name: "LG Velvet",
    brand: "LG Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "LG Velvet",
    release_date: null,
  },
  {
    id: 1914,
    name: "LG V60 ThinQ",
    brand: "LG Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "LG V60 ThinQ",
    release_date: null,
  },
  {
    id: 1913,
    name: "LG Q51",
    brand: "LG Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "LG Q51",
    release_date: null,
  },
  {
    id: 1912,
    name: "LG W10 Alpha",
    brand: "LG Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "LG W10 Alpha",
    release_date: null,
  },
  {
    id: 1911,
    name: "LG W30 Pro",
    brand: "LG Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "LG W30 Pro",
    release_date: null,
  },
  {
    id: 1910,
    name: "LG W10/W30",
    brand: "LG Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "LG W10/W30",
    release_date: null,
  },
  {
    id: 1909,
    name: "LG Stylo 5",
    brand: "LG Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "LG Stylo 5",
    release_date: null,
  },
  {
    id: 1908,
    name: "LG V50 ThinQ",
    brand: "LG Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "LG V50 ThinQ",
    release_date: null,
  },
  {
    id: 1907,
    name: "LG G8 ThinQ",
    brand: "LG Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "LG G8 ThinQ",
    release_date: null,
  },
  {
    id: 1906,
    name: "LG V40 ThinQ",
    brand: "LG Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "LG V40 ThinQ",
    release_date: null,
  },
  {
    id: 1905,
    name: "LG K10 (2018)",
    brand: "LG Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "LG K10 (2018)",
    release_date: null,
  },
  {
    id: 1904,
    name: "LG V35 ThinQ",
    brand: "LG Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "LG V35 ThinQ",
    release_date: null,
  },
  {
    id: 1903,
    name: "LG G7 ThinQ",
    brand: "LG Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "LG G7 ThinQ",
    release_date: null,
  },
  {
    id: 1902,
    name: "LG V30",
    brand: "LG Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "LG V30",
    release_date: null,
  },
  {
    id: 1901,
    name: "LG G6",
    brand: "LG Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "LG G6",
    release_date: null,
  },
  {
    id: 1900,
    name: "LG K10 (2017)",
    brand: "LG Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "LG K10 (2017)",
    release_date: null,
  },
  {
    id: 1899,
    name: "LG V20",
    brand: "LG Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "LG V20",
    release_date: null,
  },
  {
    id: 1898,
    name: "LG G5",
    brand: "LG Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "LG G5",
    release_date: null,
  },
  {
    id: 1897,
    name: "LG K10 (2016)",
    brand: "LG Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "LG K10 (2016)",
    release_date: null,
  },
  {
    id: 1896,
    name: "LG V10",
    brand: "LG Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "LG V10",
    release_date: null,
  },
  {
    id: 1895,
    name: "LG G4",
    brand: "LG Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "LG G4",
    release_date: null,
  },
  {
    id: 1894,
    name: "LG G Flex 2",
    brand: "LG Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "LG G Flex 2",
    release_date: null,
  },
  {
    id: 1893,
    name: "LG G3 Stylus",
    brand: "LG Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "LG G3 Stylus",
    release_date: null,
  },
  {
    id: 1892,
    name: "LG G3",
    brand: "LG Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "LG G3",
    release_date: null,
  },
  {
    id: 1891,
    name: "LG G Pro 2",
    brand: "LG Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "LG G Pro 2",
    release_date: null,
  },
  {
    id: 1890,
    name: "LG G2 Mini",
    brand: "LG Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "LG G2 Mini",
    release_date: null,
  },
  {
    id: 1889,
    name: "LG Gx",
    brand: "LG Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "LG Gx",
    release_date: null,
  },
  {
    id: 1888,
    name: "LG G Pro Lite",
    brand: "LG Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "LG G Pro Lite",
    release_date: null,
  },
  {
    id: 1887,
    name: "LG G Flex",
    brand: "LG Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "LG G Flex",
    release_date: null,
  },
  {
    id: 1886,
    name: "LG G2",
    brand: "LG Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "LG G2",
    release_date: null,
  },
  {
    id: 1885,
    name: "LG Optimus G",
    brand: "LG Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "LG Optimus G",
    release_date: null,
  },
  {
    id: 1884,
    name: "LG Optimus Vu",
    brand: "LG Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "LG Optimus Vu",
    release_date: null,
  },
  {
    id: 1883,
    name: "LG Optimus L9",
    brand: "LG Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "LG Optimus L9",
    release_date: null,
  },
  {
    id: 1882,
    name: "LG Optimus LTE (SU640)",
    brand: "LG Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "LG Optimus LTE (SU640)",
    release_date: null,
  },
  {
    id: 1881,
    name: "LG Optimus 4X HD",
    brand: "LG Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "LG Optimus 4X HD",
    release_date: null,
  },
  {
    id: 1880,
    name: "LG Optimus LTE (LU6200)",
    brand: "LG Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "LG Optimus LTE (LU6200)",
    release_date: null,
  },
  {
    id: 1879,
    name: "LG Optimus Slider",
    brand: "LG Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "LG Optimus Slider",
    release_date: null,
  },
  {
    id: 1878,
    name: "LG Optimus 3D",
    brand: "LG Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "LG Optimus 3D",
    release_date: null,
  },
  {
    id: 1877,
    name: "LG Optimus Black",
    brand: "LG Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "LG Optimus Black",
    release_date: null,
  },
  {
    id: 1876,
    name: "LG Optimus 2X",
    brand: "LG Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "LG Optimus 2X",
    release_date: null,
  },
  {
    id: 1875,
    name: "LG Optimus Chic",
    brand: "LG Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "LG Optimus Chic",
    release_date: null,
  },
  {
    id: 1874,
    name: "LG Optimus One",
    brand: "LG Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "LG Optimus One",
    release_date: null,
  },
  {
    id: 1873,
    name: "LG Optimus",
    brand: "LG Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "LG Optimus",
    release_date: null,
  },
  {
    id: 1872,
    name: "LG Optimus Q",
    brand: "LG Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "LG Optimus Q",
    release_date: null,
  },
  {
    id: 1871,
    name: "LG GW620",
    brand: "LG Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "LG GW620",
    release_date: null,
  },
  {
    id: 2097,
    name: "Nexus 5X",
    brand: "LG Electronics/Google",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Nexus 5X",
    release_date: null,
  },
  {
    id: 2095,
    name: "Nexus 5",
    brand: "LG Electronics/Google",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Nexus 5",
    release_date: null,
  },
  {
    id: 2094,
    name: "Nexus 4",
    brand: "LG Electronics/Google",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Nexus 4",
    release_date: null,
  },
  {
    id: 1930,
    name: "Marshall London",
    brand: "Marshall Amplification",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Marshall London",
    release_date: null,
  },
  {
    id: 1984,
    name: "Meizu 18X",
    brand: "Meizu",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Meizu 18X",
    release_date: null,
  },
  {
    id: 1983,
    name: "Meizu 18s/Pro",
    brand: "Meizu",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Meizu 18s/Pro",
    release_date: null,
  },
  {
    id: 1982,
    name: "Meizu 18/Pro",
    brand: "Meizu",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Meizu 18/Pro",
    release_date: null,
  },
  {
    id: 1981,
    name: "Meizu 17/Pro",
    brand: "Meizu",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Meizu 17/Pro",
    release_date: null,
  },
  {
    id: 1980,
    name: "Meizu 16T",
    brand: "Meizu",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Meizu 16T",
    release_date: null,
  },
  {
    id: 1979,
    name: "Meizu 16s Pro",
    brand: "Meizu",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Meizu 16s Pro",
    release_date: null,
  },
  {
    id: 1978,
    name: "Meizu 16Xs",
    brand: "Meizu",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Meizu 16Xs",
    release_date: null,
  },
  {
    id: 1977,
    name: "Meizu 16s",
    brand: "Meizu",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Meizu 16s",
    release_date: null,
  },
  {
    id: 1976,
    name: "Meizu M10",
    brand: "Meizu",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Meizu M10",
    release_date: null,
  },
  {
    id: 1975,
    name: "Meizu Note 9",
    brand: "Meizu",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Meizu Note 9",
    release_date: null,
  },
  {
    id: 1974,
    name: "Meizu C9/Pro",
    brand: "Meizu",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Meizu C9/Pro",
    release_date: null,
  },
  {
    id: 1973,
    name: "Meizu Note 8",
    brand: "Meizu",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Meizu Note 8",
    release_date: null,
  },
  {
    id: 1972,
    name: "Meizu X8",
    brand: "Meizu",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Meizu X8",
    release_date: null,
  },
  {
    id: 1971,
    name: "Meizu V8/Pro",
    brand: "Meizu",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Meizu V8/Pro",
    release_date: null,
  },
  {
    id: 1970,
    name: "Meizu 16X",
    brand: "Meizu",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Meizu 16X",
    release_date: null,
  },
  {
    id: 1969,
    name: "Meizu 16th/Plus",
    brand: "Meizu",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Meizu 16th/Plus",
    release_date: null,
  },
  {
    id: 1968,
    name: "Meizu M6T",
    brand: "Meizu",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Meizu M6T",
    release_date: null,
  },
  {
    id: 1967,
    name: "Meizu M8c",
    brand: "Meizu",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Meizu M8c",
    release_date: null,
  },
  {
    id: 1966,
    name: "Meizu 15/Pro/Lite",
    brand: "Meizu",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Meizu 15/Pro/Lite",
    release_date: null,
  },
  {
    id: 1965,
    name: "Meizu E3",
    brand: "Meizu",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Meizu E3",
    release_date: null,
  },
  {
    id: 1964,
    name: "Meizu M6s",
    brand: "Meizu",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Meizu M6s",
    release_date: null,
  },
  {
    id: 1963,
    name: "Meizu M6",
    brand: "Meizu",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Meizu M6",
    release_date: null,
  },
  {
    id: 1962,
    name: "Meizu M6 Note",
    brand: "Meizu",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Meizu M6 Note",
    release_date: null,
  },
  {
    id: 1961,
    name: "Meizu PRO 7/Plus",
    brand: "Meizu",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Meizu PRO 7/Plus",
    release_date: null,
  },
  {
    id: 1960,
    name: "Meizu M5c",
    brand: "Meizu",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Meizu M5c",
    release_date: null,
  },
  {
    id: 1959,
    name: "Meizu E2",
    brand: "Meizu",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Meizu E2",
    release_date: null,
  },
  {
    id: 1958,
    name: "Meizu M5s",
    brand: "Meizu",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Meizu M5s",
    release_date: null,
  },
  {
    id: 1957,
    name: "Meizu M5 Note",
    brand: "Meizu",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Meizu M5 Note",
    release_date: null,
  },
  {
    id: 1956,
    name: "Meizu PRO 6 Plus",
    brand: "Meizu",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Meizu PRO 6 Plus",
    release_date: null,
  },
  {
    id: 1955,
    name: "Meizu M3x",
    brand: "Meizu",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Meizu M3x",
    release_date: null,
  },
  {
    id: 1954,
    name: "Meizu PRO 6s",
    brand: "Meizu",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Meizu PRO 6s",
    release_date: null,
  },
  {
    id: 1953,
    name: "Meizu M5",
    brand: "Meizu",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Meizu M5",
    release_date: null,
  },
  {
    id: 1952,
    name: "Meizu M3 Max",
    brand: "Meizu",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Meizu M3 Max",
    release_date: null,
  },
  {
    id: 1951,
    name: "Meizu U10/20",
    brand: "Meizu",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Meizu U10/20",
    release_date: null,
  },
  {
    id: 1950,
    name: "Meizu M3E",
    brand: "Meizu",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Meizu M3E",
    release_date: null,
  },
  {
    id: 1949,
    name: "Meizu MX6",
    brand: "Meizu",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Meizu MX6",
    release_date: null,
  },
  {
    id: 1948,
    name: "Meizu M3s",
    brand: "Meizu",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Meizu M3s",
    release_date: null,
  },
  {
    id: 1947,
    name: "Meizu M3",
    brand: "Meizu",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Meizu M3",
    release_date: null,
  },
  {
    id: 1946,
    name: "Meizu PRO 6",
    brand: "Meizu",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Meizu PRO 6",
    release_date: null,
  },
  {
    id: 1945,
    name: "Meizu M3 Note",
    brand: "Meizu",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Meizu M3 Note",
    release_date: null,
  },
  {
    id: 1944,
    name: "Meizu MX5e",
    brand: "Meizu",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Meizu MX5e",
    release_date: null,
  },
  {
    id: 1943,
    name: "Meizu M1 Metal",
    brand: "Meizu",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Meizu M1 Metal",
    release_date: null,
  },
  {
    id: 1942,
    name: "Meizu PRO 5",
    brand: "Meizu",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Meizu PRO 5",
    release_date: null,
  },
  {
    id: 1941,
    name: "Meizu M2",
    brand: "Meizu",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Meizu M2",
    release_date: null,
  },
  {
    id: 1940,
    name: "Meizu MX5",
    brand: "Meizu",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Meizu MX5",
    release_date: null,
  },
  {
    id: 1939,
    name: "Meizu M2 Note",
    brand: "Meizu",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Meizu M2 Note",
    release_date: null,
  },
  {
    id: 1938,
    name: "Meizu M1/1 Note",
    brand: "Meizu",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Meizu M1/1 Note",
    release_date: null,
  },
  {
    id: 1937,
    name: "Meizu MX4 Pro",
    brand: "Meizu",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Meizu MX4 Pro",
    release_date: null,
  },
  {
    id: 1936,
    name: "Meizu MX4",
    brand: "Meizu",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Meizu MX4",
    release_date: null,
  },
  {
    id: 1935,
    name: "Meizu MX3",
    brand: "Meizu",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Meizu MX3",
    release_date: null,
  },
  {
    id: 1934,
    name: "Meizu MX2",
    brand: "Meizu",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Meizu MX2",
    release_date: null,
  },
  {
    id: 1933,
    name: "Meizu MX 4-core",
    brand: "Meizu",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Meizu MX 4-core",
    release_date: null,
  },
  {
    id: 1932,
    name: "Meizu MX",
    brand: "Meizu",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Meizu MX",
    release_date: null,
  },
  {
    id: 1931,
    name: "Meizu M9",
    brand: "Meizu",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Meizu M9",
    release_date: null,
  },
  {
    id: 2655,
    name: "Surface Duo",
    brand: "Microsoft",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Surface Duo",
    release_date: null,
  },
  {
    id: 2101,
    name: "Nokia X2",
    brand: "Microsoft Mobile",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Nokia X2",
    release_date: null,
  },
  {
    id: 2100,
    name: "Nokia XL",
    brand: "Microsoft Mobile",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Nokia XL",
    release_date: null,
  },
  {
    id: 1985,
    name: "Mode Phone MP1",
    brand: "Mode",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Mode Phone MP1",
    release_date: null,
  },
  {
    id: 1994,
    name: "Motorola Droid Pro",
    brand: "Motorola",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Motorola Droid Pro",
    release_date: null,
  },
  {
    id: 1993,
    name: "Motorola Defy",
    brand: "Motorola",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Motorola Defy",
    release_date: null,
  },
  {
    id: 1992,
    name: "Motorola Droid 2",
    brand: "Motorola",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Motorola Droid 2",
    release_date: null,
  },
  {
    id: 1991,
    name: "Motorola Charm",
    brand: "Motorola",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Motorola Charm",
    release_date: null,
  },
  {
    id: 1990,
    name: "Motorola Droid X",
    brand: "Motorola",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Motorola Droid X",
    release_date: null,
  },
  {
    id: 1989,
    name: "Motorola Flipout",
    brand: "Motorola",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Motorola Flipout",
    release_date: null,
  },
  {
    id: 1988,
    name: "Motorola Backflip",
    brand: "Motorola",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Motorola Backflip",
    release_date: null,
  },
  {
    id: 1987,
    name: "Motorola Droid",
    brand: "Motorola",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Motorola Droid",
    release_date: null,
  },
  {
    id: 1986,
    name: "Motorola Cliq",
    brand: "Motorola",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Motorola Cliq",
    release_date: null,
  },
  {
    id: 2078,
    name: "Motorola Edge (2021)",
    brand: "Motorola Mobility",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Motorola Edge (2021)",
    release_date: null,
  },
  {
    id: 2077,
    name: "Motorola Edge 20",
    brand: "Motorola Mobility",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Motorola Edge 20",
    release_date: null,
  },
  {
    id: 2076,
    name: "Moto G60S",
    brand: "Motorola Mobility",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Moto G60S",
    release_date: null,
  },
  {
    id: 2075,
    name: "Moto G50 5G",
    brand: "Motorola Mobility",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Moto G50 5G",
    release_date: null,
  },
  {
    id: 2074,
    name: "Motorola Defy (2021)",
    brand: "Motorola Mobility",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Motorola Defy (2021)",
    release_date: null,
  },
  {
    id: 2073,
    name: "Moto G Stylus 5G",
    brand: "Motorola Mobility",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Moto G Stylus 5G",
    release_date: null,
  },
  {
    id: 2072,
    name: "Moto G20",
    brand: "Motorola Mobility",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Moto G20",
    release_date: null,
  },
  {
    id: 2071,
    name: "Moto G40 Fusion",
    brand: "Motorola Mobility",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Moto G40 Fusion",
    release_date: null,
  },
  {
    id: 2070,
    name: "Moto G60",
    brand: "Motorola Mobility",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Moto G60",
    release_date: null,
  },
  {
    id: 2069,
    name: "Moto G100",
    brand: "Motorola Mobility",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Moto G100",
    release_date: null,
  },
  {
    id: 2068,
    name: "Moto G50",
    brand: "Motorola Mobility",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Moto G50",
    release_date: null,
  },
  {
    id: 2067,
    name: "Moto G10 Power",
    brand: "Motorola Mobility",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Moto G10 Power",
    release_date: null,
  },
  {
    id: 2066,
    name: "Moto G30",
    brand: "Motorola Mobility",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Moto G30",
    release_date: null,
  },
  {
    id: 2065,
    name: "Moto G10",
    brand: "Motorola Mobility",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Moto G10",
    release_date: null,
  },
  {
    id: 2064,
    name: "Moto E7 Power",
    brand: "Motorola Mobility",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Moto E7 Power",
    release_date: null,
  },
  {
    id: 2063,
    name: "Moto E6i",
    brand: "Motorola Mobility",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Moto E6i",
    release_date: null,
  },
  {
    id: 2062,
    name: "Moto G Stylus (2021)",
    brand: "Motorola Mobility",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Moto G Stylus (2021)",
    release_date: null,
  },
  {
    id: 2061,
    name: "Moto G Power (2021)",
    brand: "Motorola Mobility",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Moto G Power (2021)",
    release_date: null,
  },
  {
    id: 2060,
    name: "Moto G Play (2021)",
    brand: "Motorola Mobility",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Moto G Play (2021)",
    release_date: null,
  },
  {
    id: 2059,
    name: "Moto E7",
    brand: "Motorola Mobility",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Moto E7",
    release_date: null,
  },
  {
    id: 2058,
    name: "Moto G 5G",
    brand: "Motorola Mobility",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Moto G 5G",
    release_date: null,
  },
  {
    id: 2057,
    name: "Moto G9 Power",
    brand: "Motorola Mobility",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Moto G9 Power",
    release_date: null,
  },
  {
    id: 2056,
    name: "Motorola Razr (5G)",
    brand: "Motorola Mobility",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Motorola Razr (5G)",
    release_date: null,
  },
  {
    id: 2055,
    name: "Moto E7 Plus",
    brand: "Motorola Mobility",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Moto E7 Plus",
    release_date: null,
  },
  {
    id: 2054,
    name: "Moto G9 Plus",
    brand: "Motorola Mobility",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Moto G9 Plus",
    release_date: null,
  },
  {
    id: 2053,
    name: "Moto G9 Play",
    brand: "Motorola Mobility",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Moto G9 Play",
    release_date: null,
  },
  {
    id: 2052,
    name: "Moto G 5G Plus",
    brand: "Motorola Mobility",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Moto G 5G Plus",
    release_date: null,
  },
  {
    id: 2051,
    name: "Motorola One Fusion",
    brand: "Motorola Mobility",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Motorola One Fusion",
    release_date: null,
  },
  {
    id: 2050,
    name: "Motorola One Fusion+",
    brand: "Motorola Mobility",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Motorola One Fusion+",
    release_date: null,
  },
  {
    id: 2049,
    name: "Moto G Fast",
    brand: "Motorola Mobility",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Moto G Fast",
    release_date: null,
  },
  {
    id: 2048,
    name: "Moto E (2020)",
    brand: "Motorola Mobility",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Moto E (2020)",
    release_date: null,
  },
  {
    id: 2047,
    name: "Moto G Pro",
    brand: "Motorola Mobility",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Moto G Pro",
    release_date: null,
  },
  {
    id: 2046,
    name: "Motorola Edge/Edge+",
    brand: "Motorola Mobility",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Motorola Edge/Edge+",
    release_date: null,
  },
  {
    id: 2045,
    name: "Moto G8 Power Lite",
    brand: "Motorola Mobility",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Moto G8 Power Lite",
    release_date: null,
  },
  {
    id: 2044,
    name: "Moto E6s (2020)",
    brand: "Motorola Mobility",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Moto E6s (2020)",
    release_date: null,
  },
  {
    id: 2043,
    name: "Moto G8",
    brand: "Motorola Mobility",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Moto G8",
    release_date: null,
  },
  {
    id: 2042,
    name: "Moto G Power/Stylus",
    brand: "Motorola Mobility",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Moto G Power/Stylus",
    release_date: null,
  },
  {
    id: 2041,
    name: "Moto G8 Power",
    brand: "Motorola Mobility",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Moto G8 Power",
    release_date: null,
  },
  {
    id: 2040,
    name: "Motorola One Hyper",
    brand: "Motorola Mobility",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Motorola One Hyper",
    release_date: null,
  },
  {
    id: 2039,
    name: "Motorola Razr (4G)",
    brand: "Motorola Mobility",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Motorola Razr (4G)",
    release_date: null,
  },
  {
    id: 2038,
    name: "Motorola One Action",
    brand: "Motorola Mobility",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Motorola One Action",
    release_date: null,
  },
  {
    id: 2037,
    name: "Motorola One Macro",
    brand: "Motorola Mobility",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Motorola One Macro",
    release_date: null,
  },
  {
    id: 2036,
    name: "Moto G8 Plus",
    brand: "Motorola Mobility",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Moto G8 Plus",
    release_date: null,
  },
  {
    id: 2035,
    name: "Motorola One Zoom",
    brand: "Motorola Mobility",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Motorola One Zoom",
    release_date: null,
  },
  {
    id: 2034,
    name: "Moto Z4",
    brand: "Motorola Mobility",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Moto Z4",
    release_date: null,
  },
  {
    id: 2033,
    name: "Motorola One Vision",
    brand: "Motorola Mobility",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Motorola One Vision",
    release_date: null,
  },
  {
    id: 2032,
    name: "Moto G7",
    brand: "Motorola Mobility",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Moto G7",
    release_date: null,
  },
  {
    id: 2031,
    name: "Motorola One/One Power",
    brand: "Motorola Mobility",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Motorola One/One Power",
    release_date: null,
  },
  {
    id: 2030,
    name: "Moto Z3",
    brand: "Motorola Mobility",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Moto Z3",
    release_date: null,
  },
  {
    id: 2029,
    name: "Moto Z3 Play",
    brand: "Motorola Mobility",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Moto Z3 Play",
    release_date: null,
  },
  {
    id: 2028,
    name: "Moto G6",
    brand: "Motorola Mobility",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Moto G6",
    release_date: null,
  },
  {
    id: 2027,
    name: "Moto E5",
    brand: "Motorola Mobility",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Moto E5",
    release_date: null,
  },
  {
    id: 2026,
    name: "Moto X4",
    brand: "Motorola Mobility",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Moto X4",
    release_date: null,
  },
  {
    id: 2025,
    name: "Moto Z2 Force",
    brand: "Motorola Mobility",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Moto Z2 Force",
    release_date: null,
  },
  {
    id: 2024,
    name: "Moto Z2 Play",
    brand: "Motorola Mobility",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Moto Z2 Play",
    release_date: null,
  },
  {
    id: 2023,
    name: "Moto E4",
    brand: "Motorola Mobility",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Moto E4",
    release_date: null,
  },
  {
    id: 2022,
    name: "Moto C",
    brand: "Motorola Mobility",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Moto C",
    release_date: null,
  },
  {
    id: 2021,
    name: "Moto G5",
    brand: "Motorola Mobility",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Moto G5",
    release_date: null,
  },
  {
    id: 2020,
    name: "Moto Z Play",
    brand: "Motorola Mobility",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Moto Z Play",
    release_date: null,
  },
  {
    id: 2019,
    name: "Moto Z",
    brand: "Motorola Mobility",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Moto Z",
    release_date: null,
  },
  {
    id: 2018,
    name: "Moto E3",
    brand: "Motorola Mobility",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Moto E3",
    release_date: null,
  },
  {
    id: 2017,
    name: "Moto G4",
    brand: "Motorola Mobility",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Moto G4",
    release_date: null,
  },
  {
    id: 2016,
    name: "Moto X Style",
    brand: "Motorola Mobility",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Moto X Style",
    release_date: null,
  },
  {
    id: 2015,
    name: "Moto X Play",
    brand: "Motorola Mobility",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Moto X Play",
    release_date: null,
  },
  {
    id: 2014,
    name: "Moto G (3rd generation)",
    brand: "Motorola Mobility",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Moto G (3rd generation)",
    release_date: null,
  },
  {
    id: 2013,
    name: "Moto E (2nd generation)",
    brand: "Motorola Mobility",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Moto E (2nd generation)",
    release_date: null,
  },
  {
    id: 2012,
    name: "Motorola Droid Turbo",
    brand: "Motorola Mobility",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Motorola Droid Turbo",
    release_date: null,
  },
  {
    id: 2011,
    name: "Moto X (2nd generation)",
    brand: "Motorola Mobility",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Moto X (2nd generation)",
    release_date: null,
  },
  {
    id: 2010,
    name: "Moto G (2nd generation)",
    brand: "Motorola Mobility",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Moto G (2nd generation)",
    release_date: null,
  },
  {
    id: 2009,
    name: "Moto E (1st generation)",
    brand: "Motorola Mobility",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Moto E (1st generation)",
    release_date: null,
  },
  {
    id: 2008,
    name: "Moto G (1st generation)",
    brand: "Motorola Mobility",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Moto G (1st generation)",
    release_date: null,
  },
  {
    id: 2007,
    name: "Moto X (1st generation)",
    brand: "Motorola Mobility",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Moto X (1st generation)",
    release_date: null,
  },
  {
    id: 2006,
    name: "Motorola Droid Mini",
    brand: "Motorola Mobility",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Motorola Droid Mini",
    release_date: null,
  },
  {
    id: 2005,
    name: "Motorola Droid Maxx",
    brand: "Motorola Mobility",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Motorola Droid Maxx",
    release_date: null,
  },
  {
    id: 2004,
    name: "Motorola Droid Razr HD",
    brand: "Motorola Mobility",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Motorola Droid Razr HD",
    release_date: null,
  },
  {
    id: 2003,
    name: "Motorola Droid Razr M",
    brand: "Motorola Mobility",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Motorola Droid Razr M",
    release_date: null,
  },
  {
    id: 2002,
    name: "Motorola Photon Q",
    brand: "Motorola Mobility",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Motorola Photon Q",
    release_date: null,
  },
  {
    id: 2001,
    name: "Motorola Atrix HD",
    brand: "Motorola Mobility",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Motorola Atrix HD",
    release_date: null,
  },
  {
    id: 2000,
    name: "Motorola Droid 4",
    brand: "Motorola Mobility",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Motorola Droid 4",
    release_date: null,
  },
  {
    id: 1999,
    name: "Motorola Droid Razr",
    brand: "Motorola Mobility",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Motorola Droid Razr",
    release_date: null,
  },
  {
    id: 1998,
    name: "Motorola Atrix 2",
    brand: "Motorola Mobility",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Motorola Atrix 2",
    release_date: null,
  },
  {
    id: 1997,
    name: "Motorola Droid Bionic",
    brand: "Motorola Mobility",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Motorola Droid Bionic",
    release_date: null,
  },
  {
    id: 1996,
    name: "Motorola Droid 3",
    brand: "Motorola Mobility",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Motorola Droid 3",
    release_date: null,
  },
  {
    id: 1995,
    name: "Motorola Atrix 4G",
    brand: "Motorola Mobility",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Motorola Atrix 4G",
    release_date: null,
  },
  {
    id: 2096,
    name: "Nexus 6",
    brand: "Motorola Mobility/Google",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Nexus 6",
    release_date: null,
  },
  {
    id: 2088,
    name: "MyPhone myXl3",
    brand: "MyPhone",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "MyPhone myXl3",
    release_date: null,
  },
  {
    id: 2087,
    name: "MyPhone myX12",
    brand: "MyPhone",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "MyPhone myX12",
    release_date: null,
  },
  {
    id: 2086,
    name: "MyPhone myXl1 Plus",
    brand: "MyPhone",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "MyPhone myXl1 Plus",
    release_date: null,
  },
  {
    id: 2085,
    name: "MyPhone myXl1 Pro",
    brand: "MyPhone",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "MyPhone myXl1 Pro",
    release_date: null,
  },
  {
    id: 2084,
    name: "MyPhone myXl1",
    brand: "MyPhone",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "MyPhone myXl1",
    release_date: null,
  },
  {
    id: 2083,
    name: "MyPhone myG1",
    brand: "MyPhone",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "MyPhone myG1",
    release_date: null,
  },
  {
    id: 2082,
    name: "MyPhone myP1",
    brand: "MyPhone",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "MyPhone myP1",
    release_date: null,
  },
  {
    id: 2081,
    name: "MyPhone myA18",
    brand: "MyPhone",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "MyPhone myA18",
    release_date: null,
  },
  {
    id: 2080,
    name: "MyPhone myA17",
    brand: "MyPhone",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "MyPhone myA17",
    release_date: null,
  },
  {
    id: 2079,
    name: "MyPhone myA1 Plus",
    brand: "MyPhone",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "MyPhone myA1 Plus",
    release_date: null,
  },
  {
    id: 1515,
    name: "Casio G'zOne Commando",
    brand: "NEC, Casio and Hitachi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Casio G'zOne Commando",
    release_date: null,
  },
  {
    id: 2090,
    name: "Nextbit Robin",
    brand: "Nextbit",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Nextbit Robin",
    release_date: null,
  },
  {
    id: 2099,
    name: "Nokia X",
    brand: "Nokia",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Nokia X",
    release_date: null,
  },
  {
    id: 2151,
    name: "Nothing Phone 1",
    brand: "Nothing",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Nothing Phone 1",
    release_date: null,
  },
  {
    id: 2212,
    name: "OnePlus 10 Pro",
    brand: "OnePlus",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "OnePlus 10 Pro",
    release_date: null,
  },
  {
    id: 2211,
    name: "OnePlus Nord 2 5G",
    brand: "OnePlus",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "OnePlus Nord 2 5G",
    release_date: null,
  },
  {
    id: 2210,
    name: "OnePlus Nord N200 5G",
    brand: "OnePlus",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "OnePlus Nord N200 5G",
    release_date: null,
  },
  {
    id: 2209,
    name: "OnePlus Nord CE 5G",
    brand: "OnePlus",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "OnePlus Nord CE 5G",
    release_date: null,
  },
  {
    id: 2208,
    name: "OnePlus 9/Pro",
    brand: "OnePlus",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "OnePlus 9/Pro",
    release_date: null,
  },
  {
    id: 2207,
    name: "OnePlus Nord N100",
    brand: "OnePlus",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "OnePlus Nord N100",
    release_date: null,
  },
  {
    id: 2206,
    name: "OnePlus Nord N10 5G",
    brand: "OnePlus",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "OnePlus Nord N10 5G",
    release_date: null,
  },
  {
    id: 2205,
    name: "OnePlus 8T",
    brand: "OnePlus",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "OnePlus 8T",
    release_date: null,
  },
  {
    id: 2204,
    name: "OnePlus Nord",
    brand: "OnePlus",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "OnePlus Nord",
    release_date: null,
  },
  {
    id: 2203,
    name: "OnePlus 8/Pro",
    brand: "OnePlus",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "OnePlus 8/Pro",
    release_date: null,
  },
  {
    id: 2202,
    name: "OnePlus 7T/Pro",
    brand: "OnePlus",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "OnePlus 7T/Pro",
    release_date: null,
  },
  {
    id: 2201,
    name: "OnePlus 7/Pro",
    brand: "OnePlus",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "OnePlus 7/Pro",
    release_date: null,
  },
  {
    id: 2200,
    name: "OnePlus 6T",
    brand: "OnePlus",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "OnePlus 6T",
    release_date: null,
  },
  {
    id: 2199,
    name: "OnePlus 6",
    brand: "OnePlus",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "OnePlus 6",
    release_date: null,
  },
  {
    id: 2198,
    name: "OnePlus 5T",
    brand: "OnePlus",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "OnePlus 5T",
    release_date: null,
  },
  {
    id: 2197,
    name: "OnePlus 5",
    brand: "OnePlus",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "OnePlus 5",
    release_date: null,
  },
  {
    id: 2196,
    name: "OnePlus 3T",
    brand: "OnePlus",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "OnePlus 3T",
    release_date: null,
  },
  {
    id: 2195,
    name: "OnePlus 3",
    brand: "OnePlus",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "OnePlus 3",
    release_date: null,
  },
  {
    id: 2194,
    name: "OnePlus X",
    brand: "OnePlus",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "OnePlus X",
    release_date: null,
  },
  {
    id: 2193,
    name: "OnePlus 2",
    brand: "OnePlus",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "OnePlus 2",
    release_date: null,
  },
  {
    id: 2192,
    name: "OnePlus One",
    brand: "OnePlus",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "OnePlus One",
    release_date: null,
  },
  {
    id: 2931,
    name: "Oppo Reno6",
    brand: "Oppo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Oppo Reno6",
    release_date: null,
  },
  {
    id: 2304,
    name: "Oppo K10",
    brand: "Oppo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Oppo K10",
    release_date: null,
  },
  {
    id: 2303,
    name: "Oppo A96",
    brand: "Oppo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Oppo A96",
    release_date: null,
  },
  {
    id: 2302,
    name: "Oppo A16e",
    brand: "Oppo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Oppo A16e",
    release_date: null,
  },
  {
    id: 2301,
    name: "Oppo Reno7 Z",
    brand: "Oppo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Oppo Reno7 Z",
    release_date: null,
  },
  {
    id: 2300,
    name: "Oppo Find X5 Lite",
    brand: "Oppo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Oppo Find X5 Lite",
    release_date: null,
  },
  {
    id: 2299,
    name: "Oppo Find X5/Pro",
    brand: "Oppo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Oppo Find X5/Pro",
    release_date: null,
  },
  {
    id: 2298,
    name: "Oppo A76",
    brand: "Oppo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Oppo A76",
    release_date: null,
  },
  {
    id: 2297,
    name: "Oppo Reno7",
    brand: "Oppo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Oppo Reno7",
    release_date: null,
  },
  {
    id: 2296,
    name: "Oppo Reno6 Lite",
    brand: "Oppo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Oppo Reno6 Lite",
    release_date: null,
  },
  {
    id: 2295,
    name: "Oppo A96 5G",
    brand: "Oppo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Oppo A96 5G",
    release_date: null,
  },
  {
    id: 2294,
    name: "Oppo A36",
    brand: "Oppo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Oppo A36",
    release_date: null,
  },
  {
    id: 2293,
    name: "Oppo A11s",
    brand: "Oppo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Oppo A11s",
    release_date: null,
  },
  {
    id: 2292,
    name: "Oppo K9x",
    brand: "Oppo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Oppo K9x",
    release_date: null,
  },
  {
    id: 2291,
    name: "Oppo Frind N",
    brand: "Oppo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Oppo Frind N",
    release_date: null,
  },
  {
    id: 2290,
    name: "Oppo Reno7 (China)/Pro/SE",
    brand: "Oppo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Oppo Reno7 (China)/Pro/SE",
    release_date: null,
  },
  {
    id: 2289,
    name: "Oppo A95",
    brand: "Oppo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Oppo A95",
    release_date: null,
  },
  {
    id: 2288,
    name: "Oppo A16k",
    brand: "Oppo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Oppo A16k",
    release_date: null,
  },
  {
    id: 2287,
    name: "Oppo A54s",
    brand: "Oppo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Oppo A54s",
    release_date: null,
  },
  {
    id: 2286,
    name: "Oppo A56 5G",
    brand: "Oppo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Oppo A56 5G",
    release_date: null,
  },
  {
    id: 2285,
    name: "Oppo K9s",
    brand: "Oppo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Oppo K9s",
    release_date: null,
  },
  {
    id: 2284,
    name: "Oppo A55",
    brand: "Oppo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Oppo A55",
    release_date: null,
  },
  {
    id: 2283,
    name: "Oppo F19s",
    brand: "Oppo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Oppo F19s",
    release_date: null,
  },
  {
    id: 2282,
    name: "Oppo K9 Pro",
    brand: "Oppo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Oppo K9 Pro",
    release_date: null,
  },
  {
    id: 2281,
    name: "Oppo Reno6 Pro (Snapdragon)",
    brand: "Oppo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Oppo Reno6 Pro (Snapdragon)",
    release_date: null,
  },
  {
    id: 2280,
    name: "Oppo Reno6 Z",
    brand: "Oppo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Oppo Reno6 Z",
    release_date: null,
  },
  {
    id: 2279,
    name: "Oppo A16s",
    brand: "Oppo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Oppo A16s",
    release_date: null,
  },
  {
    id: 2278,
    name: "Oppo Reno6",
    brand: "Oppo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Oppo Reno6",
    release_date: null,
  },
  {
    id: 2277,
    name: "Oppo A16",
    brand: "Oppo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Oppo A16",
    release_date: null,
  },
  {
    id: 2276,
    name: "Oppo Reno6 5G/Pro/Pro+",
    brand: "Oppo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Oppo Reno6 5G/Pro/Pro+",
    release_date: null,
  },
  {
    id: 2275,
    name: "Oppo K9",
    brand: "Oppo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Oppo K9",
    release_date: null,
  },
  {
    id: 2274,
    name: "Oppo A53s 5G",
    brand: "Oppo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Oppo A53s 5G",
    release_date: null,
  },
  {
    id: 2273,
    name: "Oppo A74",
    brand: "Oppo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Oppo A74",
    release_date: null,
  },
  {
    id: 2272,
    name: "Oppo A54 5G/A74 5G",
    brand: "Oppo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Oppo A54 5G/A74 5G",
    release_date: null,
  },
  {
    id: 2271,
    name: "Oppo A95 5G",
    brand: "Oppo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Oppo A95 5G",
    release_date: null,
  },
  {
    id: 2270,
    name: "Oppo A94 5G",
    brand: "Oppo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Oppo A94 5G",
    release_date: null,
  },
  {
    id: 2269,
    name: "Oppo A35",
    brand: "Oppo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Oppo A35",
    release_date: null,
  },
  {
    id: 2268,
    name: "Oppo F19",
    brand: "Oppo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Oppo F19",
    release_date: null,
  },
  {
    id: 2267,
    name: "Oppo Reno5 Z",
    brand: "Oppo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Oppo Reno5 Z",
    release_date: null,
  },
  {
    id: 2266,
    name: "Oppo Reno5 Lite/F",
    brand: "Oppo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Oppo Reno5 Lite/F",
    release_date: null,
  },
  {
    id: 2265,
    name: "Oppo F19 Pro/Pro+",
    brand: "Oppo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Oppo F19 Pro/Pro+",
    release_date: null,
  },
  {
    id: 2264,
    name: "Oppo A94",
    brand: "Oppo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Oppo A94",
    release_date: null,
  },
  {
    id: 2263,
    name: "Oppo Find X3 Neo/Lite",
    brand: "Oppo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Oppo Find X3 Neo/Lite",
    release_date: null,
  },
  {
    id: 2262,
    name: "Oppo Find X3/Pro",
    brand: "Oppo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Oppo Find X3/Pro",
    release_date: null,
  },
  {
    id: 2261,
    name: "Oppo Reno5",
    brand: "Oppo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Oppo Reno5",
    release_date: null,
  },
  {
    id: 2260,
    name: "Oppo Reno5 5G/Pro 5G/Pro+ 5G",
    brand: "Oppo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Oppo Reno5 5G/Pro 5G/Pro+ 5G",
    release_date: null,
  },
  {
    id: 2259,
    name: "Oppo A73 5G",
    brand: "Oppo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Oppo A73 5G",
    release_date: null,
  },
  {
    id: 2258,
    name: "Oppo A73",
    brand: "Oppo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Oppo A73",
    release_date: null,
  },
  {
    id: 2257,
    name: "Oppo A93/Reno4 F",
    brand: "Oppo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Oppo A93/Reno4 F",
    release_date: null,
  },
  {
    id: 2256,
    name: "Oppo Reno4 Z 5G",
    brand: "Oppo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Oppo Reno4 Z 5G",
    release_date: null,
  },
  {
    id: 2255,
    name: "Oppo Reno4 Lite",
    brand: "Oppo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Oppo Reno4 Lite",
    release_date: null,
  },
  {
    id: 2254,
    name: "Oppo Reno4 SE",
    brand: "Oppo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Oppo Reno4 SE",
    release_date: null,
  },
  {
    id: 2253,
    name: "Oppo F17/Pro",
    brand: "Oppo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Oppo F17/Pro",
    release_date: null,
  },
  {
    id: 2252,
    name: "Oppo Reno4/Pro",
    brand: "Oppo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Oppo Reno4/Pro",
    release_date: null,
  },
  {
    id: 2251,
    name: "Oppo A72 5G",
    brand: "Oppo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Oppo A72 5G",
    release_date: null,
  },
  {
    id: 2250,
    name: "Oppo A12s",
    brand: "Oppo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Oppo A12s",
    release_date: null,
  },
  {
    id: 2249,
    name: "Oppo Reno4 5G/Pro 5G",
    brand: "Oppo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Oppo Reno4 5G/Pro 5G",
    release_date: null,
  },
  {
    id: 2248,
    name: "Oppo A92",
    brand: "Oppo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Oppo A92",
    release_date: null,
  },
  {
    id: 2247,
    name: "Oppo Find X2 Lite/Neo",
    brand: "Oppo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Oppo Find X2 Lite/Neo",
    release_date: null,
  },
  {
    id: 2246,
    name: "Oppo A72/A92s",
    brand: "Oppo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Oppo A72/A92s",
    release_date: null,
  },
  {
    id: 2245,
    name: "Oppo A52",
    brand: "Oppo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Oppo A52",
    release_date: null,
  },
  {
    id: 2244,
    name: "Oppo A12",
    brand: "Oppo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Oppo A12",
    release_date: null,
  },
  {
    id: 2243,
    name: "Oppo Ace2",
    brand: "Oppo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Oppo Ace2",
    release_date: null,
  },
  {
    id: 2242,
    name: "Oppo Reno3",
    brand: "Oppo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Oppo Reno3",
    release_date: null,
  },
  {
    id: 2241,
    name: "Oppo Find X2/Pro",
    brand: "Oppo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Oppo Find X2/Pro",
    release_date: null,
  },
  {
    id: 2240,
    name: "Oppo Reno3 Pro",
    brand: "Oppo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Oppo Reno3 Pro",
    release_date: null,
  },
  {
    id: 2239,
    name: "Oppo Reno3 5G/Pro 5G",
    brand: "Oppo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Oppo Reno3 5G/Pro 5G",
    release_date: null,
  },
  {
    id: 2238,
    name: "Oppo A9 (2020)",
    brand: "Oppo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Oppo A9 (2020)",
    release_date: null,
  },
  {
    id: 2237,
    name: "Oppo Reno2",
    brand: "Oppo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Oppo Reno2",
    release_date: null,
  },
  {
    id: 2236,
    name: "Oppo Reno",
    brand: "Oppo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Oppo Reno",
    release_date: null,
  },
  {
    id: 2235,
    name: "Oppo R17",
    brand: "Oppo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Oppo R17",
    release_date: null,
  },
  {
    id: 2234,
    name: "Oppo F9",
    brand: "Oppo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Oppo F9",
    release_date: null,
  },
  {
    id: 2233,
    name: "Oppo Find X",
    brand: "Oppo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Oppo Find X",
    release_date: null,
  },
  {
    id: 2232,
    name: "Oppo R15 Pro",
    brand: "Oppo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Oppo R15 Pro",
    release_date: null,
  },
  {
    id: 2231,
    name: "Oppo F7",
    brand: "Oppo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Oppo F7",
    release_date: null,
  },
  {
    id: 2230,
    name: "Oppo R11",
    brand: "Oppo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Oppo R11",
    release_date: null,
  },
  {
    id: 2229,
    name: "Oppo F1",
    brand: "Oppo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Oppo F1",
    release_date: null,
  },
  {
    id: 2228,
    name: "Oppo Neo 7",
    brand: "Oppo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Oppo Neo 7",
    release_date: null,
  },
  {
    id: 2227,
    name: "Oppo Joy 3",
    brand: "Oppo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Oppo Joy 3",
    release_date: null,
  },
  {
    id: 2226,
    name: "Oppo Neo 5 (2015)",
    brand: "Oppo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Oppo Neo 5 (2015)",
    release_date: null,
  },
  {
    id: 2225,
    name: "Oppo R7",
    brand: "Oppo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Oppo R7",
    release_date: null,
  },
  {
    id: 2224,
    name: "Oppo Neo 5s",
    brand: "Oppo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Oppo Neo 5s",
    release_date: null,
  },
  {
    id: 2223,
    name: "Oppo Joy Plus",
    brand: "Oppo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Oppo Joy Plus",
    release_date: null,
  },
  {
    id: 2222,
    name: "Oppo N3",
    brand: "Oppo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Oppo N3",
    release_date: null,
  },
  {
    id: 2221,
    name: "Oppo Neo 3/5",
    brand: "Oppo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Oppo Neo 3/5",
    release_date: null,
  },
  {
    id: 2220,
    name: "Oppo N1 mini",
    brand: "Oppo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Oppo N1 mini",
    release_date: null,
  },
  {
    id: 2219,
    name: "Oppo Joy",
    brand: "Oppo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Oppo Joy",
    release_date: null,
  },
  {
    id: 2218,
    name: "Oppo Find 7/7a",
    brand: "Oppo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Oppo Find 7/7a",
    release_date: null,
  },
  {
    id: 2217,
    name: "Oppo Neo",
    brand: "Oppo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Oppo Neo",
    release_date: null,
  },
  {
    id: 2216,
    name: "Oppo Find 5 Mini",
    brand: "Oppo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Oppo Find 5 Mini",
    release_date: null,
  },
  {
    id: 2215,
    name: "Oppo N1",
    brand: "Oppo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Oppo N1",
    release_date: null,
  },
  {
    id: 2214,
    name: "Oppo Find 5",
    brand: "Oppo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Oppo Find 5",
    release_date: null,
  },
  {
    id: 2213,
    name: "Oppo Finder",
    brand: "Oppo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Oppo Finder",
    release_date: null,
  },
  {
    id: 2305,
    name: "OSOM OV1",
    brand: "OSOM",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "OSOM OV1",
    release_date: null,
  },
  {
    id: 2307,
    name: "Palm",
    brand: "Palm, Inc.",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Palm",
    release_date: null,
  },
  {
    id: 2308,
    name: "Panasonic P100",
    brand: "Panasonic",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Panasonic P100",
    release_date: null,
  },
  {
    id: 2309,
    name: "Pantech Pocket",
    brand: "Pantech or AT&T",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Pantech Pocket",
    release_date: null,
  },
  {
    id: 2310,
    name: "Pepsi P1/P1S",
    brand: "Pepsi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Pepsi P1/P1S",
    release_date: null,
  },
  {
    id: 2323,
    name: "Q Phone",
    brand: "Qoobex",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Q Phone",
    release_date: null,
  },
  {
    id: 2326,
    name: "Razer Phone 2",
    brand: "Razer Inc.",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Razer Phone 2",
    release_date: null,
  },
  {
    id: 2325,
    name: "Razer Phone",
    brand: "Razer Inc.",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Razer Phone",
    release_date: null,
  },
  {
    id: 2406,
    name: "Realme V25",
    brand: "Realme",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Realme V25",
    release_date: null,
  },
  {
    id: 2405,
    name: "Realme narzo 50",
    brand: "Realme",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Realme narzo 50",
    release_date: null,
  },
  {
    id: 2404,
    name: "Realme 9 Pro/Pro+",
    brand: "Realme",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Realme 9 Pro/Pro+",
    release_date: null,
  },
  {
    id: 2403,
    name: "Realme C35",
    brand: "Realme",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Realme C35",
    release_date: null,
  },
  {
    id: 2402,
    name: "Realme 9i",
    brand: "Realme",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Realme 9i",
    release_date: null,
  },
  {
    id: 2401,
    name: "Realme GT2/Pro",
    brand: "Realme",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Realme GT2/Pro",
    release_date: null,
  },
  {
    id: 2400,
    name: "Realme Q3t",
    brand: "Realme",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Realme Q3t",
    release_date: null,
  },
  {
    id: 2399,
    name: "Realme GT Neo2T",
    brand: "Realme",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Realme GT Neo2T",
    release_date: null,
  },
  {
    id: 2398,
    name: "Realme Q3s",
    brand: "Realme",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Realme Q3s",
    release_date: null,
  },
  {
    id: 2397,
    name: "Realme narzo 50A/50i",
    brand: "Realme",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Realme narzo 50A/50i",
    release_date: null,
  },
  {
    id: 2396,
    name: "Realme V11s",
    brand: "Realme",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Realme V11s",
    release_date: null,
  },
  {
    id: 2395,
    name: "Realme GT Neo2",
    brand: "Realme",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Realme GT Neo2",
    release_date: null,
  },
  {
    id: 2394,
    name: "Realme C25Y",
    brand: "Realme",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Realme C25Y",
    release_date: null,
  },
  {
    id: 2393,
    name: "Realme 8i/8s 5G",
    brand: "Realme",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Realme 8i/8s 5G",
    release_date: null,
  },
  {
    id: 2392,
    name: "Realme GT Explorer Master Edition",
    brand: "Realme",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Realme GT Explorer Master Edition",
    release_date: null,
  },
  {
    id: 2391,
    name: "Realme GT Master Edition",
    brand: "Realme",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Realme GT Master Edition",
    release_date: null,
  },
  {
    id: 2390,
    name: "Realme C21Y",
    brand: "Realme",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Realme C21Y",
    release_date: null,
  },
  {
    id: 2389,
    name: "Realme C11 2021",
    brand: "Realme",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Realme C11 2021",
    release_date: null,
  },
  {
    id: 2388,
    name: "Realme C25s",
    brand: "Realme",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Realme C25s",
    release_date: null,
  },
  {
    id: 2387,
    name: "Realme X7 Max",
    brand: "Realme",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Realme X7 Max",
    release_date: null,
  },
  {
    id: 2386,
    name: "Realme narzo 30 5G",
    brand: "Realme",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Realme narzo 30 5G",
    release_date: null,
  },
  {
    id: 2385,
    name: "Realme Q3 Pro Carnival",
    brand: "Realme",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Realme Q3 Pro Carnival",
    release_date: null,
  },
  {
    id: 2384,
    name: "Realme GT Neo Flash",
    brand: "Realme",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Realme GT Neo Flash",
    release_date: null,
  },
  {
    id: 2383,
    name: "Realme narzo 30",
    brand: "Realme",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Realme narzo 30",
    release_date: null,
  },
  {
    id: 2382,
    name: "Realme C20A",
    brand: "Realme",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Realme C20A",
    release_date: null,
  },
  {
    id: 2381,
    name: "Realme Q3/Pro/3i",
    brand: "Realme",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Realme Q3/Pro/3i",
    release_date: null,
  },
  {
    id: 2380,
    name: "Realme 8 5G",
    brand: "Realme",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Realme 8 5G",
    release_date: null,
  },
  {
    id: 2379,
    name: "Realme X7 Pro Ultra",
    brand: "Realme",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Realme X7 Pro Ultra",
    release_date: null,
  },
  {
    id: 2378,
    name: "Realme GT Neo",
    brand: "Realme",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Realme GT Neo",
    release_date: null,
  },
  {
    id: 2377,
    name: "Realme V13",
    brand: "Realme",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Realme V13",
    release_date: null,
  },
  {
    id: 2376,
    name: "Realme 8/Pro",
    brand: "Realme",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Realme 8/Pro",
    release_date: null,
  },
  {
    id: 2375,
    name: "Realme C25",
    brand: "Realme",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Realme C25",
    release_date: null,
  },
  {
    id: 2374,
    name: "Realme GT",
    brand: "Realme",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Realme GT",
    release_date: null,
  },
  {
    id: 2373,
    name: "Realme C21",
    brand: "Realme",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Realme C21",
    release_date: null,
  },
  {
    id: 2372,
    name: "Realme Narzo 30 Pro/30A",
    brand: "Realme",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Realme Narzo 30 Pro/30A",
    release_date: null,
  },
  {
    id: 2371,
    name: "Realme V11",
    brand: "Realme",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Realme V11",
    release_date: null,
  },
  {
    id: 2370,
    name: "Realme X7 (India)",
    brand: "Realme",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Realme X7 (India)",
    release_date: null,
  },
  {
    id: 2369,
    name: "Realme C20",
    brand: "Realme",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Realme C20",
    release_date: null,
  },
  {
    id: 2368,
    name: "Realme V15",
    brand: "Realme",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Realme V15",
    release_date: null,
  },
  {
    id: 2367,
    name: "Realme 7i (Global)",
    brand: "Realme",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Realme 7i (Global)",
    release_date: null,
  },
  {
    id: 2366,
    name: "Realme 7 5G",
    brand: "Realme",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Realme 7 5G",
    release_date: null,
  },
  {
    id: 2365,
    name: "Realme Q2/Pro/2i",
    brand: "Realme",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Realme Q2/Pro/2i",
    release_date: null,
  },
  {
    id: 2364,
    name: "Realme 7 (Global)",
    brand: "Realme",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Realme 7 (Global)",
    release_date: null,
  },
  {
    id: 2363,
    name: "Realme Narzo 20/Pro/20A",
    brand: "Realme",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Realme Narzo 20/Pro/20A",
    release_date: null,
  },
  {
    id: 2362,
    name: "Realme C17",
    brand: "Realme",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Realme C17",
    release_date: null,
  },
  {
    id: 2361,
    name: "Realme 7i",
    brand: "Realme",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Realme 7i",
    release_date: null,
  },
  {
    id: 2360,
    name: "Realme 7 (Asia)/Pro",
    brand: "Realme",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Realme 7 (Asia)/Pro",
    release_date: null,
  },
  {
    id: 2359,
    name: "Realme X7/Pro",
    brand: "Realme",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Realme X7/Pro",
    release_date: null,
  },
  {
    id: 2358,
    name: "Realme V3",
    brand: "Realme",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Realme V3",
    release_date: null,
  },
  {
    id: 2357,
    name: "Realme C12",
    brand: "Realme",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Realme C12",
    release_date: null,
  },
  {
    id: 2356,
    name: "Realme V5",
    brand: "Realme",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Realme V5",
    release_date: null,
  },
  {
    id: 2355,
    name: "Realme C15",
    brand: "Realme",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Realme C15",
    release_date: null,
  },
  {
    id: 2354,
    name: "Realme C11",
    brand: "Realme",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Realme C11",
    release_date: null,
  },
  {
    id: 2353,
    name: "Realme X3",
    brand: "Realme",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Realme X3",
    release_date: null,
  },
  {
    id: 2352,
    name: "Realme C3i",
    brand: "Realme",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Realme C3i",
    release_date: null,
  },
  {
    id: 2351,
    name: "Realme Narzo",
    brand: "Realme",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Realme Narzo",
    release_date: null,
  },
  {
    id: 2350,
    name: "Realme X3 SuperZoom",
    brand: "Realme",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Realme X3 SuperZoom",
    release_date: null,
  },
  {
    id: 2349,
    name: "Realme X50 Pro Player",
    brand: "Realme",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Realme X50 Pro Player",
    release_date: null,
  },
  {
    id: 2348,
    name: "Realme 6i",
    brand: "Realme",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Realme 6i",
    release_date: null,
  },
  {
    id: 2347,
    name: "Realme 6 Pro",
    brand: "Realme",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Realme 6 Pro",
    release_date: null,
  },
  {
    id: 2346,
    name: "Realme 6",
    brand: "Realme",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Realme 6",
    release_date: null,
  },
  {
    id: 2345,
    name: "Realme X50 Pro",
    brand: "Realme",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Realme X50 Pro",
    release_date: null,
  },
  {
    id: 2344,
    name: "Realme C3",
    brand: "Realme",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Realme C3",
    release_date: null,
  },
  {
    id: 2343,
    name: "Realme X50",
    brand: "Realme",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Realme X50",
    release_date: null,
  },
  {
    id: 2342,
    name: "Realme 5i",
    brand: "Realme",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Realme 5i",
    release_date: null,
  },
  {
    id: 2341,
    name: "Realme X2 Pro",
    brand: "Realme",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Realme X2 Pro",
    release_date: null,
  },
  {
    id: 2340,
    name: "Realme X2",
    brand: "Realme",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Realme X2",
    release_date: null,
  },
  {
    id: 2339,
    name: "Realme XT",
    brand: "Realme",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Realme XT",
    release_date: null,
  },
  {
    id: 2338,
    name: "Realme 5 Pro",
    brand: "Realme",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Realme 5 Pro",
    release_date: null,
  },
  {
    id: 2337,
    name: "Realme 5",
    brand: "Realme",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Realme 5",
    release_date: null,
  },
  {
    id: 2336,
    name: "Realme 3i",
    brand: "Realme",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Realme 3i",
    release_date: null,
  },
  {
    id: 2335,
    name: "Realme X",
    brand: "Realme",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Realme X",
    release_date: null,
  },
  {
    id: 2334,
    name: "Realme 3 Pro",
    brand: "Realme",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Realme 3 Pro",
    release_date: null,
  },
  {
    id: 2333,
    name: "Realme C2",
    brand: "Realme",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Realme C2",
    release_date: null,
  },
  {
    id: 2332,
    name: "Realme 3",
    brand: "Realme",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Realme 3",
    release_date: null,
  },
  {
    id: 2331,
    name: "Realme U1",
    brand: "Realme",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Realme U1",
    release_date: null,
  },
  {
    id: 2330,
    name: "Realme 2 Pro",
    brand: "Realme",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Realme 2 Pro",
    release_date: null,
  },
  {
    id: 2329,
    name: "Realme C1",
    brand: "Realme",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Realme C1",
    release_date: null,
  },
  {
    id: 2328,
    name: "Realme 2",
    brand: "Realme",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Realme 2",
    release_date: null,
  },
  {
    id: 2327,
    name: "Realme 1",
    brand: "Realme",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Realme 1",
    release_date: null,
  },
  {
    id: 2932,
    name: "Samsung Galaxy S22 Ultra",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy S22 Ultra",
    release_date: null,
  },
  {
    id: 2611,
    name: "Samsung Galaxy M52 5G",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy M52 5G",
    release_date: null,
  },
  {
    id: 2610,
    name: "Samsung Galaxy Z Fold 3/Z Flip 3",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy Z Fold 3/Z Flip 3",
    release_date: null,
  },
  {
    id: 2609,
    name: "Samsung Galaxy A22/A22 5G",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy A22/A22 5G",
    release_date: null,
  },
  {
    id: 2608,
    name: "Samsung Galaxy M32",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy M32",
    release_date: null,
  },
  {
    id: 2607,
    name: "Samsung Galaxy F52 5G",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy F52 5G",
    release_date: null,
  },
  {
    id: 2606,
    name: "Samsung Galaxy Quantum 2",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy Quantum 2",
    release_date: null,
  },
  {
    id: 2605,
    name: "Samsung Galaxy F12",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy F12",
    release_date: null,
  },
  {
    id: 2604,
    name: "Samsung Galaxy F02s",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy F02s",
    release_date: null,
  },
  {
    id: 2603,
    name: "Samsung Galaxy A72",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy A72",
    release_date: null,
  },
  {
    id: 2602,
    name: "Samsung Galaxy A52/A52 5G",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy A52/A52 5G",
    release_date: null,
  },
  {
    id: 2601,
    name: "Samsung Galaxy Xcover 5",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy Xcover 5",
    release_date: null,
  },
  {
    id: 2600,
    name: "Samsung Galaxy A32",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy A32",
    release_date: null,
  },
  {
    id: 2599,
    name: "Samsung Galaxy M62",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy M62",
    release_date: null,
  },
  {
    id: 2598,
    name: "Samsung Galaxy F62",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy F62",
    release_date: null,
  },
  {
    id: 2597,
    name: "Samsung Galaxy M12",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy M12",
    release_date: null,
  },
  {
    id: 2596,
    name: "Samsung Galaxy M02",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy M02",
    release_date: null,
  },
  {
    id: 2595,
    name: "Samsung Galaxy A02",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy A02",
    release_date: null,
  },
  {
    id: 2594,
    name: "Samsung Galaxy S21 Ultra",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy S21 Ultra",
    release_date: null,
  },
  {
    id: 2593,
    name: "Samsung Galaxy S21+",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy S21+",
    release_date: null,
  },
  {
    id: 2592,
    name: "Samsung Galaxy S21",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy S21",
    release_date: null,
  },
  {
    id: 2591,
    name: "Samsung Galaxy A32 5G",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy A32 5G",
    release_date: null,
  },
  {
    id: 2590,
    name: "Samsung Galaxy A02s/M02s",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy A02s/M02s",
    release_date: null,
  },
  {
    id: 2589,
    name: "Samsung Galaxy A12",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy A12",
    release_date: null,
  },
  {
    id: 2588,
    name: "Samsung Galaxy M21s",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy M21s",
    release_date: null,
  },
  {
    id: 2587,
    name: "Samsung Galaxy F41",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy F41",
    release_date: null,
  },
  {
    id: 2586,
    name: "Samsung Galaxy S20 FE",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy S20 FE",
    release_date: null,
  },
  {
    id: 2585,
    name: "Samsung Galaxy A42 5G",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy A42 5G",
    release_date: null,
  },
  {
    id: 2584,
    name: "Samsung Galaxy A51 5G UW",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy A51 5G UW",
    release_date: null,
  },
  {
    id: 2583,
    name: "Samsung Galaxy A01 Core",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy A01 Core",
    release_date: null,
  },
  {
    id: 2582,
    name: "Samsung Galaxy Z Fold 2",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy Z Fold 2",
    release_date: null,
  },
  {
    id: 2581,
    name: "Samsung Galaxy Note 20/Ultra",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy Note 20/Ultra",
    release_date: null,
  },
  {
    id: 2580,
    name: "Samsung Galaxy Z Flip 5G",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy Z Flip 5G",
    release_date: null,
  },
  {
    id: 2579,
    name: "Samsung Galaxy A71 5G UW",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy A71 5G UW",
    release_date: null,
  },
  {
    id: 2578,
    name: "Samsung Galaxy A21s",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy A21s",
    release_date: null,
  },
  {
    id: 2577,
    name: "Samsung Galaxy A21",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy A21",
    release_date: null,
  },
  {
    id: 2576,
    name: "Samsung Galaxy A51 5G/A71 5G",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy A51 5G/A71 5G",
    release_date: null,
  },
  {
    id: 2575,
    name: "Samsung Galaxy A41",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy A41",
    release_date: null,
  },
  {
    id: 2574,
    name: "Samsung Galaxy A11",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy A11",
    release_date: null,
  },
  {
    id: 2573,
    name: "Samsung Galaxy A31",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy A31",
    release_date: null,
  },
  {
    id: 2572,
    name: "Samsung Galaxy Xcover FieldPro",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy Xcover FieldPro",
    release_date: null,
  },
  {
    id: 2571,
    name: "Samsung Galaxy S20/+/Ultra",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy S20/+/Ultra",
    release_date: null,
  },
  {
    id: 2570,
    name: "Samsung Galaxy Z Flip",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy Z Flip",
    release_date: null,
  },
  {
    id: 2569,
    name: "Samsung Galaxy Xcover Pro",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy Xcover Pro",
    release_date: null,
  },
  {
    id: 2568,
    name: "Samsung Galaxy A01",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy A01",
    release_date: null,
  },
  {
    id: 2567,
    name: "Samsung Galaxy A51/A71",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy A51/A71",
    release_date: null,
  },
  {
    id: 2566,
    name: "Samsung Galaxy A20s",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy A20s",
    release_date: null,
  },
  {
    id: 2565,
    name: "Samsung Galaxy Z Fold",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy Z Fold",
    release_date: null,
  },
  {
    id: 2564,
    name: "Samsung Galaxy A70s",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy A70s",
    release_date: null,
  },
  {
    id: 2563,
    name: "Samsung Galaxy A90 5G",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy A90 5G",
    release_date: null,
  },
  {
    id: 2562,
    name: "Samsung Galaxy A30s/A50s",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy A30s/A50s",
    release_date: null,
  },
  {
    id: 2561,
    name: "Samsung Galaxy A10s",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy A10s",
    release_date: null,
  },
  {
    id: 2560,
    name: "Samsung Galaxy Note 10/+",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy Note 10/+",
    release_date: null,
  },
  {
    id: 2559,
    name: "Samsung Galaxy A10e",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy A10e",
    release_date: null,
  },
  {
    id: 2558,
    name: "Samsung Galaxy Xcover 4s",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy Xcover 4s",
    release_date: null,
  },
  {
    id: 2557,
    name: "Samsung Galaxy A60",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy A60",
    release_date: null,
  },
  {
    id: 2556,
    name: "Samsung Galaxy A80",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy A80",
    release_date: null,
  },
  {
    id: 2555,
    name: "Samsung Galaxy A70",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy A70",
    release_date: null,
  },
  {
    id: 2554,
    name: "Samsung Galaxy A20e",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy A20e",
    release_date: null,
  },
  {
    id: 2553,
    name: "Samsung Galaxy A2 Core",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy A2 Core",
    release_date: null,
  },
  {
    id: 2552,
    name: "Samsung Galaxy A20/A40",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy A20/A40",
    release_date: null,
  },
  {
    id: 2551,
    name: "Samsung Galaxy S10 5G",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy S10 5G",
    release_date: null,
  },
  {
    id: 2550,
    name: "Samsung Galaxy S10/e/+",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy S10/e/+",
    release_date: null,
  },
  {
    id: 2549,
    name: "Samsung Galaxy A50",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy A50",
    release_date: null,
  },
  {
    id: 2548,
    name: "Samsung Galaxy A30",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy A30",
    release_date: null,
  },
  {
    id: 2547,
    name: "Samsung Galaxy A10",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy A10",
    release_date: null,
  },
  {
    id: 2546,
    name: "Samsung Galaxy A8s",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy A8s",
    release_date: null,
  },
  {
    id: 2545,
    name: "Samsung Galaxy A6s",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy A6s",
    release_date: null,
  },
  {
    id: 2544,
    name: "Samsung Galaxy A9 (2018)",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy A9 (2018)",
    release_date: null,
  },
  {
    id: 2543,
    name: "Samsung Galaxy A7 (2018)",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy A7 (2018)",
    release_date: null,
  },
  {
    id: 2542,
    name: "Samsung Galaxy Note 9",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy Note 9",
    release_date: null,
  },
  {
    id: 2541,
    name: "Samsung Galaxy A8 Star",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy A8 Star",
    release_date: null,
  },
  {
    id: 2540,
    name: "Samsung Galaxy A6/+",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy A6/+",
    release_date: null,
  },
  {
    id: 2539,
    name: "Samsung Galaxy S9/+",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy S9/+",
    release_date: null,
  },
  {
    id: 2538,
    name: "Samsung Galaxy A8 (2018)/+",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy A8 (2018)/+",
    release_date: null,
  },
  {
    id: 2537,
    name: "Samsung Galaxy C5",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy C5",
    release_date: null,
  },
  {
    id: 2536,
    name: "Samsung Galaxy C8",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy C8",
    release_date: null,
  },
  {
    id: 2535,
    name: "Samsung Galaxy Note 8",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy Note 8",
    release_date: null,
  },
  {
    id: 2534,
    name: "Samsung Galaxy Note FE",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy Note FE",
    release_date: null,
  },
  {
    id: 2533,
    name: "Samsung Galaxy S8/+",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy S8/+",
    release_date: null,
  },
  {
    id: 2532,
    name: "Samsung Galaxy Xcover 4",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy Xcover 4",
    release_date: null,
  },
  {
    id: 2531,
    name: "Samsung Galaxy A3 (2017)/A5 (2017)/A7 (2017)",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy A3 (2017)/A5 (2017)/A7 (2017)",
    release_date: null,
  },
  {
    id: 2530,
    name: "Samsung Galaxy C9 Pro",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy C9 Pro",
    release_date: null,
  },
  {
    id: 2529,
    name: "Samsung Galaxy A8 (2016)",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy A8 (2016)",
    release_date: null,
  },
  {
    id: 2528,
    name: "Samsung Galaxy Note 7",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy Note 7",
    release_date: null,
  },
  {
    id: 2527,
    name: "Samsung Galaxy C7",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy C7",
    release_date: null,
  },
  {
    id: 2526,
    name: "Samsung Galaxy A9 Pro (2016)",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy A9 Pro (2016)",
    release_date: null,
  },
  {
    id: 2525,
    name: "Samsung Galaxy Xcover 3 G389F",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy Xcover 3 G389F",
    release_date: null,
  },
  {
    id: 2524,
    name: "Samsung Galaxy S7/Edge",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy S7/Edge",
    release_date: null,
  },
  {
    id: 2523,
    name: "Samsung Galaxy A9 (2016)",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy A9 (2016)",
    release_date: null,
  },
  {
    id: 2522,
    name: "Samsung Galaxy A3 (2016)/A5 (2016)/A7 (2016)",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy A3 (2016)/A5 (2016)/A7 (2016)",
    release_date: null,
  },
  {
    id: 2521,
    name: "Samsung Galaxy S6 Edge+",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy S6 Edge+",
    release_date: null,
  },
  {
    id: 2520,
    name: "Samsung Galaxy Note 5",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy Note 5",
    release_date: null,
  },
  {
    id: 2519,
    name: "Samsung Galaxy A8/Duos",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy A8/Duos",
    release_date: null,
  },
  {
    id: 2518,
    name: "Samsung Galaxy Xcover 3",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy Xcover 3",
    release_date: null,
  },
  {
    id: 2517,
    name: "Samsung Galaxy S6/Edge",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy S6/Edge",
    release_date: null,
  },
  {
    id: 2516,
    name: "Samsung Galaxy A7/Duos",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy A7/Duos",
    release_date: null,
  },
  {
    id: 2515,
    name: "Samsung Galaxy E7",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy E7",
    release_date: null,
  },
  {
    id: 2514,
    name: "Samsung Galaxy A3/Duos",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy A3/Duos",
    release_date: null,
  },
  {
    id: 2513,
    name: "Samsung Galaxy A5",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy A5",
    release_date: null,
  },
  {
    id: 2512,
    name: "Samsung Galaxy A5 Duos",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy A5 Duos",
    release_date: null,
  },
  {
    id: 2511,
    name: "Samsung Galaxy Note Edge",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy Note Edge",
    release_date: null,
  },
  {
    id: 2510,
    name: "Samsung Galaxy Note 4",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy Note 4",
    release_date: null,
  },
  {
    id: 2509,
    name: "Samsung Galaxy Grand Prime",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy Grand Prime",
    release_date: null,
  },
  {
    id: 2508,
    name: "Samsung Galaxy Alpha",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy Alpha",
    release_date: null,
  },
  {
    id: 2507,
    name: "Samsung Galaxy Beam 2",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy Beam 2",
    release_date: null,
  },
  {
    id: 2506,
    name: "Samsung Galaxy S5",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy S5",
    release_date: null,
  },
  {
    id: 2505,
    name: "Samsung Galaxy Note 3 Neo",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy Note 3 Neo",
    release_date: null,
  },
  {
    id: 2504,
    name: "Samsung Galaxy Note 3",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy Note 3",
    release_date: null,
  },
  {
    id: 2503,
    name: "Samsung Galaxy S4 Zoom",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy S4 Zoom",
    release_date: null,
  },
  {
    id: 2502,
    name: "Samsung Galaxy S4",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy S4",
    release_date: null,
  },
  {
    id: 2501,
    name: "Samsung Galaxy Xcover 2",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy Xcover 2",
    release_date: null,
  },
  {
    id: 2500,
    name: "Samsung Galaxy Note 2",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy Note 2",
    release_date: null,
  },
  {
    id: 2499,
    name: "Samsung Galaxy Beam",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy Beam",
    release_date: null,
  },
  {
    id: 2498,
    name: "Samsung Galaxy S3",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy S3",
    release_date: null,
  },
  {
    id: 2497,
    name: "Samsung Galaxy Y DUOS",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy Y DUOS",
    release_date: null,
  },
  {
    id: 2496,
    name: "Samsung Galaxy Note",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy Note",
    release_date: null,
  },
  {
    id: 2495,
    name: "Samsung Galaxy Xcover",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy Xcover",
    release_date: null,
  },
  {
    id: 2494,
    name: "Samsung Galaxy S2",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy S2",
    release_date: null,
  },
  {
    id: 2493,
    name: "Samsung Galaxy S1",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy S1",
    release_date: null,
  },
  {
    id: 2492,
    name: "Samsung Galaxy",
    brand: "Samsung Electronics",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Samsung Galaxy",
    release_date: null,
  },
  {
    id: 2093,
    name: "Galaxy Nexus",
    brand: "Samsung Electronics/Google",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Galaxy Nexus",
    release_date: null,
  },
  {
    id: 2092,
    name: "Nexus S",
    brand: "Samsung Electronics/Google",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Nexus S",
    release_date: null,
  },
  {
    id: 2615,
    name: "Sony Ericsson Xperia pro",
    brand: "Sony Ericsson",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Sony Ericsson Xperia pro",
    release_date: null,
  },
  {
    id: 2614,
    name: "Sony Ericsson Xperia Play",
    brand: "Sony Ericsson",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Sony Ericsson Xperia Play",
    release_date: null,
  },
  {
    id: 2613,
    name: "Sony Ericsson Xperia X8",
    brand: "Sony Ericsson",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Sony Ericsson Xperia X8",
    release_date: null,
  },
  {
    id: 2612,
    name: "Sony Ericsson Xperia X10",
    brand: "Sony Ericsson",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Sony Ericsson Xperia X10",
    release_date: null,
  },
  {
    id: 2654,
    name: "Sony Xperia 1 III",
    brand: "Sony Mobile",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Sony Xperia 1 III",
    release_date: null,
  },
  {
    id: 2653,
    name: "Sony Xperia 5 III",
    brand: "Sony Mobile",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Sony Xperia 5 III",
    release_date: null,
  },
  {
    id: 2652,
    name: "Sony Xperia 10 III",
    brand: "Sony Mobile",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Sony Xperia 10 III",
    release_date: null,
  },
  {
    id: 2651,
    name: "Sony Xperia Pro",
    brand: "Sony Mobile",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Sony Xperia Pro",
    release_date: null,
  },
  {
    id: 2650,
    name: "Sony Xperia 5 II",
    brand: "Sony Mobile",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Sony Xperia 5 II",
    release_date: null,
  },
  {
    id: 2649,
    name: "Sony Xperia 10 II",
    brand: "Sony Mobile",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Sony Xperia 10 II",
    release_date: null,
  },
  {
    id: 2648,
    name: "Sony Xperia 1 II",
    brand: "Sony Mobile",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Sony Xperia 1 II",
    release_date: null,
  },
  {
    id: 2647,
    name: "Sony Xperia L4",
    brand: "Sony Mobile",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Sony Xperia L4",
    release_date: null,
  },
  {
    id: 2646,
    name: "Sony Xperia 8",
    brand: "Sony Mobile",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Sony Xperia 8",
    release_date: null,
  },
  {
    id: 2645,
    name: "Sony Xperia 5",
    brand: "Sony Mobile",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Sony Xperia 5",
    release_date: null,
  },
  {
    id: 2644,
    name: "Sony Xperia 1",
    brand: "Sony Mobile",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Sony Xperia 1",
    release_date: null,
  },
  {
    id: 2643,
    name: "Sony Xperia L3",
    brand: "Sony Mobile",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Sony Xperia L3",
    release_date: null,
  },
  {
    id: 2642,
    name: "Sony Xperia 10/10 Plus",
    brand: "Sony Mobile",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Sony Xperia 10/10 Plus",
    release_date: null,
  },
  {
    id: 2641,
    name: "Sony Xperia XA2 Plus",
    brand: "Sony Mobile",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Sony Xperia XA2 Plus",
    release_date: null,
  },
  {
    id: 2640,
    name: "Sony Xperia XZ3",
    brand: "Sony Mobile",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Sony Xperia XZ3",
    release_date: null,
  },
  {
    id: 2639,
    name: "Sony Xperia XZ2 Premium",
    brand: "Sony Mobile",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Sony Xperia XZ2 Premium",
    release_date: null,
  },
  {
    id: 2638,
    name: "Sony Xperia XZ2/Compact",
    brand: "Sony Mobile",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Sony Xperia XZ2/Compact",
    release_date: null,
  },
  {
    id: 2637,
    name: "Sony Xperia XA2/Ultra",
    brand: "Sony Mobile",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Sony Xperia XA2/Ultra",
    release_date: null,
  },
  {
    id: 2636,
    name: "Sony Xperia L2",
    brand: "Sony Mobile",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Sony Xperia L2",
    release_date: null,
  },
  {
    id: 2635,
    name: "Sony Xperia XA1 Plus",
    brand: "Sony Mobile",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Sony Xperia XA1 Plus",
    release_date: null,
  },
  {
    id: 2634,
    name: "Sony Xperia XZ1/Compact",
    brand: "Sony Mobile",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Sony Xperia XZ1/Compact",
    release_date: null,
  },
  {
    id: 2633,
    name: "Sony Xperia L1",
    brand: "Sony Mobile",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Sony Xperia L1",
    release_date: null,
  },
  {
    id: 2632,
    name: "Sony Xperia XA1 Ultra",
    brand: "Sony Mobile",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Sony Xperia XA1 Ultra",
    release_date: null,
  },
  {
    id: 2631,
    name: "Sony Xperia XA1",
    brand: "Sony Mobile",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Sony Xperia XA1",
    release_date: null,
  },
  {
    id: 2630,
    name: "Sony Xperia XZs/Premium",
    brand: "Sony Mobile",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Sony Xperia XZs/Premium",
    release_date: null,
  },
  {
    id: 2629,
    name: "Sony Xperia XZ",
    brand: "Sony Mobile",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Sony Xperia XZ",
    release_date: null,
  },
  {
    id: 2628,
    name: "Sony Xperia X Compact",
    brand: "Sony Mobile",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Sony Xperia X Compact",
    release_date: null,
  },
  {
    id: 2627,
    name: "Sony Xperia XA Ultra",
    brand: "Sony Mobile",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Sony Xperia XA Ultra",
    release_date: null,
  },
  {
    id: 2626,
    name: "Sony Xperia XA",
    brand: "Sony Mobile",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Sony Xperia XA",
    release_date: null,
  },
  {
    id: 2625,
    name: "Sony Xperia X/Performance",
    brand: "Sony Mobile",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Sony Xperia X/Performance",
    release_date: null,
  },
  {
    id: 2624,
    name: "Sony Xperia Z5 Premium",
    brand: "Sony Mobile",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Sony Xperia Z5 Premium",
    release_date: null,
  },
  {
    id: 2623,
    name: "Sony Xperia Z5/Compact",
    brand: "Sony Mobile",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Sony Xperia Z5/Compact",
    release_date: null,
  },
  {
    id: 2622,
    name: "Sony Xperia Z4",
    brand: "Sony Mobile",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Sony Xperia Z4",
    release_date: null,
  },
  {
    id: 2621,
    name: "Sony Xperia Z3/Compact",
    brand: "Sony Mobile",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Sony Xperia Z3/Compact",
    release_date: null,
  },
  {
    id: 2620,
    name: "Sony Xperia Z2",
    brand: "Sony Mobile",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Sony Xperia Z2",
    release_date: null,
  },
  {
    id: 2619,
    name: "Sony Xperia Z1 Compact",
    brand: "Sony Mobile",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Sony Xperia Z1 Compact",
    release_date: null,
  },
  {
    id: 2618,
    name: "Sony Xperia Z1",
    brand: "Sony Mobile",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Sony Xperia Z1",
    release_date: null,
  },
  {
    id: 2617,
    name: "Sony Xperia Z Ultra",
    brand: "Sony Mobile",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Sony Xperia Z Ultra",
    release_date: null,
  },
  {
    id: 2616,
    name: "Sony Xperia Z",
    brand: "Sony Mobile",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Sony Xperia Z",
    release_date: null,
  },
  {
    id: 2657,
    name: "T-Mobile G1",
    brand: "T-Mobile",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "T-Mobile G1",
    release_date: null,
  },
  {
    id: 2663,
    name: "TCL 10 Plus",
    brand: "TCL",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "TCL 10 Plus",
    release_date: null,
  },
  {
    id: 2662,
    name: "TCL 10 5G",
    brand: "TCL",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "TCL 10 5G",
    release_date: null,
  },
  {
    id: 2661,
    name: "TCL 10 SE",
    brand: "TCL",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "TCL 10 SE",
    release_date: null,
  },
  {
    id: 2660,
    name: "TCL 10 Pro",
    brand: "TCL",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "TCL 10 Pro",
    release_date: null,
  },
  {
    id: 2659,
    name: "TCL 10L",
    brand: "TCL",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "TCL 10L",
    release_date: null,
  },
  {
    id: 2658,
    name: "TCL Plex",
    brand: "TCL",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "TCL Plex",
    release_date: null,
  },
  {
    id: 2695,
    name: "Tecno Pova",
    brand: "Tecno Mobile",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Tecno Pova",
    release_date: null,
  },
  {
    id: 2694,
    name: "Tecno Camon 16",
    brand: "Tecno Mobile",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Tecno Camon 16",
    release_date: null,
  },
  {
    id: 2693,
    name: "Tecno Spark 6",
    brand: "Tecno Mobile",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Tecno Spark 6",
    release_date: null,
  },
  {
    id: 2692,
    name: "Tecno Spark 6 Air",
    brand: "Tecno Mobile",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Tecno Spark 6 Air",
    release_date: null,
  },
  {
    id: 2691,
    name: "Tecno Camon 16 Premier",
    brand: "Tecno Mobile",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Tecno Camon 16 Premier",
    release_date: null,
  },
  {
    id: 2690,
    name: "Tecno Pouvoir 4/Pro",
    brand: "Tecno Mobile",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Tecno Pouvoir 4/Pro",
    release_date: null,
  },
  {
    id: 2689,
    name: "Tecno Spark Power 2",
    brand: "Tecno Mobile",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Tecno Spark Power 2",
    release_date: null,
  },
  {
    id: 2688,
    name: "Tecno Spark 5 Pro",
    brand: "Tecno Mobile",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Tecno Spark 5 Pro",
    release_date: null,
  },
  {
    id: 2687,
    name: "Tecno Spark 5 Air",
    brand: "Tecno Mobile",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Tecno Spark 5 Air",
    release_date: null,
  },
  {
    id: 2686,
    name: "Tecno Spark 5",
    brand: "Tecno Mobile",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Tecno Spark 5",
    release_date: null,
  },
  {
    id: 2685,
    name: "Tecno Camon 15 Air/Premier",
    brand: "Tecno Mobile",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Tecno Camon 15 Air/Premier",
    release_date: null,
  },
  {
    id: 2684,
    name: "Tecno Camon 15/Pro",
    brand: "Tecno Mobile",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Tecno Camon 15/Pro",
    release_date: null,
  },
  {
    id: 2683,
    name: "Tecno Camon 12 Air",
    brand: "Tecno Mobile",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Tecno Camon 12 Air",
    release_date: null,
  },
  {
    id: 2682,
    name: "Tecno Camon 12 Pro",
    brand: "Tecno Mobile",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Tecno Camon 12 Pro",
    release_date: null,
  },
  {
    id: 2681,
    name: "Tecno Camon 12",
    brand: "Tecno Mobile",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Tecno Camon 12",
    release_date: null,
  },
  {
    id: 2680,
    name: "Tecno Spark 4/Air/Lite",
    brand: "Tecno Mobile",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Tecno Spark 4/Air/Lite",
    release_date: null,
  },
  {
    id: 2679,
    name: "Tecno Spark Go",
    brand: "Tecno Mobile",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Tecno Spark Go",
    release_date: null,
  },
  {
    id: 2678,
    name: "Tecno Phantom 9",
    brand: "Tecno Mobile",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Tecno Phantom 9",
    release_date: null,
  },
  {
    id: 2677,
    name: "Tecno Spark 3/Pro",
    brand: "Tecno Mobile",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Tecno Spark 3/Pro",
    release_date: null,
  },
  {
    id: 2676,
    name: "Tecno Camon iACE2/2X",
    brand: "Tecno Mobile",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Tecno Camon iACE2/2X",
    release_date: null,
  },
  {
    id: 2675,
    name: "Tecno Camon 11/Pro",
    brand: "Tecno Mobile",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Tecno Camon 11/Pro",
    release_date: null,
  },
  {
    id: 2674,
    name: "Tecno Spark 2",
    brand: "Tecno Mobile",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Tecno Spark 2",
    release_date: null,
  },
  {
    id: 2673,
    name: "Tecno Camon X/Pro",
    brand: "Tecno Mobile",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Tecno Camon X/Pro",
    release_date: null,
  },
  {
    id: 2672,
    name: "Tecno Spark CM",
    brand: "Tecno Mobile",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Tecno Spark CM",
    release_date: null,
  },
  {
    id: 2671,
    name: "Tecno Camon CM",
    brand: "Tecno Mobile",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Tecno Camon CM",
    release_date: null,
  },
  {
    id: 2670,
    name: "Tecno Spark Pro",
    brand: "Tecno Mobile",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Tecno Spark Pro",
    release_date: null,
  },
  {
    id: 2669,
    name: "Tecno Phantom 8",
    brand: "Tecno Mobile",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Tecno Phantom 8",
    release_date: null,
  },
  {
    id: 2668,
    name: "Tecno Spark Plus",
    brand: "Tecno Mobile",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Tecno Spark Plus",
    release_date: null,
  },
  {
    id: 2667,
    name: "Tecno Spark",
    brand: "Tecno Mobile",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Tecno Spark",
    release_date: null,
  },
  {
    id: 2666,
    name: "Tecno Camon CX Air",
    brand: "Tecno Mobile",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Tecno Camon CX Air",
    release_date: null,
  },
  {
    id: 2665,
    name: "Tecno Camon CX",
    brand: "Tecno Mobile",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Tecno Camon CX",
    release_date: null,
  },
  {
    id: 2664,
    name: "Tecno Phantom 6/Plus",
    brand: "Tecno Mobile",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Tecno Phantom 6/Plus",
    release_date: null,
  },
  {
    id: 2699,
    name: "Umidigi A9/+/Max",
    brand: "Umidigi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Umidigi A9/+/Max",
    release_date: null,
  },
  {
    id: 2698,
    name: "Unihertz TickTock",
    brand: "Unihertz",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Unihertz TickTock",
    release_date: null,
  },
  {
    id: 1845,
    name: "Jupiter IO 3",
    brand: "Vaporcade",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Jupiter IO 3",
    release_date: null,
  },
  {
    id: 2701,
    name: "Vertu Ti",
    brand: "Vertu",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Vertu Ti",
    release_date: null,
  },
  {
    id: 2751,
    name: "Vivo X60/Pro/Pro+",
    brand: "Vivo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Vivo X60/Pro/Pro+",
    release_date: null,
  },
  {
    id: 2750,
    name: "Vivo V20 (2021)",
    brand: "Vivo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Vivo V20 (2021)",
    release_date: null,
  },
  {
    id: 2749,
    name: "Vivo X51",
    brand: "Vivo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Vivo X51",
    release_date: null,
  },
  {
    id: 2748,
    name: "Vivo V20",
    brand: "Vivo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Vivo V20",
    release_date: null,
  },
  {
    id: 2747,
    name: "Vivo V20 SE",
    brand: "Vivo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Vivo V20 SE",
    release_date: null,
  },
  {
    id: 2746,
    name: "Vivo X50e",
    brand: "Vivo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Vivo X50e",
    release_date: null,
  },
  {
    id: 2745,
    name: "Vivo V20 Pro",
    brand: "Vivo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Vivo V20 Pro",
    release_date: null,
  },
  {
    id: 2744,
    name: "Vivo V19 Neo",
    brand: "Vivo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Vivo V19 Neo",
    release_date: null,
  },
  {
    id: 2743,
    name: "Vivo X50/Pro/Pro+",
    brand: "Vivo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Vivo X50/Pro/Pro+",
    release_date: null,
  },
  {
    id: 2742,
    name: "Vivo X50 Lite",
    brand: "Vivo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Vivo X50 Lite",
    release_date: null,
  },
  {
    id: 2741,
    name: "Vivo V19",
    brand: "Vivo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Vivo V19",
    release_date: null,
  },
  {
    id: 2740,
    name: "Vivo NEX 3S",
    brand: "Vivo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Vivo NEX 3S",
    release_date: null,
  },
  {
    id: 2739,
    name: "Vivo X30/Pro",
    brand: "Vivo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Vivo X30/Pro",
    release_date: null,
  },
  {
    id: 2738,
    name: "Vivo V17",
    brand: "Vivo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Vivo V17",
    release_date: null,
  },
  {
    id: 2737,
    name: "Vivo V17 Pro",
    brand: "Vivo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Vivo V17 Pro",
    release_date: null,
  },
  {
    id: 2736,
    name: "Vivo NEX 3",
    brand: "Vivo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Vivo NEX 3",
    release_date: null,
  },
  {
    id: 2735,
    name: "Vivo V17 Neo",
    brand: "Vivo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Vivo V17 Neo",
    release_date: null,
  },
  {
    id: 2734,
    name: "Vivo X27/Pro",
    brand: "Vivo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Vivo X27/Pro",
    release_date: null,
  },
  {
    id: 2733,
    name: "Vivo V15/Pro",
    brand: "Vivo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Vivo V15/Pro",
    release_date: null,
  },
  {
    id: 2732,
    name: "Vivo NEX Dual Display",
    brand: "Vivo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Vivo NEX Dual Display",
    release_date: null,
  },
  {
    id: 2731,
    name: "Vivo V11i",
    brand: "Vivo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Vivo V11i",
    release_date: null,
  },
  {
    id: 2730,
    name: "Vivo X23",
    brand: "Vivo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Vivo X23",
    release_date: null,
  },
  {
    id: 2729,
    name: "Vivo V11",
    brand: "Vivo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Vivo V11",
    release_date: null,
  },
  {
    id: 2728,
    name: "Vivo NEX",
    brand: "Vivo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Vivo NEX",
    release_date: null,
  },
  {
    id: 2727,
    name: "Vivo X21i",
    brand: "Vivo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Vivo X21i",
    release_date: null,
  },
  {
    id: 2726,
    name: "Vivo V9/Youth",
    brand: "Vivo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Vivo V9/Youth",
    release_date: null,
  },
  {
    id: 2725,
    name: "Vivo X21/UD",
    brand: "Vivo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Vivo X21/UD",
    release_date: null,
  },
  {
    id: 2724,
    name: "Vivo X20 Plus UD",
    brand: "Vivo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Vivo X20 Plus UD",
    release_date: null,
  },
  {
    id: 2723,
    name: "Vivo V7",
    brand: "Vivo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Vivo V7",
    release_date: null,
  },
  {
    id: 2722,
    name: "Vivo X20/Plus",
    brand: "Vivo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Vivo X20/Plus",
    release_date: null,
  },
  {
    id: 2721,
    name: "Vivo V7+",
    brand: "Vivo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Vivo V7+",
    release_date: null,
  },
  {
    id: 2720,
    name: "Vivo X9s/Plus",
    brand: "Vivo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Vivo X9s/Plus",
    release_date: null,
  },
  {
    id: 2719,
    name: "Vivo V5s",
    brand: "Vivo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Vivo V5s",
    release_date: null,
  },
  {
    id: 2718,
    name: "Vivo V5 Lite/Y66",
    brand: "Vivo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Vivo V5 Lite/Y66",
    release_date: null,
  },
  {
    id: 2717,
    name: "Vivo V5 Plus",
    brand: "Vivo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Vivo V5 Plus",
    release_date: null,
  },
  {
    id: 2716,
    name: "Vivo V5",
    brand: "Vivo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Vivo V5",
    release_date: null,
  },
  {
    id: 2715,
    name: "Vivo X9/Plus",
    brand: "Vivo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Vivo X9/Plus",
    release_date: null,
  },
  {
    id: 2714,
    name: "Vivo X7/Plus",
    brand: "Vivo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Vivo X7/Plus",
    release_date: null,
  },
  {
    id: 2713,
    name: "Vivo V3/Max",
    brand: "Vivo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Vivo V3/Max",
    release_date: null,
  },
  {
    id: 2712,
    name: "Vivo X6S/Plus",
    brand: "Vivo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Vivo X6S/Plus",
    release_date: null,
  },
  {
    id: 2711,
    name: "Vivo X6/Plus",
    brand: "Vivo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Vivo X6/Plus",
    release_date: null,
  },
  {
    id: 2710,
    name: "Vivo V1 Max",
    brand: "Vivo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Vivo V1 Max",
    release_date: null,
  },
  {
    id: 2709,
    name: "Vivo V1",
    brand: "Vivo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Vivo V1",
    release_date: null,
  },
  {
    id: 2708,
    name: "Vivo X5Max Platinum",
    brand: "Vivo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Vivo X5Max Platinum",
    release_date: null,
  },
  {
    id: 2707,
    name: "Vivo X5Pro",
    brand: "Vivo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Vivo X5Pro",
    release_date: null,
  },
  {
    id: 2706,
    name: "Vivo X5Max+",
    brand: "Vivo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Vivo X5Max+",
    release_date: null,
  },
  {
    id: 2705,
    name: "Vivo X5/Max",
    brand: "Vivo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Vivo X5/Max",
    release_date: null,
  },
  {
    id: 2704,
    name: "Vivo X3S",
    brand: "Vivo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Vivo X3S",
    release_date: null,
  },
  {
    id: 2703,
    name: "Vivo X3",
    brand: "Vivo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Vivo X3",
    release_date: null,
  },
  {
    id: 2702,
    name: "Vivo X1",
    brand: "Vivo",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Vivo X1",
    release_date: null,
  },
  {
    id: 2752,
    name: "Vizio VIA Phone",
    brand: "Vizio",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Vizio VIA Phone",
    release_date: null,
  },
  {
    id: 2828,
    name: "Xiaomi 11i/HyperCharge",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Xiaomi 11i/HyperCharge",
    release_date: null,
  },
  {
    id: 2827,
    name: "Xiaomi 12/Pro/X",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Xiaomi 12/Pro/X",
    release_date: null,
  },
  {
    id: 2826,
    name: "Xiaomi 11 Lite 5G NE",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Xiaomi 11 Lite 5G NE",
    release_date: null,
  },
  {
    id: 2825,
    name: "Xiaomi 11T/Pro",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Xiaomi 11T/Pro",
    release_date: null,
  },
  {
    id: 2824,
    name: "Xiaomi MIX 4",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Xiaomi MIX 4",
    release_date: null,
  },
  {
    id: 2823,
    name: "POCO X3 GT",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "POCO X3 GT",
    release_date: null,
  },
  {
    id: 2822,
    name: "POCO F3 GT",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "POCO F3 GT",
    release_date: null,
  },
  {
    id: 2821,
    name: "POCO M3 Pro",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "POCO M3 Pro",
    release_date: null,
  },
  {
    id: 2820,
    name: "Xiaomi Mi 11X/Pro",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Xiaomi Mi 11X/Pro",
    release_date: null,
  },
  {
    id: 2819,
    name: "POCO M2 Reloaded",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "POCO M2 Reloaded",
    release_date: null,
  },
  {
    id: 2818,
    name: "Xiaomi Mi MIX Fold",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Xiaomi Mi MIX Fold",
    release_date: null,
  },
  {
    id: 2817,
    name: "Xiaomi Mi 11 Lite/Lite 5G",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Xiaomi Mi 11 Lite/Lite 5G",
    release_date: null,
  },
  {
    id: 2816,
    name: "Xiaomi Mi 11 Pro/Ultra/11i",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Xiaomi Mi 11 Pro/Ultra/11i",
    release_date: null,
  },
  {
    id: 2815,
    name: "Xiaomi Black Shark 4/Pro",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Xiaomi Black Shark 4/Pro",
    release_date: null,
  },
  {
    id: 2814,
    name: "POCO F3",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "POCO F3",
    release_date: null,
  },
  {
    id: 2813,
    name: "POCO X3 Pro",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "POCO X3 Pro",
    release_date: null,
  },
  {
    id: 2812,
    name: "Xiaomi Mi 10S",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Xiaomi Mi 10S",
    release_date: null,
  },
  {
    id: 2811,
    name: "Xiaomi Mi 10i",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Xiaomi Mi 10i",
    release_date: null,
  },
  {
    id: 2810,
    name: "Xiaomi Mi 11",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Xiaomi Mi 11",
    release_date: null,
  },
  {
    id: 2809,
    name: "POCO M3",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "POCO M3",
    release_date: null,
  },
  {
    id: 2808,
    name: "POCO C3",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "POCO C3",
    release_date: null,
  },
  {
    id: 2807,
    name: "Xiaomi Mi 10T/Pro/Lite",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Xiaomi Mi 10T/Pro/Lite",
    release_date: null,
  },
  {
    id: 2806,
    name: "POCO X3",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "POCO X3",
    release_date: null,
  },
  {
    id: 2805,
    name: "POCO M2",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "POCO M2",
    release_date: null,
  },
  {
    id: 2804,
    name: "POCO X3 NFC",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "POCO X3 NFC",
    release_date: null,
  },
  {
    id: 2803,
    name: "Xiaomi Mi 10 Ultra",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Xiaomi Mi 10 Ultra",
    release_date: null,
  },
  {
    id: 2802,
    name: "POCO M2 Pro",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "POCO M2 Pro",
    release_date: null,
  },
  {
    id: 2801,
    name: "Xiaomi Mi Note 10 Lite",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Xiaomi Mi Note 10 Lite",
    release_date: null,
  },
  {
    id: 2800,
    name: "Xiaomi Mi 10 Youth",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Xiaomi Mi 10 Youth",
    release_date: null,
  },
  {
    id: 2799,
    name: "Xiaomi Mi 10 Lite",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Xiaomi Mi 10 Lite",
    release_date: null,
  },
  {
    id: 2798,
    name: "Xiaomi Black Shark 3/Pro",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Xiaomi Black Shark 3/Pro",
    release_date: null,
  },
  {
    id: 2797,
    name: "Xiaomi Mi 10/Pro",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Xiaomi Mi 10/Pro",
    release_date: null,
  },
  {
    id: 2796,
    name: "POCO X2",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "POCO X2",
    release_date: null,
  },
  {
    id: 2795,
    name: "Xiaomi Mi Note 10/Pro",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Xiaomi Mi Note 10/Pro",
    release_date: null,
  },
  {
    id: 2794,
    name: "Xiaomi Mi 9 Pro/Pro 5G",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Xiaomi Mi 9 Pro/Pro 5G",
    release_date: null,
  },
  {
    id: 2793,
    name: "Xiaomi Mi 9 Lite",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Xiaomi Mi 9 Lite",
    release_date: null,
  },
  {
    id: 2792,
    name: "Xiaomi Black Shark 2 Pro",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Xiaomi Black Shark 2 Pro",
    release_date: null,
  },
  {
    id: 2791,
    name: "Xiaomi Mi 9T Pro",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Xiaomi Mi 9T Pro",
    release_date: null,
  },
  {
    id: 2790,
    name: "Xiaomi Mi A3",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Xiaomi Mi A3",
    release_date: null,
  },
  {
    id: 2789,
    name: "Xiaomi Mi 9T",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Xiaomi Mi 9T",
    release_date: null,
  },
  {
    id: 2788,
    name: "Xiaomi Mi MIX 3 5G",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Xiaomi Mi MIX 3 5G",
    release_date: null,
  },
  {
    id: 2787,
    name: "Xiaomi Black Shark 2",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Xiaomi Black Shark 2",
    release_date: null,
  },
  {
    id: 2786,
    name: "Xiaomi Mi 9/SE",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Xiaomi Mi 9/SE",
    release_date: null,
  },
  {
    id: 2785,
    name: "Xiaomi Mi Play",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Xiaomi Mi Play",
    release_date: null,
  },
  {
    id: 2784,
    name: "Xiaomi Black Shark Helo",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Xiaomi Black Shark Helo",
    release_date: null,
  },
  {
    id: 2783,
    name: "Xiaomi Mi MIX 3",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Xiaomi Mi MIX 3",
    release_date: null,
  },
  {
    id: 2782,
    name: "Xiaomi Mi 8 Pro/Lite",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Xiaomi Mi 8 Pro/Lite",
    release_date: null,
  },
  {
    id: 2781,
    name: "Xiaomi Pocophone F1",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Xiaomi Pocophone F1",
    release_date: null,
  },
  {
    id: 2780,
    name: "Xiaomi Mi Max 3",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Xiaomi Mi Max 3",
    release_date: null,
  },
  {
    id: 2779,
    name: "Xiaomi Mi 8/SE/EE",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Xiaomi Mi 8/SE/EE",
    release_date: null,
  },
  {
    id: 2778,
    name: "Xiaomi Black Shark",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Xiaomi Black Shark",
    release_date: null,
  },
  {
    id: 2777,
    name: "Xiaomi Mi 6X/Mi A2",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Xiaomi Mi 6X/Mi A2",
    release_date: null,
  },
  {
    id: 2776,
    name: "Xiaomi Mi MIX 2S",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Xiaomi Mi MIX 2S",
    release_date: null,
  },
  {
    id: 2775,
    name: "Xiaomi Mi MIX 2",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Xiaomi Mi MIX 2",
    release_date: null,
  },
  {
    id: 2774,
    name: "Xiaomi Mi Note 3",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Xiaomi Mi Note 3",
    release_date: null,
  },
  {
    id: 2773,
    name: "Xiaomi Mi 5X/Mi A1",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Xiaomi Mi 5X/Mi A1",
    release_date: null,
  },
  {
    id: 2772,
    name: "Xiaomi Mi Max 2",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Xiaomi Mi Max 2",
    release_date: null,
  },
  {
    id: 2771,
    name: "Xiaomi Mi 6",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Xiaomi Mi 6",
    release_date: null,
  },
  {
    id: 2770,
    name: "Xiaomi Mi 5c",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Xiaomi Mi 5c",
    release_date: null,
  },
  {
    id: 2769,
    name: "Xiaomi Mi MIX",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Xiaomi Mi MIX",
    release_date: null,
  },
  {
    id: 2768,
    name: "Xiaomi Mi Note 2",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Xiaomi Mi Note 2",
    release_date: null,
  },
  {
    id: 2767,
    name: "Xiaomi Mi 5s/Plus",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Xiaomi Mi 5s/Plus",
    release_date: null,
  },
  {
    id: 2766,
    name: "Xiaomi Mi Max/Pro",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Xiaomi Mi Max/Pro",
    release_date: null,
  },
  {
    id: 2765,
    name: "Xiaomi Mi 5",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Xiaomi Mi 5",
    release_date: null,
  },
  {
    id: 2764,
    name: "Xiaomi Mi 4S",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Xiaomi Mi 4S",
    release_date: null,
  },
  {
    id: 2763,
    name: "Xiaomi Mi 4c",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Xiaomi Mi 4c",
    release_date: null,
  },
  {
    id: 2762,
    name: "Xiaomi Mi 4i",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Xiaomi Mi 4i",
    release_date: null,
  },
  {
    id: 2761,
    name: "Xiaomi Mi Note Pro",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Xiaomi Mi Note Pro",
    release_date: null,
  },
  {
    id: 2760,
    name: "Xiaomi Mi Note",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Xiaomi Mi Note",
    release_date: null,
  },
  {
    id: 2759,
    name: "Xiaomi Mi 4",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Xiaomi Mi 4",
    release_date: null,
  },
  {
    id: 2758,
    name: "Xiaomi Mi 3/TD",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Xiaomi Mi 3/TD",
    release_date: null,
  },
  {
    id: 2757,
    name: "Xiaomi Mi 2S/A",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Xiaomi Mi 2S/A",
    release_date: null,
  },
  {
    id: 2756,
    name: "Xiaomi Mi 2",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Xiaomi Mi 2",
    release_date: null,
  },
  {
    id: 2755,
    name: "Xiaomi Mi 1S",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Xiaomi Mi 1S",
    release_date: null,
  },
  {
    id: 2754,
    name: "Xiaomi Mi 1",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Xiaomi Mi 1",
    release_date: null,
  },
  {
    id: 2490,
    name: "Redmi 10A",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Redmi 10A",
    release_date: null,
  },
  {
    id: 2489,
    name: "Redmi K50/Pro",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Redmi K50/Pro",
    release_date: null,
  },
  {
    id: 2488,
    name: "Redmi K40S",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Redmi K40S",
    release_date: null,
  },
  {
    id: 2487,
    name: "Redmi 10C",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Redmi 10C",
    release_date: null,
  },
  {
    id: 2486,
    name: "Redmi 10 (India)",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Redmi 10 (India)",
    release_date: null,
  },
  {
    id: 2485,
    name: "Redmi Note 11 Pro+ (India)",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Redmi Note 11 Pro+ (India)",
    release_date: null,
  },
  {
    id: 2484,
    name: "Redmi Note 11E/11E Pro",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Redmi Note 11E/11E Pro",
    release_date: null,
  },
  {
    id: 2483,
    name: "Redmi 10 2022",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Redmi 10 2022",
    release_date: null,
  },
  {
    id: 2482,
    name: "Redmi K50G",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Redmi K50G",
    release_date: null,
  },
  {
    id: 2481,
    name: "Redmi Note 11 Pro/11 Pro 5G",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Redmi Note 11 Pro/11 Pro 5G",
    release_date: null,
  },
  {
    id: 2480,
    name: "Redmi Note 11/11S",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Redmi Note 11/11S",
    release_date: null,
  },
  {
    id: 2479,
    name: "Redmi Note 11T",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Redmi Note 11T",
    release_date: null,
  },
  {
    id: 2478,
    name: "Redmi Note 11 4G",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Redmi Note 11 4G",
    release_date: null,
  },
  {
    id: 2477,
    name: "Redmi Note 11 5G/Pro (China)/Pro+ 5G",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Redmi Note 11 5G/Pro (China)/Pro+ 5G",
    release_date: null,
  },
  {
    id: 2476,
    name: "Redmi Note 10 Lite",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Redmi Note 10 Lite",
    release_date: null,
  },
  {
    id: 2475,
    name: "Redmi 9A Sport/9i Sport",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Redmi 9A Sport/9i Sport",
    release_date: null,
  },
  {
    id: 2474,
    name: "Redmi 9 Activ",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Redmi 9 Activ",
    release_date: null,
  },
  {
    id: 2473,
    name: "Redmi 10 Prime",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Redmi 10 Prime",
    release_date: null,
  },
  {
    id: 2472,
    name: "Redmi 10",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Redmi 10",
    release_date: null,
  },
  {
    id: 2471,
    name: "Redmi Note 10 JE",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Redmi Note 10 JE",
    release_date: null,
  },
  {
    id: 2470,
    name: "Redmi Note 10T",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Redmi Note 10T",
    release_date: null,
  },
  {
    id: 2469,
    name: "Redmi Note 10 Pro 5G",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Redmi Note 10 Pro 5G",
    release_date: null,
  },
  {
    id: 2468,
    name: "Redmi Note 8 2021",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Redmi Note 8 2021",
    release_date: null,
  },
  {
    id: 2467,
    name: "Redmi K40 Gaming",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Redmi K40 Gaming",
    release_date: null,
  },
  {
    id: 2466,
    name: "Redmi Note 10S/5G",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Redmi Note 10S/5G",
    release_date: null,
  },
  {
    id: 2465,
    name: "Redmi Note 10/Pro/Pro Max",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Redmi Note 10/Pro/Pro Max",
    release_date: null,
  },
  {
    id: 2464,
    name: "Redmi K40/Pro/Pro+",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Redmi K40/Pro/Pro+",
    release_date: null,
  },
  {
    id: 2463,
    name: "Redmi 9T/Note 9T",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Redmi 9T/Note 9T",
    release_date: null,
  },
  {
    id: 2462,
    name: "Redmi 9 Power",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Redmi 9 Power",
    release_date: null,
  },
  {
    id: 2461,
    name: "Redmi Note 9 4G/5G/Pro 5G",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Redmi Note 9 4G/5G/Pro 5G",
    release_date: null,
  },
  {
    id: 2460,
    name: "Redmi K30S Ultra",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Redmi K30S Ultra",
    release_date: null,
  },
  {
    id: 2459,
    name: "Redmi K30 Ultra",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Redmi K30 Ultra",
    release_date: null,
  },
  {
    id: 2458,
    name: "Redmi 9 Prime",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Redmi 9 Prime",
    release_date: null,
  },
  {
    id: 2457,
    name: "Redmi 9C",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Redmi 9C",
    release_date: null,
  },
  {
    id: 2456,
    name: "Redmi 9A",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Redmi 9A",
    release_date: null,
  },
  {
    id: 2455,
    name: "Redmi 9",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Redmi 9",
    release_date: null,
  },
  {
    id: 2454,
    name: "Redmi 10X/Pro",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Redmi 10X/Pro",
    release_date: null,
  },
  {
    id: 2453,
    name: "Redmi Note 9/Pro (Global)",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Redmi Note 9/Pro (Global)",
    release_date: null,
  },
  {
    id: 2452,
    name: "Redmi K30 Pro/Pro Zoom",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Redmi K30 Pro/Pro Zoom",
    release_date: null,
  },
  {
    id: 2451,
    name: "Redmi Note 9 Pro (India)/Pro Max/9S",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Redmi Note 9 Pro (India)/Pro Max/9S",
    release_date: null,
  },
  {
    id: 2450,
    name: "Redmi 8A Dual",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Redmi 8A Dual",
    release_date: null,
  },
  {
    id: 2449,
    name: "Redmi K30/5G",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Redmi K30/5G",
    release_date: null,
  },
  {
    id: 2448,
    name: "Redmi Note 8T",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Redmi Note 8T",
    release_date: null,
  },
  {
    id: 2447,
    name: "Redmi 8",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Redmi 8",
    release_date: null,
  },
  {
    id: 2446,
    name: "Redmi 8A",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Redmi 8A",
    release_date: null,
  },
  {
    id: 2445,
    name: "Redmi Note 8/Pro",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Redmi Note 8/Pro",
    release_date: null,
  },
  {
    id: 2444,
    name: "Redmi K20/Pro",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Redmi K20/Pro",
    release_date: null,
  },
  {
    id: 2443,
    name: "Redmi 7A",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Redmi 7A",
    release_date: null,
  },
  {
    id: 2442,
    name: "Redmi Y3",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Redmi Y3",
    release_date: null,
  },
  {
    id: 2441,
    name: "Redmi 7",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Redmi 7",
    release_date: null,
  },
  {
    id: 2440,
    name: "Redmi Note 7 Pro",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Redmi Note 7 Pro",
    release_date: null,
  },
  {
    id: 2439,
    name: "Redmi Go",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Redmi Go",
    release_date: null,
  },
  {
    id: 2438,
    name: "Redmi Note 7",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Redmi Note 7",
    release_date: null,
  },
  {
    id: 2437,
    name: "Redmi Note 6 Pro",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Redmi Note 6 Pro",
    release_date: null,
  },
  {
    id: 2436,
    name: "Redmi 6/6A/6 Pro",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Redmi 6/6A/6 Pro",
    release_date: null,
  },
  {
    id: 2435,
    name: "Redmi S2/Y2",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Redmi S2/Y2",
    release_date: null,
  },
  {
    id: 2434,
    name: "Redmi Note 5",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Redmi Note 5",
    release_date: null,
  },
  {
    id: 2433,
    name: "Redmi Note 5 Pro",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Redmi Note 5 Pro",
    release_date: null,
  },
  {
    id: 2432,
    name: "Redmi 5/5 Plus",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Redmi 5/5 Plus",
    release_date: null,
  },
  {
    id: 2431,
    name: "Redmi 5A",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Redmi 5A",
    release_date: null,
  },
  {
    id: 2430,
    name: "Redmi Note 5A/Prime",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Redmi Note 5A/Prime",
    release_date: null,
  },
  {
    id: 2429,
    name: "Redmi 4X",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Redmi 4X",
    release_date: null,
  },
  {
    id: 2428,
    name: "Redmi Note 4/4X",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Redmi Note 4/4X",
    release_date: null,
  },
  {
    id: 2427,
    name: "Redmi 4A",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Redmi 4A",
    release_date: null,
  },
  {
    id: 2426,
    name: "Redmi 4/Pro/Prime",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Redmi 4/Pro/Prime",
    release_date: null,
  },
  {
    id: 2425,
    name: "Redmi Note 4 (MTK)",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Redmi Note 4 (MTK)",
    release_date: null,
  },
  {
    id: 2424,
    name: "Redmi Pro",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Redmi Pro",
    release_date: null,
  },
  {
    id: 2423,
    name: "Redmi 3S Prime",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Redmi 3S Prime",
    release_date: null,
  },
  {
    id: 2422,
    name: "Redmi 3X",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Redmi 3X",
    release_date: null,
  },
  {
    id: 2421,
    name: "Redmi 3S",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Redmi 3S",
    release_date: null,
  },
  {
    id: 2420,
    name: "Redmi 3 Pro",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Redmi 3 Pro",
    release_date: null,
  },
  {
    id: 2419,
    name: "Redmi Note 3/Pro",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Redmi Note 3/Pro",
    release_date: null,
  },
  {
    id: 2418,
    name: "Redmi 3",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Redmi 3",
    release_date: null,
  },
  {
    id: 2417,
    name: "Redmi Note 3 (MTK)",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Redmi Note 3 (MTK)",
    release_date: null,
  },
  {
    id: 2416,
    name: "Redmi 2 Pro",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Redmi 2 Pro",
    release_date: null,
  },
  {
    id: 2415,
    name: "Redmi Note 2",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Redmi Note 2",
    release_date: null,
  },
  {
    id: 2414,
    name: "Redmi 2 Prime",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Redmi 2 Prime",
    release_date: null,
  },
  {
    id: 2413,
    name: "Redmi 2A",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Redmi 2A",
    release_date: null,
  },
  {
    id: 2412,
    name: "Redmi 2",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Redmi 2",
    release_date: null,
  },
  {
    id: 2411,
    name: "Redmi Note Prime",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Redmi Note Prime",
    release_date: null,
  },
  {
    id: 2410,
    name: "Redmi Note 4G",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Redmi Note 4G",
    release_date: null,
  },
  {
    id: 2409,
    name: "Redmi Note (3G)",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Redmi Note (3G)",
    release_date: null,
  },
  {
    id: 2408,
    name: "Redmi 1S",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Redmi 1S",
    release_date: null,
  },
  {
    id: 2407,
    name: "Redmi 1",
    brand: "Xiaomi",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Redmi 1",
    release_date: null,
  },
  {
    id: 2831,
    name: "YotaPhone 2",
    brand: "Yota",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "YotaPhone 2",
    release_date: null,
  },
  {
    id: 2830,
    name: "YotaPhone",
    brand: "Yota",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "YotaPhone",
    release_date: null,
  },
  {
    id: 2832,
    name: "YU Yureka",
    brand: "YU Televentures",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "YU Yureka",
    release_date: null,
  },
  {
    id: 2930,
    name: "ZTE Voyage 20 Pro",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "ZTE Voyage 20 Pro",
    release_date: null,
  },
  {
    id: 2929,
    name: "ZTE Blade A31 Plus",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "ZTE Blade A31 Plus",
    release_date: null,
  },
  {
    id: 2928,
    name: "ZTE Blade L9",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "ZTE Blade L9",
    release_date: null,
  },
  {
    id: 2927,
    name: "ZTE Blade A71",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "ZTE Blade A71",
    release_date: null,
  },
  {
    id: 2926,
    name: "ZTE Axon 30",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "ZTE Axon 30",
    release_date: null,
  },
  {
    id: 2925,
    name: "ZTE Blade A31",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "ZTE Blade A31",
    release_date: null,
  },
  {
    id: 2924,
    name: "ZTE Blade A51",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "ZTE Blade A51",
    release_date: null,
  },
  {
    id: 2923,
    name: "ZTE Blade 11 Prime",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "ZTE Blade 11 Prime",
    release_date: null,
  },
  {
    id: 2922,
    name: "ZTE Axon 30 Pro/Ultra",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "ZTE Axon 30 Pro/Ultra",
    release_date: null,
  },
  {
    id: 2921,
    name: "ZTE S30/Pro/SE",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "ZTE S30/Pro/SE",
    release_date: null,
  },
  {
    id: 2920,
    name: "ZTE Axon 20 4G",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "ZTE Axon 20 4G",
    release_date: null,
  },
  {
    id: 2919,
    name: "ZTE Blade 20 5G",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "ZTE Blade 20 5G",
    release_date: null,
  },
  {
    id: 2918,
    name: "ZTE Blade A7s 2020",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "ZTE Blade A7s 2020",
    release_date: null,
  },
  {
    id: 2917,
    name: "ZTE Axon 20 5G",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "ZTE Axon 20 5G",
    release_date: null,
  },
  {
    id: 2916,
    name: "ZTE Axon 11 SE 5G",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "ZTE Axon 11 SE 5G",
    release_date: null,
  },
  {
    id: 2915,
    name: "ZTE Blade V 2020",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "ZTE Blade V 2020",
    release_date: null,
  },
  {
    id: 2914,
    name: "ZTE Axon 11 4G/5G",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "ZTE Axon 11 4G/5G",
    release_date: null,
  },
  {
    id: 2913,
    name: "ZTE Axon 10s Pro 5G",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "ZTE Axon 10s Pro 5G",
    release_date: null,
  },
  {
    id: 2912,
    name: "ZTE Blade Max View",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "ZTE Blade Max View",
    release_date: null,
  },
  {
    id: 2911,
    name: "ZTE Blade A7 Prime",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "ZTE Blade A7 Prime",
    release_date: null,
  },
  {
    id: 2910,
    name: "ZTE Blade 10 Prime",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "ZTE Blade 10 Prime",
    release_date: null,
  },
  {
    id: 2909,
    name: "ZTE Blade A20",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "ZTE Blade A20",
    release_date: null,
  },
  {
    id: 2908,
    name: "ZTE Blade A5 (2019)",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "ZTE Blade A5 (2019)",
    release_date: null,
  },
  {
    id: 2907,
    name: "ZTE Blade A7",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "ZTE Blade A7",
    release_date: null,
  },
  {
    id: 2906,
    name: "ZTE Blade A3 (2019)",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "ZTE Blade A3 (2019)",
    release_date: null,
  },
  {
    id: 2905,
    name: "ZTE Blade L8",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "ZTE Blade L8",
    release_date: null,
  },
  {
    id: 2904,
    name: "ZTE Axon 10 Pro/5G",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "ZTE Axon 10 Pro/5G",
    release_date: null,
  },
  {
    id: 2903,
    name: "ZTE Blade V10/Vita",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "ZTE Blade V10/Vita",
    release_date: null,
  },
  {
    id: 2902,
    name: "ZTE Blade A7 Vita",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "ZTE Blade A7 Vita",
    release_date: null,
  },
  {
    id: 2901,
    name: "ZTE Axon 9 Pro",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "ZTE Axon 9 Pro",
    release_date: null,
  },
  {
    id: 2900,
    name: "ZTE Blade V9/Vita",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "ZTE Blade V9/Vita",
    release_date: null,
  },
  {
    id: 2899,
    name: "ZTE Tempo Go",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "ZTE Tempo Go",
    release_date: null,
  },
  {
    id: 2898,
    name: "ZTE Blade A3",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "ZTE Blade A3",
    release_date: null,
  },
  {
    id: 2897,
    name: "ZTE Blade X",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "ZTE Blade X",
    release_date: null,
  },
  {
    id: 2896,
    name: "ZTE Axon M",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "ZTE Axon M",
    release_date: null,
  },
  {
    id: 2895,
    name: "ZTE Blade A6",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "ZTE Blade A6",
    release_date: null,
  },
  {
    id: 2894,
    name: "ZTE Maven 2",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "ZTE Maven 2",
    release_date: null,
  },
  {
    id: 2893,
    name: "ZTE Axon 7s",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "ZTE Axon 7s",
    release_date: null,
  },
  {
    id: 2892,
    name: "ZTE Axon 7 Max",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "ZTE Axon 7 Max",
    release_date: null,
  },
  {
    id: 2891,
    name: "ZTE Axon 7 mini",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "ZTE Axon 7 mini",
    release_date: null,
  },
  {
    id: 2890,
    name: "ZTE Axon 7",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "ZTE Axon 7",
    release_date: null,
  },
  {
    id: 2889,
    name: "ZTE Blade V7/Lite",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "ZTE Blade V7/Lite",
    release_date: null,
  },
  {
    id: 2888,
    name: "ZTE Axon Max",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "ZTE Axon Max",
    release_date: null,
  },
  {
    id: 2887,
    name: "ZTE Blade X3/5/9",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "ZTE Blade X3/5/9",
    release_date: null,
  },
  {
    id: 2886,
    name: "ZTE Axon",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "ZTE Axon",
    release_date: null,
  },
  {
    id: 2885,
    name: "ZTE Blade S7",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "ZTE Blade S7",
    release_date: null,
  },
  {
    id: 2884,
    name: "ZTE Axon mini",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "ZTE Axon mini",
    release_date: null,
  },
  {
    id: 2883,
    name: "ZTE Axon Elite",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "ZTE Axon Elite",
    release_date: null,
  },
  {
    id: 2882,
    name: "ZTE Blade D6",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "ZTE Blade D6",
    release_date: null,
  },
  {
    id: 2881,
    name: "ZTE Axon Pro/Lux",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "ZTE Axon Pro/Lux",
    release_date: null,
  },
  {
    id: 2880,
    name: "ZTE Blade A460",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "ZTE Blade A460",
    release_date: null,
  },
  {
    id: 2879,
    name: "ZTE Blade A410",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "ZTE Blade A410",
    release_date: null,
  },
  {
    id: 2878,
    name: "ZTE Blade Apex 3",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "ZTE Blade Apex 3",
    release_date: null,
  },
  {
    id: 2877,
    name: "ZTE Blade Q Pro",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "ZTE Blade Q Pro",
    release_date: null,
  },
  {
    id: 2876,
    name: "ZTE Blade Qlux 4G",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "ZTE Blade Qlux 4G",
    release_date: null,
  },
  {
    id: 2875,
    name: "ZTE Blade S6 Plus",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "ZTE Blade S6 Plus",
    release_date: null,
  },
  {
    id: 2874,
    name: "ZTE Blade L3 Plus",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "ZTE Blade L3 Plus",
    release_date: null,
  },
  {
    id: 2873,
    name: "ZTE Blade L3",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "ZTE Blade L3",
    release_date: null,
  },
  {
    id: 2872,
    name: "ZTE Blade G/Lux",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "ZTE Blade G/Lux",
    release_date: null,
  },
  {
    id: 2871,
    name: "ZTE Blade S6",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "ZTE Blade S6",
    release_date: null,
  },
  {
    id: 2870,
    name: "ZTE Blade Vec 3G/4G",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "ZTE Blade Vec 3G/4G",
    release_date: null,
  },
  {
    id: 2869,
    name: "ZTE Blade L2",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "ZTE Blade L2",
    release_date: null,
  },
  {
    id: 2868,
    name: "ZTE Blade Q/Maxi/Mini",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "ZTE Blade Q/Maxi/Mini",
    release_date: null,
  },
  {
    id: 2867,
    name: "ZTE Blade V",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "ZTE Blade V",
    release_date: null,
  },
  {
    id: 2866,
    name: "ZTE Blade G2",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "ZTE Blade G2",
    release_date: null,
  },
  {
    id: 2865,
    name: "ZTE Blade G V880G",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "ZTE Blade G V880G",
    release_date: null,
  },
  {
    id: 2864,
    name: "ZTE Blade III Pro",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "ZTE Blade III Pro",
    release_date: null,
  },
  {
    id: 2863,
    name: "ZTE Blade C V807",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "ZTE Blade C V807",
    release_date: null,
  },
  {
    id: 2862,
    name: "ZTE Director",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "ZTE Director",
    release_date: null,
  },
  {
    id: 2861,
    name: "ZTE Grand S",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "ZTE Grand S",
    release_date: null,
  },
  {
    id: 2860,
    name: "ZTE Avid 4G",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "ZTE Avid 4G",
    release_date: null,
  },
  {
    id: 2859,
    name: "ZTE V889M",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "ZTE V889M",
    release_date: null,
  },
  {
    id: 2858,
    name: "ZTE V887",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "ZTE V887",
    release_date: null,
  },
  {
    id: 2857,
    name: "ZTE Era",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "ZTE Era",
    release_date: null,
  },
  {
    id: 2856,
    name: "ZTE N910",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "ZTE N910",
    release_date: null,
  },
  {
    id: 2855,
    name: "ZTE Kis III V790",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "ZTE Kis III V790",
    release_date: null,
  },
  {
    id: 2854,
    name: "ZTE Groove X501",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "ZTE Groove X501",
    release_date: null,
  },
  {
    id: 2853,
    name: "ZTE Flash",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "ZTE Flash",
    release_date: null,
  },
  {
    id: 2852,
    name: "ZTE Grand X LTE",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "ZTE Grand X LTE",
    release_date: null,
  },
  {
    id: 2851,
    name: "ZTE Warp Sequent",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "ZTE Warp Sequent",
    release_date: null,
  },
  {
    id: 2850,
    name: "ZTE Grand Era",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "ZTE Grand Era",
    release_date: null,
  },
  {
    id: 2849,
    name: "ZTE Blade III",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "ZTE Blade III",
    release_date: null,
  },
  {
    id: 2848,
    name: "ZTE Grand X IN",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "ZTE Grand X IN",
    release_date: null,
  },
  {
    id: 2847,
    name: "ZTE Grand X",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "ZTE Grand X",
    release_date: null,
  },
  {
    id: 2846,
    name: "ZTE Kis V788",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "ZTE Kis V788",
    release_date: null,
  },
  {
    id: 2845,
    name: "ZTE V880E",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "ZTE V880E",
    release_date: null,
  },
  {
    id: 2844,
    name: "ZTE Score M",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "ZTE Score M",
    release_date: null,
  },
  {
    id: 2843,
    name: "ZTE Blade II V880+",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "ZTE Blade II V880+",
    release_date: null,
  },
  {
    id: 2842,
    name: "ZTE FTV Phone",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "ZTE FTV Phone",
    release_date: null,
  },
  {
    id: 2841,
    name: "ZTE Avail",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "ZTE Avail",
    release_date: null,
  },
  {
    id: 2840,
    name: "ZTE Warp",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "ZTE Warp",
    release_date: null,
  },
  {
    id: 2839,
    name: "ZTE Score",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "ZTE Score",
    release_date: null,
  },
  {
    id: 2838,
    name: "ZTE Skate",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "ZTE Skate",
    release_date: null,
  },
  {
    id: 2837,
    name: "ZTE Racer II",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "ZTE Racer II",
    release_date: null,
  },
  {
    id: 2836,
    name: "ZTE Libra",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "ZTE Libra",
    release_date: null,
  },
  {
    id: 2835,
    name: "ZTE Blade",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "ZTE Blade",
    release_date: null,
  },
  {
    id: 2834,
    name: "ZTE Racer",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "ZTE Racer",
    release_date: null,
  },
  {
    id: 2190,
    name: "Nubia Red Magic 6/Pro",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Nubia Red Magic 6/Pro",
    release_date: null,
  },
  {
    id: 2189,
    name: "Nubia Red Magic 5S",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Nubia Red Magic 5S",
    release_date: null,
  },
  {
    id: 2188,
    name: "Nubia Red Magic 5G Lite",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Nubia Red Magic 5G Lite",
    release_date: null,
  },
  {
    id: 2187,
    name: "Nubia Play",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Nubia Play",
    release_date: null,
  },
  {
    id: 2186,
    name: "Nubia Red Magic 5G",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Nubia Red Magic 5G",
    release_date: null,
  },
  {
    id: 2185,
    name: "Nubia Red Magic 3s",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Nubia Red Magic 3s",
    release_date: null,
  },
  {
    id: 2184,
    name: "Nubia Z20",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Nubia Z20",
    release_date: null,
  },
  {
    id: 2183,
    name: "Nubia Red Magic 3",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Nubia Red Magic 3",
    release_date: null,
  },
  {
    id: 2182,
    name: "Nubia Red Magic Mars",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Nubia Red Magic Mars",
    release_date: null,
  },
  {
    id: 2181,
    name: "Nubia X",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Nubia X",
    release_date: null,
  },
  {
    id: 2180,
    name: "Nubia Z18",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Nubia Z18",
    release_date: null,
  },
  {
    id: 2179,
    name: "Nubia Red Magic",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Nubia Red Magic",
    release_date: null,
  },
  {
    id: 2178,
    name: "Nubia Z18 mini",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Nubia Z18 mini",
    release_date: null,
  },
  {
    id: 2177,
    name: "Nubia V18",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Nubia V18",
    release_date: null,
  },
  {
    id: 2176,
    name: "Nubia N3",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Nubia N3",
    release_date: null,
  },
  {
    id: 2175,
    name: "Nubia Z17s",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Nubia Z17s",
    release_date: null,
  },
  {
    id: 2174,
    name: "Nubia Z17 miniS",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Nubia Z17 miniS",
    release_date: null,
  },
  {
    id: 2173,
    name: "Nubia Z17 lite",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Nubia Z17 lite",
    release_date: null,
  },
  {
    id: 2172,
    name: "Nubia Z17",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Nubia Z17",
    release_date: null,
  },
  {
    id: 2171,
    name: "Nubia Z17 mini",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Nubia Z17 mini",
    release_date: null,
  },
  {
    id: 2170,
    name: "Nubia N1 lite",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Nubia N1 lite",
    release_date: null,
  },
  {
    id: 2169,
    name: "Nubia N2",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Nubia N2",
    release_date: null,
  },
  {
    id: 2168,
    name: "Nubia M2/lite",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Nubia M2/lite",
    release_date: null,
  },
  {
    id: 2167,
    name: "Nubia Z11 mini S",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Nubia Z11 mini S",
    release_date: null,
  },
  {
    id: 2166,
    name: "Nubia N1",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Nubia N1",
    release_date: null,
  },
  {
    id: 2165,
    name: "Nubia Z11",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Nubia Z11",
    release_date: null,
  },
  {
    id: 2164,
    name: "Nubia Z11 Max",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Nubia Z11 Max",
    release_date: null,
  },
  {
    id: 2163,
    name: "Nubia Z11 mini",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Nubia Z11 mini",
    release_date: null,
  },
  {
    id: 2162,
    name: "Nubia Prague S",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Nubia Prague S",
    release_date: null,
  },
  {
    id: 2161,
    name: "Nubia My Prague",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Nubia My Prague",
    release_date: null,
  },
  {
    id: 2160,
    name: "Nubia Z9",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Nubia Z9",
    release_date: null,
  },
  {
    id: 2159,
    name: "Nubia Z9 mini",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Nubia Z9 mini",
    release_date: null,
  },
  {
    id: 2158,
    name: "Nubia Z9 Max",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Nubia Z9 Max",
    release_date: null,
  },
  {
    id: 2157,
    name: "Nubia Z5S mini NX405H",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Nubia Z5S mini NX405H",
    release_date: null,
  },
  {
    id: 2156,
    name: "Nubia Z7/Max/mini",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Nubia Z7/Max/mini",
    release_date: null,
  },
  {
    id: 2155,
    name: "Nubia X6",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Nubia X6",
    release_date: null,
  },
  {
    id: 2154,
    name: "Nubia Z5S mini NX403A",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Nubia Z5S mini NX403A",
    release_date: null,
  },
  {
    id: 2153,
    name: "Nubia Z5S",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Nubia Z5S",
    release_date: null,
  },
  {
    id: 2152,
    name: "Nubia Z5",
    brand: "ZTE",
    so: "ANDROID",
    device_type: null,
    image: null,
    alias: "Nubia Z5",
    release_date: null,
  },
];
