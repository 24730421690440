<template>
  <v-footer dark padless>
    <v-card flat tile class="secondary white--text text-center">
      <v-card-text>
        <v-btn
          v-for="(icon, i) in icons"
          :key="i"
          class="mx-4 white--text"
          :href="icon.link"
          target="_blank"
          icon
        >
          <v-icon size="24px">{{ icon.text }}</v-icon>
        </v-btn>
      </v-card-text>

      <v-card-text class="white--text pt-0">
        <strong> Segurify Portal CRM </strong> - {{ new Date().getFullYear() }}
      </v-card-text>

      <v-divider></v-divider>

      <v-card-text class="white--text">
        <p>
          Segurify 2022
          <a 
            target="_blank" 
            href="/terminos-y-condiciones"
            style="color: white"
          >
            Terminos y condiciones
          </a>
          y <a target="_blank" href="/aviso-de-privacidad" style="color: white">Aviso de privacidad</a>
        </p>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<style scoped>
.v-card {
  width: 100%;
}
</style>

<script>
export default {
  data: () => ({
    icons: [
      {
        text: "mdi-facebook",
        link: "",
      },
      {
        text: "mdi-twitter",
        link: "",
      },
      {
        text: "mdi-linkedin",
        link: "",
      },
      {
        text: "mdi-instagram",
        link: "",
      },
    ],
  }),
};
</script>
