<template>
  <v-dialog v-model="dialogSolicitud" persistent max-width="1200px">
    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="6">
            <span class="text-h5">Crear solicitud</span>
          </v-col>
          <v-col cols="6" style="text-align: end;">
            <v-btn icon dark @click="closeModal" style="color:black  ">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <span> </span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-form>
                <v-row>
                  <v-col>
                    <v-stepper v-model="stepper" non-linear>
                      <v-stepper-header>
                        <v-stepper-step step="1" editable class="stepper">
                          Datos de la Solicitud
                        </v-stepper-step>

                        <v-stepper-step step="2" editable>
                          Seguimiento de la Solicitud
                        </v-stepper-step>

                        <v-stepper-step step="3" editable>
                          SLA de la Solicitud
                        </v-stepper-step>
                      </v-stepper-header>

                      <v-stepper-items>
                        <!-- 1ST ITEM (FORM) -->
                        <v-stepper-content step="1">
                          <v-row style="padding: 20px">
                            <v-col cols="4">
                              <v-select
                                label="Origen de la solicitud"
                                :items="origenSolicitud"
                                v-model="form.origenSolicitudSelected"
                              ></v-select>
                            </v-col>
                            <v-col cols="4">
                              <v-select
                                label="Status Solicitud"
                                :items="estado"
                                v-model="estadoSelected"
                                readonly
                              ></v-select>
                            </v-col>
                            <v-col cols="4">
                              <v-autocomplete
                                label="Agente"
                                :items="agentes"
                                item-text="nombre"
                                item-value="id"
                                v-model="form.agente"
                                :readonly="isAgent == true"
                                placeholder="Requerido"
                                :rules="rules.inputsRequeridos"
                                :disabled="canEditAgent"
                                @change="esDirecto()"
                              ></v-autocomplete>
                            </v-col>
                            <v-col cols="4" v-if="esAgentDirecto">
                              <v-autocomplete
                                label="Comercial Asignado"
                                :items="comercialAsignadoList"
                                item-text="nombre"
                                item-value="id"
                                v-model="comercialAsignado"
                                :readonly="isAgent == true"
                                placeholder="Requerido"
                                :rules="rules.inputsRequeridos"
                              ></v-autocomplete>
                            </v-col>
                            <!-- End 2nd Col -->

                            <v-col cols="4">
                              <v-select
                                label="Tipo"
                                :items="tipo"
                                v-model="form.tipoSelected"
                                placeholder="Requerido"
                                :rules="rules.inputsRequeridos"
                              ></v-select>
                            </v-col>
                            <v-col cols="4">
                              <v-select
                                label="Ramo"
                                :items="categoria"
                                v-model="form.categoriaSelected"
                                placeholder="Requerido"
                                :rules="rules.inputsRequeridos"
                              ></v-select>
                            </v-col>
                            <v-col cols="4" v-if="!isAgent">
                              <v-autocomplete
                                label="Productos"
                                :items="productos"
                                v-model="form.productoSelected"
                                item-text="nombre"
                                item-value="id"
                                placeholder="Requerido"
                                :rules="rules.inputsRequeridos"
                              ></v-autocomplete>
                            </v-col>
                            <v-col cols="12">
                              <v-textarea
                                label="Detalle Solicitud"
                                v-model="form.detalle"
                                rows="1"
                                auto-grow
                                :rules="rules.inputsRequeridos"
                              ></v-textarea>
                            </v-col>
                            <v-col cols="6">
                              <v-text-field
                                v-model="form.campania"
                                :rules="rules.inputsRequeridos"
                                label="Campaña"
                                readonly
                              ></v-text-field>
                            </v-col>
                            <v-col cols="6">
                              <v-text-field
                                v-model="form.idOrigenLead"
                                label="ID Origen Lead"
                                :rules="rules.inputsRequeridos"
                                readonly
                              ></v-text-field>
                            </v-col>
                            <v-col cols="4">
                              <v-select
                                :items="regimenList"
                                v-model="form.regimenSelected"
                                label="Tipo de Persona"
                                placeholder="Requerido"
                                :rules="rules.inputsRequeridos"
                              ></v-select>
                            </v-col>
                            <v-col
                              cols="4"
                              v-if="form.regimenSelected == 'Física'"
                            >
                              <v-text-field
                                label="Nombre(s)"
                                v-model="form.nombres"
                                placeholder="Requerido"
                                :rules="rules.inputsRequeridos"
                              ></v-text-field>
                            </v-col>
                            <v-col
                              cols="4"
                              v-if="form.regimenSelected == 'Física'"
                            >
                              <v-text-field
                                label="Apellido Paterno"
                                v-model="form.apellidoPaterno"
                                placeholder="Requerido"
                                :rules="rules.inputsRequeridos"
                              ></v-text-field>
                            </v-col>
                            <v-col
                              cols="4"
                              v-if="form.regimenSelected == 'Física'"
                            >
                              <v-text-field
                                label="Apellido Materno"
                                v-model="form.apellidoMaterno"
                              ></v-text-field>
                            </v-col>
                            <v-col
                              cols="4"
                              v-if="
                                (form.tipoSelected == 'Cotización' ||
                                  form.tipoSelected == 'Emisión' ||
                                  form.tipoSelected == 'Renovación') &&
                                  form.regimenSelected == 'Física'
                              "
                            >
                              <v-select
                                :items="estadoCivil"
                                v-model="form.estadoCivilSelected"
                                label="Estado Civil"
                                placeholder="Requerido"
                                :rules="rules.inputsRequeridos"
                              ></v-select>
                            </v-col>
                            <v-col
                              cols="4"
                              v-if="
                                (form.tipoSelected == 'Cotización' ||
                                  form.tipoSelected == 'Emisión' ||
                                  form.tipoSelected == 'Renovación') &&
                                  form.regimenSelected == 'Física'
                              "
                            >
                              <v-select
                                :items="genero"
                                v-model="form.selectedGenero"
                                label="Género"
                                placeholder="Requerido"
                                :rules="rules.inputsRequeridos"
                              ></v-select>
                            </v-col>
                            <v-col
                              cols="4"
                              v-if="
                                (form.tipoSelected == 'Cotización' ||
                                  form.tipoSelected == 'Emisión' ||
                                  form.tipoSelected == 'Renovación') &&
                                  form.regimenSelected == 'Física'
                              "
                            >
                              <v-text-field
                                :value="computedDateFormattedMomentjs"
                                clearable
                                v-model="form.fechaNacimiento"
                                label="Fecha de Nacimiento"
                                prepend-icon="mdi-calendar"
                                v-mask="'##/##/####'"
                                placeholder="Requerido(DD/MM/AAAA)"
                                :rules="rules.inputsRequeridos"
                                @click:clear="form.fechaNacimiento = null"
                              ></v-text-field>
                            </v-col>
                            <v-col
                              cols="4"
                              v-if="form.regimenSelected == 'Moral'"
                            >
                              <v-text-field
                                label="Razón Social"
                                v-model="form.razonSocial"
                                :rules="rules.inputsRequeridos"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="4">
                              <v-text-field
                                placeholder="Requerido"
                                v-model="form.rfc"
                                label="RFC"
                                :rules="rules.rfcRules"
                              ></v-text-field>
                            </v-col>
                            <v-col
                              cols="4"
                              v-if="
                                (form.tipoSelected == 'Cotización' ||
                                  form.tipoSelected == 'Emisión' ||
                                  form.tipoSelected == 'Renovación') &&
                                  form.regimenSelected == 'Física'
                              "
                            >
                              <v-text-field
                                v-model="form.edad"
                                label="Edad"
                                readonly
                                :rules="rules.inputsRequeridos"
                              ></v-text-field>
                            </v-col>
                            <v-col
                              cols="4"
                              v-if="
                                form.tipoSelected == 'Cotización' ||
                                  form.tipoSelected == 'Emisión' ||
                                  form.tipoSelected == 'Renovación'
                              "
                            >
                              <v-text-field
                                placeholder="(Requerido)##########"
                                v-mask="'##########'"
                                v-model="form.celular"
                                label="Teléfono"
                                :rules="rules.inputsRequeridos"
                              ></v-text-field>
                            </v-col>
                            <v-col
                              cols="4"
                              v-if="
                                form.tipoSelected == 'Cotización' ||
                                  form.tipoSelected == 'Emisión' ||
                                  form.tipoSelected == 'Renovación'
                              "
                            >
                              <v-autocomplete
                                v-model="form.correoElectronico"
                                label="Correo Electrónico"
                                :items="prediccionesCorreo"
                                :filter="filtroCorreo"
                                :rules="[
                                  ...rules.emailRules,
                                  validarDominioComun,
                                ]"
                                :append-icon="null"
                              ></v-autocomplete>
                            </v-col>
                            <v-col
                              cols="4"
                              v-if="
                                form.tipoSelected == 'Cotización' ||
                                  form.tipoSelected == 'Emisión' ||
                                  form.tipoSelected == 'Renovación'
                              "
                            >
                              <v-text-field
                                v-mask="'#####'"
                                placeholder="(Requerido)#####"
                                label="Código Postal"
                                v-model="form.codigoPostal"
                                :rules="rules.inputsRequeridos"
                              ></v-text-field>
                            </v-col>
                            <v-col
                              cols="4"
                              v-if="
                                form.tipoSelected == 'Cotización' ||
                                  form.tipoSelected == 'Emisión' ||
                                  form.tipoSelected == 'Renovación'
                              "
                            >
                              <v-text-field
                                v-model="form.calle"
                                placeholder="Requerido"
                                label="Calle"
                                :rules="rules.inputsRequeridos"
                              ></v-text-field>
                            </v-col>
                            <v-col
                              cols="4"
                              v-if="
                                form.tipoSelected == 'Cotización' ||
                                  form.tipoSelected == 'Emisión' ||
                                  form.tipoSelected == 'Renovación'
                              "
                            >
                              <v-text-field
                                v-model="form.num_exterior"
                                placeholder="Requerido"
                                label="Número exterior"
                                :rules="rules.inputsRequeridos"
                              ></v-text-field>
                            </v-col>
                            <v-col
                              cols="4"
                              v-if="
                                form.tipoSelected == 'Cotización' ||
                                  form.tipoSelected == 'Emisión' ||
                                  form.tipoSelected == 'Renovación'
                              "
                            >
                              <v-text-field
                                v-model="form.num_interior"
                                placeholder="Requerido"
                                label="Número interior"
                                :rules="rules.inputsRequeridos"
                              ></v-text-field>
                            </v-col>
                            <v-col
                              cols="4"
                              v-if="
                                form.tipoSelected == 'Cotización' ||
                                  form.tipoSelected == 'Emisión' ||
                                  form.tipoSelected == 'Renovación'
                              "
                            >
                              <v-select
                                label="Colonia"
                                :items="colonias"
                                v-model="form.colonia"
                                :rules="rules.inputsRequeridos"
                              ></v-select>
                            </v-col>
                            <v-col
                              cols="4"
                              v-if="
                                form.tipoSelected == 'Cotización' ||
                                  form.tipoSelected == 'Emisión' ||
                                  form.tipoSelected == 'Renovación'
                              "
                            >
                              <v-text-field
                                v-model="form.municipio"
                                placeholder="Requerido"
                                label="Municipio / Ciudad"
                                readonly
                                :rules="rules.inputsRequeridos"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="4">
                              <v-select
                                placeholder="Requerido"
                                :items="estados"
                                item-text="estadoRepublica"
                                item-value="id"
                                v-model="form.estadoRepublicaSelected"
                                label="Estado de la República"
                                :rules="rules.inputsRequeridos"
                                :readonly="
                                  (form.tipoSelected == 'Cotización' ||
                                    form.tipoSelected == 'Emisión' ||
                                    form.tipoSelected == 'Renovación') &&
                                    form.regimenSelected == 'Física'
                                "
                              ></v-select>
                            </v-col>
                            <v-col
                              cols="4"
                              v-if="
                                form.tipoSelected == 'Cotización' ||
                                  form.tipoSelected == 'Emisión' ||
                                  form.tipoSelected == 'Renovación'
                              "
                            >
                              <v-text-field
                                v-model="form.anios"
                                label="Años"
                                :rules="[
                                  ...rules.inputsRequeridos,
                                  ...rules.numeric,
                                ]"
                              ></v-text-field>
                            </v-col>
                            <v-col
                              cols="4"
                              v-if="
                                form.tipoSelected == 'Cotización' ||
                                  form.tipoSelected == 'Emisión' ||
                                  form.tipoSelected == 'Renovación'
                              "
                            >
                              <v-text-field
                                :value="
                                  computedDateInicioVigenciaFormattedMomentjs
                                "
                                clearable
                                v-model="form.inicioVigenciaFormatted"
                                label="Inicio de Vigencia"
                                placeholder="Requerido (dd/mm/yyyy)"
                                v-mask="'##/##/####'"
                                prepend-icon="mdi-calendar"
                                :rules="rules.inputsRequeridos"
                                @click:clear="
                                  form.inicioVigenciaFormatted = null
                                "
                              ></v-text-field>
                            </v-col>
                            <v-col
                              cols="4"
                              v-if="
                                form.tipoSelected == 'Cotización' ||
                                  form.tipoSelected == 'Emisión' ||
                                  form.tipoSelected == 'Renovación'
                              "
                            >
                              <v-text-field
                                :value="
                                  computedDateFinVigenciaFormattedMomentjs
                                "
                                v-model="form.finVigenciaFormatted"
                                label="Fin de Vigencia"
                                placeholder="Requerido (dd/mm/yyyy)"
                                v-mask="'##/##/####'"
                                prepend-icon="mdi-calendar"
                                :rules="rules.inputsRequeridos"
                                readonly
                                hint="Calculado automáticamente"
                              ></v-text-field>
                            </v-col>
                            <v-col
                              cols="4"
                              v-if="
                                form.tipoSelected == 'Cotización' ||
                                  form.tipoSelected == 'Emisión' ||
                                  form.tipoSelected == 'Renovación'
                              "
                            >
                              <v-select
                                :items="periodicidadItems"
                                v-model="form.periodicidadSelected"
                                label="Periodicidad"
                                :rules="rules.inputsRequeridos"
                              ></v-select>
                            </v-col>
                            <v-col
                              cols="4"
                              v-if="
                                form.tipoSelected == 'Cotización' ||
                                  form.tipoSelected == 'Emisión' ||
                                  form.tipoSelected == 'Renovación'
                              "
                            >
                              <v-select
                                :items="formaPago"
                                v-model="form.formaPagoSelected"
                                label="Forma de Pago"
                                placeholder="Requerido"
                                :rules="rules.inputsRequeridos"
                              ></v-select>
                            </v-col>
                            <v-col
                              cols="4"
                              v-if="form.tipoSelected == 'Renovación'"
                            >
                              <v-text-field
                                label="No. de Póliza a renovar"
                                v-model="polizaRenovar"
                                :rules="rules.inputsRequeridos"
                              ></v-text-field>
                            </v-col>
                            <v-col
                              cols="4"
                              v-if="form.tipoSelected == 'Renovación'"
                            >
                              <v-text-field
                                label="Fecha Inicio de vigencia a renovar"
                                placeholder="dd/mm/aaaa"
                                v-model="fechaInicioVigenciaRenovar"
                                :rules="rules.inputsRequeridos"
                                v-mask="'##/##/####'"
                                prepend-icon="mdi-calendar"
                              ></v-text-field>
                            </v-col>
                            <v-col
                              cols="4"
                              v-if="form.tipoSelected == 'Renovación'"
                            >
                              <v-text-field
                                label="Fecha Fin de vigencia a renovar"
                                placeholder="dd/mm/aaaa"
                                v-model="fechaFinVigenciaRenovar"
                                :rules="rules.inputsRequeridos"
                                v-mask="'##/##/####'"
                                prepend-icon="mdi-calendar"
                              ></v-text-field>
                            </v-col>

                            <div
                              v-if="
                                form.categoriaSelected == 'AUTOS' &&
                                  (form.tipoSelected == 'Cotización' ||
                                    form.tipoSelected == 'Emisión' ||
                                    form.tipoSelected == 'Renovación')
                              "
                            >
                              <RamoAutosComponent
                                :origenSolicitud="form.origenSolicitudSelected"
                                :statusSolicitud="estadoSelected"
                                :agente="form.agente"
                                :tipo="form.tipoSelected"
                                :ramo="form.categoriaSelected"
                                :producto="form.productoSelected"
                                :detalleSolicitud="form.detalle"
                                :nombres="form.nombres"
                                :apellidoPaterno="form.apellidoPaterno"
                                :apellidoMaterno="form.apellidoMaterno"
                                :estadoCivil="form.estadoCivilSelected"
                                :estadoNacimiento="form.estadoRepublicaSelected"
                                :genero="form.selectedGenero"
                                :fechaNacimiento="form.fechaNacimiento"
                                :rfc="form.rfc"
                                :tipoPersona="form.regimenSelected"
                                :telefono="form.celular"
                                :correo="form.correoElectronico"
                                :codigoPostal="form.codigoPostal"
                                :direccion="form.direccion"
                                :inicioVigencia="form.inicioVigenciaFormatted"
                                :finVigencia="form.finVigenciaFormatted"
                                :periodicidad="form.periodicidadSelected"
                                :formaPago="form.formaPagoSelected"
                                :operador="form.ejecutivo"
                                :solicitudCompleta="
                                  form.solicitudCompletaSelected
                                "
                                :procesoCotizacion="
                                  form.procesoCotizacionSelected
                                "
                                :comercialAsignado="comercialAsignado"
                                :razonSocial="form.razonSocial"
                                :leadOrigen="leadOrigen"
                                :newFormProps="newFormProps"
                                :esDesdeDialog="true"
                                @SolicitudSuccess="SolicitudSuccess"
                              />
                            </div>
                            <div
                              v-else-if="
                                form.categoriaSelected == 'DAÑOS' &&
                                  (form.tipoSelected == 'Cotización' ||
                                    form.tipoSelected == 'Emisión' ||
                                    form.tipoSelected == 'Renovación')
                              "
                            >
                              <RamoDaniosComponent
                                :origenSolicitud="form.origenSolicitudSelected"
                                :statusSolicitud="estadoSelected"
                                :agente="form.agente"
                                :tipo="form.tipoSelected"
                                :ramo="form.categoriaSelected"
                                :producto="form.productoSelected"
                                :detalleSolicitud="form.detalle"
                                :nombres="form.nombres"
                                :apellidoPaterno="form.apellidoPaterno"
                                :apellidoMaterno="form.apellidoMaterno"
                                :estadoCivil="form.estadoCivilSelected"
                                :estadoNacimiento="form.estadoRepublicaSelected"
                                :genero="form.selectedGenero"
                                :fechaNacimiento="form.fechaNacimiento"
                                :rfc="form.rfc"
                                :tipoPersona="form.regimenSelected"
                                :telefono="form.celular"
                                :correo="form.correoElectronico"
                                :codigoPostal="form.codigoPostal"
                                :direccion="form.direccion"
                                :inicioVigencia="form.inicioVigenciaFormatted"
                                :finVigencia="form.finVigenciaFormatted"
                                :periodicidad="form.periodicidadSelected"
                                :formaPago="form.formaPagoSelected"
                                :operador="form.ejecutivo"
                                :solicitudCompleta="
                                  form.solicitudCompletaSelected
                                "
                                :procesoCotizacion="
                                  form.procesoCotizacionSelected
                                "
                                :comercialAsignado="comercialAsignado"
                                :razonSocial="form.razonSocial"
                                :leadOrigen="leadOrigen"
                                :newFormProps="newFormProps"
                                :esDesdeDialog="true"
                                @SolicitudSuccess="SolicitudSuccess"
                              />
                            </div>
                            <div
                              v-else-if="
                                form.categoriaSelected == 'SALUD' &&
                                  (form.tipoSelected == 'Cotización' ||
                                    form.tipoSelected == 'Emisión' ||
                                    form.tipoSelected == 'Renovación')
                              "
                            >
                              <RamoSaludComponent
                                :origenSolicitud="form.origenSolicitudSelected"
                                :statusSolicitud="estadoSelected"
                                :agente="form.agente"
                                :tipo="form.tipoSelected"
                                :ramo="form.categoriaSelected"
                                :producto="form.productoSelected"
                                :detalleSolicitud="form.detalle"
                                :nombres="form.nombres"
                                :apellidoPaterno="form.apellidoPaterno"
                                :apellidoMaterno="form.apellidoMaterno"
                                :estadoCivil="form.estadoCivilSelected"
                                :estadoNacimiento="form.estadoRepublicaSelected"
                                :genero="form.selectedGenero"
                                :fechaNacimiento="form.fechaNacimiento"
                                :rfc="form.rfc"
                                :tipoPersona="form.regimenSelected"
                                :telefono="form.celular"
                                :correo="form.correoElectronico"
                                :codigoPostal="form.codigoPostal"
                                :direccion="form.direccion"
                                :inicioVigencia="form.inicioVigenciaFormatted"
                                :finVigencia="form.finVigenciaFormatted"
                                :periodicidad="form.periodicidadSelected"
                                :formaPago="form.formaPagoSelected"
                                :operador="form.ejecutivo"
                                :solicitudCompleta="
                                  form.solicitudCompletaSelected
                                "
                                :procesoCotizacion="
                                  form.procesoCotizacionSelected
                                "
                                :comercialAsignado="comercialAsignado"
                                :razonSocial="form.razonSocial"
                                :leadOrigen="leadOrigen"
                                :newFormProps="newFormProps"
                                :esDesdeDialog="true"
                                @SolicitudSuccess="SolicitudSuccess"
                              />
                            </div>
                            <div
                              v-else-if="
                                form.categoriaSelected == 'VIDA' &&
                                  (form.tipoSelected == 'Cotización' ||
                                    form.tipoSelected == 'Emisión' ||
                                    form.tipoSelected == 'Renovación')
                              "
                            >
                              <RamoVidaComponent
                                :origenSolicitud="form.origenSolicitudSelected"
                                :statusSolicitud="estadoSelected"
                                :agente="form.agente"
                                :tipo="form.tipoSelected"
                                :ramo="form.categoriaSelected"
                                :producto="form.productoSelected"
                                :detalleSolicitud="form.detalle"
                                :nombres="form.nombres"
                                :apellidoPaterno="form.apellidoPaterno"
                                :apellidoMaterno="form.apellidoMaterno"
                                :estadoCivil="form.estadoCivilSelected"
                                :estadoNacimiento="form.estadoRepublicaSelected"
                                :genero="form.selectedGenero"
                                :fechaNacimiento="form.fechaNacimiento"
                                :rfc="form.rfc"
                                :tipoPersona="form.regimenSelected"
                                :telefono="form.celular"
                                :correo="form.correoElectronico"
                                :codigoPostal="form.codigoPostal"
                                :direccion="form.direccion"
                                :inicioVigencia="form.inicioVigenciaFormatted"
                                :finVigencia="form.finVigenciaFormatted"
                                :periodicidad="form.periodicidadSelected"
                                :formaPago="form.formaPagoSelected"
                                :operador="form.ejecutivo"
                                :solicitudCompleta="
                                  form.solicitudCompletaSelected
                                "
                                :procesoCotizacion="
                                  form.procesoCotizacionSelected
                                "
                                :comercialAsignado="comercialAsignado"
                                :razonSocial="form.razonSocial"
                                :leadOrigen="leadOrigen"
                                :newFormProps="newFormProps"
                                :esDesdeDialog="true"
                                @SolicitudSuccess="SolicitudSuccess"
                              />
                            </div>
                            <div
                              v-else-if="
                                form.categoriaSelected == 'AHORRO' &&
                                  (form.tipoSelected == 'Cotización' ||
                                    form.tipoSelected == 'Emisión' ||
                                    form.tipoSelected == 'Renovación')
                              "
                            >
                              <RamoAhorroComponent
                                :origenSolicitud="form.origenSolicitudSelected"
                                :statusSolicitud="estadoSelected"
                                :agente="form.agente"
                                :tipo="form.tipoSelected"
                                :ramo="form.categoriaSelected"
                                :producto="form.productoSelected"
                                :detalleSolicitud="form.detalle"
                                :nombres="form.nombres"
                                :apellidoPaterno="form.apellidoPaterno"
                                :apellidoMaterno="form.apellidoMaterno"
                                :estadoCivil="form.estadoCivilSelected"
                                :estadoNacimiento="form.estadoRepublicaSelected"
                                :genero="form.selectedGenero"
                                :fechaNacimiento="form.fechaNacimiento"
                                :rfc="form.rfc"
                                :tipoPersona="form.regimenSelected"
                                :telefono="form.celular"
                                :correo="form.correoElectronico"
                                :codigoPostal="form.codigoPostal"
                                :direccion="form.direccion"
                                :inicioVigencia="form.inicioVigenciaFormatted"
                                :finVigencia="form.finVigenciaFormatted"
                                :periodicidad="form.periodicidadSelected"
                                :formaPago="form.formaPagoSelected"
                                :operador="form.ejecutivo"
                                :solicitudCompleta="
                                  form.solicitudCompletaSelected
                                "
                                :procesoCotizacion="
                                  form.procesoCotizacionSelected
                                "
                                :comercialAsignado="comercialAsignado"
                                :razonSocial="form.razonSocial"
                                :leadOrigen="leadOrigen"
                                :newFormProps="newFormProps"
                                :esDesdeDialog="true"
                                @SolicitudSuccess="SolicitudSuccess"
                              />
                            </div>
                            <div
                              v-else-if="
                                form.categoriaSelected == 'FIANZA' &&
                                  (form.tipoSelected == 'Cotización' ||
                                    form.tipoSelected == 'Emisión' ||
                                    form.tipoSelected == 'Renovación')
                              "
                            >
                              <RamoFianzasComponent
                                :origenSolicitud="form.origenSolicitudSelected"
                                :statusSolicitud="estadoSelected"
                                :agente="form.agente"
                                :tipo="form.tipoSelected"
                                :ramo="form.categoriaSelected"
                                :producto="form.productoSelected"
                                :detalleSolicitud="form.detalle"
                                :nombres="form.nombres"
                                :apellidoPaterno="form.apellidoPaterno"
                                :apellidoMaterno="form.apellidoMaterno"
                                :estadoCivil="form.estadoCivilSelected"
                                :estadoNacimiento="form.estadoRepublicaSelected"
                                :genero="form.selectedGenero"
                                :fechaNacimiento="form.fechaNacimiento"
                                :rfc="form.rfc"
                                :tipoPersona="form.regimenSelected"
                                :telefono="form.celular"
                                :correo="form.correoElectronico"
                                :codigoPostal="form.codigoPostal"
                                :direccion="form.direccion"
                                :inicioVigencia="form.inicioVigenciaFormatted"
                                :finVigencia="form.finVigenciaFormatted"
                                :periodicidad="form.periodicidadSelected"
                                :formaPago="form.formaPagoSelected"
                                :operador="form.ejecutivo"
                                :solicitudCompleta="
                                  form.solicitudCompletaSelected
                                "
                                :procesoCotizacion="
                                  form.procesoCotizacionSelected
                                "
                                :comercialAsignado="comercialAsignado"
                                :razonSocial="form.razonSocial"
                                :leadOrigen="leadOrigen"
                                :newFormProps="newFormProps"
                                :esDesdeDialog="true"
                                @SolicitudSuccess="SolicitudSuccess"
                              />
                            </div>

                            <!-- <v-col cols="4">
                              <v-text-field
                                v-model="form.modelo"
                                label="Modelo"
                                placeholder="Requerido"
                                :rules="rules.inputsRequeridos"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="4">
                              <v-text-field
                                label="Marca"
                                v-model="form.marca"
                                placeholder="Requerido"
                                :rules="rules.inputsRequeridos"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="4">
                              <v-text-field
                                v-model="form.submarca"
                                label="Submarca"
                                placeholder="Requerido"
                                :rules="rules.inputsRequeridos"
                              ></v-text-field>
                            </v-col>

                            <v-col cols="4">
                              <v-text-field
                                v-model="form.version"
                                label="Version"
                                placeholder="Requerido"
                                :rules="rules.inputsRequeridos"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="4">
                              <v-select
                                :items="usoAutoList"
                                v-model="form.usoAutoSelected"
                                label="Uso del Auto"
                                placeholder="Requerido"
                                :rules="rules.inputsRequeridos"
                              ></v-select>
                            </v-col> -->

                            <!-- <v-col cols="4">
                              <v-select
                                :items="coberturas"
                                v-model="form.coberturasSelected"
                                label="Plan"
                                placeholder="Requerido"
                                :rules="rules.inputsRequeridos"
                              ></v-select>
                            </v-col> -->

                            <!-- <v-col cols="4">
                              <v-select
                                :items="tipoVehiculosItems"
                                v-model="form.tipoVehiculoSelected"
                                label="Tipo de Vehículo"
                                :rules="rules.inputsRequeridos"
                              ></v-select> 
                            </v-col> -->

                            <!-- CAMPOS SOLO PARA TIPO EMISION Y RENOVACION -->
                            <!-- <v-col cols="4">
                              <v-text-field
                                v-model="form.numMotor"
                                label="No. De Motor"
                                placeholder="Requerido"
                                :rules="rules.inputsRequeridos"
                              ></v-text-field>
                            </v-col>

                            <v-col cols="4">
                              <v-text-field
                                v-model="form.numSerie"
                                label="No. De Serie"
                                placeholder="Requerido"
                                :rules="rules.inputsRequeridos"
                              ></v-text-field>
                            </v-col>

                            <v-col cols="4">
                              <v-text-field
                                v-model="form.placas"
                                label="Placas"
                                placeholder="Requerido"
                                :rules="rules.inputsRequeridos"
                              ></v-text-field>
                            </v-col> -->

                            <!-- FIN DE CAMPOS SOLO PARA TIPO EMISION Y RENOVACION -->
                          </v-row>

                          <v-btn
                            color="primary"
                            @click="sendSolicitud()"
                            v-if="
                              form.tipoSelected != 'Cotización' &&
                                form.tipoSelected != 'Emisión' &&
                                form.tipoSelected != 'Renovación'
                            "
                            :disabled="!formIsValid3"
                          >
                            Guardar
                          </v-btn>
                          <v-btn
                            color="primary"
                            style="margin-left: 10px"
                            @click="stepper = 2"
                            v-if="
                              form.tipoSelected != 'Cotización' &&
                                form.tipoSelected != 'Emisión' &&
                                form.tipoSelected != 'Renovación'
                            "
                            v-show="roles != 'AGENT'"
                          >
                            Siguiente
                          </v-btn>
                        </v-stepper-content>

                        <!-- 2ND ITEM (2ND FORM) -->
                        <v-stepper-content step="2">
                          <v-row style="padding: 20px; margin-top: -35px">
                            <v-col cols="6" v-if="isAgent == false">
                              <v-autocomplete
                                :items="ejecutivos"
                                label="Ejecutivo"
                                v-model="form.ejecutivo"
                                item-text="nombre"
                                item-value="uuid"
                              ></v-autocomplete>
                            </v-col>
                            <v-col cols="6" v-if="isAgent == false">
                              <v-select
                                label="Proceso de Cotización"
                                :items="procesoCotizacion"
                                v-model="form.procesoCotizacionSelected"
                                readonly
                              ></v-select>
                            </v-col>
                          </v-row>

                          <v-row style="padding: 20px; margin-top: -70px">
                            <v-col cols="6" v-if="isAgent == false">
                              <v-select
                                label="¿Solicitud Completa?"
                                :items="solicitudCompleta"
                                v-model="form.solicitudCompletaSelected"
                              ></v-select>
                            </v-col>
                            <v-col cols="4" v-if="isAgent == false">
                              <v-checkbox
                                label="Pendiente de Información"
                                v-model="checkPendienteInfo"
                              ></v-checkbox>
                            </v-col>
                          </v-row>

                          <v-row style="padding: 20px; margin-top: -70px">
                            <v-col cols="6" v-if="isAgent == false">
                              <v-checkbox
                                label="Cancelación"
                                v-model="checkCancelacion"
                              ></v-checkbox>
                            </v-col>
                            <v-col
                              cols="6"
                              v-if="isAgent == false && checkPendienteInfo"
                            >
                              <v-textarea
                                label="Causa de Pendiente de Información"
                                v-model="causaPendienteInformacion"
                                rows="1"
                                auto-grow
                              ></v-textarea>
                            </v-col>
                          </v-row>

                          <v-btn
                            color="primary"
                            @click="sendSolicitud()"
                            :disabled="!comportamientoBotonGuardar()"
                          >
                            Guardar
                          </v-btn>

                          <v-btn text @click="stepper = 1"> Regresar </v-btn>
                          <v-btn text @click="stepper = 3">
                            Ver SLA
                          </v-btn>
                        </v-stepper-content>

                        <!-- 3RD ITEM (SLA) -->
                        <v-stepper-content step="3">
                          <v-row style="padding: 10px">
                            <v-col cols="6">
                              <p>SLA</p>
                            </v-col>
                          </v-row>

                          <v-row style="padding: 20px; margin-top: -90px">
                            <v-col cols="12">
                              <v-select
                                :items="genero"
                                label="Cumple"
                                disabled
                              ></v-select>
                            </v-col>
                            <!-- End 1st Col -->
                          </v-row>

                          <v-row style="padding: 10px; margin-top: -50px">
                            <v-col cols="6">
                              <p>Fecha de Información completa</p>
                            </v-col>
                          </v-row>

                          <v-row style="padding: 20px; margin-top: -80px">
                            <v-col cols="6">
                              <v-text-field
                                :items="genero"
                                placeholder="dd/mm/yyyy"
                                prepend-icon="mdi-calendar"
                                disabled
                              ></v-text-field>
                            </v-col>
                            <v-col cols="6">
                              <v-text-field
                                :items="genero"
                                placeholder="hh:mm"
                                prepend-icon="mdi-clock"
                                disabled
                              ></v-text-field>
                            </v-col>
                            <!-- End 2nd Col -->
                          </v-row>

                          <v-row style="padding: 10px; margin-top: -50px">
                            <v-col cols="6">
                              <p>Fecha de Pendiente de información</p>
                            </v-col>
                          </v-row>

                          <v-row style="padding: 20px; margin-top: -80px">
                            <v-col cols="6">
                              <v-text-field
                                :items="genero"
                                placeholder="dd/mm/yyyy"
                                prepend-icon="mdi-calendar"
                                disabled
                              ></v-text-field>
                            </v-col>
                            <v-col cols="6">
                              <v-text-field
                                :items="genero"
                                placeholder="hh:mm"
                                prepend-icon="mdi-clock"
                                disabled
                              ></v-text-field>
                            </v-col>
                            <!-- End 3rd Col -->
                          </v-row>

                          <v-row style="padding: 10px; margin-top: -50px">
                            <v-col cols="6">
                              <p>Fecha de Emisión de Datos Recibidos</p>
                            </v-col>
                          </v-row>

                          <v-row style="padding: 20px; margin-top: -80px">
                            <v-col cols="6">
                              <v-text-field
                                :items="genero"
                                placeholder="dd/mm/yyyy"
                                prepend-icon="mdi-calendar"
                                disabled
                              ></v-text-field>
                            </v-col>
                            <v-col cols="6">
                              <v-text-field
                                :items="genero"
                                placeholder="hh:mm"
                                prepend-icon="mdi-clock"
                                disabled
                              ></v-text-field>
                            </v-col>
                            <!-- End 4th Col -->
                          </v-row>

                          <v-row style="padding: 10px; margin-top: -50px">
                            <v-col cols="6">
                              <p>Fecha de Emisión en procesp</p>
                            </v-col>
                          </v-row>

                          <v-row style="padding: 20px; margin-top: -80px">
                            <v-col cols="6">
                              <v-text-field
                                :items="genero"
                                placeholder="dd/mm/yyyy"
                                prepend-icon="mdi-calendar"
                                disabled
                              ></v-text-field>
                            </v-col>
                            <v-col cols="6">
                              <v-text-field
                                :items="genero"
                                placeholder="hh:mm"
                                prepend-icon="mdi-clock"
                                disabled
                              ></v-text-field>
                            </v-col>
                            <!-- End 5th Col -->
                          </v-row>

                          <v-row style="padding: 10px; margin-top: -50px">
                            <v-col cols="6">
                              <p>Fecha de Finalización</p>
                            </v-col>
                          </v-row>

                          <v-row style="padding: 20px; margin-top: -80px">
                            <v-col cols="6">
                              <v-text-field
                                :items="genero"
                                placeholder="dd/mm/yyyy"
                                prepend-icon="mdi-calendar"
                                disabled
                              ></v-text-field>
                            </v-col>
                            <v-col cols="6">
                              <v-text-field
                                :items="genero"
                                placeholder="hh:mm"
                                prepend-icon="mdi-clock"
                                disabled
                              ></v-text-field>
                            </v-col>
                            <!-- End 6th Col -->
                          </v-row>

                          <v-btn text @click="stepper = 2"> Regresar </v-btn>
                        </v-stepper-content>
                      </v-stepper-items>
                    </v-stepper>
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
            <!-- BANNER HERE -->
          </v-row>

          <!-- ALERT MODAL -->
          <v-col cols="auto">
            <v-dialog
              transition="dialog-bottom-transition"
              max-width="400"
              v-model="dialog"
            >
              <template v-slot:default="dialog">
                <v-card>
                  <v-toolbar color="success" dark
                    >Confirmación de envío</v-toolbar
                  >
                  <v-card-text>
                    <div class="text-h4" style="margin-top: 10px; ">
                      Envio de solicitud exitoso!
                    </div>
                  </v-card-text>
                  <v-card-actions class="justify-end">
                    <v-btn text @click="dialog.value = false">Cerrar</v-btn>
                  </v-card-actions>
                </v-card>
              </template>
            </v-dialog>
          </v-col>
          <!-- END OF ALERT MODAL -->
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";
import { mainAxios } from "../../../mainAxios";
import RamoAutosComponent from "../../components/ramosComponents/RamoAutosComponent.vue";
import RamoDaniosComponent from "../../components/ramosComponents/RamoDaniosComponent.vue";
import RamoSaludComponent from "../../components/ramosComponents/RamoSaludComponent.vue";
import RamoVidaComponent from "../../components/ramosComponents/RamoVidaComponent.vue";
import RamoAhorroComponent from "../../components/ramosComponents/RamoAhorroComponent.vue";
import RamoFianzasComponent from "../../components/ramosComponents/RamoFianzasComponent.vue";

import RfcFacil from "rfc-facil";
import {
  dominiosComunes,
  validarDominioComun,
} from "@/helpers/validacionDominiosComunes.js";

export default {
  props: {
    dialogSolicitud: {
      type: Boolean,
      required: true,
    },
    leadOrigen: {
      type: Object,
      required: true,
    },
  },
  components: {
    RamoAutosComponent,
    RamoDaniosComponent,
    RamoSaludComponent,
    RamoVidaComponent,
    RamoAhorroComponent,
    RamoFianzasComponent,
  },
  data() {
    const defaultForm = Object.freeze({
      origenSolicitudSelected: "Portal",
      agente: null,
      tipoSelected: null,
      categoriaSelected: null,
      modelo: null,
      marca: null,
      submarca: null,
      version: null,
      detalle: null,
      inicioVigenciaFormatted: null,
      finVigenciaFormatted: null,
      coberturasSelected: null,
      periodicidadSelected: null,
      tipoVehiculoSelected: null,
      numMotor: null,
      numSerie: null,
      placas: null,
      formaPagoSelected: null,
      nombres: null,
      apellidoPaterno: null,
      apellidoMaterno: null,
      fechaNacimiento: null,
      selectedGenero: null,
      estadoCivilSelected: null,
      rfc: null,
      celular: null,
      correoElectronico: null,
      estadoRepublicaSelected: null,
      codigoPostal: null,
      productoSelected: null,
      ejecutivo: null,
      procesoCotizacionSelected: "Cotización",
      solicitudCompletaSelected: null,
      usoAutoSelected: null,
      regimenSelected: null,
      direccion: null,
      razonSocial: null,
      anios: 1,
    });
    return {
      form: Object.assign({}, defaultForm),
      rules: {
        emailRules: [
          (value) => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Dirección de correo incorrecta";
          },
        ],
        rfcRules: [
          (v) =>
            (v || "").length <= 13 || "RFC No debe ser mayor de 13 caracteres",
          (v) => !!v || "Campo Requerido",
          (v) =>
            /^([A-ZÑ\x26]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))([A-Z\d]{3})?$/.test(
              v
            ) || "RFC no es valido recordar escribir en mayusculas",
        ],
        inputsRequeridos: [(value) => !!value || "Campo Requerido"],
        numeric: [(value) => !isNaN(value) || "Número no válido"],
      },
      menuInicio: false,
      menuFin: false,
      menuFechaNacimiento: false,
      roles: JSON.parse(localStorage.getItem("roles"))[0].rol,
      isAgent: false,
      stepper: 1,
      dialog: false,
      bandera: true,
      mostrarFormulario2: false,
      infoTarjetaComercial: null,
      origenSolicitud: [
        "Correo electrónico",
        "Llamada telefónica",
        "Presencial",
        "Whatsapp",
        "Portal",
        "Otro",
      ],
      agentes: [],
      ejecutivos: [],
      estado: [
        "Nueva",
        "Asignado",
        "En trámite",
        "Pendiente de aprobación",
        "Pendiente de información",
        "Recotizar",
        "Cerrado",
        "Cancelada",
      ],
      estadoCivil: ["Soltero(a)", "Casado(a)"],
      tipo: [
        "Cotización",
        "Emisión",
        "Renovación",
        "Endoso A y D",
        "Endoso B",
        "Facturacion",
        "Cancelación",
        "Otro",
      ],
      categoria: ["AUTOS", "DAÑOS", "SALUD", "VIDA", "AHORRO", "FIANZA"],
      estados: [
        { id: 1, estadoRepublica: "Aguascalientes" },
        { id: 2, estadoRepublica: "Baja California" },
        { id: 3, estadoRepublica: "Baja California Sur" },
        { id: 4, estadoRepublica: "Campeche" },
        { id: 5, estadoRepublica: "Coahuila de Zaragoza" },
        { id: 6, estadoRepublica: "Colima" },
        { id: 7, estadoRepublica: "Chiapas" },
        { id: 8, estadoRepublica: "Chihuahua" },
        { id: 9, estadoRepublica: "Ciudad de México" },
        { id: 10, estadoRepublica: "Durango" },
        { id: 11, estadoRepublica: "Guanajuato" },
        { id: 12, estadoRepublica: "Guerrero" },
        { id: 13, estadoRepublica: "Hidalgo" },
        { id: 14, estadoRepublica: "Jalisco" },
        { id: 15, estadoRepublica: "Estado de México" },
        { id: 16, estadoRepublica: "Michoacán de Ocampo" },
        { id: 17, estadoRepublica: "Morelos" },
        { id: 18, estadoRepublica: "Nayarit" },
        { id: 19, estadoRepublica: "Nuevo León" },
        { id: 20, estadoRepublica: "Oaxaca" },
        { id: 21, estadoRepublica: "Puebla" },
        { id: 22, estadoRepublica: "Querétaro" },
        { id: 23, estadoRepublica: "Quintana Roo" },
        { id: 24, estadoRepublica: "San Luis Potosí" },
        { id: 25, estadoRepublica: "Sinaloa" },
        { id: 26, estadoRepublica: "Sonora" },
        { id: 27, estadoRepublica: "Tabasco" },
        { id: 28, estadoRepublica: "Tamaulipas" },
        { id: 29, estadoRepublica: "Tlaxcala" },
        { id: 30, estadoRepublica: "Veracruz de Ignacio de la Llave" },
        { id: 31, estadoRepublica: "Yucatán" },
        { id: 32, estadoRepublica: "Zacatecas" },
      ],
      formaPago: [
        "Tarjeta de Crédito",
        "Tarjeta de Débito",
        "Deposito Bancario",
        "Transferencia",
        "Cheque",
        "Efectivo",
        "Domiciliación",
      ],
      periodicidadItems: ["Anual", "Semestral", "Trimestral", "Mensual"],
      tipoVehiculosItems: [
        "Auto",
        "Motocicleta",
        "Pick-up",
        "Camión",
        "Tractocamión",
      ],
      coberturas: ["Amplia", "Amplia-Plus", "Limitada", "RC"],
      usoAutoList: [
        "Particular",
        "Comercial",
        "Uso con App",
        "Servicio público federal",
        "Transporte público",
        "Taxi",
      ],
      regimenList: ["Física", "Moral"],
      agenteUuid: localStorage.getItem("agenteUuid"),
      estadoSelected: "Nueva",
      productos: [],
      genero: ["Masculino", "Femenino"],
      // date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      date: "",
      dateFechaNacimiento: "",
      menu1: false,
      menu2: false,
      edad: null,
      procesoCotizacion: ["Cotización", "Emisión", "Finalizado"],
      solicitudCompleta: ["Si", "No"],
      checkPendienteInfo: false,
      checkCancelacion: false,
      causaPendienteInformacion: null,
      canal: [],
      comercialAsignadoList: [],
      comercialAsignado: null,
      esAgentDirecto: null,
      polizaRenovar: null,
      fechaInicioVigenciaRenovar: null,
      fechaFinVigenciaRenovar: null,
      defaultForm,
      productoOrigal: [],
      busquedaCorreo: "",
      colonias: [],

      campanias: [],
    };
  },
  watch: {
    dialog(val) {
      if (!val) return;

      setTimeout(() => (this.dialog = false), 4000);
    },
    date(val) {
      this.inicioVigenciaFormatted = this.formatDate(val);
    },
    dialogSolicitud(val) {
      if (val) {
        this.putOriginalData();
      }
    },
    "form.inicioVigenciaFormatted"(val) {
      var format_date = moment(val).format("DD/MM/YYYY");
      this.form.finVigenciaFormatted = moment(format_date)
        .add(1, "y")
        .format("DD/MM/YYYY");
      this.updateFinVigencia();
    },
    "form.agente"() {
      this.form.categoriaSelected = "";
      this.calculateRamos();
      this.filtrarProductos();
    },
    "form.categoriaSelected"() {
      this.filtrarProductos();
    },

    "form.nombres"() {
      this.actualizarRfc();
    },
    "form.apellidoPaterno"() {
      this.actualizarRfc();
    },
    "form.apellidoMaterno"() {
      this.actualizarRfc();
    },
    "form.fechaNacimiento"() {
      this.calcularEdad();
      this.actualizarRfc();
    },
    "form.codigoPostal"(val) {
      if (val.toString().length < 4) return;
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios
        .get("v1/catalogo/direccion?cp=" + val, config)
        .then((response) => {
          this.colonias = [];
          response.data.listaColonias.forEach((item) => {
            this.colonias.push(item.nombreColonia);
          });
          this.form.municipio = response.data.nombreMunicipio;
          this.form.estadoRepublicaSelected = this.estados.find(
            (e) => e.id == Number(response.data.nEstado)
          ).id;
        });
    },
    "form.anios"() {
      this.updateFinVigencia();
    },
  },
  methods: {
    goBack() {
      return this.$router.go(-1);
    },
    actualizarRfc() {
      if (
        !this.form.nombres ||
        !this.form.apellidoPaterno ||
        !this.form.apellidoMaterno ||
        !this.form.fechaNacimiento
      )
        return;
      const arregloNacimiento = this.form.fechaNacimiento
        .split("/")
        .map((e) => Number(e));
      if (arregloNacimiento.length < 3) return;
      const rfc = RfcFacil.forNaturalPerson({
        name: this.form.nombres,
        firstLastName: this.form.apellidoPaterno,
        secondLastName: this.form.apellidoMaterno,
        day: arregloNacimiento[0],
        month: arregloNacimiento[1],
        year: arregloNacimiento[2],
      });
      this.form.rfc = rfc.substring(0, rfc.length - 3);
    },
    calcularEdad() {
      const arregloNacimiento = this.form.fechaNacimiento
        .split("/")
        .map((e) => Number(e));
      if (arregloNacimiento.length < 3) return;
      const dateFNacimiento = new Date(
        `${arregloNacimiento[2]}-${arregloNacimiento[1]}-${arregloNacimiento[0]}`
      );
      const diferenciaMS = Date.now() - dateFNacimiento.getTime();
      const edadDiferencia = new Date(diferenciaMS);

      const edad = Math.abs(edadDiferencia.getUTCFullYear() - 1970);
      if (isNaN(edad)) return;
      this.form.edad = edad;
    },
    validarDominioComun,
    filtroCorreo(item, queryText, itemText) {
      this.busquedaCorreo = queryText;
      return itemText.includes(queryText);
    },
    updateFinVigencia() {
      if (!this.form.anios || !this.form.inicioVigenciaFormatted) return;
      const arrayDate = this.form.inicioVigenciaFormatted.split("/");
      if (arrayDate.length < 3) return;

      this.form.finVigenciaFormatted = `${arrayDate[0]}/${
        arrayDate[1]
      }/${Number(arrayDate[2]) + Number(this.form.anios)}`;
    },
    async getCampanias() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };

      mainAxios
        .get("/v1/campania/lead-segurify/list", config)
        .then(({ data }) => {
          this.campanias = data;
          this.loading = false;
          this.putOriginalData()
        });
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    putOriginalData(){
      this.form.inicioVigenciaFormatted =  moment().format('DD/MM/YYYY')
      this.form.finVigenciaFormatted = moment().add(1, 'y').format('DD/MM/YYYY');
      this.form.nombres = this.leadOrigen.nombre ? this.leadOrigen.nombre : ''
      this.form.apellidoPaterno = this.leadOrigen.apellidoPaterno ? this.leadOrigen.apellidoPaterno : ''
      this.form.apellidoMaterno = this.leadOrigen.apellidoMaterno ? this.leadOrigen.apellidoMaterno : ''
      this.form.regimenSelected = this.leadOrigen.regimen ? this.leadOrigen.regimen : ''
      this.form.estadoRepublicaSelected = this.leadOrigen.estado ? Number(this.leadOrigen.estado) :  ''
      this.form.rfc = this.leadOrigen.rfc ? this.leadOrigen.rfc : ''
      this.form.estadoCivilSelected = this.leadOrigen.estadoCivil ? this.leadOrigen.estadoCivil : ''
      this.form.fechaNacimiento = this.leadOrigen.fechaNacimiento ? moment(this.leadOrigen.fechaNacimiento).format('DD/MM/YYYY')  : null
      this.form.estadoCivilSelected = this.leadOrigen.estadoCivil ? this.leadOrigen.estadoCivil : ''
      this.form.celular = this.leadOrigen.telefono ? this.leadOrigen.telefono : ''
      this.form.correoElectronico = this.leadOrigen.correo ? this.leadOrigen.correo : ''
      this.form.codigoPostal = this.leadOrigen.codigoPostal ? String(this.leadOrigen.codigoPostal) : ''
      this.form.direccion = this.leadOrigen.calle ? this.leadOrigen.calle : '' 
                            + ' #' +  this.leadOrigen.num_exterior ? this.leadOrigen.num_exterior : '' 
                            + ' ' + this.leadOrigen.colonia ? this.leadOrigen.colonia : '' 
                            + ' ' + this.leadOrigen.ciudad ? this.leadOrigen.ciudad : ''                                        
      this.busquedaCorreo = this.form.correoElectronico;
      this.form.idOrigenLead = this.leadOrigen.id;
      this.form.agente = this.campanias.find(e => e.id == this.leadOrigen.campania_id) ? this.campanias.find(e => e.id == this.leadOrigen.campania_id).agente_id : null
      this.form.campania = this.campanias.find(e => e.id == this.leadOrigen.campania_id) ? this.campanias.find(e => e.id == this.leadOrigen.campania_id).producto : null,
      this.form.categoriaSelected = this.leadOrigen.ramo;
      this.form.calle = this.leadOrigen.calle;
      this.form.num_exterior = this.leadOrigen.num_exterior;
      this.form.num_interior = this.leadOrigen.num_interior;
      this.form.colonia = this.leadOrigen.colonia;
      this.form.selectedGenero = this.leadOrigen.sexo;
    },
    sendSolicitud() {
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };

      let request = {
        origenSolicitudSelected: this.form.origenSolicitudSelected
          ? this.form.origenSolicitudSelected
          : "",
        tipoSelected: this.form.tipoSelected ? this.form.tipoSelected : "",
        categoriaSelected: this.form.categoriaSelected
          ? this.form.categoriaSelected
          : "",
        agenteId: this.form.agente ? this.form.agente : "",
        productoId: this.form.productoSelected
          ? this.form.productoSelected
          : "",
        detalle: this.form.detalle ? this.form.detalle : "",
        operadorUuid: this.form.ejecutivo ? this.form.ejecutivo : "",
        regimen_persona: this.form.regimenSelected
          ? this.form.regimenSelected
          : "",
        razon_social: this.form.razonSocial ? this.form.razonSocial : "",
        nombres: this.form.nombres ? this.form.nombres : "",
        apellidoPaterno: this.form.apellidoPaterno
          ? this.form.apellidoPaterno
          : "",
        apellidoMaterno: this.form.apellidoMaterno
          ? this.form.apellidoMaterno
          : "",
        estadoRepublicaSelected: this.form.estadoRepublicaSelected
          ? this.form.estadoRepublicaSelected
          : "",
        rfc: this.form.rfc ? this.form.rfc : "",
        solicitudCompletaSelected: this.form.solicitudCompletaSelected
          ? this.form.solicitudCompletaSelected
          : "",
        procesoCotizacionSelected: this.form.procesoCotizacionSelected
          ? this.form.procesoCotizacionSelected
          : "",
        checkPendienteInfo: this.checkPendienteInfo,
        checkCancelacion: this.checkCancelacion,
        causaPendienteInformacion: this.causaPendienteInformacion
          ? this.causaPendienteInformacion
          : "",
        lead_id: this.leadOrigen.id ? this.leadOrigen.id : "",
        emit_direct: this.emitDirect,
        operadorId: JSON.parse(localStorage.getItem("roles"))[0].agente_id
          ? JSON.parse(localStorage.getItem("roles"))[0].agente_id
          : "777",
        checkTramite: this.emitDirect,
        ...this.newFormProps,
      };

      mainAxios
        .post("v1/solicitud/insert/", request, config)
        .then(() => {
          this.SolicitudSuccess();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    obtenerAgentes() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios.get("/v1/solicitud/agente/list", config).then((response) => {
        // console.log("Agente: ", response.data);
        response.data.map((element) => {
          this.agentes.push({
            nombre: element.nombre,
            id: element.id,
            uuid: element.uuid,
            canalList: element.canalesList,
            ramosList: element.ramosList ? element.ramosList : [],
          });
          if (this.roles == "AGENT") {
            localStorage.agenteUuid == element.uuid
              ? (this.form.agente = element.id)
              : "";
          }
        });
        // this.agentes.map(user => {
        //   console.log("Que trae USER: ", user);
        // })
      });
    },
    obtenerUsersCanalDirecto() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios
        .get("/v1/solicitud/agente/directo/list", config)
        .then((response) => {
          response.data.map((element) => {
            this.comercialAsignadoList.push({
              nombre: element.nombre,
              id: element.id,
              uuid: element.uuid,
            });
          });
        });
    },
    obtenerEjecutivos() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios.get("/v1/agentes/operador", config).then((response) => {
        response.data.map((element) => {
          this.ejecutivos.push({
            nombre:
              `${element.nombre}` +
              " " +
              `${element.apellidoPaterno}` +
              " " +
              `${element.apellidoMaterno ? element.apellidoMaterno : ""}`,
            id: element.id,
            uuid: element.uuid,
          });
          if (this.roles == "OPERADOR") {
            localStorage.agenteUuid == element.uuid
              ? (this.form.ejecutivo = element.uuid)
              : "";
          }
        });
      });
    },
    obtenerProductos() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios.get("/v1/producto/list", config).then((response) => {
        response.data.map((element) => {
          this.productos.push({
            nombre:
              `${element.ramo ? element.ramo.toUpperCase() : ""}` +
              " / " +
              `${element.nombre ? element.nombre.toUpperCase() : ""}` +
              " / " +
              `${
                element.nombreAseguradora
                  ? element.nombreAseguradora.toUpperCase()
                  : ""
              }`,
            id: element.id,
            canal: element.canal ? element.canal : "Todos",
            ramo: element.ramo ? element.ramo : "No disponible",
          });
          this.productoOrigal = this.productos;
        });
      });
    },
    obtenerLocalStorage() {
      const id = localStorage;
      console.log(id);
    },
    agent() {
      if (this.roles == "AGENT") {
        this.isAgent = true;
      }
    },
    comportamientoBotonGuardar() {
      switch (this.form.tipoSelected) {
        case "Cotización":
          return this.formIsValid;
        case "Emisión":
        case "Renovación":
          return this.formIsValid2;
        case "Endoso A y D":
        case "Endoso B":
        case "Facturacion":
        case "Cancelación":
        case "Otro":
          return this.formIsValid3;
        default:
          break;
      }
    },
    esDirecto() {
      this.agentes.map((element) => {
        let elemento = JSON.parse(JSON.stringify(element));
        let canalList = elemento.canalList;
        if (element.id == this.form.agente) {
          canalList.map((elm) => {
            if (elm.id == 3) {
              this.esAgentDirecto = true;
            } else {
              this.esAgentDirecto = false;
            }
          });
        }
      });
    },
    closeModal() {
      this.$emit("closeSolicitudModal");
    },
    SolicitudSuccess() {
      this.$emit("SolicitudSuccess");
    },
    filtrarProductos() {
      if (this.form.agente != null && this.form.categoriaSelected != null) {
        let agenteSelected = this.agentes.find(
          (element) => element.id === this.form.agente
        );
        let currentCanalesName = [];
        let isOnlyMasivos = false;

        if (
          agenteSelected.canalList.length == 1 &&
          agenteSelected.canalList[0].canal == "MASIVOS"
        ) {
          isOnlyMasivos = true;
        }

        agenteSelected.canalList.map((element) => {
          currentCanalesName.push(element.canal);
        });

        this.productos = [];
        this.form.productoSelected = null;
        this.productoOrigal.map((element) => {
          if (element.canal == "Todos" && !isOnlyMasivos) {
            if (element.ramo == this.form.categoriaSelected) {
              this.productos.push({
                nombre: element.nombre,
                id: element.id,
                canal: element.canal ? element.canal : "Todos",
                ramo: element.ramo ? element.ramo : "No disponible",
              });
            }
          } else {
            let result = currentCanalesName.indexOf(element.canal);
            if (result != "-1") {
              if (element.ramo == this.form.categoriaSelected) {
                this.productos.push({
                  nombre: element.nombre,
                  id: element.id,
                  canal: element.canal ? element.canal : "Todos",
                  ramo: element.ramo ? element.ramo : "No disponible",
                });
              }
            }
          }
        });
      }
    },
    calculateRamos() {
      let agenteSelected = this.agentes.find(
        (element) => element.id === this.form.agente
      );
      let currentRamosName = [];
      agenteSelected.ramosList.map((element) => {
        currentRamosName.push(element.ramo);
      });
      this.categoria = currentRamosName;
    },
  },
  computed: {
    // obtenerCanalAgente() {

    // },
    // esDirecto2() {
    //   this.agentes.map( element => {
    //     let elemento = JSON.parse(JSON.stringify(element))
    //     let canalList = elemento.canalList
    //     if(element.id == this.form.agente) {
    //       canalList.map(elm => {
    //         console.log("element", elm);
    //         if (elm.id == 3) {
    //           return true
    //         }else {
    //           return false
    //         }
    //       })
    //     }
    //   })
    //   return false
    // },
    computedDateFormattedMomentjs() {
      return this.date ? moment(this.date).format("DD/MM/YYYY") : "";
    },
    // computedDateBirthdayFormattedMomentjs () {
    //   return this.form.fechaNacimiento ? moment(this.form.fechaNacimiento).format('DD/MM/YYYY') : ''
    // },
    computedDateInicioVigenciaFormattedMomentjs() {
      return this.form.inicioVigenciaFormatted
        ? moment(this.form.inicioVigenciaFormatted).format("DD/MM/YYYY")
        : "";
    },
    computedDateFinVigenciaFormattedMomentjs() {
      return this.form.finVigenciaFormatted
        ? moment(this.form.finVigenciaFormatted).format("DD/MM/YYYY")
        : "";
    },
    computedDateFormatted() {
      return this.formatDate(this.date);
    },

    //ESTE FUNCIONA PARA TIPO "COTIZACIÓN"
    formIsValid() {
      if (this.roles == "AGENT") {
        return (
          this.form.agente != null &&
          this.form.tipoSelected != null &&
          this.form.categoriaSelected != null &&
          this.form.inicioVigenciaFormatted != null &&
          this.form.finVigenciaFormatted != null &&
          this.form.periodicidadSelected != null &&
          // this.form.nombres != null &&
          // this.form.apellidoPaterno != null &&
          // this.form.fechaNacimiento != null &&
          this.form.regimenSelected != null &&
          // this.form.selectedGenero != null &&
          // this.form.estadoCivilSelected != null &&
          this.form.rfc != null &&
          this.form.celular != null &&
          this.form.correoElectronico != null &&
          this.form.estadoRepublicaSelected != null &&
          this.form.codigoPostal != null
          // this.form.productoSelected
        );
      }
      return (
        this.form.agente != null &&
        this.form.tipoSelected != null &&
        this.form.categoriaSelected != null &&
        this.form.inicioVigenciaFormatted != null &&
        this.form.finVigenciaFormatted != null &&
        this.form.periodicidadSelected != null &&
        // this.form.nombres != null &&
        // this.form.apellidoPaterno != null &&
        // this.form.fechaNacimiento != null &&
        this.form.regimenSelected != null &&
        // this.form.selectedGenero != null &&
        // this.form.estadoCivilSelected != null &&
        this.form.rfc != null &&
        this.form.celular != null &&
        this.form.correoElectronico != null &&
        this.form.estadoRepublicaSelected != null &&
        this.form.codigoPostal != null &&
        this.form.productoSelected
      );
    },

    //ESTE FUNCIONA PARA TIPO "EMISIÓN" Y "RENOVACIÓN"
    formIsValid2() {
      if (this.roles == "AGENT") {
        return (
          this.form.agente != null &&
          this.form.tipoSelected != null &&
          this.form.categoriaSelected != null &&
          this.form.inicioVigenciaFormatted != null &&
          this.form.finVigenciaFormatted != null &&
          this.form.periodicidadSelected != null &&
          this.form.formaPagoSelected != null &&
          // this.form.nombres != null &&
          // this.form.apellidoPaterno != null &&
          // this.form.fechaNacimiento != null &&
          this.form.regimenSelected != null &&
          // this.form.selectedGenero != null &&
          // this.form.estadoCivilSelected != null &&
          this.form.rfc != null &&
          this.form.celular != null &&
          this.form.correoElectronico != null &&
          this.form.estadoRepublicaSelected != null &&
          this.form.codigoPostal != null
          // this.form.productoSelected
        );
      }
      return (
        this.form.agente != null &&
        this.form.tipoSelected != null &&
        this.form.categoriaSelected != null &&
        this.form.inicioVigenciaFormatted != null &&
        this.form.finVigenciaFormatted != null &&
        this.form.periodicidadSelected != null &&
        this.form.formaPagoSelected != null &&
        // this.form.nombres != null &&
        // this.form.apellidoPaterno != null &&
        // this.form.fechaNacimiento != null &&
        this.form.regimenSelected != null &&
        // this.form.selectedGenero != null &&
        // this.form.estadoCivilSelected != null &&
        this.form.rfc != null &&
        this.form.celular != null &&
        this.form.correoElectronico != null &&
        this.form.estadoRepublicaSelected != null &&
        this.form.codigoPostal != null &&
        this.form.productoSelected
      );
    },

    //ESTE ES PARA LOS TIPOS QUE NO SON "COTIZACIÓN", "EMISIÓN" O "RENOVACIÓN"
    formIsValid3() {
      return (
        this.form.agente != null &&
        this.form.tipoSelected != null &&
        this.form.categoriaSelected != null &&
        this.form.detalle != null &&
        // this.form.nombres != null &&
        // this.form.apellidoPaterno != null &&
        this.form.rfc != null
      );
    },

    prediccionesCorreo() {
      if (!this.busquedaCorreo.length) return [""];
      if (this.busquedaCorreo.includes("@")) return [this.busquedaCorreo];
      return dominiosComunes.map((e) => `${this.busquedaCorreo}@${e}`);
    },

    newFormProps() {
      return {
        edad: this.form.edad,
        codigoPostal: this.form.codigoPostal,
        calle: this.form.calle,
        numInterior: this.form.num_interior,
        numExterior: this.form.num_exterior,
        colonia: this.form.colonia,
        municipio: this.form.municipio,
        anios: this.form.anios,
        idOrigenLead: this.form.idOrigenLead,
        campania: this.form.campania,
      };
    },
    emitDirect() {
      if (
        this.roles == "ADMINCC" ||
        this.roles == "MANAGERCC" ||
        this.roles == "OPERADORCC" ||
        this.roles == "AGENTCC" ||
        this.roles == "AGENTCCSPONSOR"
      ) {
        return true;
      } else return false;
    },
    canEditAgent(){
      var currentCampania = this.campanias.find(e => e.id == this.leadOrigen.campania_id)
      if(currentCampania){
        if(currentCampania.canal == "DIRECTO" || currentCampania.canal == "MASIVOS"){
          return true
        }else{
          return false
        }
      }else{
        return false
      }
    }
  },
  async mounted() {
    await this.getCampanias();
    this.obtenerAgentes(),
      this.obtenerEjecutivos(),
      this.obtenerProductos(),
      this.obtenerLocalStorage(),
      this.agent(),
      this.obtenerUsersCanalDirecto(),
      this.putOriginalData();
  },
};
</script>
