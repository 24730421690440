<template>
  <v-main class="mb-15">
    <v-container>
      <v-row>
        <v-col>
          <p
            dir="ltr"
            style="
              line-height: 1.2;
              background-color: #f8fafc;
              margin-top: 0pt;
              margin-bottom: 0pt;
              padding: 0pt 0pt 14pt 0pt;
            "
          >
            <span style="
                font-size: 24pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              ">T&eacute;rminos y Condiciones</span>
          </p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt"
          >
            <br />
          </p>
          <hr />
          <p><br /></p>
          <p
            dir="ltr"
            style="
              line-height: 1.2;
              text-align: justify;
              background-color: #f8fafc;
              margin-top: 0pt;
              margin-bottom: 0pt;
            "
          >
            <span style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 700;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              ">T&eacute;rminos Generales</span>
          </p>
          <p
            dir="ltr"
            style="
              line-height: 1.2;
              text-align: justify;
              background-color: #f8fafc;
              margin-top: 0pt;
              margin-bottom: 0pt;
            "
          >
            <span style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "><br /></span><span style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              ">1. El contenido del presente sitio est&aacute; dirigido
              &uacute;nica y exclusivamente a los Agentes y Comisionistas que
              son colaboradores de Alige Soluciones, Agente de Seguros y de
              Fianzas S.A de C.V. (en lo sucesivo &ldquo;Segurify&rdquo;), por
              lo que &eacute;ste &uacute;ltimo requiere que dichos visitantes se
              adhieran a los siguientes t&eacute;rminos y condiciones y a
              cualquier otro contrato de servicio, seg&uacute;n sea el caso, que
              sean requeridos por Segurify, para el uso de sus servicios o
              productos espec&iacute;ficos, denominados &nbsp;&quot;Contratos de
              Servicio&quot;. Por el hecho de tener acceso al sitio, los
              &ldquo;Agentes y Comisionistas&rdquo; expresan su
              aceptaci&oacute;n y acuerdo con estos t&eacute;rminos y
              condiciones, as&iacute; como con cada modificaci&oacute;n a los
              mismos que haga Segurify de momento a momento. Debido a la
              naturaleza de apertura de Internet y del Sitio, es necesario
              cambiar y modificar constantemente el contenido, estructura y
              servicios que se ofrecen. Al acceder al Sitio, los &ldquo;Agentes
              y Comisionistas&rdquo; entienden y aceptan que Segurify
              tendr&aacute; en cualquier momento derecho a realizar dichas
              modificaciones sin previa notificaci&oacute;n. Por consiguiente,
              los &ldquo;Agentes y Comisionistas&rdquo; aceptan que es su
              obligaci&oacute;n revisar peri&oacute;dicamente este &quot;Aviso
              Legal&quot; para estar actualizados de los cambios y
              modificaciones realizadas al mismo, para lo cual, podr&aacute;n
              acceder a cada p&aacute;gina del Sitio, pudiendo visualizarlo en
              la parte inferior de cada una de ellas. Segurify no garantiza que
              los Productos, Materiales o Servicios disponibles a trav&eacute;s
              de este Sitio est&eacute;n acordes a legislaciones diferentes a
              aquellas fuera del territorio de los Estados Unidos Mexicanos ni
              de tener acceso a los mismos. Cualquier &ldquo;Agente y
              Comisionista&rdquo; que opte por acceder a este Sitio desde otras
              ubicaciones lo hace por iniciativa propia y es responsable del
              cumplimiento de leyes locales. Este Aviso Legal, sus
              modificaciones peri&oacute;dicas, una vez incorporadas al sitio,
              constituyen las reglas generales de uso del sitio, En lo sucesivo
              las &quot;Reglas del Sitio&quot;. Segurify le informa que si bien
              hace de buena fe su mejor esfuerzo a fin de que la calidad y el
              contenido que se proporciona mediante el sitio, el contenido al
              que es posible tener acceso a trav&eacute;s del Internet
              as&iacute; como aquel que se puede encontrar en cualquier enlace
              (link), p&aacute;gina web, etc., no es ni puede ser monitoreado,
              evaluado ni controlado por Segurify, &nbsp;dicho contenido y
              direcciones disponibles cambian constantemente, por lo que desde
              este momento, los &ldquo;Agentes y Comisionistas&rdquo; liberan de
              manera absoluta y sin limitaci&oacute;n alguna a Segurify de
              cualquier tipo de responsabilidad, civil, penal, administrativa,
              as&iacute; como de los da&ntilde;os (patrimoniales o morales),
              perjuicios o cualquiera otra que pudiera emanar del uso de los
              Servicios y de la informaci&oacute;n disponible mediante el
              mismo.</span>
          </p>
          <p
            dir="ltr"
            style="
              line-height: 1.2;
              text-align: justify;
              background-color: #f8fafc;
              margin-top: 0pt;
              margin-bottom: 0pt;
            "
          >
            &nbsp;
          </p>
          <p
            dir="ltr"
            style="
              line-height: 1.2;
              text-align: justify;
              background-color: #f8fafc;
              margin-top: 0pt;
              margin-bottom: 0pt;
            "
          >
          <span style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "><br /></span>
            <span style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              ">2. Servicios, Materiales y Productos</span>
          </p>
          <p
            dir="ltr"
            style="
              line-height: 1.2;
              text-align: justify;
              background-color: #f8fafc;
              margin-top: 0pt;
              margin-bottom: 0pt;
            "
          >
            <span style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "><br /></span><span style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              ">Segurify puede proporcionar y/o poner a disposici&oacute;n de los
              &ldquo;Agentes y Comisionistas&rdquo; &nbsp;diversos servicios
              financieros de seguros y de informaci&oacute;n, consistentes
              principalmente en ofertas para la contrataci&oacute;n de
              p&oacute;lizas de seguros de distintas compa&ntilde;&iacute;as de
              seguros que contiene cada una de ellas distintas condiciones
              generales de contrataci&oacute;n, coberturas y precios, siendo
              ofertas ajenas al control de Segurify, en lo sucesivo los
              &quot;Servicios&quot; y otros Materiales y Productos e
              informaci&oacute;n relacionada disponible a trav&eacute;s del
              sitio, o por enlaces con otros sitios de Internet, ya sea directa
              o indirectamente por diversos proveedores ajenos a Segurify en lo
              sucesivo los &quot;Sitios de Terceros&quot;. Sin embargo, Segurify
              no puede garantizar el servicio, los Productos o los Materiales.
              Los &ldquo;Agentes y Comisionistas&rdquo; entienden y aceptan que
              pueden darse retrasos, omisiones o imprecisiones en cualquier
              Producto, Material o Servicio, mismos que de ninguna manera pueden
              ser considerados responsabilidad de Segurify.</span>
          </p>
          <p
            dir="ltr"
            style="
              line-height: 1.2;
              text-align: justify;
              background-color: #f8fafc;
              margin-top: 0pt;
              margin-bottom: 0pt;
            "
          >
            <span style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "><br /></span><span style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "><br /></span><span style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              ">3. Uso de los Datos Personales.</span>
          </p>
          <p
            dir="ltr"
            style="
              line-height: 1.2;
              text-align: justify;
              background-color: #f8fafc;
              margin-top: 0pt;
              margin-bottom: 0pt;
            "
          >
            <span style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "><br /></span><span style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              ">Los Datos Personales que se le proporcione de manera voluntaria a
              los &ldquo;Agentes y Comisionistas&rdquo; por parte de los Cliente
              o consumidores de Segurify, podr&aacute;n ser usados
              &uacute;nicamente: (i) para proporcionarle informaci&oacute;n
              relativa a los servicios de Seguros y Fianzas ofertados por
              Segurify, (ii) para efectos de mercadotecnia internos, y (iii)
              para mantener vigente y existente la relaci&oacute;n con el
              cliente y Segurify, y/o dar cumplimiento a los compromisos
              adquiridos, por lo que ser&aacute; obligaci&oacute;n de los
              &ldquo;Agentes y Comisionistas&rdquo;, tratar los datos
              personales, patrimoniales y/o sensibles a raz&oacute;n de lo
              establecido en el presente contrato sin obtener alg&uacute;n
              beneficio propio, derivado del tratamiento de los datos personales
              proporcionados, as&iacute; como a no ser transferidos a terceros
              sin autorizaci&oacute;n por parte de los clientes o consumidores.
              En caso de que los &ldquo;Agentes y Comisionistas&rdquo;
              transfieran los datos proporcionados a terceros para casos
              distintos a lo aqu&iacute; establecido, &eacute;ste ser&aacute;
              responsable de los da&ntilde;os o perjuicios que se pudieran
              ocasionar.</span>
          </p>
          <p
            dir="ltr"
            style="
              line-height: 1.2;
              text-align: justify;
              background-color: #f8fafc;
              margin-top: 0pt;
              margin-bottom: 0pt;
            "
          >
            &nbsp;
          </p>
          <p
            dir="ltr"
            style="
              line-height: 1.2;
              text-align: justify;
              background-color: #f8fafc;
              margin-top: 0pt;
              margin-bottom: 0pt;
            "
          >
            <span style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              ">En caso de incumplimiento a la obligaci&oacute;n se&ntilde;alada
              en el p&aacute;rrafo anterior, los &ldquo;Agentes y
              Comisionistas&rdquo; aceptan indemnizar y sacar en paz y a salvo a
              Segurify y/o a las Aseguradoras y a cada uno de sus funcionarios,
              consejeros, empleados, agentes, consultores y representantes
              respectivos (cada uno una &ldquo;Parte Indemnizada&rdquo;), por y
              en contra de toda reclamaci&oacute;n, da&ntilde;o, p&eacute;rdida,
              responsabilidad y gastos (incluyendo sin limitar, los gastos y
              honorarios razonables de asesor&iacute;a legal) en los que se
              pudiera incurrir o fueran determinados o fallados contra cualquier
              Parte Indemnizada, que surjan en cada caso, en conexi&oacute;n (o
              por la preparaci&oacute;n) de cualquier defensa relativa al
              incumplimiento de lo establecido por la Ley Federal de
              Protecci&oacute;n de Datos Personales en Posesi&oacute;n de los
              Particulares antes mencionada.</span>
          </p>
          <p
            dir="ltr"
            style="
              line-height: 1.2;
              text-align: justify;
              background-color: #f8fafc;
              margin-top: 0pt;
              margin-bottom: 0pt;
            "
          >
            <span style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "><br /><br /></span>
          </p>
          <p
            dir="ltr"
            style="
              line-height: 1.2;
              text-align: justify;
              background-color: #f8fafc;
              margin-top: 0pt;
              margin-bottom: 0pt;
            "
          >
            <span style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              ">4. Responsabilidad</span>
          </p>
          <p
            dir="ltr"
            style="
              line-height: 1.2;
              text-align: justify;
              background-color: #f8fafc;
              margin-top: 0pt;
              margin-bottom: 0pt;
            "
          >
            &nbsp;
          </p>
          <p
            dir="ltr"
            style="
              line-height: 1.2;
              text-align: justify;
              background-color: #f8fafc;
              margin-top: 0pt;
              margin-bottom: 0pt;
            "
          >
            <span style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              ">Segurify, no es responsable y no controla previamente, aprueba,
              ni hace propios los servicios, informaci&oacute;n, datos,
              archivos, productos y cualquier clase de material existente en los
              sitios enlazados de las p&aacute;ginas de Internet. Los
              &ldquo;Agentes y Comisionistas&rdquo;, por tanto, debe extremar
              prudencia en la valoraci&oacute;n y utilizaci&oacute;n de los
              servicios, informaci&oacute;n, datos, archivos, productos y
              cualquier clase de material existente en los sitios enlazados.
              Segurify, no garantiza ni asume responsabilidad alguna por los
              da&ntilde;os y perjuicios de toda clase que puedan causarse por:
              (i) el funcionamiento, disponibilidad, accesibilidad o continuidad
              de los sitios enlazados; (ii) el mantenimiento de los servicios,
              informaci&oacute;n, datos, archivos, productos y cualquier clase
              de material existente en los sitios enlazados; (iii) las
              obligaciones y ofertas existentes en los sitios enlazados.
              Adicionalmente, los &ldquo;Agentes y Comisionistas&rdquo;
              reconocen y aceptan desde ahora que bajo su estricta
              responsabilidad hacen uso de Internet y en general de medios de
              comunicaci&oacute;n electr&oacute;nicos y teleinform&aacute;ticos,
              de modo que, en consecuencia, liberan expresamente a Segurify a
              sus socios y directivos, de cualquier responsabilidad civil o
              penal derivada del uso de dichos medios de comunicaci&oacute;n.
              Segurify, no ser&aacute; en ning&uacute;n caso responsable frente
              a los &ldquo;Agentes y Comisionistas&rdquo; o terceros, por
              da&ntilde;os y/o perjuicios que se relacionen con el uso o
              imposibilidad de uso de los servicios del sitio, ya sea que
              &eacute;stos deriven o no del incumplimiento a las instrucciones
              recibidas por los &ldquo;Agentes y Comisionistas&rdquo;, cuando la
              falta de cumplimiento se deba a un caso fortuito o de fuerza
              mayor, por fallas en el funcionamiento de sistemas de
              c&oacute;mputo o interrupci&oacute;n en los sistemas de
              comunicaci&oacute;n o alg&uacute;n acontecimiento similar, fuera
              del control de Segurify. Asimismo, los &ldquo;Agentes y
              Comisionistas&rdquo; &nbsp;manifiestan bajo formal protesta de
              decir verdad que, los datos que le fueron solicitados en el sitio
              al momento de la contrataci&oacute;n de una p&oacute;liza de
              seguro &nbsp;son correctos y corresponden fielmente con la
              realidad, aceptando que en caso de que no lo fuera as&iacute; y
              hubieren sido proporcionados datos incompletos o inexactos y por
              ende el Servicio contratado sea negado por dicha causa por alguna
              de las compa&ntilde;&iacute;as de seguros que se anuncian en el
              sitio, es responsabilidad &uacute;nica y exclusivamente de los
              &ldquo;Agentes y Comisionistas&rdquo; .</span><span style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "><br /></span><span style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "><br /></span><span style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              ">5. Limitaci&oacute;n de Garant&iacute;as</span>
          </p>
          <p
            dir="ltr"
            style="
              line-height: 1.2;
              text-align: justify;
              background-color: #f8fafc;
              margin-top: 0pt;
              margin-bottom: 0pt;
            "
          >
            <span style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "><br /></span><span style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              ">Los &ldquo;Agentes y Comisionistas&rdquo; aceptan que Segurify,
              no tendr&aacute; responsabilidad o contingencia alguna, de la
              precisi&oacute;n y puntualidad de los servicios o productos, o de
              la previsi&oacute;n, puntualidad, contenido o secuencia correcta
              de los materiales o de cualquier decisi&oacute;n o acto realizado
              por el usuario confi&aacute;ndose en los productos, materiales o
              servicio, por la interrupci&oacute;n o demora de cualquier
              informaci&oacute;n, material o cualquier otro aspecto de los
              servicios. Todos los servicios, productos y/o materiales se
              proporcionan &quot;como est&aacute;n&quot;. No se garantiza que
              cualquiera servicio, productos, materiales y otros contenidos
              disponibles en o a trav&eacute;s del este sitio, o el sistema de
              computaci&oacute;n de Segurify cumplir&aacute; con los fines o
              necesidades particulares del usuario. No existen garant&iacute;as
              impl&iacute;citas de comercialidad o calidad, condiciones para un
              uso particular, o precisi&oacute;n del contenido
              inform&aacute;tico, y no existe ninguna otra garant&iacute;a de
              cualquier tipo, expresa o impl&iacute;cita, respecto a los
              productos, materiales o servicios, o cualquier otro aspecto de los
              servicios (incluyendo sin estar limitado a, acceso de
              informaci&oacute;n y orden de ejecuci&oacute;n) aun si Segurify ha
              sido informado o de alguna a otra forma tiene conocimiento de la
              posibilidad de tales da&ntilde;os, incluyendo sin
              limitaci&oacute;n, responsabilidad asociada con cualquier
              c&oacute;digo de computadora, que pudiera infectar el equipo
              computacional o software relacionado de los &ldquo;Agentes y
              Comisionistas&rdquo;&nbsp;</span>
          </p>
          <p
            dir="ltr"
            style="
              line-height: 1.2;
              text-align: justify;
              background-color: #f8fafc;
              margin-top: 0pt;
              margin-bottom: 0pt;
            "
          >
            <span style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "><br /></span><span style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              ">6. Cambios a las Reglas del Sitio</span>
          </p>
          <p
            dir="ltr"
            style="
              line-height: 1.2;
              text-align: justify;
              background-color: #f8fafc;
              margin-top: 0pt;
              margin-bottom: 0pt;
            "
          >
            <span style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "><br /></span><span style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              ">Segurify, se reserva el derecho a revisar el &ldquo;Aviso
              Legal&rdquo; en cualquier tiempo, as&iacute; como a realizar
              modificaciones en dicho aviso y adaptarlo a las modificaciones
              legislativas, jurisprudenciales, as&iacute; como a
              pr&aacute;cticas de mercado. Asi mismo, los &ldquo;Agentes y
              Comisionistas&rdquo; se considerar&aacute;n enterados y obligados
              a dichos cambios en el momento en que estos ingresen al sitio y
              hagan uso de cualquier material, producto o servicio. Dicho uso o
              acceso al sitio por su parte constituir&aacute; la
              aceptaci&oacute;n y conformidad a los cambios del &ldquo;Aviso
              Legal&rdquo;, independientemente de lo dispuesto en el Contrato de
              Servicios.</span><span style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "><br /></span><span style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "><br /></span><span style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "><br /></span><span style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              ">7. Marcas y Propiedad Intelectual</span>
          </p>
          <p
            dir="ltr"
            style="
              line-height: 1.2;
              text-align: justify;
              background-color: #f8fafc;
              margin-top: 0pt;
              margin-bottom: 0pt;
            "
          >
            <span style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "><br /></span><span style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              ">La marca &ldquo;Segurify&rdquo; y logo que aparece en el sitio es
              una marca debidamente autorizada por su titular para uso y
              explotaci&oacute;n comercial de Alige Soluciones, Agente de
              Seguros y de Fianzas S.A de C.V. Todas las dem&aacute;s marcas y/o
              logos que aparece en el sitio se entienden propiedad de terceros,
              seg&uacute;n se indique. El Copyright en las im&aacute;genes,
              textos, pantallas y sitios de Internet que aparezcan o se
              relacionen con este sitio son propiedad de Segurify, tal y como se
              indica en el mismo. Se puede copiar la informaci&oacute;n y
              material de los sitios para el uso personal o educativo, siempre y
              cuando cada copia incluya una leyenda de Copyright, marcas
              registradas o derechos reservados cuando se realice la
              reproducci&oacute;n. Sin embargo, la informaci&oacute;n y material
              de los sitios no podr&aacute;n ser copiados, mostrados,
              distribuidos, impresos, dados en cualquier tipo de licencia,
              modificados, publicados, reproducidos, usados, vendidos,
              transmitidos, usados para crear un trabajo derivado o para fines
              p&uacute;blicos o comerciales, a excepci&oacute;n de que cumpla
              con los t&eacute;rminos y condiciones mostrados en el sitio y se
              tenga el consentimiento por escrito de Segurify y/o de las
              empresas titulares de dichas marcas. Los actos de
              reproducci&oacute;n, env&iacute;o, retransmisi&oacute;n,
              difusi&oacute;n, venta, distribuci&oacute;n, publicaci&oacute;n o
              transmisi&oacute;n de dicho contenido, no transferir&aacute;n a
              los &ldquo;Agentes y Comisionistas&rdquo; de los servicios de
              nuestros portales, titularidad alguna sobre cualquier software o
              material en este sitio.</span>
          </p>
          <p
            dir="ltr"
            style="
              line-height: 1.2;
              text-align: justify;
              background-color: #f8fafc;
              margin-top: 0pt;
              margin-bottom: 0pt;
            "
          >
            <span style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "><br /></span><span style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              ">8.Cookies</span><span style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "><br /></span><span style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "><br /></span><span style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              ">Segurify, se reserva el derecho de crear en la computadora de los
              &ldquo;Agentes y Comisionistas&rdquo;, un archivo cookie u otro de
              igual prop&oacute;sito, mismo que almacenar&aacute;
              informaci&oacute;n relacionada con el uso del sitio y que
              permitir&aacute; prestar los servicios relacionados con nuestros
              portales en forma m&aacute;s sencilla para los propios
              &ldquo;Agentes y Comisionistas&rdquo;.</span>
          </p>
          <p
            dir="ltr"
            style="
              line-height: 1.2;
              text-align: justify;
              background-color: #f8fafc;
              margin-top: 0pt;
              margin-bottom: 0pt;
            "
          >
            <span style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "><br /></span><span style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              ">9. Autorizaci&oacute;n a ligas de p&aacute;ginas.</span>
          </p>
          <p
            dir="ltr"
            style="
              line-height: 1.2;
              text-align: justify;
              background-color: #f8fafc;
              margin-top: 0pt;
              margin-bottom: 0pt;
            "
          >
            <span style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "><br /></span><span style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              ">A trav&eacute;s de este sitio los &ldquo;Agentes y
              Comisionistas&rdquo; podr&aacute; tener enlaces a otros sitios, en
              los cuales, Segurify, hace su mejor esfuerzo para asegurarse que
              el contenido de dichos sitios cumpla con los est&aacute;ndares
              establecidos para esta p&aacute;gina. Los enlaces, sus direcciones
              y contenidos pueden cambiar constantemente y Segurify no
              ser&aacute; responsable de la disponibilidad o funcionamiento del
              contenido de estos sitios. Esta p&aacute;gina y los enlaces a
              otros sitios no podr&aacute;n ser objeto de ligas o enlaces a
              sitios de terceros sin autorizaci&oacute;n previa y por escrito de
              Segurify</span>
          </p>
          <p
            dir="ltr"
            style="
              line-height: 1.2;
              text-align: justify;
              background-color: #f8fafc;
              margin-top: 0pt;
              margin-bottom: 0pt;
            "
          >
            <span style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "><br /></span><span style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              ">10. Autorizaci&oacute;n para usar la informaci&oacute;n</span>
          </p>
          <p
            dir="ltr"
            style="
              line-height: 1.2;
              text-align: justify;
              background-color: #f8fafc;
              margin-top: 0pt;
              margin-bottom: 0pt;
            "
          >
            <span style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "><br /></span><span style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              ">Al utilizar esta p&aacute;gina, loa &ldquo;Agentes y
              Comisionistas&rdquo; autorizan a Segurify, a hacer uso de la
              informaci&oacute;n personal proporcionada libremente por este,
              para cualquier fin legal, incluido, sin limitaci&oacute;n alguna,
              para ser usada para contactar al Usuario v&iacute;a
              tel&eacute;fono y/o correo elect&oacute;nico con el objetivo de
              darle a conocer informaci&oacute;n relacionada con los Servicios
              que los propios &ldquo;Agentes y Comisionistas&rdquo; manifiesten
              tener inter&eacute;s en contratar con Segurify.</span>
          </p>
          <p
            dir="ltr"
            style="
              line-height: 1.2;
              text-align: justify;
              background-color: #f8fafc;
              margin-top: 0pt;
              margin-bottom: 0pt;
            "
          >
            &nbsp;
          </p>
          <p
            dir="ltr"
            style="
              line-height: 1.2;
              text-align: justify;
              background-color: #f8fafc;
              margin-top: 0pt;
              margin-bottom: 0pt;
            "
          >
            <span style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "><br /></span><span style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              ">11. Fin de la informaci&oacute;n</span>
          </p>
          <p
            dir="ltr"
            style="
              line-height: 1.2;
              text-align: justify;
              background-color: #f8fafc;
              margin-top: 0pt;
              margin-bottom: 0pt;
            "
          >
            <span style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "><br /></span><span style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              ">No se deber&aacute; entender que la informaci&oacute;n financiera
              y de cualquier otra naturaleza que aparezca en este sitio tiene el
              prop&oacute;sito de asesorar a los &ldquo;Agentes y
              Comisionistas&rdquo; o que Segurify es responsable de la
              emisi&oacute;n de dicha informaci&oacute;n, por lo que se advierte
              que podr&aacute; existir demora, inexactitud u omisi&oacute;n en
              la informaci&oacute;n ah&iacute; contenida y que la misma no
              deber&aacute; ser considerada como oferta o recomendaci&oacute;n
              para la realizaci&oacute;n de operaciones espec&iacute;ficas.
              Segurify no garantiza la exactitud o integridad de la
              informaci&oacute;n que se proporciona a trav&eacute;s de los
              servicios de sus portales. El contenido de este sitio o
              p&aacute;gina estar&aacute; sujeto a cambios sin previo
              aviso.</span>
          </p>
          <p
            dir="ltr"
            style="
              line-height: 1.2;
              text-align: justify;
              background-color: #f8fafc;
              margin-top: 0pt;
              margin-bottom: 0pt;
            "
          >
            <span style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "><br /></span><span style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              ">12. Ley y jurisdicci&oacute;n</span>
          </p>
          <p
            dir="ltr"
            style="
              line-height: 1.2;
              text-align: justify;
              background-color: #f8fafc;
              margin-top: 0pt;
              margin-bottom: 0pt;
            "
          >
            <span style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "><br /></span><span style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              ">Los &ldquo;Agentes y Comisionistas&rdquo; al hacer uso de las
              p&aacute;ginas de Internet de Segurify, aceptan de manera expresa
              someterse en caso de cualquier controversia, a la
              jurisdicci&oacute;n de los tribunales de la Ciudad de
              M&eacute;xico, as&iacute; como a las leyes aplicables para el caso
              concreto vigentes en dicho lugar, renunciando tambi&eacute;n
              expresamente a cualquier otra jurisdicci&oacute;n que por motivo
              de su nacionalidad o domicilio pudiera corresponder.</span>
          </p>
          <p><br /></p>
          <p><br /></p>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
export default {};
</script>

<style>
p {
  padding-left: 10px;
  padding-right: 10px;
}
</style>