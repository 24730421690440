<template>
  <v-app>
    <Navigation></Navigation>
    <v-container>
      <v-row class="mb-15" v-if="polizaEnviada == false">
        <v-col md="12" cols="12" class="">
          <h2 class="title pb-3 pt-10">{{uuidPoliza == null ? 'Crear una nueva emisión' : 'Editar Emisión'}}</h2>
          <p v-if="uuidPoliza">
            Emisión fuera del portal Segurify
          </p>
          <p v-else>
            Llena el formulario a continuación para registrar una
            Emisión fuera del portal Segurify
          </p>
          <p style="font-size:14px">Los campos con * son requeridos</p>
        </v-col>

        <v-col cols="12" md="4">
          <v-text-field
            ref="name"
            v-model="request.poliza"
            :rules="[rules.required]"
            :error-messages="errorMessages"
            label="Número de póliza*"
            required
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="4">
          <v-text-field
            ref="name"
            v-model="request.campania"
            :rules="[rules.required]"
            :error-messages="errorMessages"
            label="Campaña / Landing*"
            required
          ></v-text-field>
        </v-col>

        <!-- Date picker fecha iniicio-->
        <v-col cols="12" md="4">
          <v-menu
            v-model="menu2"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="request.inicio"
                label="Fecha inicio*"
                prepend-icon="mdi-calendar"
                :rules="[rules.required]"
                :error-messages="errorMessages"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="request.inicio"
              @input="menu2 = false"
            ></v-date-picker>
          </v-menu>
        </v-col>

        <!-- Date picker fecha fin-->
        <v-col cols="12" md="4">
          <v-menu
            v-model="menu3"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="request.fin"
                label="Fecha fin*"
                prepend-icon="mdi-calendar"
                :rules="[rules.required]"
                :error-messages="errorMessages"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="request.fin"
              @input="menu3 = false"
            ></v-date-picker>
          </v-menu>
        </v-col>

        <!-- Plan de pago -->
        <v-col cols="12" md="4">
          <v-select
            item-text="periodo"
            item-value="id"
            label="Plan de Pago*"
            :rules="[rules.required]"
            v-model="request.pago_id"
            :items="planesPago"
          ></v-select>
        </v-col>

        <!-- Medio de pago -->
        <v-col cols="12" md="4">
          <v-select
            item-text="medio"
            item-value="id"
            label="Medio de pago*"
            :rules="[rules.required]"
            v-model="request.forma_pago"
            :items="mediosPago"
          ></v-select>
        </v-col>

        <!-- Aseguradora -->
        <v-col cols="12" md="4">
          <v-select
            item-text="aseguradora"
            item-value="id"
            label="Aseguradora*"
            :rules="[rules.required]"
            v-model="request.aseguradora_id"
            :items="aseguradoras"
          ></v-select>
        </v-col>

        <!-- Plan -->
        <v-col cols="12" md="4">
          <v-select
            item-text="plan"
            item-value="id"
            label="Plan*"
            v-model="request.plan_id"
            :rules="[rules.required]"
            :items="plan"
          ></v-select>
        </v-col>

        <!-- Status -->
        <v-col cols="12" md="4">
          <v-select
            item-text="status"
            item-value="id"
            label="Status*"
            v-model="request.status"
            :rules="[rules.required]"
            :items="status"
          ></v-select>
        </v-col>

        <!-- Status cobro
        <v-col cols="12" md="4">
          <v-select
            item-text="statusCobro"
            item-value="id"
            label="Status cobro*"
            v-model="request.status_cobro"
            :rules="[rules.required]"
            :items="statusCobro"
          ></v-select>
        </v-col> -->

        <v-col cols="12" md="4">
          <v-text-field
            ref="name"
            v-model="request.nombre_cliente"
            :rules="[rules.required]"
            :error-messages="errorMessages"
            label="Nombre del titular*"
            required
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="4">
          <v-text-field
            ref="name"
            v-model="request.prima_total"
            :rules="[rules.required]"
            :error-messages="errorMessages"
            label="Prima total*"
            prepend-icon="mdi-currency-usd"
            required
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="4">
          <v-text-field
            ref="name"
            v-model="request.prima_neta"
            :rules="[rules.required]"
            :error-messages="errorMessages"
            label="Prima neta*"
            prepend-icon="mdi-currency-usd"
            required
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="4">
          <v-text-field
            ref="name"
            v-model="request.primer_pago"
            label="Primer pago*"
            :rules="[rules.required]"
            prepend-icon="mdi-currency-usd"
            required
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="4" v-if="request.pago_id!=1">
          <v-text-field
            ref="name"
            v-model="request.subsecuente_pago"
            label="Pago subsecuente*"
            :rules="[rules.required]"
            prepend-icon="mdi-currency-usd"
            required
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="4">
          <v-text-field
            ref="name"
            v-model="request.correo_electronico"
            :rules="[rules.required, rules.email]"
            :error-messages="errorMessages"
            label="Correo electrónico contacto*"
            required
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="4">
          <v-text-field
            ref="name"
            v-model="request.operador_id"
            :rules="[rules.required, rules.email]"
            :error-messages="errorMessages"
            label="Correo electrónico asesor*"
            required
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="4">
          <v-text-field
            ref="name"
            v-model="request.telefono"
            :rules="[rules.required]"
            :error-messages="errorMessages"
            label="Teléfono*"
            required
            type="tel"
            maxlength="10"
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="4">
          <v-text-field
            ref="name"
            v-model="request.vehiculo"
            :rules="[rules.required]"
            :error-messages="errorMessages"
            label="Vehículo*"
            required
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="4">
          <v-file-input v-if="!request.url"
            counter
            label="Selecciona el archivo PDF*"
            accept=".pdf"
            :rules="[rules.required]"
            v-model="files"
            truncate-length="23"
          ></v-file-input>
          <v-btn v-else :href="'https://segurify-data.s3.amazonaws.com/'+request.url">Descargar Poliza</v-btn>

        </v-col>


        <v-col cols="12" md="4">
          <v-text-field
            ref="name"
            v-model="request.origen"
            :rules="[rules.required]"
            :error-messages="errorMessages"
            label="No. Cotización / Lead ID*"
            required
          ></v-text-field>
        </v-col>

        <!-- Estado de la República -->
        <v-col cols="12" md="4">
          <v-select
            item-text="estadoRepublica"
            item-value="id"
            :rules="[rules.required]"
            v-model="request.estado_id"
            :items="estados"
          ></v-select>
        </v-col>

        <v-col cols="12" md="12" style="text-align: right;">
          <!-- <v-row v-if="rol != ADMIN && rol != ADMINCC && rol != MANAGERCC"> -->
          <v-row >
            <v-col cols="8"></v-col>
            
            <v-col cols="2" class="text-right" v-if="!request.url">
              <v-btn rounded :disabled="verificarRequest()" color="primary" @click="enviar" v-if="polizaEnviada == false" >Guardar</v-btn>
              <p v-if="cargandoPoliza">Cargando poliza...</p>
              <p v-if="enviandoPoliza">Enviando Poliza...</p>
              <p v-if="polizaEnviada">La poliza se ha cargado exitosamente</p>
            </v-col>
            <v-col cols="2" class="text-left" v-if="!request.url">
              <v-btn rounded color="primary" @click="$router.push('/administrador/reporte-emisiones-offline')" v-if="polizaEnviada == false" >Regresar</v-btn>
            </v-col>
            <v-col cols="4" class="text-right" v-else>
              <v-btn rounded color="primary" @click="$router.push('/administrador/reporte-emisiones-offline')" v-if="polizaEnviada == false" >Regresar</v-btn>
            </v-col>
          </v-row>

          <!-- <v-row v-else>
            <v-col cols="10"></v-col>
            
            <v-col cols="2" >
              <v-btn rounded :disabled="verificarRequest()" color="primary" @click="enviar" v-if="polizaEnviada == false" >Guardar</v-btn>
              <p v-if="cargandoPoliza">Cargando poliza...</p>
              <p v-if="enviandoPoliza">Enviando Poliza...</p>
              <p v-if="polizaEnviada">La poliza se ha cargado exitosamente</p>
            </v-col>
          </v-row> -->
        </v-col>

      </v-row>

      <v-row class="mb-15" v-else>
        <v-col md="12" cols="12" class="">
          <h2 class="title pb-3 pt-10">Guardada con Exito</h2>
        </v-col>
      </v-row>
      
    </v-container>
    <v-footer fixed style="text-align:center;">
      <p style="text-align:center;width:100%; margin: 10px 0px">Segurify 2021 <a target="_blank" href="/terminos-y-condiciones">Terminos y condiciones</a> y <a target="_blank" href="/aviso-de-privacidad">Aviso de privacidad</a>  </p>
    </v-footer>
  </v-app>
</template>

<script>
import Navigation from "../../../../components/Nav.vue";
import { mainAxios } from "../../../../mainAxios";
//var moment = require("moment-timezone");

export default {
    data() {
        return {
            roles: JSON.parse(localStorage.getItem('roles'))[0].rol,
            files:[],
            polizaEnviada:false,
            enviandoPoliza:false,
            cargandoPoliza:false,
            uuid: localStorage.getItem('agenteUuid'),
            request:{
                "operador_id":null,
                "correo_operador": null,
                "forma_pago":1,
                "fecha": new Date().toISOString().substr(0, 10),
                "poliza": null,
                "prima_total": null,
                "prima_neta": null,
                "pago_id": 1,
                "aseguradora_id": 1,
                "estado_id": 9,
                "url": null,
                "status": 1,
                "status_cobro": 1,
                "origen": null,
                "vehiculo": null,
                "telefono": null,
                "correo_electronico": null,
                "nombre_cliente": null,
                "uuid": null
            },
            generalRules: [
              v => !!v || 'El valor es obligatorio',
            ],
            emisionUuid: null,
            rules: {
              required: value => !!value || 'Este campo es obligatorio.',
              email: value => {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                return pattern.test(value) || 'Correo electronico invalido'
              },
            }, 
            errorMessages: '',
            formHasErrors: false,
            // data picket
            dateCarga: new Date().toISOString().substr(0, 10),
            fechaInicio: null,
            fechaFin: null,
            menu2: false,
            menu3: false,
            planesPago: [
              {id: 1, periodo: 'Anual'},
              {id: 2, periodo: 'Semestral'},
              {id: 3, periodo: 'Trimestral'},
              {id: 4, periodo: 'Mensual'},
            ],
            mediosPago: [
              {id: 1, medio: 'Tarjeta de Crédito'},
              {id: 2, medio: 'Tarjeta de Débito'},
              {id: 3, medio: 'Depósito en banco'},
              {id: 4, medio: 'Transferencia'},
              {id: 5, medio: 'Cheque'},
              {id: 6, medio: 'Efectivo'},
              {id: 7, medio: 'Domiciliación'},
              {id: 8, medio: 'Cargo único'},
            ],
            aseguradoras: [
              {id: 1, aseguradora: 'Afirme'},
              {id: 2, aseguradora: 'ANA'},
              {id: 3, aseguradora: 'GNP'},
              {id: 4, aseguradora: 'Primero'},
              {id: 5, aseguradora: 'Qualitas'},
              {id: 6, aseguradora: 'Multiva'},
              {id: 7, aseguradora: 'Sura'},
              {id: 8, aseguradora: 'AIG'},
              {id: 9, aseguradora: 'HDI'},
              {id: 10, aseguradora: 'Zurich'},
              {id: 11, aseguradora: 'AXA'},
              {id: 12, aseguradora: 'Banorte'},
            ],
            plan: [
              {id: 1, plan: 'Amplia'},
              {id: 14, plan: 'Amplia plus'},
              {id: 3, plan: 'Limitada'},
              {id: 4, plan: 'RC'},
            ],
            
            status: [
              {id: 0, status: 'Nueva'},
              {id: 1, status: 'Emitida Pendente de Pago'},
              {id: 2, status: 'Pagada Parcial'},
              {id: 3, status: 'Pagada Total'},
              {id: 4, status: 'Renovada'},
              {id: 5, status: 'Finalizada'},
              {id: 6, status: 'Cancelada'},
            ],
            
            statusCobro: [
              {id: 1, statusCobro: 'Pagada'},
              {id: 2, statusCobro: 'No Pagada'},
            ],
            estados: [
              {id: 1, estadoRepublica: 'Aguascalientes'},
              {id: 2, estadoRepublica: 'Baja California'},
              {id: 3, estadoRepublica: 'Baja California Sur'},
              {id: 4, estadoRepublica: 'Campeche'},
              {id: 5, estadoRepublica: 'Coahuila de Zaragoza'},
              {id: 6, estadoRepublica: 'Colima'},
              {id: 7, estadoRepublica: 'Chiapas'},
              {id: 8, estadoRepublica: 'Chihuahua'},
              {id: 9, estadoRepublica: 'Ciudad de México'},
              {id: 10, estadoRepublica: 'Durango'},
              {id: 11, estadoRepublica: 'Guanajuato'},
              {id: 12, estadoRepublica: 'Guerrero'},
              {id: 13, estadoRepublica: 'Hidalgo'},
              {id: 14, estadoRepublica: 'Jalisco'},
              {id: 15, estadoRepublica: 'Estado de México'},
              {id: 16, estadoRepublica: 'Michoacán de Ocampo'},
              {id: 17, estadoRepublica: 'Morelos'},
              {id: 18, estadoRepublica: 'Nayarit'},
              {id: 19, estadoRepublica: 'Nuevo León'},
              {id: 20, estadoRepublica: 'Oaxaca'},
              {id: 21, estadoRepublica: 'Puebla'},
              {id: 22, estadoRepublica: 'Querétaro'},
              {id: 23, estadoRepublica: 'Quintana Roo'},
              {id: 24, estadoRepublica: 'San Luis Potosí'},
              {id: 25, estadoRepublica: 'Sinaloa'},
              {id: 26, estadoRepublica: 'Sonora'},
              {id: 27, estadoRepublica: 'Tabasco'},
              {id: 28, estadoRepublica: 'Tamaulipas'},
              {id: 29, estadoRepublica: 'Tlaxcala'},
              {id: 30, estadoRepublica: 'Veracruz'},
              {id: 31, estadoRepublica: 'Yucatán'},
              {id: 32, estadoRepublica: 'Zacatecas'},
              {id: 33, estadoRepublica: 'Desconocio'},
            ],
            uuidPoliza: null,
            formularioOportunidades: [],
            camposOportunidades: '',
        }
    },
    components:{
        Navigation,
    },
    mounted() {
      this.uuidPoliza = this.$route.params.emision ? this.$route.params.emision : null
      if (this.uuidPoliza != null) {
        this.loadEmision()
        this.loadInfoOportunidades()
      }
      this.$material.locale.dateFormat="M/d/yy";
      this.$material.locale.months=["Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre"]
      this.$material.locale.shortMonths=["Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre"]
      this.$material.locale.days=['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado']
      this.$material.locale.shortDays=['Dom', 'Lun', 'Mar', 'Miér', 'Jue', 'Vie', 'Sáb']
    },
    methods: {

      loadEmision() {
        const config = {
          headers: {
            Authorization: "Bearer " + localStorage.agenteAccessToken,
          },
        };

        mainAxios.get(`/v1/agente/emision/${this.uuidPoliza}`, config).then((response) => {
          let emisionOff = response.data
          console.log(response.data);

            this.request.operador_id = emisionOff.operadorId
            this.request.forma_pago = emisionOff.formaPago
            this.request.fecha = emisionOff.fecha
            this.request.inicio = emisionOff.inicio ? emisionOff.inicio.substr(0, 10) : ""
            this.request.fin = emisionOff.fin ? emisionOff.fin.substr(0, 10) : ''
            this.request.poliza = emisionOff.poliza
            this.request.prima_total = emisionOff.total
            this.request.prima_neta = emisionOff.primaNeta
            this.request.primer_pago = emisionOff.primerPago
            this.request.subsecuente_pago = emisionOff.subsecuentePago
            this.request.pago_id = emisionOff.pagoId
            this.request.plan_id = emisionOff.planId
            this.request.campania = emisionOff.campania
            this.request.aseguradora_id = emisionOff.aseguradoraId
            this.request.estado_id = emisionOff.estadoId
            this.request.url = emisionOff.urlPoliza
            this.request.status = parseInt(emisionOff.status)
            this.request.status_cobro = parseInt(emisionOff.statusCobro)
            this.request.origen = emisionOff.origen
            this.request.vehiculo = emisionOff.nombreVehiculo
            this.request.telefono = emisionOff.telefonoContacto
            this.request.correo_electronico = emisionOff.correoContacto
            this.request.nombre_cliente = emisionOff.titular
            this.request.uuid = this.uuidPoliza,
            this.request.id = emisionOff.id
        })
      },

      loadInfoOportunidades() {
        let urlForm = `/v1/oportunidad/auto/${this.uuidPoliza}`;
        mainAxios
          .get(urlForm, {
            headers: {
              Authorization: 'Bearer 8b06348c-2830-486c-bb7c-e0599ae750f5',
            },
          })
          .then((response) => {
            console.log(response);
            let valores = response.data.valores ? response.data.valores : '';
            this.formularioOportunidades = [];
            this.camposOportunidades = response.data.campos;
            this.oportunidad = response.data.oportunidad;
            this.formularioOportunidades[0] = 'En seguimiento'
            this.formularioOportunidades[3] = 'Autos'
            valores.map((element) => {
              element.campoId == 25 ? this.formularioOportunidades[0] = element.valor : ''
              element.campoId == 71 ? this.formularioOportunidades[1] = element.valor : ''
              element.campoId == 4 ? this.formularioOportunidades[2] = element.valor : ''
              element.campoId == 27 ? this.formularioOportunidades[3] = element.valor : ''
              element.campoId == 45 ? this.formularioOportunidades[4] = element.valor : ''
              element.campoId == 46 ? this.formularioOportunidades[5] = element.valor : ''
              element.campoId == 26 ? this.formularioOportunidades[6] = element.valor : ''
              element.campoId == 33 ? this.formularioOportunidades[7] = element.valor : ''
              element.campoId == 34 ? this.formularioOportunidades[8] = element.valor : ''
              element.campoId == 40 ? this.formularioOportunidades[9] = element.valor : ''
              element.campoId == 75 ? this.formularioOportunidades[10] = element.valor : ''
              element.campoId == 76 ? this.formularioOportunidades[11] = element.valor : ''
              element.campoId == 28 ? this.formularioOportunidades[12] = element.valor : ''
              element.campoId == 77 ? this.formularioOportunidades[13] = element.valor : ''
              element.campoId == 49 ? this.formularioOportunidades[14] = element.valor : ''
              element.campoId == 47 ? this.formularioOportunidades[15] = element.valor : ''
              element.campoId == 48 ? this.formularioOportunidades[16] = element.valor : ''
              element.campoId == 50 ? this.formularioOportunidades[17] = element.valor : ''
              element.campoId == 74 ? this.formularioOportunidades[18] = element.valor : ''
              element.campoId == 41 ? this.formularioOportunidades[19] = element.valor : ''
              element.campoId == 42 ? this.formularioOportunidades[20] = element.valor : ''
              element.campoId == 35 ? this.formularioOportunidades[21] = element.valor : ''
              element.campoId == 36 ? this.formularioOportunidades[22] = element.valor : ''
              element.campoId == 39 ? this.formularioOportunidades[23] = element.valor : ''
              element.campoId == 43 ? this.formularioOportunidades[24] = element.valor : ''
              element.campoId == 30 ? this.formularioOportunidades[25] = element.valor : ''
              element.campoId == 31 ? this.formularioOportunidades[26] = element.valor : ''
              element.campoId == 29 ? this.formularioOportunidades[27] = element.valor : ''
              element.campoId == 37 ? this.formularioOportunidades[28] = element.valor : ''
              element.campoId == 38 ? this.formularioOportunidades[29] = element.valor : ''
              element.campoId == 5 ? this.formularioOportunidades[30] = element.valor : ''
              element.campoId == 3 ? this.formularioOportunidades[31] = element.valor : ''
            });
            
            this.ventaOportunidad()
            const config = {
              headers: {
                Authorization: "Bearer " + 'cba3bb78-144b-434f-bd04-77ec68f03d05',
              },
            };
            console.log('this.oportunidad');
            console.log(this.oportunidad);
            if (this.oportunidad.emisionUuid) {
              mainAxios.get(`/v1/agente/emision/${this.oportunidad.emisionUuid}`, config).then((response) => {
                this.urlPoliza = response.data.urlPoliza
              })
            }
            // const fecha = new Date();
            // const year = fecha.getFullYear();
            // const day = fecha.getDate();
            // const month = fecha.getMonth() + 1;
          });
      },
        
      enviar(){
        let newDate = this.dateCarga.split('-')
        this.request.fecha = `${newDate[1]}/${newDate[2]}/${newDate[0].slice(2)}`
        let vm=this;
        let fd = new FormData();
        
        const config = {
          headers: {
            Authorization: "Bearer " + localStorage.agenteAccessToken,
          },
        };
        
        const configFile = { 
          headers: { 
            Authorization: "Bearer " + localStorage.agenteAccessToken,
            'Content-Type': 'multipart/form-data' 
          }   
        }
        if (this.request.url != null) {
          vm.cargandoPoliza=false;
          vm.request.url=this.request.url;
          this.request.rol = this.roles[0].rol
          
          mainAxios.post("/v1/emision/manual/actualizar",this.request,config).then((value) => {
            console.log(value);
            vm.enviandoPoliza=false;
            vm.polizaEnviada=true;
            setTimeout(() => {
              this.$router.push('/administrador/reporte-emisiones-offline')
            }, 2000);
          })
        } else {
          fd.append('file', this.files);
          this.cargandoPoliza=true;
          mainAxios.post("/v1/emision/upload-file", fd, configFile).then((value) => {
              vm.cargandoPoliza=false;
              vm.request.url=value.data.url;
              this.request.uuid = this.uuid;
              this.request.rol = this.roles[0].rol
              vm.enviandoPoliza=true;
              // Estatus Poliza
            this.request.status = this.formularioOportunidades[24] == 'Nueva'  ? 0 
              : this.formularioOportunidades[24] == 'Emitida Pendente de Pago' ? 1 
              : this.formularioOportunidades[24] == 'Pagada Parcial' ? 2 
              : this.formularioOportunidades[24] == 'Pagada Total' ? 3 
              : this.formularioOportunidades[24] == 'Renovada' ? 4 
              : this.formularioOportunidades[24] == 'Finalizada' ? 5 
              : 'Cancelada'
  
            this.request.statusCobro = this.formularioOportunidades[24] == 'Nueva'  ? 0 
              : this.formularioOportunidades[24] == 'Emitida Pendente de Pago' ? 1 
              : this.formularioOportunidades[24] == 'Pagada Parcial' ? 2 
              : this.formularioOportunidades[24] == 'Pagada Total' ? 3 
              : this.formularioOportunidades[24] == 'Renovada' ? 4 
              : this.formularioOportunidades[24] == 'Finalizada' ? 5 
              : 'Cancelada'
  
              mainAxios.post("/v1/emision/manual",this.request,config).then((value) => {
                console.log(value);
                vm.enviandoPoliza=false;
                this.emisionUuid = value.data.uuid
                vm.polizaEnviada=true;
                setTimeout(() => {
                  this.$router.push('/administrador/reporte-emisiones-offline')
                }, 2000);
              })
          })
        }
      },

      verificarRequest() {
        if(
          this.request.forma_pago != null &&
          this.request.fecha != null &&
          this.request.inicio != null &&
          this.request.fin != null &&
          this.request.poliza != null &&
          this.request.prima_total != null &&
          this.request.prima_neta != null &&
          this.request.primer_pago != null &&
          this.request.pago_id != null &&
          this.request.plan_id != null &&
          this.request.campania != null &&
          this.request.aseguradora_id != null &&
          this.request.estado_id != null &&
          this.request.status != null &&
          this.request.status_cobro != null &&
          this.request.origen != null &&
          this.request.vehiculo != null &&
          this.request.telefono != null &&
          this.request.correo_electronico != null &&
          this.request.nombre_cliente != null
        ) {
          console.log('todo son diferentes a null');
          return false
        } else {
          console.log('todo son iguales a null');
          return true
        }
      }
    },

}
</script>