<template>
  <v-app>
    <Navigation></Navigation>
    <v-main style="background: #f6f9ff;">
      <v-container>
        <v-row class="mb-15 fill-height">
          <v-col>
            <v-row>
              <v-col>
                <h1>Reporte de Tarificación</h1>
              </v-col>
            </v-row>
            <v-row class="mb-15" style="padding-left: 30px">
              <v-col xl="3" md="12" xs="12"  >
                <v-card
                    elevation="10"
                    class="mx-auto"
                  >
                  <v-card-text>
                    <h1 class="pb-5">Filtro </h1>  
                    <h3>Selecciona el rango de fechas</h3>
                    <v-date-picker
                      full-width
                      v-model="dates"
                      range
                    ></v-date-picker>
                    <div class="mt-5">
                        <v-btn
                          rounded
                          color="primary"
                          style="width: 100%"
                          dark
                          @click="consultar"
                          :disabled="!blockBoton"
                          >Generar reporte</v-btn
                        >
                        <v-btn
                          rounded
                          color="primary"
                          style="width:100%;margin-top:20px;"
                          :disabled="bloquear"
                          >
                            <download-csv
                              :data="reportDownloadInfo"
                              name= "Reporte_tarificacion.csv"
                            >
                               {{reportDownloadInfo.length > 0 ? ' ' + 'Descargar Información de ' +reportDownloadInfo.length + ' registros' : 'Sin registros'}} 
                            </download-csv>
                          </v-btn>
                      </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <v-footer fixed style="text-align:center; background: #f6f9ff;">
      <p style="text-align:center;width:100%;">Segurify 2022</p>
    </v-footer>
  </v-app>
</template>

<script>
import Navigation from "../../../../components/Nav.vue";
import { mainAxios } from "../../../../mainAxios";
import moment from 'moment';

export default {
  components: {
    Navigation,
  },
    data () {
      return {
        roles: JSON.parse(localStorage.getItem('roles')),
        agenteUuid: localStorage.getItem('agenteUuid'),
        reportDownloadInfo:[],
        dates: ["", ""],
        bloquear: true,
      }
    },
    
  methods: {    
    downloadComparadorReport() {

    },

    consultar(){
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios.post('/v1/reporte/cotizaciones/tarificacion',{
            "fechas": this.dates,
          }, config)
          .then((response) => {
            this.reportDownloadInfo = [];
            response.data.map(element => {
              if(this.canDowloadCompleateReport){
                this.reportDownloadInfo.push({
                  "Cotización": element.cotizacion ? element.cotizacion : 'No disponible',
                  "Nombre": element.nombre ? element.nombre : 'No disponible',
                  "Apellido": element.apellido ? element.apellido : 'No disponible',
                  "Teléfono": element.telefono ? element.telefono : 'No disponible',
                  "Aseguradora": element.aseguradora ? element.aseguradora : 'No disponible',
                  "Fecha Estimada de contratación": element.fechaEstimada ? element.fechaEstimada : 'No disponible',
                  "Correo": element.correo ? element.correo : 'No disponible',
                  "Género": element.genero ? element.genero : 'No disponible',
                  "Fecha Nacimiento": element.fechaNacimiento ? element.fechaNacimiento : 'No disponible',
                  "Código Postal": element.codigoPostal ? element.codigoPostal : 'No disponible',
                  "Fecha": element.fecha ?  moment.utc(element.fecha).subtract(6, 'hours').format('DD/MM/YYYY HH:mm:ss') : 'No disponible',
                  "Marca": element.marca ?  element.marca : 'No disponible',
                  "Submarca": element.submarca ?  element.submarca : 'No disponible',
                  "Versión": element.version ?  element.version : 'No disponible',
                  "Modelo": element.modelo ?  element.modelo : 'No disponible',
                  "Qualitas": element.qualitas ?  element.qualitas : 'No disponible',
                  "GNP": element.gnp ?  element.gnp : 'No disponible',
                  "AIG": element.aig ?  element.aig : 'No disponible',
                })
              }else{
                this.reportDownloadInfo.push({
                  "Cotización": element.cotizacion ? element.cotizacion : 'No disponible',
                  "Teléfono": element.telefono ? element.telefono : 'No disponible',
                  "Aseguradora": element.aseguradora ? element.aseguradora : 'No disponible',
                  "Fecha Estimada de contratación": element.fechaEstimada ? element.fechaEstimada : 'No disponible',
                  "Género": element.genero ? element.genero : 'No disponible',
                  "Fecha Nacimiento": element.fechaNacimiento ? element.fechaNacimiento : 'No disponible',
                  "Código Postal": element.codigoPostal ? element.codigoPostal : 'No disponible',
                  "Fecha": element.fecha ?  moment.utc(element.fecha).subtract(6, 'hours').format('DD/MM/YYYY HH:mm:ss') : 'No disponible',
                  "Marca": element.marca ?  element.marca : 'No disponible',
                  "Submarca": element.submarca ?  element.submarca : 'No disponible',
                  "Versión": element.version ?  element.version : 'No disponible',
                  "Modelo": element.modelo ?  element.modelo : 'No disponible',
                  "Qualitas": element.qualitas ?  element.qualitas : 'No disponible',
                  "GNP": element.gnp ?  element.gnp : 'No disponible',
                  "AIG": element.aig ?  element.aig : 'No disponible',
                })
              }
            })
            if(this.reportDownloadInfo.length > 0){
              this.bloquear = false;
            }
          });
      },
    },
    computed: {
      blockBoton() {
      return(
          this.dates != ["", ""]
      )   
      },
      canDowloadCompleateReport(){
        var canDownload = false
        var roles = [];
        
        this.roles.forEach((element) => {
          roles.push(element.rol)
        });
        const rolesCompleateReport = [
          "ADMINCC",
          "MANAGERCC",
          "DIRECTOR",
          "ADMIN"
        ];
        roles.forEach((element)=>{
          if(rolesCompleateReport.includes(element)){
            canDownload=true;
          }
        })
        return canDownload;
      },
    }

};
</script>
