<template>
  <v-app>
    <Navigation></Navigation>
    <v-main>
      <v-container fluid>
        <!-- HEAD TITLE -->
        <v-card color="grey lighten-4" flat tile style="margin-top: 20px">
          <v-toolbar>
            <v-toolbar-title>
              <h2>Poliza #{{ poliza.id }}</h2>
            </v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn @click="goBack()">
              <v-icon style="margin-right: 5px">mdi-arrow-left</v-icon>Regresar
            </v-btn>
          </v-toolbar>
        </v-card>
        <v-row>
          <v-col cols="8">
            <v-form>
              <v-row>
                <v-col>
                  <v-stepper v-model="stepper" non-linear>
                    <v-stepper-header class="d-flex justify-space-around">
                      <v-stepper-step step="1" editable>
                        Datos de la Póliza
                      </v-stepper-step>

                      <v-stepper-step step="2" editable>
                        Datos del Cliente y Generales
                      </v-stepper-step>
                    </v-stepper-header>

                    <v-stepper-items>
                      <!-- 1ST ITEM (FORM) -->
                      <v-stepper-content step="1">
                        <v-row style="padding: 20px; margin-top: -35px">
                          <v-col cols="4">
                            <!-- <v-text-field label="No. Póliza" v-model="poliza.poliza" :readonly="updateCuotas()" /> -->
                            <v-text-field
                              label="No. Póliza"
                              v-model="poliza.poliza"
                              :readonly="canEditThird"
                            />
                          </v-col>

                          <v-col cols="4">
                            <v-text-field
                              label="Poliza ID Interna"
                              v-model="poliza.id"
                              readonly
                            ></v-text-field>
                          </v-col>

                          <v-col cols="4">
                            <!-- <v-select label="Tipo de Póliza" v-model="poliza.type" :items="typePolizaList" :readonly="poliza.status > 0" /> -->
                            <v-select
                              label="Tipo de Póliza"
                              v-model="poliza.type"
                              :items="typePolizaList"
                              :readonly="canEditThird"
                            />
                          </v-col>

                          <v-col cols="4">
                            <!-- este no se edita -->
                            <v-select
                              label="Estatus de la Póliza"
                              v-model="poliza.status"
                              :items="statusList"
                              item-text="name"
                              item-value="id"
                              :disabled="roles != 'ADMIN'"
                              readonly
                            />
                          </v-col>
                          <v-col cols="4">
                            <v-autocomplete
                              label="Campaña"
                              v-model="poliza.campania_id"
                              :items="campanias"
                              item-text="producto"
                              item-value="id"
                              :readonly="!comportamientoCampaniaEdit"
                            ></v-autocomplete>
                          </v-col>

                          <v-col
                            cols="4"
                            v-if="
                              (poliza.status == 3 || poliza.status == 4) &&
                                canView
                            "
                          >
                            <v-text-field
                              label="Póliza renovada por"
                              v-model="anterior"
                              :readonly="
                                poliza.status != 3 && !canOptionsPolicies
                              "
                            />
                          </v-col>

                          <v-col cols="4">
                            <!-- <v-text-field label="No. Cotización / Lead ID" v-model="poliza.leadId" :readonly="poliza.status > 0" /> -->
                            <v-text-field
                              label="No. Cotización / Lead ID"
                              v-model="poliza.leadId"
                              :readonly="canEditThird"
                            />
                          </v-col>

                          <!-- INICIO DE CHECKS -->
                          <!-- <v-col cols="12" v-if="poliza.status == 4">
                            <v-checkbox label="Renovada" v-model="poliza.checkRenovada"></v-checkbox>
                          </v-col> -->
                          <v-col cols="6" v-if="poliza.status == 0 && canEdit">
                            <v-checkbox
                              label="Póliza emitida"
                              v-model="poliza.emitidaCheck"
                            ></v-checkbox>
                          </v-col>

                          <v-col cols="6" v-if="poliza.status == 1">
                            <v-text-field
                              v-model="poliza.emitidDate"
                              label="Fecha de Emisión"
                              prepend-icon="mdi-calendar"
                              v-mask="'##/##/####'"
                              placeholder="Requerido"
                              :rules="inputsRequeridos"
                              readonly
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6"> </v-col>

                          <v-row>
                            <v-col
                              cols="4"
                              v-if="
                                (poliza.status == 0 ||
                                  poliza.status == 1 ||
                                  poliza.status == 2 ||
                                  poliza.status == 4) &&
                                  canEdit
                              "
                            >
                              <v-checkbox
                                label="Póliza Cancelada"
                                v-model="poliza.canceladaCheck"
                              ></v-checkbox>
                            </v-col>

                            <v-col
                              cols="4"
                              v-if="
                                (poliza.status == 0 ||
                                  poliza.status == 1 ||
                                  poliza.status == 2 ||
                                  poliza.status == 3) &&
                                  canEdit
                              "
                            >
                              <v-checkbox
                                label="Póliza Duplicada"
                                v-model="poliza.checkPolizaDuplicada"
                              ></v-checkbox>
                            </v-col>

                            <v-col
                              cols="4"
                              v-if="
                                poliza.status == 1 ||
                                  poliza.status == 2 ||
                                  poliza.status == 3 ||
                                  poliza.status == 4
                              "
                            >
                              <v-checkbox
                                label="Generar Cuotas nuevamente"
                                v-model="poliza.checkGenerarCuotas"
                                :disabled="poliza.status < 1"
                                v-show="canEdit"
                              ></v-checkbox>
                            </v-col>
                          </v-row>

                          <v-col cols="4" v-if="poliza.status == 6">
                            <v-text-field
                              v-model="poliza.canceladaDate"
                              label="Fecha de Cancelación"
                              prepend-icon="mdi-calendar"
                              v-mask="'##/##/####'"
                              placeholder="Requerido"
                              :rules="inputsRequeridos"
                            ></v-text-field>
                          </v-col>

                          <v-col cols="12" v-if="poliza.status == 3">
                            <v-checkbox
                              label="Póliza Finalizada"
                              v-model="poliza.finalizadaCheck"
                            ></v-checkbox>
                          </v-col>

                          <v-col
                            cols="12"
                            v-if="
                              poliza.canceladaCheck ||
                                poliza.finalizadaCheck ||
                                poliza.checkPolizaDuplicada ||
                                poliza.status == 5 || poliza.status == 6
                            "
                          >
                            <v-select
                              label="Motivo de Cancelación / Finalización / Duplicidad"
                              v-model="poliza.canceladaFinalizadaMotivo"
                              :items="cancelacionFinalizacionList"
                              item-text="name"
                              item-value="name"
                            ></v-select>
                          </v-col>

                          <v-col
                            cols="12"
                            v-if="poliza.status == 6 && canOptionsPolicies"
                          >
                            <v-checkbox
                              label="Reactivar Póliza"
                              v-model="poliza.checkReactivarPoliza"
                            ></v-checkbox>
                          </v-col>

                          <v-col cols="4" v-if="poliza.status == 5">
                            <v-text-field
                              v-model="poliza.finalizadaDate"
                              label="Fecha de Finalización"
                              prepend-icon="mdi-calendar"
                              v-mask="'##/##/####'"
                              placeholder="Requerido"
                              :rules="inputsRequeridos"
                            ></v-text-field>
                          </v-col>

                          <!-- <v-col cols="6" v-if="poliza.finalizadaCheck">
                            <v-textarea
                              label="Motivo de Finalización"
                              v-model="poliza.finalizadaMotivo"
                              rows="1"
                              auto-grow
                            ></v-textarea>
                          </v-col> -->

                          <!-- FINALIZACION DE CHECKS -->
                          <v-row style="padding: 15px">
                            <v-col cols="4">
                              <v-text-field
                                label="Cliente"
                                v-model="poliza.newCliente"
                                readonly
                                @click="verCliente()"
                                class="enlacesForms"
                              ></v-text-field>
                            </v-col>

                            <v-col cols="4">
                              <v-autocomplete
                                label="Agente"
                                v-model="poliza.agente"
                                :items="agentes"
                                item-text="nombre"
                                item-value="id"
                                :readonly="canEditThird"
                                @change="esDirecto()"
                              />
                            </v-col>
                            <v-col cols="4">
                              <v-select
                                label="Ramo"
                                :items="categoria"
                                v-model="poliza.categoriaSelected"
                                placeholder="Requerido"
                                :rules="inputsRequeridos"
                              ></v-select>
                            </v-col>
                            <v-col cols="4" v-if="esAgentDirecto">
                              <v-autocomplete
                                label="Comercial Asignado"
                                :items="comercialAsignadoList"
                                item-text="nombre"
                                item-value="id"
                                v-model="comercialAsignado"
                                placeholder="Requerido"
                                :rules="inputsRequeridos"
                              ></v-autocomplete>
                            </v-col>

                            <v-col cols="4">
                              <!-- <v-autocomplete label="Productos" v-model="poliza.productos" :items="productos"
                                item-text="nombre" item-value="id" :readonly="updateCuotas()"
                                @change="obtenerRamo(poliza.productos), obtenerAseguradoraId(poliza.productos)" /> -->
                              <v-autocomplete
                                label="Productos"
                                v-model="poliza.productos"
                                :items="productos"
                                item-text="nombre"
                                item-value="id"
                                :readonly="canEditThird || editarProducto"
                                @change="
                                  obtenerRamo(poliza.productos),
                                    obtenerAseguradoraId(poliza.productos)
                                "
                              />
                            </v-col>

                            <v-col cols="4">
                              <!-- :readonly="poliza.status > 0" -->
                              <v-menu
                                v-model="menuInicioVigencia"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="auto"
                                :readonly="canEditThird"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    v-model="dateFormattedInicioVigencia"
                                    label="Fecha inicio de vigencia"
                                    persistent-hint
                                    prepend-icon="mdi-calendar"
                                    v-bind="attrs"
                                    @blur="
                                      comboInicioVigencia = parseDate(
                                        dateFormattedInicioVigencia
                                      )
                                    "
                                    v-on="on"
                                    v-mask="'##/##/####'"
                                  ></v-text-field>
                                </template>
                                <v-date-picker
                                  @input="menuInicioVigencia = false"
                                  v-model="comboInicioVigencia"
                                  no-title
                                ></v-date-picker>
                              </v-menu>
                            </v-col>
                            <v-col cols="4">
                              <v-menu
                                ref="menuFinVigencia"
                                v-model="menuFinVigencia"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="auto"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    v-model="dateFormattedFinVigencia"
                                    label="Fecha fin de vigencia"
                                    persistent-hint
                                    prepend-icon="mdi-calendar"
                                    v-bind="attrs"
                                    @blur="
                                      comboFinVigencia = parseDate(
                                        dateFormattedFinVigencia
                                      )
                                    "
                                    v-on="on"
                                    v-mask="'##/##/####'"
                                  ></v-text-field>
                                </template>
                                <v-date-picker
                                  v-model="comboFinVigencia"
                                  no-title
                                  @input="menuFinVigencia = false"
                                >
                                </v-date-picker>
                              </v-menu>
                            </v-col>
                            <v-col cols="4">
                              <!-- <v-select label="Periodicidad de pago" v-model="poliza.periodicidad"
                                :items="periodicidadList" item-text="name" item-value="id" :readonly="updateCuotas()" /> -->

                              <v-select
                                label="Periodicidad de pago"
                                v-model="poliza.periodicidad"
                                :items="periodicidadList"
                                item-text="name"
                                item-value="id"
                                :readonly="canEditThird"
                              />
                            </v-col>
                            <v-col cols="4">
                              <!-- <v-text-field label="Años" v-model="years" type="number" :readonly="updateCuotas()" /> -->
                              <v-text-field
                                label="Años"
                                v-model="years"
                                type="number"
                                :readonly="canEditThird"
                              />
                            </v-col>

                            <v-col cols="4">
                              <v-text-field
                                label="Número de cuotas"
                                v-model="poliza.numeroCuotas"
                                type="number"
                                readonly
                              ></v-text-field>
                            </v-col>
                            <v-col cols="4" v-if="ramo != 'AHORRO'">
                              <!-- <v-text-field label="Prima neta" v-model="poliza.primaNeta" :rules="inputsRequeridos"
                                :readonly="updateCuotas()" /> -->

                              <v-text-field
                                label="Prima neta"
                                v-model="poliza.primaNeta"
                                :rules="inputsRequeridos"
                                :readonly="canEditThird"
                              />
                            </v-col>
                            <v-col cols="4" v-if="ramo != 'AHORRO'">
                              <!-- <v-text-field label="Prima total" v-model="poliza.primaTotal" :rules="inputsRequeridos"
                                :readonly="updateCuotas()" /> -->

                              <v-text-field
                                label="Prima total"
                                v-model="poliza.primaTotal"
                                :rules="inputsRequeridos"
                                :readonly="canEditThird"
                              />
                            </v-col>
                            <v-col cols="4">
                              <!-- <v-select label="Forma de Pago" v-model="poliza.formaPago" :items="formaPagoList"
                                item-text="name" item-value="id" :readonly="updateCuotas()" /> -->

                              <v-select
                                label="Forma de Pago"
                                v-model="poliza.formaPago"
                                :items="formaPagoList"
                                item-text="name"
                                item-value="id"
                                :readonly="canEditThird"
                              />
                            </v-col>
                            <v-col cols="4" v-if="ramo != 'AHORRO'">
                              <!-- <v-text-field label="Monto de Primer Pago" v-model="poliza.montoPrimerPago"
                                :rules="inputsRequeridos" :readonly="updateCuotas()" /> -->

                              <v-text-field
                                label="Monto de Primer Pago"
                                v-model="poliza.montoPrimerPago"
                                :rules="inputsRequeridos"
                                :readonly="canEditThird"
                              />
                            </v-col>

                            <v-col
                              cols="4"
                              v-if="
                                (poliza.periodicidad != 1 &&
                                  ramo != 'AHORRO') ||
                                  (poliza.periodicidad == 1 && years > 1)
                              "
                            >
                              <!-- <v-text-field label="Monto de Pago Subsecuente" v-model="poliza.montoSubsecuente"
                                :rules="inputsRequeridos" :readonly="updateCuotas()" /> -->

                              <v-text-field
                                label="Monto de Pago Subsecuente"
                                v-model="poliza.montoSubsecuente"
                                :rules="inputsRequeridos"
                                :readonly="canEditThird"
                              />
                            </v-col>
                            <v-col cols="4">
                              <v-menu
                                ref="menu1"
                                v-model="menu1"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="auto"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    v-model="dateFormattedPrimerPago"
                                    label="Fecha primer pago"
                                    persistent-hint
                                    prepend-icon="mdi-calendar"
                                    v-bind="attrs"
                                    @blur="
                                      comboPrimerPagoDate = parseDate(
                                        dateFormattedPrimerPago
                                      )
                                    "
                                    v-on="on"
                                    v-mask="'##/##/####'"
                                  ></v-text-field>
                                </template>
                                <v-date-picker
                                  v-model="comboPrimerPagoDate"
                                  no-title
                                  @input="menu1 = false"
                                >
                                </v-date-picker>
                              </v-menu>
                            </v-col>
                            <v-col cols="4" v-if="poliza.solicitudRelacionada">
                              <v-text-field
                                label="No. Solicitud relacionada"
                                v-model="poliza.solicitudRelacionada"
                                class="enlacesForms"
                                @click="verSolicitud()"
                                readonly
                              ></v-text-field>
                            </v-col>
                            <!-- <v-col cols="6">
                              <v-checkbox
                                label="Primer Pago de la Póliza Realizado"
                                v-model="poliza.primerPagoCheck"
                              ></v-checkbox>
                            </v-col> -->
                            <!-- <v-col cols="6">
                              <v-text-field
                                v-model="poliza.primerPagoDate"
                                label="Fecha de Primer Pago de la Póliza Realizado"
                                prepend-icon="mdi-calendar"
                                v-mask="'##/##/####'"
                                placeholder="Requerido"
                                :rules="inputsRequeridos"
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </v-col> -->
                            <v-col cols="12">
                              <v-text-field
                                label="Notas de la Póliza"
                                v-model="poliza.notas"
                                :readonly="poliza.status > 0"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="6">
                              <v-text-field
                                label="Creado por"
                                v-model="poliza.creadoPor"
                                readonly
                              ></v-text-field>
                            </v-col>
                            <v-col cols="6">
                              <v-text-field
                                v-model="poliza.creadoPorDate"
                                label="Fecha de Creación"
                                prepend-icon="mdi-calendar"
                                placeholder="Requerido"
                                readonly
                              ></v-text-field>
                            </v-col>

                            <v-col cols="6">
                              <v-text-field
                                label="Modificado por"
                                v-model="poliza.ultimaModoficacion"
                                readonly
                              >
                              </v-text-field>
                            </v-col>
                            <v-col cols="6">
                              <v-text-field
                                v-model="poliza.ultimaModoficacionDate"
                                label="Última Modificación"
                                prepend-icon="mdi-calendar"
                                placeholder="Requerido"
                                readonly
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-row>
                        <!-- ENDS HERE -->

                        <v-btn
                          color="primary"
                          @click="actualizarPoliza()"
                          :disabled="!comportamientoBotonGuardar()"
                          :loading="loading"
                        >
                          Guardar
                        </v-btn>
                        <v-btn
                          color="primary"
                          style="margin-left: 15px"
                          @click="stepper = 2"
                        >
                          Siguiente
                        </v-btn>
                      </v-stepper-content>

                      <!-- 2ND ITEM (2ND FORM) -->
                      <v-stepper-content step="2">
                        <v-row
                          style="padding: 20px; margin-top: -35px; padding-bottom: 0px;"
                        >
                          <v-col cols="4">
                            <v-select
                              label="Tipo de Persona (Régimen)"
                              v-model="poliza.regimen"
                              :items="regimentList"
                              :rules="inputsRequeridos"
                            ></v-select>
                          </v-col>
                        </v-row>
                        <v-row
                          style="padding: 20px; margin-top: -35px; padding-bottom: 0px;"
                        >
                          <v-col
                            cols="4"
                            v-if="
                              poliza.regimen == 'Moral' &&
                                poliza.regimen != null
                            "
                          >
                            <v-text-field
                              label="Razón Social"
                              v-model="poliza.razonSocial"
                              :rules="inputsRequeridos"
                            ></v-text-field>
                          </v-col>
                          <v-col
                            cols="4"
                            v-if="
                              poliza.regimen == 'Moral' &&
                                poliza.regimen != null
                            "
                          >
                            <v-text-field
                              v-model="poliza.rfc"
                              label="RFC"
                              placeholder="Requerido"
                              :rules="rfcRules"
                            ></v-text-field>
                          </v-col>
                          <v-col
                            cols="4"
                            v-if="
                              poliza.regimen != 'Moral' &&
                                poliza.regimen != null
                            "
                          >
                            <v-text-field
                              label="Nombre(s)"
                              v-model="poliza.nombres"
                              :rules="inputsRequeridos"
                            ></v-text-field>
                          </v-col>
                          <v-col
                            cols="4"
                            v-if="
                              poliza.regimen != 'Moral' &&
                                poliza.regimen != null
                            "
                          >
                            <v-text-field
                              label="Apellido Paterno"
                              v-model="poliza.apellidoPaterno"
                              :rules="inputsRequeridos"
                            ></v-text-field>
                          </v-col>
                          <v-col
                            cols="4"
                            v-if="
                              poliza.regimen != 'Moral' &&
                                poliza.regimen != null
                            "
                          >
                            <v-text-field
                              label="Apellido Materno"
                              v-model="poliza.apellidoMaterno"
                            ></v-text-field>
                          </v-col>
                          <v-col
                            cols="6"
                            v-if="
                              poliza.regimen != 'Moral' &&
                                poliza.regimen != null
                            "
                          >
                            <v-menu
                              ref="menuFechaNacimiento"
                              v-model="menuFechaNacimiento"
                              :close-on-content-click="false"
                              transition="scale-transition"
                              offset-y
                              max-width="290px"
                              min-width="auto"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="dateFormattedFechaNacimiento"
                                  label="Fecha de Nacimiento"
                                  persistent-hint
                                  prepend-icon="mdi-calendar"
                                  v-bind="attrs"
                                  @blur="
                                    comboFechaNacimiento = parseDate(
                                      dateFormattedFechaNacimiento
                                    )
                                  "
                                  v-on="on"
                                  v-mask="'##/##/####'"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="comboFechaNacimiento"
                                no-title
                                @input="menuFechaNacimiento = false"
                              ></v-date-picker>
                            </v-menu>
                          </v-col>
                          <v-col
                            cols="6"
                            v-if="
                              poliza.regimen != 'Moral' &&
                                poliza.regimen != null
                            "
                          >
                            <v-text-field
                              v-model="poliza.rfc"
                              label="RFC"
                              placeholder="Requerido"
                              :rules="rfcRules"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4" v-if="poliza.regimen != 'Moral'">
                            <v-text-field
                              v-model="poliza.edad"
                              label="Edad"
                              placeholder="Requerido"
                            ></v-text-field>
                          </v-col>
                          <v-col
                            cols="4"
                            v-if="
                              poliza.regimen != 'Moral' &&
                                poliza.regimen != null
                            "
                          >
                            <v-select
                              label="Género"
                              v-model="poliza.selectedGenero"
                              :items="generoList"
                              :rules="inputsRequeridos"
                            ></v-select>
                          </v-col>
                          <v-col cols="4" v-if="poliza.regimen != 'Moral'">
                            <v-select
                              label="Estado civil"
                              :items="['Soltero(a)', 'Casado(a)']"
                              v-model="poliza.statusCivil"
                              :rules="inputsRequeridos"
                            ></v-select>
                          </v-col>
                          <v-col cols="4">
                            <v-text-field
                              label="Teléfono"
                              v-model="poliza.celular"
                              v-mask="'##########'"
                              :rules="inputsRequeridos"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-autocomplete
                              v-model="poliza.correoElectronico"
                              label="Correo Electrónico"
                              :items="prediccionesCorreo"
                              :filter="filtroCorreo"
                              :rules="emailRules"
                              :append-icon="null"
                            ></v-autocomplete>
                          </v-col>

                          <v-col cols="4">
                            <v-text-field
                              label="Código Postal"
                              v-model="poliza.codigoPostal"
                              v-mask="'#####'"
                              :rules="inputsRequeridos"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-text-field
                              label="Calle"
                              v-model="poliza.calle"
                              placeholder="Requerido"
                            >
                            </v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-text-field
                              label="Numero Exteriror"
                              v-model="poliza.num_exterior"
                              placeholder="Requerido"
                            >
                            </v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-text-field
                              label="Numero interior"
                              v-model="poliza.num_interior"
                            >
                            </v-text-field>
                          </v-col>
                          <v-col cols="3">
                            <v-autocomplete
                              label="Colonia"
                              placeholder="Requerido"
                              :items="colonias"
                              item-text="nombreColonia"
                              item-value="nombreColonia"
                              v-model="poliza.colonia"
                            >
                            </v-autocomplete>
                          </v-col>
                          <v-col cols="3">
                            <v-text-field
                              label="Ciudad"
                              v-model="poliza.ciudad"
                              readonly
                              placeholder="Requerido"
                            >
                            </v-text-field>
                          </v-col>
                          <v-col cols="6" v-if="poliza.regimen != null">
                            <v-select
                              label="Estado de la República"
                              v-model="poliza.estadoRepublicaSelected"
                              :items="estados"
                              item-text="name"
                              item-value="id"
                              :rules="inputsRequeridos"
                            ></v-select>
                          </v-col>

                          <v-col cols="8">
                            <v-textarea
                              label="Dirección"
                              v-model="poliza.direccion"
                              rows="1"
                              auto-grow
                            ></v-textarea>
                          </v-col>
                        </v-row>
                        <FormAutos
                          :infoEmision="ramoAuto"
                          :avisar="onResultados"
                          v-if="ramo == 'AUTOS'"
                        />
                        <FormDanios
                          :infoEmision="ramoDanios"
                          :avisar="onResultados"
                          v-if="ramo == 'DAÑOS'"
                        />
                        <FormSalud
                          :infoEmision="ramoSalud"
                          :avisar="onResultados"
                          v-if="ramo == 'SALUD'"
                        />
                        <FormVida
                          :infoEmision="ramoVida"
                          :avisar="onResultados"
                          v-if="ramo == 'VIDA'"
                        />
                        <FormAhorro
                          :infoEmision="ramoAhorro"
                          :avisar="onResultados"
                          v-if="ramo == 'AHORRO'"
                        />
                        <FormFianzas
                          :infoEmision="ramoFianzas"
                          :avisar="onResultados"
                          v-if="ramo == 'FIANZAS'"
                        />
                        <FormTecnologia
                          :infoEmision="ramoTecnologia"
                          :avisar="onResultados"
                          v-if="ramo == 'TECNOLOGIA'"
                        />

                        <v-btn
                          color="primary"
                          @click="actualizarPoliza()"
                          :disabled="!comportamientoBotonGuardar()"
                          :loading="loading"
                        >
                          Guardar
                        </v-btn>

                        <v-btn text @click="stepper = 1"> Regresar </v-btn>
                      </v-stepper-content>
                    </v-stepper-items>
                  </v-stepper>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>

        <!-- ALERT MODAL -->
        <v-col cols="auto">
          <v-dialog
            transition="dialog-bottom-transition"
            max-width="400"
            v-model="actualizadaExito"
          >
            <template v-slot:default="actualizadaExito">
              <v-card>
                <v-toolbar color="success" dark
                  >Confirmación de envío</v-toolbar
                >
                <v-card-text>
                  <div class="text-h4" style="margin-top: 10px; ">
                    Actualización de Poliza con exitoso!
                  </div>
                </v-card-text>
                <v-card-actions class="justify-end">
                  <v-btn
                    text
                    @click="
                      (actualizadaExito.value = false),
                        $router.push(
                          `/seguimiento-solicitud/${this.$route.params.id}`
                        )
                    "
                  >
                    Cerrar</v-btn
                  >
                </v-card-actions>
              </v-card>
            </template>
          </v-dialog>
        </v-col>
        <!-- END OF ALERT MODAL -->
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Navigation from "../../../components/Nav.vue";
import { mainAxios } from "../../../mainAxios";
import FormAutos from "./update/FormAutos.vue";
import FormDanios from "./update/FormDanios.vue";
import FormSalud from "./update/FormSalud.vue";
import FormVida from "./update/FormVida.vue";
import FormAhorro from "./update/FormAhorro.vue";
import FormFianzas from "./update/FormFianzas.vue";
import FormTecnologia from "./update/FormTecnologia.vue";
var moment = require("moment-timezone");
import RfcFacil from "rfc-facil";
import {
  dominiosComunes,
  validarDominioComun,
} from "./ValidacionDominiosComunes";

export default {
  components: {
    Navigation,
    FormAutos,
    FormDanios,
    FormSalud,
    FormVida,
    FormAhorro,
    FormFianzas,
    FormTecnologia,
  },
  data() {
    return {
      loading: false,
      emailRules: [
        (v) => !!v || "E-mail es requerido",
        (v) => /.+@.+\..+/.test(v) || "E-mail debe de ser valido",
        validarDominioComun,
      ],
      rfcRules: [
        (v) =>
          (v || "").length <= 13 || "RFC No debe ser mayor de 13 caracteres",
        (v) => !!v || "Campo Requerido",
        (v) =>
          /^([A-ZÑ\x26]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))([A-Z\d]{3})?$/.test(
            v
          ) || "RFC no es valido recordar escribir en mayusculas",
        (v) => !/(XXX)$/i.test(v) || "El RFC no debe terminar en 'XXX'",
      ],
      inputsRequeridos: [(value) => !!value || "Campo Requerido"],
      stepper: 1,
      poliza: {
        checkPolizaDuplicada: false,
        poliza: null,
        id: null,
        status: null,
        type: null,
        productos: null,
        ejecutivoOperacion: null,
        cliente: null,
        newCliente: null,
        agente: null,
        newAgente: null,
        landing: null,
        leadId: null,
        aseguradoraId: null,
        emitidaCheck: null,
        emitidDate: null,
        primerPagoCheck: null,
        primerPagoDate: null,
        canceladaCheck: null,
        canceladaDate: null,
        canceladaMotivo: null,
        finalizadaCheck: null,
        finalizadaDate: null,
        finalizadaMotivo: null,
        inicioDate: null,
        finDate: null,
        periodicidad: null,
        primaNeta: null,
        primaTotal: null,
        montoPrimerPago: null,
        montoSubsecuente: null,
        formaPago: null,
        numeroCuotas: null,
        noCuotasPagadas: null,
        creadoPorDate: null,
        creadoPor: null,
        ultimaModoficacionDate: null,
        ultimaModoficacion: null,
        observaciones: null,
        nombres: null,
        apellidoPaterno: null,
        apellidoMaterno: null,
        fechaNacimiento: null,
        selectedGenero: null,
        celular: null,
        correoElectronico: null,
        estadoRepublicaSelected: null,
        codigoPostal: null,
        modelo: null,
        marca: null,
        submarca: null,
        version: null,
        detalleAuto: null,
        plan: null,
        regimen: null,
        razonSocial: null,
        origenAuto: null,
        usoAuto: null,
        noMotor: null,
        noSerie: null,
        placas: null,
        notas: null,
        rfc: null,
        canceladaFinalizadaMotivo: null,
        checkRenovada: null,
        checkGenerarCuotas: null,
        checkReactivarPoliza: null,
        operadorId: null,
        solicitudRelacionada: null,
        categoriaSelected: null,
        edad: null,
        genero: null,
        estado_civil: null,
        colonia: null,
        ciudad: null,
        calle: null,
        num_interior: null,
        num_exterior: null,
      },
      years: 0,
      yearsNumber: 0,
      anterior: null,
      actualizadaExito: false,
      emision: null,
      uuid: this.$route.params.id,
      clienteUuid: null,
      roles: JSON.parse(localStorage.getItem("roles"))[0].rol,
      rolesToView: JSON.parse(localStorage.getItem("roles")),
      estados: [
        { id: 1, name: "Aguascalientes" },
        { id: 2, name: "Baja California" },
        { id: 3, name: "Baja California Sur" },
        { id: 4, name: "Campeche" },
        { id: 5, name: "Coahuila de Zaragoza" },
        { id: 6, name: "Colima" },
        { id: 7, name: "Chiapas" },
        { id: 8, name: "Chihuahua" },
        { id: 9, name: "Ciudad de México" },
        { id: 10, name: "Durango" },
        { id: 11, name: "Guanajuato" },
        { id: 12, name: "Guerrero" },
        { id: 13, name: "Hidalgo" },
        { id: 14, name: "Jalisco" },
        { id: 15, name: "Estado de México" },
        { id: 16, name: "Michoacán de Ocampo" },
        { id: 17, name: "Morelos" },
        { id: 18, name: "Nayarit" },
        { id: 19, name: "Nuevo León" },
        { id: 20, name: "Oaxaca" },
        { id: 21, name: "Puebla" },
        { id: 22, name: "Querétaro" },
        { id: 23, name: "Quintana Roo" },
        { id: 24, name: "San Luis Potosí" },
        { id: 25, name: "Sinaloa" },
        { id: 26, name: "Sonora" },
        { id: 27, name: "Tabasco" },
        { id: 28, name: "Tamaulipas" },
        { id: 29, name: "Tlaxcala" },
        { id: 30, name: "Veracruz de Ignacio de la Llave" },
        { id: 31, name: "Yucatán" },
        { id: 32, name: "Zacatecas" },
      ],
      productos: [],
      agentes: [],
      statusList: [
        { id: 0, name: "Nueva" },
        { id: 1, name: "Emitida Pendente de Pago" },
        { id: 2, name: "Pagada Parcial" },
        { id: 3, name: "Pagada Total" },
        { id: 4, name: "Renovada" },
        { id: 5, name: "Finalizada" },
        { id: 6, name: "Cancelada" },
        { id: 8, name: "Duplicada" },
      ],
      cancelacionFinalizacionList: [
        { id: 0, name: "Falta de pago" },
        { id: 1, name: "Incremento en prima / Renovación" },
        { id: 2, name: "Mal servicio agente" },
        { id: 3, name: "Mal servicion compañía" },
        { id: 4, name: "Económicas" },
        { id: 5, name: "Inconformidad con el producto" },
        { id: 6, name: "Mejora en competencia" },
        {
          id: 7,
          name: "Ya no tiene necesidad / Fallecimiento / Cambio de Agente",
        },
        { id: 8, name: "Póliza duplicada" },
      ],
      typePolizaList: ["Nueva", "Renovación"],
      landList: [
        "AIG",
        "ANA",
        "AXA",
        "COMPARADOR",
        "COMPARADOR-ADT",
        "COMPARADOR-AUTOCOSMOS",
        "GNP",
        "HDI",
        "PROVEEDORES",
        "QUÁLITAS",
        "SGMM SEGURIFY",
        "SURA",
        "SURA MOVILIDAD",
        "SURA SGMM",
      ],
      aseguradorasList: [
        { id: 1, name: "Afirme" },
        { id: 2, name: "ANA" },
        { id: 3, name: "GNP" },
        { id: 4, name: "Primero" },
        { id: 5, name: "Qualitas" },
        { id: 6, name: "Multiva" },
        { id: 7, name: "Sura" },
        { id: 8, name: "AIG" },
        { id: 9, name: "HDI" },
        { id: 10, name: "Zurich" },
        { id: 11, name: "AXA" },
        { id: 12, name: "Banorte" },
      ],
      periodicidadList: [
        { id: 1, name: "Anual" },
        { id: 2, name: "Semestral" },
        { id: 3, name: "Trimestral" },
        { id: 4, name: "Mensual" },
      ],
      clienteList: [
        { id: 1, name: "Cliente 1" },
        { id: 2, name: "Cliente 2" },
        { id: 3, name: "Cliente 3" },
        { id: 4, name: "Cliente 4" },
      ],
      formaPagoList: [
        { id: 1, name: "Tarjeta de Crédito" },
        { id: 2, name: "Tarjeta de Débito" },
        { id: 3, name: "Depósito en banco" },
        { id: 4, name: "Transferencia" },
        { id: 5, name: "Cheque" },
        { id: 6, name: "Efectivo" },
        { id: 7, name: "Domiciliación" },
        { id: 8, name: "Cargo único" },
      ],
      generoList: ["Masculino", "Femenino"],
      planList: [
        { id: 1, name: "Amplia" },
        { id: 14, name: "Amplia-Plus" },
        { id: 2, name: "Limitada" },
        { id: 3, name: "RC" },
      ],
      regimentList: ["Física", "Moral"],
      origenAutoList: [
        "Fronterizo",
        "Importado",
        "Legalizado",
        "Nacional",
        "Salvamento",
      ],
      usoAutoList: [
        "Particular",
        "Comercial",
        "Uso con App",
        "Servicio público federal",
        "Transporte público",
        "Taxi",
      ],
      canal: [],
      comercialAsignadoList: [],
      comercialAsignado: null,
      esAgentDirecto: null,

      comboInicioVigencia: null,
      dateFormattedInicioVigencia: null,
      menuInicioVigencia: false,

      comboFinVigencia: null,
      dateFormattedFinVigencia: null,
      menuFinVigencia: false,

      comboPrimerPagoDate: null,
      dateFormattedPrimerPago: null,
      menu1: false,

      comboFechaNacimiento: null,
      dateFormattedFechaNacimiento: null,
      menuFechaNacimiento: false,

      ramo: null,
      aseguradoraId: null,
      ramoAuto: {
        modelo: null,
        marca: null,
        submarca: null,
        version: null,
        detalleAuto: null,
        origenAuto: null,
        plan: null,
        usoAuto: null,
        noMotor: null,
        noSerie: null,
        placas: null,
      },
      ramoDanios: {
        tipoInmueble: null,
        otroTipoInmueble: null,
        valorInmueble: null,
        nivelDepartamento: null,
        pisosTotales: null,
        numeroPisosAltos: null,
        sotanos: null,
        valorContenidos: null,
        valorEquipoElectronico: null,
        sumaAseguradaRC: null,
        sumaAseguradaCristales: null,
        sumaAseguradaRoboViolencia: null,
        sumaAseguradaDineroValores: null,
        sumaAseguradaObrasDeArte: null,
      },

      ramoSalud: {
        nivelHospitalario: null,
        sumaAsegurada: null,
        deducible: null,
        coaseguro: null,
        ceroDeducible: null,
        medicinaFueraHospital: null,
        emergenciaExtranjero: null,
        enfermedadesCatastroficas: null,
        coberturaExtranjero: null,
        preexistencias: null,
        proteccionDental: null,
        maternidadExtendida: null,
        coberturaNacional: null,
        reconocimientoAntiguedad: null,
        fechasParaCotizar: null,
        dependientes: null,
      },
      ramoVida: {
        plan: null,
        fumador: null,
        sumaAsegurada: null,
        muerteAccidental: null,
        perdidasOrganicas: null,
        invalidezTotal: null,
        gastosFunerarios: null,
      },
      ramoAhorro: {
        tipoPortafolios: null,
        fumador: null,
        plazoComprometido: null,
        deducibilidad: null,
        revaluableInflacion: null,
        valorPlan: null,
        montoInversion: null,
      },
      ramoFianzas: {
        contratoAdjunto: null,
      },
      ramoTecnologia: {
        modelo: null,
        precio_factura: null,
      },
      categoria: ["AUTOS", "DAÑOS", "SALUD", "VIDA", "AHORRO", "FIANZA"],
      productoOrigal: [],
      colonias: [],
      campanias: [],
      busquedaCorreo: "",
      editarProducto:false,
      idProductoOrigal:''

    };
  },
  watch: {
    dialog(val) {
      if (!val) return;

      setTimeout(() => (this.dialog = false), 4000);
    },
    anterior(val) {
      if (!val) return;

      if (val) {
        this.poliza.checkRenovada = true;
      }
    },
    comboInicioVigencia() {
      this.dateFormattedInicioVigencia = this.formatDate(
        this.comboInicioVigencia
      );
    },
    comboFinVigencia() {
      this.dateFormattedFinVigencia = this.formatDate(this.comboFinVigencia);
    },
    comboPrimerPagoDate() {
      this.dateFormattedPrimerPago = this.formatDate(this.comboPrimerPagoDate);
    },
    comboFechaNacimiento() {
      this.dateFormattedFechaNacimiento = this.formatDate(
        this.comboFechaNacimiento
      );
    },
    "poliza.agente"() {
      this.filtrarProductos();
      this.calculateRamos();
    },
    "poliza.categoriaSelected"() {
      this.filtrarProductos();
    },
    "poliza.codigoPostal"(val) {
      if (typeof val != "undefined") {
        if (val.length == 5) {
          const config = {
            headers: {
              Authorization: "Bearer " + localStorage.agenteAccessToken,
            },
          };
          mainAxios
            .get("/v1/catalogo/direccion?cp=" + val, config)
            .then((response) => {
              if (response.data.listaColonias.length >= 1) {
                this.cpFlag = true;
                this.colonias = response.data.listaColonias;
                this.poliza.estado = Number(response.data.nEstado);
                this.poliza.ciudad = response.data.nombreMunicipio;
                this.poliza.pais = "México";
              } else if (response.error == "") {
                alert("No existe ese codigo postal");
              }
            });
        }
      }
    },
    "poliza.nombres"() {
      this.actualizarRfc();
    },
    "poliza.apellidoPaterno"() {
      this.actualizarRfc();
    },
    "poliza.apellidoMaterno"() {
      this.actualizarRfc();
    },
    years(value) {
      if (value.typeof != "number") {
        this.calculateCoutas();
        this.calculateFinVigencia();
      }
    },
    "poliza.periodicidad"(value) {
      if (value.typeof != "number") this.calculateCoutas();
    },
    "poliza.correoElectronico"(v) {
      this.busquedaCorreo = v;
    },
  },
  methods: {
    calculateCoutas() {
      this.years = parseInt(this.years);
      const meses = this.years * 12;
      switch (this.poliza.periodicidad) {
        case 1:
          this.poliza.numeroCuotas = meses / 12;
          break;
        case 2:
          this.poliza.numeroCuotas = meses / 6;
          break;
        case 3:
          this.poliza.numeroCuotas = meses / 3;
          break;
        case 4:
          this.poliza.numeroCuotas = meses / 1;
          break;
        default:
          break;
      }
    },
    actualizarRfc() {
      if (
        !this.poliza.nombres ||
        !this.poliza.apellidoPaterno ||
        !this.poliza.apellidoMaterno ||
        !this.dateFormattedFechaNacimiento
      )
        return;
      const arregloNacimiento = this.dateFormattedFechaNacimiento
        .split("/")
        .map((e) => Number(e));
      if (arregloNacimiento.length < 3) return;
      const rfc = RfcFacil.forNaturalPerson({
        name: this.poliza.nombres,
        firstLastName: this.poliza.apellidoPaterno,
        secondLastName: this.poliza.apellidoMaterno,
        day: arregloNacimiento[0],
        month: arregloNacimiento[1],
        year: arregloNacimiento[2],
      });
      this.poliza.rfc = rfc
    },
    calculateFinVigencia() {
      this.dateFormattedFinVigencia = moment()
        .add(this.years, "y")
        .format("DD/MM/YYYY");
    },
    /* montaje emision */
    cargarEmision() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios.get("/v1/emision/id/" + this.uuid, config).then((value) => {
        this.emision = value.data;
        this.poliza.poliza =
          this.emision.poliza == "null" ? this.emision.id : this.emision.poliza;
        this.poliza.id = this.emision.id;
        this.poliza.status = this.emision.status;
        this.poliza.type = this.emision.tipoPoliza;
        this.poliza.productos = this.emision.productoId;
        this.poliza.ejecutivoOperacion = this.emision.operadorId;
        this.poliza.cliente = this.emision.cliente;
        this.poliza.agente = this.emision.agenteId;
        this.poliza.landing = this.emision.campania;
        this.poliza.aseguradoraId = this.emision.aseguradoraId;
        this.poliza.solicitudRelacionada = this.emision.solicitudId;
        this.poliza.leadId = null;
        this.anterior = this.emision.polizaAnterior
          ? this.emision.polizaAnterior
          : null;
        this.poliza.emitidaCheck = null;
        this.poliza.emitidDate = this.emision.fechaEmision
          ? moment(this.emision.fechaEmision, "YYYY-MM-DD").format("DD/MM/YYYY")
          : null;
        this.poliza.primerPagoCheck = null;
        this.poliza.canceladaCheck = null;
        this.poliza.canceladaDate = this.emision.fechaCancelacion
          ? moment(this.emision.fechaCancelacion, "YYYY-MM-DD").format(
              "DD/MM/YYYY"
            )
          : null;
        this.poliza.canceladaMotivo = null;
        this.poliza.finalizadaCheck = null;
        this.poliza.finalizadaDate = this.emision.fechaFinalizacion
          ? moment(this.emision.fechaFinalizacion, "YYYY-MM-DD").format(
              "DD/MM/YYYY"
            )
          : null;
        this.poliza.finalizadaMotivo = null;
        this.poliza.inicioDate = moment(
          this.emision.inicio,
          "YYYY-MM-DD"
        ).format("DD/MM/YYYY");
        //moment(this.emision.inicio).format('YYYY/MM/DD')
        this.poliza.finDate = this.emision.fin
          ? moment(this.emision.fin, "YYYY-MM-DD").format("DD/MM/YYYY")
          : null;

        this.poliza.periodicidad = this.emision.pagoId; /* == 1 ? 'Anual' */
        // :  this.emision.pagoId == 2 ? 'Semestral'
        // :  this.emision.pagoId == 3 ? 'Trimestral'
        // : 'Mensual'
        this.years = this.emision.anios ? this.emision.anios : 1;
        this.poliza.primaNeta = this.emision.primaNeta;
        this.poliza.primaTotal = this.emision.total;
        this.poliza.montoPrimerPago = this.emision.primerPago;
        this.poliza.montoSubsecuente = this.emision.subsecuentePago;
        this.poliza.formaPago = this.emision.formaPago; /* == 1 ? 'Cargo en linea' */
        // : this.formaPago == 2 ? 'Domiciliación'
        // : 'Efectivo'
        // this.poliza.numeroCuotas = this.emision.pagoId == 1 ? 1
        //                           :  this.emision.pagoId == 2 ? 2
        //                           :  this.emision.pagoId == 3 ? 4
        //                           : 6
        this.poliza.primerPagoDate = moment(
          this.emision.fechaPrimerPago
        ).format("YYYY-MM-DD");
        this.poliza.noCuotasPagadas = null;
        this.poliza.creadoPorDate = moment
          .utc(this.emision.fecha)
          .subtract(6, "hours")
          .format("DD/MM/YYYY HH:mm");
        this.poliza.creadoPor = this.emision.creadoPor
          ? this.emision.creadoPor
          : "";
        this.poliza.ultimaModoficacionDate = this.emision.ultimaModificacion
          ? moment
              .utc(this.emision.ultimaModificacion)
              .subtract(6, "hours")
              .format("DD/MM/YYYY HH:mm")
          : "";
        this.poliza.ultimaModoficacion = this.emision.modificadoPor
          ? this.emision.modificadoPor
          : "";
        this.poliza.observaciones = this.emision.observaciones;
        this.poliza.nombres =
          this.emision.nombre == "" ? null : this.emision.nombre;
        this.poliza.apellidoPaterno =
          this.emision.apellidoPaterno == ""
            ? null
            : this.emision.apellidoPaterno;
        this.poliza.apellidoMaterno =
          this.emision.apellidoMaterno == ""
            ? null
            : this.emision.apellidoMaterno;
        this.poliza.fechaNacimiento = moment(
          this.emision.fechaNacimiento
        ).format("DD/MM/YYYY");
        this.poliza.selectedGenero = this.emision.genero;
        this.poliza.celular = this.emision.telefonoContacto;
        this.poliza.correoElectronico = this.emision.correoContacto;
        this.poliza.estadoRepublicaSelected = this.emision.estadoId;
        this.poliza.codigoPostal = this.emision.codigoPostal;
        this.poliza.regimen =
          this.emision.tipoPersona == "Fisica" ||
          this.emision.tipoPersona == "Física"
            ? "Física"
            : this.emision.tipoPersona == "Moral"
            ? "Moral"
            : "";
        (this.poliza.razonSocial = this.emision.razonSocial
          ? this.emision.razonSocial
          : null),
          (this.poliza.notas = this.emision.notas);
        this.poliza.rfc = this.emision.rfc;
        this.poliza.canceladaFinalizadaMotivo = this.emision.motivoCancelacion;
        this.poliza.operadorId = this.emision.operadorId;
        this.comercialAsignado = this.emision.comercialAsignado
          ? this.emision.comercialAsignado
          : null;

        //Cargar modelo de fechas
        this.comboInicioVigencia = moment(this.emision.inicio).format(
          "YYYY-MM-DD"
        );
        this.comboFinVigencia = moment(this.emision.fin).format("YYYY-MM-DD");
        this.comboPrimerPagoDate = moment(this.emision.fechaPrimerPago).format(
          "YYYY-MM-DD"
        );
        this.comboCreacion = moment(this.emision.creadoPorDate).format(
          "YYYY-MM-DD"
        );
        this.comboModificacion = moment(
          this.emision.ultimaModoficacionDate
        ).format("YYYY-MM-DD");
        this.comboFechaNacimiento = moment(this.emision.fechaNacimiento).format(
          "YYYY-MM-DD"
        );
        this.dateFormattedInicioVigencia = this.formatDate(
          moment(this.emision.inicio).format("YYYY-MM-DD")
        );
        this.dateFormattedFinVigencia = this.formatDate(
          moment(this.emision.fin).format("YYYY-MM-DD")
        );
        this.dateFormattedPrimerPago = this.formatDate(
          moment(this.emision.fechaPrimerPago).format("YYYY-MM-DD")
        );
        this.dateFormattedCreacion = this.formatDate(
          moment(this.emision.fin).format("YYYY-MM-DD")
        );
        this.dateFormattedModificacion = this.formatDate(
          moment(this.emision.fin).format("YYYY-MM-DD")
        );
        this.dateFormattedFechaNacimiento = this.formatDate(
          moment(this.emision.fechaNacimiento).format("YYYY-MM-DD")
        );

        //Data Ramo Auto
        this.ramoAuto.modelo = this.emision.modelo ? this.emision.modelo : null;
        this.ramoAuto.marca = this.emision.marca ? this.emision.marca : null;
        this.ramoAuto.submarca = this.emision.submarca
          ? this.emision.submarca
          : null;
        this.ramoAuto.version = this.emision.nombreVehiculo
          ? this.emision.nombreVehiculo
          : null;
        this.ramoAuto.detalleAuto = this.emision.detalleAuto
          ? this.emision.detalleAuto
          : null;
        this.ramoAuto.origenAuto = this.emision.origenAuto
          ? this.emision.origenAuto
          : null;
        this.ramoAuto.usoAuto = this.emision.usoAuto
          ? this.emision.usoAuto
          : null;
        this.ramoAuto.noMotor = this.emision.numMotor
          ? this.emision.numMotor
          : null;
        this.ramoAuto.noSerie = this.emision.numSerie
          ? this.emision.numSerie
          : null;
        this.ramoAuto.placas = this.emision.placas ? this.emision.placas : null;
        this.ramoAuto.plan = JSON.parse(this.emision.requestJson).plan_id
          ? JSON.parse(this.emision.requestJson).plan_id
          : null;
        this.poliza.modelo = this.emision.modelo ? this.emision.modelo : null;
        this.poliza.marca = this.emision.marca ? this.emision.marca : null;
        this.poliza.submarca = this.emision.submarca
          ? this.emision.submarca
          : null;
        this.poliza.version = this.emision.nombreVehiculo
          ? this.emision.nombreVehiculo
          : null;
        this.poliza.detalleAuto = this.emision.detalleAuto
          ? this.emision.detalleAuto
          : null;
        this.poliza.origenAuto = this.emision.origenAuto
          ? this.emision.origenAuto
          : null;
        this.poliza.usoAuto = this.emision.usoAuto
          ? this.emision.usoAuto
          : null;
        this.poliza.noMotor = this.emision.numMotor
          ? this.emision.numMotor
          : null;
        this.poliza.noSerie = this.emision.numSerie
          ? this.emision.numSerie
          : null;
        this.poliza.placas = this.emision.placas ? this.emision.placas : null;
        this.poliza.plan =  JSON.parse(this.emision.requestJson).plan_id
          ? JSON.parse(this.emision.requestJson).plan_id
          : null;
        //Data Ramo Danios
        this.ramoDanios.tipoInmueble = JSON.parse(this.emision.requestJson)
          .tipoInmueble
          ? JSON.parse(this.emision.requestJson).tipoInmueble
          : null;
        this.ramoDanios.otroTipoInmueble = JSON.parse(this.emision.requestJson)
          .otroTipoInmueble
          ? JSON.parse(this.emision.requestJson).otroTipoInmueble
          : null;
        this.ramoDanios.valorInmueble = JSON.parse(this.emision.requestJson)
          .valorInmueble
          ? JSON.parse(this.emision.requestJson).valorInmueble
          : null;
        this.ramoDanios.nivelDepartamento = JSON.parse(this.emision.requestJson)
          .nivelDepartamento
          ? JSON.parse(this.emision.requestJson).nivelDepartamento
          : null;
        this.ramoDanios.pisosTotales = JSON.parse(this.emision.requestJson)
          .pisosTotales
          ? JSON.parse(this.emision.requestJson).pisosTotales
          : null;
        this.ramoDanios.numeroPisosAltos = JSON.parse(this.emision.requestJson)
          .numeroPisosAltos
          ? JSON.parse(this.emision.requestJson).numeroPisosAltos
          : null;
        this.ramoDanios.sotanos = JSON.parse(this.emision.requestJson).sotanos
          ? JSON.parse(this.emision.requestJson).sotanos
          : null;
        this.ramoDanios.valorContenidos = JSON.parse(this.emision.requestJson)
          .valorContenidos
          ? JSON.parse(this.emision.requestJson).valorContenidos
          : null;
        this.ramoDanios.valorEquipoElectronico = JSON.parse(
          this.emision.requestJson
        ).valorEquipoElectronico
          ? JSON.parse(this.emision.requestJson).valorEquipoElectronico
          : null;
        this.ramoDanios.sumaAseguradaRC = JSON.parse(this.emision.requestJson)
          .sumaAseguradaRC
          ? JSON.parse(this.emision.requestJson).sumaAseguradaRC
          : null;
        this.ramoDanios.sumaAseguradaCristales = JSON.parse(
          this.emision.requestJson
        ).sumaAseguradaCristales
          ? JSON.parse(this.emision.requestJson).sumaAseguradaCristales
          : null;
        this.ramoDanios.sumaAseguradaRoboViolencia = JSON.parse(
          this.emision.requestJson
        ).sumaAseguradaRoboViolencia
          ? JSON.parse(this.emision.requestJson).sumaAseguradaRoboViolencia
          : null;
        this.ramoDanios.sumaAseguradaDineroValores = JSON.parse(
          this.emision.requestJson
        ).sumaAseguradaDineroValores
          ? JSON.parse(this.emision.requestJson).sumaAseguradaDineroValores
          : null;
        this.ramoDanios.sumaAseguradaObrasDeArte = JSON.parse(
          this.emision.requestJson
        ).sumaAseguradaObrasDeArte
          ? JSON.parse(this.emision.requestJson).sumaAseguradaObrasDeArte
          : null;

        this.poliza.tipoInmueble = JSON.parse(this.emision.requestJson)
          .tipoInmueble
          ? JSON.parse(this.emision.requestJson).tipoInmueble
          : null;
        this.poliza.otroTipoInmueble = JSON.parse(this.emision.requestJson)
          .otroTipoInmueble
          ? JSON.parse(this.emision.requestJson).otroTipoInmueble
          : null;
        this.poliza.valorInmueble = JSON.parse(this.emision.requestJson)
          .valorInmueble
          ? JSON.parse(this.emision.requestJson).valorInmueble
          : null;
        this.poliza.nivelDepartamento = JSON.parse(this.emision.requestJson)
          .nivelDepartamento
          ? JSON.parse(this.emision.requestJson).nivelDepartamento
          : null;
        this.poliza.pisosTotales = JSON.parse(this.emision.requestJson)
          .pisosTotales
          ? JSON.parse(this.emision.requestJson).pisosTotales
          : null;
        this.poliza.numeroPisosAltos = JSON.parse(this.emision.requestJson)
          .numeroPisosAltos
          ? JSON.parse(this.emision.requestJson).numeroPisosAltos
          : null;
        this.poliza.sotanos = JSON.parse(this.emision.requestJson).sotanos
          ? JSON.parse(this.emision.requestJson).sotanos
          : null;
        this.poliza.valorContenidos = JSON.parse(this.emision.requestJson)
          .valorContenidos
          ? JSON.parse(this.emision.requestJson).valorContenidos
          : null;
        this.poliza.valorEquipoElectronico = JSON.parse(
          this.emision.requestJson
        ).valorEquipoElectronico
          ? JSON.parse(this.emision.requestJson).valorEquipoElectronico
          : null;
        this.poliza.sumaAseguradaRC = JSON.parse(this.emision.requestJson)
          .sumaAseguradaRC
          ? JSON.parse(this.emision.requestJson).sumaAseguradaRC
          : null;
        this.poliza.sumaAseguradaCristales = JSON.parse(
          this.emision.requestJson
        ).sumaAseguradaCristales
          ? JSON.parse(this.emision.requestJson).sumaAseguradaCristales
          : null;
        this.poliza.sumaAseguradaRoboViolencia = JSON.parse(
          this.emision.requestJson
        ).sumaAseguradaRoboViolencia
          ? JSON.parse(this.emision.requestJson).sumaAseguradaRoboViolencia
          : null;
        this.poliza.sumaAseguradaDineroValores = JSON.parse(
          this.emision.requestJson
        ).sumaAseguradaDineroValores
          ? JSON.parse(this.emision.requestJson).sumaAseguradaDineroValores
          : null;
        this.poliza.sumaAseguradaObrasDeArte = JSON.parse(
          this.emision.requestJson
        ).sumaAseguradaObrasDeArte
          ? JSON.parse(this.emision.requestJson).sumaAseguradaObrasDeArte
          : null;

        //Data Ramo Salud
        this.ramoSalud.nivelHospitalario = JSON.parse(this.emision.requestJson)
          .nivelHospitalario
          ? JSON.parse(this.emision.requestJson).nivelHospitalario
          : null;
        this.ramoSalud.sumaAsegurada = JSON.parse(this.emision.requestJson)
          .sumaAsegurada
          ? JSON.parse(this.emision.requestJson).sumaAsegurada
          : null;
        this.ramoSalud.deducible = JSON.parse(this.emision.requestJson)
          .deducible
          ? JSON.parse(this.emision.requestJson).deducible
          : null;
        this.ramoSalud.coaseguro = JSON.parse(this.emision.requestJson)
          .coaseguro
          ? JSON.parse(this.emision.requestJson).coaseguro
          : null;
        this.ramoSalud.ceroDeducible = JSON.parse(this.emision.requestJson)
          .ceroDeducible
          ? JSON.parse(this.emision.requestJson).ceroDeducible
          : null;
        this.ramoSalud.medicinaFueraHospital = JSON.parse(
          this.emision.requestJson
        ).medicinaFueraHospital
          ? JSON.parse(this.emision.requestJson).medicinaFueraHospital
          : null;
        this.ramoSalud.emergenciaExtranjero = JSON.parse(
          this.emision.requestJson
        ).emergenciaExtranjero
          ? JSON.parse(this.emision.requestJson).emergenciaExtranjero
          : null;
        this.ramoSalud.enfermedadesCatastroficas = JSON.parse(
          this.emision.requestJson
        ).enfermedadesCatastroficas
          ? JSON.parse(this.emision.requestJson).enfermedadesCatastroficas
          : null;
        this.ramoSalud.coberturaExtranjero = JSON.parse(
          this.emision.requestJson
        ).coberturaExtranjero
          ? JSON.parse(this.emision.requestJson).coberturaExtranjero
          : null;
        this.ramoSalud.preexistencias = JSON.parse(this.emision.requestJson)
          .preexistencias
          ? JSON.parse(this.emision.requestJson).preexistencias
          : null;
        this.ramoSalud.proteccionDental = JSON.parse(this.emision.requestJson)
          .proteccionDental
          ? JSON.parse(this.emision.requestJson).proteccionDental
          : null;
        this.ramoSalud.maternidadExtendida = JSON.parse(
          this.emision.requestJson
        ).maternidadExtendida
          ? JSON.parse(this.emision.requestJson).maternidadExtendida
          : null;
        this.ramoSalud.coberturaNacional = JSON.parse(this.emision.requestJson)
          .coberturaNacional
          ? JSON.parse(this.emision.requestJson).coberturaNacional
          : null;
        this.ramoSalud.reconocimientoAntiguedad = JSON.parse(
          this.emision.requestJson
        ).reconocimientoAntiguedad
          ? JSON.parse(this.emision.requestJson).reconocimientoAntiguedad
          : null;
        this.ramoSalud.fechasParaCotizar = JSON.parse(this.emision.requestJson)
          .fechasParaCotizar
          ? JSON.parse(this.emision.requestJson).fechasParaCotizar
          : null;
        this.ramoSalud.dependientes = JSON.parse(this.emision.requestJson)
          .dependientes
          ? JSON.parse(this.emision.requestJson).dependientes
          : null;

        this.poliza.nivelHospitalario = JSON.parse(this.emision.requestJson)
          .nivelHospitalario
          ? JSON.parse(this.emision.requestJson).nivelHospitalario
          : null;
        this.poliza.sumaAsegurada = JSON.parse(this.emision.requestJson)
          .sumaAsegurada
          ? JSON.parse(this.emision.requestJson).sumaAsegurada
          : null;
        this.poliza.deducible = JSON.parse(this.emision.requestJson).deducible
          ? JSON.parse(this.emision.requestJson).deducible
          : null;
        this.poliza.coaseguro = JSON.parse(this.emision.requestJson).coaseguro
          ? JSON.parse(this.emision.requestJson).coaseguro
          : null;
        this.poliza.ceroDeducible = JSON.parse(this.emision.requestJson)
          .ceroDeducible
          ? JSON.parse(this.emision.requestJson).ceroDeducible
          : null;
        this.poliza.medicinaFueraHospital = JSON.parse(this.emision.requestJson)
          .medicinaFueraHospital
          ? JSON.parse(this.emision.requestJson).medicinaFueraHospital
          : null;
        this.poliza.emergenciaExtranjero = JSON.parse(this.emision.requestJson)
          .emergenciaExtranjero
          ? JSON.parse(this.emision.requestJson).emergenciaExtranjero
          : null;
        this.poliza.enfermedadesCatastroficas = JSON.parse(
          this.emision.requestJson
        ).enfermedadesCatastroficas
          ? JSON.parse(this.emision.requestJson).enfermedadesCatastroficas
          : null;
        this.poliza.coberturaExtranjero = JSON.parse(this.emision.requestJson)
          .coberturaExtranjero
          ? JSON.parse(this.emision.requestJson).coberturaExtranjero
          : null;
        this.poliza.preexistencias = JSON.parse(this.emision.requestJson)
          .preexistencias
          ? JSON.parse(this.emision.requestJson).preexistencias
          : null;
        this.poliza.proteccionDental = JSON.parse(this.emision.requestJson)
          .proteccionDental
          ? JSON.parse(this.emision.requestJson).proteccionDental
          : null;
        this.poliza.maternidadExtendida = JSON.parse(this.emision.requestJson)
          .maternidadExtendida
          ? JSON.parse(this.emision.requestJson).maternidadExtendida
          : null;
        this.poliza.coberturaNacional = JSON.parse(this.emision.requestJson)
          .coberturaNacional
          ? JSON.parse(this.emision.requestJson).coberturaNacional
          : null;
        this.poliza.reconocimientoAntiguedad = JSON.parse(
          this.emision.requestJson
        ).reconocimientoAntiguedad
          ? JSON.parse(this.emision.requestJson).reconocimientoAntiguedad
          : null;
        this.poliza.fechasParaCotizar = JSON.parse(this.emision.requestJson)
          .fechasParaCotizar
          ? JSON.parse(this.emision.requestJson).fechasParaCotizar
          : null;
        this.poliza.dependientes = JSON.parse(this.emision.requestJson)
          .dependientes
          ? JSON.parse(this.emision.requestJson).dependientes
          : null;

        //Data Ramo Vida
        this.ramoVida.plan = JSON.parse(this.emision.requestJson).plan
          ? JSON.parse(this.emision.requestJson).plan
          : null;
        this.ramoVida.fumador = JSON.parse(this.emision.requestJson).fumador
          ? JSON.parse(this.emision.requestJson).fumador
          : null;
        this.ramoVida.sumaAsegurada = JSON.parse(this.emision.requestJson)
          .sumaAsegurada
          ? JSON.parse(this.emision.requestJson).sumaAsegurada
          : null;
        this.ramoVida.muerteAccidental = JSON.parse(this.emision.requestJson)
          .muerteAccidental
          ? JSON.parse(this.emision.requestJson).muerteAccidental
          : null;
        this.ramoVida.perdidasOrganicas = JSON.parse(this.emision.requestJson)
          .perdidasOrganicas
          ? JSON.parse(this.emision.requestJson).perdidasOrganicas
          : null;
        this.ramoVida.invalidezTotal = JSON.parse(this.emision.requestJson)
          .invalidezTotal
          ? JSON.parse(this.emision.requestJson).invalidezTotal
          : null;
        this.ramoVida.gastosFunerarios = JSON.parse(this.emision.requestJson)
          .gastosFunerarios
          ? JSON.parse(this.emision.requestJson).gastosFunerarios
          : null;
        this.poliza.fumador = JSON.parse(this.emision.requestJson).fumador
          ? JSON.parse(this.emision.requestJson).fumador
          : null;
        this.poliza.sumaAsegurada = JSON.parse(this.emision.requestJson)
          .sumaAsegurada
          ? JSON.parse(this.emision.requestJson).sumaAsegurada
          : null;
        this.poliza.muerteAccidental = JSON.parse(this.emision.requestJson)
          .muerteAccidental
          ? JSON.parse(this.emision.requestJson).muerteAccidental
          : null;
        this.poliza.perdidasOrganicas = JSON.parse(this.emision.requestJson)
          .perdidasOrganicas
          ? JSON.parse(this.emision.requestJson).perdidasOrganicas
          : null;
        this.poliza.invalidezTotal = JSON.parse(this.emision.requestJson)
          .invalidezTotal
          ? JSON.parse(this.emision.requestJson).invalidezTotal
          : null;
        this.poliza.gastosFunerarios = JSON.parse(this.emision.requestJson)
          .gastosFunerarios
          ? JSON.parse(this.emision.requestJson).gastosFunerarios
          : null;

        //Data Ramo Ahorro
        this.ramoAhorro.tipoPortafolios = JSON.parse(this.emision.requestJson)
          .tipoPortafolios
          ? JSON.parse(this.emision.requestJson).tipoPortafolios
          : null;
        this.ramoAhorro.fumador = JSON.parse(this.emision.requestJson).fumador
          ? JSON.parse(this.emision.requestJson).fumador
          : null;
        this.ramoAhorro.plazoComprometido = JSON.parse(this.emision.requestJson)
          .plazoComprometido
          ? JSON.parse(this.emision.requestJson).plazoComprometido
          : null;
        this.ramoAhorro.deducibilidad = JSON.parse(this.emision.requestJson)
          .deducibilidad
          ? JSON.parse(this.emision.requestJson).deducibilidad
          : null;
        this.ramoAhorro.revaluableInflacion = JSON.parse(
          this.emision.requestJson
        ).revaluableInflacion
          ? JSON.parse(this.emision.requestJson).revaluableInflacion
          : null;
        this.ramoAhorro.valorPlan = JSON.parse(this.emision.requestJson)
          .valorPlan
          ? JSON.parse(this.emision.requestJson).valorPlan
          : null;
        this.ramoAhorro.montoInversion = JSON.parse(this.emision.requestJson)
          .montoInversion
          ? JSON.parse(this.emision.requestJson).montoInversion
          : null;

        this.poliza.tipoPortafolios = JSON.parse(this.emision.requestJson)
          .tipoPortafolios
          ? JSON.parse(this.emision.requestJson).tipoPortafolios
          : null;
        this.poliza.fumador = JSON.parse(this.emision.requestJson).fumador
          ? JSON.parse(this.emision.requestJson).fumador
          : null;
        this.poliza.plazoComprometido = JSON.parse(this.emision.requestJson)
          .plazoComprometido
          ? JSON.parse(this.emision.requestJson).plazoComprometido
          : null;
        this.poliza.deducibilidad = JSON.parse(this.emision.requestJson)
          .deducibilidad
          ? JSON.parse(this.emision.requestJson).deducibilidad
          : null;
        this.poliza.revaluableInflacion = JSON.parse(this.emision.requestJson)
          .revaluableInflacion
          ? JSON.parse(this.emision.requestJson).revaluableInflacion
          : null;
        this.poliza.valorPlan = JSON.parse(this.emision.requestJson).valorPlan
          ? JSON.parse(this.emision.requestJson).valorPlan
          : null;
        this.poliza.montoInversion = JSON.parse(this.emision.requestJson)
          .montoInversion
          ? JSON.parse(this.emision.requestJson).montoInversion
          : null;

        //Data Ramo Fianzas
        this.ramoFianzas.contratoAdjunto = JSON.parse(this.emision.requestJson)
          .contratoAdjunto
          ? JSON.parse(this.emision.requestJson).contratoAdjunto
          : null;

        this.poliza.contratoAdjunto = JSON.parse(this.emision.requestJson)
          .contratoAdjunto
          ? JSON.parse(this.emision.requestJson).contratoAdjunto
          : null;

        //Data Ramo Tecnologia
        this.ramoTecnologia.modelo = JSON.parse(this.emision.requestJson).modelo
          ? JSON.parse(this.emision.requestJson).modelo
          : null;
        this.ramoTecnologia.precio_factura = JSON.parse(
          this.emision.requestJson
        ).precio_factura
          ? JSON.parse(this.emision.requestJson).precio_factura
          : null;

        this.poliza.modelo = JSON.parse(this.emision.requestJson).modelo
          ? JSON.parse(this.emision.requestJson).modelo
          : null;
        this.poliza.precio_factura = JSON.parse(this.emision.requestJson)
          .precio_factura
          ? JSON.parse(this.emision.requestJson).precio_factura
          : null;

        this.obtenerProductos();
        // console.warn("Pasó por Obtener Productos")
        this.obtenerCliente(this.poliza.cliente);
        // console.warn("Pasó por Obtener Cliente")
        this.obtenerAgente(this.poliza.agente);
        // console.warn("Pasó por Obtener Agentes")
        this.poliza.categoriaSelected = JSON.parse(value.data.requestJson)
          .categoriaSelected
          ? JSON.parse(value.data.requestJson).categoriaSelected
          : null;
        //campos nuevos
        let jsonParse = this.emision.requestJson
          ? JSON.parse(this.emision.requestJson)
          : {};
        this.poliza.edad = jsonParse.edad;
        this.poliza.genero = jsonParse.genero;
        this.poliza.statusCivil = jsonParse.estado_civil;
        this.poliza.codigo_postal = jsonParse.codigo_postal;
        this.poliza.colonia = jsonParse.colonia;
        this.poliza.ciudad = jsonParse.ciudad;
        this.poliza.calle = jsonParse.calle;
        this.poliza.num_exterior = jsonParse.num_exterior;
        this.poliza.num_interior = jsonParse.num_interior;
        this.poliza.campania_id = jsonParse.campania_id
          ? parseInt(jsonParse.campania_id)
          : "";
        this.poliza.leadId = this.emision.lead_id ? this.emision.lead_id : "";
      });
    },
    /* fin montaje emisio */

    goBack() {
      return this.$router.go(-1);
    },
    verSolicitud() {
      this.$router.push(
        `/seguimiento-solicitud/${this.poliza.solicitudRelacionada}`
      );
    },
    obtenerAgentes() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios.get("/v1/solicitud/agente/list", config).then((response) => {
        response.data.map((element) => {
          this.agentes.push({
            nombre: element.nombre,
            id: element.id,
            uuid: element.uuid,
            canalList: element.canalesList,
            ramosList: element.ramosList ? element.ramosList : [],
          });
        });
      });
    },
    esDirecto() {
      this.agentes.map((element) => {
        let elemento = JSON.parse(JSON.stringify(element));
        let canalList = elemento.canalList;
        if (element.id == this.poliza.agente) {
          canalList.map((elm) => {
            if (elm.id == 3) {
              this.esAgentDirecto = true;
            } else {
              this.esAgentDirecto = false;
            }
          });
        }
      });
    },
    obtenerProductos() {
      // console.log('Me estouy ejecutando');
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios
        .get("/v1/producto/list", config)
        .then((response) => {
          // console.log('Productos entró!: ', response.data);
          response.data.map((element) => {
            this.productos.push({
              nombre:
                `${element.ramo}` +
                " / " +
                `${element.nombre}` +
                " / " +
                `${element.nombreAseguradora}`,
              id: element.id,
              canal: element.canal ? element.canal : "Todos",
              ramo: element.ramo ? element.ramo : "No disponible",
              aseguradoraId: element.aseguradoraId
                ? element.aseguradoraId
                : "No disponible",
              tecnologia: element.tecnologia ? element.tecnologia : false,
              status:element.status 
            });
          });
          this.productoOrigal = this.productos;
          this.idProductoOrigal =  this.emision.productoId
          let productoSelected = this.productoOrigal.find(element => element.id === this.idProductoOrigal)
          if(productoSelected){
            this.editarProducto = !productoSelected.status
          }

          this.obtenerRamo(this.emision.productoId);
          this.obtenerAseguradoraId(this.emision.productoId);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    obtenerCliente(cliente) {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios.get(`/v1/cliente/id/${cliente}`, config).then((resp) => {
        // console.log("Cliente: ", resp.data);
        this.poliza.newCliente = resp.data.nombreCliente
          ? resp.data.nombreCliente
          : resp.data.razonSocial;
        this.clienteUuid = resp.data.uuid;
      });
    },
    verCliente() {
      this.$router.push(`/cliente-detalle/${this.clienteUuid}`);
      // window.open(this.$router.push(`/cliente-detalle/${this.clienteUuid}`), '_blank')
    },
    obtenerRamo(id) {
      let productoSelected = this.productos.find(
        (element) => element.id === id
      );
      this.ramo = productoSelected ? productoSelected.ramo : null;
    },
    obtenerAseguradoraId(id) {
      let productoSelected = this.productos.find(
        (element) => element.id === id
      );
      this.aseguradoraId = productoSelected
        ? productoSelected.aseguradoraId
        : null;
    },
    obtenerAgente(agente) {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios.get(`/v1/agente/id/${agente}`, config).then((response) => {
        // console.log("Agente: ", response.data)
        this.poliza.newAgente =
          `${response.data.nombre}` +
          " " +
          `${response.data.apellidoPaterno}` +
          " " +
          `${response.data.apellidoMaterno}`;
      });
    },
    obtenerUsersCanalDirecto() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios
        .get("/v1/solicitud/agente/directo/list", config)
        .then((response) => {
          // console.log(response.data);
          response.data.map((element) => {
            this.comercialAsignadoList.push({
              nombre: element.nombre,
              id: element.id,
              uuid: element.uuid,
            });
          });
        });
    },
    actualizarPoliza() {
      this.loading = true;
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };

      let request = {
        id: this.poliza.id,
        operador_id: this.poliza.operadorId,
        comercial_asignado: this.comercialAsignado
          ? this.comercialAsignado
          : null,
        correo_operador: "",
        forma_pago: this.poliza.formaPago,
        fecha: this.poliza.creadoPorDate
          ? moment(this.poliza.creadoPorDate, "DD/MM/YYYY").format("YYYY-MM-DD")
          : null,
        poliza: `${this.poliza.poliza}`,
        prima_neta: this.poliza.primaNeta,
        prima_total: this.poliza.primaTotal,
        primer_pago: this.poliza.montoPrimerPago,
        subsecuente_pago: this.poliza.montoSubsecuente,
        pago_id: this.poliza.periodicidad,
        aseguradora_id: this.poliza.aseguradoraId,
        estado_id: this.poliza.estadoRepublicaSelected,
        origen: "",
        inicio: this.comboInicioVigencia ? this.comboInicioVigencia : null,
        anios: this.years,
        fin: this.comboFinVigencia ? this.comboFinVigencia : null,
        campania: this.nombreCampania,
        url: "",
        status: this.poliza.status,
        status_cobro: "",
        vehiculo: this.poliza.version,
        tipo_persona: this.poliza.regimen,
        razon_social: this.poliza.razonSocial,
        telefono: this.poliza.celular,
        correo_electronico: this.poliza.correoElectronico,
        producto_id: this.poliza.productos,
        observaciones: this.poliza.observaciones,
        modificado_por: parseInt(this.poliza.ultimaModoficacion),
        nombre: this.poliza.nombres,
        apellido_paterno: this.poliza.apellidoPaterno,
        apellido_materno: this.poliza.apellidoMaterno,
        codigo_postal: this.poliza.codigoPostal,
        notas: this.poliza.notas,

        /* nuevos campos */
        tipo_poliza: this.poliza.type,
        ejecutivo_de_operaciones: this.poliza.ejecutivoOperacion,
        cliente_id: this.poliza.cliente ? this.poliza.cliente : null,
        poliza_anterior: this.anterior ? this.anterior : null,
        primer_pago_poliza: this.poliza.primerPagoCheck,
        fechaPrimerPago: this.comboPrimerPagoDate
          ? this.comboPrimerPagoDate
          : null,
        checkCancelada: this.poliza.canceladaCheck
          ? this.poliza.canceladaCheck
          : false,
        fecha_cancelacion: this.poliza.canceladaDate
          ? moment(this.poliza.canceladaDate, "DD/MM/YYYY").format("YYYY-MM-DD")
          : null,
        motivoCancelacion: this.poliza.canceladaFinalizadaMotivo
          ? this.poliza.canceladaFinalizadaMotivo
          : null,
        checkPolizaFinalizada: this.poliza.finalizadaCheck
          ? this.poliza.finalizadaCheck
          : false,
        fecha_finalizacion: this.poliza.finalizadaDate
          ? moment(this.poliza.finalizadaDate, "DD/MM/YYYY").format(
              "YYYY-MM-DD"
            )
          : null,
        motivoFinalizacion: this.poliza.finalizadaMotivo,
        checkPolizaEmitida: this.poliza.emitidaCheck
          ? this.poliza.emitidaCheck
          : false,
        fecha_emision: this.poliza.emitidDate
          ? moment(this.poliza.emitidDate, "DD/MM/YYYY").format("YYYY-MM-DD")
          : null,
        ultima_modificacion: moment(new Date()).format("YYYY-MM-DD"),
        genero: this.poliza.selectedGenero ? this.poliza.selectedGenero : null,
        fecha_nacimiento: this.comboFechaNacimiento
          ? this.comboFechaNacimiento
          : null,
        rfc: this.poliza.rfc ? this.poliza.rfc : null,
        checkGenerarCuotas: this.poliza.checkGenerarCuotas
          ? this.poliza.checkGenerarCuotas
          : false,
        checkReactivarPoliza: this.poliza.checkReactivarPoliza
          ? this.poliza.checkReactivarPoliza
          : false,
        checkPolizaRenovada: this.poliza.checkRenovada
          ? this.poliza.checkRenovada
          : false,
        agente_id: this.poliza.agente ? this.poliza.agente : null,
        checkPolizaDuplicada: this.poliza.checkPolizaDuplicada
          ? this.poliza.checkPolizaDuplicada
          : false,
        categoriaSelected: this.poliza.categoriaSelected
          ? this.poliza.categoriaSelected
          : false,
        colonia: this.poliza.colonia ? this.poliza.colonia : null,
        calle: this.poliza.calle ? this.poliza.calle : null,
        num_exterior: this.poliza.num_exterior
          ? this.poliza.num_exterior
          : null,
        num_interior: this.poliza.num_interior
          ? this.poliza.num_interior
          : null,
        estado: this.poliza.estado ? this.poliza.estado : null,
        ciudad: this.poliza.ciudad ? this.poliza.ciudad : null,
        campania_id: this.poliza.campania_id ? this.poliza.campania_id : null,
        edad: this.poliza.edad ? this.poliza.edad : null,
        direccion: this.poliza.direccion ? this.poliza.direccion : null,
        estado_civil: this.poliza.statusCivil ? this.poliza.statusCivil : null,
        cotizacion: this.poliza.leadId ? this.poliza.leadId : null,
        lead_id: this.poliza.leadId ? this.poliza.leadId : null,
      };

      if (request.lead_id == null) {
        delete request.lead_id;
      }

      switch (this.ramo) {
        case "AUTOS":
          request.plan_id = this.poliza.plan;
          (request.vehiculo = this.poliza.version),
            (request.modelo = this.poliza.modelo),
            (request.marca = this.poliza.marca),
            (request.submarca = this.poliza.submarca),
            (request.version = this.poliza.version),
            (request.detalle_auto = this.poliza.detallePoliza),
            (request.origen_auto = this.poliza.origenAuto),
            (request.uso_auto = this.poliza.usoAuto),
            (request.num_motor = this.poliza.noMotor),
            (request.num_serie = this.poliza.noSerie),
            (request.placas = this.poliza.placas);
          break;
        case "DAÑOS":
          (request.tipoInmueble = this.poliza.tipoInmueble),
            (request.valorInmueble = this.poliza.valorInmueble),
            (request.nivelDepartamento = this.poliza.nivelDepartamento),
            (request.pisosTotales = this.poliza.pisosTotales),
            (request.numeroPisosAltos = this.poliza.numeroPisosAltos),
            (request.sotanos = this.poliza.sotanos),
            (request.valorContenidos = this.poliza.valorContenidos),
            (request.valorEquipoElectronico = this.poliza.valorEquipoElectronico),
            (request.sumaAseguradaRC = this.poliza.sumaAseguradaRC),
            (request.sumaAseguradaCristales = this.poliza.sumaAseguradaCristales),
            (request.sumaAseguradaRoboViolencia = this.poliza.sumaAseguradaRoboViolencia),
            (request.sumaAseguradaDineroValores = this.poliza.sumaAseguradaDineroValores),
            (request.sumaAseguradaObrasDeArte = this.poliza.sumaAseguradaObrasDeArte);
          break;
        case "SALUD":
          request.nivelHospitalario = this.poliza.nivelHospitalario;
          request.sumaAsegurada = this.poliza.sumaAsegurada;
          request.deducible = this.poliza.deducible;
          request.coaseguro = this.poliza.coaseguro;
          request.ceroDeducible = this.poliza.ceroDeducible;
          request.medicinaFueraHospital = this.poliza.medicinaFueraHospital;
          request.emergenciaExtranjero = this.poliza.emergenciaExtranjero;
          request.enfermedadesCatastroficas = this.poliza.enfermedadesCatastroficas;
          request.coberturaExtranjero = this.poliza.coberturaExtranjero;
          request.preexistencias = this.poliza.preexistencias;
          request.proteccionDental = this.poliza.proteccionDental;
          request.maternidadExtendida = this.poliza.maternidadExtendida;
          request.coberturaNacional = this.poliza.coberturaNacional;
          request.reconocimientoAntiguedad = this.poliza.reconocimientoAntiguedad;
          request.fechasParaCotizar = this.poliza.fechasParaCotizar;
          request.dependientes = this.poliza.dependientes;
          break;
        case "VIDA":
          request.plan = this.poliza.plan;
          request.fumador = this.poliza.fumador;
          request.sumaAsegurada = this.poliza.sumaAsegurada;
          request.muerteAccidental = this.poliza.muerteAccidental;
          request.perdidasOrganicas = this.poliza.perdidasOrganicas;
          request.invalidezTotal = this.poliza.invalidezTotal;
          request.gastosFunerarios = this.poliza.gastosFunerarios;
          break;
        case "AHORRO":
          request.tipoPortafolios = this.poliza.tipoPortafolios;
          request.fumador = this.poliza.fumador;
          request.plazoComprometido = this.poliza.plazoComprometido;
          request.deducibilidad = this.poliza.deducibilidad;
          request.revaluableInflacion = this.poliza.revaluableInflacion;
          request.valorPlan = this.poliza.valorPlan;
          request.montoInversion = this.poliza.montoInversion;
          break;
        case "FIANZAS":
          request.contratoAdjunto = this.poliza.contratoAdjunto;
          break;
        case "TECNOLOGIA":
          request.modelo = this.poliza.modelo;
          request.precio_factura = this.poliza.precio_factura;
          break;
        default:
          break;
      }

      mainAxios
        .post("/v1/emision/manual/actualizar", request, config)
        .then((value) => {
          console.log("aqui vamos a ver ", value.data);
          this.loading = false;
          this.enviandoPoliza = false;
          this.polizaEnviada = true;
          this.actualizadaExito = true;
          setTimeout(() => {
            this.actualizadaExito = false;
            this.$router.push(`/emision/${this.uuid}`);
          }, 2000);
          this.guardarNota(value.data)
        });

    },
    comportamientoBotonGuardar() {
      if (
        (this.poliza.emitidaCheck || this.poliza.checkGenerarCuotas) &&
        this.poliza.periodicidad != 1
      ) {
        return this.formIsValid;
      } else if (
        (this.poliza.emitidaCheck || this.poliza.checkGenerarCuotas) &&
        this.poliza.periodicidad == 1
      ) {
        return this.formIsValidForAnualPeriodity;
      } else {
        return true;
      }
    },
    updateCuotas() {
      if (this.poliza.checkGenerarCuotas != null) {
        if (this.poliza.checkGenerarCuotas == true) {
          return false;
        } else {
          return true;
        }
      } else {
        if (this.poliza.status > 0) return true;
      }
    },
    // Datos para formulario
    onResultados(datos) {
      switch (this.ramo) {
        case "AUTOS":
          this.poliza.modelo = datos.modelo;
          this.poliza.marca = datos.marca;
          this.poliza.submarca = datos.submarca;
          this.poliza.version = datos.version;
          this.poliza.vehiculo = datos.version;
          this.poliza.detallePoliza = datos.detallePoliza;
          this.poliza.plan = datos.plan;
          this.poliza.origenAuto = datos.origenAuto;
          this.poliza.usoAuto = datos.usoAuto;
          this.poliza.noMotor = datos.noMotor;
          this.poliza.noSerie = datos.noSerie;
          this.poliza.placas = datos.placas;
          break;
        case "DAÑOS":
          this.poliza.tipoInmueble = datos.tipoInmueble;
          this.poliza.valorInmueble = datos.valorInmueble;
          this.poliza.nivelDepartamento = datos.nivelDepartamento;
          this.poliza.pisosTotales = datos.pisosTotales;
          this.poliza.numeroPisosAltos = datos.numeroPisosAltos;
          this.poliza.sotanos = datos.sotanos;
          this.poliza.valorContenidos = datos.valorContenidos;
          this.poliza.valorEquipoElectronico = datos.valorEquipoElectronico;
          this.poliza.sumaAseguradaRC = datos.sumaAseguradaRC;
          this.poliza.sumaAseguradaCristales = datos.sumaAseguradaCristales;
          this.poliza.sumaAseguradaRoboViolencia =
            datos.sumaAseguradaRoboViolencia;
          this.poliza.sumaAseguradaDineroValores =
            datos.sumaAseguradaDineroValores;
          this.poliza.sumaAseguradaObrasDeArte = datos.sumaAseguradaObrasDeArte;
          break;
        case "SALUD":
          this.poliza.nivelHospitalario = datos.nivelHospitalario;
          this.poliza.sumaAsegurada = datos.sumaAsegurada;
          this.poliza.deducible = datos.deducible;
          this.poliza.coaseguro = datos.coaseguro;
          this.poliza.ceroDeducible = datos.ceroDeducible;
          this.poliza.medicinaFueraHospital = datos.medicinaFueraHospital;
          this.poliza.emergenciaExtranjero = datos.emergenciaExtranjero;
          this.poliza.enfermedadesCatastroficas =
            datos.enfermedadesCatastroficas;
          this.poliza.coberturaExtranjero = datos.coberturaExtranjero;
          this.poliza.preexistencias = datos.preexistencias;
          this.poliza.proteccionDental = datos.proteccionDental;
          this.poliza.maternidadExtendida = datos.maternidadExtendida;
          this.poliza.coberturaNacional = datos.coberturaNacional;
          this.poliza.reconocimientoAntiguedad = datos.reconocimientoAntiguedad;
          this.poliza.fechasParaCotizar = datos.comboFechasParaCotizar;
          this.poliza.dependientes = datos.dependientes;
          break;
        case "VIDA":
          this.poliza.plan = datos.plan;
          this.poliza.fumador = datos.fumador;
          this.poliza.sumaAsegurada = datos.sumaAsegurada;
          this.poliza.muerteAccidental = datos.muerteAccidental;
          this.poliza.perdidasOrganicas = datos.perdidasOrganicas;
          this.poliza.invalidezTotal = datos.invalidezTotal;
          this.poliza.gastosFunerarios = datos.gastosFunerarios;
          break;
        case "AHORRO":
          this.poliza.tipoPortafolios = datos.tipoPortafolios;
          this.poliza.fumador = datos.fumador;
          this.poliza.plazoComprometido = datos.plazoComprometido;
          this.poliza.deducibilidad = datos.deducibilidad;
          this.poliza.revaluableInflacion = datos.revaluableInflacion;
          this.poliza.valorPlan = datos.valorPlan;
          this.poliza.primaTotal = datos.valorPlan;
          this.poliza.montoInversion = datos.montoInversion;
          this.poliza.primaNeta = datos.montoInversion;
          this.poliza.montoPrimerPago = datos.montoInversion;
          this.poliza.montoSubsecuente = datos.montoInversion;
          break;
        case "FIANZAS":
          this.poliza.contratoAdjunto = datos.contratoAdjunto;
          break;
        case "TECNOLOGIA":
          this.poliza.modelo = datos.modelo;
          this.poliza.precio_factura = datos.precio_factura;
          break;
        default:
          break;
      }
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    filtrarProductos() {
      if (this.poliza.agente != null && this.poliza.categoriaSelected != null) {
        let agenteSelected = this.agentes.find(
          (element) => element.id === this.poliza.agente
        );
        let currentCanalesName = [];
        let isOnlyMasivos = false;

        if (
          agenteSelected.canalList.length == 1 &&
          agenteSelected.canalList[0].canal == "MASIVOS"
        ) {
          isOnlyMasivos = true;
        }

        agenteSelected.canalList.map((element) => {
          currentCanalesName.push(element.canal);
        });
        this.productos = [];
        this.poliza.productoSelected = null;
        this.productoOrigal.map((element) => {
          if (element.canal == "Todos" && !isOnlyMasivos) {
            if (element.ramo == this.poliza.categoriaSelected) {
              this.productos.push({
                nombre: element.nombre,
                id: element.id,
                canal: element.canal ? element.canal : "Todos",
                ramo: element.ramo ? element.ramo : "No disponible",
                aseguradoraId: element.aseguradoraId,
                tecnologia: element.tecnologia ? element.tecnologia : false,
              });
            }
          } else {
            let result = currentCanalesName.indexOf(element.canal);
            if (result != "-1") {
              if (element.ramo == this.poliza.categoriaSelected) {
                this.productos.push({
                  nombre: element.nombre,
                  id: element.id,
                  canal: element.canal ? element.canal : "Todos",
                  ramo: element.ramo ? element.ramo : "No disponible",
                  aseguradoraId: element.aseguradoraId,
                  tecnologia: element.tecnologia ? element.tecnologia : false,
                });
              }
            }
          }
        });
      }
    },
    calculateRamos() {
      let agenteSelected = this.agentes.find(
        (element) => element.id === this.poliza.agente
      );
      let currentRamosName = [];
      agenteSelected.ramosList.map((element) => {
        currentRamosName.push(element.ramo);
      });
      this.categoria = currentRamosName;
    },
    getCampanias() {
      this.loading = true;
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios
        .get("/v1/campania/lead-segurify/list", config)
        .then((response) => {
          this.loading = false;
          this.campanias = response.data;
        });
    },
    filtroCorreo(item, queryText, itemText) {
      this.busquedaCorreo = queryText;
      return itemText.includes(queryText);
    },
    guardarNota(newEmision){
      const config = {
          headers: {
              Authorization: "Bearer " + localStorage.agenteAccessToken,
              'Content-Type': 'multipart/form-data'
        },
      };
      const formData = new FormData();
      if(this.emision.status != newEmision.status){
        formData.append('tipo', 'Nota');
        formData.append('nota', 'Cambio de estatus de poliza de '+ this.getStatusName(this.emision.status) + ' a '+ this.getStatusName(newEmision.status));
        formData.append('nombre', 'Cambio de estatus');
        formData.append('tipo_documento', null);
        mainAxios.post(`/v1/emision/documento/upload/${this.poliza.id}`, formData,config)
          .then(function (result) {
              console.log(result);
          }, function (error) {
              console.log(error);
          });
      }
      if(this.emision.pagoId != newEmision.pagoId){
        formData.append('tipo', 'Nota');
        formData.append('nota', 'Cambio de periodicidad de '+ this.getPeriodicidadName(this.emision.pagoId) + ' a '+ this.getPeriodicidadName(newEmision.pagoId));
        formData.append('nombre', 'Cambio de periodicidad');
        formData.append('tipo_documento', null);
        mainAxios.post(`/v1/emision/documento/upload/${this.poliza.id}`, formData,config)
          .then(function (result) {
              console.log(result);
          }, function (error) {
              console.log(error);
          });
      }
      if(this.comboInicioVigencia != moment(this.emision.inicio).format("YYYY-MM-DD")){
        formData.append('tipo', 'Nota');
        formData.append('nota', 'Cambio de fecha de inicio de vigencia de '+ moment(this.emision.inicio).format("YYYY-MM-DD") + ' a '+ this.comboInicioVigencia);
        formData.append('nombre', 'Cambio de fecha de inicio');
        formData.append('tipo_documento', null);
        mainAxios.post(`/v1/emision/documento/upload/${this.poliza.id}`, formData,config)
          .then(function (result) {
              console.log(result);
          }, function (error) {
              console.log(error);
          });
      }

      if(this.comboFinVigencia != moment(this.emision.fin).format("YYYY-MM-DD")){
        formData.append('tipo', 'Nota');
        formData.append('nota', 'Cambio de fecha de fin de vigencia de '+ moment(this.emision.fin).format("YYYY-MM-DD") + ' a '+ this.comboFinVigencia);
        formData.append('nombre', 'Cambio de fecha de fin');
        formData.append('tipo_documento', null);
        mainAxios.post(`/v1/emision/documento/upload/${this.poliza.id}`, formData,config)
          .then(function (result) {
              console.log(result);
          }, function (error) {
              console.log(error);
          });
      }

    },
    getStatusName(id){
      return this.statusList.find((e) => e.id == id).name;
    },
    getPeriodicidadName(id){
      return this.periodicidadList.find((e) => e.id == id).name;
    }
  },
  computed: {
    nombreCampania() {
      if (!this.poliza.campania_id) return null;
      return this.campanias.find(
        (campania) => campania.id === this.poliza.campania_id
      ).producto;
    },

    formIsValid() {
      return (
        this.poliza.poliza != null &&
        this.poliza.poliza != undefined &&
        this.poliza.poliza != "" &&
        this.poliza.primaNeta != null && this.poliza.primaNeta != undefined &&
        this.poliza.primaTotal != null && this.poliza.primaTotal != undefined &&
        this.poliza.montoPrimerPago != null &&
          this.poliza.montoPrimerPago != 0 &&
        this.poliza.montoSubsecuente != null &&
          this.poliza.montoSubsecuente != 0 &&
        this.poliza.primerPagoDate != null && this.poliza.primerPagoDate != ""
      );
    },
    formIsValidForAnualPeriodity() {
      return (
        this.poliza.poliza != null &&
        this.poliza.poliza != undefined &&
        this.poliza.poliza != "" &&
        this.poliza.primaNeta != null && this.poliza.primaNeta != undefined &&
        this.poliza.primaTotal != null && this.poliza.primaTotal != undefined &&
        this.poliza.montoPrimerPago != null &&
          this.poliza.montoPrimerPago != 0 &&
        this.poliza.primerPagoDate != null && this.poliza.primerPagoDate != ""
      );
    },
    canEdit() {
      let canView = false;
      let roles = [];
      this.rolesToView.forEach((element) => {
        roles.push(element.rol);
      });
      const rolesToView = [
        "ADMIN",
        "ADMINCC",
        "MANAGERCC",
        "OPERACIONES",
        "AGENTCC",
        "MANAGERCC",
        "COBRANZACC",
        "RENOVACIONESCC",
        "OPERADOR",
      ];
      roles.forEach((element) => {
        if (rolesToView.includes(element)) {
          canView = true;
        }
      });
      return canView;
    },
    CanEditSecondary() {
      let canView = false;
      let roles = [];
      this.rolesToView.forEach((element) => {
        roles.push(element.rol);
      });
      const rolesToView = ["ADMIN", "ADMINCC", "MANAGERCC", "OPERACIONES"];
      roles.forEach((element) => {
        if (rolesToView.includes(element)) {
          canView = true;
        }
      });
      return canView;
    },
    canEditThird() {
      let edit = true;
      let roles = [];
      this.rolesToView.forEach((element) => {
        roles.push(element.rol);
      });
      const rolesToView = [
        "ADMIN",
        "ADMINCC",
        "OPERACIONES",
        "FINANZAS",
        "OPERADOR",
        "AGENTCC",
        "OPERADORCC",
        "AGENTCCSPONSORS",
        "MANAGERCC",
      ];
      roles.forEach((element) => {
        if (rolesToView.includes(element)) {
          edit = false;
        }
      });
      return edit;
    },
    canView() {
      let canView = false;
      let roles = [];
      this.rolesToView.forEach((element) => {
        roles.push(element.rol);
      });
      const rolesToView = [
        "ADMIN",
        "ADMINCC",
        "MANAGERCC",
        "OPERACIONES",
        "AGENTCC",
        "MANAGERCC",
        "COBRANZACC",
        "RENOVACIONESCC",
        "OPERADOR",
      ];
      roles.forEach((element) => {
        if (rolesToView.includes(element)) {
          canView = true;
        }
      });
      return canView;
    },
    canOptionsPolicies() {
      let canView = false;
      let roles = [];
      this.rolesToView.forEach((element) => {
        roles.push(element.rol);
      });
      const rolesToView = [
        "ADMIN",
        "ADMINCC",
        "OPERACIONES",
        "OPERADOR",
        "MANAGERCC",
        "RENOVACIONESCC",
      ];
      roles.forEach((element) => {
        if (rolesToView.includes(element)) {
          canView = true;
        }
      });
      return canView;
    },
    prediccionesCorreo() {
      if (!this.busquedaCorreo.length) return [""];
      if (this.busquedaCorreo.includes("@")) return [this.busquedaCorreo];
      return dominiosComunes.map((e) => `${this.busquedaCorreo}@${e}`);
    },
    comportamientoCampaniaEdit(){
      let canView = false;
      let roles = [];

      if(this.poliza.status == 0){
        this.rolesToView.forEach((element) => {
          roles.push(element.rol);
        });
        const rolesToView = [
          "ADMIN",
          "ADMINCC",
          "MANAGERCC",
          "AGENTCC",
          "OPERACIONES",
          "OPERADOR",
        ];
        roles.forEach((element) => {
          if (rolesToView.includes(element)) {
            canView = true;
          }
        });
      }else{
        this.rolesToView.forEach((element) => {
          roles.push(element.rol);
        });
        const rolesToView = [
          "ADMIN",
          "ADMINCC",
          "MANAGERCC",
          "OPERACIONES",
        ];
        roles.forEach((element) => {
          if (rolesToView.includes(element)) {
            canView = true;
          }
        });
      }
      return canView;
    },
  },

  mounted() {
    this.obtenerProductos(),
      this.obtenerAgentes(),
      this.obtenerUsersCanalDirecto(),
      this.getCampanias(),
      this.cargarEmision();
  },
};
</script>
