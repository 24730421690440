<template>
  <div>
    <v-row v-if="mostrarCotizacion">
      <v-col cols="12">
        <h3 v-if="formaPago != 'anual'" class="headline mb-0" style="font-size: 30px !important;">
          Total: {{ toCurrency(pagoTotal) }}
        </h3>
      </v-col>
      <v-col cols="6">
        <h3 v-if="formaPago != 'anual'" class="headline mb-0" style="font-size: 15px !important;">
          Primer Pago:<br>{{ toCurrency(pagoPrimero) }}
        </h3>
      </v-col>
      <v-col cols="6">
        <h3 v-if="formaPago != 'anual'" class="headline mb-0" style="font-size: 15px !important;">
          Subsecuentes:<br>{{ toCurrency(pagoSubsecuente) }}
        </h3>
      </v-col>
      <v-col cols="6">
        <p class="mb-0 infoCotizacion">Cobertura:<br>{{plan == 1 ? 'Amplia' : plan == 14  ? 'Amplia-Plus' : plan == 2 ? 'Limitada' : plan == 3 ? 'RC' : "" }}</p>
      </v-col>
      <v-col cols="6">
        <p class="mb-0 infoCotizacion">Periodicidad:<br>{{formaPago}}</p>
      </v-col>
    </v-row>

    <v-row v-else>
      <v-col cols="12">
        <v-btn
          style="width: 100%"
          @click="reecotizar()"
        >
          Recotizar
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <div style="word-break:break-word;" v-for="(cobertura,index) in coberturas" v-bind:key="index">
          <v-select
              :label="cobertura.titulo"
              :items="cobertura.valores"
              item-text="valor"
              item-value="valor"
              v-model="guardarCoberturas[index]"
              name="resposavilidad"
              @change="updateCobertura()"
              disabled
            ></v-select>
        </div>
      </v-col>
      <v-col cols="12" v-if="mostrarCotizacion == false">
        <v-btn
          style="width: 100%"
          @click="reecotizar()"
        >
          Recotizar
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  props: {
    aseguradora:String, 
    plan:Number,
    pagoTotal:Number,
    pagoPrimero:Number,
    pagoSubsecuente:Number,
    formaPago:String
  },
  data() {
    return {
      coberturas:[],
      guardarCoberturas: [],
      mostrarCotizacion: true,
    };
  },
  mounted() {
    switch (this.aseguradora) {
      case "qualitas":
          switch (this.plan) {
            case 1: 
              //AMPLIA
              this.coberturas=[
                {
                  titulo: 'DAÑOS MATERIALES',
                  valores: [
                    {id: 1, valor: '3%'},
                    {id: 2, valor: '5%'},
                    {id: 3, valor: '10%'},
                    {id: 4, valor: '20%'},
                  ]
                },
                {
                  titulo: 'ROBO TOTAL',
                  valores: [
                    {id: 1, valor: '5%'},
                    {id: 2, valor: '10%'},
                    {id: 3, valor: '20%'},
                  ]
                },
                {
                  titulo: 'RESPONSABILIDAD CIVIL',
                  valores: [
                    {id: 1, valor: '3,000,000'},
                    {id: 2, valor: '4,000,000'},
                    {id: 3, valor: '5,000,000'},
                    {id: 4, valor: '6,000,000'},
                    {id: 5, valor: '7,000,000'},
                    {id: 6, valor: '8,000,000'},
                  ]
                },
                {
                  titulo: 'GASTOS MÉDICOS OCUPANTES',
                  valores: [
                    {id: 1, valor: '100,000'},
                    {id: 2, valor: '200,000'},
                    {id: 3, valor: '300,000'},
                    {id: 4, valor: '400,000'},
                    {id: 5, valor: '500,000'},
                    {id: 6, valor: '600,000'},
                    {id: 7, valor: '700,000'},
                    {id: 8, valor: '800,000'},
                    {id: 9, valor: '900,000'},
                    {id: 10, valor: '1,000,000'},
                  ]
                },
                {
                  titulo: 'POR ACCIDENTE AUTOMOVILISTICO',
                  valores: [
                    {id: 1, valor: '100,000'},
                    {id: 1, valor: '150,000'},
                    {id: 1, valor: '200,000'},
                  ]
                },
              ]
              break;
            case 4: 
              //LIMITADA
              this.coberturas=[
                {
                  titulo: 'ROBO TOTAL',
                  valores: [
                    {id: 1, valor: '5%'},
                    {id: 2, valor: '10%'},
                    {id: 3, valor: '20%'},
                  ]
                },
                {
                  titulo: 'RESPONSABILIDAD CIVIL',
                  valores: [
                    {id: 1, valor: '3,000,000'},
                    {id: 2, valor: '4,000,000'},
                    {id: 3, valor: '5,000,000'},
                    {id: 4, valor: '6,000,000'},
                    {id: 5, valor: '7,000,000'},
                    {id: 6, valor: '8,000,000'},
                  ]
                },
                {
                  titulo: 'GASTOS MÉDICOS OCUPANTES',
                  valores: [
                    {id: 1, valor: '100,000'},
                    {id: 2, valor: '200,000'},
                    {id: 3, valor: '300,000'},
                    {id: 4, valor: '400,000'},
                    {id: 5, valor: '500,000'},
                    {id: 6, valor: '600,000'},
                    {id: 7, valor: '700,000'},
                    {id: 8, valor: '800,000'},
                    {id: 9, valor: '900,000'},
                    {id: 10, valor: '1,000,000'},
                  ]
                },
                {
                  titulo: 'POR ACCIDENTE AUTOMOVILÍSTICO',
                  valores: [
                    {id: 1, valor: '100,000'},
                    {id: 2, valor: '150,000'},
                    {id: 3, valor: '200,000'},
                  ]
                },
              ]
              break;
            case 3: 
              //LIMITADA
              this.coberturas=[
                {
                  titulo: 'ROBO TOTAL',
                  valores: [
                    {id: 1, valor: '5%'},
                    {id: 2, valor: '10%'},
                    {id: 3, valor: '20%'},
                  ]
                },
                {
                  titulo: 'RESPONSABILIDAD CIVIL',
                  valores: [
                    {id: 1, valor: '3,000,000'},
                    {id: 2, valor: '4,000,000'},
                    {id: 3, valor: '5,000,000'},
                    {id: 4, valor: '6,000,000'},
                    {id: 5, valor: '7,000,000'},
                    {id: 6, valor: '8,000,000'},
                  ]
                },
                {
                  titulo: 'GASTOS MÉDICOS OCUPANTES',
                  valores: [
                    {id: 1, valor: '100,000'},
                    {id: 2, valor: '200,000'},
                    {id: 3, valor: '300,000'},
                    {id: 4, valor: '400,000'},
                    {id: 5, valor: '500,000'},
                    {id: 6, valor: '600,000'},
                    {id: 7, valor: '700,000'},
                    {id: 8, valor: '800,000'},
                    {id: 9, valor: '900,000'},
                    {id: 10, valor: '1,000,000'},
                  ]
                },
                {
                  titulo: 'POR ACCIDENTE AUTOMOVILÍSTICO',
                  valores: [
                    {id: 1, valor: '100,000'},
                    {id: 2, valor: '150,000'},
                    {id: 3, valor: '200,000'},
                  ]
                },
              ]
              break;
            case 14: 
              //AMPLIA PLUS
              this.coberturas=[
                {
                  titulo: 'DAÑOS MATERIALES',
                  valores: [
                    {id: 1, valor: '3%'},
                    {id: 2, valor: '5%'},
                    {id: 3, valor: '10%'},
                    {id: 4, valor: '20%'},
                  ]
                },
                {
                  titulo: 'ROBO TOTAL',
                  valores: [
                    {id: 1, valor: '5%'},
                    {id: 2, valor: '10%'},
                    {id: 3, valor: '20%'},
                  ]
                },
                {
                  titulo: 'RESPONSABILIDAD CIVIL',
                  valores: [
                    {id: 1, valor: '3,000,000'},
                    {id: 2, valor: '4,000,000'},
                    {id: 3, valor: '5,000,000'},
                    {id: 4, valor: '6,000,000'},
                    {id: 5, valor: '7,000,000'},
                    {id: 6, valor: '8,000,000'},
                  ]
                },
                {
                  titulo: 'GASTOS MÉDICOS OCUPANTES',
                  valores: [
                    {id: 1, valor: '100,000'},
                    {id: 2, valor: '200,000'},
                    {id: 3, valor: '300,000'},
                    {id: 4, valor: '400,000'},
                    {id: 5, valor: '500,000'},
                    {id: 6, valor: '600,000'},
                    {id: 7, valor: '700,000'},
                    {id: 8, valor: '800,000'},
                    {id: 9, valor: '900,000'},
                    {id: 10, valor: '1,000,000'},
                  ]
                },
                {
                  titulo: 'POR ACCIDENTE AUTOMOVILÍSTICO',
                  valores: [
                    {id: 1, valor: '100,000'},
                    {id: 1, valor: '150,000'},
                    {id: 1, valor: '200,000'},
                  ]
                },
              ]
              break;
            default:
              break;
          }
        break;
      case "aig":
        switch (this.plan) {
            case 1:
              //AMPLIA
              this.coberturas=[
                {
                  titulo: 'DAÑOS MATERIALES',
                  valores: [
                    {id: 1, valor: '3%'},
                    {id: 2, valor: '5%'},
                    {id: 3, valor: '10%'},
                  ]
                },
                {
                  titulo: 'ROBO TOTAL',
                  valores: [
                    {id: 1, valor: '5%'},
                    {id: 2, valor: '10%'},
                    {id: 3, valor: '15%'},
                    {id: 4, valor: '20%'},
                    {id: 5, valor: '30%'},
                  ]
                },
                {
                  titulo: 'RESPONSABILIDAD CIVIL',
                  valores: [
                    {id: 1, valor: '2,000,000'},
                    {id: 2, valor: '3,000,000'},
                    {id: 3, valor: '4,500,000'},
                  ]
                },
                {
                  titulo: 'GASTOS MÉDICOS OCUPANTES',
                  valores: [
                    {id: 1, valor: '50,000'},
                    {id: 2, valor: '80,000'},
                    {id: 3, valor: '100,000'},
                  ]
                },
              ]
              break;
            case 2:
              //LIMITADA
              this.coberturas=[
                {
                  titulo: 'ROBO TOTAL',
                  valores: [
                    {id: 1, valor: '5%'},
                    {id: 2, valor: '10%'},
                    {id: 3, valor: '15%'},
                    {id: 4, valor: '20%'},
                    {id: 5, valor: '30%'},
                  ]
                },
                {
                  titulo: 'RESPONSABILIDAD CIVIL',
                  valores: [
                    {id: 1, valor: '2,000,000'},
                    {id: 2, valor: '3,000,000'},
                    {id: 3, valor: '4,500,000'},
                  ]
                },
                {
                  titulo: 'GASTOS MÉDICOS OCUPANTES',
                  valores: [
                    {id: 1, valor: '50,000'},
                    {id: 2, valor: '80,000'},
                    {id: 3, valor: '100,000'},
                  ]
                },
              ]
              break;
            case 3:
              //BÁSICA O RC
              this.coberturas=[
                {
                  titulo: 'RESPONSABILIDAD CIVIL',
                  valores: [
                    {id: 1, valor: '2,000,000'},
                    {id: 2, valor: '3,000,000'},
                    {id: 3, valor: '4,500,000'},
                  ]
                },
                {
                  titulo: 'GASTOS MÉDICOS OCUPANTES',
                  valores: [
                    {id: 1, valor: '50,000'},
                    {id: 2, valor: '80,000'},
                    {id: 3, valor: '100,000'},
                  ]
                },
              ]
              break;
            case 14 || 4:
              //AMPLIA PLUS
              this.coberturas=[
                {
                  titulo: 'DAÑOS MATERIALES',
                  valores: [
                    {id: 1, valor: '3%'},
                    {id: 2, valor: '5%'},
                    {id: 3, valor: '10%'},
                  ]
                },
                {
                  titulo: 'ROBO TOTAL',
                  valores: [
                    {id: 1, valor: '5%'},
                    {id: 2, valor: '10%'},
                    {id: 3, valor: '15%'},
                    {id: 4, valor: '20%'},
                    {id: 5, valor: '30%'},
                  ]
                },
                {
                  titulo: 'RESPONSABILIDAD CIVIL',
                  valores: [
                    {id: 1, valor: '2,000,000'},
                    {id: 2, valor: '3,000,000'},
                    {id: 3, valor: '4,500,000'},
                  ]
                },
                {
                  titulo: 'GASTOS MÉDICOS OCUPANTES',
                  valores: [
                    {id: 1, valor: '50,000'},
                    {id: 2, valor: '80,000'},
                    {id: 3, valor: '100,000'},
                  ]
                },
              ] 
              break;
            default:
              break;
          }
        break;
      case "gnp":
        switch (this.plan) {
            case 1:
              //AMPLIA
              this.coberturas=[
                {
                    titulo: 'DAÑOS MATERIALES PÉRDIDA TOTAL',
                    valores: [
                        
                            {id: 1, valor: '3%'},
                            {id: 2, valor: '5%'},
                            {id: 3, valor: '10%'},
                        
                    ]
                },
                {
                    titulo: 'DAÑOS MATERIALES PÉRDIDA PARCIAL',
                    valores: [
                        
                            {id: 1, valor: '3%'},
                            {id: 2, valor: '5%'},
                            {id: 3, valor: '10%'},
                        
                    ]
                },
                {
                    titulo: 'CRISTALES',
                    valores: [
                        
                            {id: 1, valor: '20%'},
                        
                    ]
                },
                {
                    titulo: 'ROBO TOTAL',
                    valores: [
                        
                            {id: 1, valor: '0%'},
                            {id: 2, valor: '3%'},
                            {id: 3, valor: '5%'},
                            {id: 4, valor: '10%'},
                            {id: 5, valor: '20%'},
                        
                    ]
                },
                {
                    titulo: 'RESPONSABILIDAD CIVIL',
                    valores: [
                        
                            {id: 1, valor: '3,000,000'},
                            {id: 2, valor: '3,500,000'},
                            {id: 3, valor: '4,000,000'},
                            {id: 4, valor: '4,500,000'},
                            {id: 5, valor: '5,000,000'},
                        
                    ]
                },
                {
                    titulo: 'PROTECCIÓN LEGAL',
                    valores: [
                        
                            {id: 1, valor: '1,000,000'},
                            {id: 2, valor: '1,500,000'},
                            {id: 3, valor: '2,000,000'},
                            {id: 4, valor: '2,500,000'},
                            {id: 5, valor: '3,000,000'},
                            {id: 6, valor: '3,500,000'},
                            {id: 7, valor: '4,000,000'},
                            {id: 8, valor: '4,500,000'},
                            {id: 9, valor: '5,000,000'},
                        
                    ]
                },
                {
                    titulo: 'GASTOS MEDICOS OCUPANTES',
                    valores: [
                        
                            {id: 1, valor: '100,000'},
                            {id: 2, valor: '120,000'},
                            {id: 3, valor: '150,000'},
                            {id: 4, valor: '200,000'},
                            {id: 5, valor: '300,000'},
                            {id: 6, valor: '400,000'},
                            {id: 7, valor: '500,000'},
                        
                    ]
                },
                {
                    titulo: 'EXTENSION DE RC',
                    valores: [
                        
                            {id: 1, valor: '1,000,000'},
                            {id: 2, valor: '1,500,000'},
                            {id: 3, valor: '2,000,000'},
                            {id: 4, valor: '2,500,000'},
                            {id: 5, valor: '3,000,000'},
                            {id: 6, valor: '3,500,000'},
                            {id: 7, valor: '4,000,000'},
                            {id: 8, valor: '4,500,000'},
                            {id: 9, valor: '5,000,000'},
                        
                    ]
                },
              ]
              break;
            case 2:
              //LIMITADA
              this.coberturas=[
                {
                  titulo: 'RESPONSABILIDAD CIVIL',
                  valores: [
                    {id: 1, valor: '3,000,000'},
                    {id: 2, valor: '3,500,000'},
                    {id: 3, valor: '4,000,000'},
                    {id: 4, valor: '4,500,000'},
                    {id: 5, valor: '5,000,000'},
                  ]
                },
                {
                  titulo: 'PROTECCIÓN LEGAL',
                  valores: [
                    {id: 1, valor: '1,000,000'},
                    {id: 2, valor: '1,500,000'},
                    {id: 3, valor: '2,000,000'},
                    {id: 4, valor: '2,500,000'},
                    {id: 5, valor: '3,000,000'},
                    {id: 6, valor: '3,500,000'},
                    {id: 7, valor: '4,000,000'},
                    {id: 8, valor: '4,500,000'},
                    {id: 9, valor: '5,000,000'},
                  ]
                },
                {
                  titulo: 'GASTOS MEDICOS OCUPANTES',
                  valores: [
                    {id: 1, valor: '100,000'},
                    {id: 2, valor: '120,000'},
                    {id: 3, valor: '150,000'},
                    {id: 4, valor: '200,000'},
                    {id: 5, valor: '300,000'},
                    {id: 6, valor: '400,000'},
                    {id: 7, valor: '500,000'},
                  ]
                },
                {
                  titulo: 'EXTENSIÓN DE RC',
                  valores: [
                    {id: 1, valor: '1,000,000'},
                    {id: 2, valor: '1,500,000'},
                    {id: 3, valor: '2,000,000'},
                    {id: 4, valor: '2,500,000'},
                    {id: 5, valor: '3,000,000'},
                    {id: 6, valor: '3,500,000'},
                    {id: 7, valor: '4,000,000'},
                    {id: 8, valor: '4,500,000'},
                    {id: 9, valor: '5,000,000'},
                  ]
                },
              ]
              break;
            case 3:
              //RESPONSABILIDAD CIVIL
              this.coberturas=[
                {
                  titulo: 'RESPONSABILIDAD CIVIL',
                  valores: [
                    {id: 1, valor: '3,000,000'},
                    {id: 2, valor: '3,500,000'},
                    {id: 3, valor: '4,000,000'},
                    {id: 4, valor: '4,500,000'},
                    {id: 5, valor: '5,000,000'},
                  ]
                },
                {
                  titulo: 'PROTECCIÓN LEGAL',
                  valores: [
                    {id: 1, valor: '1,000,000'},
                    {id: 2, valor: '1,500,000'},
                    {id: 3, valor: '2,000,000'},
                    {id: 4, valor: '2,500,000'},
                    {id: 5, valor: '3,000,000'},
                    {id: 6, valor: '3,500,000'},
                    {id: 7, valor: '4,000,000'},
                    {id: 8, valor: '4,500,000'},
                    {id: 9, valor: '5,000,000'},
                  ]
                },
                {
                  titulo: 'GASTOS MEDICOS OCUPANTES',
                  valores: [
                    {id: 1, valor: '100,000'},
                    {id: 2, valor: '120,000'},
                    {id: 3, valor: '150,000'},
                    {id: 4, valor: '200,000'},
                    {id: 5, valor: '300,000'},
                    {id: 6, valor: '400,000'},
                    {id: 7, valor: '500,000'},
                  ]
                },
                {
                  titulo: 'EXTENSIÓN DE RC',
                  valores: [
                    {id: 1, valor: '1,000,000'},
                    {id: 2, valor: '1,500,000'},
                    {id: 3, valor: '2,000,000'},
                    {id: 4, valor: '2,500,000'},
                    {id: 5, valor: '3,000,000'},
                    {id: 6, valor: '3,500,000'},
                    {id: 7, valor: '4,000,000'},
                    {id: 8, valor: '4,500,000'},
                    {id: 9, valor: '5,000,000'},
                  ]
                },
              ]
              break;
            case 14 || 4:
              //AMPLIA PLUS
              this.coberturas=[
                {
                  titulo: 'DAÑOS MATERIALES PÉRDIDA TOTAL',
                  valores: [
                    {id: 1, valor: '3%'},
                    {id: 2, valor: '5%'},
                    {id: 3, valor: '10%'},
                  ]
                },
                {
                  titulo: 'DAÑOS MATERIALES PÉRDIDA PARCIAL',
                  valores: [
                    {id: 1, valor: '3%'},
                    {id: 2, valor: '5%'},
                    {id: 3, valor: '10%'},
                  ]
                },
                {
                  titulo: 'CRISTALES',
                  valores: [
                    {id: 1, valor: '20%'},
                  ]
                },
                {
                  titulo: 'ROBO TOTAL',
                  valores: [
                    {id: 1, valor: '0%'},
                    {id: 2, valor: '3%'},
                    {id: 3, valor: '5%'},
                    {id: 4, valor: '10%'},
                    {id: 5, valor: '20%'},
                  ]
                },
                {
                  titulo: 'RESPONSABILIDAD CIVIL',
                  valores: [
                    {id: 1, valor: '3,000,000'},
                    {id: 2, valor: '3,500,000'},
                    {id: 3, valor: '4,000,000'},
                    {id: 4, valor: '4,500,000'},
                    {id: 5, valor: '5,000,000'},
                  ]
                },
                {
                  titulo: 'PROTECCIÓN LEGAL',
                  valores: [
                    {id: 1, valor: '1,000,000'},
                    {id: 2, valor: '1,500,000'},
                    {id: 3, valor: '2,000,000'},
                    {id: 4, valor: '2,500,000'},
                    {id: 5, valor: '3,000,000'},
                    {id: 6, valor: '3,500,000'},
                    {id: 7, valor: '4,000,000'},
                    {id: 8, valor: '4,500,000'},
                    {id: 9, valor: '5,000,000'},
                  ]
                },
                {
                  titulo: 'GASTOS MEDICOS OCUPANTES',
                  valores: [
                    {id: 1, valor: '100,000'},
                    {id: 2, valor: '120,000'},
                    {id: 3, valor: '150,000'},
                    {id: 4, valor: '200,000'},
                    {id: 5, valor: '300,000'},
                    {id: 6, valor: '400,000'},
                    {id: 7, valor: '500,000'},
                  ]
                },
                {
                  titulo: 'EXTENSIÓN DE RC',
                  valores: [
                    {id: 1, valor: '1,000,000'},
                    {id: 2, valor: '1,500,000'},
                    {id: 3, valor: '2,000,000'},
                    {id: 4, valor: '2,500,000'},
                    {id: 5, valor: '3,000,000'},
                    {id: 6, valor: '3,500,000'},
                    {id: 7, valor: '4,000,000'},
                    {id: 8, valor: '4,500,000'},
                    {id: 9, valor: '5,000,000'},
                  ]
                },
              ]
              break;
            default:
              break;
          }
        break;
      case "ana":
        switch (this.plan) {
            case 1:
              this.coberturas=[
                {
                  "titulo":"Daños Materiales",
                  "valor":"5%"
                },
                {
                  "titulo":"Robo total",
                  "valor":"10%"
                },
                {
                  "titulo":"Responsabilidad civil",
                  "valor":3000000
                },
                {
                  "titulo":"Extensión RC",
                  "valor":"AMPARADA"
                }, 
                {
                  "titulo":"Accidentes automovilisticos al conductor",
                  "valor":"AMPARADA"
                },                           
                {
                  "titulo":"Asistencia juridica",
                  "valor":"AMPARADA"
                },                                
                {
                  "titulo":"Asistencia funeraria",
                  "valor":"AMPARADA"
                },                                
                {
                  "titulo":"Asistencia de viajes",
                  "valor":"AMPARADA"
                },                             
                {
                  "titulo":"RC Daño a terceros en EUA",
                  "valor":"AMPARADA"
                },                                
              ]
              break;

            case 2:
              this.coberturas=[
                {
                  "titulo":"Daños Materiales",
                  "valor":"-"
                },
                {
                  "titulo":"Robo total",
                  "valor":"10%"
                },
                {
                  "titulo":"Responsabilidad civil",
                  "valor":3000000
                },
                {
                  "titulo":"Extensión RC",
                  "valor":"AMPARADA"
                },
                {
                  "titulo":"Accidentes automovilisticos al conductor",
                  "valor":"AMPARADA"
                },                                 
                {
                  "titulo":"Asistencia juridica",
                  "valor":"AMPARADA"
                },                                
                {
                  "titulo":"Asistencia funeraria",
                  "valor":"AMPARADA"
                },                                
                {
                  "titulo":"Asistencia de viajes",
                  "valor":"AMPARADA"
                },                             
                {
                  "titulo":"RC Daño a terceros en EUA",
                  "valor":"AMPARADA"
                },                                 
              ]
              break;

            case 3:
              this.coberturas=[
                {
                  "titulo":"Daños Materiales",
                  "valor":"-"
                },
                {
                  "titulo":"Robo total",
                  "valor":"-"
                },
                {
                  "titulo":"Responsabilidad civil",
                  "valor":3000000
                },
                {
                  "titulo":"Extensión RC",
                  "valor":"AMPARADA"
                }, 
                {
                  "titulo":"Accidentes automovilisticos al conductor",
                  "valor":"AMPARADA"
                },                                 
                {
                  "titulo":"Asistencia juridica",
                  "valor":"AMPARADA"
                },                                
                {
                  "titulo":"Asistencia funeraria",
                  "valor":"AMPARADA"
                },                                
                {
                  "titulo":"Asistencia de viajes",
                  "valor":"AMPARADA"
                },                             
                {
                  "titulo":"RC Daño a terceros en EUA",
                  "valor":"AMPARADA"
                },                                    
              ]
              break;

            case 14 || 4:
              this.coberturas=[
                {
                  "titulo":"Daños Materiales",
                  "valor":"5%"
                },
                {
                  "titulo":"Robo total",
                  "valor":"10%"
                },
                {
                  "titulo":"Responsabilidad civil",
                  "valor":3000000
                },
                {
                  "titulo":"Extensión RC",
                  "valor":"AMPARADA"
                }, 
                {
                  "titulo":"Accidentes automovilisticos al conductor",
                  "valor":"AMPARADA"
                },                                   
                {
                  "titulo":"Asistencia juridica",
                  "valor":"AMPARADA"
                },                                
                {
                  "titulo":"Asistencia funeraria",
                  "valor":"AMPARADA"
                },                                
                {
                  "titulo":"Asistencia de viajes",
                  "valor":"AMPARADA"
                },                             
                {
                  "titulo":"RC Daño a terceros en EUA",
                  "valor":"AMPARADA"
                },                                       
              ]
              break;

            default:
              break;
          }
        break;
      case "sura":
        switch (this.plan) {
            case 1:
              this.coberturas=[
                {
                  "titulo":"Daños Materiales",
                  "valor":"5%"
                },
                {
                  "titulo":"Robo total",
                  "valor":"10%"
                },
                {
                  "titulo":"Responsabilidad civil",
                  "valor":3000000
                },
                {
                  "titulo":"Extensión RC",
                  "valor":"AMPARADA"
                }, 
                {
                  "titulo":"Gastos médicos ocupantes",
                  "valor":3000000
                },
                {
                  "titulo":"Accidentes automovilisticos al conductor",
                  "valor":"AMPARADA"
                },                           
                {
                  "titulo":"Asistencia juridica",
                  "valor":"AMPARADA"
                },                                
                {
                  "titulo":"Asistencia funeraria",
                  "valor":"AMPARADA"
                },                                
                {
                  "titulo":"Asistencia de viajes",
                  "valor":"AMPARADA"
                },                             
                {
                  "titulo":"RC Daño a terceros en EUA",
                  "valor":"AMPARADA"
                },                                
              ]
              break;

            case 2:
              this.coberturas=[
                {
                  "titulo":"Daños Materiales",
                  "valor":"-"
                },
                {
                  "titulo":"Robo total",
                  "valor":"10%"
                },
                {
                  "titulo":"Responsabilidad civil",
                  "valor":3000000
                },
                {
                  "titulo":"Extensión RC",
                  "valor":"AMPARADA"
                },
                {
                  "titulo":"Gastos médicos ocupantes",
                  "valor":3000000
                },
                {
                  "titulo":"Accidentes automovilisticos al conductor",
                  "valor":"AMPARADA"
                },                                 
                {
                  "titulo":"Asistencia juridica",
                  "valor":"AMPARADA"
                },                                
                {
                  "titulo":"Asistencia funeraria",
                  "valor":"AMPARADA"
                },                                
                {
                  "titulo":"Asistencia de viajes",
                  "valor":"AMPARADA"
                },                             
                {
                  "titulo":"RC Daño a terceros en EUA",
                  "valor":"AMPARADA"
                },                                 
              ]
              break;

            case 3:
              this.coberturas=[
                {
                  "titulo":"Daños Materiales",
                  "valor":"-"
                },
                {
                  "titulo":"Robo total",
                  "valor":"-"
                },
                {
                  "titulo":"Responsabilidad civil",
                  "valor":3000000
                },
                {
                  "titulo":"Extensión RC",
                  "valor":"AMPARADA"
                },
                {
                  "titulo":"Gastos médicos ocupantes",
                  "valor":3000000
                },
                {
                  "titulo":"Accidentes automovilisticos al conductor",
                  "valor":"AMPARADA"
                },                                 
                {
                  "titulo":"Asistencia juridica",
                  "valor":"AMPARADA"
                },                                
                {
                  "titulo":"Asistencia funeraria",
                  "valor":"AMPARADA"
                },                                
                {
                  "titulo":"Asistencia de viajes",
                  "valor":"AMPARADA"
                },                             
                {
                  "titulo":"RC Daño a terceros en EUA",
                  "valor":"AMPARADA"
                },                                    
              ]
              break;

            case 14 || 4:
              this.coberturas=[
                {
                  "titulo":"Daños Materiales",
                  "valor":"5%"
                },
                {
                  "titulo":"Robo total",
                  "valor":"10%"
                },
                {
                  "titulo":"Responsabilidad civil",
                  "valor":3000000
                },
                {
                  "titulo":"Extensión RC",
                  "valor":"AMPARADA"
                }, 
                {
                  "titulo":"Gastos médicos ocupantes",
                  "valor":3000000
                },
                {
                  "titulo":"Accidentes automovilisticos al conductor",
                  "valor":"AMPARADA"
                },                                   
                {
                  "titulo":"Asistencia juridica",
                  "valor":"AMPARADA"
                },                                
                {
                  "titulo":"Asistencia funeraria",
                  "valor":"AMPARADA"
                },                                
                {
                  "titulo":"Asistencia de viajes",
                  "valor":"AMPARADA"
                },                             
                {
                  "titulo":"RC Daño a terceros en EUA",
                  "valor":"AMPARADA"
                },                                       
              ]
              break;

            default:
              break;
          }
        break;
      case "hdi":
        switch (this.plan) {
            case 1:
              this.coberturas=[
                {
                  "titulo":"Daños Materiales",
                  "valor":"5%"
                },
                {
                  "titulo":"Robo total",
                  "valor":"10%"
                },
                {
                  "titulo":"Responsabilidad civil",
                  "valor":3000000
                },
                {
                  "titulo":"Extensión RC",
                  "valor":"AMPARADA"
                }, 
                {
                  "titulo":"Accidentes automovilisticos al conductor",
                  "valor":"AMPARADA"
                },                           
                {
                  "titulo":"Asistencia juridica",
                  "valor":"AMPARADA"
                },                                
                {
                  "titulo":"Asistencia funeraria",
                  "valor":"AMPARADA"
                },                                
                {
                  "titulo":"Asistencia de viajes",
                  "valor":"AMPARADA"
                },                             
                {
                  "titulo":"RC Daño a terceros en EUA",
                  "valor":"AMPARADA"
                },                                
              ]
              break;

            case 2:
              this.coberturas=[
                {
                  "titulo":"Daños Materiales",
                  "valor":"-"
                },
                {
                  "titulo":"Robo total",
                  "valor":"10%"
                },
                {
                  "titulo":"Responsabilidad civil",
                  "valor":3000000
                },
                {
                  "titulo":"Extensión RC",
                  "valor":"AMPARADA"
                },
                {
                  "titulo":"Accidentes automovilisticos al conductor",
                  "valor":"AMPARADA"
                },                                 
                {
                  "titulo":"Asistencia juridica",
                  "valor":"AMPARADA"
                },                                
                {
                  "titulo":"Asistencia funeraria",
                  "valor":"AMPARADA"
                },                                
                {
                  "titulo":"Asistencia de viajes",
                  "valor":"AMPARADA"
                },                             
                {
                  "titulo":"RC Daño a terceros en EUA",
                  "valor":"AMPARADA"
                },                                 
              ]
              break;

            case 3:
              this.coberturas=[
                {
                  "titulo":"Daños Materiales",
                  "valor":"-"
                },
                {
                  "titulo":"Robo total",
                  "valor":"-"
                },
                {
                  "titulo":"Responsabilidad civil",
                  "valor":3000000
                },
                {
                  "titulo":"Extensión RC",
                  "valor":"AMPARADA"
                }, 
                {
                  "titulo":"Accidentes automovilisticos al conductor",
                  "valor":"AMPARADA"
                },                                 
                {
                  "titulo":"Asistencia juridica",
                  "valor":"AMPARADA"
                },                                
                {
                  "titulo":"Asistencia funeraria",
                  "valor":"AMPARADA"
                },                                
                {
                  "titulo":"Asistencia de viajes",
                  "valor":"AMPARADA"
                },                             
                {
                  "titulo":"RC Daño a terceros en EUA",
                  "valor":"AMPARADA"
                },                                    
              ]
              break;

            case 14 || 4:
              this.coberturas=[
                {
                  "titulo":"Daños Materiales",
                  "valor":"5%"
                },
                {
                  "titulo":"Robo total",
                  "valor":"10%"
                },
                {
                  "titulo":"Responsabilidad civil",
                  "valor":3000000
                },
                {
                  "titulo":"Extensión RC",
                  "valor":"AMPARADA"
                }, 
                {
                  "titulo":"Accidentes automovilisticos al conductor",
                  "valor":"AMPARADA"
                },                                   
                {
                  "titulo":"Asistencia juridica",
                  "valor":"AMPARADA"
                },                                
                {
                  "titulo":"Asistencia funeraria",
                  "valor":"AMPARADA"
                },                                
                {
                  "titulo":"Asistencia de viajes",
                  "valor":"AMPARADA"
                },                             
                {
                  "titulo":"RC Daño a terceros en EUA",
                  "valor":"AMPARADA"
                },                                       
              ]
              break;

            default:
              break;
          }
        break;

      default:
        break;
    }
    if(this.aseguradora == 'qualitas'){
      switch (this.plan) {
            case 1: 
              //AMPLIA
              this.guardarCoberturas=["3%","10%","5,000,000","500,000","200,000"]
              break;
            case 4: 
              //LIMITADA
              this.guardarCoberturas=["10%","5,000,000","500,000","200,000"]
              break;
            case 3: 
              //LIMITADA
              this.guardarCoberturas=["10%","5,000,000","500,000","200,000"]
              break;
            case 14: 
              //AMPLIA PLUS
              this.guardarCoberturas=["3%","10%","5,000,000","500,000","200,000"]
              break;
            default:
              break;
          }
      //this.guardarCoberturas=[]
    }

    // this.coberturas=[
    //   {titulo: 'DAÑOS MATERIALES',},
    //   {titulo: 'ROBO TOTAL',},
    //   {titulo: 'RESPONSABILIDAD CIVIL',},
    //   {titulo: 'GASTOS MÉDICOS OCUPANTES',},
    //   {titulo: 'POR ACCIDENTE AUTOMOVILISTICO',},
    // ]
    // {
    //   "plan" : "amplia",
    //   "coberturas" : {
    //       "sumaDanios" : "0",
    //       "deducibleDanios" : "0",
    //       "sumaRoboTotal" : "0",
    //       "deducibleRoboTotal" : "0",
    //       "sumaRC" : "0",
    //       "deducibleRC" : "0",
    //       "sumaGastosMedicos" : "0",
    //       "deducibleGastosMedicos" : "0",
    //       "sumaMuerte":"0",
    //       "deducibleMuerte":"0",
    //       "sumaGastosLegales":"0",
    //       "deducibleGastosLegales":"0",
    //       "sumaAsistencia":"0",
    //       "deducibleAsistencia":""
    //   }
    // },
  },


  methods: {
    toCurrency(number) {
      var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });
      return formatter.format(number); /* $2,500.00 */
    },
    updateCobertura() {
      this.mostrarCotizacion = false;
    }

  },

};
</script>

<style >
.v-label {
    font-size: 14px;
}

.infoCotizacion {
  font-size: 15px;
}
</style>
