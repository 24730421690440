import { mainAxios } from "../../mainAxios";
import { getDefaultHeaders, getErrorMessage } from "../mainService";

export const createSolicitud = async (request) => {
  try {
    const response = await mainAxios.post(
      `v1/solicitud/insert/`,
      request,
      getDefaultHeaders()
    );

    return response.data;
  } catch (error) {
    throw new Error(getErrorMessage(error));
  }
};