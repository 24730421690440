<template>
  <v-app>
    <Navigation></Navigation>
    <v-main class="mb-15">
      <v-container fluid>
        <v-row>
          <v-col>
            <v-row>
              <v-col cols="10" style="padding-left: 30px">
                <h1 v-if="esAgentes">Agentes</h1>
                <h1 v-else>
                  Usuarios
                </h1>
              </v-col>
              <v-col cols="2">
                <v-btn
                  color="primary"
                  @click="$router.push('/usuario/crear')"
                  style="align-items: right"
                  class="ml-11 mt-2"
                  v-show="canViewCreate"
                >
                  Crear
                </v-btn>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" class="mb-10" style="padding: 30px">
            <v-toolbar dense flat dark color="#133B7D">
              <v-toolbar-title>
                <span>{{esAgentes ? 'Agentes' : 'Usuarios'}}</span>
              </v-toolbar-title>
            </v-toolbar>
            <v-data-table
              :headers="agentesTable"
              :items="currentUsers"
              item-key="name"
              class="elevation-1"
              :loading="loading"
              loading-text="Cargando..."
              no-data-text="Para ver elementos, primero realiza una búsqueda"
            >
              <template v-slot:top>
                <v-text-field
                  v-model="search"
                  label="Buscar"
                  hint="Buscar un agente por nombre, correo o RFC"
                  class="mx-4 my-2"
                  outlined
                  dense
                  @keyup.enter="getAgent"
                >
                <template #append>
                  <v-btn color="primary" small :loading="loading" @click="getAgent">
                    <v-icon small class="mr-2">mdi-magnify</v-icon>
                    <span>Buscar</span>
                  </v-btn>
                </template>
              </v-text-field>
              </template>
              <template v-slot:item.sexo="{ item }">
                <div v-if="item.sexo == 1">Masculino</div>
                <div v-if="item.sexo == 2">Femenino</div>
              </template>
              <template #item.fechaRegistro="{item}">
                <span>{{
                  moment(item.fechaRegistro).format("DD/MM/YYYY")
                }}</span>
              </template>
              <template v-slot:item.status="{ item }">
                <div v-if="item.status == -1">
                  <v-chip color="red" dark>Baja</v-chip>
                </div>
                <div v-if="item.status == 0">
                  <v-chip color="blue" dark>Nuevo</v-chip>
                </div>
                <div v-if="item.status == 1">
                  <v-chip color="green" dark>Activo</v-chip>
                </div>
                <div v-if="item.status == 2">
                  <v-chip color="amber darken-2" dark>Inactivo</v-chip>
                </div>
              </template>
              <template v-slot:item.uuid="{ item }">
                <v-icon small class="mr-2" @click="editItem(item.uuid)">
                  mdi-eye
                </v-icon>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <v-footer fixed style="text-align: center">
      <p style="text-align: center; width: 100%; margin: 10px 0px">
        Segurify 2021
        <a target="_blank" href="/terminos-y-condiciones"
          >Terminos y condiciones</a
        >
        y <a target="_blank" href="/aviso-de-privacidad">Aviso de privacidad</a>
      </p>
    </v-footer>
  </v-app>
</template>

<script>
import Navigation from "../../../../components/Nav.vue";
//import RfcFacil from 'rfc-facil';
import { mainAxios } from "../../../../mainAxios";
import moment from "moment";

export default {
  components: {
    Navigation,
  },
  data() {
    return {
      moment,
      agentes: [],
      rol: JSON.parse(localStorage.getItem("roles"))[0].rol,
      roles: JSON.parse(localStorage.getItem("roles")),
      loading: false,
      search: "",
      agentesTable: [
        {
          text: "Nombre",
          align: "start",
          sortable: false,
          value: "nombreCompleto",
        },
        { text: "Correo Electronico", value: "correo" },
        { text: "Fecha de Registro", value: "fechaRegistro" },
        { text: "Activo", value: "status" },
        { text: "RFC", value: "rfc" },
        { text: "Ver Agente", value: "uuid" },
      ],
      report: [],
    };
  },

  watch: {
    $route() {
      this.getAgent();
    },
  },

  computed: {
    canViewCreate() {
      let canView = false;
      let roles = [];
      this.roles.forEach((element) => {
        roles.push(element.rol);
      });
      const rolesToView = ["ADMIN", "ADMINAGENT", "ADMINCC", "DIRECTOR", "OPERACIONESINBOUND", 'FORMACION'];
      roles.forEach((element) => {
        if (rolesToView.includes(element)) {
          canView = true;
        }
      });
      return canView;
    },

    esAgentes() {
      return this.$route.fullPath === "/usuarios/agentes";
    },

    noFiltrar() {
      const usuariosQueNoFiltran = ["ADMINAGENT", "MANAGER"];
      return usuariosQueNoFiltran.filter((e) =>
        JSON.parse(localStorage.getItem("roles")).find((z) => z.rol == e)
      ).length;
    },

    currentUsers(){
      var usuarios = []
      var permitedRoles = []
      var pas = []
      switch(this.rol){
        case "ADMIN":
              if(window.location.pathname == '/usuarios/agentes'){
                permitedRoles = ['AGENT']
                permitedRoles.forEach((rol) => {
                  usuarios = this.agentes.filter((e) => e.rol === rol)
                })
              }else if (window.location.pathname == '/usuarios'){
                permitedRoles = ['AGENT']
                permitedRoles.forEach((rol) => {
                  pas = this.agentes.filter((e) => e.rol != rol)
                  pas.forEach((userinfo) => {
                    usuarios.push(userinfo)
                  })
                })
              }
          break;
        case "ADMINAGENT":
            if(window.location.pathname == '/usuarios/agentes'){
                permitedRoles = ['MANAGER', 'AGENT']
                permitedRoles.forEach((rol) => {
                  usuarios = this.agentes.filter((e) => e.rol === rol)
                })
              }else if (window.location.pathname == '/usuarios'){
                permitedRoles = ['MANAGER', 'AGENT']
                permitedRoles.forEach((rol) => {
                  pas = this.agentes.filter((e) => e.rol === rol)
                  pas.forEach((userinfo) => {
                    usuarios.push(userinfo)
                  })
                })
              }
          break;
        case "MANAGER":
            if(window.location.pathname == '/usuarios/agentes'){
                permitedRoles = ['AGENT']
                permitedRoles.forEach((rol) => {
                  usuarios = this.agentes.filter((e) => e.rol === rol)
                })
              }else if (window.location.pathname == '/usuarios'){
                permitedRoles = ['AGENT']
                permitedRoles.forEach((rol) => {
                  pas = this.agentes.filter((e) => e.rol === rol)
                  pas.forEach((userinfo) => {
                    usuarios.push(userinfo)
                  })
                })
              }
          break;
        case "ADMINCC":
              if(window.location.pathname == '/usuarios/agentes'){
                permitedRoles = ['AGENT']
                permitedRoles.forEach((rol) => {
                  usuarios = this.agentes.filter((e) => e.rol === rol)
                })
              }else if (window.location.pathname == '/usuarios'){
                permitedRoles = ['MANAGERCC', 'AGENTCC', 'AGENTCCSPONSORS', 'RENOVACIONESCC', 'COBRANZACC']
                permitedRoles.forEach((rol) => {
                  pas = this.agentes.filter((e) => e.rol === rol)
                  pas.forEach((userinfo) => {
                    usuarios.push(userinfo)
                  })
                })
              }
          break;
        case "MANAGERCC":
              if(window.location.pathname == '/usuarios/agentes'){
                permitedRoles = ['AGENT']
                permitedRoles.forEach((rol) => {
                  usuarios = this.agentes.filter((e) => e.rol === rol)
                })
              }else if (window.location.pathname == '/usuarios'){
                permitedRoles = ['AGENTCC', 'AGENTCCSPONSORS']
                permitedRoles.forEach((rol) => {
                  pas = this.agentes.filter((e) => e.rol === rol)
                  pas.forEach((userinfo) => {
                    usuarios.push(userinfo)
                  })
                })
              }
          break;
          case "FORMACION":
              if(window.location.pathname == '/usuarios/agentes'){
                permitedRoles = ['AGENT']
                permitedRoles.forEach((rol) => {
                  usuarios = this.agentes.filter((e) => e.rol === rol)
                })
              }else if (window.location.pathname == '/usuarios'){
                permitedRoles = ["FORMACION", "OPERADORCC", "COBRANZACC", "AGENTCC", 'ADMINCC', 'MESACONTROLINBOUND', 'OPERACIONESINBOUND', 'OPERADORINBOUND', 'RENOVACIONESCC', 'MANAGERCC'];
                permitedRoles.forEach((rol) => {
                  pas = this.agentes.filter((e) => e.rol === rol)
                  pas.forEach((userinfo) => {
                    usuarios.push(userinfo)
                  })
                })
              }
          break;
        default:
            usuarios = this.agentes;
          break;
      }
        return usuarios
    },
  },

  methods: {
    getAgent() {
      this.loading = true;
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      const body = {
        filtroBusqueda: this.search
      }
      mainAxios.post(`/v1/agente/list-filtro`, body, config).then((response) => {
        this.agentes = []
        this.report = []
        this.loading = false;
        response.data.map(element => {
          this.agentes.push({
            nombreCompleto: element.nombreCompleto ? element.nombreCompleto : 'No disponible',
            correo: element.correo ? element.correo : 'No disponible',
            fechaRegistro:  element.fechaRegistro ? element.fechaRegistro : 'No disponible',
            status: element.status ? element.status : 'No disponible',
            rfc: element.rfc ? element.rfc : 'No disponible',
            uuid: element.uuid ? element.uuid : 'No disponible',
            rol: element.rol ? element.rol : 'No disponible'
          })
        })
      })
    },
    editItem(item) {
      console.log(item);
      this.$router.push(`/usuario/ver-micuenta/${item}`);
    },

    filterOnlyCapsText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value
          .toString()
          .toLocaleUpperCase()
          .indexOf(search) !== -1
      );
    },
  },
};
</script>
<style scoped></style>
