<template>
  <v-app>
    <Navigation></Navigation>
      <v-main class="mb-15">
        <v-container fluid>
          <v-row>
            <v-col cols="6">
              <h1 
                style="padding: 20px"
              >Solicitudes {{ this.$route.params.status == 'Abierta' ? 'Abiertas' :
                              this.$route.params.status == 'Pendiente' ? 'Pendientes' : 'Cerradas'}}</h1>
            </v-col>
            <v-col cols="6">
                <v-btn
                    @click="goBack()" 
                    elevation="2"
                    style="margin-left: 527px; margin-top: 20px"
                ><v-icon style="margin-right: 5px">mdi-arrow-left</v-icon> Regresar
                </v-btn>
            </v-col>
          </v-row>

          <v-row class="mb-15" style="padding: 30px">
              <!-- <v-col cols="3" md="3">
                <h3>Selecciona el rango de fechas</h3>
                <v-date-picker
                  style="width: 100%"
                  v-model="dates"
                  range
                ></v-date-picker>
              </v-col> -->
              <v-col cols="12">
                <div>
                  <div v-if="loading" style="text-align: center">
                    <h2 clas="pb-4">Cargando Reporte....</h2>
                    <br /><br />
                    <md-progress-spinner
                      :md-diameter="100"
                      :md-stroke="10"
                      md-mode="indeterminate"
                    ></md-progress-spinner>
                  </div>
                </div>

                <v-data-table
                  :headers="columns"
                  :items="solicitudes"
                  item-key="name"
                  class="elevation-1"
                  :search="search"
                  :custom-filter="filterOnlyCapsText"
                  :loading="loading"
                  loading-text="Cargando..."
                >
                  <template v-slot:top>
                    <v-text-field
                      v-model="search"
                      label="Buscar"
                      class="mx-4"
                    ></v-text-field>
                  </template>
                  <template v-slot:item.uuid="{ item }">
                    <v-icon
                      small
                      class="mr-2"
                      @click="verSolicitud(item.id)"
                    >
                      mdi-eye
                    </v-icon>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
        </v-container>
      </v-main>
  </v-app>
</template>

<script>
import moment from 'moment';

import Navigation from "../../../components/Nav.vue";
import { mainAxios } from '../../../mainAxios';

export default {
    components: {
        Navigation,
    },

    data() {
      return {
        agenteUuid: localStorage.getItem('agenteUuid'),
        status: null,
        solicitudes: [],
        reportDownload: [],
        bodySolicitud: null,
        search: '',
        columns: [
          {
            text: 'Número',
            align: 'start',
            sortable: false,
            value: 'id',
          },
          { text: 'Asunto', value: 'asunto' },
          { text: 'Estado', value: 'estado' },
          { text: 'Tipo', value: 'tipo' },
          { text: 'Categoria', value: 'categoria' },
          { text: 'Ejecutivo', value: 'ejecutivo' },
          { text: 'Fecha de Creación', value: 'fechaCreacion' },
          { text: 'SLA', value: '' },
          { text: 'Ver', value: 'uuid' },
        ],
      }
    },

    mounted() {
      this.status = this.$route.params.status == 'Abierta' ? 'Abierta' 
      : this.$route.params.status == 'Pendiente' ? 'Pendiente' : 'Cerrada'

      this.getSolicitudes(),
      this.obtenerEjecutivos()
    },
    methods: {
      goBack() {
        return this.$router.go(-1);
      },
      getSolicitudes() {
        let config = {
          headers: {
            Authorization: 'Bearer ' + localStorage.agenteAccessToken,
          },
        };
        mainAxios
        .get(`/v1/solicitud/list/agente/${this.agenteUuid}/status/${this.status}`, config)
          .then((response) => {
            console.log('Solicitudes: ', response.data);
            response.data.map(element => {
              this.solicitudes.push({
                id: element.id,
                asunto: `${element.categoria ? element.categoria : ''}` + ' / ' + `${element.tipo ? element.tipo : ''}` + ' / ' + `${element.nombreAgente ? element.nombreAgente : ''}`,
                fechaCreacion: moment(element.fechaCreacion, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm:ss'),
                estado: element.status,
                tipo: element.tipo,
                categoria: element.categoria,
                ejecutivo: element.nombreEjecutivo
              })
            })
          })
      },
      // obtenerEjecutivos() {
      //   const config = {
      //     headers: {
      //       Authorization: "Bearer " + localStorage.agenteAccessToken,
      //     },
      //   };
      //   mainAxios.get('https://api-dev.segurify.com/v1/agentes/operador', config).then(response => {
      //     console.log(response.data);
      //     // response.data.map(element => {
      //     //   this.ejecutivos.push({
      //     //     // id: element.id
      //     //   })
      //     // })
      //   });
      // },
      verSolicitud(id) {
        this.$router.push(`/seguimiento-solicitud/${id}`)
      },
    },

}
</script>

<style>

</style>