<template>
  <div>
    <v-card outlined>
      <v-subheader>Correos electrónicos de contacto</v-subheader>
      <v-card-text>
        <v-row no-gutters>
          <v-col v-for="correo in correosFijos" :key="correo" cols="12">
            <v-row no-gutters>
              <v-col cols="12">
                <v-text-field
                  :value="correo"
                  dense
                  filled
                  label="Correo electrónico fijo por canal"
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col v-for="(correo, index) in correos" :key="index" cols="12">
            <campo-correo
              :correo="correo"
              :index="index"
              :inhabilitarBorrado="correos.length == 1"
              :soloLectura="soloLectura"
              :favoritoFijo="favoritoFijo"
              :soloCorreosSegurify="soloCorreosSegurify"
              :esEdicionYAgente="esEdicionYAgente"
              @quitarDuplicados="quitarDuplicados"
              @cambiarFavorito="cambiarFavorito"
              @eliminarCorreo="eliminarCorreo"
              @correoModificado="correoModificado"
            ></campo-correo>
          </v-col>
          <v-col v-if="puedeAgregarCorreo" cols="12">
            <v-btn block small tile elevation="0" @click="agregarCorreo()">
              <v-icon left>mdi-plus</v-icon>
              Agregar correo
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mainAxios } from "../../mainAxios";
import CampoCorreo from "./CampoCorreo.vue";
import getCorreosFijos from "./correosFijos";

export default {
  components: {
    "campo-correo": CampoCorreo,
  },

  props: {
    idCliente: {
      type: [String, Number],
    },
    idAgente: {
      type: [String, Number],
    },
    correoPrincipal: {
      type: String,
    },
    soloLectura: {
      type: Boolean,
      default: false,
    },
    canalesAgente: {
      type: Array,
    },
    rolAgente: {
      type: String,
    },
    favoritoFijo: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      correos: [
        {
          id: null,
          correo: "",
          favorito: true,
          prediccionesCorreo: [],
          busquedaCorreo: "",
          filtroCorreo: () => {
            return function(item, queryText, itemText) {
              return itemText.includes(queryText);
            };
          },
        },
      ],
      correosAEliminar: [],

      axiosConfig: {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("agenteAccessToken")}`,
        },
      },
    };
  },

  computed: {
    endpoints() {
      if (!this.idAgente && !this.idCliente) return null;
      if (this.idAgente)
        return {
          insert: "v1/correo-contacto/insert",
          update: `v1/correo-contacto/update/`,
          delete: "v1/correo-contacto/delete",
          get: `v1/agente/list/correos-contacto/${this.idAgente}`,
        };
      return {
        insert: "v1/correo-contacto/insert",
        update: `v1/correo-contacto/update/`,
        delete: "v1/correo-contacto/delete",
        get: `v1/cliente/list/correos-contacto/${this.idCliente}`,
      };
    },

    correosFijos() {
      return getCorreosFijos({
        rol: this.rolAgente,
        canales: this.canalesAgente,
      });
    },

    tieneCanal() {
      return function(canalId) {
        if (!this.canalesAgente) return false;
        return !!this.canalesAgente.find((c) => c === canalId);
      };
    },

    puedeAgregarCorreo() {
      if (this.soloLectura) return false;
      if (!this.idCliente && !this.idAgente) return true;
      if (this.idCliente) return true;

      const tieneRol = (rol) => this.rolAgente === rol;

      if (tieneRol("AGENT")) return true;
      if (tieneRol("OPERADOR")) return true;
      if (tieneRol("OPERADORINBOUND")) return true;
      if (tieneRol("AGENTCC")) return true;
      if (tieneRol("MANAGERCC")) return true;

      return false;
    },

    soloCorreosSegurify() {
      if(this.rolAgente != 'AGENT') return true;
      if (!this.idAgente || !this.canalesAgente) return false;
      if (!this.canalesAgente.find((c) => c == 2)) return false;
      const rolesConCorreosSegurify = [
        "AGENT",
        "AGENTCC",
        "OPERADOR",
        "OPERADORINBOUND",
        "MANAGERCC",
        "MESACONTROLINBOUND",
        "OPERACIONESINBOUND",
      ];
      return !!rolesConCorreosSegurify.find((r) => r == this.rolAgente);
    },

    esEdicionYAgente(){
      if(window.location.pathname.includes('/usuario/agente/')){
        return window.location.pathname.includes('/usuario/agente/') && this.rolAgente == 'AGENT';
      }else{
        return true
      }
    }
  },

  watch: {
    canalesAgente() {
      if (this.tieneCanal(2)) {
        this.dejarSoloFavorito();
      }
    },
  },

  async mounted() {
    if (this.idAgente || this.idCliente) await this.getCorreos();
  },

  methods: {
    quitarDuplicados(nuevoValor, index) {
      const direccionExistente = this.correos.filter(
        (c) => c.correo == nuevoValor
      );
      if (direccionExistente && direccionExistente.length > 1) {
        this.correos.splice(index, 1);
        this.$toast.warning(
          "El correo ingresado ya existe en esta lista, por favor ingrese otro.",
          {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          }
        );
      }
    },

    async getCorreos() {
      const response = await mainAxios.get(
        this.endpoints.get,
        this.axiosConfig
      );
      if (response.data.error) return;

      if (response.data && response.data.length && !response.data[0].error)
        this.correos = response.data.reverse().map((c) => ({
          ...c,
          prediccionesCorreo: [c.correo],
          busquedaCorreo: c.correo,
          filtroCorreo: () => {
            return function(item, queryText, itemText) {
              return itemText.includes(queryText);
            };
          },
        }));
      else if (this.correoPrincipal)
        this.correos = [
          {
            id: null,
            correo: this.correoPrincipal,
            favorito: true,
            prediccionesCorreo: [this.correoPrincipal],
            busquedaCorreo: this.correoPrincipal,
            filtroCorreo: () => {
              return function(item, queryText, itemText) {
                return itemText.includes(queryText);
              };
            },
          },
        ];
    },

    agregarCorreo() {
      this.correos.push({
        id: null,
        correo: "",
        favorito: false,
        prediccionesCorreo: [],
        busquedaCorreo: "",
        filtroCorreo: (busquedaCorreo) => {
          return function(item, queryText, itemText) {
            busquedaCorreo = queryText;
            return itemText.includes(busquedaCorreo);
          };
        },
      });
    },

    cambiarFavorito(index) {
      if (this.soloLectura) return;

      const favoritoActual = this.correos.findIndex((c) => c.favorito === true);
      if (favoritoActual !== -1) {
        this.correos[favoritoActual].favorito = false;
      }
      this.correos[index].favorito = true;
    },

    eliminarCorreo(index) {
      const correoAEliminar = { ...this.correos[index] };
      const idAEliminar = correoAEliminar.id;
      this.correosAEliminar.push(idAEliminar);
      this.correos.splice(index, 1);
      if (correoAEliminar.favorito) this.correos[0].favorito = true;
    },

    async limpiarCorreos() {
      if (!this.correosAEliminar.length) return;
      await mainAxios.post(
        this.endpoints.delete,
        {
          id: this.correosAEliminar,
        },
        this.axiosConfig
      );
    },

    async actualizarCorreos() {
      setTimeout(async () => {
        await this.actualizarCorreosTimeout();
      }, 1000);
    },

    async actualizarCorreosTimeout() {
      for (let correo of this.correos) {
        if (correo.id) {
          await mainAxios.post(
            `${this.endpoints.update}${correo.id}`,
            {
              correo: correo.correo,
              favorito: correo.favorito,
            },
            this.axiosConfig
          );
        } else {
          await mainAxios.post(
            this.endpoints.insert,
            {
              correo: correo.correo,
              favorito: correo.favorito,
              ...(this.idAgente && { agente_id: this.idAgente }),
              ...(this.idCliente && { cliente_id: this.idCliente }),
            },
            this.axiosConfig
          );
        }
      }

      await this.limpiarCorreos();
    },

    correoModificado(nuevoValor, index) {
      if (this.correos[index].favorito)
        this.$emit("correoFavoritoModificado", nuevoValor);
    },

    dejarSoloFavorito() {
      const indexFavorito = this.correos.findIndex((c) => c.favorito === true);
      this.correos
        .filter((c) => !c.favorito && c.id)
        .forEach((c) => this.correosAEliminar.push(c.id));

      this.correos = [this.correos[indexFavorito]];
    },
  },
};
</script>
