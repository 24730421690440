<template>
  <section id="hero">
    <v-carousel
      v-model="mainBanner"
      height="750"
      cycle
      hide-delimiters
      dark
    >
      <!-- :src="banner.src" -->
      <v-carousel-item 
        v-for="(banner) in banners"
        :key="banner.id"
        continuous
      >
        <template #default>
          <v-img :src="banner.src" class="grey lighten-2">
            <template #placeholder>
              <v-row
                class="fill-height ma-0"
                align="center"
                justify="center"
              >
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>

            <template>
               <div class="hero_area" style="padding-top: 4px !important;">
                <!-- slider section -->
                <section class="slider_section position-relative">
                  <div class="slider_bg-container">
                  </div>
                  <div class="slider-container">
                    <div class="detail-box">
                      <h1 style="font-size: 2.5rem !important; color:#EF5B0C;">
                        Seguro Celular para tus clientes <br>
                      </h1>
                      <h3>
                        A partir de: 
                        $71.26 / al mes
                      </h3>
                      <div class="pt-5">
                        <h2 style="color:#EF5B0C;" >
                          Contra Todo Riesgo
                        </h2>
                        <ul>
                          <li> <h4>Daño accidental    </h4></li>
                          <li><h4> Daño por liquido  </h4></li>
                          <li><h4>Robo con violencia </h4></li>
                          <li><h4>Robo sin violencia </h4></li>
                          <li><h4>Variación voltaje  </h4></li>
                          <li><h4>2 Siniestros       </h4></li>
                          <li style="list-style: none; padding: 0; margin-left: -22px;"> 
                            <v-btn
                              class="ma-2"
                              style="background-color:#EF5B0C;" 
                              dark
                            >
                              Sin deducible
                            </v-btn>
                          </li>
                        </ul>
                      </div>
                      <div class="mt-4">
                        <v-btn
                          class="ma-2"
                          style="background-color:#EF5B0C;" 
                          dark
                          @click="openDialog()"
                        >
                          Cotizar
                        </v-btn>
                      </div>
                    </div>
                    </div>
                   </section>
                <!-- end slider section -->
              </div>
            </template>
          </v-img>
        </template>
      </v-carousel-item>
    </v-carousel>
    <cotizacion-modal @close_dialog="closeDialog" :open_dialog="open_dialog"></cotizacion-modal>
  </section>
</template>

<script>
import CotizacionModal from './CotizacionModal.vue';

export default {
  components: {
    CotizacionModal,
  },
  data() {
    return {
      open_dialog: false,
      mainBanner: 0,
      banners: [
        {id: 1, src: require("#/img/bannersHome/Frontal_op2.jpg")},
        {id: 2, src: require("#/img/bannersHome/Frontal_op1.jpg")},
        {id: 3, src: require("#/img/bannersHome/Frontal_op3.jpg")}
      ],
      features: [
        {
          img: require("@/assets/img/icon2.png"),
          title: "Servicio Eficaz",
          text: "Reparaciones rápidas con reparadores garantizados.",
        },
        {
          img: require("@/assets/img/icon1.png"),
          title: "Amplias Coberturas",
          text: "Cobertura para robo con violencia o robo sin violencia.",
        },
        {
          img: require("@/assets/img/icon3.png"),
          title: "Contratación Rápida",
          text: "Sin deducible, cobertura inmediata.",
        },
      ],
    };
  },
  watch: {
    dialog(value) {
      if (!value) {
        this.pause();
      }
    },
  },
  methods: {
    openDialog() {
      this.open_dialog = true;
    },
    closeDialog() {
      this.open_dialog = false;
    },
    goCotizacionCelular() {
      this.$router.push('/cotizacion-celular')
    },
    ready(event) {
      this.player = event.target;
    },
    cerrarModal() {
      this.pause();
    },
    change() {
      // when you change the value, the player will also change.
      // If you would like to change `playerVars`, please change it before you change `videoId`.
      // If `playerVars.autoplay` is 1, `loadVideoById` will be called.
      // If `playerVars.autoplay` is 0, `cueVideoById` will be called.
      this.videoId = "another video id";
    },
    stop() {
      this.player.stopVideo();
    },
    pause() {
      this.player.pauseVideo();
    },
  },
};
</script>

<style scoped>

#first-text {
  font-weight: bold;
  color: #fe5000;
}

.list-item {
  margin: 10px 0 10px 0;
}

.svg-border-waves .v-image {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3rem;
  width: 100%;
  overflow: hidden;
}

#hero {
  z-index: 0;
}
.svg-border-waves img {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  margin-bottom: -2px;
  z-index: -1;
}

.card {
  padding: 10px;
  transition: 0.5s ease-out;
}

.card .v-image {
  margin-bottom: 15px;
  transition: 0.75s;
}

.card h1 {
  margin-bottom: 10px;
}

.zoom-efect {
  transform: scale(1.1);
}

.up {
  transform: translateY(-20px);
  transition: 0.5s ease-out;
}
section {
  position: relative;
}
/*end header section*/
/* slider section */
.slider_section .slider-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-left: 3%;
}

.slider_section .detail-box {
  padding-top: 10%;
  width: 45%;
  z-index: 4;
  color: #ffffff;
}

.slider_section .detail-box h1 {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 3rem;
}

.slider_section .detail-box p {
  width: 75%;
  margin-top: 15px;
}

.slider_section .detail-box .slider-link {
  display: inline-block;
  padding: 10px 35px;
  border: 1px solid #ffffff;
  background-color: transparent;
  color: #ffffff;
  border-radius: 25px;
  margin-top: 35px;
}

.slider_section .detail-box .slider-link:hover {
  background-color: #ffffff;
  color: black;
}

.slider_section .img-box {
  width: 73%;
  margin-left: -17%;
  position: relative;
  z-index: 2;
}

.slider_section .img-box img {
  width: 100%;
}

.slider_section .slider_bg-container {
  position: absolute;
  width: 80%;
  height: 250%;
  background: rgb(0,56,101);
  background: linear-gradient(90deg, rgba(0,56,101,1) 0%, rgba(0,111,200,1) 62%);
  z-index: 3;
  -webkit-clip-path: polygon(0 0, 60% 0, 37% 100%, 0% 100%);
          clip-path: polygon(0 0, 60% 0, 37% 100%, 0% 100%);
}

.slider_section .carousel-control-prev,
.slider_section .carousel-control-next {
  left: 47.6%;
  width: 50px;
  height: 50px;
  z-index: 9;
  background-size: 9px;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 1;
  border-radius: 100%;
}

.slider_section .carousel-control-prev {
  background-color: #ffffff;
  top: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
}

.slider_section .carousel-control-next {
  background-color: #2e02fe;
  top: calc(50% - 30px);
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
}

@media (max-width: 992px) {


  .slider_section .slider-container {
    flex-direction: column;
    padding: 0;
  }

  .slider_section .detail-box {
    width: 100%;
    text-align: center;
    padding: 75px 15px;
    background: linear-gradient(135deg, #a454fd, #2b05df);
    position: relative;
  }

  .slider_section .detail-box ul {
    list-style: none; /* Quitamos los marcadores */
    padding: 0; /* Quitamos el padding por defecto de la lista */
    margin-left: 10px; /* Separamos la lista de la izquierda */
  }

  .slider_section .img-box {
    width: 100%;
    margin: 0;
  }

  .slider_section .slider_bg-container {
    display: none;
  }

  .slider_section .detail-box p {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .slider_section .carousel-control-prev,
  .slider_section .carousel-control-next {
    top: initial;
    bottom: 0;
    left: 44.6%;
  }

  .slider_section .carousel-control-prev {
    transform: translate(0, 50%);
  }

  .slider_section .carousel-control-next {
    transform: translate(115%, 50%);
  }

  .service_section .service_container {
    flex-wrap: wrap;
    justify-content: center;
  }

  .service_section .box {
    margin: 25px;
  }

  .service_section .box .name h6 {
    color: #ffffff;
  }

  .service_section::before {
    height: 100%;
  }

  .portfolio_section {
    padding-top: 75px;
  }

  .contact_items {
    flex-wrap: wrap;
    justify-content: center;
  }

  .contact_items .item {
    margin: 20px;
  }
}

</style>

