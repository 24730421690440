<template>
  <v-app>
    <Navigation></Navigation>
    <v-main class="mb-15">
      <v-container fluid>
        <v-row class="mb-15 fill-height">
          <v-col>
            <v-row>
              <v-col>
                <h1>
                  Reporte Toques Call Center
                </h1>
              </v-col>
            </v-row>
            <v-row class="mb-15" style="padding: 30px">
              <v-col cols="12" lg="3">
                <h3>Selecciona el rango de fechas</h3>
                <v-date-picker
                  style="width: 100%"
                  v-model="dates"
                  range
                ></v-date-picker>
                <v-btn
                  rounded
                  color="primary"
                  style="width: 100%"
                  dark
                  @click="generateReport"
                  >Generar Reporte</v-btn
                >
                <v-btn
                  rounded
                  color="primary"
                  style="width: 100%; margin-top: 20px"
                  :disabled="!report.length > 0"
                  >
                  <download-csv
                    :data="reportDownload"
                    name= "reporteToquesCC.csv"
                  >
                    Descargar Información
                  </download-csv>
                </v-btn>

                
              </v-col>
              <v-col cols="12" lg="9">
                <div>
                  <div v-if="loading" style="text-align: center">
                    <h2 clas="pb-4">Cargando Reporte....</h2>
                    <br /><br />
                    <md-progress-spinner
                      :md-diameter="100"
                      :md-stroke="10"
                      md-mode="indeterminate"
                    ></md-progress-spinner>
                  </div>
                </div>

                <v-data-table
                  v-if="!loading && reportType == 'lead'"
                  :headers="columns"
                  :items="report"
                  item-key="name"
                  class="elevation-1"
                  :search="search"
                  :custom-filter="filterOnlyCapsText"
                  :loading="loading"
                  loading-text="Cargando..."
                >
                  <template v-slot:top>
                    <v-text-field
                      v-model="search"
                      label="Buscar"
                      class="mx-4"
                    ></v-text-field>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <v-footer fixed style="text-align: center">
      <p style="text-align: center; width: 100%; margin: 10px 0px">
        Segurify 2021
        <a target="_blank" href="/terminos-y-condiciones"
          >Terminos y condiciones</a
        >
        y <a target="_blank" href="/aviso-de-privacidad">Aviso de privacidad</a>
      </p>
    </v-footer>
  </v-app>
</template>

<script>
import Navigation from "../../../components/Nav.vue";
import { mainAxios } from "../../../mainAxios";
var moment = require('moment-timezone');


export default {
  components: {
    Navigation,
  },
  data() {
    return {
      roles: JSON.parse(localStorage.getItem('roles')),
      uuid: localStorage.getItem('agenteUuid'),
      dates: [],
      downloadText: 'Descargar Reporte En Excel',
      downloading: false,
      loading: false,
      reportType: 'lead',
      report: [],
      reportDownload: [],
      search: '',
      columns: [
        {
          text: 'Uuid',
          align: 'start',
          sortable: false,
          value: 'uuid',
        },
        { text: 'Campaña', value: 'campania' },
        { text: 'Nombre', value: 'nombre' },
        { text: 'Apellido Paterno', value: 'apellidoPaterno' },
        { text: 'Apellido Materno', value: 'apellidoMaterno' },
        { text: 'Telefono', value: 'telefono' },
        { text: 'Correo', value: 'correo' },
        { text: 'Fecha Lead', value: 'fecha' },
        { text: 'Hora Lead', value: 'hora' },
        //TOQUE 1
        { text: 'Primer Disposición', value: 'disposicionToque' },
        { text: 'Fecha', value: 'fechaToque' },
        { text: 'Agente', value: 'nombreToque' },
        //last toque
        { text: 'Ultima Disposición', value: 'lastdisposicionToque' },
        { text: 'Fecha', value: 'lastfechaToque' },
        { text: 'Agente', value: 'lastnombreToque' },
      ],
    };
  },


  methods: {
    convertDate(date) {
      const dateRes = moment(date);
      dateRes.tz('UTC', true);
      return dateRes.tz('America/Mexico_City').format('DD-MM-YYYY HH:mm');
    },
    generateReport() {
      let vm = this;
      let disposicionToque, fechaToque, nombreToque;
      let disposicionToque2, fechaToque2, nombreToque2;
      let disposicionToque3, fechaToque3, nombreToque3;
      let disposicionToque4, fechaToque4, nombreToque4;
      let disposicionToque5, fechaToque5, nombreToque5;
      let disposicionToque6, fechaToque6, nombreToque6;
      let disposicionToque7, fechaToque7, nombreToque7;
      let disposicionToque8, fechaToque8, nombreToque8;
      let disposicionToque9, fechaToque9, nombreToque9;
      let disposicionToque10, fechaToque10, nombreToque10;
      let lastdisposicionToque, lastfechaToque, lastnombreToque;
      vm.report = []
      vm.reportType = 'lead';
      let config = {
        headers: {
          Authorization: 'Bearer ' + localStorage.agenteAccessToken,
        },
      };
      let inicioUTC = null
      let finUTC = null

      if(this.dates.length > 0){
        if(this.dates[1]) {
            inicioUTC = moment.utc(this.dates[0]).format('YYYY-MM-DD HH:mm:ss')
            finUTC = moment.utc(this.dates[1]).add(24, 'hours').subtract(1, 'seconds').format('YYYY-MM-DD HH:mm:ss')
        } else {
            inicioUTC = moment.utc(this.dates[0]).format('YYYY-MM-DD HH:mm:ss')
            finUTC = moment.utc(this.dates[0]).add(24, 'hours').subtract(1, 'seconds').format('YYYY-MM-DD HH:mm:ss')
        }
      //restar 6 horas al reques
      //moment.utc(element.fecha).subtract(6, 'hours').format('DD/MM/YYYY HH:mm:ss'),
      }

      let fechas = {
        fechaInicio: inicioUTC,
        fechaFin: finUTC,
      };

      vm.loading = true;
      mainAxios
        .post('/v1/reportes/leadsCC/online', fechas, config)
          .then((response) => {
            response.data.map((element, indexElement) => {

              lastdisposicionToque = element.toques[element.toques.length - 1].disposicion
              lastfechaToque = moment.utc(element.toques[element.toques.length - 1].fecha).subtract(6, 'hours').format('DD/MM/YYYY HH:mm:ss'),
              lastnombreToque = element.toques[element.toques.length - 1].nombre

              element.toques.map((toque, index) => {
                let indexToque = indexElement;
                let indexToque3 = indexElement;
                let indexToque4 = indexElement;
                let indexToque5 = indexElement;
                let indexToque6 = indexElement;
                let indexToque7 = indexElement;
                let indexToque8 = indexElement;
                let indexToque9 = indexElement;
                let indexToque10 = indexElement;
                
                if (index == 0) {
                  disposicionToque = toque.disposicion,
                  fechaToque = moment.utc(toque.fecha).subtract(6, 'hours').format('DD/MM/YYYY HH:mm:ss'),
                  nombreToque = toque.nombre
                }

                if (indexToque == indexElement) {
                  if (index == 1) {
                    disposicionToque2 = toque.disposicion,
                    fechaToque2 = moment.utc(toque.fecha).subtract(6, 'hours').format('DD/MM/YYYY HH:mm:ss'),
                    nombreToque2 = toque.nombre
                  }
                }
                
                if (indexToque3 == indexElement) {
                  if (index == 2) {
                    disposicionToque3 = toque.disposicion,
                    fechaToque3 = moment.utc(toque.fecha).subtract(6, 'hours').format('DD/MM/YYYY HH:mm:ss'),
                    nombreToque3 = toque.nombre
                  }
                }

                if (indexToque4 == indexElement) {
                  if (index == 3) {
                    disposicionToque4 = toque.disposicion,
                    fechaToque4 = moment.utc(toque.fecha).subtract(6, 'hours').format('DD/MM/YYYY HH:mm:ss'),
                    nombreToque4 = toque.nombre
                  }
                }
                
                if (indexToque5 == indexElement) {
                  if (index == 4) {
                    disposicionToque5 = toque.disposicion,
                    fechaToque5 = moment.utc(toque.fecha).subtract(6, 'hours').format('DD/MM/YYYY HH:mm:ss'),
                    nombreToque5 = toque.nombre
                  }
                }
                if (indexToque6 == indexElement) {
                  if (index == 5) {
                    disposicionToque6 = toque.disposicion,
                    fechaToque6 = moment.utc(toque.fecha).subtract(6, 'hours').format('DD/MM/YYYY HH:mm:ss'),
                    nombreToque6 = toque.nombre
                  }
                }
                if (indexToque7 == indexElement) {
                  if (index == 6) {
                    disposicionToque7 = toque.disposicion,
                    fechaToque7 = moment.utc(toque.fecha).subtract(6, 'hours').format('DD/MM/YYYY HH:mm:ss'),
                    nombreToque7 = toque.nombre
                  }
                }
                if (indexToque8 == indexElement) {
                  if (index == 7) {
                    disposicionToque8 = toque.disposicion,
                    fechaToque8 = moment.utc(toque.fecha).subtract(6, 'hours').format('DD/MM/YYYY HH:mm:ss'),
                    nombreToque8 = toque.nombre
                  }
                }
                if (indexToque9 == indexElement) {
                  if (index == 8) {
                    disposicionToque9 = toque.disposicion,
                    fechaToque9 = moment.utc(toque.fecha).subtract(6, 'hours').format('DD/MM/YYYY HH:mm:ss'),
                    nombreToque9 = toque.nombre
                  }
                }
                if (indexToque10 == indexElement) {
                  if (index == 9) {
                    disposicionToque10 = toque.disposicion,
                    fechaToque10 = moment.utc(toque.fecha).subtract(6, 'hours').format('DD/MM/YYYY HH:mm:ss'),
                    nombreToque10 = toque.nombre
                  }
                }

              }),
              vm.report.push({
                uuid: element.uuid,
                campania: element.campania,
                nombre: element.nombre,
                apellidoPaterno: element.apellidoPaterno,
                apellidoMaterno: element.apellidoMaterno,
                telefono: element.telefono,
                correo: element.correo,
                fecha: moment.utc(element.fecha).subtract(6, 'hours').format('DD/MM/YYYY HH:mm:ss'),
                hora: element.hora,
                disposicionToque: disposicionToque ? disposicionToque : '-',
                fechaToque: fechaToque ? fechaToque : '-',
                nombreToque: nombreToque ? nombreToque : '-',
                lastdisposicionToque: lastdisposicionToque ? lastdisposicionToque : '-',
                lastfechaToque: lastfechaToque ? lastfechaToque : '-',
                lastnombreToque: lastnombreToque ? lastnombreToque : '-',
              });
              vm.reportDownload.push({
                'Uuid': element.uuid,
                'Campaña': element.campania,
                'Nombre': element.nombre,
                'Apellido Paterno': element.apellidoPaterno,
                'Apellido Materno': element.apellidoMaterno,
                'Telefono': element.telefono,
                'Correo': element.correo,
                'Fecha': moment.utc(element.fecha).subtract(6, 'hours').format('DD/MM/YYYY HH:mm:ss'),
                'Hora': element.hora,
                'Disposicion Toque 1': disposicionToque ? disposicionToque : '-',
                'Fecha Toque 1': fechaToque ? fechaToque : '-',
                'Nombre Toque 1': nombreToque ? nombreToque : '-',
                'Disposicion Toque 2': disposicionToque2 ? disposicionToque2 : '-',
                'Fecha Toque 2': fechaToque2 ? fechaToque2 : '-',
                'Nombre Toque 2': nombreToque2 ? nombreToque2 : '-',
                'Disposicion Toque 3': disposicionToque3 ? disposicionToque3 : '-',
                'Fecha Toque 3': fechaToque3 ? fechaToque3 : '-',
                'Nombre Toque 3': nombreToque3 ? nombreToque3 : '-',
                'Disposicion Toque 4': disposicionToque4 ? disposicionToque4 : '-',
                'Fecha Toque 4': fechaToque4 ? fechaToque4 : '-',
                'Nombre Toque 4': nombreToque4 ? nombreToque4 : '-',
                'Disposicion Toque 5': disposicionToque5 ? disposicionToque5 : '-',
                'Fecha Toque 5': fechaToque5 ? fechaToque5 : '-',
                'Nombre Toque 5': nombreToque5 ? nombreToque5 : '-',
                'Disposicion Toque 6': disposicionToque6 ? disposicionToque6 : '-',
                'Fecha Toque 6': fechaToque6 ? fechaToque6 : '-',
                'Nombre Toque 6': nombreToque6 ? nombreToque6 : '-',
                'Disposicion Toque 7': disposicionToque7 ? disposicionToque7 : '-',
                'Fecha Toque 7': fechaToque7 ? fechaToque7 : '-',
                'Nombre Toque 7': nombreToque7 ? nombreToque7 : '-',
                'Disposicion Toque 8': disposicionToque8 ? disposicionToque8 : '-',
                'Fecha Toque 8': fechaToque8 ? fechaToque8 : '-',
                'Nombre Toque 8': nombreToque8 ? nombreToque8 : '-',
                'Disposicion Toque 9': disposicionToque9 ? disposicionToque9 : '-',
                'Fecha Toque 9': fechaToque9 ? fechaToque9 : '-',
                'Nombre Toque 9': nombreToque9 ? nombreToque9 : '-',
                'Disposicion Toque 10': disposicionToque10 ? disposicionToque10 : '-',
                'Fecha Toque 10': fechaToque10 ? fechaToque10 : '-',
                'Nombre Toque 10': nombreToque10 ? nombreToque10 : '-',
              });
            })
            vm.loading = false;
          });
    },
    
    // Table
    filterOnlyCapsText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === 'string' &&
        value.toString().indexOf(search) !== -1
      );
    },
  },
};
</script>
