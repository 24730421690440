<template>
  <v-app>
    <Navigation></Navigation>
    <v-main class="mb-15">
      <v-container fluid>
        <v-card color="grey lighten-4" flat tile style="margin-top: 20px"
          ><v-toolbar>
            <v-toolbar-title> Llamada {{ data.id }} </v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn @click="goBack()"
              ><v-icon style="margin-right: 5px">mdi-arrow-left</v-icon>Regresar
            </v-btn>
          </v-toolbar>
        </v-card>
        <v-divider></v-divider>

        <v-row>
          <v-col cols="7">
            <v-form>
              <v-row style="padding-top: 20px;">
                <v-col>
                  <v-stepper non-linear>
                    <v-stepper-header>
                      <v-stepper-step step="1" editable >
                        Datos del Cliente
                      </v-stepper-step>
                      <v-stepper-step step="2" editable>
                        Datos de llamada
                      </v-stepper-step>
                    </v-stepper-header>
                    <v-stepper-items>
                      <v-stepper-content step="1" >
                        <v-row>
                          <v-col cols="12" m="12" md="6" lg="4" xl="4">
                            <v-text-field
                              label="Tipo de persona"
                              outlined
                              readonly
                              v-model="jsonData.regimen"
                              primary
                              color="#00a7e4"
                              type="text"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col m="12" md="6" lg="4" xl="4">
                            <v-text-field
                              label="Nombre(s)"
                              outlined
                              readonly
                              v-model="jsonData.nombre"
                              primary
                              color="#00a7e4"
                              type="text"
                            ></v-text-field>
                          </v-col>
                          <v-col m="12" md="6" lg="4" xl="4">
                            <v-text-field
                              label="Apellidos Paterno"
                              outlined
                              readonly
                              v-model="jsonData.apellidoPaterno"
                              primary
                              color="#00a7e4"
                              type="text"
                            ></v-text-field>
                          </v-col>
                          <v-col m="12" md="6" lg="3" xl="3">
                            <v-text-field
                              label="Apellidos Materno"
                              outlined
                              readonly
                              v-model="jsonData.apellidoMaterno"
                              primary
                              color="#00a7e4"
                              type="text"
                            ></v-text-field>
                          </v-col>
                          <v-col m="12" md="6" lg="3" xl="3">
                            <v-text-field
                              label="Fecha de Nacimiento"
                              outlined
                              readonly
                              v-model="jsonData.fechaNacimiento"
                              primary
                              placeholder="dd/mm/aaaa"
                              v-mask="'##/##/####'"
                              color="#00a7e4"
                              type="text"
                            ></v-text-field>
                          </v-col>
                          <v-col m="12" md="6" lg="3" xl="3">
                            <v-text-field
                              label="Edad"
                              outlined
                              readonly
                              v-model="jsonData.edad"
                              primary
                              color="#00a7e4"
                              type="text"
                            ></v-text-field>
                          </v-col>
                          <v-col m="12" md="6" lg="3" xl="3">
                            <v-text-field
                              label="RFC"
                              outlined
                              readonly
                              v-model="jsonData.rfc"
                              primary
                              color="#00a7e4"
                              type="text"
                            ></v-text-field>
                          </v-col>
                          <v-col m="12" md="6" lg="3" xl="3">
                            <v-autocomlete
                              label="Género"
                              outlined
                              readonly
                              v-model="jsonData.estadoCivil"
                              :items="generoList"
                              item-text="name"
                              item-value="id"
                              primary
                              color="#00a7e4"
                              type="text"
                            ></v-autocomlete>
                          </v-col>
                          <v-col m="12" md="6" lg="3" xl="3">
                            <v-text-field
                              label="Estado Civil"
                              outlined
                              readonly
                              v-model="jsonData.celular"
                              primary
                              color="#00a7e4"
                              type="text"
                            ></v-text-field>
                          </v-col>
                          <v-col m="12" md="6" lg="3" xl="3">
                            <v-text-field
                              label="Teléfono"
                              outlined
                              readonly
                              v-model="jsonData.telefono"
                              primary
                              color="#00a7e4"
                              counter="10"
                              :maxLength="10"
                              type="text"
                            ></v-text-field>
                          </v-col>
                          <v-col m="12" md="6" lg="3" xl="3">
                            <v-text-field
                              label="Correo Electrónico"
                              outlined
                              readonly
                              v-model="jsonData.correo"
                              primary
                              color="#00a7e4"
                              type="text"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col m="12" md="6" lg="3" xl="3">
                            <v-text-field
                              label="Codigo postal"
                              outlined
                              readonly
                              v-model="jsonData.codigoPostal"
                              primary
                              color="#00a7e4"
                              type="text"
                              counter="5"
                              :maxLength="5"
                            ></v-text-field>
                          </v-col>
                          <v-col m="12" md="6" lg="3" xl="3">
                            <v-text-field
                              label="Calle"
                              outlined
                              readonly
                              v-model="jsonData.calle"
                              primary
                              color="#00a7e4"
                              type="text"
                            ></v-text-field>
                          </v-col>
                          <v-col m="12" md="6" lg="3" xl="3">
                            <v-text-field
                              label="Numero exterior"
                              outlined
                              readonly
                              v-model="jsonData.num_exterior"
                              primary
                              color="#00a7e4"
                              type="text"
                            ></v-text-field>
                          </v-col>
                          <v-col m="12" md="6" lg="3" xl="3">
                            <v-text-field
                              label="Numero interior"
                              outlined
                              readonly
                              v-model="jsonData.num_interior"
                              primary
                              color="#00a7e4"
                              type="text"
                            ></v-text-field>
                          </v-col>
                          <v-col m="12" md="6" lg="3" xl="3">
                            <v-text-field
                              label="Colonia"
                              outlined
                              readonly
                              v-model="jsonData.colonia"
                              primary
                              color="#00a7e4"
                              type="text"
                            ></v-text-field>
                          </v-col>
                          <v-col m="12" md="6" lg="3" xl="3">
                            <v-text-field
                              label="Ciudad"
                              outlined
                              readonly
                              v-model="jsonData.colonia"
                              primary
                              color="#00a7e4"
                              type="text"
                            ></v-text-field>
                          </v-col>
                          <v-col m="12" md="6" lg="3" xl="3">
                            <v-text-field
                              label="Ciudad"
                              outlined
                              readonly
                              v-model="jsonData.ciudad"
                              primary
                              color="#00a7e4"
                              type="text"
                            ></v-text-field>
                          </v-col>
                          <v-col m="12" md="6" lg="3" xl="3">
                            <v-text-field
                              label="Estado"
                              placeholder="Requerido"
                              readonly
                              outlined
                              v-model="jsonData.estado"
                            >
                            </v-text-field>
                          </v-col>
                        </v-row>
                      </v-stepper-content>
                      <v-stepper-content step="2">
                        <v-row>
                          <v-col cols="12" m="12" md="6" xl="3">
                            <v-text-field
                              label="Medio de contacto"
                              outlined
                              readonly
                              v-model="jsonData.tipo_llamada"
                              primary
                              color="#00a7e4"
                              type="text"
                            ></v-text-field>
                          </v-col>
                          <v-col m="12" md="6" xl="3">
                            <v-text-field
                              label="Producto"
                              outlined
                              readonly
                              v-model="jsonData.producto"
                              item-value="campania"
                              item-text="campania"
                              primary
                              color="#00a7e4"
                              type="text"
                            ></v-text-field>
                          </v-col>
                          <v-col m="12" md="6" xl="3" v-if="jsonData.producto != 'Seguimiento' && jsonData.producto != 'Llamada colgada/incompleta'">
                            <v-text-field
                              label="Empresa"
                              outlined
                              readonly
                              v-model="jsonData.empresa"
                              primary
                              color="#00a7e4"
                              type="text"
                            ></v-text-field>
                          </v-col>
                          <v-col
                            m="12"
                            md="6"
                            xl="3"
                            v-if="jsonData.producto != 'Seguimiento' && jsonData.producto != 'Llamada colgada/incompleta'" 
                          >
                            <v-text-field
                              label="Aseguradora"
                              outlined
                              v-model="jsonData.aseguradora"
                              primary
                              readonly
                              color="#00a7e4"
                              type="text"
                            ></v-text-field>
                          </v-col>
                          <v-col
                            m="12"
                            md="6"
                            xl="3"
                            v-if="jsonData.producto != 'Seguimiento' && jsonData.producto != 'Llamada colgada/incompleta'"
                          >
                            <v-text-field
                              label="Tipificación"
                              outlined
                              v-model="jsonData.tipificacion"
                              primary
                              readonly
                              color="#00a7e4"
                              type="text"
                            ></v-text-field>
                          </v-col>
                          <v-col
                            m="12"
                            md="6"
                            xl="3"
                            v-if="jsonData.producto != 'Seguimiento' && jsonData.producto != 'Llamada colgada/incompleta'"
                          >
                            <v-text-field
                              label="Motivo"
                              outlined
                              v-model="jsonData.disposicion"
                              primary
                              readonly
                              color="#00a7e4"
                              type="text"
                            ></v-text-field>
                          </v-col>
                          <v-col
                            m="12"
                            md="6"
                            xl="6"
                            v-if="jsonData.producto != 'Seguimiento' && jsonData.producto != 'Llamada colgada/incompleta'"
                          >
                            <v-text-field
                              label="Póliza"
                              outlined
                              readonly
                              v-model="jsonData.poliza"
                              primary
                              color="#00a7e4"
                              type="text"
                            ></v-text-field>
                          </v-col>

                          <v-col
                            class="whitout-padding-bottom"
                            cols="12"
                            sm="6"
                            md="6"
                            xl="3"
                            v-if="jsonData.producto != 'Seguimiento' && jsonData.producto != 'Llamada colgada/incompleta'"
                          >
                            <span
                              class="text-header-data whitout-padding-bottom "
                            >
                              ¿Atendio en llamada?
                            </span>
                            <v-radio-group
                              v-model="jsonData.atendioLlamada"
                              row
                              readonly
                            >
                              <v-radio
                                label="Si"
                                value="true"
                                color="#00a7e4"
                              ></v-radio>
                              <v-radio
                                label="No"
                                value="false"
                                readonly
                                color="#00a7e4"
                              ></v-radio>
                            </v-radio-group>
                          </v-col>
                          
                          <v-col
                            class="whitout-padding-bottom"
                            cols="12"
                            sm="6"
                            md="6"
                            xl="3"
                            v-if="jsonData.producto != 'Seguimiento' && jsonData.producto != 'Llamada colgada/incompleta'"
                          >
                            <span
                              class="text-header-data whitout-padding-bottom "
                            >
                              ¿Es queja?
                            </span>
                            <v-radio-group
                              v-model="jsonData.isQueja"
                              row
                              readonly
                            >
                              <v-radio
                                label="Si"
                                value="true"
                                color="#00a7e4"
                              ></v-radio>
                              <v-radio
                                label="No"
                                value="false"
                                readonly
                                color="#00a7e4"
                              ></v-radio>
                            </v-radio-group>
                          </v-col>
                          <v-col m="12" md="6" lg="12" xl="12" v-if="jsonData.producto != 'Llamada colgada/incompleta'">
                            <v-text-field
                              label="Detalle"
                              outlined
                              v-model="jsonData.detalle"
                              primary
                              readonly
                              color="#00a7e4"
                              type="text"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-stepper-content>
                    </v-stepper-items>
                  </v-stepper>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
          <v-col cols="5" style="padding-top: 100px;">
            <v-card style="margin-bottom: 40px" class="pt-1">
              <v-row>
                <v-col cols="10">
                  <v-subheader>NOTAS Y DOCUMENTOS</v-subheader>
                </v-col>
                <v-col style="aling: right" class="mt-2" cols="2">
                  <v-btn small color="primary" @click="documentsDialog = true">
                    Crear
                  </v-btn>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-data-table
                :headers="columnsDocumentos"
                :items="documentos"
                item-key="name"
                class="elevation-1"
                loading-text="Cargando..."
                dense
              >
                <template v-slot:item.url="{ item }">
                  <v-icon small class="mr-2" @click="verDocumento(item)">
                    mdi-eye
                  </v-icon>
                </template>
              </v-data-table>
            </v-card>

            <v-card style="margin-top: 15px">
              <v-row>
                <v-col cols="10">
                  <v-subheader>EMAILS</v-subheader>
                </v-col>
                <!-- <v-col cols="4">
                  <v-btn icon>
                    <v-icon>mdi-magnify</v-icon>
                  </v-btn>
                </v-col> -->
                <v-col cols="2">
                  <v-btn @click="newCorreoDialog = true" small color="primary">
                    Crear
                  </v-btn>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-data-table
                :headers="columns"
                :items="correos"
                item-key="name"
                class="elevation-1"
                loading-text="Cargando..."
                dense
              >
                <template v-slot:top>
                  <v-text-field label="Buscar" class="mx-4"></v-text-field>
                </template>
                <template v-slot:item="row">
                  <tr>
                    <td>{{ row.item.asunto }}</td>
                    <td>{{ row.item.fecha }}</td>
                    <td>{{ row.item.to }}</td>
                    <td
                      style="cursor:pointer;"
                      @click="verDetalleCorreo(row.item)"
                    >
                      <v-icon light small class="mr-2">mdi-eye</v-icon>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <v-row justify="center">
      <v-dialog v-model="documentsDialog" max-width="750px">
        <v-card>
          <v-card-title>
            <span class="text-h5">Creación de Documento / Nota</span>
          </v-card-title>

          <div style="padding: 30px">
            <DocumentosNotasModal
              :accionGuardar="accionGuardar"
              :accion_id="data.id"
              @reload_info="getLlamadaDetalle"
            />
          </div>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="documentsDialog = false">
              Cerrar
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="(accionGuardar = true), (documentsDialog = false)"
            >
              Guardar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    </v-main>
  </v-app>
</template>

<script>
import Navigation from "../../../../components/Nav.vue";
import { getLlamadaDetalles } from "@/services/campañasInbound/CampañasInbound.js";
import DocumentosNotasModal from "../../../components/DocumentosNotasModal.vue";

export default {
  components: {
    Navigation,
    DocumentosNotasModal,
  },
  data() {
    return {
      status: 1,
      jsonData: {},
      data: {},
      columnsDocumentos: [
        {
          text: "Nombre",
          align: "start",
          sortable: false,
          value: "nombre",
        },
      ],
      columns: [
        {
          text: "Asunto",
          align: "start",
          sortable: false,
          value: "asunto",
        },
        { text: "Fecha", value: "fecha" },
        { text: "Para", value: "remitente" },
      ],
      documentsDialog:false,
      accionGuardar: false,
      generoList: [
        {
          id: "M",
          name: "Masculino",
        },
        {
          id: "F",
          name: "Femenino",
        },
      ],
      documentos:[],
      correos:[]
    };
  },
  methods: {
    goBack() {
      return this.$router.go(-1);
    },
    async getLlamadaDetalle() {
      this.data = await getLlamadaDetalles(this.$route.params.id);
      this.jsonData = JSON.parse(this.data.requestJson);
    },
  },
  mounted() {
    this.getLlamadaDetalle();
  },
};
</script>

<style scoped>
.v-stepper__step__step {
  background-color: white;
}
</style>
