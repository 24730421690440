<template>
  <v-app>
    <Navigation></Navigation>
      <v-main class="mb-15">
        <v-container fluid>
          <v-row>
            <v-col cols="6">
              <h1 
                style="padding: 20px"
              >Lista de Productos</h1>
            </v-col>
            <v-col cols="6">
                <v-btn
                  @click="goBack()" 
                  elevation="2"
                  style="margin-left: 527px; margin-top: 20px"
                ><v-icon style="margin-right: 5px">mdi-arrow-left</v-icon> Regresar
                </v-btn>
            </v-col>
          </v-row>

          <v-row class="mb-15" style="padding: 30px">
            <v-col cols="12">
              <div>
                <div v-if="loading" style="text-align: center">
                  <h2 clas="pb-4">Cargando Reporte....</h2>
                  <br /><br />
                  <md-progress-spinner
                    :md-diameter="100"
                    :md-stroke="10"
                    md-mode="indeterminate"
                  ></md-progress-spinner>
                </div>
              </div>

              <v-data-table
                :headers="columns"
                :items="productos"
                item-key="name"
                class="elevation-1"
                :search="search"
                :custom-filter="filterOnlyCapsText"
                :loading="loading"
                loading-text="Cargando..."
              >
                <template v-slot:top>
                  <v-row>
                    <v-col cols="11">
                      <v-text-field
                        v-model="search"
                        label="Buscar"
                        class="mx-4"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="1" style="margin-top: 20px">
                      <v-btn color="primary" small @click="crearProducto()">
                        Crear
                      </v-btn>
                    </v-col>
                  </v-row>
                </template>
                <template v-slot:item.check="{ item }">
                  <v-simple-checkbox
                    v-model="item.check"
                    class="pl-10"
                    disabled
                  ></v-simple-checkbox>
                </template>
                <template v-slot:item.uuid="{ item }">
                  <v-icon
                    small
                    class="mr-2"
                    @click="verProducto(item.id)"
                  >
                    mdi-eye
                  </v-icon>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-main>
  </v-app>
</template>

<script>
import Navigation from "../../../components/Nav.vue";
import { mainAxios } from '../../../mainAxios';
export default {
  components: {
    Navigation,
  },
  data() {
    return {
      productos: [],
      columns: [
        {
          text: 'Número',
          align: 'start',
          value: 'id',
        },
        { text: 'Ramo/Nombre de Producto/Compañia', value: 'titulo' },
        { text: 'Ramo', value: 'ramo' },
        { text: 'Compañia', value: 'compania' },
        { text: 'Renovación Automática', value: 'check' },
        { text: 'Fecha de Creación', value: 'fechaCreacion' },
        { text: 'Última Modificación', value: 'fechaModificacion' },
        { text: 'Ver', value: 'uuid' },
      ],
    }
  },
  methods: {
    goBack() {
      return this.$router.go(-1);
    },
    obtenerProductos() {
      let config = {
        headers: {
          Authorization: 'Bearer ' + localStorage.agenteAccessToken,
        },
      };
      mainAxios
      .get('/v1/productos/listaProductos', config)
        .then((response) => {
          response.data.map(element => {
            console.log(element);
            this.productos.push({
              id: element.id,
              titulo: `${element.ramo.toUpperCase()} / ${element.nombre.toUpperCase()} / ${element.nombreAseguradora.toUpperCase()}`,
              ramo: element.ramo,
              compania: element.nombreAseguradora.toUpperCase(),
              fechaCreacion: element.fechaCreacion.slice(0,10),
              fechaModificacion: element.fechaModificacion ? element.fechaModificacion : 'No Disponible',
              check: false
            })
          })
        })
    },
    verProducto(id) {
      this.$router.push(`/visualizacion-productos/${id}`)
    },
    crearProducto() {
      this.$router.push('/registro-productos')
    }
  },
  mounted() {
    this.obtenerProductos()
  }
}
</script>

<style>

</style>