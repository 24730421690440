<template>
  <div>
    <v-simple-table style="width:100%" dense>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              Cobertura
            </th>
            <th class="text-left">
              {{plan == 1 ? 'Amplia' : plan == 14  ? 'Amplia-Pluse' : plan == 2 ? 'Limitada' : plan == 3 ? 'RC' : "" }}
            </th>
          </tr>
        </thead>
        <tbody class="text-left">
          <tr style="word-break:break-word;" v-for="(cobertura,index) in coberturas" v-bind:key="index">
            <td>{{cobertura.titulo}}</td>
            <td v-if="cobertura.titulo == 'Responsabilidad civil'"  style="word-break:keep-all;">
              <v-text-field
                v-model="cobertura.valor"
                name="resposavilidad"
                append-outer-icon="mdi-autorenew"
                @click:append-outer="responsabilidadCivil(aseguradora, plan, cobertura.valor)">
              </v-text-field>
            </td>
            <td v-else-if="cobertura.titulo == 'Gastos médicos de ocupantes'"  style="word-break:keep-all;">
              <v-text-field
                v-model="cobertura.valor"
                name="resposavilidad"
                append-outer-icon="mdi-autorenew"
                @click:append-outer="gastosMedicos(aseguradora, plan, cobertura.valor)">
              </v-text-field>
            </td>
            <td v-else style="word-break:keep-all;">{{cobertura.valor}}</td>
            

          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>
<script>
export default {
  props: {
    aseguradora:String, 
    plan:Number, 
    coberturaRC: 
    Function, 
    coberturaGastos: Function, 
    coberturaChangeQualitas: Array,
    gastosChangeQualitas: Array,
    coberturaChangeAig: Array,
    gastosChangeAig: Array,
    coberturaChangeGnp: Array,
    gastosChangeGnp: Array,
  },
  data() {
    return {
      coberturas:[],
    };
  },
  mounted() {
    
    switch (this.aseguradora) {
      case "qualitas":
          switch (this.plan) {
            case 1: 
              if (this.coberturaChangeQualitas.length > 0 || this.gastosChangeQualitas.length > 0) {
                if (this.coberturaChangeQualitas[1] == 1 || this.gastosChangeQualitas[1] == 1) {
                  this.coberturas=[
                    {
                      "titulo":"Daños Materiales",
                      "valor":"5%"
                    },
                    {
                      "titulo":"Robo total",
                      "valor":"10%"
                    },
                    {
                      "titulo":"Responsabilidad civil",
                      "valor":this.coberturaChangeQualitas[2] ? this.coberturaChangeQualitas[2] : 3000000
                    },
                    {
                      "titulo":"Gastos médicos de ocupantes",
                      "valor":this.gastosChangeQualitas[2] ? this.gastosChangeQualitas[2] : 300000
                    },
                    {
                      "titulo":"Extensión RC",
                      "valor":"AMPARADA"
                    },  
                    {
                      "titulo":"Muerte del con ductor por AA",
                      "valor":"$100,000"
                    },                                      
                    {
                      "titulo":"Gastos Legales",
                      "valor":"AMPARADA"
                    },                                      
                    {
                      "titulo":"Asistencia vial Quálitas",
                      "valor":"AMPARADA"
                    },                                      
                    {
                      "titulo":"RC Daños a terceros EUA",
                      "valor":"AMPARADA"
                    },                                      
                  ]
                } else {
                  this.coberturas=[
                    {
                      "titulo":"Daños Materiales",
                      "valor":"5%"
                    },
                    {
                      "titulo":"Robo total",
                      "valor":"10%"
                    },
                    {
                      "titulo":"Responsabilidad civil",
                      "valor":3000000
                    },
                    {
                      "titulo":"Gastos médicos de ocupantes",
                      "valor":300000
                    },
                    {
                      "titulo":"Extensión RC",
                      "valor":"-"
                    },  
                    {
                      "titulo":"Muerte del con ductor por AA",
                      "valor":"$100,000"
                    },                                
                    {
                      "titulo":"Gastos Legales",
                      "valor":"AMPARADA"
                    },                                
                    {
                      "titulo":"Asistencia vial Quálitas",
                      "valor":"AMPARADA"
                    },                                
                    {
                      "titulo":"RC Daños a terceros EUA",
                      "valor":"AMPARADA"
                    },                                
                  ]
                }
              }
              else {
                this.coberturas=[
                  {
                    "titulo":"Daños Materiales",
                    "valor":"5%"
                  },
                  {
                    "titulo":"Robo total",
                    "valor":"10%"
                  },
                  {
                    "titulo":"Responsabilidad civil",
                    "valor":3000000
                  },
                  {
                    "titulo":"Gastos médicos de ocupantes",
                    "valor":300000
                  },
                  {
                    "titulo":"Extensión RC",
                    "valor":"-"
                  },  
                  {
                    "titulo":"Muerte del con ductor por AA",
                    "valor":"$100,000"
                  },                                
                  {
                    "titulo":"Gastos Legales",
                    "valor":"AMPARADA"
                  },                                
                  {
                    "titulo":"Asistencia vial Quálitas",
                    "valor":"AMPARADA"
                  },                                
                  {
                    "titulo":"RC Daños a terceros EUA",
                    "valor":"AMPARADA"
                  },                                
                ]
              }
              break;

            case 2: 
              if (this.coberturaChangeQualitas.length > 0 || this.gastosChangeQualitas.length > 0) {
                if (this.coberturaChangeQualitas[1] == 2 || this.gastosChangeQualitas[1] == 2) {
                  this.coberturas=[
                    {
                      "titulo":"Daños Materiales",
                      "valor":"5%"
                    },
                    {
                      "titulo":"Robo total",
                      "valor":"10%"
                    },
                    {
                      "titulo":"Responsabilidad civil",
                      "valor":this.coberturaChangeQualitas[2] ? this.coberturaChangeQualitas[2] : 3000000
                    },
                    {
                      "titulo":"Gastos médicos de ocupantes",
                      "valor":this.gastosChangeQualitas[2] ? this.gastosChangeQualitas[2] : 300000
                    },
                    {
                      "titulo":"Extensión RC",
                      "valor":"AMPARADA"
                    },  
                    {
                      "titulo":"Muerte del con ductor por AA",
                      "valor":"$100,000"
                    },                                      
                    {
                      "titulo":"Gastos Legales",
                      "valor":"AMPARADA"
                    },                                      
                    {
                      "titulo":"Asistencia vial Quálitas",
                      "valor":"AMPARADA"
                    },                                      
                    {
                      "titulo":"RC Daños a terceros EUA",
                      "valor":"AMPARADA"
                    },                                      
                  ]
                } else {
                  this.coberturas=[
                    {
                      "titulo":"Daños Materiales",
                      "valor":"-"
                    },
                    {
                      "titulo":"Robo total",
                      "valor":"10%"
                    },
                    {
                      "titulo":"Responsabilidad civil",
                      "valor":3000000
                    },
                    {
                      "titulo":"Gastos médicos de ocupantes",
                      "valor":300000
                    },
                    {
                      "titulo":"Extensión RC",
                      "valor":"-"
                    },
                    {
                      "titulo":"Muerte del con ductor por AA",
                      "valor":"$100,000"
                    },                                   
                    {
                      "titulo":"Gastos Legales",
                      "valor":"AMPARADA"
                    },                                   
                    {
                      "titulo":"Asistencia vial Quálitas",
                      "valor":"AMPARADA"
                    },                                   
                    {
                      "titulo":"RC Daños a terceros EUA",
                      "valor":"AMPARADA"
                    },                                   
                  ]
                }
              } else {
                this.coberturas=[
                  {
                    "titulo":"Daños Materiales",
                    "valor":"-"
                  },
                  {
                    "titulo":"Robo total",
                    "valor":"10%"
                  },
                  {
                    "titulo":"Responsabilidad civil",
                    "valor":3000000
                  },
                  {
                    "titulo":"Gastos médicos de ocupantes",
                    "valor":300000
                  },
                  {
                    "titulo":"Extensión RC",
                    "valor":"-"
                  },
                  {
                    "titulo":"Muerte del con ductor por AA",
                    "valor":"$100,000"
                  },                                   
                  {
                    "titulo":"Gastos Legales",
                    "valor":"AMPARADA"
                  },                                   
                  {
                    "titulo":"Asistencia vial Quálitas",
                    "valor":"AMPARADA"
                  },                                   
                  {
                    "titulo":"RC Daños a terceros EUA",
                    "valor":"AMPARADA"
                  },                                   
                ]
              }
              break;

            case 3: 
              if (this.coberturaChangeQualitas.length > 0 || this.gastosChangeQualitas.length > 0) {
                if (this.coberturaChangeQualitas[1] == 3 || this.gastosChangeQualitas[1] == 3) {
                  this.coberturas=[
                    {
                      "titulo":"Daños Materiales",
                      "valor":"5%"
                    },
                    {
                      "titulo":"Robo total",
                      "valor":"10%"
                    },
                    {
                      "titulo":"Responsabilidad civil",
                      "valor":this.coberturaChangeQualitas[2] ? this.coberturaChangeQualitas[2] : 3000000
                    },
                    {
                      "titulo":"Gastos médicos de ocupantes",
                      "valor":this.gastosChangeQualitas[2] ? this.gastosChangeQualitas[2] : 300000
                    },
                    {
                      "titulo":"Extensión RC",
                      "valor":"AMPARADA"
                    },  
                    {
                      "titulo":"Muerte del con ductor por AA",
                      "valor":"$100,000"
                    },                                      
                    {
                      "titulo":"Gastos Legales",
                      "valor":"AMPARADA"
                    },                                      
                    {
                      "titulo":"Asistencia vial Quálitas",
                      "valor":"AMPARADA"
                    },                                      
                    {
                      "titulo":"RC Daños a terceros EUA",
                      "valor":"AMPARADA"
                    },                                      
                  ]
                } else {   
                  this.coberturas=[
                    {
                      "titulo":"Daños Materiales",
                      "valor":"-"
                    },
                    {
                      "titulo":"Robo total",
                      "valor":"-"
                    },
                    {
                      "titulo":"Responsabilidad civil",
                      "valor":3000000
                    },
                    {
                      "titulo":"Gastos médicos de ocupantes",
                      "valor":300000
                    },
                    {
                      "titulo":"Extensión RC",
                      "valor":"AMPARADA"
                    },   
                    {
                      "titulo":"Muerte del con ductor por AA",
                      "valor":"-"
                    },                                   
                    {
                      "titulo":"Gastos Legales",
                      "valor":"AMPARADA"
                    },                                   
                    {
                      "titulo":"Asistencia vial Quálitas",
                      "valor":"AMPARADA"
                    },                                   
                    {
                      "titulo":"RC Daños a terceros EUA",
                      "valor":"AMPARADA"
                    },                                   
                  ]
                }
              } else {
                this.coberturas=[
                  {
                    "titulo":"Daños Materiales",
                    "valor":"-"
                  },
                  {
                    "titulo":"Robo total",
                    "valor":"-"
                  },
                  {
                    "titulo":"Responsabilidad civil",
                    "valor":3000000
                  },
                  {
                    "titulo":"Gastos médicos de ocupantes",
                    "valor":300000
                  },
                  {
                    "titulo":"Extensión RC",
                    "valor":"AMPARADA"
                  },   
                  {
                    "titulo":"Muerte del con ductor por AA",
                    "valor":"-"
                  },                                   
                  {
                    "titulo":"Gastos Legales",
                    "valor":"AMPARADA"
                  },                                   
                  {
                    "titulo":"Asistencia vial Quálitas",
                    "valor":"AMPARADA"
                  },                                   
                  {
                    "titulo":"RC Daños a terceros EUA",
                    "valor":"AMPARADA"
                  },                                   
                ]
              }
              break;

            case 14 || 4: 
              if (this.coberturaChangeQualitas.length > 0 || this.gastosChangeQualitas.length > 0) {
                if (this.coberturaChangeQualitas[1] == 14 || this.gastosChangeQualitas[1] == 14) {
                  this.coberturas=[
                    {
                      "titulo":"Daños Materiales",
                      "valor":"5%"
                    },
                    {
                      "titulo":"Robo total",
                      "valor":"10%"
                    },
                    {
                      "titulo":"Responsabilidad civil",
                      "valor":this.coberturaChangeQualitas[2] ? this.coberturaChangeQualitas[2] : 3000000
                    },
                    {
                      "titulo":"Gastos médicos de ocupantes",
                      "valor":this.gastosChangeQualitas[2] ? this.gastosChangeQualitas[2] : 300000
                    },
                    {
                      "titulo":"Extensión RC",
                      "valor":"AMPARADA"
                    },  
                    {
                      "titulo":"Muerte del con ductor por AA",
                      "valor":"$100,000"
                    },                                      
                    {
                      "titulo":"Gastos Legales",
                      "valor":"AMPARADA"
                    },                                      
                    {
                      "titulo":"Asistencia vial Quálitas",
                      "valor":"AMPARADA"
                    },                                      
                    {
                      "titulo":"RC Daños a terceros EUA",
                      "valor":"AMPARADA"
                    },                                      
                  ]
                } else {
                  this.coberturas=[
                    {
                      "titulo":"Daños Materiales",
                      "valor":"5%"
                    },
                    {
                      "titulo":"Robo total",
                      "valor":"10%"
                    },
                    {
                      "titulo":"Responsabilidad civil",
                      "valor":3000000
                    },
                    {
                      "titulo":"Gastos médicos de ocupantes",
                      "valor":300000
                    },
                    {
                      "titulo":"Extensión RC",
                      "valor":"AMPARADA"
                    },  
                    {
                      "titulo":"Muerte del con ductor por AA",
                      "valor":"$100,000"
                    },                                      
                    {
                      "titulo":"Gastos Legales",
                      "valor":"AMPARADA"
                    },                                      
                    {
                      "titulo":"Asistencia vial Quálitas",
                      "valor":"AMPARADA"
                    },                                      
                    {
                      "titulo":"RC Daños a terceros EUA",
                      "valor":"AMPARADA"
                    },                                      
                  ]
                }
              } else {
                this.coberturas=[
                  {
                    "titulo":"Daños Materiales",
                    "valor":"5%"
                  },
                  {
                    "titulo":"Robo total",
                    "valor":"10%"
                  },
                  {
                    "titulo":"Responsabilidad civil",
                    "valor":3000000
                  },
                  {
                    "titulo":"Gastos médicos de ocupantes",
                    "valor":300000
                  },
                  {
                    "titulo":"Extensión RC",
                    "valor":"AMPARADA"
                  },  
                  {
                    "titulo":"Muerte del con ductor por AA",
                    "valor":"$100,000"
                  },                                      
                  {
                    "titulo":"Gastos Legales",
                    "valor":"AMPARADA"
                  },                                      
                  {
                    "titulo":"Asistencia vial Quálitas",
                    "valor":"AMPARADA"
                  },                                      
                  {
                    "titulo":"RC Daños a terceros EUA",
                    "valor":"AMPARADA"
                  },                                      
                ]
              }
              break;

            default:
              break;
          }
        break;
      case "aig":
        switch (this.plan) {
            case 1:
              if (this.coberturaChangeAig.length > 0 || this.gastosChangeAig.length > 0) {
                if (this.coberturaChangeAig[1] == 1 || this.gastosChangeAig[1] == 1) {
                  this.coberturas=[
                  {
                    "titulo":"Daños Materiales",
                    "valor":"5%"
                  },
                  {
                    "titulo":"Robo total",
                    "valor":"10%"
                  },
                  {
                    "titulo":"Responsabilidad civil",
                    "valor":this.coberturaChangeAig[2] ? this.coberturaChangeAig[2] : 3000000
                  },
                  {
                    "titulo":"Extensión RC",
                    "valor":"AMPARADA"
                  },  
                  {
                    "titulo":"Gastos médicos de ocupantes",
                    "valor":this.gastosChangeAig[2] ? this.gastosChangeAig[2] : 120000
                  },
                  {
                    "titulo":"Accidentes Automovilísticos al conductor",
                    "valor":"AMPARADA"
                  },                                
                  {
                    "titulo":"Asistencia juridica",
                    "valor":"AMPARADA"
                  },                                
                  {
                    "titulo":"Asistencia funeraria",
                    "valor":"AMPARADA"
                  },                                
                  {
                    "titulo":"Asistencia en viajes",
                    "valor":"AMPARADA"
                  },                                
                  {
                    "titulo":"RC Daños a terceros EUA",
                    "valor":"AMPARADA"
                  },                                
                ]
                } else {
                  this.coberturas=[
                    {
                      "titulo":"Daños Materiales",
                      "valor":"5%"
                    },
                    {
                      "titulo":"Robo total",
                      "valor":"10%"
                    },
                    {
                      "titulo":"Responsabilidad civil",
                      "valor":3000000
                    },
                    {
                      "titulo":"Extensión RC",
                      "valor":"AMPARADA"
                    },  
                    {
                      "titulo":"Gastos médicos de ocupantes",
                      "valor":120000
                    },
                    {
                      "titulo":"Accidentes Automovilísticos al conductor",
                      "valor":"AMPARADA"
                    },                                
                    {
                      "titulo":"Asistencia juridica",
                      "valor":"AMPARADA"
                    },                                
                    {
                      "titulo":"Asistencia funeraria",
                      "valor":"AMPARADA"
                    },                                
                    {
                      "titulo":"Asistencia en viajes",
                      "valor":"AMPARADA"
                    },                                
                    {
                      "titulo":"RC Daños a terceros EUA",
                      "valor":"AMPARADA"
                    },                                
                  ]
                }
              }
              else {
                this.coberturas=[
                  {
                    "titulo":"Daños Materiales",
                    "valor":"5%"
                  },
                  {
                    "titulo":"Robo total",
                    "valor":"10%"
                  },
                  {
                    "titulo":"Responsabilidad civil",
                    "valor":3000000
                  },
                  {
                    "titulo":"Extensión RC",
                    "valor":"AMPARADA"
                  },  
                  {
                    "titulo":"Gastos médicos de ocupantes",
                    "valor":120000
                  },
                  {
                    "titulo":"Accidentes Automovilísticos al conductor",
                    "valor":"AMPARADA"
                  },                                
                  {
                    "titulo":"Asistencia juridica",
                    "valor":"AMPARADA"
                  },                                
                  {
                    "titulo":"Asistencia funeraria",
                    "valor":"AMPARADA"
                  },                                
                  {
                    "titulo":"Asistencia en viajes",
                    "valor":"AMPARADA"
                  },                                
                  {
                    "titulo":"RC Daños a terceros EUA",
                    "valor":"AMPARADA"
                  },                                
                ]
              }
              break;

            case 2:
              if (this.coberturaChangeAig.length > 0 || this.gastosChangeAig.length > 0) {
                if (this.coberturaChangeAig[1] == 2 || this.gastosChangeAig[1] == 2) {
                  this.coberturas=[
                    {
                      "titulo":"Daños Materiales",
                      "valor":"-"
                    },
                    {
                      "titulo":"Robo total",
                      "valor":"10%"
                    },
                    {
                      "titulo":"Responsabilidad civil",
                      "valor":this.coberturaChangeAig[2] ? this.coberturaChangeAig[2] : 3000000
                    },
                    {
                      "titulo":"Extensión RC",
                      "valor":"AMPARADA"
                    },
                    {
                      "titulo":"Gastos médicos de ocupantes",
                      "valor":this.gastosChangeAig[2] ? this.gastosChangeAig[2] : 120000
                    },
                    {
                      "titulo":"Accidentes Automovilísticos al conductor",
                      "valor":"AMPARADA"
                    },                                   
                    {
                      "titulo":"Asistencia juridica",
                      "valor":"AMPARADA"
                    },                                   
                    {
                      "titulo":"Asistencia funeraria",
                      "valor":"AMPARADA"
                    },                                   
                    {
                      "titulo":"Asistencia en viajes",
                      "valor":"AMPARADA"
                    },                                   
                    {
                      "titulo":"RC Daños a terceros EUA",
                      "valor":"AMPARADA"
                    },                                   
                  ]
                } else {
                  this.coberturas=[
                    {
                      "titulo":"Daños Materiales",
                      "valor":"-"
                    },
                    {
                      "titulo":"Robo total",
                      "valor":"10%"
                    },
                    {
                      "titulo":"Responsabilidad civil",
                      "valor":3000000
                    },
                    {
                      "titulo":"Extensión RC",
                      "valor":"AMPARADA"
                    },
                    {
                      "titulo":"Gastos médicos de ocupantes",
                      "valor":120000
                    },
                    {
                      "titulo":"Accidentes Automovilísticos al conductor",
                      "valor":"AMPARADA"
                    },                                   
                    {
                      "titulo":"Asistencia juridica",
                      "valor":"AMPARADA"
                    },                                   
                    {
                      "titulo":"Asistencia funeraria",
                      "valor":"AMPARADA"
                    },                                   
                    {
                      "titulo":"Asistencia en viajes",
                      "valor":"AMPARADA"
                    },                                   
                    {
                      "titulo":"RC Daños a terceros EUA",
                      "valor":"AMPARADA"
                    },                                   
                  ]
                }
              } else {
                this.coberturas=[
                  {
                    "titulo":"Daños Materiales",
                    "valor":"-"
                  },
                  {
                    "titulo":"Robo total",
                    "valor":"10%"
                  },
                  {
                    "titulo":"Responsabilidad civil",
                    "valor":3000000
                  },
                  {
                    "titulo":"Extensión RC",
                    "valor":"AMPARADA"
                  },
                  {
                    "titulo":"Gastos médicos de ocupantes",
                    "valor":120000
                  },
                  {
                    "titulo":"Accidentes Automovilísticos al conductor",
                    "valor":"AMPARADA"
                  },                                   
                  {
                    "titulo":"Asistencia juridica",
                    "valor":"AMPARADA"
                  },                                   
                  {
                    "titulo":"Asistencia funeraria",
                    "valor":"AMPARADA"
                  },                                   
                  {
                    "titulo":"Asistencia en viajes",
                    "valor":"AMPARADA"
                  },                                   
                  {
                    "titulo":"RC Daños a terceros EUA",
                    "valor":"AMPARADA"
                  },                                   
                ]
              }
              break;

            case 3:
              if (this.coberturaChangeAig.length > 0 || this.gastosChangeAig.length > 0) {
                if (this.coberturaChangeAig[1] == 3 || this.gastosChangeAig[1] == 3) {
                  this.coberturas=[
                    {
                      "titulo":"Daños Materiales",
                      "valor":"-"
                    },
                    {
                      "titulo":"Robo total",
                      "valor":"-"
                    },
                    {
                      "titulo":"Responsabilidad civil",
                      "valor":this.coberturaChangeAig[2] ? this.coberturaChangeAig[2] : 3000000
                    },
                    {
                      "titulo":"Extensión RC",
                      "valor":"AMPARADA"
                    }, 
                    {
                      "titulo":"Gastos médicos de ocupantes",
                      "valor":this.gastosChangeAig[2] ? this.gastosChangeAig[2] : 120000
                    },
                    {
                      "titulo":"Accidentes Automovilísticos al conductor",
                      "valor":"-"
                    },                                   
                    {
                      "titulo":"Asistencia juridica",
                      "valor":"AMPARADA"
                    },                                   
                    {
                      "titulo":"Asistencia funeraria",
                      "valor":"AMPARADA"
                    },                                   
                    {
                      "titulo":"Asistencia en viajes",
                      "valor":"AMPARADA"
                    },                                   
                    {
                      "titulo":"RC Daños a terceros EUA",
                      "valor":"AMPARADA"
                    },                                   
                  ]
                } else {
                  this.coberturas=[
                    {
                      "titulo":"Daños Materiales",
                      "valor":"-"
                    },
                    {
                      "titulo":"Robo total",
                      "valor":"-"
                    },
                    {
                      "titulo":"Responsabilidad civil",
                      "valor":3000000
                    },
                    {
                      "titulo":"Extensión RC",
                      "valor":"AMPARADA"
                    }, 
                    {
                      "titulo":"Gastos médicos de ocupantes",
                      "valor":120000
                    },
                    {
                      "titulo":"Accidentes Automovilísticos al conductor",
                      "valor":"-"
                    },                                   
                    {
                      "titulo":"Asistencia juridica",
                      "valor":"AMPARADA"
                    },                                   
                    {
                      "titulo":"Asistencia funeraria",
                      "valor":"AMPARADA"
                    },                                   
                    {
                      "titulo":"Asistencia en viajes",
                      "valor":"AMPARADA"
                    },                                   
                    {
                      "titulo":"RC Daños a terceros EUA",
                      "valor":"AMPARADA"
                    },                                   
                  ]
                }
              } else {
                this.coberturas=[
                  {
                    "titulo":"Daños Materiales",
                    "valor":"-"
                  },
                  {
                    "titulo":"Robo total",
                    "valor":"-"
                  },
                  {
                    "titulo":"Responsabilidad civil",
                    "valor":3000000
                  },
                  {
                    "titulo":"Extensión RC",
                    "valor":"AMPARADA"
                  }, 
                  {
                    "titulo":"Gastos médicos de ocupantes",
                    "valor":120000
                  },
                  {
                    "titulo":"Accidentes Automovilísticos al conductor",
                    "valor":"-"
                  },                                   
                  {
                    "titulo":"Asistencia juridica",
                    "valor":"AMPARADA"
                  },                                   
                  {
                    "titulo":"Asistencia funeraria",
                    "valor":"AMPARADA"
                  },                                   
                  {
                    "titulo":"Asistencia en viajes",
                    "valor":"AMPARADA"
                  },                                   
                  {
                    "titulo":"RC Daños a terceros EUA",
                    "valor":"AMPARADA"
                  },                                   
                ]
              }
              break;

            case 14 || 4:
              if (this.coberturaChangeAig.length > 0 || this.gastosChangeAig.length > 0) {
                if (this.coberturaChangeAig[1] == 14 || this.gastosChangeAig[1] == 14) {
                  this.coberturas=[
                    {
                      "titulo":"Daños Materiales",
                      "valor":"5%"
                    },
                    {
                      "titulo":"Robo total",
                      "valor":"10%"
                    },
                    {
                      "titulo":"Responsabilidad civil",
                      "valor":this.coberturaChangeAig[2] ? this.coberturaChangeAig[2] : 3000000
                    },
                    {
                      "titulo":"Extensión RC",
                      "valor":"AMPARADA"
                    }, 
                    {
                      "titulo":"Gastos médicos de ocupantes",
                      "valor":this.gastosChangeAig[2] ? this.gastosChangeAig[2] : 120000
                    },
                    {
                      "titulo":"Accidentes Automovilísticos al conductor",
                      "valor":"AMPARADA"
                    },                                      
                    {
                      "titulo":"Asistencia juridica",
                      "valor":"AMPARADA"
                    },                                      
                    {
                      "titulo":"Asistencia funeraria",
                      "valor":"AMPARADA"
                    },                                      
                    {
                      "titulo":"Asistencia en viajes",
                      "valor":"AMPARADA"
                    },                                      
                    {
                      "titulo":"RC Daños a terceros EUA",
                      "valor":"AMPARADA"
                    },                                      
                  ]
                } else {
                  this.coberturas=[
                    {
                      "titulo":"Daños Materiales",
                      "valor":"5%"
                    },
                    {
                      "titulo":"Robo total",
                      "valor":"10%"
                    },
                    {
                      "titulo":"Responsabilidad civil",
                      "valor":3000000
                    },
                    {
                      "titulo":"Extensión RC",
                      "valor":"AMPARADA"
                    }, 
                    {
                      "titulo":"Gastos médicos de ocupantes",
                      "valor":120000
                    },
                    {
                      "titulo":"Accidentes Automovilísticos al conductor",
                      "valor":"AMPARADA"
                    },                                      
                    {
                      "titulo":"Asistencia juridica",
                      "valor":"AMPARADA"
                    },                                      
                    {
                      "titulo":"Asistencia funeraria",
                      "valor":"AMPARADA"
                    },                                      
                    {
                      "titulo":"Asistencia en viajes",
                      "valor":"AMPARADA"
                    },                                      
                    {
                      "titulo":"RC Daños a terceros EUA",
                      "valor":"AMPARADA"
                    },                                      
                  ]
                }
              } else {
                this.coberturas=[
                  {
                    "titulo":"Daños Materiales",
                    "valor":"5%"
                  },
                  {
                    "titulo":"Robo total",
                    "valor":"10%"
                  },
                  {
                    "titulo":"Responsabilidad civil",
                    "valor":3000000
                  },
                  {
                    "titulo":"Extensión RC",
                    "valor":"AMPARADA"
                  }, 
                  {
                    "titulo":"Gastos médicos de ocupantes",
                    "valor":120000
                  },
                  {
                    "titulo":"Accidentes Automovilísticos al conductor",
                    "valor":"AMPARADA"
                  },                                      
                  {
                    "titulo":"Asistencia juridica",
                    "valor":"AMPARADA"
                  },                                      
                  {
                    "titulo":"Asistencia funeraria",
                    "valor":"AMPARADA"
                  },                                      
                  {
                    "titulo":"Asistencia en viajes",
                    "valor":"AMPARADA"
                  },                                      
                  {
                    "titulo":"RC Daños a terceros EUA",
                    "valor":"AMPARADA"
                  },                                      
                ]
              }
              break;

            default:
              break;
          }
        break;
      case "gnp":
        switch (this.plan) {
            case 1:
              // if (this.coberturaChangeGnp.length > 0 || this.gastosChangeGnp.length > 0) {
              //   if (this.coberturaChangeGnp[1] == 1 || this.gastosChangeGnp[1] == 1) {
              //     this.coberturas=[
              //       {
              //         "titulo":"Daños Materiales",
              //         "valor":"5%"
              //       },
              //       {
              //         "titulo":"Robo total",
              //         "valor":"10%"
              //       },
              //       {
              //         "titulo":"Cristales",
              //         "valor":"20%"
              //       },
              //       {
              //         "titulo":"Responsabilidad civil",
              //         "valor":this.coberturaChangeGnp[2] ? this.coberturaChangeGnp[2] : 3000000
              //       },
              //       {
              //         "titulo":"Extensión RC",
              //         "valor":"AMPARADA"
              //       },  
              //       {
              //         "titulo":"Gastos médicos de ocupantes",
              //         "valor":this.gastosChangeGnp[2] ? this.gastosChangeGnp[2] : 200000
              //       },                            
              //       {
              //         "titulo":"Asistencia juridica",
              //         "valor":"AMPARADA"
              //       },                                
              //       {
              //         "titulo":"Club GNP autos",
              //         "valor":"AMPARADA"
              //       },                                
              //       {
              //         "titulo":"Ayuda llantas/rines",
              //         "valor":"-"
              //       },                                 
              //       {
              //         "titulo":"Llave de repuesto",
              //         "valor":"-"
              //       },
              //       {
              //         "titulo":"Pago cero",
              //         "valor":"-"
              //       },                             
              //       {
              //         "titulo":"Siempre en agencia",
              //         "valor":"-"
              //       },                                
              //     ]
              //   } else {
              //     this.coberturas=[
              //       {
              //         "titulo":"Daños Materiales",
              //         "valor":"5%"
              //       },
              //       {
              //         "titulo":"Robo total",
              //         "valor":"10%"
              //       },
              //       {
              //         "titulo":"Cristales",
              //         "valor":"20%"
              //       },
              //       {
              //         "titulo":"Responsabilidad civil",
              //         "valor":3000000
              //       },
              //       {
              //         "titulo":"Extensión RC",
              //         "valor":"AMPARADA"
              //       },  
              //       {
              //         "titulo":"Gastos médicos de ocupantes",
              //         "valor":200000
              //       },                            
              //       {
              //         "titulo":"Asistencia juridica",
              //         "valor":"AMPARADA"
              //       },                                
              //       {
              //         "titulo":"Club GNP autos",
              //         "valor":"AMPARADA"
              //       },                                
              //       {
              //         "titulo":"Ayuda llantas/rines",
              //         "valor":"-"
              //       },                                 
              //       {
              //         "titulo":"Llave de repuesto",
              //         "valor":"-"
              //       },
              //       {
              //         "titulo":"Pago cero",
              //         "valor":"-"
              //       },                             
              //       {
              //         "titulo":"Siempre en agencia",
              //         "valor":"-"
              //       },                                
              //     ]
              //   }
              // } else {
              //   this.coberturas=[
              //     {
              //       "titulo":"Daños Materiales",
              //       "valor":"5%"
              //     },
              //     {
              //       "titulo":"Robo total",
              //       "valor":"10%"
              //     },
              //     {
              //       "titulo":"Cristales",
              //       "valor":"20%"
              //     },
              //     {
              //       "titulo":"Responsabilidad civil",
              //       "valor":3000000
              //     },
              //     {
              //       "titulo":"Extensión RC",
              //       "valor":"AMPARADA"
              //     },  
              //     {
              //       "titulo":"Gastos médicos de ocupantes",
              //       "valor":200000
              //     },                            
              //     {
              //       "titulo":"Asistencia juridica",
              //       "valor":"AMPARADA"
              //     },                                
              //     {
              //       "titulo":"Club GNP autos",
              //       "valor":"AMPARADA"
              //     },                                
              //     {
              //       "titulo":"Ayuda llantas/rines",
              //       "valor":"-"
              //     },                                 
              //     {
              //       "titulo":"Llave de repuesto",
              //       "valor":"-"
              //     },
              //     {
              //       "titulo":"Pago cero",
              //       "valor":"-"
              //     },                             
              //     {
              //       "titulo":"Siempre en agencia",
              //       "valor":"-"
              //     },                                
              //   ]
              // }
              break;

            case 2:
              if (this.coberturaChangeGnp.length > 0 || this.gastosChangeGnp.length > 0) {
                if (this.coberturaChangeGnp[1] == 2 || this.gastosChangeGnp[1] == 2) {
                  this.coberturas=[
                    {
                      "titulo":"Daños Materiales",
                      "valor":"-"
                    },
                    {
                      "titulo":"Robo total",
                      "valor":"10%"
                    },
                    {
                      "titulo":"Cristales",
                      "valor":"-"
                    },
                    {
                      "titulo":"Responsabilidad civil",
                      "valor":this.coberturaChangeGnp[2] ? this.coberturaChangeGnp[2] : 3000000
                    },
                    {
                      "titulo":"Extensión RC",
                      "valor":"AMPARADA"
                    },
                    {
                      "titulo":"Gastos médicos de ocupantes",
                      "valor":this.gastosChangeGnp[2] ? this.gastosChangeGnp[2] : 200000
                    },                                 
                    {
                      "titulo":"Asistencia juridica",
                      "valor":"AMPARADA"
                    },                                   
                    {
                      "titulo":"Club GNP autos",
                      "valor":"AMPARADA"
                    },                                   
                    {
                      "titulo":"Ayuda llantas/rines",
                      "valor":"-"
                    },   
                    {
                      "titulo":"Llave de repuesto",
                      "valor":"-"
                    }, 
                    {
                      "titulo":"Pago cero",
                      "valor":"-"
                    },                                  
                    {
                      "titulo":"Siempre en agencia",
                      "valor":"-"
                    },                                 
                  ]
                } else {
                  this.coberturas=[
                    {
                      "titulo":"Daños Materiales",
                      "valor":"-"
                    },
                    {
                      "titulo":"Robo total",
                      "valor":"10%"
                    },
                    {
                      "titulo":"Cristales",
                      "valor":"-"
                    },
                    {
                      "titulo":"Responsabilidad civil",
                      "valor":3000000
                    },
                    {
                      "titulo":"Extensión RC",
                      "valor":"AMPARADA"
                    },
                    {
                      "titulo":"Gastos médicos de ocupantes",
                      "valor":200000
                    },                                 
                    {
                      "titulo":"Asistencia juridica",
                      "valor":"AMPARADA"
                    },                                   
                    {
                      "titulo":"Club GNP autos",
                      "valor":"AMPARADA"
                    },                                   
                    {
                      "titulo":"Ayuda llantas/rines",
                      "valor":"-"
                    },   
                    {
                      "titulo":"Llave de repuesto",
                      "valor":"-"
                    }, 
                    {
                      "titulo":"Pago cero",
                      "valor":"-"
                    },                                  
                    {
                      "titulo":"Siempre en agencia",
                      "valor":"-"
                    },                                 
                  ]
                }
              } else {
                this.coberturas=[
                  {
                    "titulo":"Daños Materiales",
                    "valor":"-"
                  },
                  {
                    "titulo":"Robo total",
                    "valor":"10%"
                  },
                  {
                    "titulo":"Cristales",
                    "valor":"-"
                  },
                  {
                    "titulo":"Responsabilidad civil",
                    "valor":3000000
                  },
                  {
                    "titulo":"Extensión RC",
                    "valor":"AMPARADA"
                  },
                  {
                    "titulo":"Gastos médicos de ocupantes",
                    "valor":200000
                  },                                 
                  {
                    "titulo":"Asistencia juridica",
                    "valor":"AMPARADA"
                  },                                   
                  {
                    "titulo":"Club GNP autos",
                    "valor":"AMPARADA"
                  },                                   
                  {
                    "titulo":"Ayuda llantas/rines",
                    "valor":"-"
                  },   
                  {
                    "titulo":"Llave de repuesto",
                    "valor":"-"
                  }, 
                  {
                    "titulo":"Pago cero",
                    "valor":"-"
                  },                                  
                  {
                    "titulo":"Siempre en agencia",
                    "valor":"-"
                  },                                 
                ]
              }
              break;

            case 3:
              if (this.coberturaChangeGnp.length > 0 || this.gastosChangeGnp.length > 0) {
                if (this.coberturaChangeGnp[1] == 3 || this.gastosChangeGnp[1] == 3) {
                  this.coberturas=[
                    {
                      "titulo":"Daños Materiales",
                      "valor":"-"
                    },
                    {
                      "titulo":"Robo total",
                      "valor":"-"
                    },
                    {
                      "titulo":"Cristales",
                      "valor":"-"
                    },
                    {
                      "titulo":"Responsabilidad civil",
                      "valor":this.coberturaChangeGnp[2] ? this.coberturaChangeGnp[2] : 3000000
                    },
                    {
                      "titulo":"Extensión RC",
                      "valor":"AMPARADA"
                    }, 
                    {
                      "titulo":"Gastos médicos de ocupantes",
                      "valor":this.gastosChangeGnp[2] ? this.gastosChangeGnp[2] : 200000
                    },                                 
                    {
                      "titulo":"Asistencia juridica",
                      "valor":"AMPARADA"
                    },                                   
                    {
                      "titulo":"Club GNP autos",
                      "valor":"AMPARADA"
                    },                                   
                    {
                      "titulo":"Ayuda llantas/rines",
                      "valor":"-"
                    },    
                    {
                      "titulo":"Llave de repuesto",
                      "valor":"-"
                    },    
                    {
                      "titulo":"Pago cero",
                      "valor":"-"
                    },                              
                    {
                      "titulo":"Siempre en agencia",
                      "valor":"-"
                    },                                   
                  ]
                } else {
                  this.coberturas=[
                    {
                      "titulo":"Daños Materiales",
                      "valor":"-"
                    },
                    {
                      "titulo":"Robo total",
                      "valor":"-"
                    },
                    {
                      "titulo":"Cristales",
                      "valor":"-"
                    },
                    {
                      "titulo":"Responsabilidad civil",
                      "valor":3000000
                    },
                    {
                      "titulo":"Extensión RC",
                      "valor":"AMPARADA"
                    }, 
                    {
                      "titulo":"Gastos médicos de ocupantes",
                      "valor":200000
                    },                                 
                    {
                      "titulo":"Asistencia juridica",
                      "valor":"AMPARADA"
                    },                                   
                    {
                      "titulo":"Club GNP autos",
                      "valor":"AMPARADA"
                    },                                   
                    {
                      "titulo":"Ayuda llantas/rines",
                      "valor":"-"
                    },    
                    {
                      "titulo":"Llave de repuesto",
                      "valor":"-"
                    },    
                    {
                      "titulo":"Pago cero",
                      "valor":"-"
                    },                              
                    {
                      "titulo":"Siempre en agencia",
                      "valor":"-"
                    },                                   
                  ]
                }
              } else {
                this.coberturas=[
                  {
                    "titulo":"Daños Materiales",
                    "valor":"-"
                  },
                  {
                    "titulo":"Robo total",
                    "valor":"-"
                  },
                  {
                    "titulo":"Cristales",
                    "valor":"-"
                  },
                  {
                    "titulo":"Responsabilidad civil",
                    "valor":3000000
                  },
                  {
                    "titulo":"Extensión RC",
                    "valor":"AMPARADA"
                  }, 
                  {
                    "titulo":"Gastos médicos de ocupantes",
                    "valor":200000
                  },                                 
                  {
                    "titulo":"Asistencia juridica",
                    "valor":"AMPARADA"
                  },                                   
                  {
                    "titulo":"Club GNP autos",
                    "valor":"AMPARADA"
                  },                                   
                  {
                    "titulo":"Ayuda llantas/rines",
                    "valor":"-"
                  },    
                  {
                    "titulo":"Llave de repuesto",
                    "valor":"-"
                  },    
                  {
                    "titulo":"Pago cero",
                    "valor":"-"
                  },                              
                  {
                    "titulo":"Siempre en agencia",
                    "valor":"-"
                  },                                   
                ]
              }
              break;

            case 14 || 4:
              if (this.coberturaChangeGnp.length > 0 || this.gastosChangeGnp.length > 0) {
                if (this.coberturaChangeGnp[1] == 14 || this.gastosChangeGnp[1] == 14) {
                  this.coberturas=[
                    {
                      "titulo":"Daños Materiales",
                      "valor":"5%"
                    },
                    {
                      "titulo":"Robo total",
                      "valor":"10%"
                    },
                    {
                      "titulo":"Cristales",
                      "valor":"20%"
                    },
                    {
                      "titulo":"Responsabilidad civil",
                      "valor":this.coberturaChangeGnp[2] ? this.coberturaChangeGnp[2] : 3000000
                    },
                    {
                      "titulo":"Extensión RC",
                      "valor":"AMPARADA"
                    }, 
                    {
                      "titulo":"Gastos médicos de ocupantes",
                      "valor":this.gastosChangeGnp[2] ? this.gastosChangeGnp[2] : 200000
                    },                                     
                    {
                      "titulo":"Asistencia juridica",
                      "valor":"AMPARADA"
                    },                                      
                    {
                      "titulo":"Club GNP autos",
                      "valor":"AMPARADA"
                    },                                      
                    {
                      "titulo":"Ayuda llantas/rines",
                      "valor":"Un evento por año póliza"
                    },    
                    {
                      "titulo":"Llave de repuesto",
                      "valor":"Un evento por año póliza"
                    },                                    
                    {
                      "titulo":"Pago cero",
                      "valor":"AMPARADA"
                    },                                      
                    {
                      "titulo":"Siempre en agencia",
                      "valor":"AMPARADA"
                    },                                      
                  ]
                } else {
                  this.coberturas=[
                    {
                      "titulo":"Daños Materiales",
                      "valor":"5%"
                    },
                    {
                      "titulo":"Robo total",
                      "valor":"10%"
                    },
                    {
                      "titulo":"Cristales",
                      "valor":"20%"
                    },
                    {
                      "titulo":"Responsabilidad civil",
                      "valor":3000000
                    },
                    {
                      "titulo":"Extensión RC",
                      "valor":"AMPARADA"
                    }, 
                    {
                      "titulo":"Gastos médicos de ocupantes",
                      "valor":200000
                    },                                     
                    {
                      "titulo":"Asistencia juridica",
                      "valor":"AMPARADA"
                    },                                      
                    {
                      "titulo":"Club GNP autos",
                      "valor":"AMPARADA"
                    },                                      
                    {
                      "titulo":"Ayuda llantas/rines",
                      "valor":"Un evento por año póliza"
                    },    
                    {
                      "titulo":"Llave de repuesto",
                      "valor":"Un evento por año póliza"
                    },                                    
                    {
                      "titulo":"Pago cero",
                      "valor":"AMPARADA"
                    },                                      
                    {
                      "titulo":"Siempre en agencia",
                      "valor":"AMPARADA"
                    },                                      
                  ]
                }
              } else {
                this.coberturas=[
                  {
                    "titulo":"Daños Materiales",
                    "valor":"5%"
                  },
                  {
                    "titulo":"Robo total",
                    "valor":"10%"
                  },
                  {
                    "titulo":"Cristales",
                    "valor":"20%"
                  },
                  {
                    "titulo":"Responsabilidad civil",
                    "valor":3000000
                  },
                  {
                    "titulo":"Extensión RC",
                    "valor":"AMPARADA"
                  }, 
                  {
                    "titulo":"Gastos médicos de ocupantes",
                    "valor":200000
                  },                                     
                  {
                    "titulo":"Asistencia juridica",
                    "valor":"AMPARADA"
                  },                                      
                  {
                    "titulo":"Club GNP autos",
                    "valor":"AMPARADA"
                  },                                      
                  {
                    "titulo":"Ayuda llantas/rines",
                    "valor":"Un evento por año póliza"
                  },    
                  {
                    "titulo":"Llave de repuesto",
                    "valor":"Un evento por año póliza"
                  },                                    
                  {
                    "titulo":"Pago cero",
                    "valor":"AMPARADA"
                  },                                      
                  {
                    "titulo":"Siempre en agencia",
                    "valor":"AMPARADA"
                  },                                      
                ]
              }
              break;

            default:
              break;
          }
        break;
      case "ana":
        switch (this.plan) {
            case 1:
              this.coberturas=[
                {
                  "titulo":"Daños Materiales",
                  "valor":"5%"
                },
                {
                  "titulo":"Robo total",
                  "valor":"10%"
                },
                {
                  "titulo":"Responsabilidad civil",
                  "valor":3000000
                },
                {
                  "titulo":"Extensión RC",
                  "valor":"AMPARADA"
                }, 
                {
                  "titulo":"Accidentes automovilisticos al conductor",
                  "valor":"AMPARADA"
                },                           
                {
                  "titulo":"Asistencia juridica",
                  "valor":"AMPARADA"
                },                                
                {
                  "titulo":"Asistencia funeraria",
                  "valor":"AMPARADA"
                },                                
                {
                  "titulo":"Asistencia de viajes",
                  "valor":"AMPARADA"
                },                             
                {
                  "titulo":"RC Daño a terceros en EUA",
                  "valor":"AMPARADA"
                },                                
              ]
              break;

            case 2:
              this.coberturas=[
                {
                  "titulo":"Daños Materiales",
                  "valor":"-"
                },
                {
                  "titulo":"Robo total",
                  "valor":"10%"
                },
                {
                  "titulo":"Responsabilidad civil",
                  "valor":3000000
                },
                {
                  "titulo":"Extensión RC",
                  "valor":"AMPARADA"
                },
                {
                  "titulo":"Accidentes automovilisticos al conductor",
                  "valor":"AMPARADA"
                },                                 
                {
                  "titulo":"Asistencia juridica",
                  "valor":"AMPARADA"
                },                                
                {
                  "titulo":"Asistencia funeraria",
                  "valor":"AMPARADA"
                },                                
                {
                  "titulo":"Asistencia de viajes",
                  "valor":"AMPARADA"
                },                             
                {
                  "titulo":"RC Daño a terceros en EUA",
                  "valor":"AMPARADA"
                },                                 
              ]
              break;

            case 3:
              this.coberturas=[
                {
                  "titulo":"Daños Materiales",
                  "valor":"-"
                },
                {
                  "titulo":"Robo total",
                  "valor":"-"
                },
                {
                  "titulo":"Responsabilidad civil",
                  "valor":3000000
                },
                {
                  "titulo":"Extensión RC",
                  "valor":"AMPARADA"
                }, 
                {
                  "titulo":"Accidentes automovilisticos al conductor",
                  "valor":"AMPARADA"
                },                                 
                {
                  "titulo":"Asistencia juridica",
                  "valor":"AMPARADA"
                },                                
                {
                  "titulo":"Asistencia funeraria",
                  "valor":"AMPARADA"
                },                                
                {
                  "titulo":"Asistencia de viajes",
                  "valor":"AMPARADA"
                },                             
                {
                  "titulo":"RC Daño a terceros en EUA",
                  "valor":"AMPARADA"
                },                                    
              ]
              break;

            case 14 || 4:
              this.coberturas=[
                {
                  "titulo":"Daños Materiales",
                  "valor":"5%"
                },
                {
                  "titulo":"Robo total",
                  "valor":"10%"
                },
                {
                  "titulo":"Responsabilidad civil",
                  "valor":3000000
                },
                {
                  "titulo":"Extensión RC",
                  "valor":"AMPARADA"
                }, 
                {
                  "titulo":"Accidentes automovilisticos al conductor",
                  "valor":"AMPARADA"
                },                                   
                {
                  "titulo":"Asistencia juridica",
                  "valor":"AMPARADA"
                },                                
                {
                  "titulo":"Asistencia funeraria",
                  "valor":"AMPARADA"
                },                                
                {
                  "titulo":"Asistencia de viajes",
                  "valor":"AMPARADA"
                },                             
                {
                  "titulo":"RC Daño a terceros en EUA",
                  "valor":"AMPARADA"
                },                                       
              ]
              break;

            default:
              break;
          }
        break;
      case "sura":
        switch (this.plan) {
            case 1:
              this.coberturas=[
                {
                  "titulo":"Daños Materiales",
                  "valor":"5%"
                },
                {
                  "titulo":"Robo total",
                  "valor":"10%"
                },
                {
                  "titulo":"Responsabilidad civil",
                  "valor":3000000
                },
                {
                  "titulo":"Extensión RC",
                  "valor":"AMPARADA"
                }, 
                {
                  "titulo":"Gastos médicos ocupantes",
                  "valor":3000000
                },
                {
                  "titulo":"Accidentes automovilisticos al conductor",
                  "valor":"AMPARADA"
                },                           
                {
                  "titulo":"Asistencia juridica",
                  "valor":"AMPARADA"
                },                                
                {
                  "titulo":"Asistencia funeraria",
                  "valor":"AMPARADA"
                },                                
                {
                  "titulo":"Asistencia de viajes",
                  "valor":"AMPARADA"
                },                             
                {
                  "titulo":"RC Daño a terceros en EUA",
                  "valor":"AMPARADA"
                },                                
              ]
              break;

            case 2:
              this.coberturas=[
                {
                  "titulo":"Daños Materiales",
                  "valor":"-"
                },
                {
                  "titulo":"Robo total",
                  "valor":"10%"
                },
                {
                  "titulo":"Responsabilidad civil",
                  "valor":3000000
                },
                {
                  "titulo":"Extensión RC",
                  "valor":"AMPARADA"
                },
                {
                  "titulo":"Gastos médicos ocupantes",
                  "valor":3000000
                },
                {
                  "titulo":"Accidentes automovilisticos al conductor",
                  "valor":"AMPARADA"
                },                                 
                {
                  "titulo":"Asistencia juridica",
                  "valor":"AMPARADA"
                },                                
                {
                  "titulo":"Asistencia funeraria",
                  "valor":"AMPARADA"
                },                                
                {
                  "titulo":"Asistencia de viajes",
                  "valor":"AMPARADA"
                },                             
                {
                  "titulo":"RC Daño a terceros en EUA",
                  "valor":"AMPARADA"
                },                                 
              ]
              break;

            case 3:
              this.coberturas=[
                {
                  "titulo":"Daños Materiales",
                  "valor":"-"
                },
                {
                  "titulo":"Robo total",
                  "valor":"-"
                },
                {
                  "titulo":"Responsabilidad civil",
                  "valor":3000000
                },
                {
                  "titulo":"Extensión RC",
                  "valor":"AMPARADA"
                },
                {
                  "titulo":"Gastos médicos ocupantes",
                  "valor":3000000
                },
                {
                  "titulo":"Accidentes automovilisticos al conductor",
                  "valor":"AMPARADA"
                },                                 
                {
                  "titulo":"Asistencia juridica",
                  "valor":"AMPARADA"
                },                                
                {
                  "titulo":"Asistencia funeraria",
                  "valor":"AMPARADA"
                },                                
                {
                  "titulo":"Asistencia de viajes",
                  "valor":"AMPARADA"
                },                             
                {
                  "titulo":"RC Daño a terceros en EUA",
                  "valor":"AMPARADA"
                },                                    
              ]
              break;

            case 14 || 4:
              this.coberturas=[
                {
                  "titulo":"Daños Materiales",
                  "valor":"5%"
                },
                {
                  "titulo":"Robo total",
                  "valor":"10%"
                },
                {
                  "titulo":"Responsabilidad civil",
                  "valor":3000000
                },
                {
                  "titulo":"Extensión RC",
                  "valor":"AMPARADA"
                }, 
                {
                  "titulo":"Gastos médicos ocupantes",
                  "valor":3000000
                },
                {
                  "titulo":"Accidentes automovilisticos al conductor",
                  "valor":"AMPARADA"
                },                                   
                {
                  "titulo":"Asistencia juridica",
                  "valor":"AMPARADA"
                },                                
                {
                  "titulo":"Asistencia funeraria",
                  "valor":"AMPARADA"
                },                                
                {
                  "titulo":"Asistencia de viajes",
                  "valor":"AMPARADA"
                },                             
                {
                  "titulo":"RC Daño a terceros en EUA",
                  "valor":"AMPARADA"
                },                                       
              ]
              break;

            default:
              break;
          }
        break;
      case "hdi":
        switch (this.plan) {
            case 1:
              this.coberturas=[
                {
                  "titulo":"Daños Materiales",
                  "valor":"5%"
                },
                {
                  "titulo":"Robo total",
                  "valor":"10%"
                },
                {
                  "titulo":"Responsabilidad civil",
                  "valor":3000000
                },
                {
                  "titulo":"Extensión RC",
                  "valor":"AMPARADA"
                }, 
                {
                  "titulo":"Accidentes automovilisticos al conductor",
                  "valor":"AMPARADA"
                },                           
                {
                  "titulo":"Asistencia juridica",
                  "valor":"AMPARADA"
                },                                
                {
                  "titulo":"Asistencia funeraria",
                  "valor":"AMPARADA"
                },                                
                {
                  "titulo":"Asistencia de viajes",
                  "valor":"AMPARADA"
                },                             
                {
                  "titulo":"RC Daño a terceros en EUA",
                  "valor":"AMPARADA"
                },                                
              ]
              break;

            case 2:
              this.coberturas=[
                {
                  "titulo":"Daños Materiales",
                  "valor":"-"
                },
                {
                  "titulo":"Robo total",
                  "valor":"10%"
                },
                {
                  "titulo":"Responsabilidad civil",
                  "valor":3000000
                },
                {
                  "titulo":"Extensión RC",
                  "valor":"AMPARADA"
                },
                {
                  "titulo":"Accidentes automovilisticos al conductor",
                  "valor":"AMPARADA"
                },                                 
                {
                  "titulo":"Asistencia juridica",
                  "valor":"AMPARADA"
                },                                
                {
                  "titulo":"Asistencia funeraria",
                  "valor":"AMPARADA"
                },                                
                {
                  "titulo":"Asistencia de viajes",
                  "valor":"AMPARADA"
                },                             
                {
                  "titulo":"RC Daño a terceros en EUA",
                  "valor":"AMPARADA"
                },                                 
              ]
              break;

            case 3:
              this.coberturas=[
                {
                  "titulo":"Daños Materiales",
                  "valor":"-"
                },
                {
                  "titulo":"Robo total",
                  "valor":"-"
                },
                {
                  "titulo":"Responsabilidad civil",
                  "valor":3000000
                },
                {
                  "titulo":"Extensión RC",
                  "valor":"AMPARADA"
                }, 
                {
                  "titulo":"Accidentes automovilisticos al conductor",
                  "valor":"AMPARADA"
                },                                 
                {
                  "titulo":"Asistencia juridica",
                  "valor":"AMPARADA"
                },                                
                {
                  "titulo":"Asistencia funeraria",
                  "valor":"AMPARADA"
                },                                
                {
                  "titulo":"Asistencia de viajes",
                  "valor":"AMPARADA"
                },                             
                {
                  "titulo":"RC Daño a terceros en EUA",
                  "valor":"AMPARADA"
                },                                    
              ]
              break;

            case 14 || 4:
              this.coberturas=[
                {
                  "titulo":"Daños Materiales",
                  "valor":"5%"
                },
                {
                  "titulo":"Robo total",
                  "valor":"10%"
                },
                {
                  "titulo":"Responsabilidad civil",
                  "valor":3000000
                },
                {
                  "titulo":"Extensión RC",
                  "valor":"AMPARADA"
                }, 
                {
                  "titulo":"Accidentes automovilisticos al conductor",
                  "valor":"AMPARADA"
                },                                   
                {
                  "titulo":"Asistencia juridica",
                  "valor":"AMPARADA"
                },                                
                {
                  "titulo":"Asistencia funeraria",
                  "valor":"AMPARADA"
                },                                
                {
                  "titulo":"Asistencia de viajes",
                  "valor":"AMPARADA"
                },                             
                {
                  "titulo":"RC Daño a terceros en EUA",
                  "valor":"AMPARADA"
                },                                       
              ]
              break;

            default:
              break;
          }
        break;

      default:
        break;
    }
  },

  methods: {
    toCurrency(number) {
      var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });
      return formatter.format(number); /* $2,500.00 */
    },
    responsabilidadCivil(seguro, plan, cobertura) {
      this.coberturaRC(seguro, plan, cobertura);
    },
    gastosMedicos(seguro, plan, cobertura) {
      this.coberturaGastos(seguro, plan, cobertura);
    },
  },
};
</script>
