<template>
  <v-app>
    <Navigation></Navigation>
    <v-main style="background: #f6f9ff;">
      <v-container>
        <h1 class="main-title">
          Contrata tu Seguro Celular por: <br />
          ${{ cotizacion.total }} / al año
        </h1>
        <template>
          <v-row class="container">
            <v-col class="first-box">
              <v-card elevation="12" outlined shaped max-width="600" max-height="800" class="card-container">
                <v-card-title>
                  <span class="text-h5 font-weight-bold" style="color: orange">Información de la cotización</span>
                </v-card-title>

                <v-card-subtitle>
                  <ul>
                    <li>
                      <span class="text-h6 font-weight-light" style="color: brown">Modelo:</span>
                      <p>
                        {{ cotizacion.modelo }}
                      </p>
                    </li>
                    <li>
                      <span class="text-h6 font-weight-light" style="color: brown">Costo del celular:</span>
                      <p>
                        ${{
                            cotizacion.precioFactura
                              ? cotizacion.precioFactura
                              : 0
                        }}
                      </p>
                    </li>
                    <li>
                      <span class="text-h6 font-weight-light" style="color: brown">Contacto:</span>
                      <p>
                        {{
                            cotizacion.nombre +
                            " " +
                            cotizacion.apellidoPaterno +
                            " " +
                            cotizacion.apellidoMaterno
                        }}
                      </p>
                    </li>
                    <li>
                      <span class="text-h6 font-weight-light" style="color: brown">Celular:</span>
                      <p>
                        {{ cotizacion.telefono }}
                      </p>
                    </li>
                    <li>
                      <span class="text-h6 font-weight-light" style="color: brown">Correo:</span>
                      <p>
                        {{ cotizacion.email }}
                      </p>
                    </li>
                    <li>
                      <span class="text-h6 font-weight-light" style="color: brown">Código Cotización:</span>
                      <p>
                        {{ cotizacion.cotizacionGeneral }}
                      </p>
                    </li>
                  </ul>
                </v-card-subtitle>
                <v-card-actions>
                  <v-btn outlined right large color="orange" class="btn-emitir" @click="dialogEmisionForm = true">
                    Emitir
                  </v-btn>
                </v-card-actions>
              </v-card>

              <v-btn outlined right large color="orange" class="btn-emitir" @click="dialogEmisionForm = true">
                Emitir
              </v-btn>
            </v-col>
          </v-row>
        </template>
      </v-container>

      <!-- Dialog for Emite insurance -->
      <div class="text-center">
        <v-dialog v-model="dialogEmisionForm" persistent max-width="900px">
          <v-card>
            <v-card-title>
              <span class="text-h5" style="color: brown">Emitir Seguro Celular</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field label="Marca y Modelo del Celular" v-model="cotizacion.modelo" readonly>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field label="Precio del celular" v-model="cotizacion.precioFactura" readonly></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field label="Fecha de compra del celular" v-model="emisionForm.fechaCompraCelular"
                      v-mask="'##/##/####'"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field label="Costo del Seguro Anual" v-model="cotizacion.total" readonly></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field label="Nombre(s)" v-model="cotizacion.nombre" readonly></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field label="Apellido Paterno" v-model="cotizacion.apellidoPaterno" readonly></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field label="Apellido Materno" v-model="cotizacion.apellidoMaterno" readonly></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field label="Fecha de Nacimiento" v-model="emisionForm.fechaNacimiento"
                      v-mask="'##/##/####'"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <p>Género</p>
                    <v-radio-group v-model="emisionForm.genero" mandatory>
                      <v-radio label="Hombre" value="Masculino" color="brown"></v-radio>
                      <v-radio label="Mujer" value="Femenino" color="brown"></v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field label="RFC" v-model="emisionForm.rfc" :rules="rulesRfc" v-mask="'XXXX######XXX'"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field label="Código Postal" v-model="cotizacion.codigoPostal" readonly></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-select label="Estado de la República" v-model="cotizacion.estadoId" :items="estados"
                      item-text="estadoRepublica" item-value="id" readonly></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field label="Alcaldía o Municipio" v-model="emisionForm.alcaldiaMunicipio"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field label="Colonia o Fraccionamiento" v-model="emisionForm.coloniaFraccionamiento">
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field label="Calle" v-model="emisionForm.calle"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field label="Número Exterior" v-model="emisionForm.numExterior" v-mask="'####'">
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field label="Número Interior" v-model="emisionForm.numInterior"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <p>Opción de Pago</p>
                    <v-radio-group v-model="emisionForm.opcionPago" mandatory>
                      <v-radio label="Un solo pago" value="tpv_12" color="brown"></v-radio>
                      <v-radio label="12 Meses sin intereses" value="tpv_12_finance" color="brown"></v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col cols="12">
                    <v-checkbox
                      label="Acepto los Términos y Condiciones de uso de medios electrónicos y el tratamiento de mis datos personales de acuerdo a lo establecido en el Aviso de Privacidad"
                      v-model="emisionForm.termYCond" color="brown"></v-checkbox>
                  </v-col>
                  <v-col cols="12">
                    <v-checkbox
                      label="Acepto el cobro y/o domiciliación requerida para la contratación del seguro y envío del material contractual a mi correo electrónico registrado"
                      v-model="emisionForm.cobroDomici" color="brown"></v-checkbox>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn outlined color="brown" @click="dialogEmisionForm = false">
                Cancelar
              </v-btn>
              <v-btn color="orange" :disabled="!validarBotonGuardarEmision" outlined
                @click="warningModalPayWindow = true">
                Pagar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
      <!-- End Dialog for Emite insurance -->

      <!-- Dialog for warning the payment window -->
      <div class="text-center">
        <v-dialog v-model="warningModalPayWindow" persistent max-width="290">
          <v-card>
            <v-card-title class="text-h5">
              Redirección pasarela de pago
            </v-card-title>
            <v-card-text>A partir de este momento se ingresará a una página externa al Portal de Segurify, que es una
              pasarela de pago segura.</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="green darken-1" text :loading="loadingButtonEmitir" :disabled="loadingButtonEmitir"
                @click="loadingButtonEmitir = true, sendEmision()">
                De acuerdo
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
      <!-- End Dialog for warning the payment window -->
    </v-main>
  </v-app>
</template>

<script>
// import moment from "moment";
import Navigation from "../../../components/Nav.vue";
import { mainAxios } from "../../../mainAxios";
export default {
  components: {
    Navigation,
  },
  data() {
    return {
      dialogEmisionForm: false,
      loadingButtonEmitir: false,
      warningModalPayWindow: false,
      rulesRfc: [ 
          v => !!v || "Este campo es requerido", 
          v => ( v && v.length >= 13 ) || "Este campo debe contener 13 caracteres, sino cuenta con homoclave colocar XXX",
          v => ( v && v.length <= 13 ) || "Este campo debe contener 13 caracteres, sino cuenta con homoclave colocar XXX",
      ],
      cotizacion: {},
      emisionForm: {
        fechaNacimiento: null,
        genero: null,
        rfc: '',
        alcaldiaMunicipio: null,
        coloniaFraccionamiento: null,
        calle: null,
        numExterior: null,
        numInterior: null,
        opcionPago: null,
        termYCond: false,
        cobroDomici: false,
        fechaCompraCelular: null,

        periodicity: null,
        pago_id: null,
        cotizacion_uuid: null
      },
      estados: [
        { id: 1, estadoRepublica: "Aguascalientes" },
        { id: 2, estadoRepublica: "Baja California" },
        { id: 3, estadoRepublica: "Baja California Sur" },
        { id: 4, estadoRepublica: "Campeche" },
        { id: 5, estadoRepublica: "Coahuila de Zaragoza" },
        { id: 6, estadoRepublica: "Colima" },
        { id: 7, estadoRepublica: "Chiapas" },
        { id: 8, estadoRepublica: "Chihuahua" },
        { id: 9, estadoRepublica: "Ciudad de México" },
        { id: 10, estadoRepublica: "Durango" },
        { id: 11, estadoRepublica: "Guanajuato" },
        { id: 12, estadoRepublica: "Guerrero" },
        { id: 13, estadoRepublica: "Hidalgo" },
        { id: 14, estadoRepublica: "Jalisco" },
        { id: 15, estadoRepublica: "Estado de México" },
        { id: 16, estadoRepublica: "Michoacán de Ocampo" },
        { id: 17, estadoRepublica: "Morelos" },
        { id: 18, estadoRepublica: "Nayarit" },
        { id: 19, estadoRepublica: "Nuevo León" },
        { id: 20, estadoRepublica: "Oaxaca" },
        { id: 21, estadoRepublica: "Puebla" },
        { id: 22, estadoRepublica: "Querétaro" },
        { id: 23, estadoRepublica: "Quintana Roo" },
        { id: 24, estadoRepublica: "San Luis Potosí" },
        { id: 25, estadoRepublica: "Sinaloa" },
        { id: 26, estadoRepublica: "Sonora" },
        { id: 27, estadoRepublica: "Tabasco" },
        { id: 28, estadoRepublica: "Tamaulipas" },
        { id: 29, estadoRepublica: "Tlaxcala" },
        { id: 30, estadoRepublica: "Veracruz de Ignacio de la Llave" },
        { id: 31, estadoRepublica: "Yucatán" },
        { id: 32, estadoRepublica: "Zacatecas" },
      ],
    };
  },
  methods: {
    async cargarCotizacion() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };

      const resp = await mainAxios.get(
        `/v1/seguro-celular/uuid/${this.$route.params.uuid}`,
        config
      );
      // console.log(resp);
      this.cotizacion = resp.data;
    },

    sendEmision() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      let response = JSON.parse(this.cotizacion.response);

      let request = {
        modelo: this.cotizacion.modelo,
        marca: " ",
        precio_factura: this.cotizacion.precioFactura,
        nombre: this.cotizacion.nombre,
        apellido_paterno: this.cotizacion.apellidoPaterno,
        apellido_materno: this.cotizacion.apellidoMaterno,
        telefono: this.cotizacion.telefono,
        email: this.cotizacion.email,
        codigo_postal: this.cotizacion.codigoPostal,
        ciudad: this.emisionForm.alcaldiaMunicipio,
        colonia: this.emisionForm.coloniaFraccionamiento,
        estado: this.cotizacion.estadoId,
        calle: this.emisionForm.calle,
        numero_exterior: parseInt(this.emisionForm.numExterior),
        fecha_nacimiento: this.emisionForm.fechaNacimiento,
        fecha_compra: this.emisionForm.fechaCompraCelular,
        // fecha_compra: moment(this.emisionForm.fechaCompraCelular, 'DD/MM/YYYY').format('YYYY-MM-DD'),
        rfc: this.emisionForm.rfc,
        estado_id: this.cotizacion.estadoId,
        origen: "Segurify",
        partner: "",
        genero: this.emisionForm.genero,
        agente_id: this.cotizacion.agenteId,

        cotizacion_uuid: this.cotizacion.uuid,
        periodicity: parseInt(response.periodicity),
        pago_id: this.emisionForm.opcionPago == 'tpv_12' ? 8 : 1,
        payment_method: this.emisionForm.opcionPago,
      };

      mainAxios
        .post("/v1/seguro-celular/emite", request, config)
        .then((response) => {
          if (response.status == 200) {
            this.loadingButtonEmitir = false;
            window.open(
              response.data.card_payment_URL,
              "Pago de Seguro",
              "width=750, height=750"
            );
            setTimeout(() => (this.$router.push(`/inicio-celular/${response.data.policy.identifier}/emision/${response.data.id_emision[0]}`)), 2000)
          }
          console.log("Emision: ", response);
        }).catch((exception) => {
          console.error(exception);
          this.loadingButtonEmitir = false;
        });
    },
  },

  computed: {
    validarBotonGuardarEmision() {
      return (
        this.emisionForm.fechaNacimiento != null &&
        this.emisionForm.genero != null &&
        this.emisionForm.cobroDomici != false &&
        this.emisionForm.fechaCompraCelular != null &&
        this.emisionForm.rfc != '' &&
        this.emisionForm.rfc.length == 13 &&
        this.emisionForm.alcaldiaMunicipio != null &&
        this.emisionForm.coloniaFraccionamiento != null &&
        this.emisionForm.calle != null &&
        this.emisionForm.numExterior != null &&
        this.emisionForm.opcionPago != null &&
        this.emisionForm.termYCond != false
      )
    }
  },

  mounted() {
    this.cargarCotizacion();
  },
  
};
</script>

<style scoped>
.main-title {
  color: orange;
  text-align: center;
}

.container {
  justify-items: center;
  align-items: center;
}

.first-box {
  justify-items: center;
}

.card-container {
  border-radius: 15px;
}

.btn-emitir {
  margin: 15px;
  margin-top: 30px;
  border-radius: 10px;
}

p {
  display: inline-block;
  font-size: 18px;
}
</style>
