<template>
  <CommonExpansionPanel :currentPanel="currentPanel">
    <template v-slot:title-panel>
      Datos generales
    </template>
    <v-expansion-panel-content>
      <v-form ref="firstStepForm" :lazy-validation="true">
        <v-row class="justify-end">
          <v-col cols="10"> </v-col>
          <v-col cols="2" class="p-6" v-if="data.producto != 'Llamada colgada/incompleta'">
            <v-switch
              v-model="llamadaSeguimiento"
              label="Mostrar todas tipificaciones"
              hide-details
              inset
            ></v-switch>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" m="12" md="6" xl="3">
            <v-text-field
              label="Medio de contacto"
              outlined
              v-model="data.tipo_llamada"
              primary
              disabled
              color="#00a7e4"
              type="text"
              :rules="requiredRule"
            ></v-text-field>
          </v-col>
          <v-col m="12" md="6" xl="3">
            <v-autocomplete
              label="Producto"
              outlined
              v-model="data.producto"
              item-value="campania"
              item-text="campania"
              :items="currentProducts"
              primary
              color="#00a7e4"
              type="text"
              :rules="requiredRule"
            ></v-autocomplete>
          </v-col>
          <v-col m="12" md="6" xl="3" v-if="data.producto == 'Llamada colgada/incompleta' ">
            <v-autocomplete
              label="Tipificación"
              outlined
              v-model="data.tipificacion"
              primary
              :items="['Se corta llamada','No se escucha','Llamada equivocada','Cliente Cuelga','Otro']"
              color="#00a7e4"
              type="text"
              :rules="requiredRule"
            ></v-autocomplete>
          </v-col>
          <v-col m="12" md="6" xl="3" v-if="data.producto && data.producto != 'Seguimiento' && data.producto != 'Llamada colgada/incompleta' ">
            <v-autocomplete
              label="Empresa"
              outlined
              v-model="data.empresa"
              primary
              item-value="empresa"
              item-text="empresa"
              :items="currentEmpresas"
              color="#00a7e4"
              type="text"
              :rules="requiredRule"
            ></v-autocomplete>
          </v-col>
          <v-col m="12" md="6" xl="3" v-if="data.empresa && data.producto && data.producto != 'Seguimiento' && data.producto != 'Llamada colgada/incompleta'">
            <v-autocomplete
              label="Aseguradora"
              outlined
              v-model="data.aseguradora"
              primary
              item-value="id"
              item-text="name"
              :items="currentAseguradoras"
              color="#00a7e4"
              type="text"
              :rules="requiredRule"
            ></v-autocomplete>
          </v-col>
          <v-col
            m="12"
            md="6"
            xl="3"
            v-if="data.empresa && data.producto && data.aseguradora && data.producto != 'Seguimiento' && data.producto != 'Llamada colgada/incompleta'"
          >
            <v-autocomplete
              label="Tipificación"
              outlined
              v-model="data.tipificacion"
              primary
              item-value="tipificacion"
              item-text="tipificacion"
              :items="filtertTipificaciones"
              color="#00a7e4"
              type="text"
              :rules="requiredRule"
            ></v-autocomplete>
          </v-col>
          <v-col
            m="12"
            md="6"
            xl="3"
            v-if="
              data.empresa &&
                data.producto &&
                data.aseguradora &&
                data.tipificacion && data.producto != 'Seguimiento' && data.producto != 'Llamada colgada/incompleta'
            "
          >
            <v-autocomplete
              label="Motivo"
              outlined
              v-model="data.disposicion"
              primary
              item-value="motivo"
              item-text="motivo"
              :items="filtertMotivos"
              color="#00a7e4"
              type="text"
              :rules="requiredRule"
            ></v-autocomplete>
          </v-col>
          <v-col
            m="12"
            md="6"
            xl="6"
            v-if="
              data.empresa &&
                data.producto &&
                data.aseguradora &&
                data.tipificacion &&
                data.disposicion && data.producto != 'Seguimiento' && data.producto != 'Llamada colgada/incompleta'
            "
          >
            <v-text-field
              label="Póliza"
              outlined
              v-model="data.poliza"
              primary
              color="#00a7e4"
              type="text"
              :rules="requiredRule"
            ></v-text-field>
          </v-col>

          <v-col
            class="whitout-padding-bottom"
            cols="12"
            sm="6"
            md="6"
            xl="3"
            v-if="
              data.empresa &&
                data.producto &&
                data.aseguradora &&
                data.tipificacion &&
                data.disposicion &&
                data.poliza && data.producto != 'Seguimiento' && data.producto != 'Llamada colgada/incompleta'
            "
          >
            <span class="text-header-data whitout-padding-bottom ">
              ¿Atendio en llamada?
            </span>
            <v-radio-group
              v-model="data.atendioLlamada"
              row
              :rules="requiredRule"
            >
              <v-radio label="Si" value="true" color="#00a7e4"></v-radio>
              <v-radio label="No" value="false" color="#00a7e4"></v-radio>
            </v-radio-group>
          </v-col>
          <v-col
            class="whitout-padding-bottom"
            cols="12"
            sm="6"
            md="6"
            xl="3"
            v-if="
              data.empresa &&
                data.producto &&
                data.aseguradora &&
                data.tipificacion &&
                data.disposicion &&
                data.poliza && data.producto != 'Seguimiento' && data.producto != 'Llamada colgada/incompleta'
            "
          >
            <span class="text-header-data whitout-padding-bottom ">
              ¿Es queja?
            </span>
            <v-radio-group v-model="data.isQueja" row :rules="requiredRule">
              <v-radio label="Si" value="true" color="#00a7e4"></v-radio>
              <v-radio label="No" value="false" color="#00a7e4"></v-radio>
            </v-radio-group>
          </v-col>
          <v-col m="12" md="6" lg="12" xl="12" v-if="data.producto != 'Llamada colgada/incompleta'">
            <v-text-field
              label="Detalle"
              outlined
              v-model="data.detalle"
              primary
              color="#00a7e4"
              type="text"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>

      <v-row style="text-align: end;">
        <v-col cols="12" v-if="data.producto == 'Llamada colgada/incompleta'">
          <v-btn rounded dark class="common-botton" @click="goToSaveLlamadaCortada">
            <span>
              Guardar
            </span>
          </v-btn>
        </v-col>
        <v-col cols="12" v-if="data.producto != 'Llamada colgada/incompleta'">
          <v-btn rounded dark class="common-botton" @click="validateStep">
            <span>
              Siguiente
            </span>
          </v-btn>
        </v-col>
      </v-row>
    </v-expansion-panel-content>
  </CommonExpansionPanel>
</template>

<script>
import CommonExpansionPanel from "../CommonExpansionPanel.vue";
import { getCampañasInbound } from "@/services/campañasInbound/CampañasInbound.js";

export default {
  components: {
    CommonExpansionPanel,
  },
  props: {
    currentPanel: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      data: {
        tipo_llamada: "Inbound",
        producto: "",
        empresa: "",
        tipificacion: "",
        aseguradora: "",
        poliza: "",
        disposicion: "",
        atendioLlamada: "",
        detalle: "",
        servicio: "",
        isQueja: "",
      },
      requiredRule: [(v) => !!v || "El campo es requerido"],
      campañas: [],
      currentEmpresas: [],
      filtertAseguradoras: [],
      filtertTipificaciones: [],
      filtertMotivos: [],
      aseguradorasList: [
        { id: 1, name: "Afirme" },
        { id: 2, name: "ANA" },
        { id: 3, name: "GNP" },
        { id: 4, name: "Primero" },
        { id: 5, name: "Qualitas" },
        { id: 6, name: "Multiva" },
        { id: 7, name: "Sura" },
        { id: 8, name: "AIG" },
        { id: 9, name: "HDI" },
        { id: 10, name: "Zurich" },
        { id: 11, name: "AXA" },
        { id: 12, name: "Banorte" },
      ],
      llamadaSeguimiento: false,
    };
  },
  computed: {
    currentAseguradoras() {
      let aseguradoras = [];
      this.filtertAseguradoras.forEach((a) => {
        const filteredAseguradoras = this.aseguradorasList.filter(
          (e) => e.id == a.aseguradoraId
        );
        aseguradoras = aseguradoras.concat(filteredAseguradoras);
      });
      return aseguradoras;
    },
    currentProducts() {
      var uniqueProducts = [];
      var filteredCampaigns = this.campañas;
      if (this.currentService && !this.llamadaSeguimiento) {
        console.log('Entro aqui', this.campañas)
        filteredCampaigns = []
        filteredCampaigns = this.campañas.filter(
          (e) => e.servicioIvr == this.currentService
        );
      }
      uniqueProducts = Array.from(
        new Set(filteredCampaigns.map((a) => a.campania))
      ).map((campania) => {
        return filteredCampaigns.find((a) => a.campania === campania);
      });

      uniqueProducts.push({
        campania:'Seguimiento',
      })

      uniqueProducts.push({
        campania:'Llamada colgada/incompleta',
      })
      
      return uniqueProducts;
    },
    currentService() {
      var service = JSON.parse(localStorage.getItem("jsonLlamada"));
      return service ? service.service : "";
    },
  },
  watch: {
    "data.producto"(val) {
      this.calculateEmpresas();
      if(val == 'Seguimiento')
        this.data.disposicion = 'Seguimiento'
      if(val == 'Llamada colgada/incompleta'){
        this.data.disposicion = 'Llamada colgada/incompleta'
        this.$emit('noNeedShowClienteData')
      }else
        this.$emit('needShowClienteData')
    },
    "data.empresa"() {
      this.calculateAseguradora();
    },
    "data.aseguradora"() {
      this.calculateTipificaciones();
    },
    "data.tipificacion"() {
      this.calculateMotivos();
    },
    llamadaSeguimiento() {
      Object.assign(this.data, {
        tipo_llamada: "Inbound",
        producto: "",
        empresa: "",
        tipificacion: "",
        aseguradora: "",
        poliza: "",
        disposicion: "",
        atendioLlamada: "",
        detalle: "",
        servicio: "",
        isQueja: "",
      });
    },
  },
  methods: {
    async getCampañas() {
      this.campañas = await getCampañasInbound();
    },
    calculateEmpresas() {
      const jsonLlamada = JSON.parse(localStorage.getItem("jsonLlamada"));
      let filteredCampaigns = !this.llamadaSeguimiento
        ? this.campañas.filter(
            (e) =>
              e.campania == this.data.producto &&
              e.servicioIvr == jsonLlamada.service
          )
        : this.campañas.filter((e) => e.campania == this.data.producto);
      let uniqueProducts = Array.from(
        new Set(filteredCampaigns.map((a) => a.empresa))
      ).map((empresa) => {
        return filteredCampaigns.find((a) => a.empresa === empresa);
      });

      this.currentEmpresas = uniqueProducts;
    },
    calculateAseguradora() {
      let filteredCampaigns = this.campañas.filter(
        (e) =>
          e.campania == this.data.producto && e.empresa == this.data.empresa
      );
      let uniqueProducts = Array.from(
        new Set(filteredCampaigns.map((a) => a.aseguradoraId))
      ).map((aseguradoraId) => {
        return filteredCampaigns.find((a) => a.aseguradoraId === aseguradoraId);
      });
      this.filtertAseguradoras = uniqueProducts;
    },
    calculateTipificaciones() {
      let filteredCampaigns = this.campañas.filter(
        (e) =>
          e.campania == this.data.producto &&
          e.empresa == this.data.empresa &&
          e.aseguradoraId == this.data.aseguradora
      );
      let uniqueProducts = Array.from(
        new Set(filteredCampaigns.map((a) => a.tipificacion))
      ).map((tipificacion) => {
        return filteredCampaigns.find((a) => a.tipificacion === tipificacion);
      });
      this.filtertTipificaciones = uniqueProducts;
    },
    calculateMotivos() {
      let filteredCampaigns = this.campañas.filter(
        (e) =>
          e.campania == this.data.producto &&
          e.empresa == this.data.empresa &&
          e.aseguradoraId == this.data.aseguradora &&
          e.tipificacion == this.data.tipificacion
      );
      let uniqueProducts = Array.from(
        new Set(filteredCampaigns.map((a) => a.motivo))
      ).map((motivo) => {
        return filteredCampaigns.find((a) => a.motivo === motivo);
      });
      this.filtertMotivos = uniqueProducts;
    },
    validateStep() {
      if (this.$refs.firstStepForm.validate()) {
        this.$emit("nextStep", this.data);
      }
    },
    goToSaveLlamadaCortada() {
      if (this.$refs.firstStepForm.validate()) {
        this.$emit("goToSaveLlamadaCortada", this.data);
      }
    },
  },
  async mounted() {
    const jsonLlamada = JSON.parse(localStorage.getItem("jsonLlamada"));
    console.log("mounted", jsonLlamada);
    this.data.servicio = jsonLlamada.service;
    await this.getCampañas();
  },
};
</script>
