<template>
  <v-app>
    <Navigation></Navigation>
    <v-main>
      <v-container fluid>
        <!-- HEAD TITLE -->
        <v-row style="margin-top: 10px">
          <v-col cols="6">
            <h2>Cuotas y Comisiones</h2>
          </v-col>

          <v-col cols="6">
            <v-btn
              @click="goBack()"
              elevation="2"
              style="margin-left: 527px"
              ><v-icon style="margin-right: 5px">mdi-arrow-left</v-icon>Regresar
            </v-btn>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="8">
            <v-form>
              <v-row>
                <v-col>
                  <v-stepper v-model="stepper" non-linear>
                    <v-stepper-header style="justify-content: left">
                      <v-stepper-step step="1" editable>
                        Cuota de la Póliza
                      </v-stepper-step>

                      <v-stepper-step step="2" editable v-if="canViewFinances || isAgente">
                        Desglose de Comisiones
                      </v-stepper-step>
                    </v-stepper-header>

                    <v-stepper-items>
                      <!-- 1ST ITEM (FORM) -->
                      <v-stepper-content step="1">
                        <v-row style="padding: 20px">
                          <v-col @click="$router.push(`/actualizar-emision/${poliza}`)" cols="6">
                            <v-text-field
                              
                              label="Pólizas"
                              v-model="poliza"
                              class="enlacesForms"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              
                              label="Orden"
                              v-model="orden"
                            ></v-text-field>
                          </v-col>
                          <!-- End 1nd Col -->
                        </v-row>

                        <v-row style="padding: 20px; margin-top: -70px">
                          <v-col cols="6">
                            <v-select
                              label="Estado de la Cuota"
                              :items="estadoCuotaItems"
                              
                              v-model="estadoCuotaSelected"
                              readonly
                            ></v-select>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              label="Valor a Pagar"
                              
                              v-model="valorPagar"
                              prefix="$"
                            ></v-text-field>
                          </v-col>
                          <!-- End 2nd Col -->
                        </v-row>

                        <!-- <v-row style="padding: 20px; margin-top: -70px"> -->
                          <v-col style="padding: 20px; margin-top: -60px; margin-bottom: 15px" cols="6" v-if="mostrarCheckRehabilitarCuota()">
                            <v-checkbox
                              label="Rehabilitar Cuota"
                              
                              v-model="checkRehabilitarCuota"
                            ></v-checkbox>
                          </v-col>
                          <v-col style="padding: 20px; margin-top: -60px; margin-bottom: 15px" cols="6" v-if="checkRehabilitarCuota">
                            <v-text-field
                              label="Motivo de rehabilitar cuota"
                              
                              v-model="motivoRehabilitarCuota"
                            ></v-text-field>
                          </v-col>
                          <!-- End 2nd Col -->
                        <!-- </v-row> -->

                        <v-row style="padding: 20px; margin-top: -70px">
                         <v-col cols="6">
                            <v-text-field
                              clearable
                              label="Fecha de pago acordada"
                              v-model="fechaPagoAcordada"
                              
                              placeholder="Requerido (dd/mm/yyyy)"
                              v-mask="'##/##/####'"
                              prepend-icon="mdi-calendar"
                              @click:clear="fechaPagoAcordada = null"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              clearable
                              label="Fecha límite de pago"
                              
                              v-model="fechaLimitePago"
                              placeholder="Requerido (dd/mm/yyyy)"
                              v-mask="'##/##/####'"
                              prepend-icon="mdi-calendar"
                              @click:clear="fechaLimitePago = null"
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row style="padding: 20px; margin-top: -70px">
                          <v-col cols="6" v-if="estadoCuotaSelected == 'Pendiente de pago'">
                            <v-checkbox
                              label="Cuota Pagada"
                              
                              v-model="checkCuotaPagada"
                            ></v-checkbox>
                          </v-col>
                          <v-col cols="6" v-if="estadoCuotaSelected != 'Vencida' && checkCuotaPagada">
                            <v-text-field
                              clearable
                              label="Fecha pago cuota"
                              
                              v-model="fechaPagoCuota"
                              placeholder="Requerido (dd/mm/yyyy)"
                              v-mask="'##/##/####'"
                              prepend-icon="mdi-calendar"
                              @click:clear="fechaPagoCuota = null"
                            ></v-text-field>
                          </v-col>
                          
                          <!-- End 7th Col -->
                        </v-row>

                        <v-row style="padding: 20px; margin-top: -70px" v-if="estadoCuotaSelected != 'Vencida' && checkCuotaPagada">
                            <v-col cols="6">
                            <v-file-input
                              v-model="adjuntoPago"
                              color="primary"
                              
                              counter
                              label="Adjunto de Pago"
                              multiple
                              prepend-icon="mdi-paperclip"
                              :show-size="1000"
                            >
                              <template v-slot:selection="{ index, text }">
                                <v-chip
                                  v-if="index < 2"
                                  color="primary"
                                  dark
                                  label
                                  small
                                >
                                  {{ text }}
                                </v-chip>

                                <span
                                  v-else-if="index === 2"
                                  class="text-overline grey--text text--darken-3 mx-2"
                                >
                                  +{{ adjuntoPago.length - 2 }} File(s)
                                </span>
                              </template>
                            </v-file-input>
                          </v-col>
                        </v-row>

                        <v-row style="padding: 20px">
                          <v-col cols="6">
                            <v-text-field
                              label="Creado por"
                              
                              v-model="creadoPor"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              label="Fecha de Creación"
                              v-model="fechaCreacion"
                              
                              v-mask="'##/##/####'"
                              readonly
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row style="padding: 20px; margin-top: -70px">
                          <v-col cols="6">
                            <v-text-field
                              label="Modificado por"
                              v-model="modificadoPor"
                              
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              label="Última Modificación"
                              v-model="ultimaModificacion"
                              
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <!--  -->
                        <v-btn color="primary" :disabled="!comportamientoBotonGuardar()" @click="actualizarCuota()">
                          Guardar
                        </v-btn>
                      </v-stepper-content>

                      <!-- 2ND ITEM (2ND FORM) -->
                      <v-stepper-content step="2">
                        <div class="row">
                          <div class="col-6">
                            <v-select
                              label="Estatus de Comisión"
                              :items="statusComisionItems"
                              v-model="statusComision"
                              :disabled="isAgente"
                              readonly
                            ></v-select>
                          </div>
                          <div class="col-6">
                            <v-text-field
                              label="Base Comisión"
                              v-model="baseComision"
                              :disabled="isAgente"
                            ></v-text-field>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-6" v-if="canViewFinances && statusComision == 'Pendiente de Pago Aseguradora' && estadoCuotaSelected == 'Pagada'">
                            <v-checkbox
                              label="Comisión Recibida"
                              v-model="checkComisionRecibida"
                              :disabled="isAgente"
                            ></v-checkbox>
                          </div>
                          <div class="col-6" v-if="checkComisionRecibida || statusComision == 'Pendiente de Pago al Agente' && canViewFinances ">
                            <v-text-field
                              label="Fecha de Comisión Recibidas"
                              v-mask="'##/##/####'"
                              v-model="fechaComisionRecibida"
                              :disabled="isAgente"
                            ></v-text-field>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-6" v-if="checkComisionRecibida || statusComision == 'Pendiente de Pago al Agente' && canViewFinances">
                            <v-text-field
                              label="Folio de Movimiento Bancario Comisión"
                              v-model="folioMovimientoBancario"
                              :disabled="isAgente"
                            ></v-text-field>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-6" v-if="checkComisionRecibida  || statusComision == 'Pendiente de Pago al Agente' && canViewFinances">
                            <v-text-field
                              label="% Comisión pagada por la aseguradora"
                              class="pb-3"
                              step="0.01"
                              :rules="[rules.loanMin, rules.loanMax]" 
                              type="number"
                              v-model="comisionPagadaAseguradora"
                              :disabled="isAgente"
                            ></v-text-field>
                          </div>
                          <div class="col-6">
                            <v-text-field
                              label="Monto Comisión pagada por la aseguradora"
                              type="number"
                              class="pt-6"
                              v-model="montoComisionPagadaAseguradora"
                              :disabled="isAgente"
                            ></v-text-field>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-6">
                            <v-text-field
                              label="% Comisión Agente"
                              type="number"                
                              :rules="[rules.loanMin, rules.loanMax]" 
                              step="0.01"
                              v-model="comisionAgente"
                              :disabled="isAgente"
                            ></v-text-field>
                          </div>
                          <div class="col-6">
                            <v-text-field
                              label="Monto de Comisión Agente"
                              type="number"                
                              :disabled="true"
                              v-model="montoComisionAgente"
                            ></v-text-field>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-6">
                          </div>
                          <div class="col-6">
                            <v-text-field
                              label="IVA"
                              type="number"
                              :disabled="true"
                              v-model="iva"
                            ></v-text-field>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-6">
                          </div>
                          <div class="col-6">
                            <v-text-field
                              label="ISR"
                              type="number"
                              :disabled="true"
                              v-model="isr"
                            ></v-text-field>
                          </div>
                        </div>
                        <div class="row pb-6">
                          <div class="col-6">
                          </div>
                          <div class="col-6">
                            <v-text-field
                              label="Retención IVA"
                              :disabled="true"
                              type="number"
                              v-model="retencionIva"
                            ></v-text-field>
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-6" v-if="statusComision == 'Pendiente de Pago al Agente'">
                            <v-text-field
                              label="Folio de la factura por pagar"
                              v-model="folioFacturaAPagar"
                            ></v-text-field>
                          </div>
                          <div class="col-6" v-if="statusComision == 'Pendiente de Pago al Agente'">
                            <template>
                              <v-file-input
                                v-model="documentosFacturaAgente"
                                color="primary"
                                placeholder="Adjunto de Factura del Agente"
                                prepend-icon="mdi-paperclip"
                              >
                                <template v-slot:selection="{ index, text }">
                                  <v-chip
                                    v-if="index < 2"
                                    color="primary"
                                    dark
                                    label
                                    small
                                  >
                                    {{ text }}
                                  </v-chip>
                                </template>
                              </v-file-input>
                            </template>
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-6" v-if="statusComision == 'Pendiente de Pago al Agente' && folioFacturaAPagar != null && folioFacturaAPagar != ''">
                            <v-checkbox
                              label="Comisión Agente Pagada"
                              :disabled="isAgente"
                              v-model="checkComisionAgentePagada"
                            ></v-checkbox>
                          </div>
                          <div class="col-6" v-if="checkComisionAgentePagada == true">
                            <v-text-field
                              v-mask="'##/##/####'"
                              label="Fecha pagado de Comisión Agente"
                              :disabled="isAgente"
                              v-model="fechaPagoComisionAgente"
                            ></v-text-field>
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-6" v-if="checkComisionAgentePagada == true">
                            <template>
                              <v-file-input
                                v-model="documentosPagoRealizadoAgente"
                                color="primary"
                                :disabled="isAgente"
                                placeholder="Adjunto de pago realizado al Agente"
                                prepend-icon="mdi-paperclip"
                              >
                                <template v-slot:selection="{ index, text }">
                                  <v-chip
                                    v-if="index < 2"
                                    color="primary"
                                    dark
                                    label
                                    small
                                  >
                                    {{ text }}
                                  </v-chip>
                                </template>
                              </v-file-input>
                            </template>
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-6">
                            <v-text-field
                              label="% de comisión para Alige"  
                              :rules="[rules.loanMin, rules.loanMax]" 
                              type="number"
                              :disabled="isAgente"
                              step="0.01"
                              max="1"
                              v-model="comisionAlige"
                            ></v-text-field>
                          </div>
                          <div class="col-6">
                            <v-text-field
                              label="Monto comisión para Alige"
                              :disabled="isAgente"
                              v-model="montoComisionAlige"
                            ></v-text-field>
                          </div>
                        </div>
                        
                        <div class="row">
                          <div class="col-6">
                            <v-text-field
                              label="% Bono"
                              type="number"
                              :rules="[rules.loanMin, rules.loanMax]" 
                              step="0.01"
                              max="1"
                              v-model="bono"
                              :disabled="isAgente"
                            ></v-text-field>
                          </div>
                          <div class="col-6">
                            <v-text-field
                              label="Monto Bono para Alige"
                              v-model="montoBonoAlige"
                              :disabled="isAgente"
                            ></v-text-field>
                          </div>
                        </div>
                        
                        <div class="row">
                          <div class="col-6">
                            <v-text-field
                              label="Folio de Movimiento Bancario Bono"
                              type="number"
                              v-model="folioMovimientoBancarioBono"
                              :disabled="isAgente"
                            ></v-text-field>
                          </div>
                          <div class="col-6">
                            <v-text-field
                              label="Fecha Pago de Bono"
                              v-mask="'##/##/####'"
                              v-model="fechaPagoBono"
                              :disabled="isAgente"
                            ></v-text-field>
                          </div>
                        </div>
                        
                        <div class="row">
                          <div class="col-6">
                            <v-text-field
                              label="% Comisión total"
                              type="number"
                              :rules="[rules.loanMin, rules.loanMax]" 
                              step="0.01"
                              max="1"
                              v-model="comisionTotal"
                              :disabled="isAgente"
                            ></v-text-field>
                          </div>
                          <div class="col-6">
                            <v-text-field
                              label="Monto Comisión Total Alige"
                              v-model="montoComisionTotalAlige"
                              :disabled="isAgente"
                            ></v-text-field>
                          </div>
                        </div>


                        <v-btn color="primary" :disabled="!comportamientoBotonGuardar()" @click="actualizarCuota()">
                          Guardar
                        </v-btn>
                      </v-stepper-content>
                    </v-stepper-items>
                  </v-stepper>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>

        <!-- ALERT MODAL -->
        <v-col cols="auto">
          <v-dialog 
            transition="dialog-bottom-transition" 
            max-width="400" 
            v-model="dialog"
          >
            <template v-slot:default="dialog">
              <v-card>
                <v-toolbar color="success" dark
                  >Confirmación de envío</v-toolbar
                >
                <v-card-text>
                  <div class="text-h4" style="margin-top: 10px; ">
                    Actualización de comisiones exitoso!
                  </div>
                </v-card-text>
                <v-card-actions class="justify-end">
                  <v-btn text @click="dialog.value = false, $router.push(`/visualizacion-comisiones/${this.$route.params.uuid}`)">Cerrar</v-btn>
                </v-card-actions>
              </v-card>
            </template>
          </v-dialog>
        </v-col>
        <!-- END OF ALERT MODAL -->
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import moment from 'moment';
import Navigation from "../../../components/Nav.vue";
import { mainAxios } from '../../../mainAxios';
export default {
  components: {
    Navigation,
  },
  data() {
    return {
      rules: {
          loanMin: value => value >= 0 || 'El valor debe ser mayor a 0',
          loanMax: value => value <= 1 || 'El valor maximo puede ser 1',
      },
      dialog: false,
      stepper: 1,
      poliza: null,
      cuotaId: null,
      rolToCompare: JSON.parse(localStorage.getItem("roles"))[0].rol,
      orden: null,
      estadoCuotaItems: [
        'Pendiente de pago',
        'Vencida',
        'Pagada',
        'Cancelada'
      ],
      estadoCuotaSelected: null,
      valorPagar: null,
      checkRehabilitarCuota: false,
      motivoRehabilitarCuota: null,
      fechaPagoAcordada: null,
      fechaLimitePago: null,
      checkCuotaPagada: null,
      fechaPagoCuota: null,
      ejecutivo: null,
      creadoPor: null,
      fechaCreacion: null,
      modificadoPor: null,
      ultimaModificacion: null,
      statusComisionItems: [
        'Pendiente de Pago Aseguradora',
        'Pendiente de Pago al Agente',
        'Pagada al Agente',
        'Cancelada'
      ],
      statusComision: null,
      baseComision: null,
      checkComisionRecibida: null,
      fechaComisionRecibida: null,
      folioMovimientoBancario: null,
      comisionPagadaAseguradora: null,
      montoComisionPagadaAseguradora: null,
      comisionAgente: null,
      montoComisionAgente: null,
      iva: null,
      isr: null,
      retencionIva: null,
      folioFacturaAPagar: null,
      importePagarAgente: null,
      checkComisionAgentePagada: null,
      fechaPagoComisionAgente: null,
      comisionAlige: null,
      montoComisionAlige: null,
      bono: null,
      montoBonoAlige: null,
      folioMovimientoBancarioBono: null,
      fechaPagoBono: null,
      comisionTotal: null,
      montoComisionTotalAlige: null,
      adjuntoPago: [],
      documentosFacturaAgente: null,
      documentosPagoRealizadoAgente: null,
      inputsRequeridos: [(value) => !!value || "Campo Requerido"],
    }
  },
  watch: {
    dialog(val) {
      if (!val) return
      setTimeout(() => (this.dialog = false), 4000)
    },
    checkComisionRecibida(newValue) {
        if(!newValue){
            this.fechaComisionRecibida = null;
            this.folioMovimientoBancario = null;
        }
    },
  },
  methods: {
    goBack() {
      return this.$router.go(-1);
    },

    mostrarCheckRehabilitarCuota() {
      if (this.estadoCuotaSelected === 'Vencida') {
        if (this.rolToCompare === 'ADMIN' || this.rolToCompare === 'OPERACIONES' || this.rolToCompare === 'OPERADOR' || this.rolToCompare === 'MESADECONTROL') {
          return true
        }
        return false
      }
      return false
    },

    actualizarCuota() {
      let config = {
        headers: {
          Authorization: 'Bearer ' + localStorage.agenteAccessToken,
        },
      };
      if (this.adjuntoPago.length > 0) {
        this.cargarArchivos()
      }
      if (this.checkRehabilitarCuota) {
        this.notaMotivoRehabilitacionCuota()
      }
      if(this.documentosFacturaAgente != null){
          this.cargarDocumento('Adjunto de Factura del Agente', `Adjunto Pago de cuota #${this.cuotaId}`)
      }
      
      if(this.documentosPagoRealizadoAgente != null){
          this.cargarDocumentoPago('Adjunto de pago realizado al Agente', `Adjunto de pago realizado al Agente #${this.cuotaId}`)
      }

      

      let request = {
        poliza: this.poliza,
        orden: this.orden,
        estadoCuotaSelected: this.estadoCuotaSelected,
        valorPagar: this.valorPagar,
        checkRehabilitarCuota: this.checkRehabilitarCuota,
        motivoRehabilitarCuota: this.motivoRehabilitarCuota ? this.motivoRehabilitarCuota : null,
        fechaPagoAcordada: this.fechaPagoAcordada ? moment(this.fechaPagoAcordada, 'DD/MM/YYYY').format('YYYY-MM-DD') : null,
        fechaLimitePago: this.fechaLimitePago ? moment(this.fechaLimitePago, 'DD/MM/YYYY').format('YYYY-MM-DD') : null,
        checkPagada: this.checkCuotaPagada ? this.checkCuotaPagada : false,
        fechaPagoCuota: this.fechaPagoCuota ? moment(this.fechaPagoCuota, 'DD/MM/YYYY').format('YYYY-MM-DD') : null,
        fechaCreacion: this.fechaCreacion ? moment(this.fechaCreacion, 'DD/MM/YYYY').format('YYYY-MM-DD') : null,
        ultimaModificacion: this.ultimaModificacion,
        statusComision: this.statusComision ? this.statusComision : null,
        baseComision: Number(this.baseComision),
        checkComisionRecibida: this.checkComisionRecibida ? true: false,
        fechaComisionRecibida: this.fechaComisionRecibida ? moment(this.fechaComisionRecibida, 'DD/MM/YYYY').format('YYYY-MM-DD') : null,
        folioMovimientoBancario: this.folioMovimientoBancario ? this.folioMovimientoBancario : null,
        porcentajeComisionAseguradora: Number(this.comisionPagadaAseguradora),
        montoComisionPagadaAseguradora: this.montoComisionPagadaAseguradora,
        porcentajeComisionAgente: Number(this.comisionAgente),
        montoComisionAgente: this.montoComisionAgente,
        iva: this.iva,
        isr: this.isr,
        retencionIva: this.retencionIva,
        folioFacturaApagar: this.folioFacturaAPagar,
        importePagarAgente: this.importePagarAgente ,
        checkComisionAgentePagada: this.checkComisionAgentePagada ? true: false,
        fechaPagoComisionAgente: this.fechaPagoComisionAgente ? moment(this.fechaPagoComisionAgente, 'DD/MM/YYYY').format('YYYY-MM-DD') : null,
        comisionAlige: this.comisionAlige,
        montoComisionAlige: this.montoComisionAlige,
        bono: this.bono,
        montoBonoAlige: this.montoBonoAlige,
        montoComisionTotalAlige: this.montoComisionTotalAlige,
        folioMovimientoBancarioBono: this.folioMovimientoBancarioBono,
        fechaPagoBono: this.fechaPagoBono ? moment(this.fechaPagoBono, 'DD/MM/YYYY').format('YYYY-MM-DD') : null,
        comisionTotal: this.comisionTotal,
      }
      mainAxios
        .post(`/v1/cuota/update/${this.cuotaId}`, request, config)
        .then(resp => {
          if (resp.status == 200) {
            this.dialog = true;
          }
          setTimeout(() => (this.$router.push(`/visualizacion-comisiones/${this.$route.params.uuid}`)), 2000);
        })
        .catch((error) => {
          console.log(error);
        });


    },
    cargarDocumento(name, description) {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
          'Content-Type': 'multipart/form-data'
        },
      };

      const formData = new FormData();
      const tipo = 'Documento'
      const tipoDocumento = 'Otro'
      const nombre = name
      const descripcion = description
      for( let i = 0; i < this.documentosFacturaAgente.length; i++ ){
        let file = this.documentosFacturaAgente[i];
        formData.append('file', file);
      }
      formData.append('nota', descripcion);
      formData.append('tipo', tipo);
      formData.append('tipo_documento', tipoDocumento);
      formData.append('nombre', nombre);
      mainAxios.post(`/v1/cuota/documento/upload/${this.cuotaId}`, formData,config)
      .then(function (result) {
        console.log(result);
      }, function (error) {
        console.log(error);
      });
    },

    cargarDocumentoPago(name, description) {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
          'Content-Type': 'multipart/form-data'
        },
      };

      const formData = new FormData();
      const tipo = 'Documento'
      const tipoDocumento = 'Otro'
      const nombre = name
      const descripcion = description
      for( let i = 0; i < this.documentosPagoRealizadoAgente.length; i++ ){
        let file = this.documentosPagoRealizadoAgente[i];
        formData.append('file', file);
      }
      formData.append('nota', descripcion);
      formData.append('tipo', tipo);
      formData.append('tipo_documento', tipoDocumento);
      formData.append('nombre', nombre);
      mainAxios.post(`/v1/cuota/documento/upload/${this.cuotaId}`, formData,config)
      .then(function (result) {
        console.log(result);
      }, function (error) {
        console.log(error);
      });
    },

    

    cargarArchivos() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
          'Content-Type': 'multipart/form-data'
        },
      };

      const formData = new FormData();
      const tipo = 'Documento'
      const tipoDocumento = 'Otro'
      const nombre = 'Adjunto Pago Cuota'
      const descripcion = `Adjunto Pago de cuota #${this.cuotaId}`
      for( let i = 0; i < this.adjuntoPago.length; i++ ){
        let file = this.adjuntoPago[i];
        formData.append('file', file);
      }
      formData.append('nota', descripcion);
      formData.append('tipo', tipo);
      formData.append('tipo_documento', tipoDocumento);
      formData.append('nombre', nombre);
      mainAxios.post(`/v1/cuota/documento/upload/${this.cuotaId}`, formData,config)
      .then(function (result) {
        console.log(result);
      }, function (error) {
        console.log(error);
      });
    },

    notaMotivoRehabilitacionCuota() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
          'Content-Type': 'multipart/form-data'
        },
      };
      const formData = new FormData();
      const tipo = 'Nota'
      const nombre = `Cuota #${this.cuotaId} Rehabilitada`
      formData.append('nota', this.motivoRehabilitarCuota ? this.motivoRehabilitarCuota : null);
      formData.append('tipo', tipo);
      formData.append('nombre', nombre);

      mainAxios.post(`/v1/cuota/documento/upload/${this.cuotaId}`, formData,config)
        .then(function (result) {
          console.log(result);
        }, function (error) {
          console.log(error);
        });
    },

    cargarCuota() {
      let config = {
          headers: {
          Authorization: 'Bearer ' + localStorage.agenteAccessToken,
          },
      };
      mainAxios.get(`/v1/cuota/uuid/${this.$route.params.uuid}`, config).then(response => {
        this.poliza = response.data.emisionId
        this.orden = response.data.orden
        this.estadoCuotaSelected = response.data.status
        this.valorPagar = response.data.valorAPagar
        this.fechaPagoAcordada = response.data.fechaPagoAcordada ? moment(response.data.fechaPagoAcordada).format('DD/MM/YYYY') : null
        this.fechaLimitePago = response.data.fechaLimitePago ? moment(response.data.fechaLimitePago).format('DD/MM/YYYY') : null
        // this.checkCuotaPagada = null
        this.fechaPagoCuota = response.data.fechaPago ? moment(response.data.fechaPago).format('DD/MM/YYYY') : null
        this.ejecutivo = response.data.operadorId ? response.data.operadorId : null
        this.creadoPor = response.data.creadoPor ? response.data.creadoPor : null
        this.fechaCreacion = response.data.fechaCreacion ? moment.utc(response.data.fechaCreacion).subtract(6, 'hours').format('DD/MM/YYYY HH:mm:ss') : null
        this.modificadoPor = response.data.modificadoPor ? response.data.modificadoPor : null,
        this.ultimaModificacion = response.data.ultimaModificacion ? moment.utc(response.data.ultimaModificacion).subtract(6, 'hours').format('DD/MM/YYYY HH:mm:ss') : null
        this.statusComision = response.data.statusComision
        this.baseComision = response.data.baseComision 
        this.checkComisionRecibida = response.data.comisionRecibida ? response.data.comisionRecibida == 0 ? false : true : null
        this.fechaComisionRecibida = response.data.fechaComisionRecibida ? moment(response.data.fechaComisionRecibida).format('DD/MM/YYYY') : null
        this.folioMovimientoBancario = response.data.folioMovimientoBancario
        this.comisionPagadaAseguradora = response.data.porcentajeComisionAseguradora
        this.montoComisionPagadaAseguradora = response.data.montoComisionPagadaAseguradora
        this.comisionAgente = response.data.porcentajeComisionAgente
        this.montoComisionAgente = response.data.montoComisionAgente
        this.iva = response.data.iva
        this.isr = response.data.isr
        this.retencionIva = response.data.retencionIva
        this.folioFacturaAPagar = response.data.folioFacturaAPagar
        this.importePagarAgente = response.data.importeAPagarAgente
        this.comisionAgentePagada = response.data.comisionAgentePagada ? response.data.comisionAgentePagada == 0 ? false : true : null
        this.fechaPagoComisionAgente = response.data.fechaPago
        this.comisionAlige = response.data.porcentajeComisionSegurify
        this.montoComisionAlige = response.data.montoComisionSegurify
        this.bono = response.data.porcentajeBono
        this.montoBonoAlige = response.data.montoBonoSegurify
        this.folioMovimientoBancarioBono = response.data.folioMovimientoBancarioBono
        this.fechaPagoBono = response.data.fechaPagoBono
        this.comisionTotal = response.data.porcentajeComisionTotal
        this.montoComisionTotalAlige = response.data.montoComisiontotalSegurify
        this.cuotaId = response.data.id
      })
    },

    comportamientoBotonGuardar() {
      if(this.checkComisionAgentePagada != null && this.fechaPagoComisionAgente != null && this.documentosPagoRealizadoAgente != null){
        return true;
      }
      if (this.checkCuotaPagada == true && this.estadoCuotaSelected == 'Pendiente de pago') {
        return this.validarFormCuotaPagada
      } 
      if (this.statusComision == 'Pendiente de Pago al Agente') {
        return this.validarFormPendientePagoAgente
      } 
      if (this.checkComisionAgentePagada) {
        return this.validarFormComisionPagadaAgente
      }

      return true
    },

  },
  computed: {
    validarFormCuotaPagada() {
      return (
        this.fechaPagoCuota != null &&
        this.adjuntoPago.length > 0
      )
    },
    validarFormPendientePagoAgente() {
      return (
        this.folioFacturaAPagar != null &&
        this.documentosFacturaAgente != null
      )
    },
    validarFormComisionPagadaAgente() {
      return (
        this.documentosPagoRealizadoAgente.length < 0
      )
    },
    canViewFinances(){
    var roles = JSON.parse(localStorage.getItem("roles"))
    var canView = false;
      roles.forEach(role => {
        if (role.rol == "ADMIN" || role.rol == "FINANZAS") {
          canView = true
        }
      })
      return canView;
    },
    isAgente(){
      var roles = JSON.parse(localStorage.getItem("roles"))
      var isAgent = false;
      var isAdmin = false;

      console.log(isAgent)
        roles.forEach(role => {
          if (role.rol == "AGENTE") {
            isAgent = true
          }
          if (role.rol == "ADMIN" || role.rol == "FINANZAS" || role.rol == "OPERACIONES" || role.rol == "ADMINCC" || role.rol == "MANAGERCC" || role.rol == "OPERADOR" ) {
            isAdmin = true
          }
        })

        
        return isAdmin ? false : isAgent;
    }
  },
  mounted() {
    this.cargarCuota()
  }
}
</script>

<style>
  .v-stepper__step__step {
    color: transparent !important;
    display: none !important;
  }
</style>