<template>
  <v-app>
    <Navigation></Navigation>
    <v-main class="mb-15">
      <v-container fluid>
        <v-card color="grey lighten-4" flat tile style="margin-top: 20px"
          ><v-toolbar>
            <v-toolbar-title>
              Solicitud #{{ this.solicitud[0].id }}
            </v-toolbar-title>

            <v-spacer></v-spacer>
            <v-btn
              icon
              v-if="canEdit || estadoSelected == 'Pendiente de Aprobacion'"
              v-show="estadoSelected != 'Cancelada'"
              @click="verSolicitudEditable(solicitud[0].id)"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>

            <v-btn @click="goBack()"><v-icon style="margin-right: 5px">mdi-arrow-left</v-icon>Regresar
            </v-btn>
          </v-toolbar>
          <p style="margin-top: 15px; padding-bottom: 10px">
            {{ this.solicitud[0].titulo + ' / ' + this.nombres + ' ' + this.apellidoPaterno + ' ' + (this.apellidoMaterno ? this.apellidoMaterno : '') }}
          </p>
        </v-card>
        <v-divider></v-divider>

        <v-row>
          <v-col cols="7">
            <v-form>
              <v-row>
                <v-col>
                  <v-stepper non-linear>
                    <v-stepper-header>
                      <v-stepper-step step="1" editable>
                        Datos de la Solicitud
                      </v-stepper-step>

                      <v-stepper-step step="2" editable>
                        Información Requerida
                      </v-stepper-step>

                      <v-stepper-step step="3" editable>
                        SLA de la Solicitud
                      </v-stepper-step>
                    </v-stepper-header>

                    <v-stepper-items>
                      <!-- 1ST ITEM (FORM) -->
                      <v-stepper-content step="1">
                        <v-row style="padding: 10px">
                          <v-col cols="12">
                              <v-alert
                                dense
                                type="info"
                                text
                              >
                                Datos generales de la <strong>Solicitud</strong>.
                              </v-alert>
                            </v-col>
                          <v-col cols="4">
                            <v-text-field
                              label="Estatus de la Solicitud"
                              v-model="solicitud[0].status"
                              readonly
                              outlined
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-text-field
                              label="Origen de la solicitud"
                              v-model="solicitud[0].origen"
                              readonly
                              outlined
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4" v-if="!creadaInbounds">
                            <v-text-field
                              label="Agente"
                              v-model="solicitud[0].nombreAgente"
                              :readonly="isAgent == true"
                              outlined
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-text-field
                              label="Tipo"
                              v-model="solicitud[0].tipo"
                              readonly
                              outlined
                            ></v-text-field>
                          </v-col>
                          <!-- End 2nd Col -->
                          <v-col cols="4" xs="12" v-if="creadaInbounds">
                              <v-text-field
                                label="Actividad de solicitud"
                                v-model="actividadSolicitud"
                                outlined
                                readonly
                              ></v-text-field>
                            </v-col>
                            <v-col cols="8">
                            <v-textarea
                              label="Detalle de Solicitud"
                              v-model="detalle"
                              rows="1"
                              auto-grow
                              readonly
                              outlined
                            ></v-textarea>
                          </v-col>
                          <v-col cols="4" v-if="!creadaInbounds">
                            <v-text-field
                              label="Ramo"
                              v-model="solicitud[0].categoria"
                              readonly
                              outlined
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4" v-if="!creadaInbounds">
                            <v-text-field
                              label="Productos"
                              v-model="productoSelected"
                              readonly
                              outlined
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-textarea
                              label="Campaña"
                              v-model="campania"
                              rows="1"
                              auto-grow
                              readonly
                              outlined
                            ></v-textarea>
                          </v-col>
                          <v-col cols="6" xs="12" v-if="creadaInbounds">
                              <v-text-field
                                v-model="ejecutivo"
                                label="Operador Inbound"
                                :items="operadoresInbound"
                                item-text="nombreCompleto"
                                item-value="id"
                                no-data-text="Primero selecciona un ramo"
                                outlined
                                readonly
                              ></v-text-field>
                            </v-col>
                          <v-col cols="6" v-if="!creadaInbounds">
                            <v-textarea
                              label="ID de Origen Lead"
                              v-model="solicitudBody.idOrigenLead"
                              rows="1"
                              auto-grow
                              readonly
                              outlined
                            ></v-textarea>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col cols="12">
                            <v-alert
                              dense
                              type="info"
                              text
                            >
                              Datos personales del <strong>cliente</strong>.
                            </v-alert>
                          </v-col>
                          <!-- End 7th Col -->
                        </v-row>
                        <v-row style="padding: 10px">
                            <v-col cols="4">
                                <v-text-field
                                  v-model="regimenPersona"
                                  label="Tipo de Persona"
                                  placeholder="Requerido"
                                  outlined
                                  readonly
                                ></v-text-field>
                              </v-col>
                              <v-col cols="4" xs="12"  v-if="regimenPersona == 'Moral'">
                              <v-text-field
                                label="Razón Social"
                                v-model="razonSocial"
                                outlined
                              ></v-text-field>
                            </v-col>
                          </v-row>

                        <v-row style="padding: 10px;">
                          <v-col cols="4">
                            <v-text-field
                              label="Nombre(s)"
                              v-model="nombres"
                              readonly
                              outlined
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-text-field
                              label="Apellido Paterno"
                              v-model="apellidoPaterno"
                              readonly
                              outlined
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-text-field
                              label="Apellido Materno"
                              v-model="apellidoMaterno"
                              readonly
                              outlined
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-text-field
                              label="Fecha de Nacimiento"
                              v-model="fechaNacimiento"
                              readonly
                              outlined
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-text-field
                              label="Fecha de Nacimiento"
                              v-model="fechaNacimiento"
                              readonly
                              outlined
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-text-field
                              label="RFC"
                              v-model="rfc"
                              readonly
                              outlined
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-text-field
                              label="Género"
                              v-model="selectedGenero"
                              readonly
                              outlined
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-text-field
                              label="Edad"
                              v-model="solicitudBody.edad"
                              readonly
                              outlined
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-text-field
                              label="Estado Civil"
                              v-model="estadoCivilSelected"
                              readonly
                              outlined
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-text-field
                              placeholder="(Requerido)##########"
                              v-model="celular"
                              label="Teléfono"
                              outlined
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-text-field
                              label="Correo Electrónico"
                              v-model="correoElectronico"
                              readonly
                              outlined
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4" v-if="numeroPoliza">
                              <v-text-field
                                v-model="numeroPoliza"
                                label="Numero de poliza"
                                outlined
                              ></v-text-field>
                            </v-col>
                            <v-col cols="4">
                              <v-text-field
                                v-model="inicioVigencia"
                                label="Inicio de Vigencia"
                                prepend-icon="mdi-calendar"
                                placeholder="Requerido (DD/MM/AAAA)"
                                v-mask="'##/##/####'"
                                readonly
                                outlined
                              ></v-text-field>
                            </v-col>
                            <v-col cols="4">
                              <v-text-field
                                v-model="finVigencia"
                                label="Fin de Vigencia"
                                prepend-icon="mdi-calendar"
                                placeholder="Requerido (DD/MM/AAAA)"
                                v-mask="'##/##/####'"
                                readonly
                                hint="Calculada automáticamente"
                                outlined
                              ></v-text-field>
                            </v-col>

                        </v-row>

                        <v-row style="padding: 10px;">
                          <v-col cols="12">
                            <v-alert
                                dense
                                type="info"
                                text
                              >
                                Domicilio del <strong>cliente</strong>.
                              </v-alert>
                          </v-col>
                          <v-col cols="4">
                            <v-text-field
                                label="Código Postal"
                                v-model="codigoPostal"
                                readonly
                                outlined
                              ></v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-text-field
                              label="Calle"
                              v-model="solicitudBody.calle"
                              readonly
                              outlined
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-text-field
                              label="Número Exterior"
                              v-model="solicitudBody.numExterior"
                              readonly
                              outlined
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-text-field
                              label="Número Interior"
                              v-model="solicitudBody.numInterior"
                              readonly
                              outlined
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-text-field
                              label="Colonia"
                              v-model="solicitudBody.colonia"
                              readonly
                              outlined
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-text-field
                              label="Municipio"
                              v-model="solicitudBody.municipio"
                              readonly
                              outlined
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-text-field
                              label="Estado de la República"
                              v-model="estadoRepublicaSelected"
                              readonly
                              outlined
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row style="padding: 10px;" v-if="mostrarInputs()">
                          <v-col cols="4">
                            <v-text-field
                              label="Inicio de Vigencia"
                              v-model="inicioVigencia"
                              prepend-icon="mdi-calendar"
                              readonly
                              outlined
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-text-field
                              label="Fin de Vigencia"
                              v-model="finVigencia"
                              prepend-icon="mdi-calendar"
                              readonly
                              outlined
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-text-field
                              label="Años"
                              v-model="solicitudBody.anios"
                              readonly
                              outlined
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4" v-if="polizaRelacionada">
                            <v-text-field
                              label="No. de Póliza Relacionada"
                              v-model="polizaRelacionada"
                              class="enlacesForms"
                              @click="verPolizaRelacionada()"
                              readonly
                              outlined
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row style="padding: 10px;" v-if="mostrarInputs()">
                          <v-col cols="4">
                            <v-text-field
                              v-model="periodicidadSelected"
                              label="Periodicidad"
                              readonly
                              outlined                              
                            ></v-text-field>
                          </v-col>

                          <v-col cols="4">
                            <v-text-field
                              v-model="formaPagoSelected"
                              label="Forma de Pago"
                              readonly
                              outlined
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row style="padding-left: 20px">
                          <v-col cols="6">
                            <v-text-field
                              v-model="creadoPor"
                              label="Creado por"
                              readonly
                              outlined
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              v-model="fechaCreacion"
                              label="Fecha de Creacion"
                              readonly
                              outlined
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6" style="margin-top: -30px">
                            <v-text-field
                              v-model="modificadoPor"
                              label="Modificado Por"
                              readonly
                              outlined
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6" style="margin-top: -30px">
                            <v-text-field
                              v-model="fechaModificacion"
                              label="Fecha de Modificacion"
                              readonly
                              outlined
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <!-- <v-row style="padding: 20px; margin-top: -70px">
                          <v-col cols="4">
                            <v-text-field
                              v-model="tipoVehiculoSelected"
                              label="Tipo de Vehículo"
                              readonly
                            ></v-text-field> 
                          </v-col>

                          <v-col cols="4">
                            <v-text-field
                              v-model="usoAutoSelected"
                              label="Uso del Auto"
                              readonly
                            ></v-text-field>
                          </v-col>

                          <v-col cols="4" v-if="mostrarInputs()">
                            <v-text-field
                              label="Modelo"
                              v-model="modelo"
                              readonly
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row style="padding: 20px; margin-top: -70px" v-if="mostrarInputs()">
                         <v-col cols="4">
                            <v-text-field
                              label="Marca"
                              v-model="marca"
                              readonly
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-text-field
                              label="Submarca"
                              v-model="submarca"
                              readonly
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-text-field
                              label="Version"
                              v-model="version"
                              readonly
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <div>
                          <v-row style="padding: 20px; margin-top: -70px" v-if="mostrarInputs()">
                            <v-col cols="4">
                              <v-text-field
                                v-model="numMotor"
                                label="No. De Motor"
                                readonly
                              ></v-text-field>
                            </v-col>

                            <v-col cols="4">
                              <v-text-field
                                v-model="numSerie"
                                label="No. De Serie"
                                readonly
                              ></v-text-field>
                            </v-col>

                            <v-col cols="4">
                              <v-text-field
                                v-model="placas"
                                label="Placas"
                                readonly  
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </div> -->
                      </v-stepper-content>

                      <!-- 2ND ITEM (2ND FORM) -->
                      <v-stepper-content step="2">
                        <v-row style="padding: 25px;">
                          <v-col cols="6" v-if="!creadaInbounds">
                            <v-text-field
                              label="Ejecutivo"
                              v-model="ejecutivo"
                              readonly
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6" v-if="tipoSelected == 'Cotización' || tipoSelected == 'Emisión' || tipoSelected == 'Renovación'">
                            <v-text-field
                              label="Proceso de Cotización"
                              v-model="procesoCotizacion"
                              readonly
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row style="padding: 20px; margin-top: -70px">
                          <v-col cols="6">
                            <v-text-field
                              label="¿Solicitud Completa?"
                              v-model="solicitudCompletaSelected"
                              readonly
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6" v-if="valorOportunidad">
                            <v-text-field
                              label="Valor Oportunidad"
                              v-model="valorOportunidad"
                              prefix="$"
                              readonly
                            ></v-text-field>
                          </v-col>
                        </v-row>

                      </v-stepper-content>

                      <!-- 3RD ITEM (SLA) -->
                      <v-stepper-content step="3">
                        <v-row style="padding: 10px">
                          <v-col cols="6">
                            <p>SLA: {{ cumpleSla }}</p>
                          </v-col>
                        </v-row>

                        <v-row style="padding: 10px; margin-top: -50px" v-if="fechaInfoCompleta && horaInfoCompleta">
                          <v-col cols="6">
                            <p>Fecha de Información completa</p>
                          </v-col>
                        </v-row>

                        <v-row style="padding: 20px; margin-top: -80px" v-if="fechaInfoCompleta && horaInfoCompleta">
                          <v-col cols="6">
                            <v-text-field
                              v-model="fechaInfoCompleta"
                              placeholder="dd/mm/yyyy"
                              prepend-icon="mdi-calendar"
                              readonly
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              v-model="horaInfoCompleta"
                              placeholder="hh:mm"
                              prepend-icon="mdi-clock"
                              readonly
                            ></v-text-field>
                          </v-col>
                          <!-- End 2nd Col -->
                        </v-row>

                        <v-row style="padding: 10px; margin-top: -50px" v-if="fechaPendienteInfo && horaPendienteInfo">
                          <v-col cols="6">
                            <p>Fecha de Pendiente de información</p>
                          </v-col>
                        </v-row>

                        <v-row style="padding: 20px; margin-top: -80px" v-if="fechaPendienteInfo && horaPendienteInfo">
                          <v-col cols="6">
                            <v-text-field
                              v-model="fechaPendienteInfo"
                              placeholder="dd/mm/yyyy"
                              prepend-icon="mdi-calendar"
                              readonly
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              v-model="horaPendienteInfo"
                              placeholder="hh:mm"
                              prepend-icon="mdi-clock"
                              readonly
                            ></v-text-field>
                          </v-col>
                          <!-- End 3rd Col -->
                        </v-row>

                        <v-row style="padding: 10px; margin-top: -50px" v-if="fechaEnTramite && horaEnTramite">
                          <v-col cols="6">
                            <p>Fecha En Trámite</p>
                          </v-col>
                        </v-row>

                        <v-row style="padding: 20px; margin-top: -80px" v-if="fechaEnTramite && horaEnTramite">
                          <v-col cols="6">
                            <v-text-field
                              v-model="fechaEnTramite"
                              placeholder="dd/mm/yyyy"
                              prepend-icon="mdi-calendar"
                              readonly
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              v-model="horaEnTramite"
                              placeholder="hh:mm"
                              prepend-icon="mdi-clock"
                              readonly
                            ></v-text-field>
                          </v-col>
                          <!-- End 2nd Col -->
                        </v-row>

                        <v-row style="padding: 10px; margin-top: -50px" v-if="fechaCotizacionTramitada && horaCotizacionTramitada">
                          <v-col cols="6">
                            <p>Fecha de Cotización Tramitada</p>
                          </v-col>
                        </v-row>

                        <v-row style="padding: 20px; margin-top: -80px" v-if="fechaCotizacionTramitada && horaCotizacionTramitada">
                          <v-col cols="6">
                            <v-text-field
                              v-model="fechaCotizacionTramitada"
                              placeholder="dd/mm/yyyy"
                              prepend-icon="mdi-calendar"
                              readonly
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              v-model="horaCotizacionTramitada"
                              placeholder="hh:mm"
                              prepend-icon="mdi-clock"
                              readonly
                            ></v-text-field>
                          </v-col>
                          <!-- End 2nd Col -->
                        </v-row>

                        <v-row style="padding: 10px; margin-top: -50px" v-if="fechaRecotizacion && horaRecotizacion">
                          <v-col cols="6">
                            <p>Fecha de Recotización</p>
                          </v-col>
                        </v-row>

                        <v-row style="padding: 20px; margin-top: -80px" v-if="fechaRecotizacion && horaRecotizacion">
                          <v-col cols="6">
                            <v-text-field
                              v-model="fechaRecotizacion"
                              placeholder="dd/mm/yyyy"
                              prepend-icon="mdi-calendar"
                              readonly
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              v-model="horaRecotizacion"
                              placeholder="hh:mm"
                              prepend-icon="mdi-clock"
                              readonly
                            ></v-text-field>
                          </v-col>
                          <!-- End 2nd Col -->
                        </v-row>

                        <v-row style="padding: 10px; margin-top: -50px" v-if="fechaEmisionEnProceso && horaEmisionEnProceso">
                          <v-col cols="6">
                            <p>Fecha de Emisión en Proceso</p>
                          </v-col>
                        </v-row>

                        <v-row style="padding: 20px; margin-top: -80px" v-if="fechaEmisionEnProceso && horaEmisionEnProceso">
                          <v-col cols="6">
                            <v-text-field
                              v-model="fechaEmisionEnProceso"
                              placeholder="dd/mm/yyyy"
                              prepend-icon="mdi-calendar"
                              readonly
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              v-model="horaEmisionEnProceso"
                              placeholder="hh:mm"
                              prepend-icon="mdi-clock"
                              readonly
                            ></v-text-field>
                          </v-col>
                          <!-- End 2nd Col -->
                        </v-row>

                        <v-row style="padding: 10px; margin-top: -50px" v-if="fechaFinalizacion && horaFinalizacion">
                          <v-col cols="6">
                            <p>Fecha de Finalización</p>
                          </v-col>
                        </v-row>

                        <v-row style="padding: 20px; margin-top: -80px" v-if="fechaFinalizacion && horaFinalizacion">
                          <v-col cols="6">
                            <v-text-field
                              v-model="fechaFinalizacion"
                              placeholder="dd/mm/yyyy"
                              prepend-icon="mdi-calendar"
                              readonly
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              v-model="horaFinalizacion"
                              placeholder="hh:mm"
                              prepend-icon="mdi-clock"
                              readonly
                            ></v-text-field>
                          </v-col>
                          <!-- End 6th Col -->
                        </v-row>

                        <v-row style="padding: 10px; margin-top: -50px" v-if="fechaCancelacion && horaCancelacion">
                          <v-col cols="6">
                            <p>Fecha de Cancelación</p>
                          </v-col>
                        </v-row>

                        <v-row style="padding: 20px; margin-top: -80px" v-if="fechaCancelacion && horaCancelacion">
                          <v-col cols="6">
                            <v-text-field
                              v-model="fechaCancelacion"
                              placeholder="dd/mm/yyyy"
                              prepend-icon="mdi-calendar"
                              readonly
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              v-model="horaCancelacion"
                              placeholder="hh:mm"
                              prepend-icon="mdi-clock"
                              readonly
                            ></v-text-field>
                          </v-col>
                          <!-- End 2nd Col -->
                        </v-row>

                        <v-btn text @click="stepper = 2"> Regresar </v-btn>
                      </v-stepper-content>
                    </v-stepper-items>
                  </v-stepper>
                </v-col>
              </v-row>
            </v-form>
          </v-col>

          <v-col cols="5">
            <v-card style="margin-bottom: 40px" class="pt-1">
              <v-row>
                <v-col cols="10">
                  <v-subheader>NOTAS Y DOCUMENTOS</v-subheader>
                </v-col>
                <v-col style="aling: right" class="mt-2" cols="2">
                  <v-btn small color="primary" @click="documentsDialog = true">
                    Crear
                  </v-btn>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-data-table
                :headers="columnsDocumentos"
                :items="documentos"
                item-key="name"
                class="elevation-1"
                loading-text="Cargando..."
                dense
              >
                <template v-slot:top>
                  <!-- <v-text-field
                                    v-model="search"
                                    label="Buscar"
                                    class="mx-4"
                                    ></v-text-field> -->
                </template>
                <template v-slot:item.url="{ item }">
                  <v-icon
                    small
                    class="mr-2"
                    @click="verDocumento(item)"
                  >
                    mdi-eye
                  </v-icon>
                </template>
                <template v-slot:item.nombre="{ item }">
                  <template v-if="item.nombre == 'INE'">
                    <v-chip
                        class="ma-2"
                        color="orange"
                        text-color="white"
                        append-icon="mdi-star"
                      >
                      {{ item.nombre }}
                    </v-chip>
                  </template>
                  <template v-else>
                    {{ item.nombre }}
                  </template>
                </template>
              </v-data-table>
            </v-card>

            <v-card style="margin-top: 15px">
              <v-row>
                <v-col cols="6">
                  <v-subheader>EMAILS</v-subheader>
                </v-col>
                <v-col cols="4">
                  <v-btn icon>
                    <v-icon>mdi-magnify</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="2">
                  <v-btn @click="newCorreoDialog=true" small color="primary">
                    Crear
                  </v-btn>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-data-table
                :headers="columns"
                :items="correos"
                item-key="name"
                class="elevation-1"
                loading-text="Cargando..."
                dense
              >
                <template v-slot:top>
                  <v-text-field
                    v-model="search"
                    label="Buscar"
                    class="mx-4"
                  ></v-text-field>
                </template>
                <template v-slot:item="row">
                    <tr>
                      <td>{{row.item.asunto}}</td>
                      <td>{{row.item.fecha}}</td>
                      <td>{{row.item.to}}</td>
                      <td style="cursor:pointer;" @click="verDetalleCorreo(row.item)">
                        <v-icon light small class="mr-2">mdi-eye</v-icon>
                      </td>
                    </tr>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>

    <!-- MODAL VER DOCUMENTOS -->
    <v-dialog
      v-model="dialogDetalleDocumentos"
      max-width="750px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Detalle de Documento / Nota</span>
        </v-card-title>

        <template>
          <v-container style="padding: 30px">
              <v-row>
                <v-col cols="6">
                    <v-text-field
                        label="Nombre"
                        v-model="detallesDocumento.nombre"
                        readonly
                    ></v-text-field>
                </v-col>
                <v-col cols="6">
                    <v-checkbox
                        label="¿Enviar por correo electrónico?"
                        readonly
                    ></v-checkbox>
                </v-col>
                <v-col cols="6">
                    <v-select
                        label="Tipo"
                        :items="tipoItems"
                        v-model="detallesDocumento.tipo"
                        required
                        readonly
                    ></v-select>
                </v-col>
                <v-col cols="6" v-if="detallesDocumento.tipo == 'Documento'">
                    <v-select
                        label="Tipo de Doocumento*"
                        :items="tipoDocumentoItems"
                        v-model="detallesDocumento.tipoDocumento"
                        required
                        readonly
                    ></v-select>
                </v-col>
                <v-col cols="6">
                    <v-text-field
                        label="Descripción"
                        v-model="detallesDocumento.descripcion"
                        required
                        readonly
                    ></v-text-field>
                </v-col>
                <v-col cols="4" v-if="detallesDocumento.url">
                  <template>
                    <v-file-input
                      v-model="documentos"
                      color="primary"
                      label="Adjuntos*"
                    >
                      <template v-slot:selection="{ index }">
                        <v-chip
                          v-if="index < 1"
                          color="primary"
                          dark
                          label
                          small
                          @click="detallesDocumento.url"
                        >
                          {{ detallesDocumento.nombre }}
                        </v-chip>

                        <span
                          v-else-if="index === 2"
                          class="text-overline grey--text text--darken-3 mx-2"
                        >
                          +{{ documentos.length - 2 }} Archivos(s)
                        </span>
                      </template>
                    </v-file-input>
                  </template>
                </v-col>
                <v-col cols="2" v-if="detallesDocumento.url">
                  <v-btn
                    text
                    :href="detallesDocumento.url"
                    small
                    color="primary"
                    class="mt-4"
                  >
                  <v-icon>mdi-download</v-icon>
                    Descargar
                  </v-btn>
                </v-col>
                <v-col cols="6">
                    <v-text-field
                        label="Creado por"
                        v-model="detallesDocumento.creadoPor"
                        readonly
                    ></v-text-field>
                </v-col>
                <v-col cols="6">
                    <v-text-field
                        label="Fecha de Creación"
                        v-mask="'##/##/####'"
                        v-model="detallesDocumento.fecha"
                        readonly
                    ></v-text-field>
                </v-col>
              </v-row>
          </v-container>
        </template>

        <v-card-actions>
              <v-spacer></v-spacer>
                <v-btn
                  color="primary darken-1"
                  text
                  @click="closeDocumentoDialog"
                >
                  Cerrar
                </v-btn>
              </v-card-actions>
      </v-card>

    </v-dialog>

    <!-- MODAL DE CORREO -->
    <v-dialog
      v-model="dialogCorreo"
      width="600px"
    >

      <v-card style="padding: 30px">
        <v-row>
          <v-col cols="12">
            <v-text-field
            label="De"
            v-model="correoOpen.remitente"
            hide-details="auto"
            readonly
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-text-field
            label="Para"
            v-model="correoOpen.to"
            hide-details="auto"
            readonly
            ></v-text-field>
          </v-col>
        </v-row>
        
        <v-row>
          <v-col cols="12">
            <v-text-field
            label="CC"
            v-model="correoOpen.cc"
            hide-details="auto"
            readonly
            ></v-text-field>
          </v-col>
        </v-row>  
        <v-row>
          <v-col cols="12">
            <v-text-field
            label="BCC"
            v-model="correoOpen.bcc"
            hide-details="auto"
            readonly
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
            label="Asunto"
            v-model="correoOpen.asunto"
            hide-details="auto"
            readonly
            ></v-text-field>
          </v-col>
        </v-row> 
        <v-row>
          <v-col cols="12">
            <v-textarea
            v-model="correoOpen.cuerpo"
            label="Mensaje"
            readonly
            ></v-textarea>
          </v-col>
        </v-row>
        
        <v-row v-if="correoOpen.documentos">
          <v-col>
            <p>Descargar Adjuntos</p>
          </v-col>
        </v-row>
        <v-row v-if="correoOpen.documentos">
          <v-col>
            <v-chip
              v-for="(documento, index) in correoOpen.documentos"
              v-bind:key="index"
              color="primary"
              outlined
              :href="documento.url"
              style="cursor: pointer"
            >
              <v-icon left>
                mdi-tray-arrow-down
              </v-icon>
              {{ documento.nombre }}
            </v-chip>
          </v-col>
        </v-row>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialogCorreo = false"
          >
           Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
    <!-- SEND EMAIL -->
    <v-row justify="center">
      <EmailForm 
          :newCorreoDialog="newCorreoDialog"
          @closeSendEmail="closeSendEmail"
          :solicitud="idSolicitud"
          :idAgente="solicitud[0].agenteId"
          :correoContacto="solicitud[0].correoElectronico"
      />
    </v-row>

    <!-- ADD DOCUMENTS -->
    <v-row justify="center">
      <v-dialog
        v-model="documentsDialog"
        max-width="750px"
      >
        <v-card>
          <v-card-title>
            <span class="text-h5">Creación de Documento / Nota</span>
          </v-card-title>

          <div style="padding: 30px">
            <DocumentosNotasModal 
              :accionGuardar="accionGuardar" 
              :solicitud="idSolicitud" 
              @reload_info="obtnerDatosSolicitud"
            />
          </div>
          
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="documentsDialog = false"
            >
              Cerrar
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="accionGuardar = true, documentsDialog = false"
            >
              Guardar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-app>
</template>

<script>
import moment from 'moment';
import Navigation from "../../../components/Nav.vue";
import EmailForm from "../../components/EmailForm";
import DocumentosNotasModal from '../../components/DocumentosNotasModal.vue';
import { mainAxios } from "../../../mainAxios";
export default {
  components: {
    Navigation,
    EmailForm,
    DocumentosNotasModal
  },
  data() {
    return {
      documentsDialog: false,
      newCorreoDialog:false,
      dialogCorreo:false,
      accionGuardar: false,
      correoOpen:{},
      dialogDetalleDocumentos: false,
      detallesDocumento: {},
      expanded: [],
      hidden: false,
      roles: JSON.parse(localStorage.getItem("roles")),
      solicitud: [],
      correos: [],
      documentos: [],
      columns: [
        {
          text: "Asunto",
          align: "start",
          sortable: false,
          value: "asunto",
        },
        { text: "Fecha", value: "fecha" },
        { text: "Para", value: "remitente" },
      ],
      columnsDocumentos: [
        {
          text: "Nombre",
          align: "start",
          sortable: false,
          value: "nombre",
        },
        { text: "Tipo", value: "tipo" },
        { text: "Descripción", value: "descripcion" },
        { text: "Creado por", value: "creadoPor" },
        { text: "Fecha", value: "fecha" },
        { text: "Ver", value: "url" },
      ],
      estados: [
        {id: 1, estadoRepublica: 'Aguascalientes'},
        {id: 2, estadoRepublica: 'Baja California'},
        {id: 3, estadoRepublica: 'Baja California Sur'},
        {id: 4, estadoRepublica: 'Campeche'},
        {id: 5, estadoRepublica: 'Coahuila de Zaragoza'},
        {id: 6, estadoRepublica: 'Colima'},
        {id: 7, estadoRepublica: 'Chiapas'},
        {id: 8, estadoRepublica: 'Chihuahua'},
        {id: 9, estadoRepublica: 'Ciudad de México'},
        {id: 10, estadoRepublica: 'Durango'},
        {id: 11, estadoRepublica: 'Guanajuato'},
        {id: 12, estadoRepublica: 'Guerrero'},
        {id: 13, estadoRepublica: 'Hidalgo'},
        {id: 14, estadoRepublica: 'Jalisco'},
        {id: 15, estadoRepublica: 'Estado de México'},
        {id: 16, estadoRepublica: 'Michoacán de Ocampo'},
        {id: 17, estadoRepublica: 'Morelos'},
        {id: 18, estadoRepublica: 'Nayarit'},
        {id: 19, estadoRepublica: 'Nuevo León'},
        {id: 20, estadoRepublica: 'Oaxaca'},
        {id: 21, estadoRepublica: 'Puebla'},
        {id: 22, estadoRepublica: 'Querétaro'},
        {id: 23, estadoRepublica: 'Quintana Roo'},
        {id: 24, estadoRepublica: 'San Luis Potosí'},
        {id: 25, estadoRepublica: 'Sinaloa'},
        {id: 26, estadoRepublica: 'Sonora'},
        {id: 27, estadoRepublica: 'Tabasco'},
        {id: 28, estadoRepublica: 'Tamaulipas'},
        {id: 29, estadoRepublica: 'Tlaxcala'},
        {id: 30, estadoRepublica: 'Veracruz de Ignacio de la Llave'},
        {id: 31, estadoRepublica: 'Yucatán'},
        {id: 32, estadoRepublica: 'Zacatecas'},
      ],
      tipoItems: [
        "Nota",
        "Documento"
      ],
      tipoDocumentoItems: [
        "Acta constitutiva",
        "Aviso de privacidad firmado",
        "Caratula de afiliación",
        "Comprobante de Domicilio",
        "Comprobante Médico",
        "Constancia de situación fiscal",
        "Caratula de estado de cuenta",
        "Cotización",
        "Cédula del Agente",
        "Identificación oficial",
        "Documento de afiliación",
        "Poder Notarial",
        "Póliza",
        "RC de Agente",
        "Tarjeta de Circulación",
        "Otro",
      ],
      bodySolicitud: null,
      uuid: this.$route.params.uuid,
      numeroSolicitud: null,
      estadoSolicitud: "",
      tipo: "",
      procesoCotizacion: "",
      isAgent: false,
      agenteUuid: localStorage.agenteUuid,
      correoAgente: null,
      idSolicitud: null,
      origenSolicitudSelected: '',
      estadoSelected: '',
      tipoSelected: '',
      categoriaSelected: '',
      agente: '',
      productoSelected: '',
      productoId: '',
      ejecutivo: '',
      seguimientoSolicitud: '',
      polizaRelacionada: null,
      oportunidades: '',
      nombres: '',
      apellidoPaterno: '',
      apellidoMaterno: '',
      fechaNacimiento: '',
      selectedGenero: '',
      modelo: '',
      marca: '',
      submarca: '',
      detalle: '',
      version: '',
      inicioVigencia: '',
      finVigencia: '',
      cobertura: '',
      celular: '',
      correoElectronico: '',
      edad: '',
      direccion: '',
      coberturasSelected: '',
      formaPagoSelected: '',
      estadoRepublicaSelected: '',
      estadoRepublicaId: '',
      estadoCivilSelected: '',
      codigoPostal: '',
      solicitudCompletaSelected: '',
      rfc: '',
      regimenPersona: null,
      valorOportunidad: '',
      scrollInvoked: 0,
      sla: [],
      numMotor: null,
      placas: null,
      numSerie: null,
      usoAutoSelected: null,
      tipoVehiculoSelected: null,
      periodicidadSelected: null,
      cumpleSla: null,
      creadoPor: null,
      fechaCreacion: null,
      modificadoPor: null,
      fechaModificacion: null,
      solicitudBody: {},
      campania: "",

      //SLA
      fechaNueva: '',
      horaNueva: '',

      fechaInfoCompleta: '',
      horaInfoCompleta: '',

      fechaPendienteInfo: '',
      horaPendienteInfo: '',

      fechaEnTramite: '',
      horaEnTramite: '',

      fechaCotizacionTramitada: '',
      horaCotizacionTramitada: '',

      fechaRecotizacion: '',
      horaRecotizacion: '',

      fechaEmisionEnProceso: '',
      horaEmisionEnProceso: '',

      fechaFinalizacion: '',
      horaFinalizacion: '',

      fechaCancelacion: '',
      horaCancelacion: '',
      creadaInbounds:false,
      todosAgentes:[],
      razonSocial:[],
    };
  },
  methods: {
    verDetalleCorreo(correo){
      console.log(correo);
      console.log("Correo open:", correo);
      this.dialogCorreo=true;
      this.correoOpen=correo;
    },
    verDocumento(documento) {
      console.log("Documento: ", documento);
      this.dialogDetalleDocumentos = true
      this.detallesDocumento = documento
    },
    goBack() {
      return this.$router.go(-1);
    },
    onScroll () {
      this.scrollInvoked++
    },
    mostrarInputs() {
      if (this.tipoSelected == 'Cotización' || this.tipoSelected == 'Emisión' || this.tipoSelected == 'Renovación') {
        return true
      } else return false
    },
    // ocultarBotonEditar() {
    //   if (this.estadoSelected == 'Cerrada' || this.estadoSelected == 'Cancelada') {
    //     this.hidden = true
    //   }
    // },
    obtnerDatosSolicitud() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios
        .get(`/v1/solicitud/id/${this.uuid}`, config)
        .then((response) => {
          console.log("Response: ", response.data);
          this.solicitud = [];
          this.correos = [];
          this.documentos = [];
          this.solicitud.push(response.data);
          this.bodySolicitud = response.data.body ? JSON.parse(response.data.body) : '';
          response.data.correos ? response.data.correos.map(element => {
            this.correos.push({
              asunto: element.subject,
              fecha: element.fecha ? moment.utc(element.fecha).subtract(6, 'hours').format('DD/MM/YYYY HH:mm') : null,
              cuerpo:element.cuerpo,
              remitente: element.remitente,
              documentos: element.documentos,
              to: element.to,
              cc: element.cc,
              bcc: element.bcc
            })
          }) : ''
          response.data.documentos ? response.data.documentos.map(element => {
            this.documentos.push({
              nombre: element.nombre ? element.nombre : 'Solicitud #' + response.data.id,
              fecha: element.fechaCreacion ? moment.utc(element.fechaCreacion).subtract(6, 'hours').format('DD/MM/YYYY HH:mm:ss') : 'No Disponible',
              tipo: element.tipoRegistro ? element.tipoRegistro : 'No Disponible',
              tipoDocumento: element.tipoDocumento ? element.tipoDocumento : 'No Disponible',
              descripcion: element.nota ? element.nota : 'Solicitud #' + response.data.id,
              creadoPor: element.creadoPor ? element.creadoPor : 'No Disponible',
              url: element.url_bucket
            })
          }) : ''
          response.data.logs ? response.data.logs.map(element => {
            // console.log("Logs: ", element)
            switch (element.status) {
              case 'Nueva':
                this.fechaNueva = moment(element.fechaCambio.slice(0,10), 'YYYY-MM-DD').format('DD/MM/YYYY')
                this.horaNueva = moment.utc(element.fechaCambio).subtract(6, 'hours').format('HH:mm:ss')
                break;
              case 'Asignada':
                this.fechaInfoCompleta = moment(element.fechaCambio.slice(0,10), 'YYYY-MM-DD').format('DD/MM/YYYY')
                this.horaInfoCompleta = moment.utc(element.fechaCambio).subtract(6, 'hours').format('HH:mm:ss')
                break;
              case 'Pendiente de Informacion':
                this.fechaPendienteInfo = moment(element.fechaCambio.slice(0,10), 'YYYY-MM-DD').format('DD/MM/YYYY')
                this.horaPendienteInfo = moment.utc(element.fechaCambio).subtract(6, 'hours').format('HH:mm:ss')
                break;
              case 'En tramite/Cotizacion':
                this.fechaEnTramite = moment(element.fechaCambio.slice(0,10), 'YYYY-MM-DD').format('DD/MM/YYYY')
                this.horaEnTramite = moment.utc(element.fechaCambio).subtract(6, 'hours').format('HH:mm:ss')
                break;
              case 'Pendiente de Aprobacion':
                this.fechaCotizacionTramitada = moment(element.fechaCambio.slice(0, 10), 'YYYY-MM-DD').format('DD/MM/YYYY')
                this.horaCotizacionTramitada = moment.utc(element.fechaCambio).subtract(6, 'hours').format('HH:mm:ss')
                break;
              case 'Recotizacion':
                this.fechaRecotizacion = moment(element.fechaCambio.slice(0, 10), 'YYYY-MM-DD').format('DD/MM/YYYY')
                this.horaRecotizacion = moment.utc(element.fechaCambio).subtract(6, 'hours').format('HH:mm:ss')
                break;
              case 'En tramite/Emision':
                this.fechaEmisionEnProceso = moment(element.fechaCambio.slice(0, 10), 'YYYY-MM-DD').format('DD/MM/YYYY')
                this.horaEmisionEnProceso = moment.utc(element.fechaCambio).subtract(6, 'hours').format('HH:mm:ss')
                break;
              case 'Cerrada':
                this.fechaFinalizacion = moment(element.fechaCambio.slice(0, 10), 'YYYY-MM-DD').format('DD/MM/YYYY')
                this.horaFinalizacion = moment.utc(element.fechaCambio).subtract(6, 'hours').format('HH:mm:ss')
                break;
              case 'Cancelada':
                this.fechaCancelacion = moment(element.fechaCambio.slice(0, 10), 'YYYY-MM-DD').format('DD/MM/YYYY')
                this.horaCancelacion = moment.utc(element.fechaCambio).subtract(6, 'hours').format('HH:mm:ss')
                break;
              default:
                break;
            }
          }) : ''
          // this.sla.push(response.data.logs)
          // this.fechaInfoCompleta = this.sla.filter(item => console.log("Que trae item: ", item))

          this.tipo = response.data.origen;

          this.origenSolicitudSelected = response.data.origen ? response.data.origen : ''
          this.estadoSelected = response.data.status ? response.data.status : ''
          this.tipoSelected = response.data.tipo ? response.data.tipo : ''
          this.categoriaSelected = response.data.categoria ? response.data.categoria : ''
          this.agente = response.data.nombreAgente ? response.data.nombreAgente : ''
          this.correoAgente = response.data.correoAgente ? response.data.correoAgente : null
          this.productoId = response.data.productoId ? response.data.productoId : ''
          this.ejecutivo = response.data.operadorId ? response.data.operadorId : ''
          if (response.data.nombreEjecutivo)
            this.ejecutivo = response.data.nombreEjecutivo ? response.data.nombreEjecutivo : ''
          this.seguimientoSolicitud = this.bodySolicitud.seguimientoSolicitud ? this.bodySolicitud.seguimientoSolicitud : ''
          // this.polizaRelacionada = this.response.data.emisionId ? this.response.data.emisionId : ''
          this.oportunidades = this.bodySolicitud.oportunidades ? this.bodySolicitud.oportunidades : ''
          this.nombres = response.data.nombre ? response.data.nombre : ''
          this.apellidoPaterno = response.data.apellidoPaterno ? response.data.apellidoPaterno : ''
          this.apellidoMaterno = response.data.apellidoMaterno ? response.data.apellidoMaterno : ''
          this.fechaNacimiento = response.data.fechaNacimiento ? moment(response.data.fechaNacimiento).format('DD/MM/YYYY') : ''
          this.selectedGenero = response.data.sexo ? response.data.sexo : ''
          this.modelo = this.bodySolicitud.modelo ? this.bodySolicitud.modelo : ''
          this.marca = this.bodySolicitud.marca ? this.bodySolicitud.marca : ''
          this.submarca = this.bodySolicitud.submarca ? this.bodySolicitud.submarca : ''
          this.detalle = this.bodySolicitud.detalle ? this.bodySolicitud.detalle : ''
          this.version = this.bodySolicitud.version ? this.bodySolicitud.version : ''
          this.inicioVigencia = response.data.fechaInicioVigencia ? moment(response.data.fechaInicioVigencia).format('DD/MM/YYYY') : ''
          this.finVigencia = response.data.fechaFinVigencia ? moment(response.data.fechaFinVigencia).format('DD/MM/YYYY') : ''
          this.cobertura = this.bodySolicitud.cobertura ? this.bodySolicitud.cobertura : ''
          this.celular = response.data.celular ? response.data.celular : ''
          this.correoElectronico = response.data.correoElectronico ? response.data.correoElectronico : ''
          this.edad = this.bodySolicitud.edad ? this.bodySolicitud.edad : ''
          this.direccion = this.bodySolicitud.direccion ? this.bodySolicitud.direccion : ''
          this.coberturasSelected = this.bodySolicitud.coberturasSelected ? this.bodySolicitud.coberturasSelected : ''
          this.formaPagoSelected = response.data.formaDePago ? response.data.formaDePago : ''
          this.estadoRepublicaSelected = response.data.estadoRepublica ? response.data.estadoRepublica : ''
          this.estadoCivilSelected = response.data.estadoCivil ? response.data.estadoCivil : ''
          this.codigoPostal = response.data.codigoPostal ? response.data.codigoPostal : ''
          this.solicitudCompletaSelected = response.data.solicitudCompleta ? response.data.solicitudCompleta == 0 ? 'No' : 'Si' : ''
          this.rfc = response.data.rfc ? response.data.rfc : ''
          this.regimenPersona = this.bodySolicitud.regimen_persona ? this.bodySolicitud.regimen_persona : null
          this.procesoCotizacion = response.data.procesoCotizacion ? response.data.procesoCotizacion : 'Cotización'
          this.polizaRelacionada = response.data.emisionId ? response.data.emisionId : null
          this.valorOportunidad = response.data.valorOportunidad ? response.data.valorOportunidad : null
          this.periodicidadSelected = response.data.periodicidadPago == 1 ? 'Anual'
                                    : response.data.periodicidadPago == 2 ? 'Semestral'
                                    : response.data.periodicidadPago == 3 ? 'Trimestral'
                                    : response.data.periodicidadPago == 4 ? 'Mensual' : '',
          this.tipoVehiculoSelected = this.bodySolicitud.tipo_vehiculo ? this.bodySolicitud.tipo_vehiculo : '',
          this.usoAutoSelected = this.bodySolicitud.uso_auto ? this.bodySolicitud.uso_auto : '',
          this.numMotor = this.bodySolicitud.numMotor ? this.bodySolicitud.numMotor : '',
          this.numSerie = this.bodySolicitud.numSerie ? this.bodySolicitud.numSerie : '',
          this.placas = this.bodySolicitud.placas ? this.bodySolicitud.placas : '',
          this.idSolicitud = response.data.id,
          this.cumpleSla = response.data.slaGeneral,
          this.creadoPor = response.data.creadoPor ? response.data.creadoPor : 'No Disponible',
          this.fechaCreacion = response.data.fechaCreacion ? moment.utc(response.data.fechaCreacion).subtract(6, 'hours').format('DD/MM/YYYY HH:mm') : 'No Disponible',
          this.modificadoPor = response.data.modificadoPor ? response.data.modificadoPor : 'No Disponible',
          this.fechaModificacion = response.data.fechaModificacion ? moment.utc(response.data.fechaModificacion).subtract(6, 'hours').format('DD/MM/YYYY HH:mm') : 'No Disponible'
          this.solicitudBody = JSON.parse(response.data.body)
          this.campania = response.data.campania
          var jsonData = JSON.parse(response.data.body)
          this.actividadSolicitud = jsonData.actividadSolicitud ? jsonData.actividadSolicitud  : '';
          this.obtenerProductos(response.data.productoId)
          this.creadaInbounds =  jsonData.esInbound ? jsonData.esInbound  : false;
          this.selectedGenero =  jsonData.selectedGenero ? jsonData.selectedGenero  : false;
          this.estado_civil =  jsonData.estado_civil ? jsonData.estado_civil  : false;
          this.estadoCivilSelected = jsonData.estado_civil ? jsonData.estado_civil : ( response.data.estadoCivil ? response.data.estadoCivil : '')
          this.numeroPoliza = jsonData.numeroPoliza ? jsonData.numeroPoliza : ''
          this.celular = jsonData.telefono ? jsonData.telefono : ( response.data.celular ? response.data.celular : '')
          this.razonSocial = this.bodySolicitud.razon_social ? this.bodySolicitud.razon_social : null

        });
      this.permitirEditarAgente();
      this.obtenerProductos();
    },
    obtenerSla() {
      this.fechaInfoCompleta = this.sla[0].fechaCambio
    },
    verPolizaRelacionada() {
      this.$router.push(`/emision/${this.polizaRelacionada}`)
    },
    formatDate(date) {
      let formattedDate = new Date(date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear())
      return formattedDate;
    },
    verSolicitudEditable(id) {
      this.$router.push(`/actualizar-solicitud/${id}`);
    },
    
    agent() {
      var isAgente = false;
      var roles=[]
      this.roles.forEach((element) => {
        roles.push(element.rol)
      });
      const rolesToView = ["AGENT"];
      roles.forEach((element)=>{
        if(rolesToView.includes(element)){
          isAgente=true;
        }
      })
      this.isAgente=isAgente;
      return isAgente;
    },
    verRol() {
      // console.log("Rol:", this.roles);
    },
    obtenerProductos() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios.get('/v1/producto/list', config).then(response => {
        // console.log('Productos: ', response.data);
        response.data.map(element => {
          // this.productos.push({
          //   nombre: `${element.ramo}`+ ' / ' + `${element.nombre}` + ' / ' + `${element.nombreAseguradora}`,
          //   id: element.id
          // })
          
          this.productoId == element.id ? this.productoSelected = `${element.ramo.toUpperCase()}`+ ' / ' + `${element.nombre.toUpperCase()}` + ' / ' + `${element.nombreAseguradora.toUpperCase()}` : ''
        })
      });
    },
    infoCompleta() {
      if (this.solicitudCompletaSelected == 1) {
        return 'Si'
      } else if (this.solicitudCompletaSelected == 0) {
        return 'No'
      }
    },
    permitirEditarAgente() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios.get("/v1/solicitud/agente/list", config).then((response) => {
        response.data.map(element => {
          // console.log("Response agente list: ", element.uuid);
          if (element.uuid == this.agenteUuid) {
            console.log("Quiero que me digas que entró")
          }
        })
      });
    },
    closeSendEmail(){
      this.newCorreoDialog=false;
      this.obtnerDatosSolicitud()
    },
    getAgentes() {
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios
          .get(`/v1/agente/list`, config)
          .then((response) => {
            this.todosAgentes = response.data
          });
    },
    closeDocumentoDialog(){
      this.dialogDetalleDocumentos = false;
    }
  },
  computed: {
    canEdit(){
      var canView = false;
      var roles=[]
      this.roles.forEach((element) => {
        roles.push(element.rol)
      });
      const rolesToView = ["ADMIN", "MESADECONTROL", "OPERACIONES","OPERADOR", "AGENTCC", "AGENTCCSPONSORS", "OPERADORCC", "ADMINCC", "MANAGERCC", "MESACONTROLINBOUND", "OPERACIONESINBOUND", "OPERADORINBOUND", "BACKOFFICEINBOUND"];
      roles.forEach((element)=>{
        if(rolesToView.includes(element)){
          canView=true;
        }
      })
      return canView;
    },
    ocultarBotonEditar() {
      if (this.estadoSelected == 'Cerrada' || this.estadoSelected == 'Cancelada') {
        return true
      }
      return false
    },
    operadoresInbound(){
      if(this.todosAgentes.length > 0)
        return this.todosAgentes.filter((e) => e.rol == 'OPERADORINBOUND' || e.rol == 'BACKOFFICEINBOUND')
      else
        return [];
    }
  },
  mounted() {
    this.getAgentes(),
    this.permitirEditarAgente(),
    this.obtnerDatosSolicitud(), 
    this.agent(), 
    this.verRol(), 
    this.infoCompleta(), 
    this.mostrarInputs(), 
    this.obtenerSla(), 
    this.estados
  },
};
</script>

<style scoped>
  .v-stepper__step__step {
    background-color: white;
  }
  .enlacesForms input {
    color: red;
    cursor: pointer;
  }
</style>
