<template>
    <v-card>
        <v-toolbar flat dense>
            <v-toolbar-title class="text-subtitle-1">Solicitudes</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn v-if="validarLead" small color="primary" @click="openModal">
                Crear
            </v-btn>
        </v-toolbar>

        <v-divider></v-divider>

        <v-data-table
            :headers="columns"
            :items="solicitudes"
            item-key="name"
            class="elevation-1"
            loading-text="Cargando..."
            dense
            :items-per-page="5"
        >
            <template v-slot:[`item.accion`]="{ item }">
                <v-icon small class="mr-2" @click="VerSolicitud(item.id)">
                    mdi-eye
                </v-icon>
            </template>
        </v-data-table>
    </v-card>
</template>

<script>
    // import { mainAxios } from "../../../mainAxios";
    // import moment from 'moment';

    export default {
        name: 'leads.detalles.llamadas',
        data: function(){
            return{
                columns: [
                    { text: "No", align: "start", sortable: false, value: "id"},
                    { text: "Fecha", value: "fecha" },
                    { text: "Tipo", value: "tipo" },
                    { text: "Estatus", value: "status" },
                    { text: "Ver", value: "accion" },
                ],
                data_table:[],
                uuid: '',
                validarLead:false
            }
        },
        props: {
            solicitudes: {
                type: Array,
                required: true
            },
            lead: {
                type: Object,
                require: true,
            },
        }, 
        watch:{
            'lead'(){
                this.checarLeadIncomplete()
            }
        },
        methods: {
            openModal(){
                this.$emit('openSolicitudModal')
            },
            VerSolicitud(id){
             window.open(`/seguimiento-solicitud/${id}`)
            },
            checarLeadIncomplete(){
                if(typeof this.lead.medio != 'undefined' &&
                typeof this.lead.producto != 'undefined' &&
                typeof this.lead.regimen != 'undefined' &&
                typeof this.lead.rfc != 'undefined' &&
                typeof this.lead.nombre != 'undefined' &&
                typeof this.lead.apellidoPaterno != 'undefined' &&
                typeof this.lead.apellidoMaterno != 'undefined' &&
                typeof this.lead.fechaNacimiento != 'undefined' &&
                typeof this.lead.sexo != 'undefined' &&
                typeof this.lead.estadoCivil != 'undefined' &&
                typeof this.lead.edad != 'undefined' &&
                typeof this.lead.telefono != 'undefined' &&
                typeof this.lead.correo != 'undefined' &&
                typeof this.lead.calle != 'undefined' &&
                typeof this.lead.num_exterior != 'undefined' &&
                typeof this.lead.num_interior != 'undefined' &&
                typeof this.lead.codigoPostal != 'undefined' &&
                typeof this.lead.colonia != 'undefined' &&
                typeof this.lead.ciudad != 'undefined' &&
                typeof this.lead.estado != 'undefined' &&
                typeof this.lead.pais != 'undefined')
                {
                    this.validarLead = true
                }else{
                    this.validarLead = false
                }
            }
        }
    }
</script>