<template>
  <v-form>
    <v-row style="padding: 20px">
      <v-col cols="4">
        <v-select
          label="Tipo de Vehículo"
          placeholder="Requerido"
          v-model="tipoVehiculo"
          :items="tipoVehiculosItems"
          outlined
        ></v-select>
      </v-col>
      <v-col cols="4">
        <v-select
          :items="usoAutoList"
          label="Uso del Auto"
          placeholder="Requerido"
          v-model="usoAuto"
          outlined
        ></v-select>
      </v-col>
      <v-col cols="4">
        <v-select
          :items="origenAutoItems"
          label="Origen del Auto"
          placeholder="Requerido"
          v-model="origenAuto"
          outlined
        ></v-select>
      </v-col>
      <v-col cols="4">
        <v-autocomplete
          label="Modelo"
          placeholder="Requerido"
          v-model="modelo"
          :items="modelos"
          item-value="id"
          item-text="id"
          auto-select-first
          @change="getMarcas()"
          outlined
        ></v-autocomplete>
      </v-col>
      <v-col cols="4">
        <v-autocomplete
          label="Marca"
          placeholder="Requerido"
          v-model="marca"
          :items="marcas"
          item-value="marca"
          item-text="marca"
          no-data-text="Primero selecciona un modelo"
          auto-select-first
          @change="getSubmarcas()"
          outlined
        ></v-autocomplete>
      </v-col>
      <v-col cols="4">
        <v-autocomplete
          label="Submarca"
          placeholder="Requerido"
          v-model="submarca"
          :items="submarcas"
          item-value="nombre"
          item-text="nombre"
          no-data-text="Primero selecciona una marca"
          auto-select-first
          @change="getVersiones()"
          outlined
        ></v-autocomplete>
      </v-col>

      <v-col cols="4">
        <v-autocomplete
          label="Version"
          placeholder="Requerido"
          v-model="version"
          :items="versiones"
          item-value="nombre"
          item-text="nombre"
          no-data-text="Primero selecciona una submarca"
          auto-select-first
          outlined
        ></v-autocomplete>
      </v-col>
      <v-col cols="4">
        <v-select
          :items="coberturas"
          label="Plan"
          placeholder="Requerido"
          v-model="plan"
          outlined
        ></v-select>
      </v-col>
      <!-- CAMPOS SOLO PARA TIPO EMISION Y RENOVACION -->
      <v-col cols="4" v-if="tipo == 'Emisión' || tipo == 'Renovación'">
        <v-text-field
          label="No. De Motor"
          placeholder="Requerido"
          v-model="numMotor"
          outlined
        ></v-text-field>
      </v-col>

      <v-col cols="4" v-if="tipo == 'Emisión' || tipo == 'Renovación'">
        <v-text-field
          label="No. De Serie"
          placeholder="Requerido"
          v-model="numSerie"
          :maxLength="17"
          :rules="[localRules.alphanumeric, $rules.min(15), $rules.max(17)]"
          outlined
        ></v-text-field>
      </v-col>

      <v-col cols="4" v-if="tipo == 'Emisión' || tipo == 'Renovación'">
        <v-text-field
          label="Placas"
          placeholder="Requerido"
          v-model="placas"
          :maxLength="10"
          :rules="[localRules.alphanumeric]"
          outlined
        ></v-text-field>
      </v-col>
      <!-- FIN DE CAMPOS SOLO PARA TIPO EMISION Y RENOVACION -->

      <!-- ALERT MODAL -->
      <v-col cols="auto">
        <v-dialog
          transition="dialog-bottom-transition"
          max-width="400"
          v-model="dialog"
        >
          <template v-slot:default="dialog">
            <v-card>
              <v-toolbar color="success" dark>
                Confirmación de envío
              </v-toolbar>
              <v-card-text>
                <div class="text-h4" style="margin-top: 10px; ">
                  Envio de solicitud exitoso!
                </div>
              </v-card-text>
              <v-card-actions class="justify-end">
                <v-btn text @click="dialog.value = false">Cerrar</v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>
      </v-col>
      <!-- END OF ALERT MODAL -->
    </v-row>
    <v-row>
      <v-col class="text-right" style="padding-right: 25px;">
        <v-btn
          color="primary"
          @click="sendSolicitud()"
          :disabled="!comportamientoBotonGuardar()"
        >
          Guardar
        </v-btn>
        <v-btn
          color="primary"
          style="margin-left: 10px"
          v-show="rolToCompare != 'AGENT'"
          @click="$emit('nextStep')"
        >
          Siguiente
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import moment from "moment";
import { mainAxios } from "../../../mainAxios";
export default {
  props: {
    origenSolicitud: String,
    statusSolicitud: String,
    agente: Number,
    tipo: String,
    ramo: String,
    producto: Number,
    detalleSolicitud: String,
    nombres: String,
    apellidoPaterno: String,
    apellidoMaterno: String,
    estadoCivil: String,
    estadoNacimiento: Number,
    genero: String,
    fechaNacimiento: String,
    rfc: String,
    tipoPersona: String,
    telefono: String,
    correo: String,
    codigoPostal: String,
    direccion: String,
    inicioVigencia: String,
    finVigencia: String,
    periodicidad: String,
    formaPago: String,
    operador: Number,
    solicitudCompleta: String,
    procesoCotizacion: String,
    comercialAsignado: Number,
    razonSocial: String,
    leadOrigen: Object,
    newFormProps: Object,
    esDesdeDialog: Boolean,
    numeroPoliza: String,
  },
  data() {
    return {
      rolToCompare: JSON.parse(localStorage.getItem("roles"))[0].rol,
      dialog: false,
      modelo: null,
      marca: null,
      submarca: null,
      version: null,
      usoAuto: null,
      tipoVehiculo: null,
      plan: null,
      numMotor: null,
      numSerie: null,
      placas: null,
      origenAuto: null,
      usoAutoList: [
        "Particular",
        "Comercial",
        "Uso con App",
        "Servicio público federal",
        "Transporte público",
        "Taxi",
      ],
      coberturas: ["Amplia", "Amplia-Plus", "Limitada", "RC"],
      tipoVehiculosItems: [
        "Auto",
        "Motocicleta",
        "Pick-up",
        "Camión",
        "Tractocamión",
      ],
      origenAutoItems: [
        "Fronterizo",
        "Importado",
        "Legalizado",
        "Nacional",
        "Salvamento",
      ],

      axiosConfig: {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      },

      localRules: {
        alphanumeric: (v) =>
          !v ||
          !!v.match(/^[0-9a-zA-Z]+$/) ||
          "Este campo solo acepta letras y números",
      },

      modelos: [],
      marcas: [],
      submarcas: [],
      versiones: [],
    };
  },
  watch: {
    dialog(val) {
      if (!val) return;

      setTimeout(() => (this.dialog = false), 2500);
    },
  },

  mounted() {
    this.getModelos();
  },

  methods: {
    getModelos() {
      mainAxios
        .get("/v1/vehiculo/qualitas/modelos", this.axiosConfig)
        .then(({ data }) => {
          this.modelos = data;
        });
    },

    getMarcas() {
      mainAxios
        .get(`/v1/vehiculo/qualitas/marca/${this.modelo}`, this.axiosConfig)
        .then(({ data }) => {
          this.marcas = data;
        });
    },

    getSubmarcas() {
      mainAxios
        .get(
          `/v1/vehiculo/qualitas/submarca/${this.marcaId}/${this.modelo}`,
          this.axiosConfig
        )
        .then(({ data }) => {
          this.submarcas = data;
        });
    },

    getVersiones() {
      mainAxios
        .get(
          `/v1/vehiculo/qualitas/vehiculo/${this.marcaId}/${this.submarca}/${this.modelo}`,
          this.axiosConfig
        )
        .then(({ data }) => {
          this.versiones = data;
        });
    },

    sendSolicitud() {
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      let request = {
        origenSolicitudSelected: this.origenSolicitud,
        tipoSelected: this.tipo,
        categoriaSelected: this.ramo,
        agenteId: this.agente,
        productoId: this.producto,
        operadorUuid: this.operador,
        nombres: this.nombres,
        apellidoPaterno: this.apellidoPaterno,
        apellidoMaterno: this.apellidoMaterno,
        fechaNacimiento: this.fechaNacimiento
          ? moment(this.fechaNacimiento, "DD/MM/YYYY").format("YYYY-MM-DD")
          : null,
        genero: this.genero,
        rfc: this.rfc,
        modelo: this.modelo,
        marca: this.marca,
        submarca: this.submarca,
        detalle: this.detalleSolicitud ? this.detalleSolicitud : "",
        version: this.version ? this.version : "",
        inicioVigencia: moment(this.inicioVigencia, "DD/MM/YYYY").format(
          "YYYY-MM-DD"
        ),
        finVigencia: moment(this.finVigencia, "DD/MM/YYYY").format(
          "YYYY-MM-DD"
        ),
        celular: this.telefono,
        comercial_asignado: this.comercialAsignado
          ? this.comercialAsignado
          : null,
        razon_social: this.razonSocial ? this.razonSocial : null,
        correoElectronico: this.correo,
        codigoPostal: this.codigoPostal,
        direccion: this.direccion,
        estadoRepublicaSelected: this.estadoNacimiento,
        estadoCivilSelected: this.estadoCivil,
        coberturasSelected: this.plan,
        periodicidadPago:
          this.periodicidad == "Anual"
            ? 1
            : this.periodicidad == "Semestral"
            ? 2
            : this.periodicidad == "Trimestral"
            ? 3
            : this.periodicidad == "Mensual"
            ? 4
            : "",
        numMotor: this.numMotor,
        numSerie: this.numSerie,
        placas: this.placas,
        formaPagoSelected: this.formaPago,
        solicitudCompletaSelected: this.solicitudCompleta
          ? this.solicitudCompleta
          : false,
        procesoCotizacionSelected: this.procesoCotizacion,
        checkPendienteInfo: this.checkPendienteInfo
          ? this.checkPendienteInfo
          : null,
        checkCancelacion: this.checkCancelacion ? this.checkCancelacion : null,
        causaPendienteInformacion: this.causaPendienteInformacion
          ? this.causaPendienteInformacion
          : null,
        uso_auto: this.usoAuto ? this.usoAuto : null,
        tipo_vehiculo: this.tipoVehiculo ? this.tipoVehiculo : "",
        regimen_persona: this.tipoPersona,
        origenAuto: this.origenAuto,
        lead_id: this.leadOrigen ? this.leadOrigen.id : "",
        ...this.newFormProps,
        emit_direct: this.emitDirect,
        operadorId: JSON.parse(localStorage.getItem("roles"))[0].agente_id
          ? JSON.parse(localStorage.getItem("roles"))[0].agente_id
          : "777",
        checkTramite: this.emitDirect,
        numeroPoliza: this.numeroPoliza ? this.numeroPoliza : "",
      };

      console.log({ request });

      mainAxios
        .post("v1/solicitud/insert/", request, config)
        .then((response) => {
          if (response.status == 200) {
            this.$emit('showIDSolicitud', response)
            const ruta = window.location.pathname;
            if (ruta.includes("/segurify/leads-segurify/lead/")) {
              this.$emit("SolicitudSuccess");
            } else {
              this.dialog = true;
              !this.esDesdeDialog &&
                setTimeout(() => this.$router.push("/solicitudes"), 2500);
              this.esDesdeDialog &&
                setTimeout(() => (this.dialog.value = false), 2500);
              this.$emit("SolicitudSuccess");
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    comportamientoBotonGuardar() {
      if (
        (this.tipo == "Emisión" || this.tipo == "Renovación") &&
        this.procesoCotizacion == "Cotización"
      ) {
        return this.botonGuardar2;
      }
      return this.botonGuardar;
    },
  },
  computed: {
    marcaId() {
      if (!this.marca) return null;
      return this.marcas.find((m) => m.marca == this.marca).id;
    },

    botonGuardar() {
      if (this.tipoPersona == "Moral") {
        return (
          this.origenSolicitud != null &&
          this.statusSolicitud != null &&
          this.agente != null &&
          this.tipo != null &&
          this.ramo != null &&
          this.estadoNacimiento != null &&
          this.detalleSolicitud != null &&
          this.tipoPersona != null &&
          this.razonSocial != null &&
          this.rfc != null &&
          this.telefono != null &&
          this.correo != null &&
          this.codigoPostal != null &&
          this.inicioVigencia != null &&
          this.finVigencia != null &&
          this.periodicidad != null &&
          this.formaPago != null &&
          /* CAMPOS DEL RAMO */
          this.modelo != null &&
          this.marca != null &&
          this.submarca != null &&
          this.version != null &&
          this.plan != null
        );
      }
      return (
        this.origenSolicitud != null &&
        this.statusSolicitud != null &&
        this.agente != null &&
        this.tipo != null &&
        this.ramo != null &&
        this.detalleSolicitud != null &&
        this.nombres != null &&
        this.apellidoPaterno != null &&
        this.genero != null &&
        this.fechaNacimiento != null &&
        this.estadoNacimiento != null &&
        this.estadoCivil != null &&
        this.tipoPersona != null &&
        this.rfc != null &&
        this.telefono != null &&
        this.correo != null &&
        this.codigoPostal != null &&
        this.inicioVigencia != null &&
        this.finVigencia != null &&
        this.periodicidad != null &&
        this.formaPago != null &&
        /* CAMPOS DEL RAMO */
        this.modelo != null &&
        this.marca != null &&
        this.submarca != null &&
        this.version != null &&
        this.plan != null
      );
    },
    botonGuardar2() {
      if (this.tipoPersona == "Moral") {
        return (
          this.origenSolicitud != null &&
          this.statusSolicitud != null &&
          this.agente != null &&
          this.tipo != null &&
          this.ramo != null &&
          this.detalleSolicitud != null &&
          this.tipoPersona != null &&
          this.razonSocial != null &&
          this.rfc != null &&
          this.telefono != null &&
          this.correo != null &&
          this.codigoPostal != null &&
          this.inicioVigencia != null &&
          this.finVigencia != null &&
          this.periodicidad != null &&
          this.formaPago != null &&
          /* CAMPOS DEL RAMO */
          this.modelo != null &&
          this.marca != null &&
          this.submarca != null &&
          this.version != null &&
          this.plan != null
        );
      }
      return (
        this.origenSolicitud != null &&
        this.statusSolicitud != null &&
        this.agente != null &&
        this.tipo != null &&
        this.ramo != null &&
        this.detalleSolicitud != null &&
        this.nombres != null &&
        this.apellidoPaterno != null &&
        this.genero != null &&
        this.fechaNacimiento != null &&
        this.estadoNacimiento != null &&
        this.estadoCivil != null &&
        this.tipoPersona != null &&
        this.rfc != null &&
        this.telefono != null &&
        this.correo != null &&
        this.codigoPostal != null &&
        this.inicioVigencia != null &&
        this.finVigencia != null &&
        this.periodicidad != null &&
        this.formaPago != null &&
        /* CAMPOS DEL RAMO */
        this.modelo != null &&
        this.marca != null &&
        this.submarca != null &&
        this.version != null &&
        this.plan != null &&
        this.numMotor != null &&
        this.numSerie != null &&
        this.placas != null
      );
    },
    emitDirect() {
      if (
        this.rolToCompare == "ADMINCC" ||
        this.rolToCompare == "MANAGERCC" ||
        this.rolToCompare == "OPERADORCC" ||
        this.rolToCompare == "AGENTCC" ||
        this.rolToCompare == "AGENTCCSPONSOR"
      ) {
        return true;
      } else return false;
    },
  },
};
</script>

<style></style>
